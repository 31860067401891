import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Icon, Card, Select, Button, InputNumber } from 'antd'

import {
  formErrorFieldsNameLocalize,
  updateEmptyAndUndefined,
  successToast,
} from '../../../../../helpers/helper'
import { connect } from 'react-redux'
import { currencyData } from '../../../../../data/Currency'
import { postPanelApi } from '../../../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 22 },
    lg: { span: 16 },
  },
}

class GeneralSettingsForm extends Component {

  state = {
    loading: false,
    submitting: false,
  }

  componentDidMount() {

    this.setState({
      loading: true,
    }, () => {

      postPanelApi('/settings/general-settings-open').then(result => {

        if (result) {

          this.setState({
            loading: false,
          })

          this.props.form.setFieldsValue({
            fixed_exchange_rate: result.form.fixed_exchange_rate,
            increment: result.form.increment,
          })

        }

      })

    })

  }

  onSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      this.setState({
        submitting: true,
      }, () => {

        postPanelApi('/settings/general-settings-update', values).then(result => {

          if (result) {

            successToast(this.props.langData['General Settings Success'])

          }

        }).finally(() => {

          this.setState({
            submitting: false,
          })

        })

      })

    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        loading={this.state.loading}
        title={<span><Icon type="control"/> &nbsp;{this.props.langData['General Settings']}</span>}
        bordered={false}
      >

        <Form
          hideRequiredMark={true}
          className="form-label-default"
          onSubmit={e => {
            e.preventDefault()
            this.onSubmit()
          }}
        >

          <div className="form-block">

            <Form.Item label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Fixed Exchange Rate']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('fixed_exchange_rate', {
                rules: [{ required: true }],
                validateTrigger: false,
              })(
                <Select size="large" suffixIcon={<Icon type="caret-down"/>} disabled={true}>
                  {currencyData.map((x, k) => {
                    return <Select.Option value={x.currency_id} key={k}>{x.name + ' - ' + x.symbol}</Select.Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item
              label={(
                <span><Icon
                  type="bar-chart"/><p>{this.props.langData['Amount of increase in decimal numbers']}</p></span>
              )}
              {...formItemLayout}
            >
              {getFieldDecorator('increment', {
                validateTrigger: true,
              })(
                <InputNumber
                  decimalSeparator=","
                  size="large"
                  min={0}
                  step={0.0001}
                  style={{ width: '-webkit-fill-available' }}
                />,
              )}

            </Form.Item>

          </div>

          <div className="form-block">
            <Form.Item {...formItemLayoutNoLabel}>
              <Button type="primary" size="large" loading={this.state.submitting} htmlType="submit"
                      className="default-button">
                {this.props.langData['Save']}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    )
  }
}

GeneralSettingsForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(GeneralSettingsForm)
