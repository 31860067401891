import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Input, Button, Card, Divider } from 'antd'
import { KeyOutlined, MailOutlined, SaveOutlined, TagOutlined, UserOutlined } from '@ant-design/icons'

import {
  formErrorFieldsNameLocalize,
  updateEmptyAndUndefined,
  deleteNull, successToast,
} from '../../../helpers/helper'

import { postPanelApi } from '../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

function ManagerInfoForm(props) {

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    postPanelApi('/manager/info-open').then(result => {
        if (result) {
          props.form.setFieldsValue(result.form)
        }
      },
    )
  }, [])

  const onSubmit = () => {

    props.form.validateFields(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)
      values = deleteNull(values)

      setSubmitting(true)

      postPanelApi('/manager/info-submit', values).then(result => {

        if (result) {

          successToast(result.message)

        }

        setSubmitting(false)

      })

    })

  }

  const { getFieldDecorator } = props.form

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><UserOutlined/> {props.langData['My Manager Information']}</span>}
      bordered={false}
    >
      <Form
        hideRequiredMark={true}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default"
      >
        <div className="form-block">
          <Form.Item
            hasFeedback
            label={
              <span>
                <TagOutlined/>
                <p>{props.langData['Name Surname']}</p>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator('name_surname', {
              rules: [{ max: 250 }, { required: true }],
              validateTrigger: false,
            })(<Input size="large"/>)}
          </Form.Item>

          <Form.Item
            hasFeedback
            label={
              <span>
                <MailOutlined/>
                <p>{props.langData['E-Mail']}</p>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator('e_mail', {
              rules: [{ max: 100 }, { required: true }],
              validateTrigger: false,
            })(<Input size="large"/>)}
          </Form.Item>

          <Form.Item
            hasFeedback
            label={
              <span>
                <KeyOutlined/>
                <p>{props.langData['Password']}</p>
              </span>
            }
            {...formItemLayout}
          >
            {getFieldDecorator('password', {
              rules: [
                { max: 32 },
                { min: 6 },
                { required: false },
              ],
              validateTrigger: false,
            })(<Input size="large"/>)}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              loading={submitting}
              htmlType="submit"
              className="default-button"
            >
              <SaveOutlined/> {props.langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  )

}

ManagerInfoForm.propTypes = {
  manager_id: PropTypes.number,
  langData: PropTypes.object,
  isMe: PropTypes.bool,
  form: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
}

export default connect((state) => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
})(ManagerInfoForm)
