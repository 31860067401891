import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import {
  Table,
  Tag,
  Select,
  Spin,
  Input,
  Form,
  Popover,
  Button,
  Icon,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Modal,
} from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { cloneDeep, remove } from 'lodash'

import apolloClient from './../../../helpers/apolloClient'
import {
  toast,
  deleteEmpty,
  toLocaleString,
  graphQLErrorsToToast,
  createCsv,
  confirm,
  extractExcel,
} from '../../../helpers/helper'
import { multipleDeleteData } from '../../../helpers/trait'
import { getCurrencyWithCode } from '../../../data/Currency'
import { getMarketplacesWithCode } from '../../../data/Marketplaces'
import ShareEInvoiceMailModal from '../ShareEInvoiceMailModal/ShareEInvoiceMailModal'
import ShareInvoiceMail from '../ShareInvoiceMail/ShareInvoiceMail'
import InvoiceEArchiveModal from '../InvoiceEArchiveModal/InvoiceEArchiveModal'
import ETransformResultModal from '../ETransformResultModal/ETransformResultModal'
import ExcelExtractModal from '../../../utils/ExcelExtractModal'
import routes from '../../../routes'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'
import { postPanelApi } from '../../../api'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
    filterData: state.TableFilterReducer.data.Invoice,
    filterFieldsData: state.TableFilterReducer.data.InvoiceFieldsData,
    pageInfo: state.TableFilterReducer.data.InvoicePage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'Invoice' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'InvoiceFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'InvoicePage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'Invoice' } })
      dispatch({ type: 'resetFilter', payload: { name: 'InvoiceFieldsData' } })
    },
  }
}

const LIST_QUERY = fields => (
  gql`
    query($sorting: InvoiceSorting, $paging: Paging, $filter: InvoiceFilterBase){
      invoiceList(sorting: $sorting, paging: $paging, filter: $filter){
        transactions{
          __typename
          ... on SalesInvoice{
            transaction_id
            code
            grand_total
            discount_type
            currency
            invoice_country
            e_invoice_type
            invoice_address
            invoice_status_type
            return_invoices{
              transaction_id
            }
            ${fields}
          }
          ... on PurchaseInvoice{
            transaction_id
            grand_total
            code
            discount_type
            currency
            invoice_country
            invoice_status_type
            e_invoice_type
            invoice_address
            return_invoices{
              transaction_id
            }
            ${fields.indexOf('order_platform_number') === -1 ? fields : fields.replace('order_platform_number', '')}
          }
        }
        page_info{
          total_count
        }
      }
    }
  `
)

const MARKETPLACE_SETTINGS_QUERY = (
  gql`
    {
      settings {
        invoice_settings {
          does_use_market_place_customer
        }
      }
    }
  `
)

const SEND_INVOICE_EMAIL_QUERY = (
  gql`
    mutation($mailInformations: InvoiceSendMailAsTextInput) {
      invoiceSendMailAsText(mail_informations: $mailInformations) {
        incorrect_invoices
        informative {
          messages
        }
      }
    }
  `
)

const SEND_EINVOICE_MAIL_QUERY = (
  gql`
    mutation($mailInformations: SalesInvoicesSendWithMailEInvoiceInput!) {
      salesInvoicesSendWithMailEInvoice(mailInformations: $mailInformations) {
        incorrect_invoices
        informative {
          messages
        }
      }
    }
  `
)

class InvoiceList extends Component {
  fields = []
  state = {
    data: null,
    columns: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    sorting: {
      field: 'date',
      type: 'DESC',
    },
    filter: null,
    selectedRows: [],
    multipleRowsSelected: false,
    shareEInvoiceMail: false,
    shareEMailIncorrectInvoices: [],
    shareInvoiceMail: false,
    shareInvoiceIncorrectInvoices: [],
    createMultipleETransform: {
      enabled: false,
      result: [],
      type: '',
    },
    isExtractExcel: false,

    show_e_archive_create_modal: false,
    create_einvoice_submitting: false,
  }

  constructor(props) {
    super(props)

    this.multipleDeleteData = multipleDeleteData('invoice', 'transaction_id').bind(this)
    this.fields = this.props.sizeWidth < 576 ? ['customer', 'remaining', 'category'] : ['customer', 'remaining', 'category']
    this.setFieldsAsMarketplace()
    if (localStorage.getItem('invoice_list_columns')) {
      this.fields = localStorage.getItem('invoice_list_columns').split(',')
    }
  }

  setFieldsAsMarketplace = async () => {
    try {
      const result = await apolloClient.query({ query: MARKETPLACE_SETTINGS_QUERY, fetchPolicy: 'network-only' })
      const marketplaceStatus = result.data.settings.invoice_settings.does_use_market_place_customer
      if (marketplaceStatus) {
        // Add marketplace columns
        const fieldsData = [...this.fields, 'invoice_address', 'order_platform_number', 'currency']
        this.fields = [...new Set(fieldsData)]
      } else {
        // Remove marketplace columns
        this.fields = remove(this.fields, function (n) {
          return n !== 'invoice_address' && n !== 'order_platform_number' && n !== 'currency'
        })
      }
    } catch (error) {
      graphQLErrorsToToast(error)
      return null
    }
    localStorage.setItem('invoice_list_columns', this.fields)
  }

  onChangeField = fields => {
    if ( ! fields.length)
      return
    let columns = cloneDeep(this.state.columns)
    columns.forEach(x => {
      if (fields.indexOf(x.fieldName) !== -1)
        x.hide = false
      else
        x.hide = true
      x.width = 100 / (fields.length + 1) + '%'
    })

    this.setState({
      columns,
    })


    localStorage.setItem('invoice_list_columns', fields)

    this.fields = fields
  }

  componentDidMount() {
    this.props.breadcrumb_add(routes.INVOICE_LIST)
    this.setState({
      columns: [
        {
          title: this.props.langData['Invoice Type'],
          fieldName: '__typename',
          dataIndex: '__typename',
          // width: 100 / (this.fields.length+1)+ '%',
          render: (value, record) => {
            if (value === 'SalesInvoice') {
              return (
                <div>
                  <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Sales Invoice']}</Tag><br/>
                  {record.e_invoice_type === 'EInvoice' ? <Tag className="success" style={{
                      marginRight: 0,
                      marginTop: 5,
                    }}>{this.props.langData['E-Invoice']}</Tag> :
                    record.e_invoice_type ? <Tag className="success" style={{
                      marginRight: 0,
                      marginTop: 5,
                    }}>{this.props.langData['E-Archive']}</Tag> : ''}
                  {record.return_invoices && record.return_invoices.length ? <Tag color="#ff7875" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['Return']}</Tag> : ''}
                  {record.invoice_status_type === 'Cancel' ? <Tag color="#ff4d4f" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['Cancel']}</Tag> : ''}
                </div>
              )
            }

            if (value === 'PurchaseInvoice') {
              return (
                <div>
                  <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Purchase Invoice']}</Tag><br/>
                  {record.e_invoice_type === 'EInvoice' ? <Tag className="success" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['E-Invoice']}</Tag> : record.e_invoice_type ? <Tag className="success"
                                                                                             style={{
                                                                                               marginRight: 0,
                                                                                               marginTop: 5,
                                                                                             }}>{this.props.langData['E-Archive']}</Tag> : ''}
                  {record.return_invoices && record.return_invoices.length ? <Tag color="#ff7875" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['Return']}</Tag> : ''}
                </div>
              )
            }
          },
        },
        {
          title: this.props.langData['Code'],
          dataIndex: 'code',
          fieldName: 'code',
          fieldLabel: 'Code',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('code') === -1,
          key: 'code',
          align: 'center',
        },
        {
          title: this.props.langData['Category'],
          dataIndex: 'category',
          fieldName: 'category',
          fieldLabel: 'Category',
          subFields: ['name'],
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('category') === -1,
          key: 'category',
          align: 'center',
          render: record => record ? record.name : '',
        },
        {
          title: this.props.langData['Customer'],
          dataIndex: 'customer',
          fieldName: 'customer',
          fieldLabel: 'Customer',
          subFields: ['name'],
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('customer') === -1,
          key: 'customer',
          align: 'center',
          render: record => record ? record.name : '',
        },
        {
          title: this.props.langData['Marketplace Customer'],
          dataIndex: 'invoice_address',
          fieldName: 'invoice_address',
          fieldLabel: 'Marketplace Customer',
          width: 100 / (this.fields.length + 1) + '%',
          key: 'marketplace_customer',
          hide: this.fields.indexOf('invoice_address') === -1,
          render: record => {
            if (record && record.includes('-->', '-&-')) {
              const firstSplit = record.split('-->')[1]
              const secondSplit = firstSplit.split('-&-')[0].trim()
              return secondSplit
            }
          },
        },
        {
          title: this.props.langData['Marketplace'],
          fieldName: 'currency',
          dataIndex: 'currency',
          fieldLabel: 'Marketplace',
          hide: this.fields.indexOf('currency') === -1,
          key: 'currency',
          align: 'center',
          render: (_, data) => {
            return getMarketplacesWithCode(data.code) && <img style={{ width: 75 }}
                                                              src={`https://app.jetofis.com/marketplaces/${((getMarketplacesWithCode(data.code).name).toLowerCase())}.svg`}></img>
          },
        },
        {
          title: this.props.langData['Marketplace Order No'],
          dataIndex: 'order_platform_number',
          fieldName: 'order_platform_number',
          fieldLabel: 'Marketplace Order No',
          hide: this.fields.indexOf('order_platform_number') === -1,
          key: 'order_platform_number',
          align: 'center',
          sorter: true,
        },
        {
          title: this.props.langData['Expiry Date'],
          dataIndex: 'expiry_date',
          fieldName: 'expiry_date',
          fieldLabel: 'Expiry Date',
          align: 'center',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('expiry_date') === -1,
          sorter: true,
          render: value => moment(value).format('DD.MM.YYYY'),
        },
        {
          title: this.props.langData['Date'],
          dataIndex: 'date',
          fieldName: 'date',
          fieldLabel: 'Date',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('date') === -1,
          defaultSortOrder: 'descend',
          sorter: (a, b) => new Date(a.date) - new Date(b.date),
          align: 'center',
          render: value => moment(value).format('DD.MM.YYYY HH:mm'),
        },
        {
          title: this.props.langData['Discount'],
          dataIndex: 'discount',
          fieldName: 'discount',
          fieldLabel: 'Discount',
          align: 'center',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('discount') === -1,
          render: (value, record) => {
            return (
              <div>
                <span>{`${value ? value : ''}${record.discount_type ? record.discount_type : ''}`}</span>
              </div>
            )
          },
        },
        {
          title: this.props.langData['Country'],
          dataIndex: 'invoice_country',
          fieldName: 'invoice_country',
          fieldLabel: 'Country',
          align: 'center',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('invoice_country') === -1,
        },
        {
          title: this.props.langData['City'],
          dataIndex: 'invoice_city',
          fieldName: 'invoice_city',
          fieldLabel: 'City',
          align: 'center',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('invoice_city') === -1,
        },
        {
          title: this.props.langData['Total'],
          dataIndex: 'remaining',
          fieldName: 'remaining',
          fieldLabel: 'Total',
          align: 'right',
          width: 100 / (this.fields.length + 1) + '%',
          hide: this.fields.indexOf('remaining') === -1,
          sorter: true,
          render: (value, record) => {
            if (value !== undefined)
              return (
                <div>
                  <span>{`${toLocaleString(record.grand_total)} ${getCurrencyWithCode(record.currency).symbol}`}</span>
                  <br/>
                </div>
              )
          },
        },
      ],
      filter: this.props.filterData,
    })
    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }

    if (this.props.matchWaybillType)
      this.matchWaybill()
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({ filter: null })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
      type: this.props.langData[record.__typename === 'SalesInvoice' ? 'sales' : 'purchase'],
      id: record.transaction_id,
    }))
  }

  getVisibleColumnsFieldName = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide && x.fieldName !== '__typename').map(x => x.fieldName) : []
  }

  getVisibleColumnsSubField = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide && x.fieldName !== '__typename').map(x => x.subFields) : []
  }

  getQueryWithSubFields = (fields, subFields) => {
    let query = ''
    subFields.forEach((e, i) => {
      e = e === undefined ? '' : '{' + e + '}'
      query += fields[i] + ' ' + e
    })
    return query
  }

  getColumnsFieldNameWithLabel = () => {
    return this.state.columns ? this.state.columns.filter(x => x.fieldName !== '__typename').map(x => ({
      label: this.props.langData[x.fieldLabel],
      value: x.fieldName,
      key: x.key,
    })) : []
  }

  getVisibleRawColumns = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide || x.fieldName === '__typename') : []
  }

  multipleDelete = refetch => {
    confirm(undefined, result => {
      if ( ! result)
        return

      this.setState({
        selectedRows: this.state.selectedRows.map(item => delete item.code),
      })
      this.multipleDeleteData(this.state.selectedRows, refetch)
      this.setState({
        selectedRows: [],
        multipleRowsSelected: false,
      })
    })
  }

  copy = () => {
    this.props.history.push(generatePath(routes.INVOICE_COPY_NEW, {
      type: this.state.selectedRows[0].type === 'SalesInvoice' ? this.props.langData['sales'] : this.props.langData['purchase'],
      id: this.state.selectedRows[0].transaction_id,
    }))
  }

  matchWaybill = () => {
    let waybillType = this.props.matchWaybillType === 'sending' ? 'SalesInvoice' : 'PurchaseInvoice'
    this.setState({ filter: { AND: { type: { eq: waybillType } } } })
  }

  getSelectedTransactionIds = () => {
    return this.state.selectedRows.map(row => row.transaction_id)
  }

  shareInvoiceMail = async values => {
    const transactionIds = this.getSelectedTransactionIds()

    try {
      const result = await apolloClient.mutate({
        mutation: SEND_INVOICE_EMAIL_QUERY, variables: {
          mailInformations: {
            transaction_ids: transactionIds,
            is_send_current_mail_address: values.isSendCurrentMailAddress,
            mail_addresses: typeof values.mail_addresses !== 'undefined' ? values.mail_addresses : [],
          },
        },
      })
      toast(true, result.data.invoiceSendMailAsText.informative.messages)
      if ( ! result.data.invoiceSendMailAsText.incorrect_invoices || result.data.invoiceSendMailAsText.incorrect_invoices.length === 0) {
        this.setState({
          shareInvoiceMail: false,
        })
      } else {
        this.setState({
          shareInvoiceIncorrectInvoices: result.data.invoiceSendMailAsText.incorrect_invoices || [],
        })
      }
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  printInvoice = () => {
    const transactionIds = this.getSelectedTransactionIds()
    window.open(`${process.env.REACT_APP_NEW_API_URL}/invoice/printMultiple/${localStorage.token}/${transactionIds}/1x/v/${this.props.lang}`, '_blank')
  }

  shareEInvoiceMail = async payload => {
    const transactionIds = this.getSelectedTransactionIds()
    try {
      const result = await apolloClient
        .mutate({
          mutation: SEND_EINVOICE_MAIL_QUERY,
          variables: {
            mailInformations: {
              transaction_ids: transactionIds,
              e_mails: payload.to,
              content: payload.content,
              subject: payload.subject,
            },
          },
        })
      toast(true, result.data.salesInvoicesSendWithMailEInvoice.informative.messages)
      if (result.data.salesInvoicesSendWithMailEInvoice.incorrect_invoices.length === 0) {
        this.setState({
          shareEInvoiceMail: false,
        })
      } else {
        this.setState({
          shareEMailIncorrectInvoices: result.data.salesInvoicesSendWithMailEInvoice.incorrect_invoices,
        })
      }
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  createMultipleEInvoice = async refetch => {

    this.setState({
      create_einvoice_submitting: true,
    })

    return postPanelApi('/invoice/einvoice-create', {
      multiple: true,
      transaction_ids: this.getSelectedTransactionIds(),
      profile: 'TICARIFATURA',
    }).then(result => {

      if (result) {

        this.setState(prevState => ({
          createMultipleETransform: {
            ...prevState.createMultipleETransform,
            enabled: true,
            result: result.data.results,
            type: 'E-Invoice',
          },
        }))

        refetch()

      }

      this.setState({
        create_einvoice_submitting: false,
      })

    })

  }

  createMultipleEArchive = async (data, refetch) => {

    return postPanelApi('/invoice/einvoice-create', {
      multiple: true,
      transaction_ids: this.getSelectedTransactionIds(),
      profile: 'EARSIVFATURA',
      data: data,
    }).then(result => {

      if (result) {

        this.setState(prevState => ({
          createMultipleETransform: {
            ...prevState.createMultipleETransform,
            enabled: true,
            result: result.data.results,
            type: 'E-Archive',
          },
        }))

        refetch()

        // modal'a işin sorunsuz tamamlandığının bilgisini veriyoruz
        return true

      }

      // işlemin başarılı bir şekilde tamamlanmadığının bilgisini modal'a vermiş oluyoruz
      return false

    })

  }

  render() {
    return (
      <Query
        query={LIST_QUERY(this.getQueryWithSubFields(this.getVisibleColumnsFieldName(), this.getVisibleColumnsSubField()))}
        skip={ ! this.state.columns}
        fetchPolicy="network-only"
        variables={{ filter: this.state.filter, paging: this.state.paging, sorting: this.state.sorting }}
      >
        {({ loading: loadingQuery, error, data, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data) {
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
                data: cloneDeep(data.invoiceList.transactions),
              })
            } else if (this.state.data && JSON.stringify(data.invoiceList.transactions) !== JSON.stringify(this.state.data))
              this.setState({
                data: cloneDeep(data.invoiceList.transactions),
                pagination: {
                  pageSize: this.state.pagination.pageSize,
                  total: data.invoiceList.page_info.total_count,
                },
              })
          }
          return (
            <div>
              <FilterBox
                callback={this.filterCallback}
                isSubmit={loadingQuery}
                csv={createCsv.bind(this)}
                matchWaybillType={this.props.matchWaybillType}
                extractExcel={() => this.setState({ isExtractExcel: true })}
                {...this.props}
              />
              {this.state.isExtractExcel &&
                <ExcelExtractModal type="invoice" onCancel={() => this.setState({ isExtractExcel: false })}
                                   onOk={extractExcel.bind(this)} {...this.props} />}
              {this.state.shareInvoiceMail &&
                <ShareInvoiceMail onOk={this.shareInvoiceMail} onCancel={() => this.setState({
                  shareInvoiceMail: false,
                  shareInvoiceIncorrectInvoices: [],
                })}
                                  incorrectInvoices={this.state.shareInvoiceIncorrectInvoices}  {...this.props}
                                  validateMessages={this.props.langData.formValidationMessages}/>}

              {this.state.createMultipleETransform.enabled && (
                <ETransformResultModal
                  result={this.state.createMultipleETransform.result}
                  type={this.state.createMultipleETransform.type}
                  onCancel={() => {
                    this.setState({ createMultipleETransform: { enabled: false, result: [] } })
                  }}
                />
              )}

              {this.state.show_e_archive_create_modal && (
                <InvoiceEArchiveModal
                  langData={this.props.langData}
                  callback={data => this.createMultipleEArchive(data, refetch)}
                  onCancel={() => this.setState({ show_e_archive_create_modal: false })}
                  isMultiple={true}
                />
              )}
              {this.state.shareEInvoiceMail &&
                <ShareEInvoiceMailModal  {...this.props} onOk={this.shareEInvoiceMail}
                                         incorrectInvoices={this.state.shareEMailIncorrectInvoices}
                                         onCancel={() => {
                                           this.setState({ shareEInvoiceMail: false, shareEMailIncorrectInvoices: [] })
                                         }}/>}
              { ! this.props.modalClose &&
                <Row type="flex" justify="start" xs={24} sm={12} md={12} lg={this.props.sizeWidth >= 780 ? 24 : 12}
                     xl={this.props.sizeWidth >= 780 ? 24 : 12}>
                  <Col xs={12} sm={8} md={5} lg={5} xl={4}>
                    <Popover content={(
                      <div>
                        <Checkbox.Group options={this.getColumnsFieldNameWithLabel()} onChange={fields => {
                          this.onChangeField(fields)
                          refetch()
                        }} value={this.fields} style={{ width: 110 }}/>
                      </div>
                    )} trigger="click" placement="bottom">
                      <Button icon="menu" block={true} style={{ marginBottom: 10, width: '100%', borderRadius: 0 }}>
                        {this.props.langData['Customize']}
                      </Button>
                    </Popover>
                  </Col>
                  <Col xs={12} sm={8} md={5} lg={5} xl={4}>
                    <Link to={routes.INVOICE_NEW}><Button
                      style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                      icon="plus-circle">{this.props.langData['Add New One']}</Button></Link>
                  </Col>
                  {
                    this.state.multipleRowsSelected && ! this.props.matchWaybillType &&
                    <Col xs={12} sm={6} md={5} lg={5} xl={4}>
                      <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                              onClick={() => this.multipleDelete(refetch)}><Icon
                        type="delete"/>{this.props.langData['Delete']}</Button>
                    </Col>
                  }
                  {this.state.multipleRowsSelected && this.state.selectedRows.length == 1 && ! this.props.matchWaybillType &&
                    <Col xs={12} sm={6} md={5} lg={5} xl={4}>
                      <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                              onClick={() => this.copy()}><Icon type="copy"/>{this.props.langData['Copy']}</Button>
                    </Col>}
                  {
                    this.state.multipleRowsSelected &&
                    this.state.selectedRows.length <= 10 && <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                      <Tooltip placement="top" title={this.props.langData['You can send up to 10 invoices!']}>
                        <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                                onClick={this.printInvoice}><Icon type="copy"/>{this.props.langData['Print Invoice']}
                        </Button>
                      </Tooltip>
                    </Col>
                  }
                  {
                    this.state.multipleRowsSelected &&
                    this.state.selectedRows.length <= 10 && <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                      <Tooltip placement="top" title={this.props.langData['You can send up to 10 invoices!']}>
                        <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                                onClick={() => this.setState({ shareInvoiceMail: true })}><Icon
                          type="copy"/>{this.props.langData['Share Mail Invoice']}</Button>
                      </Tooltip>
                    </Col>
                  }
                  {
                    this.state.multipleRowsSelected &&
                    this.state.selectedRows.length <= 10 && <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                      <Tooltip placement="top" title={this.props.langData['You can send up to 10 invoices!']}>
                        <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true} onClick={() => {
                          this.setState({ shareEInvoiceMail: true })
                        }}><Icon type="copy"/>{this.props.langData['Share E-Invoice Mail']}</Button>
                      </Tooltip>
                    </Col>
                  }
                  {this.state.multipleRowsSelected && (
                    <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                      <Button
                        style={{ marginBottom: 10, borderRadius: 0 }}
                        block={true}
                        onClick={() => this.createMultipleEInvoice(refetch)}
                        disabled={this.state.create_einvoice_submitting}
                      >
                        <Icon type="file-protect"/>{this.props.langData['Create E-Invoice']}
                      </Button>
                    </Col>
                  )}
                  {this.state.multipleRowsSelected && (
                    <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                      <Button
                        style={{ marginBottom: 10, borderRadius: 0 }}
                        block={true}
                        onClick={() => this.setState({ show_e_archive_create_modal: true })}
                      >
                        <Icon type="file-protect"/>{this.props.langData['Create E-Archive']}
                      </Button>
                    </Col>
                  )}
                </Row>
              }
              <Table
                columns={this.getVisibleRawColumns()}
                rowKey={record => record.transaction_id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={loadingQuery}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => {
                    if ( ! this.props.rowItemClick)
                      return this.rowItemClick(record)
                    this.props.rowItemClick(record)
                  },
                })}
                rowSelection={! this.props.modalClose ? {
                  selectedRowKeys: this.state.selectedRows.map(x => x.transaction_id),
                  onChange: (selectedRowKeys, selectedRows) => {
                    this.setState({
                      selectedRows: selectedRows.map(row => ({
                        transaction_id: row.transaction_id,
                        type: row.__typename,
                        code: row.code,
                      })),
                      multipleRowsSelected: selectedRows.length > 0,
                    })
                  },
                  columnWidth: 60,
                } : ''}
                rowClassName="white"
                scroll={{
                  x: 700,
                  y: ! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)',
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

InvoiceList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
  email_verification: PropTypes.bool,
  filterData: PropTypes.func,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.object,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.object,
  sizeHeight: PropTypes.number,
  scrollHeight: PropTypes.number,
  modalClose: PropTypes.func,
  matchWaybillType: PropTypes.string,
  rowItemClick: PropTypes.func,
  filter: PropTypes.func,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isSubmit !== nextProps.isSubmit)
      this.setState({ isSubmit: nextProps.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

const CATEGORY_LIST_QUERY = (
  gql`
    {
      invoiceCategoryList {
        category_id
        name
      }
    }
  `
)

const CUSTOMER_LIST_QUERY = (
  gql`
    query($filter: CustomerFilterBase){
      customerList(filter: $filter){
        customers{
          customer_id
          name
          e_mail
        }
        page_info{
          total_count
        }
      }
    }
  `
)

const CUSTOMER_READ = (
  gql`
    query($customer_id: Int!){
      customer(customer_id: $customer_id) {
        customer_id
        name
        e_mail
      }
    }
  `
)

class FilterBoxForm extends Component {

  state = {
    selectedOptions: [],
    customerList: [],
    categoryList: null,
    isSubmit: false,
    items: [
      {
        value: 'customer_id',
        required: true,
        width: 300,
        operator: 'eq',
      },
      {
        value: 'e_mail',
        label: this.props.langData['E-Mail'],
        width: 300,
        operator: 'eq',
      },
      {
        value: 'type',
        label: this.props.langData['Type'],
        added: false,
        width: 300,
        operator: 'eq',
      },
      {
        value: 'code',
        label: this.props.langData['Code'],
        width: 300,
        operator: 'contains',
        added: false,
      },
      {
        value: 'category_id',
        label: this.props.langData['Category'],
        width: 300,
        operator: 'eq',
        added: false,
      },
      {
        value: 'date',
        label: this.props.langData['Date'],
        width: 400,
        operator: 'date',
        added: false,
      },
      {
        value: 'expiry_date',
        label: this.props.langData['Expiry Date'],
        width: 400,
        operator: 'date',
        added: false,
      },
      {
        value: 'status',
        label: this.props.langData['Status'],
        width: 300,
        added: false,
      },
      {
        value: 'einvoice_or_earchive_created',
        label: this.props.langData['E-Invoice or E-Archive Created'],
        width: 300,
        added: false,
      },
      {
        value: 'order_platform_number',
        label: this.props.langData['Marketplace Order No'],
        width: 300,
        operator: 'contains',
        added: false,
      },
      {
        value: 'invoice_address',
        label: this.props.langData['Marketplace Customer'],
        width: 300,
        operator: 'contains',
        added: false,
      },
    ],
    open_sales_invoice_settings_modal: false,
    open_purchase_invoice_settings_modal: false,
    start_date: moment().add(-1, 'M'),
    end_date: moment(),
  }

  componentDidMount() {
    this.props.filterFieldsData && Object.keys(this.props.filterFieldsData).forEach(x => {
      this.state.items.find(y => y.value === x).added = true
      this.props.form.getFieldDecorator(x, { initialValue: this.props.filterFieldsData[x] })
    })
    if (this.props.filterFieldsData) {
      if (this.props.filterFieldsData.customer_id) {
        this.getCustomerInformation(this.props.filterFieldsData.customer_id)
      }
    }
  }

  getCustomerInformation = async id => {
    try {
      let customerResult = await apolloClient.query({ query: CUSTOMER_READ, variables: { customer_id: id } })
      this.setState({
        customerList: [{
          customer_id: customerResult.data.customer.customer_id,
          name: customerResult.data.customer.name,
          e_mail: customerResult.data.customer.e_mail,
        }],
      })
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items: items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }

  onSubmit = form => {

    form.validateFields((err, values) => {

      if (err) {
        return
      }

      this.setState({ isSubmit: true })

      deleteEmpty(values)

      let filterData = []

      for (const [key, value] of Object.entries(values)) {

        const item = this.state.items.find(x => x.value === key)

        if (key === 'status') {
          filterData.push({
            remaining: {
              [value]: 0,
            },
          })
        } else if (key === 'einvoice_or_earchive_created') {
          filterData.push({
            e_invoice_id: {
              null_or_empty: value,
            },
          })
        } else if (item.operator === 'date') {
          filterData.push({
            [key]: {
              gte: moment(value[0]).format('YYYY-MM-DDT00:00:00.000Z'),
              lte: moment(value[1]).format('YYYY-MM-DDT23:59:00.000Z'),
            },
          })
        } else {

          let newValue = value

          if (key === 'invoice_address') {
            newValue = ('--> ').concat(value)
          }

          filterData.push({
            [key]: {
              [item.operator]: newValue,
            },
          })

        }

      }

      this.props.callback(filterData.length ? filterData : null, values)

    })
  }

  customerValue = null

  searchCustomer = async value => {
    if (this.customerValue === value)
      return
    this.customerValue = value
    if (value.length < 2)
      return
    try {
      let customerList = await apolloClient.query({
        query: CUSTOMER_LIST_QUERY,
        variables: { filter: { AND: [{ name: { contains: value } }] } },
      })
      this.setState({
        customerList: customerList.data.customerList.customers,
      })

      if ( ! customerList.data.customerList.page_info.total_count)
        this.props.form.setFieldsValue({
          customer_id: null,
        })
    } catch (e) {
      toast(false, this.props.langData['Server error'])
    }
  }

  customerEmailValue = null

  searchCustomerEmail = async value => {
    if (this.customerEmailValue === value)
      return
    this.customerEmailValue = value
    if (value.length < 2)
      return
    try {
      let customerList = await apolloClient.query({
        query: CUSTOMER_LIST_QUERY,
        variables: { filter: { AND: [{ e_mail: { contains: value } }] } },
      })
      this.setState({
        customerList: customerList.data.customerList.customers,
      })
      if ( ! customerList.data.customerList.page_info.total_count)
        this.props.form.setFieldsValue({
          customer_id: null,
        })
    } catch (e) {
      toast(false, this.props.langData['Server error'])
    }
  }

  setExportExcelOfSalesInvoicesModal = (isOpen) => {
    this.setState({
      open_sales_invoice_settings_modal: isOpen,
    })
  }

  setExportExcelOfPurchaseInvoicesModal = (isOpen) => {
    this.setState({
      open_purchase_invoice_settings_modal: isOpen,
    })
  }

  exportExcelOfSalesInvoices = () => {
    window.open(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/salesinvoice/list/pricedetail/excel/${localStorage.token}/${moment(this.state.start_date).format('YYYY-MM-DD')}/${moment(this.state.end_date).format('YYYY-MM-DD')}`, '_blank')
    this.setExportExcelOfSalesInvoicesModal(false)
  }

  exportExcelOfPurchaseInvoices = () => {
    window.open(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/purchaseinvoice/list/pricedetail/excel/${localStorage.token}/${moment(this.state.start_date).format('YYYY-MM-DD')}/${moment(this.state.end_date).format('YYYY-MM-DD')}`, '_blank')
    this.setExportExcelOfPurchaseInvoicesModal(false)
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []
    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      ! this.props.matchWaybillType &&
      <Popover placement="bottomLeft" content={
        <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
          <Button key="1" onClick={this.props.csv} style={{
            width: '-webkit-fill-available',
            marginBottom: '10px',
          }}>{this.props.langData['Csv Export']}</Button>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
            <Button key="2" onClick={() => this.props.extractExcel(true)}
                    style={{ width: '-webkit-fill-available', marginBottom: '10px' }}>
              {this.props.langData['Xlsx Export']}
            </Button>
          </Row>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
            <Button key="3" style={{ width: '100%' }} onClick={() => this.setExportExcelOfSalesInvoicesModal(true)}>
              {this.props.langData['Export Sales Invoices']}
            </Button>
          </Row>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
            <Button key="3" style={{ width: '100%', marginTop: '10px' }}
                    onClick={() => this.setExportExcelOfPurchaseInvoicesModal(true)}>
              {this.props.langData['Export Purchase Invoices']}
            </Button>
          </Row>
        </Row>
      } trigger="click">
        <Button><Icon type="file-unknown"/></Button>
      </Popover>,

      <CheckboxedDropdown
        key="2"
        width={280}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedOptions}
        onChange={this.onChangeCheckbox}
      />,

    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let content = [...filterHeader]

    if (this.state.items.filter(x => x.value === 'type' && x.added).length) {
      content.push(
        (
          getFieldDecorator('type')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'type')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              key="5"
              placeholder={this.props.langData['Type']}
            >
              <Select.Option value="SalesInvoice">{this.props.langData['Sales Invoice']}</Select.Option>
              <Select.Option value="PurchaseInvoice">{this.props.langData['Purchase Invoice']}</Select.Option>
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'category_id' && x.added).length) {
      content.push(
        (
          getFieldDecorator('category_id')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'category_id')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              key="6"
              placeholder={this.props.langData['Category']}
            >
              {this.state.categoryList && this.state.categoryList.map(x => <Select.Option value={x.category_id}
                                                                                          key={x.category_id}>{x.name}</Select.Option>)}
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('date')(
            <DatePicker.RangePicker key="77" format="DD-MM-YYYY"
                                    style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'date')[0].width : null }}
                                    allowClear/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'expiry_date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('expiry_date')(
            <DatePicker.RangePicker key="88" format="DD-MM-YYYY"
                                    style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'expiry_date')[0].width : null }}
                                    allowClear/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'code' && x.added).length) {
      content.push(
        (
          getFieldDecorator('code')(
            <Input allowClear placeholder={this.props.langData['Code']} key="10"
                   style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'code')[0].width : null }}/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'status' && x.added).length) {
      content.push(
        (
          getFieldDecorator('status')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'status')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              placeholder={this.props.langData['Status']}
              key="8"
            >
              <Select.Option value="eq">{this.props.langData['Paid']}</Select.Option>
              <Select.Option value="gt">{this.props.langData['Unpaid']}</Select.Option>
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'einvoice_or_earchive_created' && x.added).length) {
      content.push(
        (
          getFieldDecorator('einvoice_or_earchive_created')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'einvoice_or_earchive_created')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              placeholder={this.props.langData['E-Invoice or E-Archive Created']}
              key="8"
            >
              <Select.Option value={false}>{this.props.langData['Yes']}</Select.Option>
              <Select.Option value={true}>{this.props.langData['No']}</Select.Option>
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'order_platform_number' && x.added).length) {
      content.push(
        (
          getFieldDecorator('order_platform_number')(
            <Input
              placeholder={this.props.langData['Marketplace Order No']}
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'order_platform_number')[0].width : null }}
              maxLength={50}
              allowClear
            />,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'invoice_address' && x.added).length) {
      content.push(
        (
          getFieldDecorator('invoice_address')(
            <Input
              placeholder={this.props.langData['Marketplace Customer']}
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'invoice_address')[0].width : null }}
              maxLength={50}
              allowClear
            />,
          )
        ),
      )
    }

    content.push((
      getFieldDecorator('customer_id')(
        <Select
          filterOption={false}
          defaultActiveFirstOption={false}
          suffixIcon={<Icon type="caret-down"/>}
          showSearch
          notFoundContent={this.state.fetchingCustomer ? <Spin/> : null}
          onSearch={this.searchCustomer}
          placeholder={this.props.langData['Customer']}
          key="7"
          style={{ width: '100%' }}
          allowClear
        >
          {this.state.customerList.map((x, k) => {
            return <Select.Option value={x.customer_id} key={k}>{x.name}</Select.Option>
          })}
        </Select>,
      )
    ))

    if (this.state.items.filter(x => x.value === 'e_mail' && x.added).length) {
      content.push((
        getFieldDecorator('customer_id')(
          <Select
            filterOption={false}
            defaultActiveFirstOption={false}
            suffixIcon={<Icon type="caret-down"/>}
            showSearch
            notFoundContent={this.state.fetchingCustomer ? <Spin/> : null}
            onSearch={this.searchCustomerEmail}
            placeholder={this.props.langData['E-Mail']}
            key="22"
            style={{ width: '100%' }}
            allowClear
          >
            {this.state.customerList.map((x, k) => {
              return <Select.Option value={x.customer_id} key={k}>{x.e_mail}</Select.Option>
            })}
          </Select>,
        )
      ))
    }

    if (this.state.open_sales_invoice_settings_modal) {
      return (
        <Modal title={this.props.langData['Export Sales Invoices']}
               visible={this.state.open_sales_invoice_settings_modal} width={500} footer={null}
               onCancel={() => this.setExportExcelOfSalesInvoicesModal(false)}>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24" align="center">
            <Col sm={24} md={12}>
              <div>{this.props.langData['Start Date']}</div>
              <DatePicker format="DD-MM-YYYY" defaultValue={this.state.start_date} onChange={event =>
                this.setState({
                  start_date: event,
                })
              }/>
            </Col>
            <Col sm={24} md={12}>
              <div>{this.props.langData['End Date']}</div>
              <DatePicker format="DD-MM-YYYY" defaultValue={this.state.end_date} onChange={event =>
                this.setState({
                  end_date: event,
                })
              }/>
            </Col>
            <div>
              <Button onClick={this.exportExcelOfSalesInvoices}
                      style={{ width: '-webkit-fill-available', marginTop: '20px' }}>
                {this.props.langData['Create Excel']}
              </Button>
            </div>
          </Row>
        </Modal>
      )
    }

    if (this.state.open_purchase_invoice_settings_modal) {
      return (
        <Modal title={this.props.langData['Export Purchase Invoices']}
               visible={this.state.open_purchase_invoice_settings_modal} width={500} footer={null}
               onCancel={() => this.setExportExcelOfPurchaseInvoicesModal(false)}>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24" align="center">
            <Col sm={24} md={12}>
              <div>{this.props.langData['Start Date']}</div>
              <DatePicker format="DD-MM-YYYY" defaultValue={this.state.start_date} onChange={event =>
                this.setState({
                  start_date: event,
                })
              }/>
            </Col>
            <Col sm={24} md={12}>
              <div>{this.props.langData['End Date']}</div>
              <DatePicker format="DD-MM-YYYY" defaultValue={this.state.end_date} onChange={event =>
                this.setState({
                  end_date: event,
                })
              }/>
            </Col>
            <div>
              <Button onClick={() => this.exportExcelOfPurchaseInvoices()}
                      style={{ width: '-webkit-fill-available', marginTop: '20px' }}>
                {this.props.langData['Create Excel']}
              </Button>
            </div>
          </Row>
        </Modal>
      )
    }

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={CATEGORY_LIST_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading: loadingQuery, data, error }) => {
            if (error) {
              graphQLErrorsToToast(error)
              return null
            }
            if ( ! loadingQuery && ! this.state.categoryList)
              this.setState({ categoryList: cloneDeep(data.invoiceCategoryList) })
            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {content.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )
    return (
      <Query
        query={CATEGORY_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.categoryList)
            this.setState({ categoryList: cloneDeep(data.invoiceCategoryList) })
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {content.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )

  }

}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isSubmit: PropTypes.bool,
  csv: PropTypes.func,
  multipleDelete: PropTypes.func,
  colOptions: PropTypes.array,
  colFields: PropTypes.array,
  colOnChange: PropTypes.func,
  filterFieldsData: PropTypes.object,
  matchWaybillType: PropTypes.string,
  extractExcel: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
