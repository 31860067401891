import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import AdditionalChargeForm from '../AdditionalChargeForm/AdditionalChargeForm'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Copy Additional Charge', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class AdditionalChargeCopy extends Component {

  additional_charge_copy_id

  constructor(props) {
    super(props)
    this.additional_charge_copy_id = parseInt(props.additional_charge_copy_id)
  }


  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.ADDITIONAL_CHARGE_COPY, {
      id: this.additional_charge_copy_id,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const WrappedAdditionalChargeCopyForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(AdditionalChargeForm)
    return <WrappedAdditionalChargeCopyForm {...this.props}
                                            additional_charge_copy_id={this.additional_charge_copy_id}/>
  }
}

AdditionalChargeCopy.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  additional_charge_copy_id: PropTypes.number,
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalChargeCopy)
