import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SubProductForm from './../SubProductForm/SubProductForm'
import { connect } from 'react-redux'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

class NewSubProduct extends Component {

  main_product_id = null

  constructor(props) {
    super(props)

    this.main_product_id = parseInt(this.props.match.params.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedSubProductForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(SubProductForm)
    return <WrappedSubProductForm main_product_id={this.main_product_id} {...this.props}></WrappedSubProductForm>
  }

}

NewSubProduct.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  isModal: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps)(NewSubProduct)
