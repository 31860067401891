import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Icon, Form, Input, Button, Divider, Select } from 'antd'

import { formErrorFieldsNameLocalize, deleteEmpty, successToast } from '../../../../../helpers/helper'

import { postPanelApi } from '../../../../../api'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

class IntegrationSettingsForm extends Component {

  state = {
    uriBefore: 'https://',

    operation_in_progress: false,
  }

  componentDidMount() {

    postPanelApi('/settings/integration-settings-open').then(result => {

      if (result) {

        this.setState({
          uriBefore: result.tahsildar.store_uri ? new URL(result.tahsildar.store_uri).protocol + '//' : 'https://',
        })

        this.props.form.setFieldsValue({
          'digital_planet[corporate_code]': result.digital_planet.corporate_code,
          'digital_planet[login_name]': result.digital_planet.login_name,
          'digital_planet[password]': result.digital_planet.password,
          'n11_faturam[corporate_code]': result.n11_faturam.corporate_code,
          'n11_faturam[login_name]': result.n11_faturam.login_name,
          'n11_faturam[password]': result.n11_faturam.password,
          'tahsildar_ws[store_uri]': result.tahsildar.store_uri && new URL(result.tahsildar.store_uri).hostname.replace('.tahsildar.com.tr', ''),
          'tahsildar_ws[ws_username]': result.tahsildar.ws_username,
          'tahsildar_ws[ws_password]': result.tahsildar.ws_password,
        })

      }

    })

  }

  onSubmitDigitalPlanet = () => {

    this.props.form.validateFieldsAndScroll(['digital_planet'], (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      this.setState({
        operation_in_progress: true,
      })

      postPanelApi('/settings/digital-planet-settings-update', values.digital_planet).then(result => {

        if (result) {

          successToast(this.props.langData['Integration Settings Success'])

        }

        this.setState({
          operation_in_progress: false,
        })

      })

    })

  }

  onSubmitN11Faturam = () => {

    this.props.form.validateFieldsAndScroll(['n11_faturam'], (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      this.setState({
        operation_in_progress: true,
      })

      postPanelApi('/settings/n11-faturam-settings-update', values.n11_faturam).then(result => {

        if (result) {

          successToast(this.props.langData['Integration Settings Success'])

        }

        this.setState({
          operation_in_progress: false,
        })

      })

    })

  }

  onSubmitTahsildar = () => {

    this.props.form.validateFieldsAndScroll(['tahsildar_ws'], (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      this.setState({
        operation_in_progress: true,
      })

      values.tahsildar_ws.store_uri = this.state.uriBefore.concat(values.tahsildar_ws.store_uri).concat('.tahsildar.com.tr')

      postPanelApi('/settings/tahsildar-settings-update', values.tahsildar_ws).then(result => {

        if (result) {

          successToast(result.message)

        }

        this.setState({
          operation_in_progress: false,
        })

      })

    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        title={<span><Icon
          type="control"/>&nbsp; {this.props.langData['Integration Web Service Settings']}</span>}
        bordered={false}
      >
        <Form
          hideRequiredMark={true}
          className="form-label-default"
          onSubmit={e => {
            e.preventDefault()
            this.onSubmitDigitalPlanet()
          }}
        >

          <div className="form-block">

            <Divider>Digital Planet</Divider>

            <Form.Item label={(
              <span><Icon type="book"/><p>{this.props.langData['Corporate Code']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('digital_planet[corporate_code]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="user"/><p>{this.props.langData['Login Name']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('digital_planet[login_name]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="lock"/><p>{this.props.langData['Password']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('digital_planet[password]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input.Password size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <div className="form-block">
              <Form.Item {...formItemLayoutNoLabel}>
                <Button
                  disabled={this.state.operation_in_progress}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="default-button"
                >
                  {this.props.langData['Save']}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>

        <Form
          hideRequiredMark={true}
          className="form-label-default"
          onSubmit={e => {
            e.preventDefault()
            this.onSubmitN11Faturam()
          }}
        >

          <div className="form-block">

            <Divider>N11 Faturam</Divider>

            <Form.Item label={(
              <span><Icon type="book"/><p>{this.props.langData['Corporate Code']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('n11_faturam[corporate_code]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="user"/><p>{this.props.langData['Login Name']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('n11_faturam[login_name]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="lock"/><p>{this.props.langData['Password']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('n11_faturam[password]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input.Password size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <div className="form-block">
              <Form.Item {...formItemLayoutNoLabel}>
                <Button
                  disabled={this.state.operation_in_progress}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="default-button"
                >
                  {this.props.langData['Save']}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>

        <Form
          hideRequiredMark={true}
          className="form-label-default"
          onSubmit={e => {
            e.preventDefault()
            this.onSubmitTahsildar()
          }}
        >
          <div className="form-block">
            <Divider>Tahsildar</Divider>

            <Form.Item label={(
              <span><Icon type="user"/><p>{this.props.langData['Site Address']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('tahsildar_ws[store_uri]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input addonBefore={
                  <Select value={this.state.uriBefore ? this.state.uriBefore : 'https://'}
                          onChange={val => this.setState({ uriBefore: val })}>
                    <Select.Option value="https://">https://</Select.Option>
                    <Select.Option value="http://">http://</Select.Option>
                  </Select>
                } addonAfter=".tahsildar.com.tr" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="book"/><p>{this.props.langData['Username']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('tahsildar_ws[ws_username]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="lock"/><p>{this.props.langData['Password']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('tahsildar_ws[ws_password]', {
                rules: [{ max: 100, required: true }], validateTrigger: false,
              })(
                <Input.Password size="large" maxLength={100}/>,
              )}
            </Form.Item>

            <div className="form-block">
              <Form.Item {...formItemLayoutNoLabel}>
                <Button
                  disabled={this.state.operation_in_progress}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="default-button"
                >
                  {this.props.langData['Save']}
                </Button>
              </Form.Item>
            </div>
          </div>

        </Form>

      </Card>
    )
  }
}

IntegrationSettingsForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
}

export default connect(mapStateToProps, null)(IntegrationSettingsForm)
