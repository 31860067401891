import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { Button, Card, Icon, Table, Tag } from 'antd'
import { cloneDeep } from 'lodash'
import { generatePath } from 'react-router-dom'

import 'moment/locale/tr'

import routes from '../../../routes'

import CustomerTransactionsExport from '../CustomerTransactionsExport'

import { errorToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode } from '../../../data/Currency'

const LIST_QUERY = gql`
    query ($customer_id: Int!, $paging: Paging) {
        customerTransactionList(
            filter: {
                AND: [
                    {
                        OR: [
                            { account_opening_customer: { customer_id: { eq: $customer_id }  } }
                            { credit: { customer_id: { eq: $customer_id }  } }
                            { debit: { customer_id: { eq: $customer_id }  } }
                            { sales_invoice: { customer_id: { eq: $customer_id }  } }
                            { purchase_invoice: { customer_id: { eq: $customer_id }  } }
                            { return_sales_invoice : { customer_id : {eq: $customer_id } } }
                            { return_purchase_invoice : { customer_id : {eq: $customer_id } } }
                        ]
                    }
                ]
            }
            sorting: {
                field: date
                type: DESC
            }
            paging: $paging
        ){
            transactions {
                __typename
                ... on AccountOpeningCustomer {
                    transaction_id
                    type
                    amount
                    date
                    currency
                }
                ... on Credit {
                    transaction_id
                    amount
                    date
                    safe {
                        name
                        currency
                    }
                    explanation
                    commits {
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        invoice: purchase_invoice {
                            currency
                        }
                    }
                }
                ... on Debit {
                    transaction_id
                    amount
                    date
                    safe {
                        name
                        currency
                    }
                    explanation
                    commits {
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        invoice: sales_invoice {
                            currency
                        }
                    }
                }
                ... on SalesInvoice {
                    transaction_id
                    grand_total
                    date
                    currency
                    explanation
                    commits {
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        paid {
                            __typename
                            ... on Debit {
                                safe {
                                    name
                                    currency
                                }
                            }
                            ... on AccountOpeningCustomer {
                                currency
                            }
                        }
                    }
                }
                ... on PurchaseInvoice {
                    transaction_id
                    grand_total
                    date
                    currency
                    explanation
                    commits {
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        paid {
                            __typename
                            ... on Credit {
                                safe {
                                    name
                                    currency
                                }
                            }
                            ... on AccountOpeningCustomer {
                                currency
                                amount
                            }
                        }
                    }
                }
                ... on ReturnSalesInvoice {
                    transaction_id
                    grand_total
                    currency
                    explanation
                    date
                    commits{
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        invoice: return_sales_invoice {
                            currency
                        }
                        paid {
                            __typename
                            ... on Credit {
                                safe {
                                    name
                                    currency
                                }
                            }
                            ... on AccountOpeningCustomer {
                                currency
                                amount
                            }
                        }
                    }

                }
                ... on ReturnPurchaseInvoice {
                    transaction_id
                    currency
                    grand_total
                    explanation
                    date
                    commits {
                        invoice_transaction_id
                        paid_transaction_id
                        currency_amounts {
                            try
                            usd
                            eur
                            gbp
                            rub
                        }
                        invoice: return_purchase_invoice {
                            currency
                        }
                        paid {
                            __typename
                            ... on Debit {
                                safe {
                                    name
                                    currency
                                }
                            }
                            ... on AccountOpeningCustomer {
                                currency
                                amount
                            }
                        }
                    }
                }
            }
            page_info {
                total_count
            }
        }
    }`

function TransactionList({ customerId, customerBalances, totalCustomerBalance, langData, lang, history }) {

  const pageSize = 50

  const localCustomerBalances = cloneDeep(customerBalances)

  const [currentPage, setCurrentPage] = useState(1)

  const [showingExtractFile, setShowingExtractFile] = useState(false)

  const { loading, error, data } = useQuery(LIST_QUERY, {
    variables: {
      customer_id: customerId,
      paging: {
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
      },
    },
  })

  const columns = [
    {
      title: langData['Transaction Type'],
      dataIndex: '__typename',
      width: '18%',
      render: (value, record) => {
        if (value === 'ReturnSalesInvoice')
          return <Tag color="#AFB42B" style={{ marginRight: 0 }}>{langData['Return Sales Invoice']}</Tag>
        if (value === 'ReturnPurchaseInvoice')
          return <Tag color="#FF6D00" style={{ marginRight: 0 }}>{langData['Return Purchase Invoice']}</Tag>
        if (value === 'AccountOpeningCustomer')
          return <Tag color={record.type === 'Debit' ? '#1890ff' : '#ff4d4f'}
                      style={{ marginRight: 0 }}>{langData['Account Opening']}</Tag>
        if (value === 'Credit')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{langData['Credit']}</Tag>
        if (value === 'Debit')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{langData['Debit']}</Tag>
        if (value === 'SalesInvoice')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{langData['Sales Invoice']}</Tag>
        if (value === 'PurchaseInvoice')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{langData['Purchase Invoice']}</Tag>
      },
    },
    {
      title: langData['Safe'],
      dataIndex: 'safe.name',
      width: '15%',
    },
    {
      title: langData['Date'],
      dataIndex: 'date',
      width: '22%',
      render: value => (
        moment(value).locale(lang).format('DD MMMM YYYY H:mm')
      ),
    },
    {
      title: langData['Explanation'],
      dataIndex: 'explanation',
      width: '15%',
    },
    {
      title: langData['Amount'],
      width: '15%',
      className: 'text-right',
      render: (value, data) => {
        return `
					${data.amount ? toLocaleString(data.amount) : toLocaleString(data.grand_total)}
					${data.safe ? getCurrencyWithCode(data.safe.currency).symbol : getCurrencyWithCode(data.currency).symbol}
				`
      },
    },
    {
      title: langData['Balance'],
      dataIndex: 'balance',
      width: '15%',
      className: 'text-right',
      render: value => {
        let text = ''
        Object.keys(value).map(x => {
          if (value[x] && x !== '__typename') {
            text += `${toLocaleString(value[x])} ${getCurrencyWithCode(x).symbol}, `
          }
        })
        if (text === '') {
          return <p>-</p>
        }

        return <span>{text.slice(0, -2)}</span>
      },
    },
  ]

  if (error) {
    errorToast(error.message)
    return null
  }

  // const { page_info, transactions } = data.customerTransactionList
  const { transactions } = data ? data.customerTransactionList : { transactions: [] }

  /*
  const pagination = {
    disabled: true,
    current: currentPage,
    pageSize: pageSize,
    total: page_info.total_count,
    showTotal: total => `${langData['Total']} ${total} ${langData['items']}`
  }
   */

  const rowItemClick = record => {
    let typeName
    let id = record.transaction_id
    if (record.__typename === 'AccountOpeningCustomer') {
      typeName = 'customer-account-opening'
      id = customerId
    } else if (record.__typename === 'Credit') {
      typeName = 'credit'
    } else if (record.__typename === 'Debit') {
      typeName = 'debit'
    } else if (record.__typename === 'SalesInvoice') {
      typeName = 'sales'
    } else if (record.__typename === 'PurchaseInvoice') {
      typeName = 'purchase'
    } else if (record.__typename === 'ReturnSalesInvoice') {
      typeName = 'salesDetail'
    } else if (record.__typename === 'ReturnPurchaseInvoice') {
      typeName = 'purchaseDetail'
    }

    if (record.__typename === 'AccountOpeningCustomer' || record.__typename === 'Credit' || record.__typename === 'Debit') {
      history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
        id,
        type: typeName,
      }))
    } else if (record.__typename === 'ReturnSalesInvoice' || record.__typename === 'ReturnPurchaseInvoice') {

      if (typeName === 'purchaseDetail') {
        history.push(generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
          id: id,
        }))
      } else {
        history.push(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
          type: typeName,
          id,
        }))
      }

    } else {
      history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
        type: langData[typeName],
        id,
      }))
    }
  }

  let previousType
  let previousBalance = { try: 0, usd: 0, eur: 0, gbp: 0, rub: 0 }

  const transaction2s = cloneDeep(transactions)

  transaction2s.forEach((transaction, index) => {
    if (previousType) {
      if (previousType === 'Debit' || previousType === 'PurchaseInvoice') {
        localCustomerBalances.try += previousBalance.try
        localCustomerBalances.usd += previousBalance.usd
        localCustomerBalances.eur += previousBalance.eur
        localCustomerBalances.gbp += previousBalance.gbp
        localCustomerBalances.rub += previousBalance.rub
      } else {
        localCustomerBalances.try -= previousBalance.try
        localCustomerBalances.usd -= previousBalance.usd
        localCustomerBalances.eur -= previousBalance.eur
        localCustomerBalances.gbp += previousBalance.gbp
        localCustomerBalances.rub += previousBalance.rub
      }
    }
    transaction2s[index].balance = Object.assign({}, localCustomerBalances)
    let currency = transaction.safe ? transaction.safe.currency : transaction.currency
    previousBalance = { try: 0, usd: 0, eur: 0, gbp: 0, rub: 0 }
    previousBalance[currency] = transaction.amount || transaction.grand_total

    transaction.commits && transaction.commits.forEach(commit => {
      const commit_currency = commit.invoice ? commit.invoice.currency : commit.paid && commit.paid.safe ? commit.paid.safe.currency : commit.paid && commit.paid.currency ? commit.paid.currency : ''
      if (currency !== commit_currency) {
        let commitIndex = -1
        for (let i in transaction2s) {
          if (transaction2s[index].transaction_id === commit[commit.paid ? 'paid_transaction_id' : 'invoice_transaction_id']) {
            commitIndex = i
            break
          }
        }
        if (commitIndex === -1 || commitIndex > index) {
          previousBalance[currency] -= commit.currency_amounts[currency]
          previousBalance[commit_currency] += commit.currency_amounts[commit_currency]
        }
      }
    })

    if (transaction.__typename !== 'AccountOpeningCustomer')
      previousType = transaction.__typename
    else
      previousType = transaction.type
  })

  return (
    <div>

      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        style={{ marginTop: 10 }}
        title={
          <span><Icon type="swap"/>
            &nbsp; {langData['Transaction List']}</span>
        }
        extra={(
          transaction2s && transaction2s.length > 0 &&
          <Button type="primary" onClick={() => setShowingExtractFile(true)}
                  style={{ float: 'right', margin: '5px' }}>{langData['Customer Transaction Extract']}</Button>
        )}
      >

        <Table
          columns={columns}
          rowKey={record => record.transaction_id}
          dataSource={transaction2s}
          pagination={false}
          loading={loading}
          onChange={(pagination) => setCurrentPage(pagination.current)}
          onRow={(record) => ({
            onClick: () => rowItemClick(record),
          })}
          style={{ borderRadius: '0px' }}
          rowClassName="white"
          scroll={{ x: 700, y: 500 }}
        />

      </Card>

      {showingExtractFile && (
        <CustomerTransactionsExport
          customerId={customerId}
          langData={langData}
          lang={lang}
          onCancel={() => setShowingExtractFile(false)}
        />
      )}

    </div>
  )

}

TransactionList.propTypes = {
  customerId: PropTypes.number,
  customerBalances: PropTypes.object,
  totalCustomerBalance: PropTypes.number,
  langData: PropTypes.object,
  lang: PropTypes.string,
  history: PropTypes.object,
}

export default TransactionList
