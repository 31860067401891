import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Card, Divider, Select, Switch } from 'antd'
import { generatePath } from 'react-router-dom'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

import routes from '../../routes'

import { currencyData } from '../../data/Currency'

import { postPanelApi } from '../../api'
import { formErrorFieldsNameLocalize, updateEmptyAndUndefined, successToast } from '../../helpers/helper'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

class UpdateSafeForm extends Component {

  state = {
    loading: false,
    submitting: false,
  }

  componentDidMount() {

    this.setState({
      loading: true,
    })

    postPanelApi('/safe/update-open', { safe_id: this.props.safe_id }).then(result => {

      this.setState({
        loading: false,
      })

      if (result) {
        this.props.form.setFieldsValue(result.form)
      }

    })

  }

  onSubmit = () => {

    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      // para birimini göndermeye gerek yok
      delete values.currency_id

      this.setState({
        submitting: true,
      })

      postPanelApi('/safe/update-save', { ...values, safe_id: this.props.safe_id }).then(result => {

        this.setState({
          submitting: false,
        })

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, {
            id: this.props.safe_id,
          }))

        }

      })

    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Card
        loading={this.state.loading}
        bodyStyle={{ padding: '0px' }}
        title={
          <span><EditOutlined/> &nbsp;{this.props.langData['Update Safe']}</span>
        }
        bordered={false}
      >

        <Form
          hideRequiredMark={true}
          onSubmit={e => {
            e.preventDefault()
            this.onSubmit()
          }}
          className="form-label-default"
        >

          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('name', {
                rules: [{ max: 20 }, { required: true }],
                validateTrigger: false,
              })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('code', { rules: [{ max: 20 }], validateTrigger: false })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Currency']}</p></span>)}
                       {...formItemLayout}
            >
              {getFieldDecorator('currency_id', {
                rules: [{ required: true }],
                validateTrigger: false,
              })(
                <Select
                  disabled={true}
                  size="large"
                  suffixIcon={<Icon type="caret-down"/>}
                >
                  {currencyData.map((x, k) => {
                    return <Select.Option value={x.currency_id} key={k}>{x.name + ' - ' + x.symbol}</Select.Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="check-circle-o"/><p>{this.props.langData['Default']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('default', {
                valuePropName: 'checked',
                validateTrigger: false,
              })(
                <Switch
                  checkedChildren={<CheckOutlined/>}
                  unCheckedChildren={<CloseOutlined/>}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item {...formItemLayoutNoLabel}>
              <Button
                loading={this.state.submitting}
                htmlType="submit"
                type="primary"
                size="large"
                className="default-button"
              >
                {this.props.langData['Save']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Card>
    )
  }

}

UpdateSafeForm.propTypes = {
  langData: PropTypes.object,
  safe_id: PropTypes.number,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

export default connect(mapStateToProps)(UpdateSafeForm)
