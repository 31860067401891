import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, InputNumber, Button, Divider, Modal, Select, Spin } from 'antd'
import { graphQLErrorsToToast } from './../../../helpers/helper'
import { debounce } from 'lodash'
import { gql } from '@apollo/client'

import apolloClient from './../../../helpers/apolloClient'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

class InvoiceAdditionalCharge extends Component {

  render() {
    const WrappedInvoiceAdditionalChargeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceAdditionalChargeForm)
    return <WrappedInvoiceAdditionalChargeForm {...this.props} />
  }

}

InvoiceAdditionalCharge.propTypes = {
  langData: PropTypes.object,
}

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          additional_charge_id
          name
          unit {
            name
          }
          buying_price
          selling_price
          vat
        }
      }
    }
  `
)

class InvoiceAdditionalChargeForm extends Component {

  constructor(props) {
    super(props)
    let additionalChargeList = []
    if (props.additional_charge.additional_charge_id) {
      let findAdditionalCharge = props.additional_charge.additional_charges.find(x => x.additional_charge_id === props.additional_charge.additional_charge_id)
      additionalChargeList.push({
        name: findAdditionalCharge.name,
        unit: findAdditionalCharge.unit,
        additional_charge_id: props.additional_charge.additional_charge_id,
      })
    }
    this.state = {
      additionalChargeList: additionalChargeList,
      fetchingAdditionalCharge: false,
    }
    this.searchAdditionalCharge = debounce(this.searchAdditionalCharge, 800)
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err)
        return
      this.props.editAdditionalCharge({
        ...this.props.additional_charge,
        ...values,
        vatTotal: ((values.amount * values.quantity) / 100) * (values.vat || 0),
        unit: values.additional_charge_id ? this.state.additionalChargeList.find(x => x.additional_charge_id === values.additional_charge_id).unit : null,
      })
    })
  }

  searchValue = null

  searchAdditionalCharge = async value => {
    if (this.searchValue === value)
      return

    this.searchValue = value

    if (value.length < 2)
      return

    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      if ( ! result.data.additionalChargeList.additional_charges.length)
        this.props.form.setFieldsValue({ additional_charge_id: null })
      this.setState({
        fetchingAdditionalCharge: false,
        additionalChargeList: result.data.additionalChargeList.additional_charges,
      })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  calculateAdditionalCharge = (reverse = false) => {
    setTimeout(() => {
      let additional_charge = this.props.form.getFieldsValue()
      if ( ! reverse) {
        let vatTotal = parseFloat(((((additional_charge.quantity || 0) * (additional_charge.amount || 0)) / 100) * (additional_charge.vat || 0)).toFixed(4))
        this.props.form.setFieldsValue({
          total: parseFloat((((additional_charge.quantity || 0) * (additional_charge.amount || 0)) + vatTotal).toFixed(4)),
        })
      } else {
        if (additional_charge.vat) {
          let without_vat_total = parseFloat(((additional_charge.total || 0) / parseFloat(((additional_charge.vat / 100) + 1).toFixed(4))).toFixed(4))
          this.props.form.setFieldsValue({
            amount: parseFloat((without_vat_total / (additional_charge.quantity || 0)).toFixed(4)),
          })
        } else {
          this.props.form.setFieldsValue({
            amount: parseFloat((additional_charge.total / (additional_charge.quantity || 0)).toFixed(4)),
          })
        }
      }
    }, 50)
  }

  render() {

    const { getFieldDecorator } = this.props.form
    return (
      <Modal
        title={<span><Icon
          type="star-o"/> &nbsp;{this.props.langData[this.props.additional_charge.additional_charge_id ? 'Edit Additional Charge' : 'Add Additional Charge']}</span>}
        bodyStyle={{ padding: 8 }}
        visible={true}
        footer={null}
        onCancel={this.props.modalClose}
      >
        <Form onSubmit={this.onSubmit} className="form-label-default">

          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="star-o"/><p>{this.props.langData['Additional Charge']}</p></span>
            )}
            >
              {getFieldDecorator('additional_charge_id', {
                rules: [{ required: true }],
                initialValue: this.props.additional_charge.additional_charge_id,
              })(
                <Select
                  size="large"
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  showSearch
                  notFoundContent={this.state.fetchingAdditionalCharge ? <Spin size="large"/> : null}
                  onSearch={this.searchAdditionalCharge}
                  onSelect={value => {
                    let additional_charge = this.state.additionalChargeList.find(x => x.additional_charge_id === value)
                    let price = this.props.type === 'sales' ? 'selling_price' : 'buying_price'

                    if (additional_charge[price]) {
                      if (this.props.selectedCurrency.code === 'try')
                        this.props.form.setFieldsValue({ amount: additional_charge[price] })
                      else
                        this.props.form.setFieldsValue({ amount: parseFloat((additional_charge[price] / this.props.currency.try).toFixed(4)) })
                    }

                    if (additional_charge.vat)
                      this.props.form.setFieldsValue({ vat: additional_charge.vat })

                    this.calculateAdditionalCharge()
                  }}
                >
                  {this.state.additionalChargeList.map((x, k) => {
                    return <Option value={x.additional_charge_id} key={k}>{x.name}</Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Quantity']}</p></span>
            )}>
              {getFieldDecorator('quantity', {
                rules: [{ required: true }],
                initialValue: this.props.additional_charge.quantity,
              })(
                <InputNumber
                  size="large"
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateAdditionalCharge()}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Amount']}</p></span>
            )}>
              {getFieldDecorator('amount', {
                rules: [{ required: true }],
                initialValue: this.props.additional_charge.amount || 0.0001,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateAdditionalCharge()}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedCurrency.symbol, '') + ` ${this.props.selectedCurrency.symbol}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedCurrency.symbol, '')}
                  onKeyUp={event => {
                    if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                      event.target.setSelectionRange(event.target.value.length - 2, event.target.value.length - 2)
                  }}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Vat']}</p></span>
            )}>
              {getFieldDecorator('vat', {
                initialValue: this.props.additional_charge.vat,
              })(
                this.props.lang === 'tr' ? (
                  <Select
                    className="w-100"
                    onChange={() => this.calculateAdditionalCharge()}
                  >
                    {this.props.langData.vats.map((x, k) => {
                      return <Option value={x} key={k}>{`${x}%`}</Option>
                    })}
                  </Select>
                ) : (
                  <InputNumber
                    className="w-100"
                    min={0}
                    step={1}
                    onChange={() => this.calculateAdditionalCharge()}
                    formatter={value => {
                      if (value.toString() === '')
                        return ''
                      return value.toString().replace('%', '') + '%'
                    }}
                    parser={value => value.replace('%', '')}
                    onKeyUp={event => {
                      if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                        event.target.setSelectionRange(event.target.value.length - 1, event.target.value.length - 1)
                    }}
                  />
                ),
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Total']}</p></span>
            )}>
              {getFieldDecorator('total', {
                rules: [{ required: true }],
                initialValue: this.props.additional_charge.total || 0.0001,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateAdditionalCharge(true)}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedCurrency.symbol, '') + ` ${this.props.selectedCurrency.symbol}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedCurrency.symbol, '')}
                  onKeyUp={event => {
                    if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                      event.target.setSelectionRange(event.target.value.length - 2, event.target.value.length - 2)
                  }}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit" className="default-button">
                {this.props.langData[this.props.additional_charge.additional_charge_id ? 'Edit' : 'Add']}
              </Button>
              <Button size="large" onClick={this.props.modalClose} style={{ marginRight: 8 }}
                      className="default-button danger2">
                {this.props.langData['Close']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Modal>
    )
  }

}

InvoiceAdditionalChargeForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  additional_charge: PropTypes.object,
  form: PropTypes.object,
  editAdditionalCharge: PropTypes.func,
  modalClose: PropTypes.func,
  selectedCurrency: PropTypes.object,
  type: PropTypes.string,
  currency: PropTypes.object,
}

export default connect(mapStateToProps)(InvoiceAdditionalCharge)
