import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import InvoiceForm from '../../../InvoiceForm/InvoiceForm'
import { generatePath } from 'react-router-dom'
import routes from '../../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Create Invoice Copy' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class InvoiceCopy extends Component {
  type
  invoice_copy_id

  typeEnglishName = {
    'satis': 'sales',
    'alis': 'purchase',
  }

  constructor(props) {
    super(props)
    this.type = this.props.match.params.type
    this.invoice_copy_id = parseInt(this.props.match.params.id)
    if (this.typeEnglishName[this.type])
      this.type = this.typeEnglishName[this.type]
  }

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.INVOICE_COPY_NEW, {
      type: this.type,
      id: this.invoice_copy_id,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedInvoiceCopyForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceForm)
    return <WrappedInvoiceCopyForm invoice_copy_id={this.invoice_copy_id}
                                   type={this.type}{...this.props} ></WrappedInvoiceCopyForm>
  }
}

InvoiceCopy.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCopy)
