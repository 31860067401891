import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  selectFilterLowerCase,
  deleteNull,
  updateEmptyAndUndefined,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
} from '../../../helpers/helper'
import { currencyData, getCurrencyWithCode } from '../../../data/Currency'
import { connect } from 'react-redux'
import {
  Form,
  Icon,
  Input,
  Button,
  Card,
  Divider,
  Select,
  InputNumber,
  DatePicker,
  Table,
  Spin,
  Checkbox,
  Popover,
  Col,
} from 'antd'
import { compile } from 'path-to-regexp'
import moment from 'moment'
import { gql } from '@apollo/client'

import { cloneDeep, debounce } from 'lodash'
import { Query, Mutation } from '@apollo/client/react/components'
import apolloClient from './../../../helpers/apolloClient'
import AdditionalChargeSearchModal
  from './../../AdditionalCharge/AdditionalChargeSearchModal/AdditionalChargeSearchModal'
import ProductAdditionalCharge from './../ProductAdditionalCharge/ProductAdditionalCharge'
import {
  createCategory,
  sequenceSetFirstValues,
  refetchAndControlSequenceValues,
  setDefault,
} from '../../../helpers/trait'
import AddUpSelect from './../../Utils/AddUpSelect'

const { Option } = Select
const InputGroup = Input.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
    fixed_exchange_rate: state.GlobalReducer.fixed_exchange_rate,
  }
}

const READ_QUERY = (
  gql`
    query product($product_id: Int!){
      product(product_id: $product_id) {
        product_id
        name
        code
        category_id
        stock
        is_ghost
        name_on_invoice
        supplier_product_code
        category {
          name
        }
        stock_tracking
        unit_id
        unit {
          name
        }
        selling_price
        selling_currency
        buying_price
        buying_currency
        has_sub_product
        vat
        barcode
        stock_opening {
          type
          quantity
          date
        }
        sub_products {
          product_id
        }
        additional_charges {
          product_additional_charge_id
          additional_charge_id
          buying_price
          selling_price
          quantity
          vat
          additional_charge {
            name
          }
        }
      }

      productCategoryList {
        category_id
        name
        code
      }

      productDefaults {
        category_id
        category {
          name
        }
        stock_tracking
        unit_id
        unit {
          name
        }
        vat
        code_sequence {
          sequence_id
          next_value
          pattern
          end
          show_all_columns
        }
      }

      unitList {
        unit_id
        name
      }

      settings {
        product_settings {
          modal_status
        }
      }

      userInformation {
        settings {
          increment
        }
      }

    }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
    {
      productCategoryList {
        category_id
        name
        code
      }

      productDefaults {
        category_id
        category {
          name
        }
        stock_tracking
        unit_id
        vat
        code_sequence {
          sequence_id
          next_value
          pattern
          end
          show_all_columns
        }
      }

      unitList {
        unit_id
        name
      }

      settings {
        product_settings {
          modal_status
        }
      }

      userInformation {
        settings {
          increment
        }
      }
    }
  `
)

const ADDITIONAL_CHARGE_READ_QUERY = (
  gql`
    query additionalCharge($additional_charge_id: Int!){
      additionalCharge(additional_charge_id: $additional_charge_id){
        name
        buying_price
        selling_price
        vat
      }
      unitList {
        unit_id
        name
      }
    }
  `
)

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          additional_charge_id
          name
          buying_price
          selling_price
          vat
        }
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation product($product: ProductCreateInput!, $stock_opening: StockOpeningCreateInput){
      product: productCreate(product: $product, stock_opening: $stock_opening) {
        product {
          product_id
        }
        informative {
          messages
        }
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation product($product_id: Int!, $product: ProductUpdateInput!, $stock_opening: StockOpeningCreateInput){
      product: productUpdate(product_id: $product_id, product: $product, stock_opening: $stock_opening) {
        informative {
          messages
        }
      }
    }
  `
)

class ProductForm extends Component {

  columns = [
    {
      title: this.props.langData['Additional Charge'],
      dataIndex: 'additional_charge_id',
      width: '40%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputGroup compact style={{ display: 'flex' }}>
              <Select
                className="w-100"
                filterOption={false}
                suffixIcon={<Icon type="caret-down"/>}
                defaultActiveFirstOption={false}
                showSearch
                style={{ width: '100%' }}
                notFoundContent={this.state.fetchingAdditionalCharge ? <Spin size="large"/> : null}
                onSearch={value => this.searchAdditionalCharge(record.id, value)}
                value={value}
                disabled={record.delete}
                onSelect={value => {
                  let selected_additional_charge = record.additional_charges.find(x => x.additional_charge_id === value)
                  let additional_charges = this.state.additional_charges
                  let additional_charge = additional_charges.find(x => x.id === record.id)
                  additional_charge.additional_charge_id = value
                  additional_charge.buying_price = selected_additional_charge.buying_price
                  additional_charge.selling_price = selected_additional_charge.selling_price
                  additional_charge.vat = selected_additional_charge.vat
                  this.setState({ additional_charges })
                }}
              >
                {record.additional_charges && record.additional_charges.map((x, i) => {
                  return <Option value={x.additional_charge_id} key={i}>{x.name}</Option>
                })}
              </Select>
              <Button
                icon="search"
                disabled={record.delete}
                onClick={() => this.setState({ modalAdditionalChargeSearch: record.id })}
              ></Button>
            </InputGroup>
          )
        if (value && record.additional_charges)
          return record.additional_charges.find(x => x.additional_charge_id === value).name
        return null
      },
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              className="w-100"
              min={0.0001}
              step={this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              allowClear
              disabled={record.delete}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).quantity = value
                this.setState({ additional_charges })
              }}
            />
          )
        return value
      },
    },
    {
      title: this.props.langData['Buying Price'],
      dataIndex: 'buying_price',
      width: '18%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputGroup compact style={{ display: 'flex' }}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step={this.state.increment.toFixed(4)}
                decimalSeparator=","
                onChange={value => {
                  let additional_charges = this.state.additional_charges
                  additional_charges.find(x => x.id === record.id).buying_price = value
                  this.setState({ additional_charges })
                }}
                value={value}
                disabled={record.delete}
                onKeyUp={event => {
                  if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                    event.target.setSelectionRange(event.target.value.length - 2, event.target.value.length - 2)
                }}
              />
              <Button disabled>{this.state.selectedBuyingCurrency.symbol}</Button>
            </InputGroup>
          )
        return value ? (value + ' ' + this.state.selectedBuyingCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Selling Price'],
      dataIndex: 'selling_price',
      width: '19%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputGroup compact style={{ display: 'flex' }}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step={this.state.increment.toFixed(4)}
                decimalSeparator=","
                onChange={value => {
                  let additional_charges = this.state.additional_charges
                  additional_charges.find(x => x.id === record.id).selling_price = value
                  this.setState({ additional_charges })
                }}
                value={value}
                disabled={record.delete}
                onKeyUp={event => {
                  if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                    event.target.setSelectionRange(event.target.value.length - 2, event.target.value.length - 2)
                }}
              />
              <Button disabled>{this.state.selectedSellingCurrency.symbol}</Button>
            </InputGroup>
          )
        return value ? (value + ' ' + this.state.selectedSellingCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 756) {
          if (this.props.lang === 'tr')
            return (
              <Select
                className="w-100"
                value={value}
                suffixIcon={<Icon type="caret-down"/>}
                disabled={record.delete}
                onChange={value => {
                  let additional_charges = this.state.additional_charges
                  additional_charges.find(x => x.id === record.id).vat = value
                  this.setState({ additional_charges })
                }}
              >
                {this.props.langData.vats.map((x, k) => {
                  return <Option value={x} key={k}>{`${x}%`}</Option>
                })}
              </Select>
            )
          return (
            <InputNumber
              className="w-100"
              min={0}
              step={1}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).vat = value
                this.setState({ additional_charges })
              }}
              value={value}
              disabled={record.delete}
              formatter={value => {
                if (value.toString() === '') {
                  return ''
                }
                return value.toString().replace('%', '') + '%'
              }}
              parser={value => value.replace('%', '')}
              onKeyUp={event => {
                if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                  event.target.setSelectionRange(event.target.value.length - 1, event.target.value.length - 1)
              }}
            />
          )
        }
        return value ? (value + '%') : null
      },
    },
    {
      className: 'text-right',
      render: (value, record) => {
        return (
          <Button
            icon={record.delete === true ? 'minus-circle-o' : 'close-circle-o'}
            style={{ width: 40 }}
            onClick={event => {
              event.stopPropagation()
              this.deleteAdditionalCharge(record.id)
            }}
          >
          </Button>
        )
      },
    },
  ]

  initialState = {
    data: null,
    categories: null,
    units: null,
    additional_charges: [],
    fetchingAdditionalCharge: false,
    stock_opening_quantity: null,
    selectedSellingCurrency: {},
    selectedBuyingCurrency: {},
    defaults: null,
    modalAdditionalChargeSearch: null,
    modalAdditionalCharge: null,
    initialize: false,
    has_sub_product: false,
    willAddCategory: null,
    is_ghost: false,
    increment: null,
    modal_status: null,
  }

  state = this.initialState

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: this.props.showingInModal ? 16 : 12 },
    },
  }

  formItemLayoutHalf = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  }

  sequenceIntervals = null

  constructor(props) {
    super(props)
    this.searchAdditionalCharge = debounce(this.searchAdditionalCharge, 800)
    this.createCategory = createCategory('product').bind(this)
    this.refetchAndControlSequenceValues = refetchAndControlSequenceValues.bind(this)
    this.setDefault = setDefault.bind(this)
  }

  onSubmit = (mutate, callback) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      // kategori eklenmesi gerek ise ekler
      const step1 = async () => {
        if (values.product.category_id) {
          let category = this.state.categories.find(x => x.name === values.product.category_id)
          if (category) {
            values.product.category_id = category.category_id
            step2()
          }
        } else {
          step2()
        }
      }

      // kayıt aşaması
      const step2 = async () => {

        values.product.stock_tracking = (values.product.stock_tracking === 'true')

        if (this.state.additional_charges.length) {
          let additional_charges = []
          let additional_charges_update = []
          let additional_charges_delete = []
          for (let i = 0; i < this.state.additional_charges.length; i++) {
            let additional_charge = this.state.additional_charges[i]
            if (additional_charge.delete) {
              additional_charges_delete.push(additional_charge.product_additional_charge_id)
            } else {
              if ( ! additional_charge.additional_charge_id) {
                toast(false, this.props.langData['Incorrect additional charges entered'])
                return
              }
              if (this.props.product_copy_id) {
                delete additional_charge.product_additional_charge_id
              }
              delete additional_charge.id
              delete additional_charge.additional_charges
              delete additional_charge.delete
              if (additional_charge.product_additional_charge_id)
                additional_charges_update.push(additional_charge)
              else
                additional_charges.push(additional_charge)
            }
          }
          if (additional_charges.length)
            values.product.additional_charges = additional_charges
          if (additional_charges_update.length)
            values.product.additional_charges_update = additional_charges_update
          if (additional_charges_delete.length)
            values.product.additional_charges_delete = additional_charges_delete

        }

        values.product.has_sub_product = this.state.has_sub_product
        values.product.is_ghost = this.state.is_ghost
        if (this.props.product_id) {
          updateEmptyAndUndefined(values)
          values.product_id = Number(this.props.product_id)
          if ( ! values.stock_opening.quantity)
            values.stock_opening = null
          else
            values.stock_opening.date = moment(values.stock_opening.date).format('YYYY-MM-DD HH:mm')

          // varsayılan değerlere eş değer olan fieldları null yap, veritabanı bunları set_defaults ile otomatik alacak
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.product[x]) {
              if (x !== 'unit_id' && x !== 'code')
                values.product[x] = null
            }

          }
        } else {
          if ( ! values.stock_opening.quantity)
            delete values.stock_opening
          else
            values.stock_opening.date = moment(values.stock_opening.date).format('YYYY-MM-DD HH:mm')

          // varsayılan değerlere eş değer olan fieldları sil( yani gönderme ), veritabanı bunları set_defaults ile otomatik alacak
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.product[x])
              if (x !== 'unit_id' && x !== 'code')
                delete values.product[x]
          }
        }

        try {
          const result = await mutate({ variables: values })
          if (result !== undefined) {
            toast(true, result.data.product.informative.messages)
            if (callback) {
              return callback()
            }
            if (this.props.showingInModal) {
              return this.props.onModalClose()
            }
            this.props.history.push('/' + compile(this.props.langData.route['product/detail/:id'])({
              id: this.props.product_id || result.data.product.product.product_id,
            }))
          }
        } catch (err) {
          graphQLErrorsToToast(err)
        }
      }

      step1()

    })
  }

  onSubmitAndNew = (mutate, refetch) => {
    this.onSubmit(mutate, async () => {
      this.props.form.resetFields()
      this.sequenceIntervals()
      const result = await refetch()
      document.getElementById('main-content').scrollTop = 0
      this.setState({
        ...(this.initialState),
        additional_charges: [],
        categories: cloneDeep(result.data.productCategoryList),
      })
    })
  }

  addAdditionalCharge = () => {
    let additional_charge = {
      id: Math.floor(Math.random() * 999999) + 1,
      additional_charge_id: null,
      quantity: 1,
      buying_price: null,
      selling_price: null,
      vat: null,
    }
    let additional_charges = this.state.additional_charges
    additional_charges.push(additional_charge)
    this.setState({ additional_charges })

    if (this.props.sizeWidth <= 576) {
      this.setState({ modalAdditionalCharge: additional_charge })
    } else {
      if (this.state.modal_status) {
        this.setState({ modalAdditionalChargeSearch: additional_charge.id })
      }
    }
  }

  deleteAdditionalCharge = id => {
    if ( ! this.props.product_id)
      return this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })

    let additional_charges = this.state.additional_charges
    if ( ! additional_charges.find(x => x.id === id).product_additional_charge_id)
      return this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })

    additional_charges.find(x => x.id === id).delete = ! additional_charges.find(x => x.id === id).delete
    this.setState({ additional_charges })
  }

  additionalChargeSearchModalCallback = async record => {
    let id = this.state.modalAdditionalChargeSearch
    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    try {
      const result = await apolloClient.query({
        query: ADDITIONAL_CHARGE_READ_QUERY,
        variables: { additional_charge_id: record.additional_charge_id },
      })
      if ( ! result.data.additionalCharge)
        return toast(false, this.props.langData['x not found']('Additional charge'))

      let additionalChargePure = result.data.additionalCharge
      additional_charge.additional_charge_id = record.additional_charge_id
      additional_charge.buying_price = additionalChargePure.buying_price
      additional_charge.selling_price = additionalChargePure.selling_price
      additional_charge.vat = additionalChargePure.vat
      additional_charge.additional_charges = [
        {
          additional_charge_id: record.additional_charge_id,
          name: additionalChargePure.name,
        },
      ]

      this.setState({ additional_charges, modalAdditionalChargeSearch: null })
    } catch (err) {
      this.setState({ modalAdditionalChargeSearch: null })
      graphQLErrorsToToast(err)
    }

  }

  searchValue = null

  searchAdditionalCharge = async (id, value) => {
    if (this.searchValue === value)
      return

    this.searchValue = value

    if (value.length < 2)
      return

    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      additional_charge.additional_charges = result.data.additionalChargeList.additional_charges
      if ( ! result.data.additionalChargeList.additional_charges.length) {
        additional_charge.additional_charge_id = null
        additional_charge.name = null
      }
      this.setState({ fetchingAdditionalCharge: false })
      this.setState({ additional_charges })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  rowItemClick = record => {
    this.setState({
      modalAdditionalCharge: record,
    })
  }

  editAdditionalCharge = (additional_charge, name) => {
    let additional_charges = []

    this.state.additional_charges.forEach(x => {
      if (x.id !== additional_charge.id)
        additional_charges.push(x)
      else
        additional_charges.push({
          ...additional_charge, additional_charges: [
            {
              additional_charge_id: additional_charge.additional_charge_id,
              name,
            },
          ],
        })
    })

    this.setState({
      modalAdditionalCharge: null,
      additional_charges,
    })
  }

  modalClose = () => {
    let additional_charges = this.state.additional_charges.filter(x => x.additional_charge_id !== null)
    this.setState({
      additional_charges,
      modalAdditionalCharge: null,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Query
        query={this.props.product_id || this.props.product_copy_id ? READ_QUERY : READ_FOR_CREATE_QUERY}
        variables={this.props.product_id ? { product_id: Number(this.props.product_id) } : this.props.product_copy_id ? { product_id: Number(this.props.product_copy_id) } : null}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            // ürün düzenleme açılıyorsa ve ürün yoksa hata ver ve yönlendir
            if (this.props.product_id && ! data.product) {
              toast(false, this.props.langData['x not found']('Product'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else {
              let defaults = cloneDeep(data.productDefaults)
              // varsayılan category_id var ise category_id ye category.name değerini set et
              if (defaults.category) {
                defaults.category_id = defaults.category.name
                delete defaults.category
              }

              sequenceSetFirstValues(['code'], defaults)
              this.setState({
                categories: cloneDeep(data.productCategoryList),
                units: data.unitList,
                defaults,
                initialize: true,
                increment: data.userInformation.settings.increment,
                modal_status: data.settings.product_settings.modal_status,
              })

              if (this.props.product_id) {
                this.setState({ data: data.product })
                // ürün ek ücretleri var ise her ek ücret için bir id belirle. ve select te seçili görülebilsin diye. additional_charges ekle
                if (data.product.additional_charges.length) {
                  let additional_charges = cloneDeep(data.product.additional_charges)
                  additional_charges.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.additional_charges = [
                      {
                        additional_charge_id: x.additional_charge_id,
                        name: x.additional_charge.name,
                      },
                    ]
                    delete x.additional_charge
                  })
                  this.setState({ additional_charges })
                }

                let product = data.product
                let stock_opening = data.product.stock_opening
                this.setState({
                  has_sub_product: data.product.has_sub_product,
                  is_ghost: data.product.is_ghost,
                })
                setTimeout(() => {
                  this.props.form.setFieldsValue(deleteNull({
                    'product[name]': product.name,
                    'product[code]': product.code,
                    'product[stock_tracking]': (product.stock_tracking).toString(),
                    'product[vat]': product.vat,
                    'product[barcode]': product.barcode,
                    'product[selling_price]': product.selling_price,
                    'product[buying_price]': product.buying_price,
                    'product[buying_currency]': product.buying_currency,
                    'product[selling_currency]': product.selling_currency,
                    'product[unit_id]': product.unit_id,
                    'product[name_on_invoice]': product.name_on_invoice,
                    'product[supplier_product_code]': product.supplier_product_code,
                  }))
                  // kurları seç
                  if (product.selling_currency)
                    this.setState({ selectedSellingCurrency: getCurrencyWithCode(product.selling_currency) })
                  else
                    this.setState({ selectedSellingCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })

                  if (product.buying_currency)
                    this.setState({ selectedBuyingCurrency: getCurrencyWithCode(product.buying_currency) })
                  else
                    this.setState({ selectedBuyinCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })


                  if (product.category_id)
                    this.props.form.setFieldsValue({
                      'product[category_id]': product.category.name,
                    })

                  if (stock_opening) {
                    this.setState({ stock_opening_quantity: stock_opening.quantity })
                    setTimeout(() => {
                      this.props.form.setFieldsValue({
                        'stock_opening[quantity]': product.stock,
                        'stock_opening[type]': stock_opening.type,
                        'stock_opening[date]': moment(stock_opening.date),
                      })
                    }, 10)
                  }
                }, 10)
              } else {
                this.sequenceIntervals = this.refetchAndControlSequenceValues('product', ['code'], defaults)
                // varsayılan değerleri yükle
                let defaultDataForUpdate = {}
                Object.keys(defaults).forEach(x => {
                  if (x.indexOf('_sequence') === -1 && defaults[x] !== null) {
                    if (x === 'stock_tracking')
                      defaultDataForUpdate[`product[${x}]`] = defaults[x].toString()
                    else
                      defaultDataForUpdate[`product[${x}]`] = defaults[x]
                  }
                })
                this.props.form.setFieldsValue(defaultDataForUpdate)

                if (this.props.product_copy_id) {
                  this.setState({ data: data.product })

                  // ürün ek ücretleri var ise her ek ücret için bir id belirle. ve select te seçili görülebilsin diye. additional_charges ekle
                  if (data.product.additional_charges.length) {
                    let additional_charges = cloneDeep(data.product.additional_charges)
                    additional_charges.forEach(x => {
                      x.id = Math.floor(Math.random() * 999999) + 1
                      x.additional_charges = [
                        {
                          additional_charge_id: x.additional_charge_id,
                          name: x.additional_charge.name,
                        },
                      ]
                      delete x.additional_charge
                    })
                    this.setState({ additional_charges })
                  }

                  let product = data.product
                  let stock_opening = data.product.stock_opening
                  this.setState({
                    has_sub_product: data.product.has_sub_product,
                    is_ghost: data.product.is_ghost,
                  })

                  setTimeout(() => {
                    this.props.form.setFieldsValue(deleteNull({
                      'product[name]': product.name,
                      'product[stock_tracking]': (product.stock_tracking).toString(),
                      'product[vat]': product.vat,
                      'product[selling_price]': product.selling_price,
                      'product[buying_price]': product.buying_price,
                      'product[buying_currency]': product.buying_currency,
                      'product[selling_currency]': product.selling_currency,
                      'product[unit_id]': product.unit_id,
                    }))
                    // kurları seç
                    if (product.selling_currency)
                      this.setState({ selectedSellingCurrency: getCurrencyWithCode(product.selling_currency) })
                    else
                      this.setState({ selectedSellingCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })

                    if (product.buying_currency)
                      this.setState({ selectedBuyingCurrency: getCurrencyWithCode(product.buying_currency) })
                    else
                      this.setState({ selectedBuyinCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })


                    if (product.category_id)
                      this.props.form.setFieldsValue({
                        'product[category_id]': product.category.name,
                      })

                    if (stock_opening) {
                      this.setState({ stock_opening_quantity: product.stock })
                      setTimeout(() => {
                        this.props.form.setFieldsValue({
                          'stock_opening[quantity]': product.stock,
                          'stock_opening[type]': stock_opening.type,
                          'stock_opening[date]': moment(),
                        })
                      }, 10)
                    }
                  }, 10)
                }

                // varsayılan kurları seç
                let selectedCurrency = getCurrencyWithCode(this.props.fixed_exchange_rate)
                this.setState({
                  selectedBuyingCurrency: selectedCurrency,
                  selectedSellingCurrency: selectedCurrency,
                })
              }
            }
          }

          return (
            <Mutation
              mutation={! this.props.product_id ? CREATE_QUERY : UPDATE_QUERY}
            >
              {(mutate, { loading: loadingMutation }) => (
                <div>
                  <Card
                    loading={! this.props.product_id ? false : (loadingQuery || ! data.product)}
                    bodyStyle={{ padding: '0px' }}
                    title={
                      <span>
												<Icon style={{ display: 'contents' }} type={! this.props.product_id ? 'file-add' : 'edit'}/>
                        &nbsp;{this.props.product_id ? this.props.langData['Update Product'] : this.props.langData['New Product']}
											</span>
                    } bordered={false}
                    extra={
                      <Popover
                        style={{ width: 500 }}
                        placement="bottomRight"
                        content={
                          <React.Fragment>
                            <Col>
                              <Checkbox onChange={(e) => {
                                this.setState({
                                  has_sub_product: e.target.checked,
                                })
                              }}
                                        checked={this.state.has_sub_product}
                                        disabled={this.state.is_ghost}>
                                <span style={{
                                  color: '#000000',
                                  filter: this.state.is_ghost ? 'opacity(0.5)' : 'none',
                                }}>{this.props.langData['This product is a main product']}</span>
                              </Checkbox>
                            </Col>
                            <Col>
                              <Checkbox onChange={(e) => {
                                this.setState({
                                  is_ghost: e.target.checked,
                                })
                              }}
                                        checked={this.state.is_ghost}
                                        disabled={this.state.has_sub_product}
                              >
                                <span style={{
                                  color: '#000000',
                                  filter: this.state.has_sub_product ? 'opacity(0.5)' : 'none',
                                }}>{this.props.langData['This product is a ghost product']}</span>
                              </Checkbox>
                            </Col>
                          </React.Fragment>
                        }
                        trigger="click"
                      >
												<span style={{ color: 'white', fontSize: 16, cursor: 'pointer' }}>
													<Icon style={{
                            width: '1em',
                            height: '1em',
                            color: (this.state.has_sub_product || this.state.is_ghost) ? '#1890ff' : '#ffffff',
                          }}
                                type="setting"/>
                          &nbsp;{this.props.langData['Preferences']}
                          &nbsp;&nbsp;<Icon style={{ width: '1em', height: '1em' }} type="down"/>
												</span>
                      </Popover>
                    }
                  >

                    <Form hideRequiredMark={true} className="form-label-default">

                      <div className="form-block">

                        <Form.Item hasFeedback label={(
                          <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[name]', {
                            rules: [{ max: 250 }, { required: true }],
                            validateTrigger: false,
                          })(
                            <Input size="large" maxLength={250}
                                   onBlur={item => this.setDefault('product[name]', 'name', item)}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={
                          <span><Icon type="tags"/><p>{this.props.langData['Invoice Name']}</p></span>}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[name_on_invoice]', {
                            validateTrigger: false,
                          })(
                            <Input size="large" maxLength={250}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(<span><Icon
                          type="bulb"/><p>{this.props.langData['Category']}</p></span>)} {...this.formItemLayout} >
                          {getFieldDecorator('product[category_id]', { validateTrigger: false })(
                            <AddUpSelect
                              data={this.state.categories && this.state.categories.map(x => ({
                                key: x.category_id,
                                value: x.name,
                              }))}
                              onAddUp={value => this.createCategory(value)}
                            />,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[code]', {
                            rules: [{ max: 20 }, { required: true }],
                            validateTrigger: false,
                          })(
                            <Input size="large" maxLength={20}
                                   onBlur={item => this.setDefault('product[code]', 'code', item)}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={
                          <span><Icon
                            type="paper-clip"/><p>{this.props.langData['Supplier Product Code']}</p></span>}{...this.formItemLayout}
                        >
                          {getFieldDecorator('product[supplier_product_code]', {
                            validateTrigger: false,
                          })(
                            <Input size="large" maxLength={250}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="barcode"/><p>{this.props.langData['Barcode']}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[barcode]', { rules: [{ max: 30 }] })(
                            <Input size="large" maxLength={30}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="calculator"/><p>{this.props.langData['Stock Tracking']}</p></span>
                        )}
                                   {...(this.props.showingInModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          {getFieldDecorator('product[stock_tracking]', {
                            initialValue: 'true',
                            validateTrigger: false,
                          })(
                            <Select size="large" suffixIcon={<Icon type="caret-down"/>}>
                              <Option value="true">{this.props.langData['Open']}</Option>
                              <Option value="false">{this.props.langData['Closed']}</Option>
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Opening Stock']}</p></span>)}
                                   {...this.formItemLayout}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('stock_opening[type]', {
                              initialValue: 'Pozitive',
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                disabled={this.state.has_sub_product ? true : false}
                                suffixIcon={<Icon type="caret-down"/>}
                              >
                                <Option value="Pozitive">+</Option>
                                <Option value="Negative">-</Option>
                              </Select>,
                            )}
                            {getFieldDecorator('stock_opening[quantity]', { validateTrigger: false })(
                              <InputNumber
                                disabled={this.state.has_sub_product ? true : false}
                                size="large"
                                step={this.state.increment && this.state.increment.toFixed(4)}
                                decimalSeparator=","
                                min={0}
                                className="w-100"
                                onChange={value => this.setState({ stock_opening_quantity: value })}
                              />,
                            )}
                          </InputGroup>

                        </Form.Item>

                        { !! this.state.stock_opening_quantity &&
                          <Form.Item label={(<span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>)}
                                     {...this.formItemLayout}
                          >
                            {getFieldDecorator('stock_opening[date]', {
                              initialValue: moment(),
                              rules: [{ required: true }],
                              validateTrigger: false,
                            })(
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm' }}
                                size="large"
                                className="w-100"
                              />,
                            )}
                          </Form.Item>}

                      </div>

                      <Divider/>

                      <div className="form-block">

                        <Form.Item hasFeedback label={(
                          <span><Icon type="database"/><p>{this.props.langData['Unit']}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[unit_id]', {
                            rules: [{ required: true }],
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              suffixIcon={<Icon type="caret-down"/>}
                              showSearch
                              filterOption={selectFilterLowerCase}
                            >
                              {this.state.units && this.state.units.map(x => {
                                return <Option value={x.unit_id} key={x.unit_id}>{x.name}</Option>
                              })}
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="wallet"/><p>{this.props.langData['Vat']}</p></span>
                        )}
                                   {...(this.props.showingInModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          {getFieldDecorator('product[vat]', { validateTrigger: false })(
                            (this.props.lang === 'tr') ?
                              (
                                <Select
                                  size="large"
                                  className="w-100"
                                  suffixIcon={<Icon type="caret-down"/>}
                                >
                                  {this.props.langData.vats.map((x, k) => {
                                    return <Option value={x} key={k}>{`${x}%`}</Option>
                                  })}
                                </Select>
                              ) : (
                                <InputNumber
                                  size="large"
                                  min={0}
                                  formatter={value => value === '' ? '' : `${value}%`}
                                  className="w-100"
                                  parser={value => {
                                    if (value === '')
                                      return ''
                                    return value.replace('%', '')
                                  }}
                                  //onKeyUp={event => {
                                  // if(event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                                  // 	event.target.setSelectionRange(event.target.value.length -1, event.target.value.length -1)
                                  // }}
                                  onBlur={item => this.setDefault('product[vat]', 'vat', item, '%')}
                                />
                              ),
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Buying Price']}</p></span>
                        )}
                                   {...(this.props.showingInModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('product[buying_price]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                min={0}
                                step={this.state.increment && this.state.increment.toFixed(4)}
                                decimalSeparator=","
                                // onKeyUp={event => {
                                // 	if(event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                                // 		event.target.setSelectionRange(event.target.value.length -2, event.target.value.length -2)
                                // }}
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}
                            {getFieldDecorator('product[buying_currency]', {
                              initialValue: this.props.fixed_exchange_rate,
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                onSelect={val => this.setState({ selectedBuyingCurrency: currencyData.find(x => x.code === val) })}
                                suffixIcon={<Icon type="caret-down"/>}
                              >
                                {currencyData.map((x, k) => {
                                  return <Option value={x.code} key={k}>{x.symbol}</Option>
                                })}
                              </Select>,
                            )}
                          </InputGroup>

                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Selling Price']}</p></span>
                        )}
                                   {...(this.props.showingInModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('product[selling_price]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                min={0}
                                step={this.state.increment && this.state.increment.toFixed(4)}
                                decimalSeparator=","
                                // onKeyUp={event => {
                                // 	if(event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                                // 		event.target.setSelectionRange(event.target.value.length -2, event.target.value.length -2)
                                // }}
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}
                            {getFieldDecorator('product[selling_currency]', {
                              initialValue: this.props.fixed_exchange_rate,
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                onSelect={val => this.setState({ selectedSellingCurrency: currencyData.find(x => x.code === val) })}
                                suffixIcon={<Icon type="caret-down"/>}
                              >
                                {currencyData.map((x, k) => {
                                  return <Option value={x.code} key={k}>{x.symbol}</Option>
                                })}
                              </Select>,
                            )}
                          </InputGroup>

                        </Form.Item>
                      </div>

                    </Form>
                  </Card>
                  <Card loading={loadingQuery} bodyStyle={{ padding: 0, marginTop: 20 }} bordered={false}>
                    { ! this.props.showingInModal && <React.Fragment>
                      <Divider>
                        <Icon type="star-o"/> {this.props.langData['Additional Charges']}
                      </Divider>

                      <Table
                        columns={this.columns}
                        rowKey={record => record.id}
                        dataSource={this.state.additional_charges}
                        pagination={false}
                        rowClassName="white"
                        scroll={{ x: 700, y: 500 }}
                        onRow={record => {
                          if (this.props.sizeWidth <= 576)
                            return { onClick: () => this.rowItemClick(record) }
                        }}
                      />

                      <Button
                        style={{ marginLeft: 16, marginTop: 14 }}
                        icon="plus-circle-o"
                        onClick={this.addAdditionalCharge}
                        disabled={this.state.additional_charges.filter(x => x.additional_charge_id === null).length}
                      >
                        {this.props.langData['Add']}
                      </Button>

                      {this.state.modalAdditionalChargeSearch && (
                        <AdditionalChargeSearchModal
                          callback={this.additionalChargeSearchModalCallback}
                          modalClose={() => this.setState({ modalAdditionalChargeSearch: false })}
                        />
                      )}

                      {this.state.modalAdditionalCharge && (
                        <ProductAdditionalCharge
                          editAdditionalCharge={this.editAdditionalCharge}
                          additional_charge={this.state.modalAdditionalCharge}
                          modalClose={this.modalClose}
                          selectedBuyingCurrency={this.state.selectedBuyingCurrency.symbol}
                          selectedSellingCurrency={this.state.selectedSellingCurrency.symbol}
                        />
                      )}
                    </React.Fragment>}
                    <div className="form-block">
                      <Form.Item>
                        { ! this.props.product_id && ! this.props.showingInModal && <Button
                          loading={loadingMutation}
                          size="large" className="default-button success"
                          onClick={() => this.onSubmitAndNew(mutate, refetch)}
                          style={{ float: 'right' }}
                        >
                          {this.props.langData['Save & New']}
                        </Button>}
                        <Button
                          loading={loadingMutation}
                          type="primary" size="large" className="default-button"
                          style={{ marginRight: 5 }}
                          onClick={() => this.onSubmit(mutate)}
                        >
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>
                  </Card>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

ProductForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  product_id: PropTypes.string,
  form: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  fixed_exchange_rate: PropTypes.string,
  showingInModal: PropTypes.bool,

  // ekleme işlemi başarılı bir şekilde yapıldığında tetikleniyor, daha iyi bir isim verilebilir,
  // ayrıca düzenleme vs işlemlerinde de tetikleniyor olabilir
  onModalClose: PropTypes.func,
  product_copy_id: PropTypes.number,
}

export default connect(mapStateToProps)(ProductForm)
