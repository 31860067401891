import React, { Component } from 'react'
import { Modal, Icon, Button } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import InvoiceList from '../InvoiceList/InvoiceList'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}


class InvoiceSearchModal extends Component {
  render() {
    return (
      <Modal
        title={<span><Icon type="security-scan"/> &nbsp; {this.props.langData['Invoice Match']}</span>}
        bodyStyle={{ padding: 8 }}
        visible={true}
        width="75%"
        onCancel={this.props.modalClose}
        footer={[
          <Button key="close" size="large" onClick={this.props.modalClose} style={{ marginRight: 8 }}
                  className="danger2">
            {this.props.langData['Close']}
          </Button>,
        ]}
      >
        <InvoiceList noBreadCrumb={true} scrollHeight={200} rowItemClick={this.props.callback}
                     matchWaybillType={this.props.type} modalClose={this.props.modalClose}/>
      </Modal>
    )
  }
}

InvoiceSearchModal.propTypes = {
  langData: PropTypes.object,
  modalClose: PropTypes.func,
  type: PropTypes.string,
  callback: PropTypes.func,
}

export default connect(mapStateToProps, null)(InvoiceSearchModal)
