import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Link, generatePath } from 'react-router-dom'
import { compile } from 'path-to-regexp'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { capitalize, cloneDeep, debounce } from 'lodash'
import moment from 'moment'
import { MailOutlined, PrinterOutlined, SyncOutlined } from '@ant-design/icons'

import {
  Row,
  Col,
  Card,
  Icon,
  Button,
  Dropdown,
  Menu,
  Divider,
  Table,
  Modal,
  Progress,
  List,
  Typography,
  Result,
  Tag,
} from 'antd'
import {
  toast,
  confirm,
  confir2m,
  graphQLErrorsToToast,
  toLocaleString,
  successToast,
} from '../../../../helpers/helper'
import { getCurrencyWithCode, getCurrenciesWithoutMatchWithCode } from '../../../../data/Currency'
import NewPayment from './../NewPayment/NewPayment'
import InvoicePrintModal from './../../InvoicePrintModal/InvoicePrintModal'
import { deleteData } from '../../../../helpers/trait'
import apolloClient from './../../../../helpers/apolloClient'
import InvoiceEArchiveModal from './../../InvoiceEArchiveModal/InvoiceEArchiveModal'
import SettleNetResult from '../SettleNetResult/SettleNetResult'
import routes from '../../../../routes'
import { postPanelApi } from '../../../../api'
import { hasLicence, openBase64NewTab } from '../../../../utils'
import { licenceMethods } from '../../../../enums'

const READ_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      data: ${type}Invoice(transaction_id: $transaction_id) {
      transaction_id
      customer_id
      customer {
      name
      e_mail
      }
      currency
      code
      no
      series
      as_waybill
      invoice_status_type
      category {
      name
      }
      return_invoices {
      transaction_id
      invoice_id
      customer_id
      currency
      code
      no
      series
      category_id
      total
      discount
      discount_type
      total_vat
      grand_total
      paid
      remaining
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      delivery_postal_code
      products {
      invoice_product_id
      product_id
      product {
      name
      unit {
      name
      }
      }
      quantity
      amount
      vat
      grand_total
      }
      additional_charges {
      invoice_additional_charge_id
      additional_charge_id
      additional_charge {
      name
      unit {
      name
      }
      }
      quantity
      amount
      vat
      grand_total
      }
      }
      waybills {
      waybill_id
      invoice_transaction_id
      customer_id
      no
      explanation
      date
      sending_date
      waybill_country
      waybill_city
      waybill_town
      waybill_address
      }
      total
      discount
      discount_type
      total_vat
      grand_total
      paid
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      invoice_postal_code
      delivery_postal_code
      e_invoice_type
      e_invoice_id
      e_invoice_uuid
      ${type === 'sales' ? 'order_platform_number' : ''}
      products {
      invoice_product_id
      product_id
      product {
      name
      name_on_invoice
      unit {
      name
      }

      }
      quantity
      amount
      vat
      grand_total
      }
      additional_charges {
      invoice_additional_charge_id
      additional_charge_id
      additional_charge {
      name
      unit {
      name
      }
      }
      quantity
      amount
      vat
      grand_total
      }
      commits {
      paid_transaction_id
      invoice_transaction_id
      currency_amounts {
      try
      usd
      eur
      gbp
      rub
      }
      paid {
      __typename
      ... on ${type === 'sales' ? 'Debit' : 'Credit'} {
      transaction_id
      amount
      safe {
      name
      currency
      }
      explanation
      }
      ... on AccountOpeningCustomer {
      transaction_id
      amount
      customer_id
      currency
      }
      }
      }
      }
      settings {
      invoice_settings {
      product_invoice_name_status
      }
      }
      }
  `
)

const TAHSILDAR_PAYMENT_NOTIFICATION_QUERY = (
  gql`
      mutation ($notification_info: SendPaymentNotificationWithoutCustomerSaveInput!){
          sendPaymentNotificationWithoutCustomerSave(notification_info: $notification_info) {
              informative {
                  messages
              }
          }
      }
  `
)

const CREATE_SETTLE_NET_QUERY = (
  gql`
      mutation($settle_net_informations: SettleNetCreateInput) {
          settleNetCreate(settle_net_informations: $settle_net_informations) {
              payment_informations {
                  code
                  grand_total
                  paid
              }
              informative {
                  messages
              }
          }
      }
  `
)

const TAHSILDAR_PAYMENT_CONTROL_QUERY = (
  gql`
      mutation($customer_id: Int!, $priority_invoice: Int){
          checkPaymentNotification(customer_id: $customer_id, priority_invoice: $priority_invoice) {
              all_payments {
                  e_mail
                  will_pay
                  paid_credit
                  currency
                  created_at
                  paid_at
                  jetofis_paid_status
                  tahsildar_notification_id
              }
              informative {
                  messages
              }
          }
      }
  `
)

const UPDATE_QUERY = type => (
  gql`
      mutation invoiceUpdate($transaction_id: Int!, $invoice: InvoiceUpdateInput!){
      invoice: ${type}InvoiceUpdate(transaction_id: $transaction_id, ${type}_invoice: $invoice){
      invoice: ${type}_invoice {
      transaction_id
      }
      informative {
      messages
      }
      }
      }
  `
)

class InvoiceDetail extends Component {

  transaction_id = null

  constructor(props) {
    super(props)
    this.transaction_id = Number(props.match.params.id)

    this.setTahsildarPaymentNotification = debounce(this.setTahsildarPaymentNotification, 800)
  }

  state = {
    data: null,
    printModal: false,
    invoice_id: null,
    noMoreReturnInvoice: false,
    isToday: null,
    dateDiff: null,
    product_invoice_name_status: false,
    marketplaceCustomer: '',
    tahsildar: {
      tahsildarPayments: null,
      showTahsildarPaymentControl: false,
      payable: null,
      tahsildar_notification_id: null,
    },
    settleNet: {
      result: [],
      status: false,
    },
    marketplaceAddress: null,

    operation_in_progress: false,
    show_e_archive_create_modal: false,

    integration_codes: [],
  }

  componentDidMount() {

    let type = this.props.match.params.type
    if (this.typeEnglishName[type])
      type = this.typeEnglishName[type]
    this.setState({ type })

    this.props.breadcrumb_add(this.props.langData[capitalize(type) + ' Invoice'], generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
      type: this.props.langData[type],
      id: this.transaction_id,
    }))

    this.deleteData = deleteData(type + 'Invoice', 'transaction_id').bind(this)

    postPanelApi('/invoice/invoice-open', { transaction_id: this.transaction_id }).then(result => {

      if (result) {

        this.setState({
          integration_codes: result.integration_codes,
        })

      }

    })

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      if (e.target.location.pathname.includes(this.props.langData['paying'])) {
        this.props.history.go(-2)
      }
    }
  }

  commitColumns = [
    {
      title: this.props.langData['Safe'],
      dataIndex: 'paid',
      width: '20%',
      render: value => value.safe ? value.safe.name : '-',
    },
    {
      title: this.props.langData['Explanation'],
      dataIndex: 'paid.explanation',
      width: '35%',
      render: value => value ? value : '-',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'paid.amount',
      width: '20%',
      className: 'text-right',
      render: (value, record) => {
        return `${toLocaleString(value) + ' ' + (record.paid.safe ? getCurrencyWithCode(record.paid.safe.currency).symbol : getCurrencyWithCode(record.paid.currency).symbol)}`
      },
    },
    {
      title: this.props.langData['Amount processed'],
      className: 'text-right',
      width: '25%',
      render: (value, record) => {
        return (
          <div>
            <div>{toLocaleString(record.currency_amounts[this.state.data.currency]) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</div>
            {getCurrenciesWithoutMatchWithCode(this.state.data.currency).map((x, k) => {
              if (record.currency_amounts[x.code])
                return <div
                  key={k}>{`${toLocaleString(record.currency_amounts[x.code]) + ' ' + getCurrencyWithCode(x.code).symbol}`}</div>
            })}
          </div>
        )
      },
    },
  ]

  productAdditionalColumns = [
    {
      title: this.props.langData['Product'],
      width: '25%',
      dataIndex: 'product.name|additional_charge.name',
      render: (value, record) => {
        if (record.product) {
          if (this.state.product_invoice_name_status && record.product.name_on_invoice) {
            return record.product.name + ' | ' + record.product.name_on_invoice
          } else {
            return record.product.name
          }
        }

        return record.additional_charge.name

      },
    },
    {
      title: this.props.langData['Quantity'],
      width: '15%',
      dataIndex: 'quantity',
      render: (value, record) => {
        return value + ' ' + (typeof record.product !== 'undefined' ? (record.product.unit ? record.product.unit.name : null) : (record.additional_charge.unit ? record.additional_charge.unit.name : null))
      },
    },
    {
      title: this.props.langData['Price'],
      dataIndex: 'amount',
      width: '20%',
      className: 'text-right',
      render: value => `${toLocaleString(value) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}`,
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '20%',
      className: 'text-right',
      render: value => value ? value + '%' : '-',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'grand_total',
      width: '20%',
      className: 'text-right',
      render: value => `${toLocaleString(value) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}`,
    },
  ]

  typeEnglishName = {
    'satis': 'sales',
    'alis': 'purchase',
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      id: record.paid.__typename === 'AccountOpeningCustomer' ? record.paid.customer_id : record.paid_transaction_id,
      type: record.paid.__typename === 'AccountOpeningCustomer' ? 'customer-account-opening' : record.paid.__typename.toLowerCase(),
    }))
  }

  createWaybill = () => {
    this.props.history.push('/' + compile(this.props.langData.route['waybill/new/:type/:id'])({
      type: this.props.langData[this.state.type === 'sales' ? 'Sending' : 'Incoming'],
      id: this.state.data.transaction_id,
    }))
  }

  delete = () => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteData(this.transaction_id)
    })
  }

  onPrint = value => {
    let printPage = value
    let pageFields = {
      type: 'v',
      copy: 1,
    }
    switch (printPage) {
      case 'A4-h4':
        pageFields.type = 'h'
        pageFields.copy = 4
        break
      case 'A5-h':
        pageFields.type = 'h'
        break
      case 'A4-h3':
        pageFields.type = 'h'
        pageFields.copy = 3
        break
    }

    if (pageFields.type === 'v' && pageFields.copy === 1) {
      window.open(
        `${process.env.REACT_APP_NEW_API_URL}/invoice/printMultiple/${localStorage.getItem('token')}/${this.transaction_id}/${pageFields.copy}x/${pageFields.type}/${this.props.lang}`,
        '_blank',
      )
    } else {
      window.open(
        `${process.env.REACT_APP_NEW_API_URL}/invoice/printMultipleOnePage/${localStorage.getItem('token')}/${this.transaction_id}/${pageFields.copy}/${this.props.lang}`,
        '_blank',
      )
    }
  }

  eInvoicePrint = async () => {

    this.setState({
      operation_in_progress: true,
    })

    return postPanelApi('/invoice/open-pdf', { transaction_id: this.transaction_id }).then(result => {

      if (result) {

        openBase64NewTab(result.data)

      }

      this.setState({
        operation_in_progress: false,
      })

    })

  }

  print() {
    this.setState({ printModal: true })
  }

  edit = () => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['invoice/:type/update/:id'])({
        type: this.props.langData[this.state.type],
        id: this.transaction_id,
      }),
    )
  }

  returnInvoice = () => {
    this.props.history.push(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
      type: this.state.type,
      id: this.transaction_id,
    }))
  }

  eInvoice = async () => {

    this.setState({ operation_in_progress: true })

    postPanelApi('/invoice/einvoice-create', {
      multiple: false,
      transaction_id: this.transaction_id,
      profile: 'TICARIFATURA',
    }).then(result => {

      if (result) {

        successToast(result.data.result.message)

        this.setState({
          data: {
            ...this.state.data,
            e_invoice_type: result.data.result.einvoice.type,
            e_invoice_id: result.data.result.einvoice.id,
            e_invoice_uuid: result.data.result.einvoice.uuid,
          },
        })

      }

      this.setState({ operation_in_progress: false })

    })

  }

  eArchive = async () => {
    this.setState({ show_e_archive_create_modal: true })
  }

  invoiceEArchiveModalCallback = async data => {

    return postPanelApi('/invoice/einvoice-create', {
      multiple: false,
      transaction_id: this.transaction_id,
      profile: 'EARSIVFATURA',
      data: data,
    }).then(result => {

      if (result) {

        successToast(result.data.result.message)

        this.setState({
          data: {
            ...this.state.data,
            e_invoice_type: result.data.result.einvoice.type,
            e_invoice_id: result.data.result.einvoice.id,
            e_invoice_uuid: result.data.result.einvoice.uuid,
          },
        })

        // modal'a işin sorunsuz tamamlandığının bilgisini veriyoruz
        return true

      }

      // işlemin başarılı bir şekilde tamamlanmadığının bilgisini modal'a vermiş oluyoruz
      return false

    })

  }

  setTahsildarPaymentNotification = async () => {
    const fullName = this.state.data.customer.name.trim()
    const name = fullName.split(' ').slice(0, -1).join(' ')
    const surname = fullName.split(' ').slice(-1).join(' ')
    try {
      const result = await apolloClient.mutate({
        mutation: TAHSILDAR_PAYMENT_NOTIFICATION_QUERY,
        variables: {
          notification_info: {
            first_name: name,
            last_name: surname,
            email: this.state.data.customer.e_mail,
            customer_id: this.state.data.customer_id,
            price: this.state.data.grand_total - this.state.data.paid,
            priority_invoice: this.state.data.transaction_id,
          },
        },
      })

      toast(true, result.data.sendPaymentNotificationWithoutCustomerSave.informative.messages)
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }

  controlTahsildarPaymentNotification = async () => {
    try {
      const result = await apolloClient.mutate({
        mutation: TAHSILDAR_PAYMENT_CONTROL_QUERY,
        variables: {
          customer_id: this.state.data.customer_id,
          priority_invoice: this.state.data.transaction_id,
        },
      })

      toast(true, result.data.checkPaymentNotification.informative.messages)
      this.setState({
        tahsildar: {
          tahsildarPayments: result.data.checkPaymentNotification.all_payments,
          showTahsildarPaymentControl: true,
        },
      })
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }

  applySettleNet = async refetch => {
    try {
      const result = await apolloClient.mutate({
        mutation: CREATE_SETTLE_NET_QUERY, variables: {
          settle_net_informations: {
            customer_id: this.state.data.customer_id,
            amount: this.state.data.grand_total - this.state.data.paid,
            date: moment().format('YYYY-MM-DD HH:mm'),
            priority_invoice: this.transaction_id,
          },
        },
      })
      toast(true, result.data.settleNetCreate.informative.messages)
      this.setState({
        settleNet: {
          status: true,
          result: result.data.settleNetCreate.payment_informations,
        },
      })
      const refetchData = await refetch()
      this.setState({
        data: refetchData.data.data,
      })
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  cancelETransformation = () => {

    const einvoiceTypeText = this.props.langData[this.state.data.e_invoice_type === 'EInvoice' ? 'E-Invoice' : 'E-Archive']

    confir2m(this.props.langData['E-Invoice will be cancelled. Are you sure?'](einvoiceTypeText), (confirmed) => {

      if (confirmed) {

        this.setState({
          operation_in_progress: true,
        })

        postPanelApi('/invoice/einvoice-cancel', {
          transaction_id: this.transaction_id,
          type: this.state.data.e_invoice_type === 'EInvoice' ? 'EInvoice' : 'EArchive',
        }).then(result => {

          if (result) {

            successToast(result.message)

            this.setState({
              data: {
                ...this.state.data,
                e_invoice_type: null,
                e_invoice_id: null,
                e_invoice_uuid: null,
              },
            })

          }

          this.setState({
            operation_in_progress: false,
          })

        })

      }

    })

  }

  sendEmail = () => {

    postPanelApi('/invoice/send-email-step1', { transaction_id: this.transaction_id }).then(result => {

      if (result) {

        confir2m(result.receipent_name + ' isimli müşterinin ' + result.receipent_email + ' e-posta adresine faturanın PDF\'i gönderilecek.', (confirmed) => {

          if (confirmed) {

            postPanelApi('/invoice/send-email-step2', { transaction_id: this.transaction_id }).then(result => {

              if (result) {

                successToast(result.message)

              }

            })

          }

        })

      }

    })

  }

  onRowClick = record => {
    if (record.product_id) {
      this.props.history.push('/' + compile(this.props.langData.route['product/detail/:id'])({
        id: record.product_id,
      }))
    }
    if (record.additional_charge_id) {
      this.props.history.push(generatePath(routes.ADDITIONAL_CHARGE_UPDATE, {
        id: record.additional_charge_id,
      }))
    }
  }

  menuOnClick = item => {

    switch (item.key) {

      // işlemler menüsü
      case 'print':
        this.print()
        break
      case 'edit':
        this.edit()
        break
      case 'delete':
        this.delete()
        break
      case 'returnInvoice':
        this.returnInvoice()
        break

      // e-fatura menüsü
      case 'eInvoice':
        this.eInvoice()
        break
      case 'eArchive':
        this.eArchive()
        break
    }

  }


  cancelInvoice = async () => {
    confirm(this.state.data.invoice_status_type === 'Active' ? 'Cancel Invoice Message' : 'Activate Invoice Message', async result => {
      if ( ! result) {
        return
      }

      try {
        const resultData = await apolloClient.mutate({
          mutation: UPDATE_QUERY(this.state.type), variables: {
            transaction_id: this.transaction_id,
            invoice: {
              invoice_status_type: this.state.data.invoice_status_type === 'Active' ? 'Cancel' : 'Active',
            },
          },
        })
        toast(true, resultData.data.invoice.informative.messages)
        this.setState({
          data: {
            ...this.state.data,
            invoice_status_type: this.state.data.invoice_status_type === 'Active' ? 'Cancel' : 'Active',
          },
        })
      } catch (error) {
        graphQLErrorsToToast(error)
      }
    })
  }

  render() {
    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        {this.state.type === 'sales' && <Menu.Item key="print">
          <span><Icon type="printer"/> {this.props.langData['Print']}</span>
        </Menu.Item>}
        <Menu.Item key="edit">
          <span><Icon type="edit"/> {this.props.langData['Edit']}</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']}</span>
        </Menu.Item>
        { ! this.state.noMoreReturnInvoice && <Menu.Item key="returnInvoice">
          <span><Icon type="rollback"/>{this.props.langData['Create Return']}</span>
        </Menu.Item>}
      </Menu>
    )

    const menuEInvoice = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="eInvoice">
          <span><Icon type="file-protect"/> {this.props.langData['E-Invoice']}</span>
        </Menu.Item>
        <Menu.Item key="eArchive">
          <span><Icon type="file-text"/> {this.props.langData['E-Archive']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={READ_QUERY(this.state.type)}
        variables={{ transaction_id: Number(this.transaction_id) }}
        skip={ ! this.state.type}
        fetchPolicy="network-only"
      >
        {({ loading, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loading && data) {
            // işlem yoksa hata ver ve yönlendir
            if (data && ! data.data) {
              toast(false, this.props.langData['x not found']('Invoice'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data) {
              // geçiş yumuşak olsun diye 1 saniye bekleme attık
              let copyData = cloneDeep(data.data)
              // eğer indirim var ise bunu burda hesaplamamız gerek
              if (copyData.discount) {
                if (copyData.discount_type === 'Percent') {
                  let discount_total = (copyData.total + copyData.total_vat) * copyData.discount / 100
                  copyData.discount_total = discount_total
                } else {
                  copyData.discount_total = copyData.discount
                }
              }
              if (copyData.return_invoices.length)
                this.setState({ invoice_id: copyData.return_invoices.map((x) => x.invoice_id) })
              this.setState({ data: copyData })

              if (copyData.return_invoices.length) {
                let returnedAdditionalCharges = 0, returnedProducts = 0, additionalCharges = 0, products = 0

                if (copyData.additional_charges.length) {
                  copyData.additional_charges.forEach((x, l) => {
                    additionalCharges += copyData.additional_charges.map(x => x.quantity)[l]
                    copyData.return_invoices.filter(x => x.additional_charges.map(x => x.additional_charge_id)[l] === copyData.additional_charges.map(x => x.additional_charge_id)[l]).map(x => {
                      returnedAdditionalCharges += x.additional_charges.map(x => x.quantity)[0]
                    })
                  })
                }
                if (copyData.products.length) {
                  copyData.products.forEach((x, l) => {
                    products += copyData.products.map(x => x.quantity)[l]
                    copyData.return_invoices.filter((x, k) => x.products.map(x => x.product_id)[l] === data.data.products.map(x => x.product_id)[l]).map((x, k) => {
                      returnedProducts += x.products.map(x => x.quantity)[0]
                    })
                  })
                }
                this.setState({
                  noMoreReturnInvoice: (returnedAdditionalCharges === additionalCharges) && (returnedProducts === products),
                })
              }

              if (copyData.invoice_address && copyData.invoice_address.includes('-->', '-&-')) {
                const firstSplit = copyData.invoice_address.split('-->')[1]
                const secondSplit = firstSplit.split('-&-')[0].trim()

                let marketplaceAddress = copyData.invoice_address.replace('-->', `<br/><strong> ${this.props.langData['Customer Name']} </strong>:`).replaceAll('-&-', '<br/>')
                this.setState({
                  marketplaceCustomer: secondSplit,
                  marketplaceAddress,
                })
              }

              this.setState({
                data: copyData,
                isToday: moment(copyData.expiry_date).isSame(moment(), 'day'),
                dateDiff: (moment().diff(moment(copyData.expiry_date), 'days')),
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })
            }
          }
          return (
            <Row gutter={16}>
              {this.state.settleNet.status && this.state.settleNet.result.length > 0 &&
                <SettleNetResult data={this.state.settleNet.result}
                                 onCancel={() => this.setState({
                                   settleNet: {
                                     result: [],
                                     status: false,
                                   },
                                 })} {...this.props} />}
              {this.state.printModal &&
                <InvoicePrintModal onPrint={this.onPrint} onCancel={() => this.setState({ printModal: false })}/>}

              {this.state.show_e_archive_create_modal && (
                <InvoiceEArchiveModal
                  langData={this.props.langData}
                  callback={this.invoiceEArchiveModalCallback}
                  onCancel={() => this.setState({ show_e_archive_create_modal: false })}
                />
              )}

              <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="idcard"/> &nbsp;{this.props.langData['Invoice Detail']}</span>}
                  bordered={false}
                  loading={ ! this.state.data}
                  extra={[
                    (this.state.type !== 'purchase') &&
                    <Dropdown overlay={menuEInvoice} key="1" trigger={['click']}
                              disabled={this.state.operation_in_progress}>
                      <Button loading={ ! this.state.data}>
                        {this.props.langData['E-Invoice']} {this.state.data && <Icon type="down"/>}
                      </Button>
                    </Dropdown>,
                    <Dropdown overlay={menu} key="2" trigger={['click']} disabled={this.state.operation_in_progress}>
                      <Button loading={ ! this.state.data} style={{ marginLeft: 5 }}>
                        {this.props.langData['Actions']} {this.state.data && <Icon type="down"/>}
                      </Button>
                    </Dropdown>,
                    (this.state.integration_codes.includes('TSOFT') && (
                      <Dropdown overlay={(
                        <Menu className="menu-default">
                          <Menu.Item
                            key="1"
                            onClick={() => this.props.history.push(generatePath(routes.INTEGRATION_TSOFT_SYNC_INVOICE, { id: this.transaction_id }))}
                          >
                            <span><SyncOutlined/> Fatura Bilgilerini Senkronize Et</span>
                          </Menu.Item>
                        </Menu>
                      )} key="3" trigger={['click']} disabled={this.state.operation_in_progress}>
                        <Button style={{ marginLeft: 5 }}>
                          TSOFT {this.state.data && <Icon type="down"/>}
                        </Button>
                      </Dropdown>
                    )),
                  ]}
                >
                  { ! this.state.data && <div style={{ width: '100%', height: 150 }}></div>}

                  {this.state.data && <React.Fragment>
                    <div className="form-block label-default">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="tag"/><p>{this.props.langData['Type']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.type === 'sales' ? this.props.langData['Sales Invoice'] : this.props.langData['Purchase Invoice']}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          <Link to={generatePath(routes.CUSTOMER_DETAIL, { id: this.state.data.customer_id })}>
                            <span style={{ color: '#1890ff' }}>{this.state.data.customer.name}</span>
                          </Link>
                        </Col>
                      </Row>

                      {this.state.data.category &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.category.name}
                          </Col>
                        </Row>
                      }

                      {this.state.marketplaceCustomer &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="user"/><p>{this.props.langData['Marketplace Customer']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.marketplaceCustomer}
                          </Col>
                        </Row>
                      }

                      {this.state.data.code &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.code}
                          </Col>
                        </Row>
                      }

                      {
                        this.state.data.order_platform_number &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="paper-clip"/><p>{this.props.langData['Marketplace Order No']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.order_platform_number}
                          </Col>
                        </Row>
                      }

                      {(this.state.data.series || this.state.data.no) &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="paper-clip"/><p>{this.props.langData['Invoice No']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.series ? this.state.data.series : '-'} / {this.state.data.no ? this.state.data.no : '-'}
                          </Col>
                        </Row>
                      }

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {moment(this.state.data.date).locale(this.props.lang).format('DD MMMM YYYY H:mm')}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="calendar"/><p>{this.props.langData['Expiry Date']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.expiry_date ? moment(this.state.data.expiry_date).locale(this.props.lang).format('DD MMMM YYYY') : '-'}
                        </Col>
                      </Row>

                      {this.state.data.invoice_country && <div>
                        <Divider/>
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Country']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.invoice_country}
                          </Col>
                        </Row>
                      </div>}

                      {(this.state.data.invoice_city || this.state.data.invoice_town) &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="environment-o"/><p>{this.props.langData['Invoice City/Town']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {
                              (this.state.data.invoice_city ? this.state.data.invoice_city : '-') + '/' +
                              (this.state.data.invoice_town ? this.state.data.invoice_town : '-')
                            }
                          </Col>
                        </Row>}

                      {this.state.data.invoice_postal_code && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Postal Code']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.invoice_postal_code}
                        </Col>
                      </Row>}

                      {this.state.marketplaceAddress && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Address']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          <div dangerouslySetInnerHTML={{ __html: this.state.marketplaceAddress }}/>
                        </Col>
                      </Row>}

                      {this.state.data.delivery_country &&
                        <div>
                          <Divider/>
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Country']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                              {this.state.data.delivery_country}
                            </Col>
                          </Row>
                        </div>
                      }

                      {(this.state.data.delivery_city || this.state.data.delivery_town) &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="environment-o"/><p>{this.props.langData['Delivery City/Town']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {
                              (this.state.data.delivery_city ? this.state.data.delivery_city : '-') + '/' +
                              (this.state.data.delivery_town ? this.state.data.delivery_town : '-')
                            }
                          </Col>
                        </Row>}

                      {this.state.data.delivery_postal_code && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Postal Code']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.delivery_postal_code}
                        </Col>
                      </Row>}

                      {this.state.data.delivery_address && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Address']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.delivery_address}
                        </Col>
                      </Row>}

                      {this.state.data.discount && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Discount']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.discount + (this.state.data.discount_type === 'Percent' ? '%' : (' ' + getCurrencyWithCode(this.state.data.currency).symbol))}
                        </Col>
                      </Row>}

                    </div>

                    {this.state.data.explanation &&
                      <div>
                        <Divider/>
                        <div style={{ padding: '0px 10px 20px 10px', textAlign: 'center' }}>
                          {this.state.data.explanation}
                        </div>
                      </div>
                    }
                  </React.Fragment>}

                </Card>

                <Card
                  bodyStyle={{ padding: 0, marginTop: 20 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon
                      type="star-o"/> {this.props.langData['Products'] + '/' + this.props.langData['Additional Charges']}
                  </Divider>

                  <Table
                    className="table-no-border"
                    columns={this.productAdditionalColumns}
                    rowKey={record => record.invoice_product_id ? record.invoice_product_id + 'p' : record.invoice_additional_charge_id + 'a'}
                    dataSource={this.state.data ? this.state.data.products.concat(this.state.data.additional_charges) : null}
                    loading={ ! this.state.data}
                    onRow={record => ({
                      onClick: () => this.onRowClick(record),
                    })}
                    pagination={false}
                    style={{ borderRadius: '0px', cursor: 'pointer' }}
                    rowClassName="white"
                    size="small"
                    scroll={{ x: 700, y: 500 }}
                    bordered
                  />

                  {this.state.data &&
                    <div className="form" style={{ textAlign: 'right', width: '100%', height: 'auto', marginTop: 20 }}>

                      <div className="fl-r" style={{ marginRight: 10 }}>
                        <p>{toLocaleString(this.state.data.total) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        <p>{toLocaleString(this.state.data.total_vat) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        {this.state.data.discount &&
                          <p>{toLocaleString(this.state.data.discount_total) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        }
                        <p>
                          <strong>{toLocaleString(this.state.data.grand_total) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</strong>
                        </p>
                      </div>

                      <div className="fl-r" style={{ marginRight: 26 }}>
                        <p>{this.props.langData['Interim Total']}</p>
                        <p>{this.props.langData['Total VAT']}</p>
                        {this.state.data.discount && <p>{this.props.langData['Total Discount']}</p>}
                        <p><strong>{this.props.langData['Grand Total']}</strong></p>
                      </div>

                    </div>}

                </Card>

                <Card
                  bodyStyle={{ padding: 0, marginTop: 20 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon type="swap"/> {this.props.langData['Payment List']}
                  </Divider>

                  <Table
                    columns={this.commitColumns}
                    rowKey={Math.floor(Math.random() * 999999) + 1}
                    dataSource={this.state.data ? this.state.data.commits : null}
                    loading={ ! this.state.data}
                    pagination={false}
                    onRow={record => ({
                      onClick: () => this.rowItemClick(record),
                    })}
                    style={{ borderRadius: '0px' }}
                    rowClassName="white"
                    scroll={{ x: 700 }}
                  />

                </Card>

              </Col>
              {this.state.data && <Col xs={24} sm={24} md={10} lg={8} xl={7}>
                <Route exact path={routes.INVOICE_DETAIL_BY_TYPE} render={() => {
                  return (
                    <Card
                      bodyStyle={{ padding: '0px' }}
                      title={
                        (this.state.data.grand_total - this.state.data.paid) && (
                          <React.Fragment>
                            <Button className={this.state.type === 'sales' ? 'w-100' : 'w-50'}
                                    type={this.state.type === 'sales' ? 'primary' : 'danger'}
                                    disabled={this.state.data.invoice_status_type === 'Cancel'}>
                              <Link to={generatePath(routes.INVOICE_DETAIL_PAY_BY_TYPE, {
                                type: this.props.langData[this.state.type],
                                id: this.transaction_id,
                              })}
                              >
                                {this.props.langData[this.state.data.invoice_status_type === 'Cancel' ? 'Invoice Has Been Cancelled' : this.state.type === 'sales' ? 'Get Payment' : 'Pay']}
                              </Link>
                            </Button>
                            {this.state.type === 'purchase' &&
                              <Button className="w-50" type="primary" onClick={() => this.applySettleNet(refetch)}>
                                {this.props.langData['Settle Net']}
                              </Button>}
                          </React.Fragment>
                        )
                      }
                      bordered={false}
                    >
                      <div className="form-block">
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span style={{ fontSize: 15 }}>{this.props.langData['Remaining']}</span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="text-right">
														<span style={{ fontSize: 18, fontWeight: 'bold' }}>
															{toLocaleString((this.state.data.grand_total - this.state.data.paid))}
														</span>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>
															&nbsp;{getCurrencyWithCode(this.state.data.currency).symbol}
														</span>
                          </Col>
                        </Row>
                        <Row gutter={24}
                             style={{ marginBottom: ! (this.state.data.grand_total - this.state.data.paid) > 0 ? 16 : 0 }}>
                          <Progress
                            percent={parseFloat((this.state.data.paid * 100) / this.state.data.grand_total === 100 ? ((this.state.data.paid * 100) / this.state.data.grand_total).toFixed(0) : ((this.state.data.paid * 100) / this.state.data.grand_total).toFixed(1))}
                            strokeColor={{
                              '0%': '#108ee9',
                              '100%': '#87d068',
                            }}
                            format={value => value.toString().replace('.', ',') + '%'}
                            status="active"
                          />
                        </Row>
                        {(this.state.data.grand_total - this.state.data.paid) > 0 && <div>
                          <Divider/>
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={9} sm={10} md={8} lg={8} xl={10}>
                              <span
                                style={{ fontSize: 15, color: '#9ea6ad' }}>{this.props.langData['Payment Date']}</span>
                            </Col>
                            <Col xs={15} sm={14} md={16} lg={16} xl={14} className="text-right">
                              <span style={{
                                fontSize: 18,
                                color: 'red',
                              }}>{this.state.data.expiry_date ? moment(this.state.data.expiry_date).locale(this.props.lang).format('DD MMMM YYYY') : '-'}</span>
                            </Col>
                            {this.state.dateDiff >= 0 &&
                              <Col xs={12} sm={24} md={16} lg={16} xl={24} className="text-right"
                                   style={{ float: 'right' }}>
                              <span style={{
                                fontSize: 15,
                                color: 'red',
                              }}>{this.state.isToday ? `(${this.props.langData['Expiry date is today']})` : this.state.dateDiff > 0 && `(${this.props.langData['Days Late'](moment().diff(moment(this.state.data.expiry_date), 'days'))})`}
                                <br/></span>
                              </Col>}
                          </Row>
                        </div>}

                      </div>
                    </Card>
                  )
                }}/>

                <Route path={routes.INVOICE_DETAIL_PAY_BY_TYPE} render={props => {
                  const newNewPayment = (
                    <NewPayment
                      type={this.state.type}
                      payable={this.state.tahsildar.payable ? this.state.tahsildar.payable : null}
                      tahsildar_notification_id={this.state.tahsildar.tahsildar_notification_id ? this.state.tahsildar.tahsildar_notification_id : null}
                      priority_invoice={this.transaction_id}
                      callback={async () => {
                        await this.setState({ data: null })
                        await refetch().then(val => {
                          this.setState({ data: val.data.data })
                        })
                      }}
                      {...props}
                    />
                  )
                  if (this.props.sizeWidth <= 576) {
                    return (
                      <Modal
                        title={
                          <span><Icon type="pay-circle-o"/> &nbsp;{
                            this.props.langData[this.state.type === 'sales' ? 'Get Payment' : 'Pay']
                          }</span>
                        }
                        bodyStyle={{ padding: 8 }}
                        visible={true}
                        footer={null}
                        onCancel={() => {
                          props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
                            type: this.props.langData[this.state.type],
                            id: this.transaction_id,
                          }))
                        }}
                      >
                        {newNewPayment}
                      </Modal>
                    )
                  }

                  this.setState({
                    tahsildar: {
                      payable: null,
                      tahsildar_notification_id: null,
                    },
                  })

                  return newNewPayment

                }}
                />
              </Col>}

              {(this.state.data && this.state.data.e_invoice_type) && (
                <Col xs={24} sm={24} md={10} lg={8} xl={7}>
                  <Card
                    bodyStyle={{ padding: '0px' }}
                    style={{ marginTop: 10 }}
                    title={
                      <Button
                        className="w-100" type={this.state.type === 'sales' ? 'primary' : 'danger'}
                        onClick={this.eInvoicePrint}
                        disabled={this.state.operation_in_progress}
                      >
                        <PrinterOutlined/> {this.state.data.e_invoice_type === 'EInvoice' ? this.props.langData['E-Invoice Print'] : this.props.langData['E-Archive Print']}
                      </Button>
                    }
                    bordered={false}
                  >
                    <div className="form-block">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                          <span style={{ fontSize: 15 }}>ID</span>
                        </Col>
                        <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.data.e_invoice_id}
												</span>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                          <span style={{ fontSize: 15 }}>UUID</span>
                        </Col>
                        <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.data.e_invoice_uuid}
												</span>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Card
                          bodyStyle={{ padding: '0px' }}
                          style={{ marginTop: 10 }}
                          title={
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Button
                                  disabled={this.state.operation_in_progress}
                                  className="w-100"
                                  type="danger"
                                  onClick={this.cancelETransformation}
                                >
                                  <PrinterOutlined/> {this.state.data.e_invoice_type === 'EInvoice' ? this.props.langData['E-Invoice Cancel'] : this.props.langData['E-Archive Cancel']}
                                </Button>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Button disabled={this.state.operation_in_progress} className="w-100" type="danger"
                                        onClick={this.sendEmail}>
                                  <MailOutlined/> {this.props.langData['Send E-Mail']}
                                </Button>
                              </Col>
                            </Row>
                          }
                          bordered={false}
                        />
                      </Row>
                    </div>
                  </Card>
                </Col>
              )}

              {this.state.data && this.state.data.as_waybill &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 10, textAlign: 'center' }}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    title={
                      <span>
												<Icon type="car"/>&nbsp; {this.props.langData['Invoice With Waybill']}
											</span>
                    }
                    bordered={false}
                  />
                </Col>
              }

              {this.state.data &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 10, textAlign: 'center' }}>
                  <Card
                    bodyStyle={{ padding: this.state.data.invoice_status_type === 'Cancel' ? 25 : 0 }}
                    title={
                      <Button className="w-100" type="danger" onClick={this.cancelInvoice}>
                        {this.props.langData[this.state.data.invoice_status_type === 'Active' ? 'Cancel Invoice' : 'Activate Invoice']}
                      </Button>
                    }
                    bordered={false}
                  >
                    {this.state.data.invoice_status_type === 'Cancel' ? this.props.langData['Cancel Invoice Detail Message'] : ''}
                  </Card>
                </Col>
              }

              {this.state.data && ! this.state.data.as_waybill &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 16 }}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    title={
                      <Button className="w-100" type={this.state.type === 'sales' ? 'primary' : 'danger'}
                              onClick={this.createWaybill}>
                        <Icon type="car"/>&nbsp;
                        {this.state.type === 'sales' ? this.props.langData['Create Sending Waybill'] : this.props.langData['Create Incoming Waybill']}
                      </Button>
                    }
                    bordered={false}
                  >
                    {this.state.data.waybills.length > 0 && <div style={{ marginBottom: 16, marginTop: 16 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Menu
                          defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          mode="inline"
                          inlineIndent={8}
                        >
                          <Menu.SubMenu
                            key="key1"
                            title={
                              <span style={{ fontWeight: 'bold' }}><Icon
                                type="file-text"/>{this.props.langData['Pieces waybill was created'](this.state.data.waybills.length)}</span>
                            }
                          >
                            {this.state.data.waybills.map((x, k) => {
                              k = k + 1
                              return (
                                <Menu.Item key={x.waybill_id} style={{ marginBottom: 10, marginTop: 10 }}>
                                  <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                    <Button className="w-100" size="large">
                                      <Link to={
                                        compile('/' + this.props.langData.route['waybill/:type/detail/:id'])({
                                          type: this.props.langData[this.state.type === 'sales' ? 'Sending' : 'Incoming'],
                                          id: x.waybill_id,
                                        })}>
                                      <span style={{ float: 'left' }}><Icon
                                        type="car"/>&nbsp;{this.props.langData['Waybill']} {k}</span>
                                        {this.props.sizeWidth > 320 &&
                                          <span style={{ float: 'right' }}>{moment(x.date).format('DD.MM.YYYY')}</span>}
                                      </Link>
                                    </Button>
                                  </Col>
                                </Menu.Item>
                              )
                            })}
                          </Menu.SubMenu>
                        </Menu>
                      </Col>
                    </div>}
                  </Card>
                </Col>
              }

              {
                this.state.data && hasLicence(this.props.licences, licenceMethods.TAHSILDAR) && this.state.type === 'sales' && (this.state.data.grand_total - this.state.data.paid) > 0 &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 16 }}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    title={
                      <Button className="w-100" type="primary" onClick={this.setTahsildarPaymentNotification}>
                        {this.props.langData['Send Payment Notification']}
                      </Button>
                    }
                    bordered={false}
                  />
                </Col>
              }

              {
                this.state.data && hasLicence(this.props.licences, licenceMethods.TAHSILDAR) && this.state.type === 'sales' && (this.state.data.grand_total - this.state.data.paid) > 0 &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 16 }}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    title={
                      <Button className="w-100" type="primary" onClick={this.controlTahsildarPaymentNotification}>
                        {this.props.langData['Payment Notification Control']}
                      </Button>
                    }
                    bordered={false}
                  >
                    <Modal
                      visible={this.state.tahsildar.showTahsildarPaymentControl}
                      footer={null}
                      title={<span><Icon
                        type="money-collect"/> Tahsildar {this.props.langData['Payment Notification Control']}</span>}
                      onCancel={() => this.setState({
                        tahsildar: {
                          showTahsildarPaymentControl: false,
                          tahsildarPayments: null,
                        },
                      })}
                    >
                      {this.state.tahsildar.tahsildarPayments ?
                        <List
                          header={<div style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                          }}>{this.props.langData['Payment Records']}</div>}
                          footer={
                            this.state.data.grand_total - this.state.data.paid > 0 && <React.Fragment>
                              <Button className="w-100" type="primary" onClick={() => {
                                this.setState({
                                  tahsildar: {
                                    payable: this.state.data.grand_total - this.state.data.paid,
                                    showTahsildarPaymentControl: false,
                                  },
                                })
                              }}>
                                <Link
                                  to={generatePath(routes.INVOICE_DETAIL_PAY_BY_TYPE, {
                                    type: this.props.langData[this.state.type],
                                    id: this.transaction_id,
                                  })}
                                >
                                  {this.props.langData['Get Full Payment To Invoice']}
                                </Link>
                              </Button>
                            </React.Fragment>
                          }
                          bordered
                          dataSource={this.state.tahsildar.tahsildarPayments.filter(data => data.paid_credit)}
                          renderItem={(item, index) => {
                            index += 1
                            return (
                              <List.Item>
                                <Typography.Text><b>{`${this.state.tahsildar.tahsildarPayments.length > 1 ? this.props.langData['Payment'] + ' - ' + index : this.props.langData['Payment']}`}</b></Typography.Text> {`${toLocaleString(item.paid_credit)} ${getCurrencyWithCode(item.currency.toLowerCase()).symbol}`}
                                {
                                  ! item.jetofis_paid_status && this.state.data.grand_total - this.state.data.paid > 0 ?
                                    <Button type="primary" style={{ width: '23%', float: 'right' }} onClick={() => {
                                      this.setState({
                                        tahsildar: {
                                          payable: item.paid_credit,
                                          tahsildar_notification_id: item.tahsildar_notification_id,
                                          showTahsildarPaymentControl: false,
                                        },
                                      })
                                    }}>
                                      <Link to={generatePath(routes.INVOICE_DETAIL_PAY_BY_TYPE, {
                                        type: this.props.langData[this.state.type],
                                        id: this.transaction_id,
                                      })}
                                      >
                                        {this.props.langData['Get Payment']}
                                      </Link>
                                    </Button>
                                    :
                                    <Tag style={{ float: 'right' }} color="gold">{this.props.langData['Paid']}</Tag>
                                }
                              </List.Item>
                            )
                          }
                          }
                        />
                        :
                        this.state.tahsildar.showTahsildarPaymentControl && <Result
                          status="404"
                          subTitle={this.props.langData['You have not yet sent a payment notification and have not received payment']}
                        />
                      }
                    </Modal>
                  </Card>
                </Col>
              }

              {this.state.data && this.state.data.return_invoices.length > 0 &&
                <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginTop: 16 }}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    title={<span><Icon type="file-excel"/>&nbsp;{this.props.langData['Return Invoice']}</span>}
                    bordered={false}
                  >
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Menu
                          defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          mode="inline"
                          inlineIndent={8}
                        >
                          <Menu.SubMenu
                            key="key1"
                            title={
                              <span style={{ fontWeight: 'bold' }}><Icon
                                type="file-text"/>{this.props.langData['Pieces return invoice was created'](this.state.data.return_invoices.length)}</span>
                            }
                          >
                            {this.state.data.return_invoices.map((x, k) => {
                              k = k + 1
                              return (
                                <Menu.Item key={x.transaction_id} style={{ marginBottom: 10, marginTop: 10 }}>
                                  <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                    <Button className="w-100" size="large">
                                      <Link
                                        to={this.state.type === 'purchase' ? generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
                                          id: x.transaction_id,
                                        }) : generatePath(routes.INVOICE_RETURN_BY_TYPE, {
                                          type: `${this.state.type}Detail`,
                                          id: x.transaction_id,
                                        })}
                                      >
                                      <span style={{ float: 'left' }}><Icon
                                        type="file"/>&nbsp;{this.props.langData['Return Invoice']} {k}</span>
                                        {this.props.sizeWidth > 320 &&
                                          <span style={{ float: 'right' }}>{moment(x.date).format('DD.MM.YYYY')}</span>}
                                      </Link>
                                    </Button>
                                  </Col>
                                </Menu.Item>
                              )
                            })}
                          </Menu.SubMenu>
                        </Menu>
                      </Col>
                    </div>
                  </Card>
                </Col>}
            </Row>
          )
        }}
      </Query>
    )
  }
}

InvoiceDetail.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  type: PropTypes.string,
  location: PropTypes.object,
  licences: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
    licences: state.AuthReducer.licences,
  }
}, dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(InvoiceDetail)
