import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import { connect } from 'react-redux'
import { toLocaleString } from '../../../helpers/helper'

import { getCurrencyWithId } from '../../../data/Currency'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class SafeList extends Component {

  state = {
    sorting: null, // {field: 'code', type: 'ASC'}
    loading: false,
    items: [],
  }

  handleTableChange = (paging, filter, sorting) => {

    if (sorting.field) {

      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      }, () => {

        this.loadItems()

      })

    }
  }

  columns = [
    {
      title: this.props.langData['Name'],
      dataIndex: 'name',
      sorter: true,
      key: 'name',
      width: '30%',
      render: (value, record) => `${value} - ${getCurrencyWithId(record.currency_id).symbol}`,
    },
    {
      title: this.props.langData['Code'],
      dataIndex: 'code',
      sorter: true,
      width: '25%',
      key: 'code',
    },
    {
      title: this.props.langData['Default'],
      dataIndex: 'default',
      width: '15%',
      key: 'default',
      align: 'center',
      render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: '18px' }}/> : null,
    },
    {
      title: this.props.langData['Balance'],
      dataIndex: 'balance',
      sorter: true,
      align: 'right',
      width: '30%',
      key: 'balance',
      render: (balance, record) => toLocaleString(balance) + ' ' + getCurrencyWithId(record.currency_id).symbol,
    },
  ]

  componentDidMount() {

    this.props.breadcrumb_add(routes.SAFE_LIST)

    this.loadItems()

  }

  loadItems = () => {

    this.setState({
      loading: true,
    }, () => {

      postPanelApi('/safe/list', {
        sorting: this.state.sorting,
      }).then(result => {

        if (result) {

          this.setState({
            items: result.items,
          })

        }

      }).finally(() => {

        this.setState({
          loading: false,
        })

      })

    })

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    return (
      <div>
        <Table
          columns={this.columns}
          rowKey={record => record.safe_id}
          dataSource={this.state.items}
          loading={this.state.loading}
          pagination={false}
          rowClassName="white"
          onChange={this.handleTableChange}
          onRow={record => ({
            onClick: () => this.props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, { id: record.safe_id })),
          })}
          scroll={{
            x: 500,
            y: ! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)',
          }}
        />
      </div>
    )
  }
}

SafeList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  email_verification: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(SafeList)
