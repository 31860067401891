import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  formErrorFieldsNameLocalize,
  successToast,
} from '../../helpers/helper'
import { connect } from 'react-redux'
import { startCase } from 'lodash'
import { Form, Icon, Input, Button, Card, Divider, Select, Switch } from 'antd'

import { generatePath } from 'react-router-dom'
import routes from '../../routes'
import {
  BulbOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  FileAddOutlined,
  LinkOutlined, PlusOutlined,
} from '@ant-design/icons'
import { postPanelApi } from '../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

function NewWebhookForm(props) {

  const hookTypes = [
    'Customer',
    'Product',
    'SubProduct',
    'AdditionalCharge',
    'SalesInvoice',
    'PurchaseInvoice',
    'Credit',
    'Debit',
    'CustomerCategory',
    'AdditionalChargeCategory',
    'ProductCategory',
    'InvoiceCategory',
    'TransactionCategory',
    'MoneyInput',
    'MoneyOutput',
    'Safe',
  ]

  const hookMethods = {
    Create: 1,
    Update: 2,
  }

  const [submitting, setSubmitting] = useState(false)
  const [isDestinationTsoft, setIsDestinationTsoft] = useState(false)

  const onSubmit = () => {

    props.form.validateFields(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      // tsoft ise url bilgisine gerek yok
      if (isDestinationTsoft) {
        values.url = ''
      }

      // tsoft seçilip seçilmediğini merkeze bildireceğiz
      values.destination = isDestinationTsoft ? 'tsoft' : ''

      setSubmitting(true)

      postPanelApi('/webhook/create', values).then(result => {

        if (result) {

          successToast(result.message)

          props.history.push(generatePath(routes.WEBHOOK_DETAIL, {
            id: result.webhook_id,
          }))

        } else {

          setSubmitting(false)

        }

      })

    })

  }

  const { getFieldDecorator } = props.form

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><FileAddOutlined/> {props.langData['New Web Hook']}</span>}
      bordered={false}
      extra={
        <Switch
          checkedChildren="T-Soft"
          unCheckedChildren={props.langData['Other']}
          style={{ float: 'right' }}
          onChange={() => {
            setIsDestinationTsoft( ! isDestinationTsoft)
          }}
        />
      }
    >
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default"
      >
        <div className="form-block">

          { ! isDestinationTsoft && (
            <Form.Item hasFeedback label={(
              <span><LinkOutlined/><p>{props.langData['Method URI']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('url', {
                rules: [{ type: 'url', min: 1, max: 200 }],
                validateTrigger: false,
                initialValue: '',
              })(
                <Input size="large"/>,
              )}
            </Form.Item>
          )}

          <Form.Item label={(<span><BulbOutlined/><p>{props.langData['Type']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('type', {
              initialValue: hookTypes[0],
              rules: [{ required: true }],
              validateTrigger: false,
            })(
              <Select size="large">
                {hookTypes.map(x => (
                  <Select.Option value={x} key={x}>
                    {props.langData[startCase(x)]}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><CheckCircleOutlined/><p>{props.langData['Active']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('active', {
              valuePropName: 'checked',
              validateTrigger: false,
              initialValue: true,
            })(
              <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
              />,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="check-circle-o"/><p>{props.langData['Methods']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('methods', { validateTrigger: false, initialValue: [] })(
              <Select mode="multiple">
                {Object.entries(hookMethods).map(([key, value]) => (
                  <Select.Option value={value} key={value}>
                    {props.langData[key]}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="default-button"
              style={{ marginRight: 5 }}
              disabled={submitting}
            >
              <PlusOutlined/> {props.langData['Add']}
            </Button>
          </Form.Item>
        </div>

      </Form>
    </Card>
  )

}

NewWebhookForm.propTypes = {
  langData: PropTypes.object,
  hook_id: PropTypes.number,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(NewWebhookForm)
