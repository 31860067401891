import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import EWaybillSettingsForm from './EWaybillSettingsForm/EWaybillSettingsForm'
import routes from '../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'E-Waybill Settings', url: routes.DESPATCH_SETTINGS } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function EWaybillSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add()

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedEWaybillSettings = Form.create({
    walidateMessages: langData.formValidationMessages,
  })(EWaybillSettingsForm)
  return <WrappedEWaybillSettings {...props} />

}

EWaybillSettings.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(EWaybillSettings)
