import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SubProductForm from './../SubProductForm/SubProductForm'
import { connect } from 'react-redux'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

class SubProductUpdate extends Component {

  paramsMatch = {
    main_product_id: null,
    product_id: null,
  }

  constructor(props) {
    super(props)

    this.paramsMatch.main_product_id = parseInt(this.props.match.params.id)
    this.paramsMatch.product_id = parseInt(this.props.match.params.sub_product_id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedSubProductForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(SubProductForm)
    return (<WrappedSubProductForm {...this.paramsMatch} {...this.props} ></WrappedSubProductForm>)
  }

}

SubProductUpdate.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps)(SubProductUpdate)
