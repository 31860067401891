import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Divider } from 'antd'

import {
  deleteEmpty,
  formErrorFieldsNameLocalize,
  successToast,
} from '../../../helpers/helper'
import { postPanelApi } from '../../../api'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 14 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

function Step2Form(props) {

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {

    postPanelApi('/auth/step2-open').then(result => {

      if (result) {

        props.form.setFieldsValue(result.form)

      }

    })

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      setSubmitting(true)

      postPanelApi('/auth/step2-save', values).then(result => {

        if (result) {

          successToast(result.message)

          props.history.push(generatePath(routes.REGISTER_STEP, { step: 3 }))

        } else {

          setSubmitting(false)

        }

      })

    })

  }

  const { getFieldDecorator } = props.form

  return (
    <Form
      hideRequiredMark={true}
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      className="form-label-default"
    >

      <Divider>{props.langData['Manager Information']}</Divider>

      <div className="form-block">

        <Form.Item hasFeedback label={(
          <span><Icon type="user"/><p>{props.langData['Name Surname']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('name_surname', {
            rules: [{ max: 250 }, { required: true }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('e_mail', {
            rules: [{ type: 'email' }, { max: 100 }, { required: true }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="lock"/><p>{props.langData['Password']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('password', { rules: [{ max: 32 }, { min: 6 }], validateTrigger: false })(
            <Input.Password size="large" type="password"/>,
          )}
        </Form.Item>

      </div>

      <Divider/>

      <div className="form-block">
        <Form.Item {...formItemLayoutNoLabel}>
          <Button type="primary" size="large" loading={submitting} htmlType="submit"
                  className="default-button">
            {props.langData['Next']}
          </Button>
        </Form.Item>
      </div>

    </Form>
  )

}

Step2Form.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

function Step2(props) {

  const WrappedStep2Form = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(Step2Form)

  return <WrappedStep2Form {...props} />

}

Step2.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(Step2)
