import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form, Input, Icon, DatePicker, Select } from 'antd'

import { formErrorFieldsNameLocalize } from '../../../helpers/helper'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 17 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

function EinvoiceModalForIadeForm(props) {

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      setSubmitting(true)

      const result = await props.callback({
        einvoice_profile: values.einvoice_profile,
        einvoice_no: values.einvoice_no,
        einvoice_date: values.einvoice_date.format('YYYY-MM-DD'),
      })

      if (result) {
        props.onCancel()
      } else {
        setSubmitting(false)
      }

    })

  }

  const { getFieldDecorator } = props.form

  return (

    <Modal
      title={<span><Icon type="file-text"/> İade E-Faturası</span>}
      bodyStyle={{ padding: 8 }}
      visible={true}
      footer={null}
      onCancel={props.onCancel}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default no-indent"
        hideRequiredMark={true}
      >

        <div className="form-block">
          <Form.Item hasFeedback label={<p>E-Fatura No</p>} {...formItemLayout}>
            {getFieldDecorator('einvoice_no', { rules: [{ max: 250 }] })(
              <Input
                style={{ width: '100%' }}
                allowClear
                key="6"
              />,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item
            hasFeedback
            label={<p>E-Fatura Tarihi</p>}
            {...formItemLayout}
          >
            {getFieldDecorator('einvoice_date', {
              initialValue: moment(),
            })(
              <DatePicker format="YYYY-MM-DD"/>,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="default-button"
              loading={submitting}
            >
              {props.langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )

}

EinvoiceModalForIadeForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
}

function EinvoiceModalForIade(props) {

  const WrappedEinvoiceModalForIadeForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(EinvoiceModalForIadeForm)

  return <WrappedEinvoiceModalForIadeForm {...props} />

}

EinvoiceModalForIade.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
}

export default EinvoiceModalForIade
