import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import { postPanelApi } from '../api'
import routes from '../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'My Packages', url: routes.MY_PACKAGES_LIST } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New Package' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function BuyNewPackagePage(props) {

  const [loading, setLoading] = useState(false)
  const [iframeUrl, setIframeUrl] = useState('')

  useEffect(() => {

    props.breadcrumb_add()

    setLoading(true)

    postPanelApi('/general/open-buy-new-package-page').then(result => {

      if (result) {

        setIframeUrl(result.iframe_url)

      }

      setLoading(false)

    })

    return () => {
      props.breadcrumb_delete()
    }

  }, [])

  if (loading) {
    return <Spin/>
  }

  return (
    <iframe
      src={iframeUrl}
      width="100%"
      height="1200"
      frameBorder={0}
    />
  )

}

BuyNewPackagePage.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyNewPackagePage)
