import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'
import { gql } from '@apollo/client'

import moment from 'moment'
import { compile } from 'path-to-regexp'

import { Table, Tag, Input, Form, Button, Icon, DatePicker, Row, Tooltip, Select } from 'antd'
import { graphQLErrorsToToast, toLocaleString, deleteEmpty, toast } from '../../../helpers/helper'
import { getCurrencyWithCode } from '../../../data/Currency'
import axios from 'axios'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    filterData: state.TableFilterReducer.data.IncomingEInvoice,
    filterFieldsData: state.TableFilterReducer.data.IncomingEInvoiceFieldsData,
    pageInfo: state.TableFilterReducer.data.IncomingEInvoicePage,
    email_verification: state.AuthReducer.email_verification,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Incoming E-Invoice Box', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'IncomingEInvoice' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'IncomingEInvoiceFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'IncomingEInvoicePage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'IncomingEInvoice' } })
      dispatch({ type: 'resetFilter', payload: { name: 'IncomingEInvoiceFieldsData' } })
    },
  }
}

const READ_QUERY = (
  gql`
    query($filter: IncomingEInvoiceFilterBase,$paging: Paging, $sorting: IncomingEInvoiceSorting) {
      incomingEInvoiceList(filter: $filter, paging: $paging, sorting: $sorting){
        incoming_einvoices {
          user_id
          customer
          currency
          code
          date
          invoice_country
          invoice_city
          invoice_town
          invoice_address
          products
          e_invoice_uuid
          e_invoice_id
          e_invoice_type
          come_from
          is_saved
          message
          tax_inclusive_amount
        }
        page_info {
          total_count
        }
      }
    }
  `
)

class IncomingEInvoiceBox extends Component {
  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    sorting: {
      field: 'date',
      type: 'DESC',
    },
    filter: null,
    disabledButton: false,
  }

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['invoice/incoming-e-invoice-box'])

    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }
    if (this.props.filterData)
      this.setState({ filter: this.props.filterData })
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  columns = [
    {
      title: this.props.langData['Invoice Type'],
      key: 'invoice_type',
      render: () => <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Incoming E-Invoice']}</Tag>,
    },
    {
      title: this.props.langData['Customer'],
      dataIndex: 'customer.name',
      key: 'customer_name',
    },
    {
      title: this.props.langData['Invoice Code'],
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (record) => moment(record).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: this.props.langData['Total Price'],
      dataIndex: 'tax_inclusive_amount',
      key: 'total',
      align: 'right',
      render: (_, record) => `${toLocaleString(parseFloat(record.tax_inclusive_amount))} ${getCurrencyWithCode(record.currency).symbol}`,
    },
  ]

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['invoice/detail/incoming-e-invoice-box/:id'])({ id: record.e_invoice_uuid }),
    )
  }

  getInvoicesDateRange = refetch => {

    this.setState({ disabledButton: true })

    axios.post(`${process.env.REACT_APP_NEW_API_URL}/invoice/einvoice/fetchIncomingEInvoice`, {},
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Token': localStorage.token,
        },
      },
    ).then(response => {
      this.setState({ disabledButton: false })
      toast(response.data.success, response.data.messages ? response.data.messages : response.data.message)
      refetch()
    }).catch(() => {
      this.setState({ disabledButton: false })
    })
  }

  render() {
    return (
      <Query
        query={READ_QUERY}
        fetchPolicy="network-only"
        variables={{ filter: this.state.filter, paging: this.state.paging, sorting: this.state.sorting }}
      >
        {({ loading: loadingQuery, error, data, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data) {
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: data.incomingEInvoiceList.page_info.total_count,
                },
                data: cloneDeep(data.incomingEInvoiceList.incoming_einvoices),
              })
            } else if (this.state.data && JSON.stringify(data.incomingEInvoiceList.incoming_einvoices) !== JSON.stringify(this.state.data)) {
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: data.incomingEInvoiceList.page_info.total_count,
                },
                data: cloneDeep(data.incomingEInvoiceList.incoming_einvoices),
              })
            }
          }
          return (
            <React.Fragment>
              <FilterBox
                callback={this.filterCallback}
                isSubmit={loadingQuery}
                {...this.props}
              />
              <Tooltip title={this.props.langData['It returns invoices for last 30 days']}>
                <Button disabled={this.state.disabledButton} htmlType="submit" key="3" style={{ marginBottom: 10 }}
                        onClick={() => this.getInvoicesDateRange(refetch)}>{this.props.langData['Get Available Invoices']}</Button>
              </Tooltip>
              <Row>
                <Table
                  columns={this.columns}
                  dataSource={this.state.data}
                  rowClassName="white"
                  loading={loadingQuery}
                  rowKey={record => record.e_invoice_id}
                  onRow={record => ({
                    onClick: () => {
                      return this.rowItemClick(record)
                    },
                  })}
                  onChange={this.handleTableChange}
                  pagination={this.state.pagination}
                  scroll={{
                    x: 500,
                    y: ! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)',
                  }}
                />
              </Row>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

IncomingEInvoiceBox.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  filter: PropTypes.func,
  filterData: PropTypes.object,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.func,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.func,
  email_verification: PropTypes.bool,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isSubmit != nextProps.isSubmit)
      this.setState({ isSubmit: nextProps.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

class FilterBoxForm extends Component {
  state = {
    customerList: [],
    selectedOptions: [],
    items: [
      {
        required: true,
        value: 'is_saved',
        label: this.props.langData['Saving Status'],
        operator: 'eq',
        added: false,
      },
      {
        label: this.props.langData['Date'],
        value: 'date',
        width: 900,
        operator: 'date',
        added: false,
      },
      {
        label: this.props.langData['Invoice Code'],
        value: 'code',
        width: 500,
        operator: 'contains',
        added: false,
      },
      {
        label: this.props.langData['Customer Name'],
        value: 'customer_name',
        width: 500,
        operator: 'contains',
        added: false,
      },
    ],
  }

  componentDidMount() {
    this.onSubmit(this.props.form)
  }

  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items: items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }

  onSubmit = form => {
    form.validateFields((err, values) => {
      if (err)
        return
      this.setState({ isSubmit: true })
      deleteEmpty(values)
      let filterData = []

      for (let value in values) {
        let item = this.state.items.filter(x => x.value === value)[0]
        if (item.operator === 'date') {
          filterData.push({
            [value]: {
              gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
              lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
            },
          })
          if ( ! values[value][0]) {
            filterData = filterData.filter(data => ! data.date)
          }
        } else {
          filterData.push({ [value]: { [item.operator]: values[value] } })
        }
      }

      this.props.callback(filterData.length ? filterData : null, values)

    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []
    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      <Button key="1"><Icon type="file-unknown"/></Button>,
      <CheckboxedDropdown
        key="2"
        width={160}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedOptions}
        onChange={this.onChangeCheckbox}
      />,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="2" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="3" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let content = [...filterHeader]

    if (this.state.items.filter(x => x.value === 'date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('date', {
            initialValue: this.props.filterFieldsData && this.props.filterFieldsData['date'],
          })(
            <DatePicker.RangePicker
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'date')[0].width : null }}
              format="DD-MMMM-YYYY"
              key="6"
              allowClear
            />,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'code' && x.added).length) {
      content.push(
        (
          getFieldDecorator('code')(
            <Input allowClear placeholder={this.props.langData['Invoice Code']} key="7"
                   style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'code')[0].width : null }}/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'customer_name' && x.added).length) {
      content.push(
        (
          getFieldDecorator('customer_name')(
            <Input allowClear placeholder={this.props.langData['Customer Name']} key="7"
                   style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'customer_name')[0].width : null }}/>,
          )
        ),
      )
    }

    content.push(
      (
        getFieldDecorator('is_saved', {
          initialValue: this.props.filterFieldsData ? this.props.filterFieldsData['is_saved'] : false,
        })(
          <Select
            style={{ width: '100%' }}
            allowClear
            key="4"
            placeholder={
              <div>
                <Icon type="search"/> {this.props.langData['Saving Status']}
              </div>
            }
            suffixIcon={<Icon type="caret-down"/>}
          >
            <Select.Option value={true}><Icon type="save"/> {this.props.langData['Show Saved']}</Select.Option>
            <Select.Option value={false}><Icon type="save"/> {this.props.langData['Show Unsaved']}</Select.Option>
          </Select>,
        )
      ),
    )

    if (this.props.sizeWidth > 576)
      return (
        <Form onSubmit={e => {
          e.preventDefault()
          this.onSubmit(this.props.form)
        }}>
          <Input.Group compact style={{ display: 'flex' }}>
            {content.map(x => x)}
            <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
          </Input.Group>
        </Form>
      )
    return (
      <Form onSubmit={e => {
        e.preventDefault()
        this.onSubmit(this.props.form)
      }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
        {content.map(x => x)}
      </Form>
    )
  }
}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isSubmit: PropTypes.bool,
  filterFieldsData: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingEInvoiceBox)
