import React from 'react'
import { Icon, List, Modal } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

function ETransformResultModal(props) {

  return (
    <Modal
      title={(
        <span>
          <Icon type="mail"/> {props.langData['Preview Creating Multiple E-Transform'](props.langData[props.type])}
        </span>
      )}
      visible={true}
      footer={null}
      onCancel={props.onCancel}
      width="40%"
      centered
    >
      <List
        header={
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ fontWeight: 'bold' }}>{props.langData['Code']}</div>
            <div style={{ fontWeight: 'bold' }}>{props.langData['Message']}</div>
          </div>
        }
        footer={null}
        pagination={{
          pageSize: 8,
        }}
        bordered
        dataSource={props.result}
        renderItem={item => {

          if (item.success) {

            return (
              <List.Item style={{ display: 'flex', justifyContent: 'space-around' }}>
                <List.Item.Meta
                  title={item.data.result.invoice.code}
                  description={item.data.result.message}
                />
                <div>{item.data.result.einvoice.id}</div>
              </List.Item>
            )

          }

          return (
            <List.Item style={{ display: 'flex', justifyContent: 'space-around' }}>
              <List.Item.Meta
                title={item.data.invoice.code}
                description={item.data.message}
              />
            </List.Item>
          )

        }}
      />
    </Modal>
  )

}

ETransformResultModal.propTypes = {
  langData: PropTypes.object,
  result: PropTypes.array,
  type: PropTypes.string,
  onCancel: PropTypes.func,
}

export default connect(mapStateToProps)(ETransformResultModal)
