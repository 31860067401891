import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { toast, formErrorFieldsNameLocalize, graphQLErrorsToToast } from './../../helpers/helper'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Dropdown, Menu, Row, Col, Card, Divider } from 'antd'
import { gql } from '@apollo/client'

import { Mutation } from '@apollo/client/react/components'
import logo from './../../logo.svg'
import routes from '../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
}

const FORGOT_PASSWORD_MUTATION = (
  gql`
    mutation($forgot_passwd: ForgotPasswdInput!){
      forgotPasswd(forgot_passwd: $forgot_passwd){
        informative{
          messages
        }
      }
    }
  `
)

class ForgotPassword extends Component {

  render() {
    const WrappedForgotPasswordForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ForgotPasswordForm)
    return (
      <div className="h-100">
        <Row type="flex" justify="space-around" align="middle" className="h-100">
          <Col xs={1} sm={6} md={7} lg={9}/>
          <Col xs={22} sm={12} md={10} lg={6}>

            <Card style={{
              textAlign: 'center',
              boxShadow: '0 0 100px rgba(0,0,0,.08)',
              background: 'transparent',
              padding: 20,
            }} bordered={false}>
              <img src={logo} alt="Jetofis"/>
              <Divider style={{ marginTop: 20 }}>{this.props.langData['Forgot password']}</Divider>
              <WrappedForgotPasswordForm {...this.props} />
              <Divider>JETOFİS</Divider>
              <Card.Meta
                title="Bulut Tabanlı Ticari Yazılım"
                description="www.jetofis.com"
              />
            </Card>

          </Col>
          <Col xs={1} sm={6} md={7} lg={9}/>
        </Row>
      </div>
    )
  }

}

ForgotPassword.propTypes = {
  langData: PropTypes.object,
}

class ForgotPasswordForm extends Component {

  onSubmit = (form, mutate) => {
    form.validateFields(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)
      try {
        const result = await mutate({ variables: { forgot_passwd: values } })

        if (result !== undefined) {
          toast(true, result.data.forgotPasswd.informative.messages)
        }
      } catch (e) {
        graphQLErrorsToToast(e)
      }
    })
  }

  changeLang(lang) {
    this.props.languageChange(lang)
  }

  render() {

    const menu = <Menu onSelect={e => this.changeLang(e.key)} selectable={true}>
      {Object.keys(this.props.langData.languages).map(x => {
        return (
          <Menu.Item key={x}>{this.props.langData.languages[x]}</Menu.Item>
        )
      })}
    </Menu>

    const { getFieldDecorator } = this.props.form

    return (
      <Mutation
        mutation={FORGOT_PASSWORD_MUTATION}
        onError={err => graphQLErrorsToToast(err)}
      >
        {(mutate, { loading: loadingMutation }) => {
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form, mutate)
            }}>
              <Form.Item hasFeedback style={{ marginTop: 24, marginBottom: 14 }}>
                {getFieldDecorator('e_mail', { rules: [{ type: 'email' }, { max: 70 }, { required: true }] })(
                  <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                         placeholder={this.props.langData['E-Mail']}/>,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingMutation} style={{ width: '100%' }}>
                  {this.props.langData['Send']}
                </Button>
                <Link to={routes.LOGIN} className="fl-l">{this.props.langData['Login']}</Link>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="fl-r">{this.props.langData.languages[this.props.lang]}</Button>
                </Dropdown>
              </Form.Item>
            </Form>
          )
        }}
      </Mutation>
    )

  }

}

ForgotPasswordForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
  lang: PropTypes.string,
  languageChange: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
