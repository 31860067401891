import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Card, DatePicker, Col, Row, Statistic, Icon, Button, Input } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithId } from '../../../data/Currency'
import { cloneDeep } from 'lodash'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import moment from 'moment'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'VAT Report', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}
const LIST_QUERY = (
  gql`
    query($start_date: Date!, $end_date: Date!) {
      reports {
        invoice {
          moving(field:date, moving:"monthly", interval:{ start_date: $start_date, end_date: $end_date }){
            sum( field:total_vat , group: type ) {
              results {
                sum
                type
              }
            }
          }
          totalVats:sum(field:total_vat , group: type) {
            results {
              sum
              type
            }
          }
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

class VatReport extends Component {

  currencyId = 1

  state = {
    data: null,
    totalSalesVat: null,
    totalPurchaseVat: null,
    totalReturnSalesVat: null,
    totalReturnPurchaseVat: null,
    total: null,
    selectedYear: moment().format('YYYY'),
    reportsData: [],
  }
  columns = [
    {
      title: this.props.langData['Type'],
      key: 'month',
      width: '20%',
      render: (value, record, index) => {
        return moment().locale(this.props.lang).month(index).format('MMMM')
      },
    },
    {
      title: this.props.langData['Calculated VAT'],
      dataIndex: 'salesInvoiceSum',
      key: 'salesInvoiceSum',
      width: '20%',
      align: 'right',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
    {
      title: this.props.langData['VAT To Be Reduced For This Period'],
      dataIndex: 'purchaseInvoiceSum',
      key: 'purchaseInvoiceSum',
      width: '30%',
      align: 'center',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
    {
      title: this.props.langData['Previous Calculated Vat'],
      dataIndex: 'previousPeriodVat',
      key: 'previousPeriodVat',
      width: '40%',
      align: 'center',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
    {
      title: this.props.langData['Total Reduced VAT'],
      dataIndex: 'totalCalcVat',
      key: 'totalCalcVat',
      width: '40%',
      align: 'center',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
    {
      title: this.props.langData['Payable VAT'],
      dataIndex: 'payableVat',
      key: 'payableVat',
      width: '40%',
      align: 'center',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
    {
      title: this.props.langData['VAT Transferred To Next Period'],
      dataIndex: 'nextPeriodVat',
      key: 'nextPeriodVat',
      width: '40%',
      align: 'right',
      render: value => `${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`,
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add(routes.TRANSACTION_VAT_REPORT)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{
          start_date: this.state.selectedYear.toString() + '-01-01',
          end_date: this.state.selectedYear.toString() + '-12-31',
          total_start_date: moment().startOf('year').format('YYYY-MM-DD HH:mm'),
          totalend_date: moment().endOf('year').format('YYYY-MM-DD HH:mm'),
        }}
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          this.currencyId = data.legacyData.currency_id

          let reportsData = []

          const reports = cloneDeep(data.reports.invoice.moving.sum)
          let nextPeriodVat = [] //Sonraki Döneme devreden kdv

          //İade faturası vs incelenecek.
          reports.forEach((data, index) => {

            let salesInvoiceSum = 0 //Hesaplanan kdv
            let purchaseInvoiceSum = 0 //İndirilecek kdv
            let previousPeriodVat = 0 //Önceki Dönemden devreden kdv
            let totalCalcVat = 0 // Toplam indirilecek kdv
            let payableVat = 0 // Ödenecek kdv
            let minus = false

            data.results.forEach((data) => {
              if (data.type === 'SalesInvoice') {
                salesInvoiceSum = data.sum
              } else if (data.type === 'PurchaseInvoice') {
                purchaseInvoiceSum = data.sum
              } else if (data.type === 'ReturnSalesInvoice') {
                salesInvoiceSum = salesInvoiceSum - data.sum
              } else if (data.type === 'ReturnPurhcaseInvoice') {
                purchaseInvoiceSum = purchaseInvoiceSum - data.sum
              }
            })

            previousPeriodVat = index === 0 ? 0 : nextPeriodVat[index - 1]

            if (salesInvoiceSum - purchaseInvoiceSum - previousPeriodVat < 0) {
              nextPeriodVat.push(-1 * (salesInvoiceSum - purchaseInvoiceSum - previousPeriodVat))
            } else {
              nextPeriodVat.push(0)
            }
            minus = salesInvoiceSum - purchaseInvoiceSum - previousPeriodVat < 0
            totalCalcVat = index === 0 ? purchaseInvoiceSum : purchaseInvoiceSum + previousPeriodVat
            payableVat = minus ? 0 : salesInvoiceSum - purchaseInvoiceSum - previousPeriodVat


            reportsData.push({
              salesInvoiceSum,
              purchaseInvoiceSum,
              previousPeriodVat,
              totalCalcVat,
              payableVat,
              nextPeriodVat: nextPeriodVat[index],
            })

          })

          const vat_total = data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice').length ?
            data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum - data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0].sum :
            data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice').length ? data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum : 0

          const vat_expense = data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice').length && data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice').length ?
            data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum - data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0].sum :
            data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice').length ? data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum : 0


          this.setState({
            data: cloneDeep(data.reports.invoice.moving.sum),
            totalSalesVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum : 0),
            totalReturnSalesVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0].sum : 0),
            totalReturnPurchaseVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0].sum : 0),
            totalPurchaseVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum : 0),
            total: vat_total - vat_expense,
            reportsData,
          })

        }}
      >
        {({ loading: loadingQuery }) => {

          return (
            <div>
              <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={24} md={8} l={8} xl={8}>
                  <Card>
                    <Statistic
                      title={this.props.langData['Total Calculated VAT']}
                      value={this.state.totalReturnSalesVat ? (this.state.totalSalesVat - this.state.totalReturnSalesVat) : this.state.totalSalesVat ? this.state.totalSalesVat : 0}
                      decimalSeparator=","
                      groupSeparator="."
                      precision={4}
                      valueStyle={{ color: '#cf1322' }}
                      prefix={<Icon type="arrow-up"/>}
                      suffix={getCurrencyWithId(this.currencyId).symbol}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8} l={8} xl={8}>
                  <Card>
                    <Statistic
                      title={this.props.langData['Total VAT to be Deducted']}
                      value={this.state.totalReturnPurchaseVat ? (this.state.totalPurchaseVat - this.state.totalReturnPurchaseVat) : this.state.totalPurchaseVat ? this.state.totalPurchaseVat : 0}
                      decimalSeparator=","
                      groupSeparator="."
                      precision={4}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<Icon type="arrow-down"/>}
                      suffix={getCurrencyWithId(this.currencyId).symbol}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8} l={8} xl={8}>
                  <Card>
                    <Statistic
                      title={this.props.langData['Total']}
                      value={this.state.total ? this.state.total : 0}
                      decimalSeparator=","
                      groupSeparator="."
                      precision={4}
                      valueStyle={{ color: '#1890ff' }}
                      prefix={<Icon type="plus"/>}
                      suffix={getCurrencyWithId(this.currencyId).symbol}
                    />
                  </Card>
                </Col>
              </Row>
              <Card
                bodyStyle={{ padding: '0px' }}
                title={<span>{this.state.selectedYear.toString() + ' ' + this.props.langData['VAT Report']}</span>}
                extra={
                  <Input.Group>
                    <Button
                      onClick={() => {
                        this.setState({
                          selectedYear: (parseInt(this.state.selectedYear) - 1).toString(),
                        })
                      }}
                    >
                      <Icon type="caret-left"/>
                    </Button>
                    <DatePicker
                      mode="year"
                      suffixIcon={<Icon type="calendar" theme="filled"/>}
                      value={moment(this.state.selectedYear)}
                      allowClear={false}
                      format="YYYY"
                      style={{
                        top: 12,
                        width: 100,
                      }}
                      onPanelChange={value => {
                        this.setState({
                          selectedYear: value.format('YYYY').toString(),
                        })
                      }}
                    />
                    <Button
                      onClick={() => {
                        this.setState({
                          selectedYear: (parseInt(this.state.selectedYear) + 1).toString(),
                        })
                      }}
                    >
                      <Icon type="caret-right"/>
                    </Button>
                  </Input.Group>
                }
              >
                <Table
                  columns={this.columns}
                  size="middle"
                  rowKey={(record, index) => index}
                  dataSource={this.state.reportsData ? this.state.reportsData : []}
                  loading={loadingQuery}
                  pagination={false}
                  rowClassName="white"
                  scroll={{ x: 500, y: this.props.sizeWidth > 576 ? 500 : null }}
                />
              </Card>
            </div>
          )
        }}
      </Query>
    )
  }
}

VatReport.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(VatReport)
