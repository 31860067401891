import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SequenceForm from './../SequenceForm/SequenceForm'
import { connect } from 'react-redux'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewSequence extends Component {

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['sequence/new'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const WrappedSequenceForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(SequenceForm)
    return <WrappedSequenceForm {...this.props} refetch={this.props.refetch}></WrappedSequenceForm>
  }

}

NewSequence.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSequence)
