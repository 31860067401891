import React, { Component } from 'react'
import { Form, Modal, Select, Icon, Button, Checkbox, List, Typography } from 'antd'
import PropTypes from 'prop-types'

import { formErrorFieldsNameLocalize } from '../../../helpers/helper'
import { throttle } from 'lodash'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 },
    lg: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 13 },
    lg: { span: 13 },
  },
}

class ShareInvoiceMail extends Component {
  render() {
    const WrappedShareInvoiceMail = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ShareInvoiceMailForm)
    return <WrappedShareInvoiceMail {...this.props}></WrappedShareInvoiceMail>
  }
}

ShareInvoiceMail.propTypes = {
  langData: PropTypes.object,
}


class ShareInvoiceMailForm extends Component {
  constructor() {
    super()
    this.throttleShareInvoice = throttle(this.shareInvoice, 2000)
  }

  onSubmit = form => {
    form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)
      this.throttleShareInvoice(values)
    })
  }

  shareInvoice = values => {
    this.props.onOk(values)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Modal
        title={<span><Icon type="file"/>&nbsp;{this.props.langData['Share Mail Invoice']}</span>}
        footer={null}
        visible={true}
        width="38%"
        onCancel={this.props.onCancel}
      >
        {
          this.props.incorrectInvoices.length > 0 ? (
            <List
              header={<div style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}>{this.props.langData['Emails That Cannot Be Sent']}</div>}
              footer={<div style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}>{this.props.langData['Your invoices with these codes/codes could not be sent as e-mail.']}</div>}
              bordered
              dataSource={this.props.incorrectInvoices}
              renderItem={item => (
                <List.Item>
                  <Typography.Text delete>{item}</Typography.Text>
                </List.Item>
              )}
            />
          ) : (
            <Form
              onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}
              className="form-label-default"
              hideRequiredMark={true}
            >
              <Form.Item
                label={<span><Icon type="user-add"/><p>{this.props.langData['To']}</p></span>}
                {...formItemLayout}
              >
                {getFieldDecorator('isSendCurrentMailAddress', {
                  valuePropName: 'checked',
                  initialValue: false,
                })(
                  <Checkbox>{this.props.form.getFieldValue('isSendCurrentMailAddress') ? this.props.langData['Let it be sent to customer mail'] : this.props.langData['Let it be sent to the e-mails I have determined']}</Checkbox>,
                )}
              </Form.Item>

              { ! this.props.form.getFieldValue('isSendCurrentMailAddress') && <Form.Item
                hasFeedback
                label={<span><Icon type="user-add"/><p>{this.props.langData['Mail Address / Addresses']}</p></span>}
                {...formItemLayout}
              >
                {getFieldDecorator('mail_addresses', {
                  initialValue: [],
                  validateTrigger: false,
                  rules: [{ type: 'array', max: 10 }, { required: true }],
                })(
                  <Select className="remove-icon-black" mode="tags" style={{ width: '100%', color: 'black' }}
                          placeholder={this.props.langData['Enter Mail / Mails']}/>,
                )}
              </Form.Item>}

              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" className="default-button" /* loading={loading}*/>
                  {this.props.langData['Send']}
                </Button>
              </Form.Item>
            </Form>
          )
        }
      </Modal>
    )
  }
}

ShareInvoiceMailForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  incorrectInvoices: PropTypes.array,
}


export default ShareInvoiceMail
