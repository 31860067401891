import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreditDebitForm from './../../../Customer/CreditDebitForm/CreditDebitForm'
import { connect } from 'react-redux'

import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../../routes'

class NewReturnPayment extends Component {

  type
  id

  constructor(props) {
    super(props)
    this.id = this.props.match.params.id
    this.type = this.props.match.params.type
  }

  componentDidMount() {
    this.props.breadcrumb_add(
      this.props.type === 'sales' ? 'Pay' : 'Get Payment',
      generatePath(routes.INVOICE_RETURN_PAY_BY_TYPE, {
        type: this.props.langData[this.props.type],
        id: this.id,
      }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete(this.props.type === 'sales' ? 'Pay' : 'Get Payment')
  }

  render() {
    const WrappedCreditDebitForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(CreditDebitForm)
    return (
      <WrappedCreditDebitForm
        type={this.type}
        priority_invoice={this.id}
        id={this.id}
        {...this.props}
      />
    )
  }

}

NewReturnPayment.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  type: PropTypes.string, // fatura tipi
  id: PropTypes.number,
  callback: PropTypes.func,
  match: PropTypes.object,
  priority_invoice: PropTypes.string,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete(name) {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(NewReturnPayment)
