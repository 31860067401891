import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'

import routes from '../../../routes'
import NewSafeForm from '../NewSafeForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewSafe extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(routes.SAFE_NEW)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    const WrappedNewSafeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(NewSafeForm)
    return <WrappedNewSafeForm {...this.props} />
  }

}

NewSafe.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSafe)
