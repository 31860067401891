import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'

import routes from '../../../../routes'

import TSoftWebServiceSettingsForm from './../TSoftWebServiceSettings/TSoftWebServiceSettingsForm'

function TSoftWebServiceSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add(routes.TSOFT_WS_SETTINGS)

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedTSoftWebServiceSettings = Form.create({
    validateMessages: langData.formValidationMessages,
  })(TSoftWebServiceSettingsForm)
  return <WrappedTSoftWebServiceSettings {...props} />

}

TSoftWebServiceSettings.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'T-Soft Web Service Settings', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(TSoftWebServiceSettings)
