const currencyData = [
  {
    currency_id: 1,
    code: 'try',
    name: 'Türk Lirası',
    symbol: 'TL'
  },
  {
    currency_id: 2,
    code: 'usd',
    name: 'Amerikan Doları',
    symbol: 'USD'
  },
  {
    currency_id: 3,
    code: 'eur',
    name: 'Euro',
    symbol: 'EUR'
  },
  {
    currency_id: 4,
    code: 'gbp',
    name: 'İngiliz Sterlini',
    symbol: 'GBP'
  },
  {
    currency_id: 5,
    code: 'rub',
    name: 'Rus Rublesi',
    symbol: 'RUB'
  }
]

let getCurrencyWithId = id => {
  for (let x of currencyData) {
    if (x.currency_id === id) {
      return x
    }
  }
}

let getCurrenciesWithoutMatchWithCode = code => {
  let data = []
  for (let x of currencyData) {
    if (x.code !== code)
      data.push(x)
  }
  return data
}

const getCurrencyWithCode = code => {
  for (let x of currencyData) {
    if (x.code === code)
      return x
  }
}

export {
  currencyData,
  getCurrencyWithId,
  getCurrencyWithCode,
  getCurrenciesWithoutMatchWithCode
}
