import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import WaybillForm from './../WaybillForm/WaybillForm'
import { compile } from 'path-to-regexp'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Waybill' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}


class WaybillUpdate extends Component {
  type
  waybill_id

  typeEnglishName = {
    'giden': 'sending',
    'gelen': 'incoming',
  }

  constructor(props) {
    super(props)
    this.waybill_id = parseInt(props.match.params.id)
    this.type = props.match.params.type
    if (this.typeEnglishName[this.type])
      this.type = this.typeEnglishName[this.type]
  }

  componentDidMount() {
    this.props.breadcrumb_add('/' + compile(this.props.langData.route['waybill/:type/update/:id'])({
      id: this.waybill_id,
      type: this.type,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedWaybillUpdateForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(WaybillForm)
    return <WrappedWaybillUpdateForm type={this.type}
                                     waybill_id={this.waybill_id} {...this.props}></WrappedWaybillUpdateForm>
  }
}

WaybillUpdate.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  match: PropTypes.object,
}
export default connect(mapStateToProps, mapDispatchToProps)(WaybillUpdate)
