import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NewAdditionalCharge from './../NewAdditionalCharge/NewAdditionalCharge'
import { Drawer, Icon } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

class NewAdditionalChargeModal extends Component {
  render() {
    return (
      <Drawer
        title={<span><Icon type="file-add"/> &nbsp;{this.props.langData['New Additional Charge']}</span>}
        placement="right"
        closable={true}
        visible={true}
        width="40%"
        onClose={this.props.modalClose}
      >
        <NewAdditionalCharge isModal={this.props.modalClose}/>
      </Drawer>
    )
  }
}

NewAdditionalChargeModal.propTypes = {
  langData: PropTypes.object,
  modalClose: PropTypes.func,
  sizeWidth: PropTypes.number,
}

export default connect(mapStateToProps)(NewAdditionalChargeModal)
