export default {

  SUPPORT_PAGE: '/support',

  LOGIN: '/login',
  REGISTER: '/register/:commissioner_e_mail?',
  FORGOT_PASSWORD: '/forgot-my-password',
  SET_NEW_PASSWORD: '/new-password/:token',
  LOGIN_AS_MANAGER: '/login-as-manager/:token',

  REGISTER_STEPS: '/register/steps',
  REGISTER_STEP: '/register/steps/:step',

  VERIFY_EMAIL: '/email-verification/:token',

  STARTING: '/starting',

  SETTINGS_INDEX: '/preferences',
  COMPANY_EDIT_SETTINGS: '/company/information',
  GENERAL_SETTINGS: '/preferences/general',
  PRODUCT_SETTINGS: '/preferences/product',
  TSOFT_WS_SETTINGS: '/preferences/t-soft-web-service',
  INVOICE_SETTINGS: '/preferences/invoice',
  DESPATCH_SETTINGS: '/preferences/despatch',
  INTEGRATION_SETTINGS: '/preferences/integration',

  MANAGER_LIST: '/manager/list',
  MANAGER_NEW: '/manager/new',
  MANAGER_INFO: '/manager/info',
  MANAGER_UPDATE: '/manager/update/:id',

  CUSTOMER_LIST: '/customer/list',
  CUSTOMER_NEW: '/customer/new',
  CUSTOMER_DETAIL: '/customer/detail/:id',
  CUSTOMER_UPDATE: '/customer/update/:id',
  ADD_DEBIT_CREDIT_FOR_CUSTOMER: '/customer/detail/:id/:type',
  CUSTOMER_DEFAULT_SETTINGS: '/customer/default-settings',
  CUSTOMER_BALANCE_REPORT: '/customer/balance-report',
  CUSTOMER_DEFAULTS: '/customer/defaults',

  PRODUCT_LIST: '/product/list',
  PRODUCT_NEW: '/product/new',
  PRODUCT_COPY: '/product/new/copy/:id',
  PRODUCT_DETAIL: '/product/detail/:id',
  ADD_SUB_PRODUCT: '/product/detail/:id/add-sub-product',
  EDIT_SUB_PRODUCT: '/product/detail/:id/edit-sub-product/:sub_product_id',
  SET_DEFAULT_ATTRIBUTE_GROUPS_FOR_SUB_PRODUCT: '/product/detail/:id/set-default-attribute-groups',
  PRODUCT_UPDATE: '/product/update/:id',
  PRODUCT_DEFAULT_SETTINGS: '/product/defaults',
  SUB_PRODUCT_NEW: '/product/detail/:id/sub-product/new',

  WEBHOOK_LIST: '/webhook/list',
  WEBHOOK_DETAIL: '/webhook/detail/:id',
  WEBHOOK_NEW: '/webhook/new',
  WEBHOOK_UPDATE: '/webhook/update/:id',

  ADDITIONAL_CHARGE_LIST: '/additional-charge',
  ADDITIONAL_CHARGE_NEW: '/additional-charge/new',
  ADDITIONAL_CHARGE_UPDATE: '/additional-charge/update/:id',
  ADDITIONAL_CHARGE_COPY: '/additional-charge/copy/:id',

  WAYBILL_NEW_INVOICE: '/waybill-invoice/new/:type/:id',
  WAYBILL_DETAIL: '/waybill/:type/detail/:id',

  INCOMING_E_INVOICE_BOX_PAGE: '/invoice/incoming-e-invoice-box',
  INCOMING_E_INVOICE_BOX_DETAIL: '/invoice/detail/incoming-e-invoice-box/:id',

  INVOICE_LIST: '/invoice/list',
  INVOICE_NEW: '/invoice/new',
  INVOICE_COPY_NEW: '/invoice/new/copy/:type/:id',
  INVOICE_TYPE_NEW: '/invoice/new/:type/:id',
  INVOICE_DETAIL_BY_TYPE: '/invoice/:type/detail/:id',
  INVOICE_DETAIL_PAY_BY_TYPE: '/invoice/:type/detail/:id/pay',
  INVOICE_RETURN_BY_TYPE: '/invoice/:type/return/:id',
  INVOICE_RETURN_PAY_BY_TYPE: '/invoice/:type/return/:id/pay',

  PURCHASE_RETURN_INVOICE_DETAIL: '/purchase-return-invoice-detail/:id',
  PURCHASE_RETURN_INVOICE_PAY_DETAIL: '/purchase-return-invoice-detail/:id/pay',

  TRANSACTION_LIST: '/transaction/list',
  TRANSACTION_REPORT: '/transaction/report',
  TRANSACTION_VAT_REPORT: '/transaction/vat-report',
  TRANSACTION_DETAIL_BY_TYPE: '/transaction/:id/detail/:type',
  TRANSACTION_DETAIL_UPDATE_BY_TYPE: '/transaction/:id/detail/:type/update',

  SAFE_LIST: '/safe/list',
  SAFE_NEW: '/safe/new',
  SAFE_DETAIL_BY_ID: '/safe/detail/:id',
  SAFE_DETAIL_BY_TYPE: '/safe/detail/:id/:type',
  SAFE_MONEY_IO: '/safe/detail/:id/money/:type',
  SAFE_UPDATE: '/safe/update/:id',
  SAFE_REPORT: '/safe/report',
  SAFE_INCOME_EXPENSE_REPORT: '/safe/income-expense-report',

  CATEGORY_LIST: '/categories',
  CATEGORY_NEW: '/categories/new/:type',
  CATEGORY_UPDATE_BY_TYPE: '/categories/:id/update/:type',

  INTEGRATION_TSOFT_FETCH_ORDERS: '/integration/tsoft/fetch-orders',
  INTEGRATION_TSOFT_SYNC_INVOICE: '/integration/tsoft/sync-invoice/:id',

  MY_PACKAGES_LIST: '/my-packages/list',
  MY_PACKAGES_BUY_NEW: '/my-packages/buy-new',

}
