import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { Form, Icon, Input, Button, Col, Card, Divider, Select, Switch } from 'antd'

import routes from '../../../routes'

import AddUpSelect from './../../Utils/AddUpSelect'

import {
  deleteNull,
  successToast,
  selectFilterLowerCase,
  updateEmptyAndUndefined,
  formErrorFieldsNameLocalize,
} from '../../../helpers/helper'
import { postPanelApi } from '../../../api'

class CustomerUpdateForm extends Component {

  customerId

  state = {
    submitting: false,
    categories: [],
  }

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: this.props.isModal ? 16 : 12 },
    },
  }

  formItemLayoutHalf = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  }

  formItemLayoutNoLabel = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 },
      lg: { span: 0 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: this.props.isModal ? 24 : 20 },
    },
  }

  constructor(props) {

    super(props)

    this.customerId = Number(this.props.customer_id)

  }

  componentDidMount() {

    postPanelApi('/customer/edit-open', { customer_id: this.customerId }).then(result => {

      if (result) {

        this.setState({
          categories: result.categories,
        })

        this.props.form.setFieldsValue(deleteNull(result.form))

      }

    })

  }

  onSubmit = () => {

    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      values.customer_id = this.customerId

      this.setState({
        submitting: true,
      })

      postPanelApi('/customer/edit-submit', values).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.CUSTOMER_DETAIL, {
            id: this.customerId,
          }))

        } else {

          this.setState({
            submitting: false,
          })

        }

      })

    })

  }

  createCustomerCategory = (name) => {

    postPanelApi('/customer/category/create', { name }).then(result => {

      if (result) {

        this.setState(state => {

          const categories = [...state.categories, {
            category_id: result.category_id,
            name: result.name,
          }]

          return {
            categories,
          }

        })

      }

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        title={<span><Icon type="edit"/> {this.props.langData['Update Customer']}</span>}
        bordered={false}
      >

        <Form
          hideRequiredMark={true}
          onSubmit={e => {
            e.preventDefault()
            this.onSubmit()
          }}
          className="form-label-default"
        >

          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('name', {
                rules: [{ max: 250 }, { required: true }],
                validateTrigger: false,
              })(
                <Input size="large" maxLength={250}/>,
              )}
            </Form.Item>

            <Form.Item hasFeedback
                       label={(<span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>)}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('category_id', { validateTrigger: false })(
                <AddUpSelect
                  data={this.state.categories.map(x => ({
                    key: x.category_id,
                    value: x.category_id,
                    content: x.name,
                  }))}
                  onAddUp={value => this.createCustomerCategory(value)}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('code', { rules: [{ max: 20 }], validateTrigger: false })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="phone"/><p>{this.props.langData['Phone'] + ' 1'}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('phone_1', { rules: [{ max: 20 }], validateTrigger: false })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="phone"/><p>{this.props.langData['Phone'] + ' 2'}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('phone_2', { rules: [{ max: 20 }], validateTrigger: false })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="environment-o"/><p>{this.props.langData['Country']}</p></span>)}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('country', { rules: [{ max: 100 }], validateTrigger: false })(
                <Select
                  size="large"
                  showSearch
                  allowClear
                  filterOption={selectFilterLowerCase}
                  suffixIcon={<Icon type="caret-down"/>}
                  maxLength={100}
                >
                  {this.props.langData.countries.map((x, k) => {
                    return <Select.Option value={x} key={k}>{x}</Select.Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon
                type="environment-o"/><p>{this.props.langData['City'] + ' / ' + this.props.langData['Town'] + ' / ' + this.props.langData['Postal Code']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              <Input.Group size="large">
                <Col span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('city', { rules: [{ max: 100 }], validateTrigger: false })(
                      this.props.lang === 'tr' && this.props.form.getFieldValue('country') === 'Türkiye' ? (
                        <Select
                          size="large"
                          className="w-100"
                          showSearch
                          suffixIcon={<Icon type="caret-down"/>}
                          allowClear
                          filterOption={selectFilterLowerCase}
                          maxLength={100}
                        >
                          {this.props.langData.cityTown.map((x, k) => {
                            return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                          })}
                        </Select>
                      ) : (
                        <Input size="large" maxLength={100}/>
                      ),
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('town', { rules: [{ max: 100 }], validateTrigger: false })(
                      this.props.lang === 'tr' && this.props.form.getFieldValue('country') === 'Türkiye' ? (
                        <Select
                          size="large"
                          className="w-100"
                          showSearch
                          allowClear
                          suffixIcon={<Icon type="caret-down"/>}
                          filterOption={selectFilterLowerCase}
                          maxLength={100}
                        >
                          {this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('city')).length && (
                            this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('city'))[0].towns.map((x, k) => {
                              return <Select.Option value={x} key={k}>{x}</Select.Option>
                            })
                          )}
                        </Select>
                      ) : (
                        <Input size="large" maxLength={100}/>
                      ),
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('postal_code')(
                      <Input
                        size="large"
                        style={{ width: '100%' }}
                        maxLength={10}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Input.Group>

            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="environment-o"/><p>{this.props.langData['Address']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('address', { rules: [{ max: 500 }], validateTrigger: false })(
                <Input.TextArea
                  size="large"
                  maxLength={500}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="mail"/><p>{this.props.langData['E-Mail']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('e_mail', {
                rules: [{ type: 'email', max: 100 }],
                validateTrigger: false,
              })(
                <Input size="large" maxLength={70}/>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon
                type="money-collect"/><p>{this.props.langData['Bank Name'] + ' / ' + this.props.langData['IBAN No']}</p></span>
            )}
                       {...this.formItemLayout}
            >
              <Input.Group size="large">
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('bank_name', { validateTrigger: false })(
                      <Input placeholder={this.props.langData['Bank Name']} maxLength={100} size="large"/>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('iban_no', { validateTrigger: false })(
                      <Input placeholder={this.props.langData['IBAN No']} maxLength={100} size="large"/>,
                    )}
                  </Form.Item>
                </Col>
              </Input.Group>
            </Form.Item>
          </div>

          <Divider/>

          <div className="form-block">

            <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Type']}</p></span>)}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('is_individual', {
                validateTrigger: false,
                valuePropName: 'checked',
              })(
                <Switch
                  checkedChildren={this.props.langData['Real Person']}
                  unCheckedChildren={this.props.langData['Legal Person']}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="idcard"/><p>{
                this.props.form.getFieldValue('is_individual') ?
                  this.props.langData['Identification Number'] :
                  this.props.langData['Id/Tax Identification Number']
              }</p></span>
            )}
                       {...this.formItemLayout}
            >
              {getFieldDecorator('id_or_tax_identification_number', {
                rules: [{ max: 11, min: 10 }],
                validateTrigger: false,
              })(
                <Input size="large" maxLength={11}/>,
              )}
            </Form.Item>

            { ! this.props.form.getFieldValue('is_individual') && (
              <Form.Item hasFeedback label={(
                <span><Icon type="compass"/><p>{this.props.langData['Tax Administration']}</p></span>
              )}
                         {...this.formItemLayout}
              >
                {getFieldDecorator('tax_administration', {
                  rules: [{ max: 250 }],
                  validateTrigger: false,
                })(
                  <Input.TextArea size="large" maxLength={250} autoSize={{ minRows: 2, maxRows: 5 }}/>,
                )}
              </Form.Item>
            )}

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item hasFeedback {...this.formItemLayoutNoLabel} >
              {getFieldDecorator('explanation', { rules: [{ max: 500 }], validateTrigger: false })(
                <Input.TextArea
                  maxLength={500}
                  size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                  placeholder={this.props.langData['Explanation']}
                />,
              )}
            </Form.Item>
          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item {...this.formItemLayoutNoLabel}>
              <Button
                loading={this.state.submitting}
                type="primary"
                size="large"
                htmlType="submit"
                className="default-button"
              >
                {this.props.langData['Save']}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    )
  }

}

CustomerUpdateForm.propTypes = {
  langData: PropTypes.object,
  customer_id: PropTypes.number,
  history: PropTypes.object,
  form: PropTypes.object,
  fixed_exchange_rate: PropTypes.string,
  lang: PropTypes.string,
  isModal: PropTypes.func,
  incomingEInvoiceData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    fixed_exchange_rate: state.GlobalReducer.fixed_exchange_rate,
  }
})(CustomerUpdateForm)
