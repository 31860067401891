import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, InputNumber, Button, Divider, Modal, Select, Spin } from 'antd'
import { graphQLErrorsToToast } from './../../../helpers/helper'
import { debounce } from 'lodash'
import { gql } from '@apollo/client'

import apolloClient from './../../../helpers/apolloClient'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

class InvoiceProduct extends Component {

  render() {
    const WrappedInvoiceProductForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceProductForm)
    return <WrappedInvoiceProductForm {...this.props} />
  }

}

InvoiceProduct.propTypes = {
  langData: PropTypes.object,
}

const PRODUCT_SEARCH_QUERY = (
  gql`
    query productList($name: String!){
      productList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        products {
          product_id
          name
          buying_price
          buying_currency
          selling_price
          selling_currency
          vat
          additional_charges {
            additional_charge_id
            buying_price
            selling_price
            vat
            additional_charge {
              name
            }
          }
        }
      }
    }
  `
)

class InvoiceProductForm extends Component {

  constructor(props) {
    super(props)
    let productList = []
    if (props.product.product_id)
      productList.push({
        name: props.product.products.find(x => x.product_id === props.product.product_id).name,
        product_id: props.product.product_id,
      })
    this.state = {
      productList,
      fetchingProduct: false,
    }
    this.searchProduct = debounce(this.searchProduct, 800)
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err)
        return
      this.props.editProduct(
        {
          ...this.props.product,
          ...values,
          vatTotal: ((values.amount * values.quantity) / 100) * (values.vat || 0),
        },
        values.product_id ? this.state.productList.find(x => x.product_id === values.product_id).name : null,
        values.product_id ? this.state.productList.find(x => x.product_id === values.product_id).unit : null,
        this.state.productList.find(x => x.product_id === values.product_id).additional_charges || [],
      )
    })
  }

  searchValue = null

  searchProduct = async value => {
    if (this.searchValue === value)
      return

    this.searchValue = value

    if (value.length < 2)
      return

    this.setState({ fetchingProduct: true })

    try {
      const result = await apolloClient.query({ query: PRODUCT_SEARCH_QUERY, variables: { name: value } })
      if ( ! result.data.productList.products.length)
        this.props.form.setFieldsValue({ product_id: null })
      this.setState({ fetchingProduct: false, productList: result.data.productList.products })
    } catch (err) {
      this.setState({ fetchingProduct: false })
      graphQLErrorsToToast(err)
    }

  }

  calculateProduct = (reverse = false) => {
    setTimeout(() => {
      let product = this.props.form.getFieldsValue()
      if ( ! reverse) {
        let vatTotal = parseFloat(((((product.quantity || 0) * (product.amount || 0)) / 100) * product.vat).toFixed(4))
        this.props.form.setFieldsValue({
          total: parseFloat((((product.quantity || 0) * (product.amount || 0)) + vatTotal).toFixed(4)),
        })
      } else {
        if (product.vat) {
          let without_vat_total = parseFloat(((product.total || 0) / parseFloat(((product.vat / 100) + 1).toFixed(4))).toFixed(4))
          this.props.form.setFieldsValue({
            amount: parseFloat((without_vat_total / (product.quantity || 0)).toFixed(4)),
          })
        } else {
          this.props.form.setFieldsValue({
            amount: parseFloat((product.total / (product.quantity || 0)).toFixed(4)),
          })
        }
      }
    }, 50)
  }

  render() {

    const { getFieldDecorator } = this.props.form
    return (
      <Modal
        title={<span><Icon
          type="star-o"/> &nbsp;{this.props.langData[this.props.product.product_id ? 'Edit Product' : 'Add Product']}</span>}
        bodyStyle={{ padding: 8 }}
        visible={true}
        footer={null}
        onCancel={this.props.modalClose}
      >
        <Form onSubmit={this.onSubmit} className="form-label-default">


          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="star-o"/><p>{this.props.langData['Product']}</p></span>
            )}
            >
              {getFieldDecorator('product_id', {
                rules: [{ required: true }],
                initialValue: this.props.product.product_id,
              })(
                <Select
                  size="large"
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  showSearch
                  notFoundContent={this.state.fetchingProduct ? <Spin size="large"/> : null}
                  onSearch={this.searchProduct}
                  onSelect={value => {
                    let product = this.state.productList.find(x => x.product_id === value)
                    let price_currency = this.props.type === 'sales' ? 'selling_currency' : 'buying_currency'
                    let price = this.props.type === 'sales' ? 'selling_price' : 'buying_price'

                    if (product[price]) {
                      if (product[price_currency] === this.props.selectedCurrency.code)
                        this.props.form.setFieldsValue({ amount: product[price] })
                      else
                        this.props.form.setFieldsValue({
                          amount: parseFloat(
                            (product[price] / this.props.currency[product[this.state.type === 'sales' ? 'selling_currency' : 'buying_currency'].code]).toFixed(4),
                          ),
                        })
                    }

                    if (product.vat)
                      this.props.form.setFieldsValue({ vat: product.vat })

                    this.calculateProduct()
                  }}
                >
                  {this.state.productList.map((x, k) => {
                    return <Option value={x.product_id} key={k}>{x.name}</Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Quantity']}</p></span>
            )}>
              {getFieldDecorator('quantity', {
                rules: [{ required: true }],
                initialValue: this.props.product.quantity,
              })(
                <InputNumber
                  size="large"
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateProduct(false)}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Amount']}</p></span>
            )}>
              {getFieldDecorator('amount', {
                rules: [{ required: true }],
                initialValue: this.props.product.amount || 0.0001,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateProduct(false)}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedCurrency.symbol, '') + ` ${this.props.selectedCurrency.symbol}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedCurrency.symbol, '')}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Vat']}</p></span>
            )}>
              {getFieldDecorator('vat', {
                initialValue: this.props.product.vat,
              })(
                this.props.lang === 'tr' ? (
                  <Select
                    className="w-100"
                    onChange={() => this.calculateProduct(false)}
                  >
                    {this.props.langData.vats.map((x, k) => {
                      return <Option value={x} key={k}>{`${x}%`}</Option>
                    })}
                  </Select>
                ) : (
                  <InputNumber
                    className="w-100"
                    min={0}
                    step={1}
                    onChange={() => this.calculateProduct(false)}
                    formatter={value => {
                      if (value.toString() === '')
                        return ''
                      return value.toString().replace('%', '') + '%'
                    }}
                    parser={value => value.replace('%', '')}
                  />
                ),
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Total']}</p></span>
            )}>
              {getFieldDecorator('total', {
                rules: [{ required: true }],
                initialValue: this.props.product.total || 0.0001,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  onChange={() => this.calculateProduct(true)}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedCurrency.symbol, '') + ` ${this.props.selectedCurrency.symbol}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedCurrency.symbol, '')}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit" className="default-button">
                {this.props.langData[this.props.product.product_id ? 'Edit' : 'Add']}
              </Button>
              <Button size="large" onClick={this.props.modalClose} style={{ marginRight: 8 }}
                      className="default-button danger2">
                {this.props.langData['Close']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Modal>
    )
  }
}

InvoiceProductForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  product: PropTypes.object,
  form: PropTypes.object,
  editProduct: PropTypes.func,
  modalClose: PropTypes.func,
  selectedCurrency: PropTypes.object,
  type: PropTypes.string,
  currency: PropTypes.object,
}

export default connect(mapStateToProps)(InvoiceProduct)
