import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Route, Link, generatePath } from 'react-router-dom'
import { connect } from 'react-redux'
import { DeleteOutlined, FileDoneOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons'

import {
  deleteNull,
  formErrorFieldsNameLocalize,
  toLocaleString,
  confirm,
  successToast, confir2m,
} from '../../../helpers/helper'
import {
  Card,
  Icon,
  Form,
  Input,
  DatePicker,
  Divider,
  Table,
  Button,
  Col,
  Modal,
  Row,
  Progress,
} from 'antd'


import routes from '../../../routes'
import { postPanelApi } from '../../../api'
import NewDebitTransaction from './NewDebitTransaction'
import EinvoiceModalForIade from './EinvoiceModalForIade'
import { openBase64NewTab } from '../../../utils'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 6 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

class PurchaseReturnInvoiceDetailForm extends Component {

  mounted = false

  type

  constructor(props) {
    super(props)
    this.type = 'purchase'
  }

  state = {

    submitting: false,
    operation_in_progress: false,

    show_einvoice_form: false,

    customer_balance: 0,
    currency_code: 'TL',

    product2s: [],

    footer_info: {
      sub_total: '',
      tax_amount_total: '',
      grand_total: '',
    },

    commits: [],

    invoic2e: {
      transaction_id: 0,
      invoice_id: 0,
      customer_id: 0,
      remaining: 0,
      currency_symbol: '',
      paid_rate: 0,
      expiry_date: '',
      expiry_date_in_days: 0,
      e_invoice_id: '',
      e_invoice_uuid: '',
    },

  }

  commitColumns = [
    {
      title: this.props.langData['Safe'],
      dataIndex: 'safe_name',
      width: '20%',
    },
    {
      title: this.props.langData['Explanation'],
      dataIndex: 'explanation',
      width: '35%',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'amount',
      width: '20%',
      className: 'text-right',
    },
    {
      title: this.props.langData['Amount processed'],
      dataIndex: 'processed_amounts',
      className: 'text-right',
      width: '25%',
      render: (value) => {
        return value.map((item, index) => <div key={index}>{item}</div>)
      },
    },
  ]

  columns = [
    {
      title: this.props.langData['Product'] + ' / ' + this.props.langData['Additional Charge'],
      key: 'product_id|additional_charge_id',
      width: '40%',
      dataIndex: 'name',
    },
    {
      title: this.props.langData['Quantity'],
      width: '12%',
      dataIndex: 'quantity',
      className: 'text-center',
    },
    {
      title: this.props.langData['Price'],
      dataIndex: 'amount',
      width: '14%',
      className: 'text-right',
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'tax_rate',
      width: '12%',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'total',
      width: '14%',
      className: 'text-right',
    },
  ]

  async componentDidMount() {

    this.mounted = true

    await this.reloadPageData()

  }

  componentWillUnmount() {

    this.mounted = false

  }

  async reloadPageData() {

    return postPanelApi('/invoice/purchase-return-invoice/detail-open', { transaction_id: this.props.transaction_id }).then(result => {

      if (result) {

        if (this.mounted) {

          this.setState({
            customer_balance: result.customer_balance,
            currency_code: result.currency_code,
            product2s: result.products,
            footer_info: result.footer_info,
            commits: result.commits,
            invoic2e: result.invoice,
          })

          this.props.form.setFieldsValue(deleteNull({
            'explanation': result.form.explanation,
            'customer_name': result.form.customer_name,
            'date': moment(result.form.date),
          }))

        }

      }

    })

  }

  onSubmit = () => {

    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      delete values.customer_name

      this.setState({
        submitting: true,
      })

      postPanelApi('/invoice/purchase-return-invoice/detail-submit', {
        transaction_id: this.props.transaction_id,
        ...values,
      }).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
            type: 'purchase',
            id: this.state.invoic2e.invoice_id,
          }))

        } else {

          this.setState({
            submitting: false,
          })

        }

      })

    })

  }

  rowItemClick = debitTransactionId => {
    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      id: debitTransactionId,
      type: 'debit',
    }))
  }

  deleteReturnInvoice = () => {

    confirm(undefined, async result => {

      if ( ! result) {
        return
      }

      postPanelApi('/invoice/purchase-return-invoice/delete', {
        transaction_id: this.props.transaction_id,
      }).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
            type: 'purchase',
            id: this.state.invoic2e.invoice_id,
          }))

        }

      })

    })

  }

  einvoiceModalForIadeCallback = async data => {

    return postPanelApi('/invoice/create-einvoice-for-iade', {
      transaction_id: this.props.transaction_id,
      einvoice_date: data.einvoice_date,
      einvoice_no: data.einvoice_no,
    }).then(result => {

      if (result) {

        successToast(result.data.message)

        this.setState({
          invoic2e: {
            ...this.state.invoic2e,
            e_invoice_id: result.data.einvoice.id,
            e_invoice_uuid: result.data.einvoice.uuid,
          },
        })

        // modal'a işin sorunsuz tamamlandığının bilgisini veriyoruz
        return true

      }

      // işlemin başarılı bir şekilde tamamlanmadığının bilgisini modal'a vermiş oluyoruz
      return false

    })

  }

  eInvoicePrint = async () => {

    this.setState({
      operation_in_progress: true,
    })

    return postPanelApi('/invoice/open-pdf', { transaction_id: this.props.transaction_id }).then(result => {

      if (result) {

        openBase64NewTab(result.data)

      }

      this.setState({
        operation_in_progress: false,
      })

    })

  }

  sendEmail = () => {

    postPanelApi('/invoice/send-email-step1', { transaction_id: this.props.transaction_id }).then(result => {

      if (result) {

        confir2m(result.receipent_name + ' isimli müşterinin ' + result.receipent_email + ' e-posta adresine faturanın PDF\'i gönderilecek.', (confirmed) => {

          if (confirmed) {

            postPanelApi('/invoice/send-email-step2', { transaction_id: this.props.transaction_id }).then(result => {

              if (result) {

                successToast(result.message)

              }

            })

          }

        })

      }

    })

  }

  cancelETransformation = () => {

    confir2m('E-Fatura iptal edilecek! Emin misiniz?', (confirmed) => {

      if (confirmed) {

        this.setState({
          operation_in_progress: true,
        })

        postPanelApi('/invoice/einvoice-cancel', {
          transaction_id: this.props.transaction_id,
          type: 'EInvoice',
        }).then(result => {

          if (result) {

            successToast(result.message)

            this.setState({
              invoic2e: {
                ...this.state.invoic2e,
                e_invoice_id: null,
                e_invoice_uuid: null,
              },
            })

          }

          this.setState({
            operation_in_progress: false,
          })

        })

      }

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
          <Card
            title={<span><Icon type="file-excel"/> {this.props.langData['Return Invoice']}</span>}
            bordered={false}
            extra={
              <div>
                <Button onClick={() => this.setState({ show_einvoice_form: true })} style={{ marginLeft: 5 }}>
                  <FileDoneOutlined/> {this.props.langData['E-Invoice']}
                </Button>
                <Button onClick={this.deleteReturnInvoice} style={{ marginLeft: 5 }}>
                  <DeleteOutlined/> {this.props.langData['Delete']}
                </Button>
              </div>
            }
          >
            <Form hideRequiredMark={true} className="form-label-default">

              <div className="form">

                <Form.Item label={(
                  <span><Icon type="edit"/><p>{this.props.langData['Explanation']}</p></span>
                )}
                           {...formItemLayout}
                >
                  {getFieldDecorator('explanation', {
                    rules: [{ max: 100 }],
                    validateTrigger: false,
                  })(
                    <Input size="large"/>,
                  )}
                </Form.Item>

                <Form.Item label={(
                  <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                )}
                           help={
                             <div style={{ marginTop: '3px' }}>
                               <Icon type="info-circle"/>&nbsp;
                               {this.props.langData['This customer has debt'](toLocaleString(this.state.customer_balance), this.state.currency_code)}
                             </div>
                           }
                           {...formItemLayout}
                >
                  {getFieldDecorator('customer_name', { rules: [{ max: 100 }] })(
                    <Input disabled={true} size="large"/>,
                  )}
                </Form.Item>

                <Form.Item label={(
                  <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                )}
                           {...formItemLayoutHalf}
                >
                  {getFieldDecorator('date', {
                    rules: [{ required: true }],
                  })(
                    <DatePicker
                      format="DD.MM.YYYY"
                      size="large"
                      suffixIcon={<Icon type="calendar" theme="filled"/>}
                      className="w-100"
                    />,
                  )}
                </Form.Item>

                <Divider/>

                <Form.Item {...formItemLayoutNoLabel}>
                  <Button
                    loading={this.state.submitting}
                    size="large"
                    type="primary"
                    className="default-button"
                    onClick={() => this.onSubmit()}
                  >
                    {this.props.langData['Save']}
                  </Button>
                </Form.Item>

              </div>
            </Form>
          </Card>

          <Card
            bodyStyle={{ padding: 0, marginTop: 20 }}
            bordered={false}
          >
            <Divider>
              <Icon
                type="star-o"/> {this.props.langData['Products']} / {this.props.langData['Additional Charges']}
            </Divider>
            <Table
              columns={this.columns}
              rowKey="row_key"
              dataSource={this.state.product2s}
              pagination={false}
              id="table"
              scroll={{ x: 700, y: 500 }}
            />

            <div
              className="form-block"
              style={{ textAlign: 'right', width: '100%', height: 'auto', marginTop: 20 }}
            >

              <div className="fl-r">
                <p>{this.state.footer_info.sub_total}</p>
                <p>{this.state.footer_info.tax_amount_total}</p>
                <p><strong>{this.state.footer_info.grand_total}</strong></p>
              </div>

              <div className="fl-r" style={{ marginRight: 26 }}>
                <p>{this.props.langData['Interim Total']}</p>
                <p>{this.props.langData['Total VAT']}</p>
                <p><strong>{this.props.langData['Grand Total']}</strong></p>
              </div>

            </div>

          </Card>

          {this.state.commits.length > 0 && (
            <Card
              bodyStyle={{ padding: 0, marginTop: 20 }}
              bordered={false}
            >
              <Divider>
                <Icon type="swap"/> {this.props.langData['Payment List']}
              </Divider>

              <Table
                columns={this.commitColumns}
                rowKey="row_key"
                dataSource={this.state.commits}
                pagination={false}
                onRow={record => ({
                  onClick: () => this.rowItemClick(record.paid_transaction_id),
                })}
                style={{ borderRadius: '0px' }}
                rowClassName="white"
                scroll={{ x: 700 }}
              />

            </Card>
          )}
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} xl={7}>

          <Route exact path={routes.PURCHASE_RETURN_INVOICE_DETAIL} render={() => {

            return (
              <Card
                bodyStyle={{ padding: '0px' }}
                title={this.state.invoic2e.remaining > 0 && (
                  <Button className="w-100" type="primary">
                    <Link
                      to={generatePath(routes.PURCHASE_RETURN_INVOICE_PAY_DETAIL, {
                        id: this.props.transaction_id,
                      })}
                    >
                      {this.props.langData['Get Payment to Return Invoice']}
                    </Link>
                  </Button>
                )}
                bordered={false}
              >
                <div className="form-block">
                  <Row gutter={24} style={{ marginBottom: 16 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                      <span style={{ fontSize: 15 }}>{this.props.langData['Remaining']}</span>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={18} className="text-right">
                              <span style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {this.state.invoic2e.remaining}
                              </span>
                      <span style={{
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}> {this.state.invoic2e.currency_symbol}</span>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: this.state.invoic2e.remaining > 0 ? 0 : 16 }}>
                    <Progress
                      percent={this.state.invoic2e.paid_rate}
                      strokeColor={{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                      }}
                      format={value => value.toString().replace('.', ',') + '%'}
                      status="active"
                    />
                  </Row>
                  {this.state.invoic2e.remaining > 0 && (
                    <div>
                      <Divider/>
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={9} sm={10} md={8} lg={8} xl={10}>
                      <span style={{
                        fontSize: 15,
                        color: '#9ea6ad',
                      }}>{this.props.langData['Payment Date']}</span>
                        </Col>
                        <Col xs={15} sm={14} md={16} lg={16} xl={14} className="text-right">
                      <span style={{
                        fontSize: 18,
                        color: 'red',
                      }}>{this.state.invoic2e.expiry_date ? moment(this.state.invoic2e.expiry_date).locale(this.props.lang).format('DD MMMM YYYY') : '-'}</span>
                        </Col>
                        {this.state.invoic2e.expiry_date_in_days <= 0 && (
                          <Col
                            xs={12} sm={24} md={16} lg={16} xl={24} className="text-right"
                            style={{ float: 'right' }}
                          >
                        <span style={{ fontSize: 15, color: 'red' }}>
                          {this.state.invoic2e.expiry_date_in_days === 0 ? `(${this.props.langData['Expiry date is today']})` : `(${this.props.langData['Days Late'](Math.abs(this.state.invoic2e.expiry_date_in_days))})`}
                          <br/>
                        </span>
                          </Col>
                        )}
                      </Row>
                    </div>
                  )}
                </div>
              </Card>
            )

          }}/>

          <Route exact path={routes.PURCHASE_RETURN_INVOICE_PAY_DETAIL} render={props => {

            const newReturnPayment = (
              <NewDebitTransaction
                type={this.type}
                customer_id={this.state.invoic2e.customer_id}
                id={this.state.invoic2e.transaction_id}
                callback={async () => {
                  await this.reloadPageData()
                }}
                {...props}
              />
            )

            if (this.props.sizeWidth <= 576) {

              return (
                <Modal
                  title={
                    <span><Icon type="pay-circle-o"/> &nbsp;{
                      this.props.langData[this.state.type === 'sales' ? 'Get Payment' : 'Pay']
                    }</span>
                  }
                  bodyStyle={{ padding: 8 }}
                  visible={true}
                  footer={null}
                  destroyOnClose={true}
                  onCancel={() => {
                    props.history.push(
                      // @todo bu kısım test edilecek, bu link nereye gitmektedir, amacı nedir?
                      generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
                        type: `${this.state.type}Detail`,
                        id: this.props.transaction_id,
                      }),
                    )
                  }}
                >
                  {newReturnPayment}
                </Modal>
              )

            }

            return newReturnPayment
          }}
          />

          {this.state.invoic2e.e_invoice_id && (
            <Card
              bodyStyle={{ padding: '0px' }}
              style={{ marginTop: 10 }}
              title={
                <Button
                  className="w-100" type={'danger'}
                  onClick={this.eInvoicePrint}
                  disabled={this.state.operation_in_progress}
                >
                  <PrinterOutlined/> {this.props.langData['E-Invoice Print']}
                </Button>
              }
              bordered={false}
            >
              <div className="form-block">
                <Row gutter={24} style={{ marginBottom: 16 }}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <span style={{ fontSize: 15 }}>ID</span>
                  </Col>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.invoic2e.e_invoice_id}
												</span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: 16 }}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <span style={{ fontSize: 15 }}>UUID</span>
                  </Col>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.invoic2e.e_invoice_uuid}
												</span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: 16 }}>
                  <Card
                    bodyStyle={{ padding: '0px' }}
                    style={{ marginTop: 10 }}
                    title={
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Button
                            disabled={this.state.operation_in_progress}
                            className="w-100"
                            type="danger"
                            onClick={this.cancelETransformation}
                          >
                            <PrinterOutlined/> {this.props.langData['E-Invoice Cancel']}
                          </Button>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Button disabled={this.state.operation_in_progress} className="w-100" type="danger"
                                  onClick={this.sendEmail}>
                            <MailOutlined/> {this.props.langData['Send E-Mail']}
                          </Button>
                        </Col>
                      </Row>
                    }
                    bordered={false}
                  />
                </Row>
              </div>
            </Card>
          )}

          {this.state.show_einvoice_form && (
            <EinvoiceModalForIade
              langData={this.props.langData}
              callback={this.einvoiceModalForIadeCallback}
              onCancel={() => this.setState({ show_einvoice_form: false })}
            />
          )}

        </Col>
      </Row>
    )
  }
}

PurchaseReturnInvoiceDetailForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  match: PropTypes.object,
  type: PropTypes.string,
  transaction_id: PropTypes.number,
  lang: PropTypes.string,
}

export default connect(state => {
  return {
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
})(PurchaseReturnInvoiceDetailForm)
