import { combineReducers } from 'redux'

import { AuthReducer } from './AuthReducer'
import { LocalizeReducer } from './LocalizeReducer'
import { GeneralReducer } from './GeneralReducer'
import { GlobalReducer } from './GlobalReducer'
import { BreadCrumbReducer } from './BreadCrumbReducer'
import { SizeDetectorReducer } from './SizeDetectorReducer'
import { TableFilterReducer } from './TableFilterReducer'

export default combineReducers({
  AuthReducer,
  LocalizeReducer,
  GeneralReducer,
  GlobalReducer,
  BreadCrumbReducer,
  SizeDetectorReducer,
  TableFilterReducer,
})

