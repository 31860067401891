import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast } from '../../helpers/helper'
import { generatePath, Link } from 'react-router-dom'
import moment from 'moment'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { cloneDeep, startCase } from 'lodash'
import routes from '../../routes'
import { PlusOutlined } from '@ant-design/icons'

const LIST_QUERY = (
  gql`
    query($paging: Paging){
      hookList(paging: $paging){
        hooks {
          hook_id
          url
          type
          date
          active
          methods {
            method
          }
        }
        page_info {
          total_count
        }
      }
    }
  `
)

class WebhookList extends Component {

  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
  }

  columns = [
    {
      title: this.props.langData['Type'],
      dataIndex: 'type',
      width: '20%',
      key: 'type',
      render: value => this.props.langData[startCase(value)],
    },
    {
      title: this.props.langData['Method URI'],
      width: '40%',
      dataIndex: 'url',
      key: 'url',

    },
    {
      title: this.props.langData['Date'],
      width: '30%',
      dataIndex: 'date',
      key: 'date',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY'),
    },
    {
      title: this.props.langData['Active'],
      width: '10%',
      dataIndex: 'active',
      key: 'active',
      className: 'text-center',
      render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: '18px' }}/> : null,
    },

  ]

  componentDidMount() {
    this.props.breadcrumb_add()
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  handleTableChange = (paging) => {
    if (paging.pageSize)
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: (paging.current * paging.pageSize),
        },
      })
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.WEBHOOK_DETAIL, {
      id: record.hook_id,
    }))
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        variables={{ paging: this.state.paging }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data)
              this.setState({
                data: cloneDeep(data.hookList.hooks),
                pagination: {
                  ...this.state.pagination,
                  total: data.hookList.page_info.total_count,
                },
              })
          }

          return (
            <div>
              <Table
                columns={this.columns}
                rowKey={record => record.hook_id}
                dataSource={this.state.data}
                loading={loadingQuery}
                pagination={this.state.pagination}
                rowClassName="white"
                onRow={record => ({
                  onClick: () => this.rowItemClick(record),
                })}
                scroll={{ x: 500, y: 500 }}
              />
              <div style={{ textAlign: 'right', paddingTop: 10 }}>
                <Link to={routes.WEBHOOK_NEW}><Button size="large"><PlusOutlined/> {this.props.langData['New Web Hook']}</Button></Link>
              </div>
            </div>
          )
        }}
      </Query>
    )

  }

}

WebhookList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Web Hook' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(WebhookList)
