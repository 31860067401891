import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  selectFilterLowerCase,
  updateEmptyAndUndefined,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
  pushNotification,
} from '../../../helpers/helper'
import { connect } from 'react-redux'
import { currencyData, getCurrencyWithCode, getCurrenciesWithoutMatchWithCode } from '../../../data/Currency'
import {
  Form,
  Icon,
  Input,
  Button,
  Col,
  Card,
  Divider,
  Select,
  Table,
  InputNumber,
  Spin,
  DatePicker,
  Menu,
  Dropdown,
  Checkbox,
  Radio,
} from 'antd'
import { CaretDownOutlined, IdcardOutlined } from '@ant-design/icons'
import moment from 'moment'
import { cloneDeep, debounce } from 'lodash'
import InvoiceProduct from './../InvoiceProduct/InvoiceProduct'
import InvoiceAdditionalCharge from './../InvoiceAdditionalCharge/InvoiceAdditionalCharge'
import CustomerSearchModal from './../../Customer/CustomerSearchModal/CustomerSearchModal'
import ProductSearchModal from './../../Product/ProductSearchModal/ProductSearchModal'
import AdditionalChargeSearchModal
  from './../../AdditionalCharge/AdditionalChargeSearchModal/AdditionalChargeSearchModal'
import {
  searchCustomer,
  createCategory,
  sequenceSetFirstValues,
  refetchAndControlSequenceValues,
  fetchCurrencyRates,
  setDefault,
} from '../../../helpers/trait'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import apolloClient from './../../../helpers/apolloClient'
import AddUpSelect from './../../Utils/AddUpSelect'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 6 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

const ADDITIONAL_CHARGE_READ_QUERY = (
  gql`
    query additionalCharge($additional_charge_id: Int!){
      additionalCharge(additional_charge_id: $additional_charge_id) {
        additional_charge_id
        name
        category_id
        buying_price
        selling_price
        vat
      }
    }
  `
)

const PRODUCT_READ_QUERY = (
  gql`
    query ($product_id: Int!){
      product(product_id: $product_id) {
        product_id
        name
        selling_price
        selling_currency
        buying_price
        buying_currency
        vat
        name_on_invoice
        additional_charges {
          additional_charge_id
          buying_price
          selling_price
          quantity
          vat
          additional_charge {
            name
          }
        }
      }
    }
  `
)

const PRODUCT_SEARCH_QUERY = (
  gql`
    query productList($name: String!){
      productList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        products {
          product_id
          name
          buying_price
          buying_currency
          selling_price
          selling_currency
          vat
          additional_charges {
            additional_charge_id
            buying_price
            selling_price
            quantity
            vat
            additional_charge {
              name
            }
          }
        }
      }
    }
  `
)

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          additional_charge_id
          name
          buying_price
          selling_price
          vat
        }
      }
    }
  `
)

const READ_QUERY = type => (
  gql`
    query ($transaction_id: Int!){
    invoice: ${type}Invoice(transaction_id: $transaction_id) {
    customer_id
    customer {
    name
    }
    currency
    as_waybill
    currency_rates {
    try
    usd
    eur
    gbp
    rub
    }
    code
    no
    series
    category_id
    category {
    name
    }
    waybills {
    waybill_id
    }
    discount
    discount_type
    explanation
    date
    expiry_date
    invoice_country
    invoice_city
    invoice_town
    invoice_address
    invoice_postal_code
    delivery_country
    delivery_city
    delivery_town
    delivery_address
    delivery_postal_code
    ${type === 'sales' ? 'order_platform_number' : ''}
    products {
    invoice_product_id
    product_id
    quantity
    amount
    total
    vat
    grand_total
    product {
    name
    }
    }
    additional_charges {
    invoice_additional_charge_id
    additional_charge_id
    quantity
    amount
    total
    vat
    grand_total
    additional_charge {
    name
    }
    }
    }

    invoiceDefaults {
    series
    category_id
    category {
    name
    }
    invoice_country
    invoice_city
    invoice_town
    invoice_address
    delivery_country
    delivery_city
    delivery_town
    delivery_address
    explanation
    code_sequence {
    sequence_id
    pattern
    show_all_columns
    next_value
    end
    }
    no_sequence {
    sequence_id
    pattern
    show_all_columns
    next_value
    end
    }
    }

    invoiceCategoryList {
    category_id
    name
    }

    settings {
    invoice_settings {
    modal_status
    product_invoice_name_status
    }
    product_settings {
    critical_stock_count
    }
    }

    userInformation {
    settings {
    increment
    }
    }

    productList{
    products {
    product_id
    name
    code
    category_id
    stock_tracking
    stock
    unit_id
    selling_price
    selling_currency
    buying_price
    buying_currency
    vat
    barcode
    has_sub_product
    is_ghost
    }
    }
    }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
    query ($currency: Currency, $date: Date!){
      invoiceDefaults {
        series
        category_id
        category {
          name
        }
        invoice_country
        invoice_city
        invoice_town
        invoice_address
        delivery_country
        delivery_city
        delivery_town
        delivery_address
        explanation
        code_sequence {
          sequence_id
          pattern
          show_all_columns
          next_value
          end
        }
        no_sequence {
          sequence_id
          pattern
          show_all_columns
          next_value
          end
        }
      }

      invoiceCategoryList {
        category_id
        name
      }

      currencyRates (currency: $currency, date: $date){
        currency_rates {
          try
          usd
          eur
          gbp
          rub
        }
      }

      settings {
        invoice_settings {
          modal_status
          product_invoice_name_status
        }
        product_settings {
          critical_stock_count
        }
      }

      userInformation {
        settings {
          increment
        }
      }

      productList{
        products {
          product_id
          name
          code
          category_id
          stock_tracking
          stock
          unit_id
          selling_price
          selling_currency
          buying_price
          buying_currency
          vat
          barcode
          has_sub_product
          is_ghost
        }
      }
    }
  `
)

const WAYBILL_READ_QUERY = type => (
  gql`
    query($waybill_id:Int!, $currency: Currency, $date: Date!){
    invoice: ${type}Waybill(waybill_id:$waybill_id) {
    customer {
    name
    }
    waybill_id
    invoice_transaction_id
    customer_id
    no
    explanation
    date
    sending_date
    waybill_country
    waybill_city
    waybill_town
    waybill_address
    waybill_postal_code
    products {
    product_id
    unit_id
    quantity
    product {
    product_id
    name
    unit {
    name
    }
    vat
    selling_price
    additional_charges {
    additional_charge_id
    buying_price
    selling_price
    quantity
    vat
    }
    }
    }
    additional_charges {
    additional_charge_id
    unit_id
    quantity
    additional_charge {
    additional_charge_id
    name
    unit {
    name
    }
    vat
    selling_price
    }
    }
    invoice{
    transaction_id
    products {
    invoice_product_id
    }
    additional_charges {
    invoice_additional_charge_id
    }
    }
    }
    invoiceDefaults {
    series
    category_id
    category {
    name
    }
    invoice_country
    invoice_city
    invoice_town
    invoice_address
    delivery_country
    delivery_city
    delivery_town
    delivery_address
    explanation
    code_sequence {
    sequence_id
    pattern
    show_all_columns
    next_value
    end
    }
    no_sequence {
    sequence_id
    pattern
    show_all_columns
    next_value
    end
    }
    }
    settings {
    invoice_settings {
    modal_status
    product_invoice_name_status
    }
    product_settings {
    critical_stock_count
    }
    }

    userInformation {
    settings {
    increment
    }
    }
    currencyRates (currency: $currency, date: $date){
    currency_rates {
    try
    usd
    eur
    gbp
    rub
    }
    }

    productList {
    products {
    product_id
    name
    code
    category_id
    stock_tracking
    stock
    unit_id
    selling_price
    selling_currency
    buying_price
    buying_currency
    vat
    barcode
    has_sub_product
    is_ghost
    }
    }

    invoiceCategoryList {
    category_id
    name
    code
    }
    }
  `
)

const CREATE_QUERY = type => (
  gql`
    mutation invoiceCreate($invoice: InvoiceCreateInput!, $waybill_id: Int){
    invoice: ${type}InvoiceCreate(${type}_invoice: $invoice, waybill_id: $waybill_id){
    invoice: ${type}_invoice {
    transaction_id
    products{
    product {
    stock
    product_id
    stock_tracking
    }
    }
    }
    informative {
    messages
    }
    }
    }
  `
)

const UPDATE_QUERY = type => (
  gql`
    mutation invoiceUpdate($transaction_id: Int!, $invoice: InvoiceUpdateInput!){
    invoice: ${type}InvoiceUpdate(transaction_id: $transaction_id, ${type}_invoice: $invoice){
    invoice: ${type}_invoice {
    transaction_id
    products{
    product {
    stock
    product_id
    stock_tracking
    }
    }
    }
    informative {
    messages
    }
    }
    }
  `
)

class InvoiceForm extends Component {

  getFieldDecorator = this.props.form.getFieldDecorator

  initialState = {
    type: 'sales',
    data: null,
    sameAddress: false,
    defaults: null,
    categories: null,
    products: [],
    additional_charges: [],
    isSubmit: false,
    discount_type: 'Percent',
    selectedCurrency: {},
    addedCurrency: [],
    currency: {},
    customerList: [],
    fetchingCustomer: false,
    fetchingProduct: false,
    fetchingAdditionalCharge: false,
    invoice_country: null,
    invoice_city: null,
    delivery_country: null,
    delivery_city: null,
    footer: {
      interimTotal: 0,
      totalVat: 0,
      totalDiscount: 0,
      grandTotal: 0,
    },
    modalProduct: null,
    modalAdditionalCharge: null,
    modalProductSearch: null,
    modalAdditionalChargeSearch: null,
    initialize: false,
    isLoading: false,
    modal_status: null,
    increment: null,
    transaction_id: null,
    invoice: null,
    product_list: [],
    critical_stock_count: null,
    product_invoice_name_status: false,

    showProductSearchAsModal: false,
    showCustomerSearchAsModal: false,
  }

  state = this.initialState

  sequenceIntervals = null

  columns = [
    {
      title: this.props.langData['Product'] + ' / ' + this.props.langData['Additional Charge'],
      width: '40%',
      key: 'product_id|additional_charge_id',
      render: (value, record) => {
        let type = (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge'
        if (this.props.sizeWidth > 576)
          return (
            <Input.Group compact style={{ display: 'flex' }}>
              <Select
                suffixIcon={<Icon type="caret-down"/>}
                className="w-100"
                filterOption={false}
                showSearch
                defaultActiveFirstOption={false}
                notFoundContent={(this.state.fetchingProduct || this.state.fetchingAdditionalCharge) ? <Spin/> : null}
                value={record.product_id || record.additional_charge_id}
                onSearch={value => (type === 'product') ? this.searchProduct(record.id, value) : this.searchAdditionalCharge(record.id, value)}
                disabled={record.delete}
                onSelect={value => {
                  let datas = this.state[type + 's']
                  let data = datas.find(x => x.id === record.id)
                  let dataPure = data[type + 's'].find(x => x[type + '_id'] === value)
                  let price_currency = this.state.type === 'sales' ? 'selling_currency' : 'buying_currency'
                  let price = this.state.type === 'sales' ? 'selling_price' : 'buying_price'

                  if (dataPure[price]) {
                    if (type === 'product') {
                      if (dataPure[price_currency] === this.state.selectedCurrency.code)
                        data.amount = dataPure[price]
                      else
                        data.amount = parseFloat(
                          (dataPure[price] / this.state.currency[dataPure[this.state.type === 'sales' ? 'selling_currency' : 'buying_currency']])
                            .toFixed(4),
                        )
                    } else {
                      if (this.state.selectedCurrency.code === 'try')
                        data.amount = dataPure[price]
                      else
                        data.amount = parseFloat((dataPure[price] / this.state.currency.try).toFixed(4))
                    }
                  }

                  if (dataPure.vat)
                    data.vat = dataPure.vat

                  data[type + '_id'] = value

                  this.setState({ [type + 's']: datas })

                  if (type === 'product' && dataPure.additional_charges.length) {
                    let product_additional_charges = []
                    dataPure.additional_charges
                      .forEach(x => {
                        let amount = 0
                        if (this.state.selectedCurrency.code === 'try')
                          amount = x[price]
                        else
                          amount = parseFloat((x[price] / this.state.currency.try).toFixed(4))

                        product_additional_charges.push({
                          id: Math.floor(Math.random() * 999999) + 1,
                          additional_charge_id: x.additional_charge_id,
                          quantity: 1,
                          amount,
                          vat: x.vat,
                          vatTotal: 0,
                          total: 0,
                          additional_charges: [{
                            additional_charge_id: x.additional_charge_id,
                            name: x.additional_charge.name,
                          }],
                        })

                      })

                    this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
                    product_additional_charges.forEach(x => this.calculateRow(x.id, 'additional_charge'))

                  }

                  this.calculateRow(record.id, type)

                }}
              >
                {
                  (record.products || record.additional_charges).map((x, k) => {

                    if (this.state.product_invoice_name_status && x.name_on_invoice) {
                      return <Select.Option value={x.product_id || x.additional_charge_id}
                                            key={k}>{x.name} | {x.name_on_invoice}</Select.Option>
                    }

                    return <Select.Option value={x.product_id || x.additional_charge_id}
                                          key={k}>{x.name}</Select.Option>

                  })
                }
              </Select>
              <Button
                icon="search"
                disabled={record.delete}
                onClick={() => this.setState({ [`modal${type === 'product' ? 'Product' : 'AdditionalCharge'}Search`]: record.id })}
              ></Button>
            </Input.Group>
          )
        if (value && record[type + 's'].length)
          return record[type + 's'].find(x => x[type + '_id'] === record[type + '_id']).name
        return null
      },
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      width: '12%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0.0001}
              step={this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              disabled={record.delete || (this.props.waybill_id ? true : false)}
              onChange={value => {
                if (typeof (value) === 'number') {
                  let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                  datas.find(x => x.id === record.id).quantity = value
                  this.setState({
                    [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                  })
                  this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
                }
              }}
            />
          )
        return `${value} ${record.unit ? ' ' + record.unit.name : ''}`
      },
    },
    {
      title: this.props.langData['Price'],
      dataIndex: 'amount',
      width: '16%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <Input.Group compact style={{ display: 'flex' }}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step={this.state.increment.toFixed(4)}
                decimalSeparator=","
                onChange={value => {
                  if (typeof (value) === 'number') {
                    let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                    datas.find(x => x.id === record.id).amount = value
                    this.setState({
                      [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                    })
                    this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
                  }
                }}
                value={value}
                disabled={record.delete}
              />
              <Button disabled>{this.state.selectedCurrency.symbol}</Button>
            </Input.Group>
          )
        return value ? (value + ' ' + this.state.selectedCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '12%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576) {
          if (this.props.lang === 'tr')
            return (
              <Select
                style={{ width: '100%' }}
                suffixIcon={<Icon type="caret-down"/>}
                value={value}
                disabled={record.delete}
                onChange={value => {
                  let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                  datas.find(x => x.id === record.id).vat = value
                  this.setState({
                    [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                  })
                  this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
                }}
              >
                {this.props.langData.vats.map((x, k) => {
                  return <Select.Option value={x} key={k}>{`${x}%`}</Select.Option>
                })}
              </Select>
            )
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step={1}
              onChange={value => {
                let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                datas.find(x => x.id === record.id).vat = value
                this.setState({
                  [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                })
                this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
              }}
              value={value}
              disabled={record.delete}
              formatter={value => {
                if (value.toString() === '')
                  return ''
                return value.toString().replace('%', '') + '%'
              }}
              parser={value => value.replace('%', '')}
            />
          )
        }
        return value ? (value + '%') : null
      },
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'total',
      width: '14%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step={this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              disabled={true}
              onChange={value => {
                let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                datas.find(x => x.id === record.id).total = value
                this.setState({
                  [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                })
                this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge', true)
              }}
              formatter={value => {
                if (value.toString() === '')
                  return ''
                return value.toString().replace(this.state.selectedCurrency.symbol, '') + ` ${this.state.selectedCurrency.symbol}`
              }}
              parser={value => value.replace(' ' + this.state.selectedCurrency.symbol, '')}
            />
          )
        return `${value} ${this.state.selectedCurrency.symbol}`
      },
    },
    {
      className: 'text-right',
      render: (value, record) => {
        return (
          <Button
            icon={record.delete === true ? 'minus-circle-o' : 'close-circle-o'}
            style={{ width: 40 }}
            onClick={event => {
              event.stopPropagation()
              if (typeof record.product_id !== 'undefined')
                this.deleteProduct(record.id)
              else
                this.deleteAdditionalCharge(record.id)
            }}
          >
          </Button>
        )
      },
    },
  ]

  menu = (
    <Menu onClick={e => (e.key === 'product') ? this.addProduct() : this.addAdditionalCharge()}>
      <Menu.Item key="product">
        {this.props.langData['Product']}
      </Menu.Item>
      <Menu.Item key="additionalCharge">
        {this.props.langData['Additional Charge']}
      </Menu.Item>
    </Menu>
  )

  typeEnglishName = {
    'satis': 'sales',
    'alis': 'purchase',
  }

  constructor(props) {

    super(props)

    this.onChangeDate = debounce(this.onChangeDate, 800)
    this.searchCustomer = searchCustomer('invoice[customer_id]').bind(this)
    this.createCategory = createCategory('invoice').bind(this)
    this.searchProduct = debounce(this.searchProduct, 800)
    this.searchAdditionalCharge = debounce(this.searchAdditionalCharge, 800)
    this.refetchAndControlSequenceValues = refetchAndControlSequenceValues.bind(this)
    this.fetchCurrencyRates = fetchCurrencyRates('invoice[date]').bind(this)
    this.setDefault = setDefault.bind(this)

  }

  componentDidMount() {
    // eğer fatura tipi güncelleniyorsa hangi fatura türü güncelleniyor urlden type olarak gönderilmiştir.
    if (this.props.transaction_id || this.props.invoice_copy_id) {
      let type = this.props.match.params.type
      if (this.typeEnglishName[type])
        type = this.typeEnglishName[type]
      this.setState({ type })
    } else {
      this.setState({ type: 'sales' })
    }
    if (this.props.id) {
      this.getCustomerInformation(this.props.id)
    }
  }

  onSubmit = (mutate, callback) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)
      //kategori eklenmesi gerek ise ekler
      const step1 = async () => {
        if (values.invoice.category_id) {
          let category = this.state.categories.find(x => x.name === values.invoice.category_id)
          if (category) {
            values.invoice.category_id = category.category_id
            step2()
          } else {
            try {
              values.invoice.category_id = await this.createCategory(values.invoice.category_id)
              step2()
            } catch (ex) { /* empty */ }
          }
        } else {
          step2()
        }
      }

      //kayıt aşaması
      const step2 = async () => {
        // ürünler
        if (this.state.products.length) {

          let products = []
          let products_update = []
          let products_delete = []

          for (let i = 0; i < this.state.products.length; i++) {
            let product = Object.assign({}, this.state.products[i])
            if (product.delete) {
              products_delete.push(product.invoice_product_id)
            } else {
              if ( ! product.product_id || ! product.amount || ! product.quantity)
                return toast(false, this.props.langData['Incorrect products entered'])

              delete product.id
              delete product.products
              delete product.delete
              delete product.vatTotal
              delete product.total
              delete product.grand_total
              delete product.unit
              delete product.unit_id
              delete product.__typename
              if (product.name || product.name === null) {
                delete product.name
              }

              if (this.props.invoice_copy_id) {
                delete product.invoice_product_id
              }

              if (product.invoice_product_id) {
                products_update.push(product)
              } else {
                products.push(product)
              }
            }
          }

          if (products.length)
            values.invoice.products = products
          if (products_update.length)
            values.invoice.products_update = products_update
          if (products_delete.length)
            values.invoice.products_delete = products_delete

        }

        // ek ücretler
        if (this.state.additional_charges.length) {
          let additional_charges = []
          let additional_charges_update = []
          let additional_charges_delete = []

          for (let i = 0; i < this.state.additional_charges.length; i++) {
            let additional_charge = Object.assign({}, this.state.additional_charges[i])
            if (additional_charge.delete) {
              additional_charges_delete.push(additional_charge.invoice_additional_charge_id)
            } else {
              if ( ! additional_charge.additional_charge_id || ! additional_charge.amount || ! additional_charge.quantity)
                return toast(false, this.props.langData['Incorrect additional charges entered'])

              delete additional_charge.id
              delete additional_charge.additional_charges
              delete additional_charge.delete
              delete additional_charge.vatTotal
              delete additional_charge.total
              delete additional_charge.unit_id
              delete additional_charge.grand_total
              delete additional_charge.__typename

              if (this.props.invoice_copy_id) {
                delete additional_charge.invoice_additional_charge_id
              }

              if (additional_charge.invoice_additional_charge_id)
                additional_charges_update.push(additional_charge)
              else
                additional_charges.push(additional_charge)
            }
          }
          if (additional_charges.length)
            values.invoice.additional_charges = additional_charges
          if (additional_charges_update.length)
            values.invoice.additional_charges_update = additional_charges_update
          if (additional_charges_delete.length)
            values.invoice.additional_charges_delete = additional_charges_delete

        }

        values.invoice.date = moment(values.invoice.date).format('YYYY-MM-DD HH:mm')
        if (values.invoice.expiry_date)
          values.invoice.expiry_date = moment(values.invoice.expiry_date).format('YYYY-MM-DD')

        if (this.props.transaction_id) {
          if ( ! values.invoice.products && ! values.invoice.products_update && ! values.invoice.additional_charges && ! values.invoice.additional_charges_update)
            return toast(false, this.props.langData['At least one product or additional charge must be entered'])

          updateEmptyAndUndefined(values)
          values.transaction_id = this.props.transaction_id

          // varsayılan değerlere eş değer olan fieldları null yap, veritabanı bunları set_defaults ile otomatik alacak
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.invoice[x])
              values.invoice[x] = null
          }
        } else if (this.props.waybill_id) {
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.invoice[x])
              delete values.invoice[x]
          }
          updateEmptyAndUndefined(values)
          values.invoice.as_waybill = false
          values.waybill_id = this.props.waybill_id
        } else if (this.props.invoice_copy_id) {
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.invoice[x])
              delete values.invoice[x]
          }
          updateEmptyAndUndefined(values)
        } else {
          if ( ! values.invoice.products && ! values.invoice.additional_charges)
            return toast(false, this.props.langData['At least one product or additional charge must be entered'])

          // varsayılan değerlere eş değer olan fieldları sil( yani gönderme ), veritabanı bunları set_defaults ile otomatik alacak
          for (let x in this.state.defaults) {
            if (this.state.defaults[x] === values.invoice[x])
              delete values.invoice[x]
          }
        }

        if (this.state.sameAddress) {
          values.invoice.delivery_city = values.invoice.invoice_city
          values.invoice.delivery_town = values.invoice.invoice_town
          values.invoice.delivery_country = values.invoice.invoice_country
          values.invoice.delivery_address = values.invoice.invoice_address
          values.invoice.delivery_postal_code = values.invoice.invoice_postal_code
        }
        // gönderilmeyen tüm kurları null yap
        if ( ! values.invoice.currency_rates)
          values.invoice.currency_rates = {}
        getCurrenciesWithoutMatchWithCode(values.invoice.currency).forEach(x => {
          if ( ! values.invoice.currency_rates[x.code])
            values.invoice.currency_rates[x.code] = null
        })
        delete values.currency
        delete values.type


        try {
          const result = await mutate({ variables: values })
          if (result !== undefined) {
            toast(true, result.data.invoice.informative.messages)
            if (this.state.products.length) {
              let name = ''
              this.state.products.forEach(x => {
                let stockResult = result.data.invoice.invoice.products
                x.products.map(y => {
                  for (let i = 0; i < stockResult.length; i++) {
                    if (y.product_id === stockResult[i].product.product_id) {
                      if (this.state.type === 'sales' && stockResult[i].product.stock_tracking && stockResult[i].product.stock <= this.state.critical_stock_count) {
                        name += y.name + ','
                      }
                    }
                  }
                })
              })
              if (name !== '')
                pushNotification('Stock Decreased', 'The stock of named product decreased', name.slice(0, -1))
              else
                pushNotification('Your invoice has been created', 'Your invoice creation was successful')
            }
            if (callback) {
              return callback()
            }
            this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
              type: this.props.langData[this.state.type],
              id: this.props.transaction_id || this.state.transaction_id || result.data.invoice.invoice.transaction_id,
            }))
          }
        } catch (err) {
          graphQLErrorsToToast(err)
        }
      }
      step1()
    })
  }

  onSubmitAndNew = (mutate, refetch) => {
    this.onSubmit(mutate, async () => {
      this.props.form.resetFields()
      this.sequenceIntervals()
      const result = await refetch()
      document.getElementById('main-content').scrollTop = 0
      this.setState({
        ...(this.initialState),
        categories: cloneDeep(result.data.invoiceCategoryList),
      })
    })
  }

  onChangeDate = value => {
    if (value !== null) {
      this.fetchCurrencyRates()
    }
  }

  currencyOnSelect = code => {
    if (this.state.selectedCurrency !== null && this.state.selectedCurrency.code === code)
      return
    this.setState({ addedCurrency: [] })
    this.setState({ selectedCurrency: getCurrencyWithCode(code) })
    setTimeout(this.fetchCurrencyRates, 1)
    // eski seçim select te kaldığı için. ( bug )
    setTimeout(() => {
      let selectableCurrency = this.getSelectableCurrency()
      if (selectableCurrency.length)
        this.props.form.setFieldsValue({ currency: selectableCurrency[0].code })
    }, 1)
  }

  addCurrency = () => {
    let currency_code = this.props.form.getFieldValue('currency')
    let currency = getCurrencyWithCode(currency_code)
    this.setState({ addedCurrency: [...this.state.addedCurrency, currency] })

    // eski seçim select te kaldığı için. ( bug )
    setTimeout(() => {
      let selectableCurrency = this.getSelectableCurrency()
      if (selectableCurrency.length)
        this.props.form.setFieldsValue({ currency: selectableCurrency[0].code })
    }, 1)
  }

  deleteCurrency = code => {
    let addedCurrency = []
    for (let x of this.state.addedCurrency) {
      if (x.code !== code)
        addedCurrency.push(x)
    }
    this.setState({ addedCurrency })
  }

  deleteProduct = id => {
    if ( ! this.props.transaction_id) {
      this.setState({ products: this.state.products.filter(x => x.id !== id) })
    } else {
      let products = this.state.products
      if ( ! products.find(x => x.id === id).invoice_product_id) {
        this.setState({ products: this.state.products.filter(x => x.id !== id) })
      } else {
        products.find(x => x.id === id).delete = ! products.find(x => x.id === id).delete
        this.setState({ products })
      }
    }
    this.calculateFooter()
  }

  deleteAdditionalCharge = id => {
    if ( ! this.props.transaction_id) {
      this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
    } else {
      let additional_charges = this.state.additional_charges
      if ( ! additional_charges.find(x => x.id === id).invoice_additional_charge_id) {
        this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
      } else {
        additional_charges.find(x => x.id === id).delete = ! additional_charges.find(x => x.id === id).delete
        this.setState({ additional_charges })
      }
    }
    this.calculateFooter()
  }

  addProduct = () => {
    const product = {
      id: Math.floor(Math.random() * 999999) + 1,
      product_id: null,
      quantity: 1,
      amount: 0,
      vat: 0,
      vatTotal: 0,
      total: 0,
      products: [],
    }

    setTimeout(() => {
      let element = document.querySelector('#table .ant-table-body')
      if (element) {
        element.scrollTop = 999999
      }
    }, 1)

    this.setState({
      products: [...this.state.products, product],
    })

    if (this.props.sizeWidth <= 576) {
      this.setState({ modalProduct: product })
    } else {
      if (this.state.modal_status) {
        this.setState({
          modalProductSearch: product.id,
          showProductSearchAsModal: true,
        })
      }
    }
  }


  addAdditionalCharge = () => {
    const additionalCharge = {
      id: Math.floor(Math.random() * 999999) + 1,
      additional_charge_id: null,
      quantity: 1,
      amount: 0,
      vat: 0,
      vatTotal: 0,
      total: 0,
      additional_charges: [],
    }

    setTimeout(() => {
      let element = document.querySelector('#table .ant-table-body')
      if (element)
        element.scrollTop = 999999
    }, 1)

    this.setState({
      additional_charges: [...this.state.additional_charges, additionalCharge],
    })

    if (this.props.sizeWidth <= 576) {
      this.setState({ modalAdditionalCharge: additionalCharge })
    } else {
      if (this.state.modal_status) {
        this.setState({ modalAdditionalChargeSearch: additionalCharge.id })
      }
    }

  }

  editProduct = (product, name, unit, additional_charges) => {
    let products = []
    this.state.products.map(x => {
      if (x.id !== product.id)
        products.push(x)
      else
        products.push({
          ...product, products: [
            {
              product_id: product.product_id,
              name,
              unit,
            },
          ],
        })
    })

    this.setState({ modalProduct: null, products })

    let price = this.state.type === 'sales' ? 'selling_price' : 'buying_price'
    let product_additional_charges = []
    additional_charges
      .forEach(x => {
        let amount = 0
        if (this.state.selectedCurrency.code === 'try')
          amount = x[price]
        else
          amount = parseFloat((x[price] / this.state.currency.try).toFixed(4))

        product_additional_charges.push({
          id: Math.floor(Math.random() * 999999) + 1,
          additional_charge_id: x.additional_charge_id,
          quantity: 1,
          amount,
          vat: x.vat,
          vatTotal: 0,
          total: 0,
          additional_charges: [{
            additional_charge_id: x.additional_charge_id,
            name: x.additional_charge.name,
          }],
        })

      })

    this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
    product_additional_charges.forEach(x => this.calculateRow(x.id, 'additional_charge'))

    this.calculateFooter()
  }

  editAdditionalCharge = (additional_charge) => {
    let additional_charges = []
    this.state.additional_charges.map(x => {
      if (x.id !== additional_charge.id)
        additional_charges.push(x)
      else
        additional_charges.push({
          ...additional_charge, additional_charges: [
            {
              additional_charge_id: additional_charge.additional_charge_id,
              name: additional_charge.name,
              unit: additional_charge.unit,
            },
          ],
        })
    })
    this.setState({
      modalAdditionalCharge: null,
      additional_charges: additional_charges,
    })
    this.calculateFooter()
  }

  modalClose = () => {
    let products = this.state.products.filter(x => x.product_id !== null)
    let additional_charges = this.state.additional_charges.filter(x => x.additional_charge_id !== null)
    this.setState({
      products: products,
      additional_charges: additional_charges,
      modalProduct: null,
      modalAdditionalCharge: null,
    })
  }

  productValue = null

  searchProduct = async (id, value) => {
    if (this.productValue === value)
      return

    this.productValue = value

    if (value.length < 2)
      return

    let products = this.state.products
    let product = products.find(x => x.id === id)

    product.products = []
    this.setState({ fetchingProduct: true })

    try {
      const result = await apolloClient.query({ query: PRODUCT_SEARCH_QUERY, variables: { name: value } })
      product.products = result.data.productList.products
      if ( ! result.data.productList.products.length) {
        product.product_id = null
        product.name = null
      }
      this.setState({ products, fetchingProduct: false })
    } catch (err) {
      this.setState({ fetchingProduct: false })
      graphQLErrorsToToast(err)
    }

  }

  additionalChargeValue = null

  searchAdditionalCharge = async (id, value) => {
    if (this.additionalChargeValue === value)
      return

    this.additionalChargeValue = value

    if (value.length < 2)
      return

    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    additional_charge.additional_charges = []
    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      additional_charge.additional_charges = result.data.additionalChargeList.additional_charges
      if ( ! result.data.additionalChargeList.additional_charges.length) {
        additional_charge.additional_charge_id = null
        additional_charge.name = null
      }
      this.setState({ additional_charges, fetchingAdditionalCharge: false })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  calculateRow = (id, type, reverse = false) => {
    setTimeout(() => {
      let datas = this.state[type + 's']
      let data = datas.find(x => x.id === id)
      if ( ! reverse) {
        data.vatTotal = parseFloat(((((data.quantity || 0) * (data.amount || 0)) / 100) * data.vat).toFixed(4))
        data.total = parseFloat((((data.quantity || 0) * (data.amount || 0)) + data.vatTotal).toFixed(4))
      } else {
        if (data.vat) {
          let without_vat_total = parseFloat(((data.total || 0) / parseFloat(((data.vat / 100) + 1).toFixed(4))).toFixed(4))
          data.amount = parseFloat((without_vat_total / (data.quantity || 0)).toFixed(4))
          data.vatTotal = parseFloat((data.total - data.amount).toFixed(4))
        } else {
          data.vatTotal = 0
          data.amount = parseFloat((data.total / (data.quantity || 0)).toFixed(4))
        }
      }
      this.setState({
        [type + 's']: datas,
      })
      this.calculateFooter()
    }, 50)
  }

  calculateFooter = () => {
    setTimeout(() => {
      let footer = {
        interimTotal: 0,
        totalVat: 0,
        totalDiscount: 0,
        grandTotal: 0,
      }

      this.state.products.map(x => {
        if (x.delete !== true) {
          let total = x.total
          footer.totalVat += x.vatTotal
          footer.interimTotal += total - x.vatTotal
          footer.grandTotal += total
        }
      })

      this.state.additional_charges.map(x => {
        if (x.delete !== true) {
          let total = x.total
          footer.totalVat += x.vatTotal
          footer.interimTotal += total - x.vatTotal
          footer.grandTotal += total
        }
      })

      if (this.props.form.getFieldValue('invoice[discount]')) {
        if (this.props.form.getFieldValue('invoice[discount_type]') === 'Percent')
          footer.totalDiscount = (footer.grandTotal / 100) * this.props.form.getFieldValue('invoice[discount]')
        else
          footer.totalDiscount = this.props.form.getFieldValue('invoice[discount]')
      }

      footer.grandTotal -= footer.totalDiscount
      footer.grandTotal = parseFloat(footer.grandTotal.toFixed(4))

      footer = {
        interimTotal: footer.interimTotal.toFixed(4),
        totalVat: footer.totalVat.toFixed(4),
        totalDiscount: footer.totalDiscount.toFixed(4),
        grandTotal: footer.grandTotal.toFixed(4),
      }
      this.setState({ footer })

    }, 100)
  }

  rowItemClick = record => {
    this.setState({ ['modal' + ((typeof record.product_id !== 'undefined') ? 'Product' : 'AdditionalCharge')]: record })
  }

  customerSearchModalCallback = record => {
    this.setState({
      customerList: [{
        name: record.name,
        customer_id: record.customer_id,
      }],
      showCustomerSearchAsModal: false,
    })
    this.props.form.setFieldsValue({
      'invoice[customer_id]': record.customer_id,
    })
    this.getCustomerInformation(record.customer_id)
  }

  productSearchModalCallback = async record => {

    try {
      const result = await apolloClient.query({
        query: PRODUCT_READ_QUERY,
        variables: { product_id: record.product_id },
      })
      if ( ! result.data.product)
        return toast(false, this.props.langData['x not found']('Product'))

      let id = this.state.modalProductSearch
      let products = this.state.products
      let product = products.find(x => x.id === id)

      let productPure = result.data.product
      let price_currency = this.state.type === 'sales' ? 'selling_currency' : 'buying_currency'
      let price = this.state.type === 'sales' ? 'selling_price' : 'buying_price'

      if (productPure[price])
        if (productPure[price_currency] === this.state.selectedCurrency.code)
          product.amount = productPure[price]
        else
          product.amount = parseFloat(
            (productPure[price] / this.state.currency[productPure[this.state.type === 'sales' ? 'selling_currency' : 'buying_currency']]).toFixed(4),
          )

      if (productPure.vat)
        product.vat = productPure.vat

      product.product_id = record.product_id
      product.unit = record.unit
      product.products = [{
        product_id: record.product_id,
        name: productPure.name,
        name_on_invoice: productPure.name_on_invoice,
      }]

      this.setState({
        products: products,
        modalProductSearch: null,
        showProductSearchAsModal: false,
      })

      this.calculateRow(id, 'product')

      if (productPure.additional_charges.length) {
        let product_additional_charges = []
        productPure.additional_charges
          .filter(x => ! this.state.additional_charges.find(y => y.additional_charge_id === x.additional_charge_id))
          .forEach(x => {
            let amount = 0
            if (this.state.selectedCurrency.code === 'try')
              amount = x[price]
            else
              amount = parseFloat((x[price] / this.state.currency.try).toFixed(4))

            product_additional_charges.push({
              id: Math.floor(Math.random() * 999999) + 1,
              additional_charge_id: x.additional_charge_id,
              quantity: x.quantity,
              amount,
              vat: x.vat,
              vatTotal: 0,
              total: 0,
              unit: x.unit,
              additional_charges: [{
                additional_charge_id: x.additional_charge_id,
                name: x.additional_charge.name,
              }],
            })
          })

        this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
        product_additional_charges.forEach(x => this.calculateRow(x.id, 'additional_charge'))

      }

    } catch (err) {
      this.setState({
        modalProductSearch: null,
        showProductSearchAsModal: false,
      })
      graphQLErrorsToToast(err)
    }

  }

  additionalChargeSearchModalCallback = async record => {
    let id = this.state.modalAdditionalChargeSearch
    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    try {
      const result = await apolloClient.query({
        query: ADDITIONAL_CHARGE_READ_QUERY,
        variables: { additional_charge_id: record.additional_charge_id },
      })
      if ( ! result.data.additionalCharge)
        return toast(false, this.props.langData['x not found']('Additional charge'))

      let additionalChargePure = result.data.additionalCharge
      let price = this.state.type === 'sales' ? 'selling_price' : 'buying_price'
      if (additionalChargePure[price]) {
        if (this.state.selectedCurrency.code === 'try')
          additional_charge.amount = additionalChargePure[price]
        else
          additional_charge.amount = parseFloat((additionalChargePure[price] / this.state.currency.try).toFixed(4))
      }

      if (additionalChargePure.vat)
        additional_charge.vat = additionalChargePure.vat

      additional_charge.additional_charge_id = null
      // additional_charge.unit = record.unit
      additional_charge.additional_charge_id = additionalChargePure.additional_charge_id
      additional_charge.additional_charges = [{
        additional_charge_id: additionalChargePure.additional_charge_id,
        name: additionalChargePure.name,
      }]

      this.setState({ additional_charges, modalAdditionalChargeSearch: null })
      this.calculateRow(id, 'additional_charge')

    } catch (err) {
      this.setState({ modalAdditionalChargeSearch: null })
      graphQLErrorsToToast(err)
    }

  }

  getCustomerInformation = async (id) => {

    postPanelApi('/invoice/get-customer-data', {
      customer_id: id,
    }).then(result => {

      if (result) {

        if (this.props.id) {

          setTimeout(() => {
            this.setState({
              customerList: [{
                customer_id: this.props.id,
                name: result.name,
              }],
              type: this.props.type,
            })
          }, 10)

        }

        setTimeout(() => {
          this.props.form.setFieldsValue({
            'invoice[invoice_country]': result.country,
            'invoice[invoice_city]': result.city,
            'invoice[invoice_town]': result.town,
            'invoice[invoice_address]': result.address,
            'invoice[invoice_postal_code]': result.postal_code,
          })
        }, 10)

      }

    })

  }

  // seçilebilir kurları getirir
  getSelectableCurrency = () => currencyData.filter(x => (x.code !== this.state.selectedCurrency.code) && ! this.state.addedCurrency.find(y => y.code === x.code))

  render() {
    return (
      <Query
        query={this.props.waybill_id ? WAYBILL_READ_QUERY(this.props.type) :
          (this.props.transaction_id || this.props.invoice_copy_id) ? READ_QUERY(this.state.type) : READ_FOR_CREATE_QUERY}
        variables={
          this.props.transaction_id ?
            { transaction_id: Number(this.props.transaction_id) } :
            this.props.waybill_id ?
              {
                waybill_id: Number(this.props.waybill_id),
                date: moment().format('YYYY-MM-DD HH:mm'),
              } :
              this.props.invoice_copy_id ? { transaction_id: Number(this.props.invoice_copy_id) } :
                { date: moment().format('YYYY-MM-DD HH:mm') }
        }
        skip={ ! this.state.type}
        fetchPolicy="network-only"
        onError={graphQLErrorsToToast}
      >
        {({ loading: loadingQuery, data, refetch }) => {
          if ( ! loadingQuery && ! this.state.initialize) {
            // fatura düzenleme açılıyorsa ve fatura yoksa hata ver ve yönlendir
            if (this.props.transaction_id && ! data.invoice) {
              toast(false, this.props.langData['x not found']('Invoice'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else {
              let defaults = cloneDeep(data.invoiceDefaults)
              sequenceSetFirstValues(['code', 'no'], defaults)
              // varsayılan category_id var ise category_id ye category.name değerini set et
              if (defaults.category) {
                defaults.category_id = defaults.category.name
                delete defaults.category
              }
              this.setState({
                categories: cloneDeep(data.invoiceCategoryList),
                defaults,
                initialize: true,
                modal_status: data.settings.invoice_settings.modal_status,
                increment: data.userInformation.settings.increment,
                product_list: data.productList.products,
                critical_stock_count: data.settings.product_settings.critical_stock_count,
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })

              if (this.props.id) {
                setTimeout(() => {
                  this.props.form.setFieldsValue({
                    'invoice[customer_id]': this.props.id,
                    'type': this.props.type,
                  })
                }, 10)
              }

              if (this.props.transaction_id) {
                this.setState({ data: data.product, invoice: data.invoice })
                // ürünler ve ek ücretler için id belirle ve select te seçili görülebilsin diye. products ekle
                if (data.invoice.products.length) {
                  let products = cloneDeep(data.invoice.products)
                  products.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.products = [
                      {
                        product_id: x.product_id,
                        name: x.product.name,
                      },
                    ]
                    x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                    x.total = parseFloat((x.total + x.vatTotal).toFixed(4))
                    delete x.product
                  })
                  this.setState({ products })
                }

                if (data.invoice.additional_charges.length) {
                  let additional_charges = cloneDeep(data.invoice.additional_charges)
                  additional_charges.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.additional_charges = [
                      {
                        additional_charge_id: x.additional_charge_id,
                        name: x.additional_charge.name,
                      },
                    ]
                    x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                    x.total = parseFloat((x.total + x.vatTotal).toFixed(4))
                    delete x.additional_charge
                  })
                  this.setState({ additional_charges })
                }

                let invoice = data.invoice

                // kur değerlerini ayarla
                let addedCurrency = []
                for (let x in invoice.currency_rates) {
                  if (x !== invoice.currency && invoice.currency_rates[x] !== null && x !== '__typename')
                    addedCurrency.push(getCurrencyWithCode(x))
                }

                this.setState({ addedCurrency })

                setTimeout(() => {
                  this.props.form.setFieldsValue({
                    'type': this.state.type,
                    'invoice[customer_id]': invoice.customer_id,
                    'invoice[currency]': invoice.currency,
                    'invoice[code]': invoice.code,
                    'invoice[no]': invoice.no,
                    'invoice[series]': invoice.series,
                    'invoice[explanation]': invoice.explanation,
                    'invoice[date]': moment(invoice.date),
                    'invoice[discount]': invoice.discount,
                    'invoice[discount_type]': invoice.discount_type,
                    'invoice[expiry_date]': moment(invoice.expiry_date),
                    'invoice[invoice_address]': invoice.invoice_address,
                    'invoice[invoice_country]': invoice.invoice_country,
                    'invoice[invoice_postal_code]': invoice.invoice_postal_code,
                    'invoice[delivery_country]': invoice.delivery_country,
                    'invoice[invoice_city]': invoice.invoice_city,
                    'invoice[delivery_city]': invoice.delivery_city,
                    'invoice[invoice_town]': invoice.invoice_town,
                    'invoice[delivery_town]': invoice.delivery_town,
                    'invoice[delivery_address]': invoice.delivery_address,
                    'invoice[delivery_postal_code]': invoice.delivery_postal_code,
                    'invoice[currency_rates][try]': invoice.currency_rates.try,
                    'invoice[currency_rates][usd]': invoice.currency_rates.usd,
                    'invoice[currency_rates][eur]': invoice.currency_rates.eur,
                    'invoice[currency_rates][gbp]': invoice.currency_rates.gbp,
                    'invoice[currency_rates][rub]': invoice.currency_rates.rub,
                    'invoice[category_id]': invoice.category_id ? invoice.category.name : null,
                    'invoice[as_waybill]': invoice.as_waybill,
                    'invoice[order_platform_number]': invoice.order_platform_number,
                  })

                  // seçili kuru ver ve type,city,contry state e gönder
                  this.setState({
                    selectedCurrency: getCurrencyWithCode(invoice.currency),
                    invoice_country: invoice.invoice_country,
                    invoice_city: invoice.invoice_city,
                    delivery_country: invoice.delivery_country,
                    delivery_city: invoice.delivery_city,
                    customerList: [{
                      customer_id: invoice.customer_id,
                      name: invoice.customer.name,
                    }],
                    discount_type: invoice.discount_type,
                  })

                  this.calculateFooter()
                  // kurları getir
                  this.fetchCurrencyRates()

                }, 10)

              } else { // yeni fatura ise
                this.sequenceIntervals = this.refetchAndControlSequenceValues('invoice', ['no', 'code'], defaults)
                // varsayılan değerleri yükle
                let defaultDataForUpdate = {}
                Object.keys(defaults).forEach(x => {
                  if (x.indexOf('_sequence') === -1 && defaults[x] !== null)
                    defaultDataForUpdate[`invoice[${x}]`] = defaults[x]
                })
                this.props.form.setFieldsValue(defaultDataForUpdate)

                if ( ! this.props.invoice_copy_id) {
                  //Yeni kopya fatura değilse
                  // kurları ayarla, varsayılan ayarları set et
                  this.setState({
                    invoice_country: defaultDataForUpdate['invoice[invoice_country]'],
                    invoice_city: defaultDataForUpdate['invoice[invoice_city]'],
                    delivery_country: defaultDataForUpdate['invoice[delivery_country]'],
                    delivery_city: defaultDataForUpdate['invoice[delivery_city]'],
                    currency: data.currencyRates.currency_rates,
                    selectedCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate),
                  })
                }

                if (this.props.invoice_copy_id) {
                  let invoice = data.invoice
                  this.setState({ data: data.product, invoice: data.invoice })
                  // ürünler ve ek ücretler için id belirle ve select te seçili görülebilsin diye. products ekle
                  if (data.invoice.products.length) {
                    let products = cloneDeep(data.invoice.products)
                    products.forEach(x => {
                      x.id = Math.floor(Math.random() * 999999) + 1
                      x.products = [
                        {
                          product_id: x.product_id,
                          name: x.product.name,
                        },
                      ]
                      x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                      x.total = parseFloat((x.total + x.vatTotal).toFixed(4))
                      delete x.product
                    })
                    this.setState({ products })
                  }

                  if (data.invoice.additional_charges.length) {
                    let additional_charges = cloneDeep(data.invoice.additional_charges)
                    additional_charges.forEach(x => {
                      x.id = Math.floor(Math.random() * 999999) + 1
                      x.additional_charges = [
                        {
                          additional_charge_id: x.additional_charge_id,
                          name: x.additional_charge.name,
                        },
                      ]
                      x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                      x.total = parseFloat((x.total + x.vatTotal).toFixed(4))
                      delete x.additional_charge
                    })
                    this.setState({ additional_charges })
                  }

                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'invoice[customer_id]': invoice.customer_id,
                      'type': this.state.type,
                      'invoice[currency]': invoice.currency,
                      'invoice[no]': defaultDataForUpdate['invoice[no]'],
                      'invoice[series]': defaultDataForUpdate['invoice[series]'],
                      'invoice[explanation]': invoice.explanation,
                      'invoice[date]': moment(),
                      'invoice[discount]': invoice.discount,
                      'invoice[discount_type]': invoice.discount_type,
                      'invoice[expiry_date]': moment(invoice.expiry_date),
                      'invoice[invoice_address]': invoice.invoice_address,
                      'invoice[invoice_postal_code]': invoice.invoice_postal_code,
                      'invoice[invoice_country]': invoice.invoice_country,
                      'invoice[delivery_country]': invoice.delivery_country,
                      'invoice[invoice_city]': invoice.invoice_city,
                      'invoice[delivery_city]': invoice.delivery_city,
                      'invoice[invoice_town]': invoice.invoice_town,
                      'invoice[delivery_town]': invoice.delivery_town,
                      'invoice[delivery_address]': invoice.delivery_address,
                      'invoice[delivery_postal_code]': invoice.delivery_postal_code,
                      'invoice[currency_rates][try]': invoice.currency_rates.try,
                      'invoice[currency_rates][usd]': invoice.currency_rates.usd,
                      'invoice[currency_rates][eur]': invoice.currency_rates.eur,
                      'invoice[currency_rates][gbp]': invoice.currency_rates.gbp,
                      'invoice[currency_rates][rub]': invoice.currency_rates.rub,
                      'invoice[category_id]': invoice.category_id ? invoice.category.name : null,
                      'invoice[as_waybill]': invoice.as_waybill,
                      'invoice[code]': defaultDataForUpdate['invoice[code]'],
                    })

                    this.setState({
                      selectedCurrency: getCurrencyWithCode(invoice.currency),
                      invoice_country: invoice.invoice_country,
                      invoice_city: invoice.invoice_city,
                      delivery_country: invoice.delivery_country,
                      delivery_city: invoice.delivery_city,
                      customerList: [{
                        customer_id: invoice.customer_id,
                        name: invoice.customer.name,
                      }],
                      discount_type: invoice.discount_type,
                    })

                    this.calculateFooter()

                  }, 10)


                }

                if (this.props.waybill_id) {
                  let invoice = data.invoice
                  let categories = cloneDeep(data.invoiceCategoryList)
                  let defaults = cloneDeep(data.invoiceDefaults)
                  sequenceSetFirstValues(['code', 'no'], defaults)
                  // varsayılan category_id var ise category_id ye category.name değerini set et
                  if (categories.category) {
                    categories.category_id = categories.category.name
                    delete categories.category
                  }
                  if (invoice.products.length) {
                    let products = cloneDeep(invoice.products)
                    products.forEach(x => {
                      x.id = Math.floor(Math.random() * 999999) + 1
                      this.calculateRow(x.id, 'product')
                      x.products = [
                        {
                          product_id: x.product_id,
                          name: x.product.name,
                        },
                      ]
                      x.vat = x.product.vat ? x.product.vat : 0
                      x.amount = x.product.selling_price ? x.product.selling_price : 0
                      x.total = x.quantity * x.amount
                      x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))

                      delete x.product
                    })
                    this.setState({ products })
                    this.calculateFooter()
                  }

                  if (invoice.additional_charges.length) {
                    let additional_charges = cloneDeep(invoice.additional_charges)
                    additional_charges.forEach(x => {
                      x.id = Math.floor(Math.random() * 999999) + 1
                      this.calculateRow(x.id, 'additional_charge')
                      x.additional_charges = [
                        {
                          additional_charge_id: x.additional_charge_id,
                          name: x.additional_charge.name,
                        },
                      ]
                      x.vat = x.additional_charge.vat ? x.additional_charge.vat : 0
                      x.amount = x.additional_charge.selling_price ? x.additional_charge.selling_price : 0
                      x.total = x.quantity * x.amount
                      x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                      delete x.additional_charge
                    })
                    this.setState({ additional_charges })
                    this.calculateFooter()
                  }

                  this.setState({
                    customerList: [{
                      customer_id: invoice.customer_id,
                      name: invoice.customer.name,
                    }],
                    modal_status: data.settings.invoice_settings.modal_status,
                    product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
                    increment: data.userInformation.settings.increment,
                    type: this.props.type === 'incoming' ? 'purchase' : 'sales',
                    defaults,
                  })
                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'type': this.state.type === 'sending' ? 'sales' : this.state.type === 'incoming' ? 'purchase' : this.state.type,
                      'invoice[customer_id]': invoice.customer_id,
                      'invoice[delivery_address]': invoice.waybill_address,
                      'invoice[delivery_country]': invoice.waybill_country,
                      'invoice[delivery_city]': invoice.waybill_city,
                      'invoice[delivery_town]': invoice.waybill_town,
                      'invoice[delivery_postal_code]': invoice.waybill_postal_code,
                      'invoice[category_id]': invoice.invoiceCategoryList,
                    })
                  }, 10)
                }

              }
            }
          }
          return (
            <Mutation
              mutation={this.props.transaction_id ? UPDATE_QUERY(this.state.type) : CREATE_QUERY(this.state.type)}
            >
              {mutate => (
                <div>
                  <Card
                    loading={! this.props.transaction_id ? false : (loadingQuery || ! data.invoice)}
                    bodyStyle={{ padding: '0px' }}
                    title={
                      <span><Icon type={! this.props.transaction_id ? 'file-add' : 'edit'}/>
                        &nbsp;{this.props.transaction_id || this.props.match.params[1] ? this.props.langData['Update Invoice'] : this.props.langData['New Invoice']}</span>
                    } bordered={false}
                  >

                    <Form hideRequiredMark={true} className="form-label-default">

                      <div className="form-block">

                        <Form.Item
                          label={(
                            <span><IdcardOutlined/><p>{this.props.langData['Customer']}</p></span>
                          )}
                          {...formItemLayout}
                        >
                          <Input.Group compact style={{ display: 'flex' }}>
                            {this.getFieldDecorator('invoice[customer_id]', {
                              rules: [{ required: true }],
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                showSearch
                                style={{ width: '100%' }}
                                suffixIcon={<CaretDownOutlined/>}
                                notFoundContent={this.state.fetchingCustomer ? <Spin size="large"/> : null}
                                onSearch={this.searchCustomer}
                              >
                                {this.state.customerList.map((x, k) => (
                                  <Select.Option
                                    key={k}
                                    value={x.customer_id}
                                    onClick={() => this.getCustomerInformation(x.customer_id)}
                                  >
                                    {x.name}
                                  </Select.Option>
                                ))}
                              </Select>,
                            )}
                            <Button
                              size="large"
                              icon="search"
                              onClick={() => this.setState({ showCustomerSearchAsModal: true })}
                            />
                          </Input.Group>
                        </Form.Item>
                        <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Type']}</p></span>)}
                                   {...formItemLayoutHalf}
                        >
                          {this.getFieldDecorator('type', {
                            initialValue: 'sales',
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              onSelect={val => this.setState({ type: val })}
                              disabled={ !! this.props.transaction_id}
                              suffixIcon={<Icon type="caret-down"/>}
                            >
                              <Select.Option value="sales">{this.props.langData['Sales Invoice']}</Select.Option>
                              <Select.Option value="purchase">{this.props.langData['Purchase Invoice']}</Select.Option>
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>)}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[category_id]', { validateTrigger: false })(
                            <AddUpSelect
                              data={this.state.categories && this.state.categories.map(x => ({
                                key: x.category_id,
                                value: x.name,
                              }))}
                              onAddUp={value => this.createCategory(value)}
                            />,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[code]', { rules: [{ max: 20 }], validateTrigger: false })(
                            <Input size="large" maxLength={20}
                                   onBlur={item => this.setDefault('invoice[code]', 'code', item)}/>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="paper-clip"/><p>{this.props.langData['Invoice No']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          <Input.Group size="large">
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[series]', {
                                  rules: [{ max: 30 }],
                                  validateTrigger: false,
                                })(
                                  <Input
                                    placeholder={this.props.langData['Series']}
                                    onBlur={item => this.setDefault('invoice[series]', 'series', item)}
                                    maxLength={30}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[no]', {
                                  rules: [{ max: 30 }],
                                  validateTrigger: false,
                                })(
                                  <Input
                                    placeholder={this.props.langData['Queue']}
                                    onBlur={item => this.setDefault('invoice[no]', 'no', item)}
                                    maxLength={30}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          </Input.Group>

                        </Form.Item>

                        {this.state.type === 'sales' &&
                          <Form.Item hasFeedback
                                     label={<span><Icon
                                       type="number"/><p>{this.props.langData['Marketplace Order No']}</p></span>}
                                     {...formItemLayoutHalf}
                          >
                            {this.getFieldDecorator('invoice[order_platform_number]')(
                              <Input
                                size="large"
                                placeholder={this.props.langData['Marketplace Order No']}
                                maxLength={50}
                              />,
                            )}
                          </Form.Item>
                        }

                        <Form.Item label={
                          <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                        }
                                   {...formItemLayoutHalf}
                        >
                          {this.getFieldDecorator('invoice[date]', {
                            initialValue: moment(),
                            rules: [{ required: true }],
                            validateTrigger: false,
                          })(
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              showTime={{ format: 'HH:mm' }}
                              suffixIcon={<Icon type="calendar" theme="filled"/>}
                              size="large"
                              className="w-100"
                              onChange={this.onChangeDate}
                            />,
                          )}
                        </Form.Item>

                        <Form.Item label={
                          <span><Icon type="calendar"/><p>{this.props.langData['Expiry Date']}</p></span>
                        }
                                   {...formItemLayoutHalf}
                        >
                          {this.getFieldDecorator('invoice[expiry_date]', {
                            initialValue: moment(),
                            rules: [{ required: true }],
                            validateTrigger: false,
                          })(
                            <DatePicker
                              format="DD.MM.YYYY"
                              size="large"
                              suffixIcon={<Icon type="calendar" theme="filled"/>}
                              className="w-100"
                            />,
                          )}
                        </Form.Item>
                      </div>

                      {( ! this.props.waybill_id && ( ! this.props.transaction_id || (this.state.invoice && ! this.state.invoice.waybills.length))) &&
                        <div>
                          <Divider/>
                          <div className="form-block">
                            <Form.Item label={
                              <span><Icon type="car"/><p>{this.props.langData['Waybill']}</p></span>
                            }
                                       {...formItemLayout}
                            >
                              {this.getFieldDecorator('invoice[as_waybill]', {
                                initialValue: true,
                                validateTrigger: false,
                                rules: [{ required: true }],
                              })(
                                <Radio.Group name="radiogroup">
                                  <Col xs={24} sm={12} md={12} xl={12}>
                                    <Card style={{ borderRadius: '10px 0 0 10px' }}>
                                      <Radio value={true} style={{ whiteSpace: 'break-spaces' }}>
                                        <strong>
                                          {this.props.langData['Invoice With Waybilll']}
                                        </strong>
                                        <p style={{ marginTop: 10 }}>
                                          {this.props.langData['Invoice With Waybill Info']}
                                        </p>
                                      </Radio>
                                    </Card>
                                  </Col>
                                  <Col xs={24} sm={12} md={12} xl={12}>
                                    <Card style={{ borderRadius: '0 10px 10px 0' }}>
                                      <Radio value={false} style={{ whiteSpace: 'break-spaces' }}>
                                        <strong>
                                          {this.props.langData[this.state.type === 'sales' ? 'Sales Without Waybilll' : 'Purchase Without Waybill']}
                                        </strong>
                                        <p style={{ marginTop: 10 }}>
                                          {this.props.langData['Sales Without Waybill Info']}
                                        </p>
                                      </Radio>
                                    </Card>
                                  </Col>
                                </Radio.Group>,
                              )}
                            </Form.Item>
                          </div>
                        </div>}

                      <Divider/>

                      <div className="form-block">

                        <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Currency']}</p></span>)}
                                   {...formItemLayoutHalf}
                        >
                          {this.getFieldDecorator('invoice[currency]', {
                            initialValue: this.props.fixed_exchange_rate,
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              onSelect={this.currencyOnSelect}
                              suffixIcon={<Icon type="caret-down"/>}
                            >
                              {currencyData.map((x, k) => {
                                return <Select.Option value={x.code} key={k}>{x.name + ' - ' + x.symbol}</Select.Option>
                              })}
                            </Select>,
                          )}
                        </Form.Item>

                        {this.getSelectableCurrency().length > 0 && (
                          <Form.Item
                            label={<span><Icon type="plus-circle-o"/><p
                              style={{ fontWeight: 'normal' }}>{this.props.langData['Add Currency']}</p></span>}
                            {...formItemLayoutHalf}
                          >
                            <Input.Group compact style={{ display: 'flex' }}>
                              {this.getFieldDecorator('currency', {
                                initialValue: this.getSelectableCurrency()[0].code,
                                validateTrigger: false,
                              })(
                                <Select
                                  size="large"
                                  className="w-100"
                                  suffixIcon={<Icon type="caret-down"/>}
                                >
                                  {this.getSelectableCurrency().map((x, k) => (
                                    <Select.Option value={x.code} key={k}>{x.symbol}</Select.Option>
                                  ))}
                                </Select>,
                              )}
                              <Button size="large" style={{ padding: '0px 40px' }} onClick={this.addCurrency}>
                                {this.props.langData['Add']}
                              </Button>
                            </Input.Group>
                          </Form.Item>
                        )}

                        {this.state.addedCurrency.map((x, k) => {
                          return (
                            <Form.Item key={k} label={<span><Icon type="pay-circle-o"/><p>{x.symbol}</p></span>}
                                       {...formItemLayoutHalf}
                            >
                              <Input.Group compact style={{ display: 'flex' }}>
                                {this.getFieldDecorator('invoice[currency_rates][' + x.code + ']', {
                                  initialValue: this.state.currency[x.code],
                                  rules: [{ required: true }],
                                  validateTrigger: false,
                                })(
                                  <InputNumber
                                    size="large"
                                    step={this.state.increment.toFixed(4)}
                                    min={0.0001}
                                    decimalSeparator=","
                                    style={{ width: '100%', marginRight: 0 }}
                                    formatter={value => {
                                      if (value.toString() === '')
                                        return ''
                                      return value.toString().replace(x.symbol, '') + ` ${x.symbol}`
                                    }}
                                    parser={value => value.replace(' ' + x.symbol, '')}
                                  />,
                                )}
                                <Input
                                  size="large" disabled style={{ textAlign: 'center' }}
                                  value={this.state.selectedCurrency.symbol + ' / ' + x.symbol}
                                />
                                <Button size="large" icon="close" onClick={() => this.deleteCurrency(x.code)}/>
                              </Input.Group>

                            </Form.Item>
                          )
                        })}
                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Discount']}</p></span>
                        )}
                                   {...formItemLayoutHalf}
                        >
                          <Input.Group compact style={{ display: 'flex' }}>
                            {this.state.discount_type === 'Percent' && this.getFieldDecorator('invoice[discount]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                onChange={this.calculateFooter}
                                min={0} max={100}
                                step={1}
                                formatter={value => {
                                  if (value === '')
                                    return ''
                                  return `${value}%`
                                }}
                                parser={value => value.replace('%', '')}
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}

                            {this.state.discount_type === 'Value' && this.getFieldDecorator('invoice[discount]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                onChange={this.calculateFooter}
                                min={0}
                                step={this.state.increment && this.state.increment.toFixed(4)}
                                decimalSeparator=","
                                formatter={value => {
                                  if (value.toString() === '')
                                    return ''
                                  return value.toString().replace(this.state.selectedCurrency.symbol, '') + ` ${this.state.selectedCurrency.symbol}`
                                }}
                                parser={value => value.replace(' ' + this.state.selectedCurrency.symbol, '')}
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}

                            {this.getFieldDecorator('invoice[discount_type]', {
                              initialValue: 'Percent',
                              validateTrigger: false,
                            })(
                              <Select
                                style={{ width: '46%' }}
                                size="large"
                                onSelect={value => {
                                  this.setState({ discount_type: value })
                                  this.calculateFooter()
                                }}
                                suffixIcon={<Icon type="caret-down"/>}
                              >
                                <Select.Option value="Percent">{this.props.langData['Percent']}</Select.Option>
                                <Select.Option value="Value">{this.props.langData['Value']}</Select.Option>
                              </Select>,
                            )}
                          </Input.Group>

                        </Form.Item>

                      </div>

                      <Divider/>

                      <div className="form-block">

                        <Form.Item hasFeedback label={(
                          <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Country']}</p></span>)}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[invoice_country]', {
                            rules: [{ max: 100 }],
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              suffixIcon={<Icon type="caret-down"/>}
                              showSearch
                              allowClear
                              filterOption={selectFilterLowerCase}
                              onSelect={val => this.setState({ invoice_country: val })}
                              onBlur={item => this.setDefault('invoice[invoice_country]', 'invoice_country', item)}
                              maxLength={100}
                            >
                              {this.props.langData.countries.map((x, k) => {
                                return <Select.Option value={x} key={k}>{x}</Select.Option>
                              })}
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon
                            type="environment-o"/><p>{this.props.langData['Invoice City/Town'] + '/' + this.props.langData['Postal Code']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          <Input.Group size="large">
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[invoice_city]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                                    <Select
                                      size="large"
                                      className="w-100"
                                      showSearch
                                      allowClear
                                      suffixIcon={<Icon type="caret-down"/>}
                                      filterOption={selectFilterLowerCase}
                                      onChange={val => this.setState({ invoice_city: val })}
                                      onBlur={item => this.setDefault('invoice[invoice_city]', 'invoice_city', item)}
                                      maxLength={100}
                                    >
                                      {this.props.langData.cityTown.map((x, k) => {
                                        return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                                      })}
                                    </Select>
                                  ) : <Input maxLength={100}
                                             onBlur={item => this.setDefault('invoice[invoice_city]', 'invoice_city', item)}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[invoice_town]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                                    <Select
                                      size="large"
                                      className="w-100"
                                      showSearch
                                      allowClear
                                      suffixIcon={<Icon type="caret-down"/>}
                                      filterOption={selectFilterLowerCase}
                                      onBlur={item => this.setDefault('invoice[invoice_town]', 'invoice_town', item)}
                                      maxLength={100}
                                    >
                                      {
                                        this.props.langData.cityTown.filter(x => x.city === this.state.invoice_city).length && (
                                          this.props.langData.cityTown.filter(x => x.city === this.state.invoice_city)[0].towns.map((x, k) => {
                                            return <Select.Option value={x} key={k}>{x}</Select.Option>
                                          })
                                        )
                                      }
                                    </Select>
                                  ) : <Input maxLength={100}
                                             onBlur={item => this.setDefault('invoice[invoice_town]', 'invoice_town', item)}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[invoice_postal_code]')(
                                  <Input
                                    size="large"
                                    style={{ width: '100%' }}
                                    maxLength={10}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          </Input.Group>

                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Address']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[invoice_address]', {
                            rules: [{ max: 500 }],
                            validateTrigger: false,
                          })(
                            <Input.TextArea
                              size="large"
                              autoSize={{ minRows: 2, maxRows: 5 }}
                              onBlur={item => this.setDefault('invoice[invoice_address]', 'invoice_address', item)}
                              maxLength={500}
                            />,
                          )}
                        </Form.Item>
                        { ! this.props.transaction_id &&
                          <Form.Item hasFeedback>
                            <Checkbox onChange={(e) => {
                              this.setState({
                                sameAddress: e.target.checked,
                              })
                            }}>
                              {this.props.langData['The delivery address is the same as the billing address']}
                            </Checkbox>
                          </Form.Item>
                        }
                      </div>
                      <Divider/>
                      { ! this.state.sameAddress && (<div className="form-block">
                        <Form.Item hasFeedback label={(
                          <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Country']}</p></span>)}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[delivery_country]', {
                            rules: [{ max: 100 }],
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              showSearch
                              allowClear
                              suffixIcon={<Icon type="caret-down"/>}
                              filterOption={selectFilterLowerCase}
                              onSelect={val => this.setState({ delivery_country: val })}
                              onBlur={item => this.setDefault('invoice[delivery_country]', 'delivery_country', item)}
                              maxLength={100}
                            >
                              {this.props.langData.countries.map((x, k) => {
                                return <Select.Option value={x} key={k}>{x}</Select.Option>
                              })}
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon
                            type="environment-o"/><p>{this.props.langData['Delivery City/Town'] + '/' + this.props.langData['Postal Code']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          <Input.Group size="large">
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[delivery_city]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.delivery_country === 'Türkiye') ? (
                                    <Select
                                      size="large"
                                      suffixIcon={<Icon type="caret-down"/>}
                                      className="w-100"
                                      showSearch
                                      allowClear
                                      filterOption={selectFilterLowerCase}
                                      onChange={val => this.setState({ delivery_city: val })}
                                      onBlur={item => this.setDefault('invoice[delivery_city]', 'delivery_city', item)}
                                      maxLength={100}
                                    >
                                      {this.props.langData.cityTown.map((x, k) => {
                                        return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                                      })}
                                    </Select>
                                  ) : <Input maxLength={100}
                                             onBlur={item => this.setDefault('invoice[delivery_city]', 'delivery_city', item)}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[delivery_town]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.delivery_country === 'Türkiye') ? (
                                    <Select
                                      size="large"
                                      className="w-100"
                                      showSearch
                                      allowClear
                                      suffixIcon={<Icon type="caret-down"/>}
                                      filterOption={selectFilterLowerCase}
                                      onBlur={item => this.setDefault('invoice[delivery_town]', 'delivery_town', item)}
                                      maxLength={100}
                                    >
                                      {
                                        this.props.langData.cityTown.filter(x => x.city === this.state.delivery_city).length && (
                                          this.props.langData.cityTown.filter(x => x.city === this.state.delivery_city)[0].towns.map((x, k) => {
                                            return <Select.Option value={x} key={k}>{x}</Select.Option>
                                          })
                                        )
                                      }
                                    </Select>
                                  ) : <Input maxLength={100}
                                             onBlur={item => this.setDefault('invoice[delivery_town]', 'delivery_town', item)}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {this.getFieldDecorator('invoice[delivery_postal_code]')(
                                  <Input
                                    size="large"
                                    style={{ width: '100%' }}
                                    maxLength={10}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          </Input.Group>

                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Address']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {this.getFieldDecorator('invoice[delivery_address]', {
                            rules: [{ max: 500 }],
                            validateTrigger: false,
                          })(
                            <Input.TextArea
                              size="large"
                              autoSize={{ minRows: 2, maxRows: 5 }}
                              onBlur={item => this.setDefault('invoice[delivery_address]', 'delivery_address', item)}
                              maxLength={500}
                            />,
                          )}
                        </Form.Item>

                      </div>)}
                      { ! this.state.sameAddress && <Divider/>}
                      <div className="form-block">
                        <Form.Item hasFeedback {...formItemLayoutNoLabel} >
                          {this.getFieldDecorator('invoice[explanation]', {
                            rules: [{ max: 500 }],
                            validateTrigger: false,
                          })(
                            <Input.TextArea
                              size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                              placeholder={this.props.langData['Explanation']}
                              onBlur={item => this.setDefault('invoice[explanation]', 'explanation', item)}
                              maxLength={500}
                            />,
                          )}
                        </Form.Item>
                      </div>

                    </Form>
                  </Card>

                  <Card
                    bodyStyle={{ padding: 0, marginTop: 20 }}
                    bordered={false}
                  >
                    <Divider>
                      <Icon
                        type="star-o"/> {this.props.langData['Products']} / {this.props.langData['Additional Charges']}
                    </Divider>

                    <Table
                      columns={this.columns}
                      rowKey={record => record.id}
                      dataSource={this.state.products.concat(this.state.additional_charges)}
                      pagination={false}
                      id="table"
                      onRow={record => {
                        if (this.props.sizeWidth <= 576)
                          return { onClick: () => this.rowItemClick(record) }
                      }}
                      rowClassName="white"
                      scroll={{ x: 700, y: 500 }}
                    />

                    <Dropdown
                      overlay={this.menu}
                      trigger={['click']}
                      disabled={
                        this.state.products.filter(x => x.product_id === null).length ||
                        this.state.additional_charges.filter(x => x.additional_charge_id === null).length
                      }
                    >
                      <Button
                        style={{ marginLeft: 16, marginTop: 14 }}
                        icon="plus-circle-o"
                      >
                        {this.props.langData['Add']}
                      </Button>
                    </Dropdown>

                    {this.state.modalProduct && (
                      <InvoiceProduct
                        editProduct={this.editProduct}
                        product={this.state.modalProduct}
                        modalClose={this.modalClose}
                        selectedCurrency={this.state.selectedCurrency}
                        type={this.state.type}
                        currency={this.state.currency}
                      />
                    )}

                    {this.state.modalAdditionalCharge && (
                      <InvoiceAdditionalCharge
                        editAdditionalCharge={this.editAdditionalCharge}
                        additional_charge={this.state.modalAdditionalCharge}
                        modalClose={this.modalClose}
                        selectedCurrency={this.state.selectedCurrency}
                        type={this.state.type}
                        currency={this.state.currency}
                      />
                    )}

                    {this.state.showCustomerSearchAsModal && (
                      <CustomerSearchModal
                        callback={this.customerSearchModalCallback}
                        modalClose={() => this.setState({ showCustomerSearchAsModal: false })}
                      />
                    )}

                    {this.state.showProductSearchAsModal && (
                      <ProductSearchModal
                        langData={this.props.langData}
                        callback={this.productSearchModalCallback}
                        modalClose={() => this.setState({
                          modalProductSearch: false,
                          showProductSearchAsModal: false,
                        })}
                      />
                    )}

                    {this.state.modalAdditionalChargeSearch && (
                      <AdditionalChargeSearchModal
                        callback={this.additionalChargeSearchModalCallback}
                        modalClose={() => this.setState({ modalAdditionalChargeSearch: false })}
                      />
                    )}

                    <div className="form-block" style={{ textAlign: 'right', width: '100%', height: 'auto' }}>

                      <div className="fl-r">
                        <p>{this.state.footer.interimTotal.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>
                        <p>{this.state.footer.totalVat.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>
                        {this.state.footer.totalDiscount != '0.0000' &&
                          <p>{this.state.footer.totalDiscount.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>}
                        <p>
                          <strong>{this.state.footer.grandTotal.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</strong>
                        </p>
                      </div>

                      <div className="fl-r" style={{ marginRight: 26 }}>
                        <p>{this.props.langData['Interim Total']}</p>
                        <p>{this.props.langData['Total VAT']}</p>
                        {this.state.footer.totalDiscount != '0.0000' && <p>{this.props.langData['Total Discount']}</p>}
                        <p><strong>{this.props.langData['Grand Total']}</strong></p>
                      </div>

                    </div>

                    <Divider className="fl-l"/>

                    <div className="form-block">
                      <Form.Item {...formItemLayoutNoLabel}>
                        { ! this.props.transaction_id && ! this.props.waybill_id && <Button
                          loading={this.state.isSubmit}
                          size="large" className="default-button success"
                          onClick={() => this.onSubmitAndNew(mutate, refetch)}
                          style={{ float: 'right' }}
                        >
                          {this.props.langData['Save & New']}
                        </Button>}
                        <Button
                          loading={this.state.isSubmit}
                          type="primary" size="large" className="default-button"
                          style={{ marginRight: 5 }}
                          onClick={() => this.onSubmit(mutate)}
                        >
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>
                  </Card>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

InvoiceForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  history: PropTypes.object,
  fixed_exchange_rate: PropTypes.string,
  transaction_id: PropTypes.string,
  match: PropTypes.object,
  id: PropTypes.string,
  type: PropTypes.string,
  waybill_id: PropTypes.number,
  invoice_copy_id: PropTypes.number,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    fixed_exchange_rate: state.GlobalReducer.fixed_exchange_rate,
    sizeWidth: state.SizeDetectorReducer.width,
  }
})(InvoiceForm)
