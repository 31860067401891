import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { graphQLErrorsToToast } from './../../../helpers/helper'
import { Button, Modal, Form, Select, Icon } from 'antd'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { cloneDeep } from 'lodash'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const TEMPLATE_LIST_QUERY = (
  gql`
    {
      templateList(
        filter: {
          AND:[{
            default: { eq : true}
          }]
        }
      ){
        templates {
          width
          height
        }
      }
    }
  `
)

const page = [
  {
    value: 'A4',
    key: 'A4',
    width: 21,
    height: 29.7,
  },
  {
    value: 'A5',
    key: 'A5',
    width: 14.8,
    height: 21,
  },
  {
    value: 'A4 Yatay 4 Kopya',
    key: 'A4-h4',
    width: 21,
    height: 29.7,
  },
  {
    value: 'A4 Yatay 3 Kopya',
    key: 'A4-h3',
    width: 21,
    height: 29.7,
  },
  {
    value: 'A5 Yatay',
    key: 'A5-h',
    width: 14.8,
    height: 21,
  },
]

class InvoicePrintModal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    const WrappedInvoicePrintModalForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoicePrintModalForm)
    return <WrappedInvoicePrintModalForm {...this.props} onPrint={this.props.onPrint}
                                         onCancel={this.props.onCancel}></WrappedInvoicePrintModalForm>
  }
}

InvoicePrintModal.propTypes = {
  langData: PropTypes.object,
  onPrint: PropTypes.func,
  onCancel: PropTypes.func,
}

class InvoicePrintModalForm extends Component {

  state = {
    dimensions: {
      width: 21,
      height: 29.7,
    },
  }

  onVisibleOption = () => {
    return page.filter(x => (this.state.dimensions.height === x.height && this.state.dimensions.width === x.width))
  }

  render() {
    const result = this.props.form.getFieldValue('pageType')
    const { getFieldDecorator } = this.props.form
    return (
      <Query
        query={TEMPLATE_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && (JSON.stringify(data.templateList.templates[0]) !== JSON.stringify(this.state.dimensions)))
            this.setState({
              dimensions: cloneDeep(data.templateList.templates[0]),
            })
          return (
            <Modal
              title={this.props.langData['Print']}
              visible={true}
              footer={null}
              width={350}
              onCancel={this.props.onCancel}
            >
              <Form
                layout="inline"
              >
                <Form.Item hasFeedback>
                  {getFieldDecorator('pageType', {
                    initialValue: this.onVisibleOption()[0].key,
                    validateTrigger: false,
                  })(
                    <Select
                      style={{ width: 200 }}
                      suffixIcon={<Icon type="caret-down"/>}
                    >
                      {this.onVisibleOption().map(x => {
                        return <Select.Option value={x.key} key={x.key}>{x.value}</Select.Option>
                      })}
                    </Select>,
                  )}

                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => this.props.onPrint(result)}
                  >
                    {this.props.langData['Print']}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          )
        }}
      </Query>
    )
  }
}

InvoicePrintModalForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  lang: PropTypes.string,
  onPrint: PropTypes.func,
  onCancel: PropTypes.func,
}

export default connect(mapStateToProps)(InvoicePrintModal)

