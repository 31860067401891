import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Row, Col, Card, Icon, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import { confirm } from './../../../helpers/helper'
import { deleteData } from './../../../helpers/trait'
import { Route, Link, Switch as RouterSwitch } from 'react-router-dom'
import NewSequence from './../NewSequence/NewSequence'
import SequenceUpdate from './../SequenceUpdate/SequenceUpdate'
import { compile } from 'path-to-regexp'
import routePaths from './../../../localize/routePaths'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { graphQLErrorsToToast } from './../../../helpers/helper'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Sequence', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query {
      sequenceList{
        sequences{
          sequence_id
          name
          current_value
        }
      }
    }
  `
)

class Sequence extends Component {

  state = {
    data: null,
    initData: false,
  }

  constructor(props) {
    super(props)
    this.deleteData = deleteData('sequence', 'sequence_id', false).bind(this)
  }

  delete = (sequence_id, refetch) => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteData(sequence_id)
      refetch()
    })
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            this.props.breadcrumb_add('/' + this.props.langData.route['sequence'])
            if ( ! this.state.data) {
              this.setState({
                data: data.sequenceList.sequences,
              })
            } else if (JSON.stringify(this.state.data) !== JSON.stringify(data.sequenceList.sequences) && this.state.data) {
              this.setState({
                data: data.sequenceList.sequences,
              })
            }
          }
          return (
            <div>
              <Row gutter={48}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginBottom: 16 }}>
                  <Card
                    loading={ ! this.state.data}
                    bodyStyle={{ padding: '0px' }}
                    title={<span><Icon type="bars"/> &nbsp;{this.props.langData['Sequences']}</span>}
                    bordered={false}
                    extra={
                      <Link to={'/' + this.props.langData.route['sequence/new']}>
                        <Button icon="file-add">{this.props.langData['Add']}</Button>
                      </Link>
                    }
                  >
                    <List dataSource={this.state.data}
                          renderItem={item => (
                            <List.Item key={item.sequence_id} actions={[
                              <Link key={item.sequence_id} to={
                                '/' + compile(this.props.langData.route['sequence/update/:id'])({ id: item.sequence_id })
                              }>
                                <Icon type="edit" style={{ fontSize: '16px' }}/>
                              </Link>,
                              <Icon key={item.sequence_id} type="delete" style={{ fontSize: '15px' }}
                                    onClick={() => this.delete(item.sequence_id, refetch)}/>,
                            ]}>
                              <List.Item.Meta
                                description={
                                  <div style={{ padding: '0px 20px' }}>{item.name} - {item.current_value}</div>
                                }
                              />
                            </List.Item>
                          )}
                    >
                    </List>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                  <RouterSwitch>
                    <Route path={routePaths['sequence/new']} render={props => {
                      const newSequence = <NewSequence {...props} refetch={refetch}/>
                      if (this.props.sizeWidth <= 576)
                        return (
                          <Modal
                            title={<span><Icon type="file-add"/> &nbsp;{this.props.langData['New Sequence']}</span>}
                            bodyStyle={{ padding: 8 }}
                            visible={true}
                            footer={null}
                            onCancel={() => {
                              props.history.push('/' + this.props.langData.route['sequence'])
                            }}
                          >
                            {newSequence}
                          </Modal>
                        )
                      return newSequence
                    }}/>

                    <Route exact path={routePaths['sequence/update/:id']} render={(props) => {
                      const sequenceUpdate = <SequenceUpdate {...props} refetch={refetch}
                                                             sequence_id={Number(props.match.params.id)}/>
                      if (this.props.sizeWidth <= 576)
                        return (
                          <Modal
                            title={<span><Icon type="edit"/> &nbsp;{this.props.langData['Update Sequence']}</span>}
                            bodyStyle={{ padding: 8 }}
                            visible={true}
                            footer={null}
                            onCancel={() => {
                              props.history.push('/' + compile(this.props.langData.route['sequence']))
                            }}
                          >
                            {sequenceUpdate}
                          </Modal>
                        )
                      return sequenceUpdate
                    }}/>

                  </RouterSwitch>

                </Col>
              </Row>
            </div>
          )
        }}
      </Query>
    )
  }

}

Sequence.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  sizeWidth: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sequence)
