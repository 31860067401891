import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast, formErrorFieldsNameLocalize, graphQLErrorsToToast } from './../../../helpers/helper'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Card, Divider, Select, InputNumber, Checkbox } from 'antd'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const READ_QUERY = (
  gql`
    query($sequence_id:Int!){
      sequence(sequence_id:$sequence_id) {
        sequence_id
        name
        type
        pattern
        start
        end
        step
        current_value
        next_value
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation ($sequence: SequenceCreateInput!) {
      sequence: sequenceCreate(sequence: $sequence) {
        informative {
          messages
        }
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation ($sequence_id: Int!, $sequence: SequenceUpdateInput!) {
      sequence: sequenceUpdate(sequence_id:$sequence_id,sequence:$sequence) {
        informative {
          messages
        }
      }
    }
  `
)

class SequenceForm extends Component {

  constructor(props) {
    super(props)
    if (this.props.sequence_id) {
      this.state = {
        initialize: false,
        start: null,
        end: null,
        current_value: null,
        pattern: '',
        show_all_columns: null,
      }
    } else {
      this.state = {
        initialize: true,
        start: 0,
        end: 99999,
        current_value: 0,
        pattern: '{$}',
        show_all_columns: false,
      }
    }
  }

  onSubmit = mutate => {
    this.props.form.validateFields(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      let variables = {
        sequence: values,
      }

      if (this.props.sequence_id) {
        variables.sequence_id = this.props.sequence_id
        delete variables.sequence.type
      }

      try {
        const result = await mutate({ variables })
        if (result !== undefined) {
          toast(true, result.data.sequence.informative.messages)
          this.props.refetch()
          if ( ! this.props.sequence_id) {
            this.props.form.resetFields()
          }
        }
      } catch (err) {
        graphQLErrorsToToast(err)
      }
    })
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 11 },
        lg: { span: 11 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 13 },
        lg: { span: 13 },
      },
    }

    const { getFieldDecorator } = this.props.form

    let form = (mutate, loadingMutation) => (
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit(mutate)
        }}
        className="form-label-default"
      >

        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('name', { rules: [{ max: 20 }, { required: true }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Type']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('type', { rules: [{ max: 20 }], initialValue: 'Number', validateTrigger: false })(
              <Select
                disabled={this.props.sequence_id ? true : false}
                size="large"
                suffixIcon={<Icon type="caret-down"/>}
              >
                <Option value="Number">{this.props.langData['Number']}</Option>
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="retweet"/><p>{this.props.langData['Step']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('step', { rules: [{ required: true }], initialValue: 1, validateTrigger: false })(
              <InputNumber size="large" min={1} max={9999} className="w-100"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="flag"/><p>{this.props.langData['Start']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('start', { rules: [{ required: true }], initialValue: 0, validateTrigger: false })(
              <InputNumber size="large" max={this.state.end} className="w-100"
                           onChange={val => this.setState({ start: val })}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="pause"/><p>{this.props.langData['End']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('end', { rules: [{ required: true }], initialValue: 99999, validateTrigger: false })(
              <InputNumber size="large" min={this.state.start} max={Number.MAX_SAFE_INTEGER} className="w-100"
                           onChange={val => this.setState({ end: val })}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="check-circle-o"/><p>{this.props.langData['Current Value']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('current_value', {
              rules: [{ required: true }],
              initialValue: 0,
              validateTrigger: false,
            })(
              <InputNumber size="large" min={this.state.start} max={this.state.end} className="w-100"
                           onChange={val => this.setState({ current_value: val })}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="appstore-o"/><p>{this.props.langData['Pattern']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('pattern', {
              rules: [{ max: 20 }, { required: true }],
              initialValue: '{$}',
              validateTrigger: false,
            })(
              <Input size="large" type="text" onChange={item => this.setState({ pattern: item.target.value })}
                     onBlur={item => {
                       if (item.target.value.indexOf('{$}') === -1) {
                         let pattern = this.state.pattern.length > 17 ? this.state.pattern.substr(0, 17) : this.state.pattern
                         this.props.form.setFieldsValue({ pattern: pattern + '{$}' })
                         this.setState({ pattern: pattern + '{$}' })
                       }
                     }}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="ellipsis"/><p>{this.props.langData['Show All Columns']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('show_all_columns', {
              valuePropName: 'checked',
              initialValue: false,
              validateTrigger: false,
            })(
              <Checkbox
                onChange={item => this.setState({ show_all_columns: item.target.checked })}
              >
                {this.props.langData[this.state.show_all_columns ? 'Yes' : 'No']}
              </Checkbox>,
            )}
          </Form.Item>

          <Form.Item style={{ background: '#f8f8f8', textAlign: 'center', padding: 14 }}>
            {
              (this.state.pattern && typeof this.state.current_value !== 'undefined' && this.state.end) &&
              (this.state.pattern.replace(
                /\{\$\}/g,
                this.state.show_all_columns ?
                  this.state.current_value.toLocaleString('tr-TR', {
                    minimumIntegerDigits: this.state.end.toString().length,
                    useGrouping: false,
                  }) : this.state.current_value,
              ))
            }
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item>
            <Button type="primary" size="large" loading={loadingMutation} htmlType="submit" className="default-button">
              {this.props.langData['Save']}
            </Button>
            <Link to={'/' + this.props.langData.route['sequence']}>
              <Button size="large" style={{ marginRight: 8 }} loading={this.state.isSubmit}
                      className="default-button danger2">
                {this.props.langData['Close']}
              </Button>
            </Link>
          </Form.Item>
        </div>

      </Form>
    )

    return (
      <Query
        query={READ_QUERY}
        skip={ ! this.props.sequence_id}
        fetchPolicy="network-only"
        variables={{ sequence_id: this.props.sequence_id }}
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            if (this.props.sequence_id && ! data.sequence) {
              toast(false, this.props.langData['x not found']('Sequence'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.initialize) {
              if (this.props.sequence_id) {
                this.props.form.setFieldsValue(cloneDeep(data.sequence))
              }

              this.setState({
                current_value: data.sequence.current_value,
                start: data.sequence.start,
                end: data.sequence.end,
                show_all_columns: data.sequence.show_all_columns,
                pattern: data.sequence.pattern,
                initialize: true,
              })
            }
          }
          return (
            <Mutation
              mutation={! this.props.sequence_id ? CREATE_QUERY : UPDATE_QUERY}
            >
              {(mutate, { loading: loadingMutation }) => {
                if (this.props.sizeWidth > 576)
                  return (
                    <Card loading={ ! this.state.initialize} bodyStyle={{ padding: '0px' }} title={
                      <span><Icon type={! this.props.sequence_id ? 'file-add' : 'edit'}/> &nbsp;{
                        ! this.props.sequence_id ? this.props.langData['New Sequence'] : this.props.langData['Update Sequence']
                      }</span>
                    }
                          bordered={false}
                          extra={
                            <Link to={'/' + this.props.langData.route['sequence']}>
                              <Icon className="cursor" type="close"/>
                            </Link>
                          }
                    >
                      {form(mutate, loadingMutation)}
                    </Card>
                  )
                return form(mutate, loadingMutation)
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

SequenceForm.propTypes = {
  sequence_id: PropTypes.number,
  langData: PropTypes.object,
  type: PropTypes.string,
  form: PropTypes.object,
  refetch: PropTypes.func,
  sizeWidth: PropTypes.number,
  history: PropTypes.object,
}

export default connect(mapStateToProps)(SequenceForm)
