import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Card, Icon, Button, Switch } from 'antd'

import {
  updateEmptyAndUndefined,
  formErrorFieldsNameLocalize,
  successToast,
} from '../../../../helpers/helper'
import { postPanelApi } from '../../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

function TSoftWebServiceSettingsForm(props) {

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {

    postPanelApi('/settings/tsoft/open').then(result => {

      if (result) {

        props.form.setFieldsValue(result.form)

      }

    })

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll((err, values) => {

      if (err) {
        formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      setSubmitting(true)

      postPanelApi('/settings/tsoft/submit', values).then(result => {

        if (result) {

          successToast(result.message)

        }

        setSubmitting(false)

      })

    })
  }

  const { getFieldDecorator } = props.form

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><Icon
        type="control"/> &nbsp;{props.langData['T-Soft Web Service Settings']}</span>}
      bordered={false}
    >

      <Form
        hideRequiredMark={true}
        className="form-label-default"
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
      >

        <div className="form-block">

          <Form.Item label={(
            <span><Icon type="book"/><p>{props.langData['Store URI']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('store_uri', {
              rules: [{ max: 100 }], validateTrigger: false,
            })(
              <Input size="large" maxLength={100}/>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="user"/><p>{props.langData['Username']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('ws_username', {
              rules: [{ max: 100 }], validateTrigger: false,
            })(
              <Input size="large" maxLength={100}/>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="lock"/><p>{props.langData['Password']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('ws_password', {
              rules: [{ max: 100 }], validateTrigger: false,
            })(
              <Input.Password size="large" maxLength={100} type="password"/>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="key"/><p>Secret Key</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('tsoft_hook_token', {
              rules: [{ max: 100 }], validateTrigger: false,
            })(
              <Input size="large" maxLength={100}/>,
            )}
          </Form.Item>

          <Form.Item
            help={props.langData['Describe of where to get product informations'](props.form.getFieldValue('is_getdata_tsoft') ? 'T-Soft' : 'JetOfis')}
            label={(
              <span><Icon
                type="share-alt"/><p>{props.langData['Where to get product informations']}</p></span>
            )}
            {...formItemLayout}
          >
            {getFieldDecorator('is_getdata_tsoft', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren="T-Soft"
                unCheckedChildren="JetOfis"
              />,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" loading={submitting} size="large" htmlType="submit"
                    className="default-button">
              {props.langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  )

}

TSoftWebServiceSettingsForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
}

export default TSoftWebServiceSettingsForm
