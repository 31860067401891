import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Modal } from 'antd'
import { IdcardOutlined } from '@ant-design/icons'

import CustomerList from './../CustomerList/CustomerList'
import NewCustomerModal from './../NewCustomerModal/NewCustomerModal'
import NewIncomingEInvoiceCustomer from './../NewIncomingEInvoiceCustomer/NewIncomingEInvoiceCustomer'

class CustomerSearchModal extends Component {

  state = {
    refetch: false,
    formModal: false,
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          title={
            <React.Fragment>
              <span><IdcardOutlined/> &nbsp;{this.props.langData['Customer Search']}</span>
              <Button
                key="new"
                type="primary"
                style={{ float: 'right' }}
                onClick={() => this.setState({ formModal: true })}
              >
                {this.props.langData['Add New One']}
              </Button>
            </React.Fragment>
          }
          bodyStyle={{ padding: 8 }}
          visible={true}
          closable={false}
          width={this.props.sizeWidth > 576 ? '75%' : '100%'}
          onCancel={this.props.modalClose}
          footer={[
            <Button
              key="close"
              size="large"
              onClick={this.props.modalClose} style={{ marginRight: 8 }}
              className="danger2"
            >
              {this.props.langData['Close']}
            </Button>,
          ]}
        >
          <CustomerList
            noBreadCrumb={true}
            scrollHeight={200}
            rowItemClick={this.props.callback}
            modalClose={this.props.modalClose}
            refetch={this.state.refetch}
          />
        </Modal>
        {this.state.formModal && (
          this.props.incomingEInvoiceData ?
            <NewIncomingEInvoiceCustomer
              incomingEInvoiceData={this.props.incomingEInvoiceData}
              modalClose={(status) => {
                this.setState({
                  formModal: false,
                  refetch: status ? true : false,
                })
              }}
            />
            :
            <NewCustomerModal
              modalClose={(status) => {
                this.setState({
                  formModal: false,
                  refetch: status ? true : false,
                })
              }}
            />
        )}
      </React.Fragment>
    )
  }
}

CustomerSearchModal.propTypes = {
  langData: PropTypes.object,
  modalClose: PropTypes.func,
  callback: PropTypes.func,
  sizeWidth: PropTypes.number,
  incomingEInvoiceData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
})(CustomerSearchModal)
