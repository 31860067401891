import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'
import WaybillForm from './../WaybillForm/WaybillForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Waybill' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}


class NewWaybill extends Component {
  type
  transaction_id

  typeEnglishName = {
    'gelen': 'incoming',
    'giden': 'sending',
  }

  constructor(props) {
    super(props)
    this.transaction_id = parseInt(props.match.params.id)
    this.type = props.match.params.type
    if (this.typeEnglishName[this.type])
      this.type = this.typeEnglishName[this.type]
  }

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['waybill/new'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedNewWaybillForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(WaybillForm)
    return <WrappedNewWaybillForm type={this.type} {...this.props}
                                  transaction_id={this.transaction_id}></WrappedNewWaybillForm>
  }
}

NewWaybill.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewWaybill)
