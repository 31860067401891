import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Result, Button, Card, Row, Col, Divider, Spin } from 'antd'

import { postGuestApi } from '../../../api'

function ManagerEmailVerification(props) {

  const [checking, setChecking] = useState(false)
  const [completed, setCompleted] = useState(false)

  useEffect(() => {

    // kullanıcının üye girişi yaptıktan sonra maildeki onay
    localStorage.removeItem('token')

    if ( ! props.match.params.token || props.match.params.token.length !== 36) {
      return setTimeout(() => props.history.push('/'), 1000)
    }

    setChecking(true)

    postGuestApi('/auth/email-verification-check', { token: props.match.params.token }).then(result => {

      if (result) {

        setCompleted(true)

      } else {

        setCompleted(false)

      }

      setChecking(false)

    })

  }, [])

  return (
    <div className="h-100">
      <Row type="flex" align="middle" className="h-100">
        <Col xs={1} sm={6} md={7} lg={9} xl={6}/>
        <Col xs={22} sm={12} md={10} lg={6} xl={12}>
          <Card
            style={{
              textAlign: 'center',
              boxShadow: '0 0 100px rgba(0,0,0,.08)',
              background: 'transparent',
              padding: 20,
            }}
            bordered={false}
          >
            <img
              src="https://app.jetofis.com/logo.svg"
              alt="Jetofis"
              style={{ width: '100%', maxWidth: 400 }}
            />
            {checking ? <Spin/> : (
              <Result
                title={completed ? props.langData['Email verification completed successfully'] : props.langData['Token is invalid']}
                status={completed ? 'success' : 'error'}
                extra={
                  <Button type="primary" key="login"
                          onClick={() => setTimeout(() => props.history.push('/'), 1000)}>
                    {props.langData['Login']}
                  </Button>
                }
              />
            )}

            <Divider>JETOFİS</Divider>
            <Card.Meta
              title="Bulut Tabanlı Ticari Yazılım"
              description="www.jetofis.com"
            />
          </Card>
        </Col>
        <Col xs={1} sm={6} md={7} lg={9} xl={6}/>
      </Row>
    </div>
  )

}

ManagerEmailVerification.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
})(ManagerEmailVerification)
