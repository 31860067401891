import { Form } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import routes from '../../../routes'

import InvoiceForm from './../InvoiceForm/InvoiceForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewInvoice extends Component {

  id
  type

  typeEnglishName = {
    'satis': 'sales',
    'alis': 'purchase',
  }

  constructor(props) {
    super(props)
    this.id = parseInt(this.props.match.params.id)
    this.type = this.props.match.params.type
    if (this.typeEnglishName[this.type]) {
      this.type = this.typeEnglishName[this.type]
    }
  }

  componentDidMount() {
    this.props.breadcrumb_add(routes.INVOICE_NEW)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
    this.id = null
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedInvoiceForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceForm)
    return <WrappedInvoiceForm {...this.props} />
  }

}

NewInvoice.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice)
