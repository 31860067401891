import axios from 'axios'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import * as Json2csv from 'json2csv'
import OneSignal from 'react-onesignal'
import { message, Modal } from 'antd'

import Store from './../reducers/Store'

const successToast = (msg, second = 3) => {
  message.success(msg, second)
}

const errorToast = (msg, second = 3) => {
  message.error(msg, second)
}

const toast = (type, msg = null, second = 3) => {
  if (msg === null) {
    message[type.success ? 'success' : 'error'](
      (typeof type.message === 'string') ? type.message : type.message.join('. '),
      type.second || second,
    )
  } else {
    message[type ? 'success' : 'error'](msg, second)
  }
}

const confirm = (title = 'Are you sure you want to continue', callback) => {
  Modal.confirm({
    title: reducerData().langData[title] + '?',
    okText: reducerData().langData['Yes'],
    okType: 'danger',
    cancelText: reducerData().langData['No'],
    onOk: () => callback(true),
    onCancel: () => callback(false),
  })
}

const confir2m = (title = 'Are you sure you want to continue', callback) => {
  Modal.confirm({
    title: title,
    okText: reducerData().langData['Yes'],
    okType: 'danger',
    cancelText: reducerData().langData['No'],
    onOk: () => callback(true),
    onCancel: () => callback(false),
  })
}

const selectFilterLowerCase = (input, option) => {
  return option.props.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
}

const deleteEmpty = data => {
  for (let x in data) {
    if (typeof data[x] === 'undefined' || data[x] === '' || data[x] === null) {
      delete data[x]
    }
  }
}

// null olan değerleri siler
const deleteNull = data => {
  let clone = cloneDeep(data)
  for (let x in clone) {
    if (clone[x] === null)
      delete clone[x]
  }
  return clone
}

const updateEmptyAndUndefined = data => {
  for (let x in data) {
    if (typeof data[x] === 'undefined' || data[x] === '') {
      data[x] = null
    } else if (typeof data[x] === 'object') {
      updateEmptyAndUndefined(data[x])
    }
  }
}

const toLocaleString = value => {
  if (value.toString().indexOf('e') !== -1) {
    return Number(value).toLocaleString(reducerData().lang + '-' + (reducerData().lang === 'tr' ? 'TR' : 'US'), {
      minimumFractionDigits: 4,
    })
  } else {
    return Number(value.toString().indexOf('.') !== -1 ? value.toString().substring(0, value.toString().indexOf('.') + 5) : value.toString() + '.0000').toLocaleString(reducerData().lang + '-' + (reducerData().lang === 'tr' ? 'TR' : 'US'), {
      minimumFractionDigits: 4,
    })
  }
}

const graphQLErrorsToToast = err => {
  let messages = err.graphQLErrors ? err.graphQLErrors.map(x => x.message) : ''
  toast(false, messages.length ? messages : reducerData().langData['Server error'])
}

function reducerData() {
  return {
    langData: Store.getState().LocalizeReducer.langData,
    lang: Store.getState().LocalizeReducer.lang,
  }
}

/*
	TCMB İçin Müsait Tarihi Getirir
*/
function getAvailableTCMB(date) {
  date = moment(date)
  let now = moment()
  // eğer büyük bir tarih verilmiş ise bu tarihe al
  let daysDiff = moment([now.year(), now.month() + 1, now.date()]).diff(moment([date.year(), date.month() + 1, date.date()]), 'days')
  if (daysDiff < 0)
    date = now

  daysDiff = moment([now.year(), now.month() + 1, now.date()]).diff(moment([date.year(), date.month() + 1, date.date()]), 'days')
  // eğer bügünün tarihi isteniyorsa ve saat 15:30 dan önce ise bir gün çıkar. Karşıdan kur hazır değil mesajı gelmesin diye
  if (daysDiff === 0) {
    if (now.hour() < 15 || (now.hour() === 15 && now.minute() <= 30))
      date = date.add('days', -1)
  }

  // eğer yılın ilk günü isteniyorsa ve yılın ilk günü hafta için ise tcmb kur vermediği için bir geri al
  if (date.month() === 0 && date.date() === 1 && date.weekday() <= 4)
    date = date.add('days', -1)

  // eğer 1 mayıs isteniyor ise tcmb kur vermediği için bir geri al
  if (date.month() === 4 && date.date() === 1)
    date = date.add('days', -1)

  // eğer tarih haftasonu ise haftanın son çalışma gününe çek
  if (date.weekday() > 4)
    date = date.add('days', 4 - date.weekday())

  return date
}

const formErrorFieldsNameLocalize = err => {
  Object.keys(err).forEach(x => {
    if ( ! err[x].errors)
      return formErrorFieldsNameLocalize(err[x])
    err[x].errors.forEach(y => {
      y.message = y.message.replace(y.field, reducerData().langData.formFields[y.field])
    })
  })
}

const createCsv = async function () {
  try {
    let fields = this.getVisibleColumnsFieldName()
    fields = fields.map(field => {
      if (field === 'customer' || field === 'category') {
        field = `${field}.name`
      }
      return field
    })
    const csv = await Json2csv.parse(this.state.data, { fields, withBOM: true })
    var blob = new Blob([csv], { type: 'text/csv;' })
    var url = URL.createObjectURL(blob)
    var link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'file.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (e) {
    console.log(e)
  }

}

const pushNotification = async (headings, contents, additionalContent) => {

  const playerId = await OneSignal.getPlayerId()

  const data = {
    app_id: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
    headings: { 'en': reducerData().langData[headings] },
    contents: { 'en': additionalContent ? reducerData().langData[contents](additionalContent) : reducerData().langData[contents] },
    include_player_ids: [playerId],
  }

  axios.post('https://onesignal.com/api/v1/notifications', data, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_ONE_SIGNAL_API_KEY,
    },
  }).catch(error => {
    console.log(error)
  })

}

const extractExcel = function (payload) {
  let url = null
  if (payload.type === 'customer')
    url = `${process.env.REACT_APP_IMPORT_EXPORT_URL}/customer/list/excel/${localStorage.token}/${payload.starting}/${payload.total}`
  else
    url = `${process.env.REACT_APP_IMPORT_EXPORT_URL}/invoice/list/withoutproduct/excel/${localStorage.token}/${payload.starting}/${payload.total}`

  let link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', 'customers.xlsx')
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  this.setState({ isExtractExcel: false })
}

Store.subscribe(reducerData)

export {
  successToast,
  errorToast,
  toast,
  confirm,
  confir2m,
  selectFilterLowerCase,
  deleteEmpty,
  deleteNull,
  updateEmptyAndUndefined,
  getAvailableTCMB,
  toLocaleString,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
  createCsv,
  pushNotification,
  extractExcel,
}
