import React, { Component } from 'react'
import { Query, Mutation } from '@apollo/client/react/components'
import {
  graphQLErrorsToToast,
  toast,
  deleteNull,
  formErrorFieldsNameLocalize,
  toLocaleString,
  confirm,
} from '../../../../helpers/helper'
import PropTypes from 'prop-types'
import {
  Card,
  Icon,
  Form,
  Input,
  DatePicker,
  Divider,
  Table,
  InputNumber,
  Button,
  Col,
  Modal,
  Row,
  Progress,
} from 'antd'
import { getCurrencyWithCode } from '../../../../data/Currency'
import { fetchCurrencyRates } from '../../../../helpers/trait'
import { gql } from '@apollo/client'

import { connect } from 'react-redux'
import { cloneDeep, capitalize } from 'lodash'
import moment from 'moment'
import apolloClient from './../../../../helpers/apolloClient'
import NewReturnPayment from './../../InvoiceDetail/NewReturnPayment/NewReturnPayment'
import { Route, Link, generatePath } from 'react-router-dom'
import { getCurrenciesWithoutMatchWithCode } from '../../../../data/Currency'
import routes from '../../../../routes'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 6 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

const READ_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      invoice: ${type}Invoice(transaction_id: $transaction_id) {
      customer_id
      customer {
      name
      balance{
      try
      usd
      eur
      gbp
      rub
      }
      }
      currency
      currency_rates {
      try
      usd
      eur
      gbp
      rub
      }
      code
      no
      series
      category_id
      category {
      name
      }
      return_invoices {
      transaction_id
      invoice_id
      customer_id
      currency
      code
      no
      series
      category_id
      total
      discount
      discount_type
      total_vat
      grand_total
      paid
      remaining
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      products {
      invoice_product_id
      product_id
      quantity
      amount
      total
      vat
      vat_total
      grand_total
      }
      additional_charges {
      invoice_additional_charge_id
      additional_charge_id
      quantity
      amount
      total
      vat
      grand_total
      }
      }
      discount
      remaining
      discount_type
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      products {
      product_id
      quantity
      amount
      total
      vat
      grand_total
      product {
      name
      }
      }
      additional_charges {
      additional_charge_id
      quantity
      amount
      total
      vat
      grand_total
      additional_charge {
      name
      }
      }
      }

      userInformation {
      settings {
      increment
      }
      }
      }
  `
)

const CREATE_INVOICE_QUERY = type => (
  gql`
      mutation return${capitalize(type)}InvoiceCreate($invoice_id: Int!, $return_${type}_invoice: InvoiceCreateInput!){
      return${capitalize(type)}InvoiceCreate(invoice_id: $invoice_id, return_${type}_invoice: $return_${type}_invoice){
      informative{
      messages
      }
      return_${type}_invoice {
      transaction_id
      invoice_id
      customer_id
      currency
      code
      no
      series
      category_id
      total
      discount
      discount_type
      total_vat
      grand_total
      remaining
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      }
      }
      }
  `
)

const RETURN_INVOICE_DETAIL_QUERY = type => (
  gql`
      query($transaction_id: Int!){
          invoice: return${capitalize(type)}Invoice(transaction_id:$transaction_id) {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
              customer {
                  name
                  balance{
                      try
                      usd
                      eur
                      gbp
                      rub
                  }
              }
              products {
                  invoice_product_id
                  product_id
                  quantity
                  amount
                  total
                  vat
                  grand_total
                  product{
                      name
                  }
              }
              additional_charges {
                  invoice_additional_charge_id
                  additional_charge_id
                  quantity
                  amount
                  total
                  vat
                  grand_total
                  additional_charge{
                      name
                  }
              }
              commits {
                  paid_transaction_id
                  invoice_transaction_id
                  currency_amounts {
                      try
                      usd
                      eur
                      gbp
                      rub
                  }
                  paid {
                      __typename
                      ... on ${type === 'sales' ? 'Credit' : 'Debit'} {
          transaction_id
          amount
          safe {
              name
              currency
          }
          explanation
      }
      ... on AccountOpeningCustomer {
      transaction_id
      amount
      customer_id
      currency
      }
      }
      }
      currency_rates{
      try
      usd
      eur
      gbp
      rub
      }
      }
      userInformation {
      settings {
      increment
      }
      }
      }
  `
)

const RETURN_INVOICE_UPDATE_QUERY = type => (
  gql`
      mutation($transaction_id: Int! $return_${type}_invoice: InvoiceUpdateInput!){
      return${capitalize(type)}InvoiceUpdate(transaction_id:$transaction_id, return_${type}_invoice:$return_${type}_invoice ){
      return_${type}_invoice {
      transaction_id
      invoice_id
      customer_id
      currency
      code
      no
      series
      category_id
      total
      discount
      discount_type
      total_vat
      grand_total
      paid
      remaining
      explanation
      date
      expiry_date
      invoice_country
      invoice_city
      invoice_town
      invoice_address
      delivery_country
      delivery_city
      delivery_town
      delivery_address
      }
      informative{
      messages
      }
      }
      }
  `
)

const RETURN_INVOICE_DELETE_QUERY = type => (
  gql`
      mutation ($transaction_id: Int!) {
          return${capitalize(type)}InvoiceDelete(transaction_id: $transaction_id) {
              transaction_id
              informative {
                  messages
              }
          }
      }
  `
)

class InvoiceReturnForm extends Component {

  type
  transaction_id

  constructor(props) {
    super(props)
    this.fetchCurrencyRates = fetchCurrencyRates('invoice[date]').bind(this)
    this.type = props.type.includes('Detail') ? props.type.replace('Detail', '') : null
    this.transaction_id = props.match.params.id
  }

  initialState = {
    invoice: null,
    initialize: false,
    products: [],
    additional_charges: [],
    increment: null,
    selectedCurrency: {},
    footer: {
      interimTotal: 0,
      totalVat: 0,
      totalDiscount: 0,
      grandTotal: 0,
    },
    isSubmit: false,
    remaining: null,
    balance: null,
    return_invoices: [],
    status: false,
    toggle: false,
    isToday: null,
    dateDiff: null,
  }

  state = this.initialState

  commitColumns = [
    {
      title: this.props.langData['Safe'],
      dataIndex: 'paid',
      width: '20%',
      render: value => value.safe ? value.safe.name : '-',
    },
    {
      title: this.props.langData['Explanation'],
      dataIndex: 'paid.explanation',
      width: '35%',
      render: value => value ? value : '-',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'paid.amount',
      width: '20%',
      className: 'text-right',
      render: (value, record) => {
        return `${toLocaleString(value) + ' ' + (record.paid.safe ? getCurrencyWithCode(record.paid.safe.currency).symbol : getCurrencyWithCode(record.paid.currency).symbol)}`
      },
    },
    {
      title: this.props.langData['Amount processed'],
      className: 'text-right',
      width: '25%',
      render: (value, record) => {
        return (
          <div>
            <div>{toLocaleString(record.currency_amounts[this.state.invoice.currency]) + ' ' + getCurrencyWithCode(this.state.invoice.currency).symbol}</div>
            {getCurrenciesWithoutMatchWithCode(this.state.invoice.currency).map((x, k) => {
              if (record.currency_amounts[x.code])
                return <div
                  key={k}>{`${toLocaleString(record.currency_amounts[x.code]) + ' ' + getCurrencyWithCode(x.code).symbol}`}</div>
            })}
          </div>
        )
      },
    },
  ]

  columns = [
    {
      title: this.props.langData['Product'] + ' / ' + this.props.langData['Additional Charge'],
      key: 'product_id|additional_charge_id',
      width: '40%',
      render: (value, record) => {
        let type = (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge'
        if (value && record[type + 's'].length)
          return record[type + 's'].find(x => x[type + '_id'] === record[type + '_id']).name
        return null
      },
    },
    {
      title: this.props.langData['Quantity'],
      width: '12%',
      dataIndex: 'quantity',
      render: (value, record) => {
        let quantityData = this.state.invoice.return_invoices
        let a = 0
        let b = 0
        if ( ! this.props.type.includes('Detail')) {
          quantityData.forEach((x, k) => {
            if (typeof record.product_id !== 'undefined' && typeof x.products.filter(x => x.product_id === record.product_id).map(x => x.quantity)[0] !== 'undefined') {
              a += x.products.filter(x => x.product_id === record.product_id).map(x => x.quantity)[0]
            } else if (typeof record.additional_charge_id !== 'undefined' && typeof x.additional_charges.filter(x => x.additional_charge_id === record.additional_charge_id).map(x => x.quantity)[0] !== 'undefined') {
              b += x.additional_charges.filter(x => x.additional_charge_id === record.additional_charge_id).map(x => x.quantity)[0]
            }
          })
          if (typeof (value) === 'number' && ! this.state.status) {
            let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
            if (typeof record.product_id !== 'undefined') {
              datas.find(x => x.id === record.id).quantity = value - a
            } else if (typeof record.additional_charge_id !== 'undefined') {
              datas.find(x => x.id === record.id).quantity = value - b
            }
            this.setState({
              [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
              status: true,
            })
          }
        }
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0.0001}
              step={this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              onChange={value => {
                if (typeof (value) === 'number') {
                  let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                  datas.find(x => x.id === record.id).quantity = value === 0 ? 0.0001 : value
                  this.setState({
                    [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                  })
                  this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
                }
              }}
            >
            </InputNumber>
          )
        return `${value} ${record.unit ? ' ' + record.unit.name : ''}`
      },
    },
    {
      title: this.props.langData['Price'],
      dataIndex: 'amount',
      width: '14%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <Input.Group compact style={{ display: 'flex' }}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step={this.state.increment.toFixed(4)}
                decimalSeparator=","
                onChange={value => {
                  if (typeof (value) === 'number') {
                    let datas = (typeof record.product_id !== 'undefined' ? this.state.products : this.state.additional_charges)
                    datas.find(x => x.id === record.id).amount = value
                    this.setState({
                      [typeof record.product_id !== 'undefined' ? 'products' : 'additional_charges']: datas,
                    })
                    this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
                  }
                }}
                value={value}
                disabled={record.delete}
                formatter={value => {
                  if (value.toString() === '')
                    return ''
                  return value.toString().replace(this.state.selectedCurrency.symbol, '') + ` ${this.state.selectedCurrency.symbol}`
                }}
                parser={value => value.replace(' ' + this.state.selectedCurrency.symbol, '')}
              />
              <Button disabled>{this.state.selectedCurrency.symbol}</Button>
            </Input.Group>
          )
        return value ? (value + ' ' + this.state.selectedCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '12%',
      render: (value) => {
        return value ? (value + '%') : `${0}%`
      },
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'total',
      width: '14%',
      render: (value, record) => {
        let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
        datas.find(x => x.id === record.id).total = (record.quantity * record.amount) + record.vatTotal
        if ( ! this.state.status)
          this.setState({
            [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
            status: true,
          })
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              step={this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              disabled={true}
              onChange={value => {
                let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                datas.find(x => x.id === record.id).total = value
                this.setState({
                  [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                })
                this.calculateRow(record.id, (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge')
              }}
              formatter={value => {
                if (value.toString() === '')
                  return ''
                return value.toString().replace(this.state.selectedCurrency.symbol, '') + ` ${this.state.selectedCurrency.symbol}`
              }}
              parser={value => value.replace(' ' + this.state.selectedCurrency.symbol, '')}
            />
          )
        return `${value} ${this.state.selectedCurrency.symbol}`
      },
    },
    {
      className: 'text-right',
      render: (value, record) => {
        return (
          <Button
            icon={record.delete === true ? 'minus-circle-o' : 'close-circle-o'}
            style={{ width: 40 }}
            onClick={event => {
              event.stopPropagation()
              if (typeof record.product_id !== 'undefined')
                this.deleteProduct(record.id)
              else
                this.deleteAdditionalCharge(record.id)
            }}
          />
        )
      },
    },
  ]

  onSubmit = (mutate) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      this.callQuery(mutate, values)
    })
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      id: record.paid.__typename === 'AccountOpeningCustomer' ? record.paid.customer_id : record.paid_transaction_id,
      type: record.paid.__typename === 'AccountOpeningCustomer' ? 'customer-account-opening' : record.paid.__typename.toLowerCase(),
    }))
  }

  callQuery = async (mutate, values) => {
    let invoice = cloneDeep(this.state.invoice)
    if (this.state.products.length) {

      let products = []

      for (let i = 0; i < this.state.products.length; i++) {
        let product = Object.assign({}, this.state.products[i])
        if (product.quantity) {
          if ( ! product.product_id || ! product.amount || ! product.quantity)
            return toast(false, this.props.langData['Incorrect products entered'])

          delete product.id
          delete product.products
          delete product.delete
          delete product.vatTotal
          delete product.total
          delete product.grand_total
          delete product.unit
          delete product.__typename

          products.push(product)
        }
      }

      if (products.length) {
        if (this.props.type.includes('Detail'))
          values.invoice.products_update = products
        else
          values.invoice.products = products
      }
    }

    // ek ücretler
    if (this.state.additional_charges.length) {
      let additional_charges = []

      for (let i = 0; i < this.state.additional_charges.length; i++) {
        let additional_charge = Object.assign({}, this.state.additional_charges[i])
        if (additional_charge.quantity) {

          if ( ! additional_charge.additional_charge_id || ! additional_charge.amount || ! additional_charge.quantity)
            return toast(false, this.props.langData['Incorrect additional charges entered'])

          delete additional_charge.id
          delete additional_charge.additional_charges
          delete additional_charge.delete
          delete additional_charge.vatTotal
          delete additional_charge.total
          delete additional_charge.grand_total
          delete additional_charge.__typename

          additional_charges.push(additional_charge)
        }
      }
      if (additional_charges.length) {
        if (this.props.type.includes('Detail'))
          values.invoice.additional_charges_update = additional_charges
        else
          values.invoice.additional_charges = additional_charges
      }
    }

    values.invoice.date = moment(values.invoice.date).format('YYYY-MM-DD HH:mm')
    if (values.invoice.expiry_date)
      values.invoice.expiry_date = moment(values.invoice.expiry_date).format('YYYY-MM-DD')

    values.invoice.currency = this.state.selectedCurrency

    delete values.type
    delete values.invoice.customer
    delete values.invoice.paid

    values.invoice.customer_id = invoice.customer_id
    values.invoice.currency = invoice.currency

    try {
      const result = await mutate({
        variables: {
          [(this.type ? (this.type === 'sales' ? 'return_sales_invoice' : 'return_purchase_invoice') : this.props.type === 'sales' ? 'return_sales_invoice' : 'return_purchase_invoice')]: values.invoice,
          [this.props.type.includes('Detail') ? 'transaction_id' : 'invoice_id']: this.props.transaction_id,
        },
      })
      if (result !== undefined) {
        if (this.props.type.includes('Detail'))
          toast(true, this.type === 'sales' ? result.data.returnSalesInvoiceUpdate.informative.messages : result.data.returnPurchaseInvoiceUpdate.informative.messages)
        else
          toast(true, this.props.type === 'sales' ? result.data.returnSalesInvoiceCreate.informative.messages : result.data.returnPurchaseInvoiceCreate.informative.messages)

        this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
          type: this.props.type.includes('Detail') ? this.props.langData[this.type] : this.props.langData[this.props.type],
          id: this.props.type.includes('Detail') ? this.state.invoice.invoice_id : this.props.transaction_id,
        }))
      }
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }

  calculateRow = (id, type, reverse = false) => {
    setTimeout(() => {
      let datas = this.state[type + 's']
      let data = datas.find(x => x.id === id)
      if ( ! reverse) {
        data.vatTotal = parseFloat(((((data.quantity || 0) * (data.amount || 0)) / 100) * data.vat).toFixed(4))
        data.total = parseFloat((((data.quantity || 0) * (data.amount || 0)) + data.vatTotal).toFixed(4))
      } else {
        if (data.vat) {
          let without_vat_total = parseFloat(((data.total || 0) / parseFloat(((data.vat / 100) + 1).toFixed(4))).toFixed(4))
          data.amount = parseFloat((without_vat_total / (data.quantity || 0)).toFixed(4))
          data.vatTotal = parseFloat((data.total - data.amount).toFixed(4))
        } else {
          data.vatTotal = 0
          data.amount = parseFloat((data.total / (data.quantity || 0)).toFixed(4))
        }
      }
      this.setState({
        [type + 's']: datas,
      })
      this.calculateFooter()
    }, 50)
  }

  calculateFooter = () => {
    setTimeout(() => {
      let footer = {
        interimTotal: 0,
        totalVat: 0,
        totalDiscount: 0,
        grandTotal: 0,
      }

      this.state.products.map(x => {
        if (x.delete !== true) {
          let total = x.total
          footer.totalVat += x.vatTotal
          footer.interimTotal += total - x.vatTotal
          footer.grandTotal += total
        }
      })

      this.state.additional_charges.map(x => {
        if (x.delete !== true) {
          let total = x.total
          footer.totalVat += x.vatTotal
          footer.interimTotal += total - x.vatTotal
          footer.grandTotal += total
        }
      })

      footer.grandTotal -= footer.totalDiscount
      footer.grandTotal = parseFloat(footer.grandTotal.toFixed(4))

      footer = {
        interimTotal: footer.interimTotal.toFixed(4),
        totalVat: footer.totalVat.toFixed(4),
        totalDiscount: footer.totalDiscount.toFixed(4),
        grandTotal: footer.grandTotal.toFixed(4),
      }

      this.setState({ footer })

    }, 100)
  }

  deleteProduct = id => {
    if ( ! this.props.transaction_id) {
      this.setState({ products: this.state.products.filter(x => x.id !== id) })
    } else {
      let products = this.state.products
      if ( ! products.find(x => x.id === id).invoice_product_id) {
        this.setState({ products: this.state.products.filter(x => x.id !== id) })
      } else {
        products.find(x => x.id === id).delete = ! products.find(x => x.id === id).delete
        this.setState({ products })
      }
    }
    this.calculateFooter()
  }

  deleteAdditionalCharge = id => {
    if ( ! this.props.transaction_id) {
      this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
    } else {
      let additional_charges = this.state.additional_charges
      if ( ! additional_charges.find(x => x.id === id).invoice_additional_charge_id) {
        this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
      } else {
        additional_charges.find(x => x.id === id).delete = ! additional_charges.find(x => x.id === id).delete
        this.setState({ additional_charges })
      }
    }
    this.calculateFooter()
  }

  deleteReturnInvoice = () => {
    confirm(undefined, async result => {
      if ( ! result)
        return
      try {
        let result = await apolloClient.mutate({
          mutation: RETURN_INVOICE_DELETE_QUERY(this.type),
          variables: { transaction_id: Number(this.props.transaction_id) },
        })
        if ( ! result.data.returnSalesInvoiceDelete && ! result.data.returnPurchaseInvoiceDelete) {
          return toast(false, this.props.langData['x not found']('Return Invoice'))
        } else {
          if (this.type === 'sales') {
            toast(true, result.data.returnSalesInvoiceDelete.informative.messages)
          } else {
            toast(true, result.data.returnPurchaseInvoiceDelete.informative.messages)
          }
          this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
            type: this.props.langData[this.type],
            id: this.state.invoice.invoice_id,
          }))
        }
      } catch (error) {
        graphQLErrorsToToast(error)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Query
        query={this.props.type.includes('Detail') ? RETURN_INVOICE_DETAIL_QUERY(this.type) : READ_QUERY(this.props.type.includes('Detail') ? this.props.type.replace('Detail', '') : this.props.type)}
        fetchPolicy="network-only"
        variables={{ transaction_id: this.props.transaction_id }}
      >
        {({ loading: loadingQuery, data, refetch, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            if (this.props.transaction_id && ! data.invoice) {
              toast(false, this.props.langData['x not found']('Invoice'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else {
              this.setState({
                invoice: cloneDeep(data.invoice),
                initialize: true,
                increment: data.userInformation.settings.increment,
              })
              if (this.props.transaction_id) {
                if (data.invoice.products.length) {
                  let products = cloneDeep(data.invoice.products)
                  products.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.products = [
                      {
                        product_id: x.product_id,
                        name: x.product.name,
                      },
                    ]
                    x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                    delete x.product
                  })
                  this.setState({ products })
                }

                if (data.invoice.additional_charges.length) {
                  let additional_charges = cloneDeep(data.invoice.additional_charges)
                  additional_charges.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.additional_charges = [
                      {
                        additional_charge_id: x.additional_charge_id,
                        name: x.additional_charge.name,
                      },
                    ]
                    x.vatTotal = parseFloat(((x.total / 100) * (x.vat || 0)).toFixed(4))
                    delete x.additional_charge
                  })
                  this.setState({ additional_charges })
                }

                let balance = 0
                let balanceData = data.invoice.customer.balance

                if (balanceData) {
                  Object.keys(balanceData).map(x => {
                    if (balanceData[x] !== 0 && x !== '__typename')
                      balance += parseFloat((balanceData[x] / data.invoice.currency_rates[x]).toFixed(4))
                    this.setState({ balance })
                  })
                }

                let invoice = data.invoice

                let addedCurrency = []
                for (let x in invoice.currency_rates) {
                  if (x !== invoice.currency && invoice.currency_rates[x] !== null && x !== '__typename')
                    addedCurrency.push(getCurrencyWithCode(x))
                }

                setTimeout(() => {
                  this.props.form.setFieldsValue(deleteNull({
                    'invoice[explanation]': data.invoice.explanation ? data.invoice.explanation : this.props.langData['Return Invoice'],
                    'invoice[customer][name]': data.invoice.customer.name,
                    'invoice[date]': this.props.type.includes('Detail') ? moment(data.invoice.date) : moment(),
                  }))

                  this.setState({
                    selectedCurrency: getCurrencyWithCode(invoice.currency),
                    invoice_country: invoice.invoice_country,
                    invoice_city: invoice.invoice_city,
                    delivery_country: invoice.delivery_country,
                    delivery_city: invoice.delivery_city,
                    customerList: [{
                      customer_id: invoice.customer_id,
                      name: invoice.customer.name,
                    }],
                    discount_type: invoice.discount_type,
                    remaining: invoice.remaining,
                  })

                  this.calculateFooter()
                  // kurları getir
                  this.fetchCurrencyRates()
                }, 10)
              }
            }
          }
          return (
            <Mutation
              mutation={this.props.type.includes('Detail') ? RETURN_INVOICE_UPDATE_QUERY(this.type) : CREATE_INVOICE_QUERY(this.props.type)}
              onError={error => graphQLErrorsToToast(error)}
            >
              {(mutate, { loading: loadingMutation }) => (
                <Row gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={this.props.type.includes('Detail') ? 14 : 24}
                    lg={this.props.type.includes('Detail') ? 16 : 24}
                    xl={this.props.type.includes('Detail') ? 17 : 24}
                    style={{ marginBottom: 16 }}>
                    <Card
                      title={
                        <span><Icon type="file-excel"/>
                          &nbsp; {this.props.langData['Return Invoice']}</span>
                      }
                      bordered={false}
                      extra={
                        this.props.type.includes('Detail') && <div>
                          <Button onClick={this.deleteReturnInvoice} loading={ ! this.state.invoice}
                                  style={{ marginLeft: 5 }}>
                            <Icon type="delete"/> {this.props.langData['Delete']}
                          </Button>
                        </div>
                      }
                    >
                      <Form hideRequiredMark={true} className="form-label-default">

                        <div className="form">

                          <Form.Item label={(
                            <span><Icon type="edit"/><p>{this.props.langData['Explanation']}</p></span>
                          )}
                                     {...formItemLayout}
                          >
                            {getFieldDecorator('invoice[explanation]', {
                              rules: [{ max: 100 }],
                              initialValue: this.props.langData['Return Invoice'],
                              validateTrigger: false,
                            })(
                              <Input size="large"/>,
                            )}
                          </Form.Item>

                          <Form.Item label={(
                            <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                          )}
                                     help={
                                       <div style={{ marginTop: '3px' }}>
                                         <Icon type="info-circle"/>&nbsp;
                                         {(this.state.balance || this.state.balance === 0) && this.props.langData['This customer has debt'](toLocaleString(parseFloat(this.state.balance === 0 ? 0 : this.state.balance)), this.state.selectedCurrency.symbol)}
                                       </div>
                                     }
                                     {...formItemLayout}
                          >
                            {getFieldDecorator('invoice[customer][name]', { rules: [{ max: 100 }] })(
                              <Input disabled={true} size="large"/>,
                            )}
                          </Form.Item>

                          <Form.Item label={(
                            <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                          )}
                                     {...formItemLayoutHalf}
                          >
                            {getFieldDecorator('invoice[date]', {
                              rules: [{ required: true }],
                            })(
                              <DatePicker
                                format="DD.MM.YYYY"
                                size="large"
                                suffixIcon={<Icon type="calendar" theme="filled"/>}
                                className="w-100"
                              />,
                            )}
                          </Form.Item>
                        </div>
                      </Form>
                    </Card>

                    <Card
                      bodyStyle={{ padding: 0, marginTop: 20 }}
                      bordered={false}
                    >
                      <Divider>
                        <Icon
                          type="star-o"/> {this.props.langData['Products']} / {this.props.langData['Additional Charges']}
                      </Divider>
                      <Table
                        columns={this.columns}
                        rowKey={Math.floor(Math.random() * 999999) + 1}
                        dataSource={
                          this.state.products.filter(x => x.quantity).concat(this.state.additional_charges.filter(x => x.quantity))
                        }
                        pagination={false}
                        id="table"
                        scroll={{ x: 700, y: 500 }}
                      />

                      <div className="form-block"
                           style={{ textAlign: 'right', width: '100%', height: 'auto', marginTop: 20 }}>

                        <div className="fl-r">
                          <p>{this.state.footer.interimTotal.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>
                          <p>{this.state.footer.totalVat.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>
                          {this.state.footer.totalDiscount != '0.0000' &&
                            <p>{this.state.footer.totalDiscount.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</p>}
                          <p>
                            <strong>{this.state.footer.grandTotal.toString().replace('.', ',') + ' ' + this.state.selectedCurrency.symbol}</strong>
                          </p>
                        </div>

                        <div className="fl-r" style={{ marginRight: 26 }}>
                          <p>{this.props.langData['Interim Total']}</p>
                          <p>{this.props.langData['Total VAT']}</p>
                          {this.state.footer.totalDiscount != '0.0000' &&
                            <p>{this.props.langData['Total Discount']}</p>}
                          <p><strong>{this.props.langData['Grand Total']}</strong></p>
                        </div>
                      </div>

                      <Divider/>

                      <div className="form-block">
                        <Form.Item {...formItemLayoutNoLabel}>
                          <Button
                            loading={this.state.isSubmit}
                            size="large"
                            type="primary"
                            className="default-button"
                            onClick={() => this.onSubmit(mutate)}
                          >
                            {this.props.langData['Save']}
                          </Button>
                        </Form.Item>
                      </div>
                    </Card>
                    <Card
                      bodyStyle={{ padding: 0, marginTop: 20 }}
                      bordered={false}
                    >
                      {this.state.invoice && this.state.invoice.commits && <Divider>
                        <Icon type="swap"/> {this.props.langData['Payment List']}
                      </Divider>}

                      {this.state.invoice && this.state.invoice.commits && <Table
                        columns={this.commitColumns}
                        rowKey={Math.floor(Math.random() * 999999) + 1}
                        dataSource={this.state.invoice ? this.state.invoice.commits : null}
                        loading={ ! this.state.invoice}
                        pagination={false}
                        onRow={record => ({
                          onClick: () => this.rowItemClick(record),
                        })}
                        style={{ borderRadius: '0px' }}
                        rowClassName="white"
                        scroll={{ x: 700 }}
                      />}
                    </Card>
                  </Col>
                  {this.state.invoice && this.props.type.includes('Detail') && (
                    <Col xs={24} sm={24} md={10} lg={8} xl={7}>
                      <Route exact path={routes.INVOICE_RETURN_BY_TYPE} render={() => {
                        return (
                          <Card
                            bodyStyle={{ padding: '0px' }}
                            title={
                              this.state.invoice.remaining &&
                              <Button className="w-100" type={this.type === 'sales' ? 'danger' : 'primary'}>
                                <Link
                                  to={generatePath(routes.INVOICE_RETURN_PAY_BY_TYPE, {
                                    type: `${this.type}Detail`,
                                    id: this.transaction_id,
                                  })}
                                >
                                  {this.props.langData[this.type === 'sales' ? 'Pay to Return Invoice' : 'Get Payment to Return Invoice']}
                                </Link>
                              </Button>
                            }
                            bordered={false}
                          >
                            <div className="form-block">
                              <Row gutter={24} style={{ marginBottom: 16 }}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                  <span style={{ fontSize: 15 }}>{this.props.langData['Remaining']}</span>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16} xl={18} className="text-right">
														<span style={{ fontSize: 18, fontWeight: 'bold' }}>
															{toLocaleString((this.state.invoice.grand_total - this.state.invoice.paid))}
														</span>
                                  <span style={{ fontSize: 13, fontWeight: 'bold' }}>
															&nbsp;{getCurrencyWithCode(this.state.invoice.currency).symbol}
														</span>
                                </Col>
                              </Row>
                              <Row gutter={24}
                                   style={{ marginBottom: ! (this.state.invoice.grand_total - this.state.invoice.paid) > 0 ? 16 : 0 }}>
                                <Progress
                                  percent={(this.state.invoice.paid * 100) / this.state.invoice.grand_total === 100 ? ((this.state.invoice.paid * 100) / this.state.invoice.grand_total).toFixed(0) : ((this.state.invoice.paid * 100) / this.state.invoice.grand_total).toFixed(1)}
                                  strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                  }}
                                  format={value => value.toString().replace('.', ',') + '%'}
                                  status="active"
                                />
                              </Row>
                              {(this.state.invoice.grand_total - this.state.invoice.paid) > 0 && <div>
                                <Divider/>
                                <Row gutter={24} style={{ marginBottom: 16 }}>
                                  <Col xs={9} sm={10} md={8} lg={8} xl={10}>
                                    <span style={{
                                      fontSize: 15,
                                      color: '#9ea6ad',
                                    }}>{this.props.langData['Payment Date']}</span>
                                  </Col>
                                  <Col xs={15} sm={14} md={16} lg={16} xl={14} className="text-right">
                                  <span style={{
                                    fontSize: 18,
                                    color: 'red',
                                  }}>{this.state.invoice.expiry_date ? moment(this.state.invoice.expiry_date).locale(this.props.lang).format('DD MMMM YYYY') : '-'}</span>
                                  </Col>
                                  {this.state.dateDiff >= 0 &&
                                    <Col xs={12} sm={24} md={16} lg={16} xl={24} className="text-right"
                                         style={{ float: 'right' }}>
                                  <span style={{
                                    fontSize: 15,
                                    color: 'red',
                                  }}>{this.state.isToday ? `(${this.props.langData['Expiry date is today']})` : this.state.dateDiff > 0 && `(${this.props.langData['Days Late'](moment().diff(moment(this.state.invoice.expiry_date), 'days'))})`}
                                    <br/></span>
                                    </Col>}
                                </Row>
                              </div>}
                            </div>
                          </Card>
                        )
                      }}/>

                      <Route exact path={routes.INVOICE_RETURN_PAY_BY_TYPE} render={props => {
                        const newReturnPayment = (
                          <NewReturnPayment
                            type={this.type}
                            customer_id={this.state.invoice.customer_id}
                            id={this.state.invoice.transaction_id}
                            callback={() => {
                              this.setState({ invoice: null })
                              refetch()
                            }}
                            {...props}
                          />
                        )

                        if (this.props.sizeWidth <= 576) {

                          return (
                            <Modal
                              title={
                                <span><Icon type="pay-circle-o"/> &nbsp;{
                                  this.props.langData[this.state.type === 'sales' ? 'Get Payment' : 'Pay']
                                }</span>
                              }
                              bodyStyle={{ padding: 8 }}
                              visible={true}
                              footer={null}
                              destroyOnClose={true}
                              onCancel={() => {
                                props.history.push(
                                  generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
                                    type: `${this.state.type}Detail`,
                                    id: this.transaction_id,
                                  }),
                                )
                              }}
                            >
                              {newReturnPayment}
                            </Modal>
                          )

                        }

                        return newReturnPayment
                      }}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Mutation>
          )
        }
        }
      </Query>
    )
  }
}

InvoiceReturnForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  match: PropTypes.object,
  type: PropTypes.string,
  transaction_id: PropTypes.number,
  lang: PropTypes.string,
}

export default connect(state => {
  return {
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
})(InvoiceReturnForm)
