import routes from '../routes'

const initialState = {
  data: [
    {
      name: 'Dashboard',
      url: routes.STARTING,
      icon: 'dashboard',
    },
  ],
}

export const BreadCrumbReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'breadcrumb_add':

      return {
        data: [
          ...state.data,
          {
            name: action.payload.name,
            url: action.payload.url,
            icon: action.payload.icon,
          },
        ],
      }

    case 'breadcrumb_delete':

      // sadece ana sayfa kalsın
      return {
        data: [
          {
            name: 'Dashboard',
            url: routes.STARTING,
            icon: 'dashboard',
          },
        ],
      }

    default:

      return state

  }

}
