import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import { Table, Select, Input, Form, Popover, Button, Icon, Checkbox, Row, Col, Modal } from 'antd'
import { connect } from 'react-redux'
import { compile } from 'path-to-regexp'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { cloneDeep } from 'lodash'

import ExcelExtractModal from '../../../utils/ExcelExtractModal'
import ExcelUpload from './../../Utils/ExcelUpload'
import {
  graphQLErrorsToToast,
  deleteEmpty,
  createCsv,
  confirm,
  toLocaleString,
  toast,
} from '../../../helpers/helper'
import { multipleDeleteData } from '../../../helpers/trait'
import { getCurrencyWithCode } from '../../../data/Currency'
import ProductSaveControlModal from './../ProductSaveControlModal/ProductSaveControlModal'
import routes from '../../../routes'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'


const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
    filterData: state.TableFilterReducer.data.Product,
    filterFieldsData: state.TableFilterReducer.data.ProductFieldsData,
    pageInfo: state.TableFilterReducer.data.ProductPage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'Product' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'ProductFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'ProductPage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'Product' } })
      dispatch({ type: 'resetFilter', payload: { name: 'ProductFieldsData' } })
    },
  }
}

const LIST_QUERY = fields => (
  gql`
    query($filter: ProductFilterBase, $sorting: ProductSorting, $paging: Paging){
      productList(filter: $filter, sorting: $sorting, paging: $paging){
        products{
          product_id
          unit {
            name
          }
          name_on_invoice
          selling_currency
          buying_currency
          ${fields}
          has_sub_product
          children: sub_products {
            product_id
            main_product_id
            unit{
              name
            }
            selling_currency
            buying_currency
            ${fields}
          }
        }
        page_info{
          total_count
        }
      }
      settings {
        product_settings {
          critical_stock_count
        }
      }
    }
  `
)

class ProductList extends Component {
  fields = []
  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    columns: null,
    paging: {
      offset: 0,
      limit: 50,
    },
    sorting: {
      field: 'stock',
      type: 'DESC',
    },
    filter: null,
    selectedRows: [],
    isDeleteEnable: false,
    uploadExcelModelOpen: false,
    excelFooter: null,
    waiting: false,
    isExtractExcel: false,
  }

  constructor(props) {
    super(props)

    this.multipleDeleteData = multipleDeleteData('product', 'product_id').bind(this)
    this.fields = this.props.sizeWidth < 576 ? ['name', 'stock', 'category'] : ['name', 'stock', 'category', 'selling_price', 'buying_price']
    if (localStorage.getItem('product_list_columns')) {
      this.fields = localStorage.getItem('product_list_columns').split(',')
    }

  }

  onChangeField = fields => {
    if ( ! fields.length)
      return
    let columns = this.state.columns
    columns.map(x => {
      if (fields.indexOf(x.fieldName) !== -1)
        x.hide = false
      else
        x.hide = true

      x.width = 100 / fields.length + '%'
    })

    this.setState({
      columns,
    })

    localStorage.setItem('product_list_columns', fields)

    this.fields = fields
  }

  componentDidMount() {

    ! this.props.noBreadCrumb && this.props.breadcrumb_add(routes.PRODUCT_LIST)

    if ( ! this.state.columns) {
      this.setState({
        columns: [
          {
            title: this.props.langData['Name'],
            fieldName: 'name',
            fieldLabel: 'Name',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('name') === -1,
            dataIndex: 'name',
            sorter: true,
            key: 'name',
          },
          {
            title: this.props.langData['Invoice Name'],
            dataIndex: 'name_on_invoice',
            fieldName: 'name_on_invoice',
            fieldLabel: 'Invoice Name',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('name_on_invoice') === -1,
            sorter: true,
            key: 'name_on_invoice',
          },
          {
            title: this.props.langData['Code'],
            fieldName: 'code',
            fieldLabel: 'Code',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('code') === -1,
            dataIndex: 'code',
            align: 'center',
            sorter: true,
            key: 'code',
          },
          {
            title: this.props.langData['Category'],
            fieldName: 'category',
            fieldLabel: 'Category',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('category') === -1,
            subFields: ['name'],
            dataIndex: 'category',
            key: 'category',
            align: 'center',
            render: record => record ? record.name : '',
          },
          {
            title: this.props.langData['Stock Tracking'],
            fieldName: 'stock_tracking',
            fieldLabel: 'Stock Tracking',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('stock_tracking') === -1,
            dataIndex: 'stock_tracking',
            key: 'stock_tracking',
            align: 'center',
            render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: 18 }}/> : null,
          },
          {
            title: this.props.langData['Vat'],
            fieldName: 'vat',
            fieldLabel: 'Vat',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('vat') === -1,
            dataIndex: 'vat',
            key: 'vat',
            align: 'center',
            render: value => `${(value ? value + ' %' : '')}`,
          },
          {
            title: this.props.langData['Main Product'],
            fieldName: 'has_sub_product',
            fieldLabel: 'Main Product',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('has_sub_product') === -1,
            dataIndex: 'has_sub_product',
            key: 'has_sub_product',
            align: 'center',
            sorter: true,
            render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: 18 }}/> : null,
          },
          {
            title: this.props.langData['Stock'],
            fieldName: 'stock',
            fieldLabel: 'Stock',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('stock') === -1,
            dataIndex: 'stock',
            align: 'center',
            key: 'stock',
            sorter: true,
            render: (stock, data) => {
              let childrenStock = 0
              if (data.children) {
                data.children.map(x => {
                  childrenStock += x.stock
                })
                return `${childrenStock + (data.unit ? (' ' + data.unit.name) : '')}`
              } else
                return `${stock + (data.unit ? (' ' + data.unit.name) : '')}`
            },
          },
          {
            title: this.props.langData['Barcode'],
            fieldName: 'barcode',
            fieldLabel: 'Barcode',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('barcode') === -1,
            dataIndex: 'barcode',
            align: 'center',
            key: 'barcode',
            sorter: true,
          },
          {
            title: this.props.langData['Buying Price'],
            fieldName: 'buying_price',
            fieldLabel: 'Buying Price',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('buying_price') === -1,
            dataIndex: 'buying_price',
            key: 'buying_price',
            align: 'right',
            render: (value, data) => `${(! value ? 0 : toLocaleString(value)) + (data.buying_currency ? ' ' + getCurrencyWithCode(data.buying_currency).symbol : '')}`,
          },
          {
            title: this.props.langData['Selling Price'],
            fieldName: 'selling_price',
            fieldLabel: 'Selling Price',
            width: 100 / this.fields.length + '%',
            hide: this.fields.indexOf('selling_price') === -1,
            dataIndex: 'selling_price',
            key: 'selling_price',
            align: 'right',
            render: (value, data) => `${(! value ? 0 : toLocaleString(value)) + (data.selling_currency ? ' ' + getCurrencyWithCode(data.selling_currency).symbol : '')}`,
          },
        ],
        filter: this.props.filterData,
      })
    }

    //////Current page farklı ise current bilgisi güncelleniyor. // filtre yoksa sayfalama set edilsin.
    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }
  }

  componentWillUnmount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.stateProps))
      return false
    return true
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        //Pagination butonunun aktif olması için
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
      //Sayfa değiştiğinde limit offset ve pageInformations bilgileri redux'a gönderilir. Ayrıca pagination
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({ filter: null })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['product/detail/:id'])({ id: record.product_id }),
    )
  }

  getVisibleColumnsFieldName = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide && x.fieldName !== 'has_sub_product').map(x => x.fieldName) : []
  }

  getVisibleColumnsSubField = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide && x.fieldName !== 'has_sub_product').map(x => x.subFields) : []
  }

  getQueryWithSubFields = (fields, subFields) => {
    let query = ''
    subFields.forEach((e, i) => {
      e = e === undefined ? '' : '{' + e + '}'
      query += fields[i] + ' ' + e
    })

    return query
  }

  getColumnsFieldNameWithLabel = () => {
    return this.state.columns ? this.state.columns.map(x => ({
      label: this.props.langData[x.fieldLabel],
      value: x.fieldName,
      key: x.key,
    })) : []
  }

  getVisibleRawColumns = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide) : []
  }

  multipleDelete = refetch => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.multipleDeleteData(this.state.selectedRows, refetch)
      this.setState({
        selectedRows: [],
        isDeleteEnable: false,
      })
    })
  }

  uploadExcel = () => {
    this.setState({
      uploadExcelModelOpen: true,
    })
  }

  copy = () => {
    this.props.history.push(generatePath(routes.PRODUCT_COPY, {
      id: this.state.selectedRows[0].product_id,
    }))
  }

  extractExcel = payload => {
    const url = `${process.env.REACT_APP_IMPORT_EXPORT_URL}/product/list/excel/${localStorage.token}/${payload.starting}/${payload.total}`
    let link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'customers.xlsx')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    this.setState({ isExtractExcel: false })
  }

  render() {
    return (
      <Query
        query={LIST_QUERY(this.getQueryWithSubFields(this.getVisibleColumnsFieldName(), this.getVisibleColumnsSubField()))}
        fetchPolicy="network-only"
        skip={ ! this.state.columns}
        variables={{ filter: this.state.filter, sorting: this.state.sorting, paging: this.state.paging }}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery) {
              let graphQLData = cloneDeep(data.productList.products)
              graphQLData.map(x => {
                if ( ! x.children.length)
                  delete x.children
              })
              if ( ! this.state.data)
                this.setState({
                  data: graphQLData,
                  pagination: {
                    ...this.state.pagination,
                    total: data.productList.page_info.total_count,
                  },
                  critical_stock_count: data.settings.product_settings.critical_stock_count,
                })
              else if (this.state.data && JSON.stringify(graphQLData) !== JSON.stringify(this.state.data))
                this.setState({
                  data: cloneDeep(graphQLData),
                  pagination: {
                    pageSize: this.state.pagination.pageSize,
                    total: data.productList.page_info.total_count,
                  },
                  critical_stock_count: data.settings.product_settings.critical_stock_count,
                })
            }
          }

          this.props.refetch && refetch()

          return (
            <div>
              <FilterBox
                callback={this.filterCallback}
                isSubmit={loadingQuery}
                csv={createCsv.bind(this)}
                isModal={this.props.modalClose}
                extractExcel={() => this.setState({ isExtractExcel: true })}
                {...this.props}
              />
              {this.state.isExtractExcel && <ExcelExtractModal onCancel={() => this.setState({ isExtractExcel: false })}
                                                               onOk={this.extractExcel} {...this.props} />}
              {this.props.showHeaderButtons && <Row type="flex" justify="start">
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Popover content={(
                    <div>
                      <Checkbox.Group options={this.getColumnsFieldNameWithLabel()} onChange={fields => {
                        this.onChangeField(fields)
                        refetch()
                      }} value={this.fields} style={{ width: 110 }}/>
                    </div>
                  )} trigger="click" placement="bottom">
                    <Button icon="menu" block={true} style={{ marginBottom: 10, width: '100%', borderRadius: 0 }}>
                      {this.props.langData['Customize']}
                    </Button>
                  </Popover>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Link to={routes.PRODUCT_NEW}><Button
                    style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                    icon="plus-circle">{this.props.langData['Add New One']}</Button></Link>
                </Col>
                <Col xs={0} sm={0} md={6} lg={4} xl={3}>
                  {<Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                           onClick={() => this.uploadExcel()}>
                    <Icon type="file-excel"/> {this.props.langData['Import']}
                  </Button>}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  {this.state.isDeleteEnable && <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                                                        onClick={() => this.multipleDelete(refetch)}><Icon
                    type="delete"/> {this.props.langData['Delete']} </Button>}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  {this.state.isDeleteEnable && this.state.selectedRows.length == 1 &&
                    <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true} onClick={() => this.copy()}><Icon
                      type="copy"/> {this.props.langData['Copy']} </Button>}
                </Col>
              </Row>}

              <Modal
                width="50%"
                visible={this.props.sizeWidth > 576 ? this.state.uploadExcelModelOpen : false}
                title={<span><Icon type="file-excel"/>&nbsp;{this.props.langData['Import']}</span>}
                bodyStyle={{ padding: 8 }}
                destroyOnClose={true}
                closable={ ! this.state.waiting}
                maskClosable={false}
                onCancel={() => this.setState({
                  uploadExcelModelOpen: false,
                })}
                footer={this.state.excelFooter}
              >
                <ExcelUpload
                  setFooter={footer => this.setState({ excelFooter: footer })}
                  onClose={(el) => {
                    this.setState({
                      waiting: true,
                    })
                    setTimeout(() => {
                      this.setState({
                        uploadExcelModelOpen: false,
                        waiting: false,
                      })

                      toast(true, this.props.langData['Your products are imported'])
                      refetch()
                    }, el * 500)
                  }}
                  waiting={this.state.waiting}
                  openExcelSavingModal={() =>
                    this.setState({
                      uploadExcelModelOpen: false,
                      openExcelSavingModal: true,
                    })
                  }
                />
              </Modal>
              <Modal
                visible={this.props.sizeWidth > 576 ? this.state.openExcelSavingModal : false}
                title={<span><Icon type="search"/>&nbsp; {this.props.langData['Product Record Control']}</span>}
                closable={true}
                maskClosable={false}
                width={this.props.sizeWidth > 1130 ? '50%' : '80%'}
                footer={null}
                onCancel={() => this.setState({ openExcelSavingModal: false })}
                bodyStyle={{ padding: 0 }}
              >
                <ProductSaveControlModal/>
              </Modal>
              <Table
                columns={this.getVisibleRawColumns()}
                rowKey={record => record.product_id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={loadingQuery}
                onChange={this.handleTableChange}
                rowClassName={record => {
                  let childrenStock = 0
                  if (record.stock_tracking) {
                    if (record.children) {
                      record.children.map(x => {
                        childrenStock += x.stock
                      })
                      return childrenStock <= this.state.critical_stock_count ? 'stock' : 'white'
                    } else {
                      return record.stock <= this.state.critical_stock_count ? 'stock' : 'white'
                    }
                  }
                }
                }

                expandRowByClick={this.props.rowItemClick ? true : false}
                onRow={record => ({
                  onClick: () => {
                    if ( ! this.props.rowItemClick) {
                      return this.rowItemClick(record)
                    } else {
                      if ( ! record.has_sub_product)
                        this.props.rowItemClick(record)
                    }
                  },
                })}
                rowSelection={! this.props.modalClose ? {
                  selectedRowKeys: this.state.selectedRows.length ? this.state.selectedRows.map(x => x.product_id) : [],
                  onChange: x => {
                    this.setState({
                      selectedRows: x.map(y => ({ product_id: y })),
                      isDeleteEnable: x.length ? true : false,
                    })
                  },
                  columnWidth: 60,
                } : undefined}
                scroll={{
                  x: this.state.columns ? this.getVisibleRawColumns().length * 100 : 500,
                  y: this.props.scrollHeight ? this.props.scrollHeight : (! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)'),
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }

}

ProductList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  noBreadCrumb: PropTypes.bool,
  rowItemClick: PropTypes.func,
  modalClose: PropTypes.func,
  showHeaderButtons: PropTypes.bool,
  scrollHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  refetch: PropTypes.bool,
  email_verification: PropTypes.bool,
  filter: PropTypes.func,
  filterData: PropTypes.object,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.func,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.func,
}

ProductList.defaultProps = {
  showHeaderButtons: true,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
    critical_stock_count: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isSubmit !== nextProps.isSubmit)
      this.setState({ isSubmit: nextProps.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

const CATEGORY_LIST_QUERY = (
  gql`
    {
      productCategoryList {
        category_id
        name
      }
    }
  `
)

class FilterBoxForm extends Component {

  initialFilterItems = {
    category_id: {
      label: this.props.langData['Category'],
      width: 300,
      added: false,
      operator: 'eq',
    },
    stock: {
      label: this.props.langData['Stock'],
      width: 380,
      added: false,
    },
    code: {
      label: this.props.langData['Code'],
      width: 300,
      added: false,
      operator: 'contains',
    },
    barcode: {
      label: this.props.langData['Barcode'],
      width: 300,
      added: false,
      operator: 'contains',
    },
    name: {
      required: true,
      operator: 'contains',
    },
  }

  state = {
    selectedOptions: [],
    categoryList: [],
    isSubmit: false,
    filterItems: this.initialFilterItems,
  }

  componentDidMount() {

    if (this.props.filterFieldsData) {

      const filterItems = cloneDeep(this.initialFilterItems)

      for (const [key, value] of Object.entries(this.props.filterFieldsData)) {

        if (filterItems.hasOwnProperty(key)) {

          if (filterItems[key].required !== true) {
            filterItems[key].added = true
          }

          this.props.form.getFieldDecorator(key, {
            initialValue: value,
          })

        }

      }

      this.setState({
        filterItems: filterItems,
      })

    }

  }

  onChangeCheckbox = options => {

    const filterItems = cloneDeep(this.initialFilterItems)

    for (const option of options) {

      if (filterItems.hasOwnProperty(option)) {
        if (filterItems[option].required !== true) {
          filterItems[option].added = true
        }
      }

    }

    this.setState({
      filterItems: filterItems,
    })

  }

  onSubmit = form => {

    form.validateFields((err, values) => {

      if (err) {
        return
      }

      this.setState({ isSubmit: true })

      deleteEmpty(values)

      const filterData = []

      for (const [key, value] of Object.entries(values)) {

        if ( ! this.state.filterItems.hasOwnProperty(key)) {
          continue
        }

        if (key === 'stock') {
          filterData.push({
            [key]: {
              [value]: 0,
            },
          })
        } else {

          filterData.push({
            [key]: {
              [this.state.filterItems[key].operator]: value,
            },
          })

        }

      }

      this.props.callback(filterData.length > 0 ? filterData : null, values)

    })

  }

  notRequiredFilterItems() {

    const items = []

    for (const [key, value] of Object.entries(this.state.filterItems)) {

      if (value.required !== true) {
        items.push({
          value: key,
          label: value.label,
          added: value.added,
        })
      }

    }

    return items

  }

  notRequiredAndAddedItems() {
    return this.notRequiredFilterItems().filter(x => x.added === true)
  }

  render() {

    const { getFieldDecorator } = this.props.form

    const options = this.notRequiredFilterItems().map(x => ({
      value: x.value,
      label: x.label,
    }))

    const selectedValues = this.notRequiredAndAddedItems().map(x => x.value)

    let headerContent = [
      ! this.props.isModal &&
      <Popover placement="bottomLeft" content={
        <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
          <Button key="1" onClick={this.props.csv} style={{
            width: '-webkit-fill-available',
            marginBottom: '10px',
          }}>{this.props.langData['Csv Export']}</Button>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
            <Button key="2" onClick={() => this.props.extractExcel(true)}>
              {this.props.langData['Xlsx Export']}
            </Button>
          </Row>
        </Row>
      } trigger="click">
        <Button><Icon type="file-unknown"/></Button>
      </Popover>,
      <CheckboxedDropdown
        key="2"
        width={120}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedValues}
        onChange={this.onChangeCheckbox}
      />,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let contentItems = [...filterHeader]

    if (this.state.filterItems.category_id.added) {
      contentItems.push(
        getFieldDecorator('category_id')(
          <Select
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.category_id.width : null }}
            allowClear
            key="6"
            placeholder={this.props.langData['Category']}
            suffixIcon={<Icon type="caret-down"/>}
          >
            {this.state.categoryList.map(x => <Select.Option value={x.category_id}
                                                             key={x.category_id}>{x.name}</Select.Option>)}
          </Select>,
        ),
      )
    }

    if (this.state.filterItems.stock.added) {
      contentItems.push(
        getFieldDecorator('stock')(
          <Select
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.stock.width : null }}
            allowClear
            key="4"
            placeholder={this.props.langData['Stock']}
            suffixIcon={<Icon type="caret-down"/>}
          >
            <Select.Option value="gt">{this.props.langData['Has Stock']}</Select.Option>
            <Select.Option value="eq">{this.props.langData['Has No Stock']}</Select.Option>
          </Select>,
        ),
      )
    }

    if (this.state.filterItems.barcode.added) {
      contentItems.push(
        getFieldDecorator('barcode')(
          <Input
            placeholder={this.props.langData['Barcode']}
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.barcode.width : null }}
            key="9"
            allowClear
          />,
        ),
      )
    }

    if (this.state.filterItems.code.added) {
      contentItems.push(
        getFieldDecorator('code')(
          <Input
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.code.width : null }}
            key=""
            allowClear
            placeholder={this.props.langData['Code']}/>,
        ),
      )
    }

    contentItems.push(
      getFieldDecorator('name')(
        <Input key="5" allowClear placeholder={this.props.langData['Name']}/>,
      ),
    )

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={CATEGORY_LIST_QUERY}
          fetchPolicy="network-only"
          onError={graphQLErrorsToToast}
          onCompleted={data => {
            this.setState({
              categoryList: data.productCategoryList,
            })
          }}
        >
          {() => {
            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {contentItems.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )
    return (
      <Query
        query={CATEGORY_LIST_QUERY}
        fetchPolicy="network-only"
        onError={graphQLErrorsToToast}
        onCompleted={data => {
          this.setState({
            categoryList: data.productCategoryList,
          })
        }}
      >
        {() => {
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {contentItems.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )
  }

}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isSubmit: PropTypes.bool,
  csv: PropTypes.func,
  isModal: PropTypes.func,
  filterFieldsData: PropTypes.object,
  extractExcel: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
