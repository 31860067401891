import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AdditionalChargeForm from './../AdditionalChargeForm/AdditionalChargeForm'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class AdditionalChargeUpdateUpdate extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.ADDITIONAL_CHARGE_UPDATE, {
      id: this.props.additional_charge_id,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const WrappedAdditionalChargeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(AdditionalChargeForm)
    return <WrappedAdditionalChargeForm
      additional_charge_id={this.additional_charge_id} {...this.props} ></WrappedAdditionalChargeForm>
  }

}

AdditionalChargeUpdateUpdate.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  additional_charge_id: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalChargeUpdateUpdate)
