import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreditDebitForm from './../../../Customer/CreditDebitForm/CreditDebitForm'
import { connect } from 'react-redux'

import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete(name) {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewPayment extends Component {
  id
  type

  constructor(props) {
    super(props)
    this.id = props.match.params.id
    this.type = props.match.params.type
  }

  componentDidMount() {
    this.props.breadcrumb_add(
      this.props.type === 'sales' ? 'Get Payment' : 'Pay',
      generatePath(routes.INVOICE_DETAIL_PAY_BY_TYPE, {
        type: this.props.langData[this.props.type],
        id: this.id,
      }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete(this.props.type === 'sales' ? 'Get Payment' : 'Pay')
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  render() {
    const WrappedCreditDebitForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(CreditDebitForm)
    return <WrappedCreditDebitForm priority_invoice={this.props.priority_invoice} {...this.props} />
  }

}

NewPayment.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  type: PropTypes.string, // fatura tipi
  priority_invoice: PropTypes.string,
  callback: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPayment)
