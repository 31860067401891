import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Icon, Row, Col, Collapse, Input, InputNumber, Select, List, Radio, Button, Checkbox } from 'antd'
import FontAwesome from 'react-fontawesome'
import { debounce, startCase } from 'lodash'
import moment from 'moment'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/resizable'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/themes/base/all.css'
import './WaybillDesign.css'

window.jQuery = $
require('colresizable/colResizable-1.6.min.js')

const Panel = Collapse.Panel
const Option = Select.Option
const InputGroup = Input.Group
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const ButtonGroup = Button.Group
const CheckboxGroup = Checkbox.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Design' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class WaybillDesign extends Component {

  sizes = {
    'A4': { width: 21, height: 29.7 },
    'A5': { width: 14.8, height: 21 },
  }

  contents = {
    en: {
      'name': 'TEKROM TECHNOLOGY CORPORATION',
      'phone_1': '0555 755 55 55',
      'phone_2': '0555 755 55 56',
      'address': 'Yıldız Technical University Davutpaşa Campus Technopark D2 Block No:104 ESENLER / İstanbul',
      'country': 'Turkey',
      'city': 'İstanbul',
      'town': 'Beyoğlu',
      'code': '#123',
      'e_mail': 'info@jetofis.com',
      'id_or_tax_identification_number': '11223344556',
      'tax_administration': 'Beyoğlu TO',
      'waybill_no': '965',
    },
    tr: {
      'name': 'TEKROM TEKNOLOJİ A.Ş',
      'phone_1': '0555 755 55 55',
      'phone_2': '0555 755 55 56',
      'address': 'Yıldız Teknik Üniversitesi Davutpaşa Kampüsü Teknopark D2 Blok No:104 ESENLER / İSTANBUL',
      'country': 'Türkiye',
      'city': 'İstanbul',
      'town': 'Beyoğlu',
      'code': '#123',
      'e_mail': 'info@jetofis.com',
      'id_or_tax_identification_number': '11223344556',
      'tax_administration': 'Beyoğlu VD',
      'waybill_no': '965',
    },
  }

  columnsData = {
    en: {
      'name': 'Computer',
      'code': 'u_123',
      'unit': 'Count',
      'quantity': 1,
    },
    tr: {
      'name': 'Bilgisayar',
      'code': 'u_123',
      'unit': 'Adet',
      'quantity': 1,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      width: parseFloat(props.width),
      height: parseFloat(props.height),
      size: props.size,
      selectedElement: null,
      collapseActiveKey: ['customer-information'],
      background: null,
      source: props.source || {
        style: {
          fontSize: 11,
          textAlign: 'left',
        },
        elements: [],
      },
      elements: {
        'customer-information': [
          {
            name: 'name',
            icon: 'tag',
          },
          {
            name: 'phone_1',
            icon: 'phone',
          },
          {
            name: 'phone_2',
            icon: 'phone',
          },
          {
            name: 'address',
            icon: 'environment-o',
          },
          {
            name: 'country',
            icon: 'environment-o',
          },
          {
            name: 'city',
            icon: 'environment-o',
          },
          {
            name: 'town',
            icon: 'environment-o',
          },
          {
            name: 'code',
            icon: 'paper-clip',
          },
          {
            name: 'e_mail',
            icon: 'mail',
          },
          {
            name: 'id_or_tax_identification_number',
            icon: 'idcard',
          },
          {
            name: 'tax_administration',
            icon: 'compass',
          },
        ],
        'waybill-information': [
          {
            name: 'waybill_no',
            icon: 'paper-clip',
          },
          {
            name: 'date',
            icon: 'calendar',
          },
        ],
        'textbox': [],
        'product-table': {
          name: 'product_table',
          columns: ['name', 'code', 'unit', 'quantity'],
        },
      },
    }
    this.onChangePageSize = debounce(this.onChangePageSize, 100)
    this.onChangePosition = debounce(this.onChangePosition, 100)
    this.onChangeSize = debounce(this.onChangeSize, 100)
    this.checkTextBoxs()
  }

  componentDidMount() {
    this.props.breadcrumb_add()

    this.designerInit()
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  onSubmit = () => {
    let source = this.state.source
    for (let x of source.elements) {
      delete x.id
      delete x.type
      if (x.name === 'product_table') {
        let productTableWidth = $('.product-table').width()
        let productTableFirstTr = $('.product-table').find('tr:first')
        for (let i in x.columns) {
          let c = parseInt(i) + 1
          x.columns[i].width = parseFloat(((productTableFirstTr.find('td:nth-child(' + c + ')').width() / productTableWidth) * 100).toFixed(2))
        }
      }

    }

    this.props.callback(source, this.state.size, this.state.width, this.state.height)
  }

  designerInit() {
    if (this.state.source) {
      let source = this.state.source
      let elements = this.state.elements
      source.elements.map(x => {
        x.id = Math.floor(Math.random() * 9999999)
        if (x.name === 'product_table') {
          let columns = []
          x.columns.map(y => columns.push(y.name))
          let lastColumns = this.state.elements['product-table'].columns
          elements['product-table'].added = true
          elements['product-table'].columns = Object.assign([], columns)
          lastColumns.map(y => {
            if (columns.indexOf(y) === -1)
              elements['product-table'].columns.push(y)
          })
        } else {
          for (let k in elements) {
            for (let i in elements[k]) {
              if (elements[k][i].name === x.name) {
                elements[k][i].added = true
                break
              }
            }
          }
        }
      })
      this.setState({
        source: source,
        elements: elements,
      })
    }
    $('#designer-content').droppable({
      accept: '.menu-element',
      drop: (event, ui) => {
        let x = (ui.offset.left - $('#designer-content').offset().left) / 37.795275591
        let y = (ui.offset.top - $('#designer-content').offset().top) / 37.795275591
        if (x < 0 || y < 0)
          return
        let elements = this.state.elements
        let source = this.state.source
        let menu = ui.draggable[0].getAttribute('data-menu')
        let name = ui.draggable[0].getAttribute('data-name')


        let newElement = {
          id: Math.floor(Math.random() * 9999999),
          name: name,
          style: {
            x: parseFloat(x.toFixed(2)),
            y: parseFloat(y.toFixed(2)),
            width: (menu === 'product-table') ? 10 : 5,
            height: (menu === 'product-table') ? 6 : 1,
          },
        }

        if (menu === 'product-table') {
          elements['product-table'].added = true
          newElement.columns = [
            { name: 'name' }, { name: 'code' }, { name: 'unit' }, { name: 'quantity' },
          ]
        } else {
          for (let k in elements[menu]) {
            if (elements[menu][k].name === name) {
              elements[menu][k].added = true
              this.setState({ elements: elements })
              break
            }
          }
        }

        if (menu === 'waybill-information') {
          if (name === 'date')
            newElement.date_format = Object.values(this.props.langData['date-formats'])[0]

        } else if (menu === 'textbox') {
          newElement.textbox = true
          newElement.content = this.contents[this.props.lang][name]
        }

        source.elements.push(newElement)
        this.setState({ source })
        this.designerLiveElement()

      },
    })


    this.designerLiveElement()

    $('#designer-content').click(e => {
      if ($(e.target).prop('tagName') === 'IMG')
        this.setState({ selectedElement: null })
    })

  }

  onPressEnter = e => {
    let elements = this.state.elements
    if ( ! elements['textbox'].includes({ name: e.target.value.replace(' ', '_'), icon: 'edit' }))
      elements['textbox'].push({ name: e.target.value.replace(' ', '_'), icon: 'edit' })
    this.contents[this.props.lang][e.target.value.replace(' ', '_')] = e.target.value
    this.setState({ elements: elements })

    //let textboxes = localStorage.getItem('textboxes') ? localStorage.getItem('textboxes') : []
    localStorage.setItem('textboxes', JSON.stringify(elements['textbox']))
    localStorage.setItem('textbox-content', JSON.stringify(this.contents))
    this.designerInit()
    e.target.value = ''
  }

  checkTextBoxs = () => {
    let textboxes = localStorage.getItem('textboxes') ? localStorage.getItem('textboxes') : '[]'
    let contents = localStorage.getItem('textbox-content') ? localStorage.getItem('textbox-content') : JSON.stringify(this.contents)


    let elements = this.state.elements
    if (JSON.parse(textboxes).length) {
      elements['textbox'] = JSON.parse(textboxes)
      this.contents = JSON.parse(contents)
    }
    this.setState({ elements: elements })
  }

  designerLiveElement() {
    setTimeout(() => {
      $('.product-table').colResizable({ disable: true })
      $('.product-table').colResizable({
        liveDrag: true,
        partialRefresh: true,
        postbackSafe: true,
      })
      $('.menu-element').draggable({
        containment: '#designer-row',
        helper: 'clone',
        cursor: 'move',
      })
      $('#menu-product-columns').sortable({
        stop: () => {
          let elements = this.state.elements
          let source = this.state.source
          let lastColumns = []
          source.elements.find(x => x.name === 'product_table').columns.map(x => lastColumns.push(x.name))
          let columns = []
          let elementColumns = []
          $('#menu-product-columns li').each((i, e) => {
            let name = $(e).attr('data-name')
            if (lastColumns.indexOf(name) !== -1)
              columns.push({ name: name })
            elementColumns.push(name)
          })
          source.elements.find(x => x.name === 'product_table').columns = columns
          elements['product-table'].columns = elementColumns
          this.setState({
            elements: elements,
            source: source,
          })
          this.designerLiveElement()
          return false
        },
      })
      $('#menu-product-columns').disableSelection({
        liveDrag: true,
      })
      $('.element').draggable({
        containment: '#designer-content',
        cursor: 'move',
        drag: (event, ui) => {
          let x = (ui.offset.left - $('#designer-content').offset().left) / 37.795275591
          let y = (ui.offset.top - $('#designer-content').offset().top) / 37.795275591
          let selectedElement = this.state.selectedElement
          selectedElement.x = x.toFixed(2)
          selectedElement.y = y.toFixed(2)
          let source = this.state.source
          source.elements.find(x => x.name === selectedElement.name).style.x = parseFloat(x.toFixed(2))
          source.elements.find(x => x.name === selectedElement.name).style.y = parseFloat(y.toFixed(2))
          this.setState({
            selectedElement,
            source,
          })
        },

      })
      $('.element').resizable({
        containment: '#designer-content',
        resize: (event, ui) => {
          let width = ui.size.width / 37.795275591
          let height = ui.size.height / 37.795275591
          let selectedElement = this.state.selectedElement
          selectedElement.width = width.toFixed(2)
          selectedElement.height = height.toFixed(2)
          let source = this.state.source
          source.elements.find(x => x.name === selectedElement.name).style.width = parseFloat(width.toFixed(2))
          source.elements.find(x => x.name === selectedElement.name).style.height = parseFloat(height.toFixed(2))
          this.setState({
            selectedElement,
          })
        },
      })
    }, 500)
  }

  onChangePageSize(type, value) {
    this.setState({ [type]: value })
  }

  onChangePosition(type, value) {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style[type] = value
    selectedElement[type] = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeSize(type, value) {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style[type] = value
    selectedElement[type] = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeTextAlign = value => {
    value = value.target.value
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style.textAlign = value
    selectedElement.textAlign = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeBold = () => {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style.fontBold = ! selectedElement.fontBold
    selectedElement.fontBold = ! selectedElement.fontBold
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeItalic = () => {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style.fontItalic = ! selectedElement.fontItalic
    selectedElement.fontItalic = ! selectedElement.fontItalic
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeFontSize = value => {
    if ( ! this.state.selectedElement) {
      let source = this.state.source
      source.style.fontSize = value
      source.elements.map(x => delete x.style.fontSize)
      return this.setState({ source: source })
    }
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.style.fontSize = value
    selectedElement.fontSize = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeProductColumn = values => {
    let source = this.state.source
    let columns = []

    $('#menu-product-columns li').each((i, e) => {
      let name = $(e).attr('data-name')
      if (values.indexOf(name) !== -1)
        columns.push({ name: name, width: 6 })
    })

    source.elements.find(x => x.name === 'product_table').columns = columns
    this.setState({ source: source })
    this.designerLiveElement()
  }

  deleteElement(id) {
    let source = this.state.source
    let elements = this.state.elements

    let step3 = () => {
      this.setState({
        source: source,
        elements: elements,
        selectedElement: null,
      })
      this.designerLiveElement()
    }

    let step2 = name => {
      if (name === 'product_table') {
        elements['product-table'].added = false
        step3()
      }
      for (let k in elements) {
        for (let i in elements[k]) {
          if (elements[k][i].name === name) {
            elements[k][i].added = false
            step3()
            break
          }
        }
      }
    }

    let step1 = () => {
      for (let i in source.elements) {
        if (source.elements[i].id === id) {
          let name = source.elements[i].name
          source.elements.splice(i, 1)
          step2(name)
          break
        }
      }
    }

    step1()
  }

  onChangeRightMenu = values => {
    if ( ! values.length)
      return this.setState({ collapseActiveKey: [] })
    this.setState({
      collapseActiveKey: [values[values.length - 1]],
    })
    this.designerLiveElement()
  }

  onChangeShowCurrency = value => {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.show_currency = value
    selectedElement.show_currency = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onChangeDetailyVat = value => {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === selectedElement.id)[0]
    element.detaily_vat = value
    element.style.height = value ? 2.5 : 0.5
    selectedElement.detaily_vat = value
    this.setState({
      source,
      selectedElement,
    })

  }

  onChangeDateFormat = value => {
    let selectedElement = this.state.selectedElement
    let source = this.state.source
    let element = source.elements.filter(x => x.id === this.state.selectedElement.id)[0]
    element.date_format = value
    selectedElement.date_format = value
    this.setState({
      source,
      selectedElement,
    })
  }

  onClickBackgroundButton = () => {
    if (this.state.background)
      return this.setState({ background: null })
    $('input[type=file]').click()
  }

  onChangeFileUpload = e => {
    e.preventDefault()

    if ( ! e.target.files.length)
      return

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => this.setState({ background: reader.result })
    reader.readAsDataURL(file)
    $(e.target).val('')
  }

  render() {
    const customPanelStyle = {
      background: 'white',
      marginBottom: 16,
      border: 0,
      padding: 0,
    }

    return (
      <Card
        bodyStyle={{ padding: 0 }}
        title={<span><Icon type="edit"/> &nbsp;{this.props.langData['Waybill Design']}</span>}
        bordered={false}
        style={{ background: 'transparent' }}
      >
        <Row style={{ marginTop: 1 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ background: 'white', float: 'left', width: '100%' }}>
              <div onClick={this.onClickBackgroundButton}
                   style={{ float: 'left', width: 80, height: 61, position: 'relative' }}>
                {this.state.background &&
                  <FontAwesome name="minus-circle" size="2x" className="image-button"
                               style={{ width: '100%', height: '100%', position: 'absolute' }}/>
                }
                { ! this.state.background &&
                  <FontAwesome name="image" size="2x" className="image-button"
                               style={{ width: '100%', height: '100%', position: 'absolute' }}/>
                }
              </div>
              <div style={{ padding: '0px 20px 0px 0px', float: 'left' }}>
                { ! this.state.selectedElement && <div style={{
                  width: 1,
                  height: 61,
                  background: '#e8e8e8',
                  float: 'left',
                  margin: '0px 20px 0px 0px',
                }}></div>}
                { ! this.state.selectedElement && <div style={{ padding: '10px 0px 0px 0px', float: 'left' }}>
                  <InputGroup style={{ width: 224 }} compact>
                    <Select
                      style={{ width: '28%' }}
                      size="small"
                      suffixIcon={<Icon type="caret-down"/>}
                      defaultValue={this.state.size}
                      onSelect={value => {
                        this.setState({ size: value })
                        if (value !== 'Specific')
                          this.setState({
                            width: this.sizes[value].width,
                            height: this.sizes[value].height,
                          })
                      }}
                    >
                      {Object.keys(this.sizes).map(x => {
                        return <Option value={x} key={x}>{x}</Option>
                      })}
                      <Option value="Specific">{this.props.langData['Specific']}</Option>
                    </Select>
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={1}
                      value={this.state.width}
                      style={{ width: '36%', marginRight: 0 }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangePageSize('width', value)}
                      disabled={this.state.size !== 'Specific'}
                    />
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={1}
                      value={this.state.height}
                      style={{ width: '36%', marginRight: 0 }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangeSize('height', value)}
                      disabled={this.state.size !== 'Specific'}
                    />
                  </InputGroup>
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Page Size']}
                  </div>
                </div>}
                {this.state.selectedElement && <div style={{
                  width: 1,
                  height: 61,
                  background: '#e8e8e8',
                  float: 'left',
                  margin: '0px 20px 0px 0px',
                }}></div>}
                {this.state.selectedElement && <div style={{ padding: '10px 0px 0px 0px', float: 'left' }}>
                  <InputGroup style={{ width: 156 }} compact>
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={0}
                      value={this.state.selectedElement.x}
                      style={{ width: '50%', marginRight: 0, textAlign: 'right' }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangePosition('x', value)}
                    />
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={0}
                      value={this.state.selectedElement.y}
                      style={{ width: '50%', marginRight: 0 }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangePosition('y', value)}
                    />
                  </InputGroup>
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Location']}
                  </div>
                </div>}
                {this.state.selectedElement &&
                  <div style={{ width: 1, height: 61, background: '#e8e8e8', float: 'left', margin: '0 20px' }}></div>}
                {this.state.selectedElement && <div style={{ padding: '10px 0px 0px 0px', float: 'left' }}>
                  <InputGroup style={{ width: 160 }} compact>
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={0.01}
                      value={this.state.selectedElement.width}
                      style={{ width: '50%', marginRight: 0 }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangeSize('width', value)}
                    />
                    <InputNumber
                      size="small"
                      step={0.01}
                      min={0.01}
                      value={this.state.selectedElement.height}
                      style={{ width: '50%', marginRight: 0 }}
                      formatter={value => value.toString().replace('cm', '') + ' cm'}
                      parser={value => value.replace(' cm', '')}
                      onKeyDown={event => {
                        if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                          event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                      }}
                      onChange={value => this.onChangeSize('height', value)}
                    />
                  </InputGroup>
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Size']}
                  </div>
                </div>}
                <div style={{ width: 1, height: 61, background: '#e8e8e8', float: 'left', margin: '0 20px' }}></div>
                <div style={{ padding: '10px 0px 0px 0px', float: 'left', width: 70 }}>
                  <InputNumber
                    size="small"
                    step={1}
                    min={1}
                    style={{ width: '100%', textAlign: 'right' }}
                    value={this.state.selectedElement ? this.state.selectedElement.fontSize : this.state.source.style.fontSize}
                    formatter={value => value.toString().replace('pt', '') + ' pt'}
                    parser={value => value.replace(' pt', '')}
                    onKeyDown={event => {
                      if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                        event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                    }}
                    onChange={this.onChangeFontSize}
                  />
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Font Size']}
                  </div>
                </div>
                {this.state.selectedElement &&
                  <div style={{ width: 1, height: 61, background: '#e8e8e8', float: 'left', margin: '0 20px' }}></div>}
                {this.state.selectedElement && <div style={{ padding: '10px 0px 0px 0px', float: 'left' }}>
                  <RadioGroup size="small" onChange={this.onChangeTextAlign}
                              value={this.state.selectedElement.textAlign}>
                    <RadioButton value="left"><FontAwesome name="align-left"/></RadioButton>
                    <RadioButton value="center"><FontAwesome name="align-center"/></RadioButton>
                    <RadioButton value="right"><FontAwesome name="align-right"/></RadioButton>
                  </RadioGroup>
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Text Align']}
                  </div>
                </div>}
                {this.state.selectedElement &&
                  <div style={{ width: 1, height: 61, background: '#e8e8e8', float: 'left', margin: '0 20px' }}></div>}
                {this.state.selectedElement && <div style={{ padding: '10px 0px 0px 0px', float: 'left' }}>
                  <ButtonGroup size="small" style={{ width: '100%' }}>
                    <Button
                      style={{ border: '1px solid ' + (this.state.selectedElement.fontBold ? '#1890ff' : '#d9d9d9') }}
                      onClick={this.onChangeBold}
                    >
                      <FontAwesome name="bold"/>
                    </Button>
                    <Button
                      style={{ border: '1px solid ' + (this.state.selectedElement.fontItalic ? '#1890ff' : '#d9d9d9') }}
                      onClick={this.onChangeItalic}
                    >
                      <FontAwesome name="italic"/>
                    </Button>
                  </ButtonGroup>
                  <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                    {this.props.langData['Bold/Italic']}
                  </div>
                </div>}
                {(this.state.selectedElement && typeof this.state.selectedElement.show_currency !== 'undefined') &&
                  <div style={{ padding: '10px 0px 0px 0px', float: 'left', width: 140, textAlign: 'center' }}>
                    <Checkbox onChange={e => this.onChangeShowCurrency(e.target.checked)}
                              checked={this.state.selectedElement.show_currency}></Checkbox>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                      {this.props.langData['Show Currency']}
                    </div>
                  </div>
                }

                {(this.state.selectedElement && this.state.selectedElement.name === 'total_vat') &&
                  <div style={{ padding: '10px 0px 0px 0px', float: 'left', width: 140, textAlign: 'center' }}>
                    <Checkbox onChange={e => this.onChangeDetailyVat(e.target.checked)}
                              checked={this.state.selectedElement.detaily_vat}></Checkbox>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                      {this.props.langData['Detaily VAT']}
                    </div>
                  </div>
                }

                {(this.state.selectedElement && typeof this.state.selectedElement.date_format !== 'undefined') &&
                  <div style={{ width: 1, height: 61, background: '#e8e8e8', float: 'left', margin: '0 20px' }}></div>
                }
                {(this.state.selectedElement && typeof this.state.selectedElement.date_format !== 'undefined') &&
                  <div style={{ padding: '10px 0px 0px 0px', float: 'left', width: 126 }}>
                    <Select
                      size="small"
                      suffixIcon={<Icon type="caret-down"/>}
                      style={{ width: '100%' }}
                      defaultValue={this.state.selectedElement.date_format}
                      onSelect={this.onChangeDateFormat}
                    >
                      {Object.keys(this.props.langData['date-formats']).map(x => {
                        return <Option value={this.props.langData['date-formats'][x]} key={x}>{x}</Option>
                      })}
                    </Select>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: 12, height: 26, lineHeight: '26px' }}>
                      {this.props.langData['Date Format']}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 1 }} id="designer-row">
          <Col xs={18} sm={18} md={18} lg={18} xl={18}>
            <div style={{ width: '100%', height: 300, background: 'white' }}>
              <div id="designer-container"
                   style={{ width: '100%', height: 600, padding: 20, overflow: 'auto', background: '#d2d0cf' }}>
                <div style={{
                  width: this.state.width + 'cm',
                  height: this.state.height + 'cm',
                  background: 'white',
                  position: 'relative',
                  margin: '0 auto',
                }} id="designer-content"
                >
                  <img
                    src={this.state.background}
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      opacity: this.state.background ? 0.5 : 0,
                    }}
                  />
                  {this.state.source.elements.map(x => {
                    let source = this.state.source
                    return (
                      <div
                        className="element"
                        data-id={x.id}
                        key={x.name}
                        onMouseDown={e => {
                          let target = $(e.target)
                          if ( ! target.hasClass('element'))
                            target = $(e.target).parents('.element')
                          let _x = (target.offset().left - $('#designer-content').offset().left) / 37.795275591
                          let _y = (target.offset().top - $('#designer-content').offset().top) / 37.795275591
                          let width = target.width() / 37.795275591
                          let height = target.height() / 37.795275591
                          let punto = 3 / 4 * parseFloat(target.css('font-size').replace('px', ''))
                          let selectedElement = {
                            target: e.target,
                            name: x.name,
                            x: _x.toFixed(2),
                            y: _y.toFixed(2),
                            width: width.toFixed(2),
                            height: height.toFixed(2),
                            textAlign: x.style.textAlign || source.style.textAlign,
                            fontBold: target.css('font-weight') === '700',
                            fontItalic: target.css('font-style') === 'italic',
                            fontSize: punto.toFixed(0),
                            id: x.id,
                          }
                          if (typeof x.show_currency !== 'undefined')
                            selectedElement.show_currency = x.show_currency
                          if (typeof x.detaily_vat !== 'undefined')
                            selectedElement.detaily_vat = x.detaily_vat
                          if (typeof x.date_format !== 'undefined')
                            selectedElement.date_format = x.date_format
                          this.setState({ selectedElement: selectedElement })
                        }}
                        style={{
                          position: 'absolute',
                          fontSize: `${x.style.fontSize || source.style.fontSize}pt`,
                          left: x.style.x + 'cm',
                          top: x.style.y + 'cm',
                          boxSizing: 'border-box',
                          boxShadow: '0px 0px ' +
                            ((this.state.selectedElement && this.state.selectedElement.id === x.id) ? '2' : '1') + 'px ' +
                            ((this.state.selectedElemen && this.state.selectedElement.id === x.id) ? 'black' : 'red'),
                          overflow: 'hidden',
                          width: x.style.width + 'cm',
                          height: x.style.height + 'cm',
                          fontStyle: x.style.fontItalic ? 'italic' : 'normal',
                          fontWeight: x.style.fontBold ? 'bold' : 'normal',
                          textAlign: x.style.textAlign || source.style.textAlign || 'left',
                        }}
                      >
                        <Icon
                          type="close-square-o"
                          style={{ position: 'absolute', right: 0, top: 0, display: 'none', cursor: 'pointer' }}
                          onClick={() => this.deleteElement(x.id)}
                        />
                        {x.name !== 'product_table' && (
                          x.name === 'date' ?
                            <div>
                              {moment().format(x.date_format)}
                            </div>
                            :
                            <div>
                              {this.contents[this.props.lang][x.name] + (x.show_currency ? this.contents[this.props.lang]['show_currency'] : '')}
                            </div>
                        )}
                        {x.name === 'product_table' &&
                          <table className="product-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_x, y) => {
                              return (
                                <tr key={y}>
                                  {x.columns.map((x, i) => {
                                    return (
                                      <td
                                        style={{
                                          fontWeight: 'inherit',
                                          border: '1px solid rgba(0, 0, 0, 0.1)',
                                          overflow: 'hidden',
                                        }}
                                        key={i}
                                        data-name={x.name}
                                      >
                                        {this.columnsData[this.props.lang][x.name]}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })}
                            </tbody>
                          </table>
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Collapse
              bordered={false} activeKey={this.state.collapseActiveKey}
              style={{ marginTop: 20, background: 'transparent' }}
              onChange={this.onChangeRightMenu}
            >
              <Panel header={this.props.langData['Customer Information']} key="customer-information"
                     style={customPanelStyle}>
                <List
                  dataSource={this.state.elements['customer-information'].filter(x => ! x.added)}
                  renderItem={item => {
                    return (
                      <List.Item style={{ padding: 0 }}>
                        <div className="menu-element" style={{ width: '100%', padding: '8px 14px' }}
                             data-menu="customer-information" data-name={item.name}>
                          <Icon type={item.icon} style={{ lineHeight: '21px' }}/>
                          <span style={{ marginLeft: 8 }}>
														{this.props.langData[
                              (item.name === 'phone_1' || item.name === 'phone_2') ? 'Phone' :
                                item.name === 'e_mail' ? 'E-Mail' :
                                  item.name === 'id_or_tax_identification_number' ? 'Id/Tax Identification Number' :
                                    startCase(item.name.replace('_', ' '))
                              ]}
													</span>
                        </div>
                      </List.Item>
                    )
                  }}
                />
              </Panel>
              <Panel header={this.props.langData['Product Table']} key="product-table" style={customPanelStyle}>
                { ! this.state.elements['product-table'].added &&
                  <div className="menu-element" style={{ width: '100%', padding: '8px 14px' }} data-menu="product-table"
                       data-name="product_table">
                    <Icon type="star-o" style={{ lineHeight: '21px' }}/>
                    <span style={{ marginLeft: 8 }}>{this.props.langData['Product Table']}</span>
                  </div>}
                {this.state.elements['product-table'].added &&
                  <CheckboxGroup
                    value={(() => {
                      let columns = []
                      this.state.source.elements.find(e => e.name === 'product_table').columns.map(x => columns.push(x.name))
                      return columns
                    })()}
                    onChange={this.onChangeProductColumn}
                    style={{ width: '100%' }}
                  >
                    <ul id="menu-product-columns" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                      {this.state.elements['product-table'].columns.map((x, i) => {
                        return (
                          <li className="menu-product-column"
                              style={{ width: '100%', padding: '8px 14px', borderBottom: '1px solid #e8e8e8' }} key={i}
                              data-name={x}>
                            <Checkbox value={x}>
                              {this.props.langData[x.name === 'id_or_tax_identication_number' ? 'Id/Tax Identification Number' : startCase(x.replace('_', ' '))]}
                            </Checkbox>
                          </li>
                        )
                      })}
                    </ul>
                  </CheckboxGroup>}
              </Panel>
              <Panel header={this.props.langData['Waybill Information']} key="waybill-information"
                     style={customPanelStyle}>
                <List
                  dataSource={this.state.elements['waybill-information'].filter(x => ! x.added)}
                  renderItem={item => {
                    return (
                      <List.Item style={{ padding: 0 }}>
                        <div className="menu-element" style={{ width: '100%', padding: '8px 14px' }}
                             data-menu="waybill-information" data-name={item.name}>
                          <Icon type={item.icon} style={{ lineHeight: '21px' }}/>
                          <span
                            style={{ marginLeft: 8 }}>{this.props.langData[startCase(item.name.replace('_', ' '))]}</span>
                        </div>
                      </List.Item>
                    )
                  }}
                />
              </Panel>
              <Panel header={this.props.langData['Textbox']} key="textbox" style={customPanelStyle}>
                <Input placeholder={this.props.langData['Press ENTER, after entering the text']}
                       onPressEnter={this.onPressEnter}/>
                <div style={{ width: '100%', padding: '8px 14px' }}>
                  <List
                    dataSource={this.state.elements['textbox'].filter(x => ! x.added)}
                    renderItem={item => {
                      return (
                        <List.Item style={{ padding: 0 }}>
                          <div className="menu-element" style={{ width: '100%', padding: '8px 14px' }}
                               data-menu="textbox" data-name={item.name}>
                            <Icon type={item.icon} style={{ lineHeight: '21px' }}/>
                            <span style={{ marginLeft: 8 }}>{item.name}</span>
                          </div>
                        </List.Item>
                      )
                    }}
                  />
                </div>
              </Panel>
            </Collapse>
            <Button type="primary" onClick={this.onSubmit}
                    style={{ width: '100%' }}>{this.props.langData['Save']}</Button>
          </Col>
        </Row>
        <input type="file" accept="image/*" onChange={this.onChangeFileUpload} style={{ display: 'none' }}/>
      </Card>
    )
  }

}

WaybillDesign.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  sizeWidth: PropTypes.number,
  match: PropTypes.object,
  callback: PropTypes.func,
  source: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillDesign)
