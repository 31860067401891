import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Col, Row, Icon } from 'antd'
import { Link } from 'react-router-dom'

import tsoftlogo from './../../../../tsoft.svg'
import routes from '../../../../routes'
import { hasLicence } from '../../../../utils'
import { licenceMethods } from '../../../../enums'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    licences: state.AuthReducer.licences,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function UserSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add()

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  return (
    <Row gutter={26} style={{ marginTop: 23 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Link to={routes.GENERAL_SETTINGS}>
          <Card hoverable style={{ marginBottom: 16 }}>
            <Card.Meta
              avatar={<Icon style={{ fontSize: 30, marginTop: 12 }} type="setting"/>}
              title={langData['General Settings']}
              description={langData['You can configure your general settings using this menu']}
              style={{ color: '#f37054' }}
            />
          </Card>
        </Link>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Link to={routes.PRODUCT_SETTINGS}>
          <Card hoverable style={{ marginBottom: 16 }}>
            <Card.Meta
              avatar={<Icon style={{ fontSize: 30, marginTop: 12 }} type="transaction"/>}
              title={langData['Product Settings']}
              description={langData['You can configure your product settings using this menu']}
              style={{ color: '#666666' }}
            />
          </Card>
        </Link>
      </Col>

      {hasLicence(props.licences, licenceMethods.TSOFT_INTEGRATION) && (
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Link to={routes.TSOFT_WS_SETTINGS}>
            <Card hoverable style={{ marginBottom: 16 }}>
              <Card.Meta
                avatar={<img src={tsoftlogo} alt="Tsoft"/>}
                title={langData['T-Soft Web Service Settings']}
                description={langData['You can configure your T-Soft Web Service settings using this menu']}
                style={{ color: 'blue' }}
              />
            </Card>
          </Link>
        </Col>
      )}

      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Link to={routes.INTEGRATION_SETTINGS}>
          <Card hoverable style={{ marginBottom: 16 }}>
            <Card.Meta
              avatar={<Icon style={{ fontSize: 30, marginTop: 12 }} type="file-protect"/>}
              title={langData['Integration Web Service Settings']}
              description={langData['You can configure your integration settings using this menu']}
            />
          </Card>
        </Link>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Link to={routes.INVOICE_SETTINGS}>
          <Card hoverable style={{ marginBottom: 16 }}>
            <Card.Meta
              avatar={<Icon style={{ fontSize: 30, marginTop: 12 }} type="file-text"/>}
              title={langData['Invoice & E-Invoice & E-Archive Settings']}
              description={langData['You can configure your invoice settings using this menu']}
              style={{ color: '#a3abb1' }}
            />
          </Card>
        </Link>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
        <Link to={routes.DESPATCH_SETTINGS}>
          <Card hoverable style={{ marginBottom: 16 }}>
            <Card.Meta
              avatar={<Icon style={{ fontSize: 30, marginTop: 12 }} type="file-sync"/>}
              title={langData['E-Waybill Invoice Settings']}
              description={langData['You can configure your E-Waybill settings using this menu']}
            >
            </Card.Meta>
          </Card>
        </Link>
      </Col>
    </Row>
  )

}


UserSettings.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  sizeWidth: PropTypes.number,
  licences: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
