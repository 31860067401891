import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formErrorFieldsNameLocalize, successToast } from '../../helpers/helper'
import { generatePath, Link } from 'react-router-dom'
import moment from 'moment'
import { Form, Icon, Input, Button, Card, Divider, DatePicker, InputNumber, Spin } from 'antd'

import { getCurrencyWithId } from '../../data/Currency'
import AddUpSelect from './../Utils/AddUpSelect'
import routes from '../../routes'
import { CloseOutlined, PayCircleOutlined } from '@ant-design/icons'
import { postPanelApi } from '../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 17 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

class NewMoneyIOForm extends Component {

  state = {
    loading: false,
    submitting: false,

    categories: [],
    safe_name: null,
    currency_symbol: 'TL',
    increment: 1,
  }

  componentDidMount() {

    this.setState({
      loading: true,
    })

    postPanelApi('/safe/create-io-open', { safe_id: this.props.safe_id }).then(result => {

      if (result) {

        this.setState({
          categories: result.categories,
          increment: result.settings.increment,
          safe_name: result.safe.name,
          currency_symbol: getCurrencyWithId(result.safe.currency_id).symbol,
        })

      }

      this.setState({
        loading: false,
      })

    })

  }

  onSubmit = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      // kayıt aşaması

      this.setState({
        submitting: true,
      })

      postPanelApi('/safe/create-io-save', {
        type: this.props.type,
        safe_id: this.props.safe_id,
        amount: values.amount,
        category_id: values.category_id,
        date: values.date.toISOString(true),
        explanation: values.explanation,
      }).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
            id: result.transaction_id,
            type: this.props.type,
          }))

        } else {

          this.setState({
            submitting: false,
          })

        }

      })


    })
  }

  createTransactionCategory = (name) => {

    postPanelApi('/transaction/category/create', { name }).then(result => {

      if (result) {

        this.setState(state => {

          const categories = [...state.categories, {
            category_id: result.category_id,
            name: result.name,
          }]

          return {
            categories,
          }

        })

      }

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    const form = () => (
      <Form hideRequiredMark={true} onSubmit={this.onSubmit} className="form-label-default no-indent">

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Safe']}</p>}
                     {...formItemLayout}
          >
            <Input value={this.state.safe_name} disabled={true}/>
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Amount']}</p>}
                     {...formItemLayout}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              {getFieldDecorator('amount', {
                initialValue: 1,
                rules: [{ required: true }],
              })(
                <InputNumber
                  size="large"
                  className="w-100"
                  step={this.state.increment.toFixed(4)}
                  min={0.0001}
                  decimalSeparator=","
                />,
              )}
              <Button disabled size="large">{this.state.currency_symbol}</Button>
            </Input.Group>
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Category']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('category_id')(
              <AddUpSelect
                data={this.state.categories.map(x => ({
                  key: x.category_id,
                  value: x.category_id,
                  content: x.name,
                }))}
                onAddUp={value => this.createTransactionCategory(value)}
              />,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Date']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('date', {
              initialValue: moment(),
              rules: [{ required: true }],
            })(
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm' }}
                size="large"
                className="w-100"
                suffixIcon={<Icon type="calendar" theme="filled"/>}
              />,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item hasFeedback {...formItemLayoutNoLabel} >
            {getFieldDecorator('explanation', { rules: [{ max: 250 }] })(
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 2, maxRows: 5 }}
                maxLength={250}
                placeholder={this.props.langData['Explanation']}
              />,
            )}
          </Form.Item>
        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              loading={this.state.submitting}
              htmlType="submit"
              className="default-button"
            >
              {this.props.langData['Save']}
            </Button>
            <Link to={generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.props.safe_id })}>
              <Button
                size="large"
                style={{ marginRight: 8 }}
                loading={this.state.submitting}
                className="default-button danger2"
              >
                {this.props.langData['Close']}
              </Button>
            </Link>
          </Form.Item>
        </div>

      </Form>
    )

    if (this.props.sizeWidth <= 576) {

      if (this.state.loading) {
        return <Spin/>
      }

      return form()
    }

    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        title={
          <span><PayCircleOutlined/> &nbsp;{this.props.type === 'money-input' ? this.props.langData['Input'] : this.props.langData['Output']}</span>}
        bordered={false}
        loading={this.state.loading}
        extra={
          <Link to={generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.props.safe_id })}>
            <CloseOutlined/>
          </Link>
        }
      >
        {form()}
      </Card>
    )

  }

}

NewMoneyIOForm.propTypes = {
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,

  history: PropTypes.object,

  form: PropTypes.object,

  safe_id: PropTypes.number,
  type: PropTypes.string,
}

export default NewMoneyIOForm
