import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast, confirm, formErrorFieldsNameLocalize, graphQLErrorsToToast } from './../../../helpers/helper'
import { deleteData } from './../../../helpers/trait'
import { connect } from 'react-redux'
import { Form, Icon, Input, InputNumber, Button, Card, Divider, Select, Checkbox, Menu, Dropdown } from 'antd'
import InvoiceDesign from './../InvoiceDesign/InvoiceDesign'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'

const { Option } = Select
const InputGroup = Input.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const READ_QUERY = (
  gql`
    query($template_id: Int!){
      template(template_id:$template_id){
        template_id
        width
        height
        source
        default
        name
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation($template_id: Int!, $template: TemplateUpdateInput!){
      template: templateUpdate(template_id: $template_id, template: $template){
        template {
          template_id
          name
          source
          default
          width
          height
        }

        informative{
          messages
        }
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation($template: TemplateCreateInput!){
      template: templateCreate(template: $template){
        template {
          template_id
          name
          source
          default
          width
          height
        }

        informative{
          messages
        }
      }
    }
  `
)

class TemplateForm extends Component {

  sizes = {
    'A4': { width: 21, height: 29.7 },
    'A5': { width: 14.8, height: 21 },
  }

  state = {
    default: false,
    addedTag: [],
    design: false,
    size: 'A4',
    source: null,
  }

  colors = {
    transaction: 'magenta', islem: 'magenta',
    customer: 'red', cari: 'red',
  }

  constructor(props) {
    super(props)
    this.deleteData = deleteData('template', 'template_id').bind(this)
  }

  onSubmit = mutate => {
    this.props.form.validateFields(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      values.source = this.state.source

      delete values.size
      let variables = {
        template: values,
      }

      if (this.props.template_id)
        variables.template_id = this.props.template_id

      try {
        const result = await mutate({ variables })
        if (result !== undefined) {
          toast(true, result.data.template.informative.messages)
          this.props.history.push('/' + this.props.langData.route['template/list'])
        }
      } catch (err) {
        graphQLErrorsToToast(err)
      }
    })
  }

  invoiceDesignCallback = (source, size, width, height) => {
    this.setState({
      size: size,
      source: source,
      design: false,
    })
    this.props.form.setFieldsValue({
      width: width,
      height: height,
    })
  }

  design = () => {
    this.setState({ design: true })
  }

  delete = () => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteData(this.props.template_id)
    })
  }

  menuOnClick = item => {
    this[item.key]()
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 14 },
        lg: { span: 8 },
      },
    }

    const formItemLayoutNoLabel = {
      labelCol: {
        xs: { span: 0 },
        sm: { span: 0 },
        md: { span: 0 },
        lg: { span: 0 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 20 },
        lg: { span: 16 },
      },
    }

    const { getFieldDecorator } = this.props.form

    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={READ_QUERY}
        variables={{ template_id: this.props.template_id }}
        skip={ ! this.props.template_id}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if (data && ! loadingQuery) {
            if (this.props.template_id && ! data.template) {
              toast(false, this.props.langData['x not found']('Template'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data) {
              let size = 'Specific'
              for (let x in this.sizes) {
                if (this.sizes[x].width === data.template.width && this.sizes[x].height === data.template.height) {
                  size = x
                  break
                }
              }

              if (this.props.template_id) {
                this.setState({
                  data: cloneDeep(data.template),
                  size: size,
                  default: cloneDeep(data.template.default),
                  source: cloneDeep(data.template.source),
                })

                this.props.form.setFieldsValue({
                  name: data.template.name,
                  width: data.template.width,
                  default: data.template.default,
                  height: data.template.height,
                })
              }
            }
          }

          return (
            <Mutation
              mutation={! this.props.template_id ? CREATE_QUERY : UPDATE_QUERY}
            >
              {(mutate, { loading: loadingMutation }) => (
                <div>
                  {this.state.design && <InvoiceDesign
                    callback={this.invoiceDesignCallback}
                    source={this.state.source}
                    width={this.props.form.getFieldValue('width')}
                    height={this.props.form.getFieldValue('height')}
                    size={this.state.size}
                  />}
                  <Card loading={this.props.template_id ? ! this.state.data : false} bodyStyle={{ padding: '0px' }}
                        style={{ display: this.state.design ? 'none' : 'block' }} title={
                    <span><Icon type={! this.props.template_id ? 'file-add' : 'edit'}/> &nbsp;{
                      ! this.props.template_id ? this.props.langData['New Template'] : this.props.langData['Update Template']
                    }</span>
                  } bordered={false}
                        extra={
                          this.props.template_id && (
                            <Dropdown overlay={menu} trigger={['click']}>
                              <Button>
                                {this.props.langData['Actions']} <Icon type="down"/>
                              </Button>
                            </Dropdown>
                          )
                        }
                  >

                    <Form
                      hideRequiredMark={true}
                      onSubmit={e => {
                        e.preventDefault()
                        this.onSubmit(mutate)
                      }}
                      className="form-label-default"
                    >

                      <div className="form-block">

                        <Form.Item hasFeedback label={(
                          <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('name', {
                            rules: [{ max: 20 }, { required: true }],
                            validateTrigger: false,
                          })(
                            <Input size="large"/>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="check-circle-o"/><p>{this.props.langData['Default']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('default', {
                            valuePropName: 'checked',
                            initialValue: false,
                            validateTrigger: false,
                          })(
                            <Checkbox
                              onChange={item => this.setState({ default: item.target.checked })}
                            >
                              {this.props.langData[this.state.default ? 'Yes' : 'No']}
                            </Checkbox>,
                          )}
                        </Form.Item>

                        <Form.Item label={(<span><Icon type="arrows-alt"/><p>{this.props.langData['Size']}</p></span>)}
                                   {...formItemLayout}
                        >
                          <InputGroup compact>
                            {getFieldDecorator('size', {
                              initialValue: this.state.size,
                              validateTrigger: false,
                            })(
                              <Select
                                style={{ width: '24%' }}
                                suffixIcon={<Icon type="caret-down"/>}
                                size="large"
                                onSelect={value => {
                                  this.setState({ size: value })
                                  if (value !== 'Specific')
                                    this.props.form.setFieldsValue(this.sizes[value])
                                }}
                              >
                                {Object.keys(this.sizes).map(x => {
                                  return <Option value={x} key={x}>{x}</Option>
                                })}
                                <Option value="Specific">{this.props.langData['Specific']}</Option>
                              </Select>,
                            )}
                            {getFieldDecorator('width', {
                              initialValue: 21,
                              validateTrigger: false,
                            })(
                              <InputNumber
                                decimalSeparator=","
                                size="large"
                                step={0.01}
                                min={1}
                                style={{ width: '38%', marginRight: 0 }}
                                formatter={value => value.toString().replace('cm', '') + ' cm'}
                                parser={value => value.replace(' cm', '')}
                                onKeyDown={event => {
                                  if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                                    event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                                }}
                                disabled={this.state.size !== 'Specific'}
                              />,
                            )}
                            {getFieldDecorator('height', {
                              initialValue: 29.7,
                              validateTrigger: false,
                            })(
                              <InputNumber
                                decimalSeparator=","
                                size="large"
                                step={0.01}
                                min={1}
                                style={{ width: '38%', marginRight: 0 }}
                                formatter={value => value.toString().replace('cm', '') + ' cm'}
                                parser={value => value.replace(' cm', '')}
                                onKeyDown={event => {
                                  if (event.keyCode === 8 && event.target.value.length === event.target.selectionStart)
                                    event.target.setSelectionRange(event.target.value.length - 3, event.target.value.length - 3)
                                }}
                                disabled={this.state.size !== 'Specific'}
                              />,
                            )}
                          </InputGroup>

                        </Form.Item>

                      </div>

                      <Divider/>

                      <div className="form-block">
                        <Form.Item {...formItemLayoutNoLabel}>
                          {this.state.source &&
                            <Button type="primary" size="large" loading={loadingMutation} htmlType="submit"
                                    className="default-button" style={{ marginLeft: 10 }}>
                              {this.props.langData['Save']}
                            </Button>
                          }
                          <Button size="large" icon="layout" loading={loadingMutation} className="default-button"
                                  onClick={this.design}>
                            {this.props.langData['Design']}
                          </Button>
                        </Form.Item>
                      </div>

                    </Form>
                  </Card>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

TemplateForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  template_id: PropTypes.number,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

export default connect(mapStateToProps)(TemplateForm)
