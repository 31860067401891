import React, { Component } from 'react'
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom'
import routePaths from './../localize/routePaths'
import tr_TR from 'antd/lib/locale/tr_TR'

// components
import Login from './Login/Login'
import Register from './Register/Register'
import RegisterStep from './RegisterStep/RegisterStep/RegisterStep'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import NewPassword from './NewPassword/NewPassword'
import Main from './Layout/Main'

import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'
import PropTypes from 'prop-types'
import EmailVerification from './Manager/ManagerEmailVerification/ManagerEmailVerification'
import routes from '../routes'
import LoginAsManager from './LoginAsManager'

class App extends Component {

  render() {

    return (
      <ConfigProvider locale={tr_TR}>
        <BrowserRouter>
          <RouterSwitch>
            <Route exact path={routePaths['login']} component={Login}/>
            <Route exact path={routePaths['forgot-my-password']} component={ForgotPassword}/>
            <Route exact path={routes.SET_NEW_PASSWORD} component={NewPassword}/>
            <Route exact path={routePaths['register/:commissioner_e_mail?']} component={Register}/>
            <Route exact path={routes.VERIFY_EMAIL} component={EmailVerification}/>
            <Route exact path={routes.LOGIN_AS_MANAGER} component={LoginAsManager}/>
            <Route path={routes.REGISTER_STEPS} component={RegisterStep}/>
            <Route component={Main}/>
          </RouterSwitch>
        </BrowserRouter>
      </ConfigProvider>
    )

  }

}

App.propTypes = {
  history: PropTypes.func,
  languageChange: PropTypes.func,
  langData: PropTypes.object,
  match: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
})(App)
