import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Row, Col, Card, List, Tag, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode, getCurrencyWithId } from '../../../data/Currency'
import { compile } from 'path-to-regexp'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import '../../Starting/Starting.css'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    fixed_exchange_rate: state.GlobalReducer.fixed_exchange_rate,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction Report', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($yearFirstDay:DateTime!,$start_date: Date!, $end_date: Date!, $sorting: InvoiceSorting, $paging: Paging, $currency: Currency, $date: Date!){
      invoiceList(sorting: $sorting, paging: $paging){
        transactions{
          __typename
          ... on SalesInvoice{
            transaction_id
            grand_total
            discount_type
            currency
            invoice_country
            date
            expiry_date
            category{
              name
            }
            customer{
              name
              e_mail
            }
            return_invoices {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
              e_invoice_type
              e_invoice_id
              e_invoice_uuid
            }
            currency
            series
            total_vat
            remaining
            additional_charges{
              additional_charge{
                name
              }
              total
            }
          }
          ... on PurchaseInvoice{
            transaction_id
            grand_total
            discount_type
            currency
            invoice_country
            date
            expiry_date
            category{
              name
            }
            customer{
              name
              e_mail
            }
            return_invoices {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
            }
            currency
            series
            total_vat
            remaining
            additional_charges{
              additional_charge{
                name
              }
              total
            }
          }
        }

        page_info{
          total_count
        }
      }

      reports {
        invoiceCategoryGroup: invoice {
          count(group: [category_id]) {
            results {
              category_id
              category {
                name
              }
              count
            }
          }
        }

        movingInvoice: invoice{
          moving(field: expiry_date, interval: { start_date: $start_date, end_date: $end_date}, moving: 1){
            sum(field:grand_total, group: [
              currency
              type
            ]){
              results{
                sum
                type
                currency
              }
            }
          }
        }

        invoiceCategorySum: invoice {
          sum(field:total,group:[category_id]) {
            results {
              category_id
              category {
                name
              }
              sum
            }
          }
        }
        topSales:invoice {
          sum(field: grand_total, group: [customer_id], filter: {AND: [{type: {eq: SalesInvoice}, date: {gte: $yearFirstDay}}]}, sorting_sum: DESC, paging: {offset: 0, limit: 7}) {
            results {
              customer {
                name
              }
              sum
            }
          }
        }
      }
      currencyRates (currency: $currency, date: $date){
        currency_rates {
          try
          usd
          eur
          gbp
          rub
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

class MyTag extends Component {

  state = { checked: true }

  handleChange = checked => {
    this.setState({ checked })
  }

  render() {
    return (
      <Checkbox {...this.props} checked={true}
                style={{ width: '100%', paddingLeft: 20, paddingRight: 20, alignItems: 'center' }}/>
    )
  }
}

class TransactionReport extends Component {

  currencyId = 1

  state = {
    data: null,
    pagination: {
      pageSize: 3,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 5,
    },
    sorting: null,
    filter: null,
    invoiceCategoryGroup: [],
    topSales: [],
    invoiceCategorySum: null,
    nextDaysData: { input: [], output: [] },
    splineDistribution: null,
    invoiceCategoryColumns: null,
    checked: true,
    currency: {},
  }

  componentDidMount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_add(routes.TRANSACTION_REPORT)
    this.setState({
      invoiceCategoryColumns: [
        {
          title: this.props.langData['Category'],
          fieldName: 'category',
          fieldLabel: 'Category',
          subFields: ['name'],
          dataIndex: 'category',
          key: 'category',
          align: 'center',
          render: record => record ? record.name : this.props.langData['Uncategorized'],
        },
        {
          title: this.props.langData['Total'],
          fieldName: 'sum',
          fieldLabel: 'Sum',
          dataIndex: 'sum',
          sorter: false,
          align: 'center',
          key: 'sum',
        },
      ],
    })
  }

  componentWillUnmount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  SplineOnChange = (e) => {
    this.setState({ splineDistribution: null })
    let distribution = []
    let startDataDay = 15, endDateDay = 16
    if (e.target.value === 'W') {
      startDataDay = 6
      endDateDay = 6
      distribution.push(`'startDate':${startDataDay},'endDate':${endDateDay}`)
    } else if (e.target.value === 'M') {
      startDataDay = 15
      endDateDay = 16
      distribution.push(`'startDate':${startDataDay},'endDate':${endDateDay}`)
    } else if (e.target.value === 'Y') {
      startDataDay = 336
      endDateDay = 366
      distribution.push(`'startDate':${startDataDay},'endDate':${endDateDay}`)
    }
    this.setState({ splineDistribution: cloneDeep(distribution) })
  }
  rowItemClick = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['transaction/detail/:id'])({ id: record.transaction_id }),
    )
  }

  render() {
    const invoiceOptions = {
      chart: {
        type: 'pie',
        height: 290,
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 100,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: { enabled: false },
          colors: ['#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        },
        series: {
          cursor: 'bar',
          events: {},
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          `<td style="padding:0"><b>{point.y}</b> ${this.props.langData['Piece']} ${this.props.langData['Invoice']}</td></tr>`,
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      title: {
        text: this.props.langData['Invoice Categories'],
        useHtml: true,
        style: { color: '#999593', fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase' },
      },
      series: [{
        name: 'Toplam',
        showInLegend: true,
        tooltip: { enabled: false },
        data: this.state.invoiceCategoryGroup[0],
      }],
      credits: { enabled: false },
    }

    const mostSellerOptions = {
      chart: {
        type: 'pie',
        height: 290,
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 100,
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          dataLabels: { enabled: false },
          colors: ['#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
        pointFormat: `<tr><td style="color:{series.color};padding:0">${this.props.langData['Total']}: <b>{point.y} {point.custom}</b></td>`,
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      title: {
        text: this.props.langData['Top Customers'] + ' (' + moment().format('YYYY') + ')',
        useHtml: true,
        style: { color: '#999593', fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase' },
      },
      series: [{
        showInLegend: true,
        tooltip: { enabled: false },
        data: this.state.topSales,
      }],
      credits: { enabled: false },
    }

    const monthlySellerOptions = {
      chart: {
        type: 'spline',
        height: 280,
      },
      title: {
        style: { display: 'none' },
      },
      tooltip: {
        crosshairs: false,
        shared: true,
        formatter: (() => {
          let langData = this.props.langData
          let symbol = getCurrencyWithId(this.currencyId).symbol
          return function () {
            return `
							<b>${this.x === moment().format('YYYY-MM-DD') ? langData['Today'] : this.x}</b> <br/>
							${langData['Input']}: <b>${this.points[0] && toLocaleString(this.points[0].y)} ${symbol}</b> <br/>
							${langData['Output']}: <b>${this.points[1] && toLocaleString(Math.abs(this.points[1].y))} ${symbol}</b>
						`
          }
        })(),
      },
      xAxis: {
        categories: (() => {
          let data = []
          for (let i = -15; i < 16; i++) {
            let date
            if (i === 0)
              date = this.props.langData['Today']
            else
              date = moment().locale(this.props.lang).add(i, 'days').format('DD MMMM')
            data.push(date)
          }
          return data
        })(),
        plotBands: [{
          from: 15.5,
          to: 14.5,
          color: '#3b599973',
        }],
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 0.5,
          },
          states: {
            hover: {
              lineWidth: 3,
            },
          },
          showInLegend: false,
        },
      },
      pointInterval: 3600000, // one hour
      pointStart: Date.UTC(2019, 8, 4, 0, 0, 0),
      series: [
        {
          name: this.props.langData['Input'],
          data: this.state.nextDaysData.input,
        }, {
          name: this.props.langData['Output'],
          marker: { symbol: 'diamond' },
          data: this.state.nextDaysData.output,
        },
      ],
      credits: { enabled: false },
    }
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{
          yearFirstDay: moment().startOf('year').format('YYYY-MM-DD hh:MM'),
          start_date: moment().subtract(15, 'days').format('Y-MM-DD'),
          end_date: moment().add(16, 'days').format('Y-MM-DD'),
          filter: this.state.filter,
          sorting: this.state.sorting,
          paging: this.state.paging,
          date: moment(),
        }}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data) {

              this.currencyId = data.legacyData.currency_id

              let datas = []
              let topSalesDatas = []
              let groupResult = data.reports.invoiceCategoryGroup.count.results
              for (let c = 0; c < groupResult.length; c++) {
                let resu = {
                  name: groupResult[c].category ? groupResult[c].category.name : this.props.langData['Uncategorized'],
                  y: groupResult[c] ? groupResult[c].count : 0,
                  custom: groupResult[c].category_id === null ? 0 : groupResult[c].category_id,
                }
                datas.push(resu)
              }
              let topSalesResult = data.reports.topSales.sum.results
              for (let c = 0; c < topSalesResult.length; c++) {
                let resu = {
                  name: topSalesResult[c].customer ? topSalesResult[c].customer.name : 'İsimsiz Müşteri',
                  y: topSalesResult[c] ? topSalesResult[c].sum : 0,
                  custom: getCurrencyWithCode(this.props.fixed_exchange_rate).symbol,
                }
                topSalesDatas.push(resu)
              }
              this.state.invoiceCategoryGroup.push(datas)
              this.setState({
                invoiceCategoryGroup: cloneDeep(this.state.invoiceCategoryGroup),
                data: cloneDeep(data.invoiceList.transactions),
                invoiceCategorySum: cloneDeep(data.reports.invoiceCategorySum.sum.results),
                topSales: cloneDeep(topSalesDatas),
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
                currency: data.currencyRates.currency_rates,
              })

              if ( ! this.state.nextDaysData.length) {
                let nextDaysData = { input: [], output: [] }
                for (let x in data.reports.movingInvoice.moving.sum) {
                  let nextData = data.reports.movingInvoice.moving.sum[x].results
                  if ( ! nextData.length) {
                    nextDaysData.input.push(0)
                    nextDaysData.output.push(0)
                  } else {
                    let input = 0
                    let output = 0
                    nextData.map(x => {
                      if (x.type === 'SalesInvoice') {
                        if (x.currency === this.props.fixed_exchange_rate) {
                          input += parseFloat(x.sum.toFixed(4))
                        } else {
                          input += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
                        }
                      } else {
                        if (x.currency === this.props.fixed_exchange_rate) {
                          output -= x.sum
                        } else {
                          output -= parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
                        }
                      }
                    })

                    nextDaysData.input.push(input)
                    nextDaysData.output.push(output)
                  }
                }
                this.setState({
                  nextDaysData: cloneDeep(nextDaysData),
                })
              }
            }
          } else if (data && this.state.data && JSON.stringify(data.invoiceList.transactions) !== JSON.stringify(this.state.data)) {
            this.setState({
              data: cloneDeep(data.invoiceList.transactions),
              pagination: {
                ...this.state.pagination,
                total: data.invoiceList.page_info.total_count,
              },
            })
          }
          this.props.refetch && refetch()
          return (
            <div>
              <Row gutter={8}>
                <Col xs={24} sm={24} md={24} l={12} xl={12} style={{ marginBottom: 10 }}>
                  <Card>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={invoiceOptions}
                    ></HighchartsReact>
                    <List
                      size="small"
                      dataSource={this.state.invoiceCategorySum || []}

                      renderItem={item => (
                        <List.Item key={item.sum}
                                   style={{ marginLeft: 5, marginRight: 5, paddingTop: 5, borderRadius: 10 }}
                                   className={'list-item list-item-' + (item.category ? 'category' : 'nonCategory')}>
                          <MyTag>
                            <span>{item.category ? item.category.name : this.props.langData['Uncategorized']}</span><span
                            style={{ float: 'right' }}>{item.sum && `${toLocaleString(item.sum)} ₺`}</span>
                          </MyTag>
                        </List.Item>
                      )}
                    >
                    </List>
                  </Card>
                </Col>

                <Col xs={24} sm={24} md={24} l={12} xl={12} style={{ marginBottom: 10 }}>
                  <Card>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={mostSellerOptions}
                    ></HighchartsReact>
                    <List
                      size="small"
                      dataSource={this.state.topSales || []}
                      renderItem={item => (
                        <List.Item key={item.y}
                                   style={{ marginLeft: 5, marginRight: 5, paddingTop: 5, borderRadius: 10 }}
                                   className={'list-item list-item-' + (item.name ? 'category' : 'nonCategory')}>
                          <MyTag>
                            <span>{item.name ? item.name : 'İsimsiz'}</span><span
                            style={{ float: 'right' }}>{item.y && `${toLocaleString(item.y)} ₺`}</span>
                          </MyTag>
                        </List.Item>
                      )}
                    >
                    </List>
                  </Card>
                </Col>


              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 16 }}>
                  <Card bodyStyle={{ padding: 10 }}>
                    <Row>
                      <Col span={24}>
                        {/* <Radio.Group style={{ float: 'right', padding: 10 }} defaultValue='M' onChange={this.SplineOnChange}>
													<Radio.Button value='W'><b> Haftalık</b></Radio.Button>
													<Radio.Button value='M'><b> Aylık</b></Radio.Button>
													<Radio.Button value='Y'><b> Yıllık</b></Radio.Button>
												</Radio.Group> */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={monthlySellerOptions}
                        ></HighchartsReact>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 16 }}>
                  <Card bodyStyle={{ padding: 10 }}>
                    <Row>
                      <Col span={24}>
                        <List
                          scroll={{ x: 700, y: 500 }}
                          itemLayout="vertical"
                          size="small"
                          header={<b>{this.props.langData['Dated Bills']}</b>}
                          pagination={{ pageSize: 3 }}
                          dataSource={this.state.data || []}
                          renderItem={item => (
                            <List.Item
                              style={{ padding: 0 }}
                              key={item.transaction_id}
                            >
                              <div style={{ display: 'table', width: '100%', height: '100%' }}>
                                <div style={{
                                  display: 'table-cell',
                                  width: '6%',
                                  verticalAlign: 'middle',
                                  paddingRight: 10,
                                  paddingBottom: 10,
                                  textAlign: 'center',
                                }}>
                                  <br/>
                                  <Icon type="file-text"
                                        style={{ fontSize: 35, background: '#d7d5d5', borderRadius: 4, padding: 4 }}/>
                                </div>
                                <div style={{ display: 'table-cell', width: '74%', verticalAlign: 'middle' }}>
                                  <b>{item.customer ? item.customer.name : ''}</b><br/>
                                  <b>{this.props.langData['Expiry Date']} : {moment(item.expiry_date).locale(this.props.lang).format('DD MMMM YYYY')}</b>
                                  <br/>
                                  <Tag style={{ alignItems: 'center' }}
                                       color={item.__typename === 'SalesInvoice' ? '#87d068' : '#f50f50'}>
                                    {item.__typename == 'SalesInvoice' ? this.props.langData['Sales Invoice'] : this.props.langData['Purchase Invoice']}
                                  </Tag>
                                  <Tag style={{ alignItems: 'center' }}
                                       color="blue">{item.category ? item.category.name : this.props.langData['Uncategorized']}
                                  </Tag>
                                  {item.return_invoices && item.return_invoices.length ? <Tag color="#ff7875" style={{
                                    marginRight: 0,
                                    marginTop: 5,
                                  }}>{this.props.langData['Return']}</Tag> : ''}
                                </div>
                                <div style={{
                                  display: 'table-cell',
                                  width: '20%',
                                  verticalAlign: 'middle',
                                  textAlign: 'end',
                                }}>
                                  <br/>
                                  <b>
                                    <span>{`${this.props.langData['Total']} : ${toLocaleString(item.grand_total)} ${getCurrencyWithCode(item.currency).symbol}`}</span>
                                  </b>
                                  <br/>
                                  <b>
                                    <span>{`${this.props.langData['Remaining']} : ${toLocaleString(parseFloat((item.remaining).toFixed(4)))} ${getCurrencyWithCode(item.currency).symbol}`}</span>
                                  </b>
                                </div>
                              </div>

                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          )
        }}
      </Query>
    )
  }

}

TransactionReport.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  noBreadCrumb: PropTypes.bool,
  rowItemClick: PropTypes.func,
  modalClose: PropTypes.func,
  scrollHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  fixed_exchange_rate: PropTypes.string,
  refetch: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReport)
