import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Query, Mutation } from '@apollo/client/react/components'
import { graphQLErrorsToToast, formErrorFieldsNameLocalize, toast, deleteNull } from '../../../../../helpers/helper'
import { Card, Icon, Form, Switch, Button, InputNumber } from 'antd'
import { gql } from '@apollo/client'


const READ_QUERY = (
  gql`
    {
      settings {
        product_settings {
          modal_status
          critical_stock_count
        }
      }
    }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
    mutation ($settings: SettingsUpdateInput!) {
      settingsUpdate(settings: $settings) {
        settings {
          product_settings {
            modal_status
            critical_stock_count
          }
        }

        informative{
          messages
        }
      }
    }
  `
)

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

class ProductSettingsForm extends Component {
  state = {
    initialize: null,
  }
  onSubmit = (mutate) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        formErrorFieldsNameLocalize(err)

      mutate({ variables: values })
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Query
        query={READ_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.initialize) {
            this.setState({ initialize: true })
            this.props.form.setFieldsValue(deleteNull({
              'settings[product_settings][modal_status]': data.settings.product_settings.modal_status,
              'settings[product_settings][critical_stock_count]': data.settings.product_settings.critical_stock_count,
            }))
          }

          return (
            <Mutation
              mutation={READ_FOR_CREATE_QUERY}
              onError={error => {
                graphQLErrorsToToast(error)
              }}
              onCompleted={() => {
                toast(true, this.props.langData['Product Settings Success'])
              }}
            >
              {(mutate, { loading: loadingMutation }) => (
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="control"/> &nbsp;{this.props.langData['Product Settings']}</span>}
                  bordered={false}
                >
                  <Form
                    hideRequiredMark={true}
                    className="form-label-default"
                    onSubmit={e => {
                      e.preventDefault()
                      this.onSubmit(mutate)
                    }}
                  >
                    <div className="form-block">
                      <Form.Item label={(
                        <span><Icon
                          type="fullscreen"/><p>{this.props.langData['Should modal be opened when adding additional charge to the product']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('settings[product_settings][modal_status]', {
                          valuePropName: 'checked',
                        })(
                          <Switch
                            checkedChildren={this.props.langData['Yes']}
                            unCheckedChildren={this.props.langData['No']}
                          />,
                        )}
                      </Form.Item>

                      <Form.Item label={(
                        <span><Icon type="warning"/><p>{this.props.langData['Critical Stock Alert Count']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('settings[product_settings][critical_stock_count]', {
                          validateTrigger: false,
                        })(
                          <InputNumber
                            decimalSeparator=","
                            size="large"
                            step={0.0001}
                            style={{ width: '-webkit-fill-available' }}
                          />,
                        )}
                      </Form.Item>
                    </div>

                    <div className="form-block">
                      <Form.Item {...formItemLayoutNoLabel}>
                        <Button type="primary" size="large" loading={loadingMutation} htmlType="submit"
                                className="default-button">
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Card>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

ProductSettingsForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
}
export default ProductSettingsForm
