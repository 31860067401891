import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'

import routes from '../../../routes'

import NewCustomerForm from './NewCustomerForm'

function NewCustomer(props) {

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedNewCustomerForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(NewCustomerForm)

  return <WrappedNewCustomerForm {...props}/>

}

NewCustomer.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  isModal: PropTypes.func,
  incomingEInvoiceData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Customer', url: routes.CUSTOMER_LIST } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(NewCustomer)
