import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'

import routes from '../../routes'

import UpdateMoneyIOForm from './UpdateMoneyIOForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class UpdateMoneyIO extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.TRANSACTION_DETAIL_UPDATE_BY_TYPE, {
      type: this.props.type,
      id: this.props.transaction_id,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  render() {
    const WrappedUpdateMoneyIOForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(UpdateMoneyIOForm)
    return <WrappedUpdateMoneyIOForm {...this.props} />
  }

}

UpdateMoneyIO.propTypes = {
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,

  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,

  transaction_id: PropTypes.number,
  type: PropTypes.string,

  callback: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMoneyIO)
