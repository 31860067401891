import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Divider, Col, Select, Switch } from 'antd'
import { BulbOutlined } from '@ant-design/icons'

import {
  deleteEmpty,
  selectFilterLowerCase,
  formErrorFieldsNameLocalize,
  successToast,
} from '../../../helpers/helper'

import { postPanelApi } from '../../../api'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 14 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

function Step1Form(props) {

  // iki defa render oluyor!
  let ready = false

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {

    ready = true

    postPanelApi('/auth/step1-open').then(result => {

      if (result) {

        if (ready) {

          props.form.setFieldsValue(result.form)

        }

      }

    })

    return () => {

      ready = false

    }

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      setSubmitting(true)

      postPanelApi('/auth/step1-save', values).then(result => {

        if (result) {

          successToast(result.message)

          props.history.push(generatePath(routes.REGISTER_STEP, { step: 2 }))

        } else {

          setSubmitting(false)

        }

      })

    })
  }

  const { getFieldDecorator } = props.form

  return (
    <Form
      hideRequiredMark={true}
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      className="form-label-default"
    >
      <Divider>{props.langData['User Information']}</Divider>

      <div className="form-block">

        <Form.Item hasFeedback label={(
          <span><Icon type="home"/><p>{props.langData['Company Name']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('company_name', {
            rules: [{ max: 250 }, { required: true }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="tag"/><p>{props.langData['Title']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('title', { rules: [{ max: 250 }], validateTrigger: false })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="phone"/><p>{props.langData['Phone'] + ' 1'}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('phone_1', { rules: [{ max: 20 }], validateTrigger: false })(
            <Input type="number" size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="phone"/><p>{props.langData['Phone'] + ' 2'}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('phone_2', { rules: [{ max: 20 }], validateTrigger: false })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item label={(
          <span>
							<Icon type="environment-o"/>
							<p>{props.langData['Country']}</p>
						</span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('country', {
            initialValue: 'Türkiye',
            rules: [{ max: 100 }],
            validateTrigger: false,
          })(
            <Select
              size="large"
              showSearch optionFilterProp="children"
              allowClear
              filterOption={selectFilterLowerCase}
            >
              {props.langData.countries.map((x, k) => {
                return <Select.Option value={x} key={k}>{x}</Select.Option>
              })}
            </Select>,
          )}
        </Form.Item>

        <Form.Item label={(
          <span><Icon
            type="environment-o"/><p>{props.langData['City'] + ' / ' + props.langData['Town']}</p></span>
        )}
                   {...formItemLayout}
        >
          <Input.Group size="large">
            <Col span={12}>
              <Form.Item hasFeedback>
                {getFieldDecorator('city', { rules: [{ max: 100 }], validateTrigger: false })(
                  (props.lang === 'tr' && props.form.getFieldValue('country') === 'Türkiye') ? (
                    <Select
                      size="large"
                      className="w-100"
                      showSearch optionFilterProp="children"
                      allowClear
                      filterOption={selectFilterLowerCase}
                    >
                      {props.langData.cityTown.map((x, k) => {
                        return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                      })}
                    </Select>
                  ) : <Input/>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item hasFeedback>
                {getFieldDecorator('town', { rules: [{ max: 100 }], validateTrigger: false })(
                  (props.lang === 'tr' && props.form.getFieldValue('country') === 'Türkiye') ? (
                    <Select
                      size="large"
                      className="w-100"
                      showSearch optionFilterProp="children"
                      allowClear
                      filterOption={selectFilterLowerCase}
                    >
                      {
                        props.langData.cityTown.filter(x => x.city === props.form.getFieldValue('city')).length && (
                          props.langData.cityTown.filter(x => x.city === props.form.getFieldValue('city'))[0].towns.map((x, k) => {
                            return <Select.Option value={x} key={k}>{x}</Select.Option>
                          })
                        )
                      }
                    </Select>
                  ) : <Input/>,
                )}
              </Form.Item>
            </Col>
          </Input.Group>

        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="environment-o"/><p>{props.langData['Address']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('address', { rules: [{ max: 500 }], validateTrigger: false })(
            <Input.TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('e_mail', { rules: [{ type: 'email', max: 100 }], validateTrigger: false })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="link"/><p>{props.langData['Website']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('web_site', { rules: [{ min: 1, max: 70 }], validateTrigger: false })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(<span><BulbOutlined/><p>{props.langData['Type']}</p></span>)}
                   {...formItemLayout}
        >
          {getFieldDecorator('is_individual', {
            valuePropName: 'checked',
            initialValue: false,
          })(
            <Switch
              checkedChildren={props.langData['Real Person']}
              unCheckedChildren={props.langData['Legal Person']}
            />,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="idcard"/><p>{props.langData['Id/Tax Identification Number']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('tax_identification_number', {
            rules: [{ min: 10, max: 11 }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        { ! props.form.getFieldValue('is_individual') && (
          <Form.Item
            hasFeedback
            label={(<span><Icon type="compass"/><p>{props.langData['Tax Administration']}</p></span>)}
            {...formItemLayout}
          >
            {getFieldDecorator('tax_administration', { rules: [{ max: 250 }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>
        )}

      </div>

      <Divider>{props.langData['Commissioner Information']}</Divider>

      <div className="form-block">

        <Form.Item hasFeedback label={(
          <span><Icon type="user"/><p>{props.langData['Name Surname']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('commissioner_name_surname', {
            rules: [{ max: 250 }, { required: true }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('commissioner_e_mail', {
            rules: [{ type: 'email' }, { max: 100 }, { required: true }],
            validateTrigger: false,
          })(
            <Input size="large"/>,
          )}
        </Form.Item>

        <Form.Item hasFeedback label={(
          <span><Icon type="phone"/><p>{props.langData['Phone']}</p></span>
        )}
                   {...formItemLayout}
        >
          {getFieldDecorator('commissioner_phone', {
            rules: [{ max: 20 }, { required: true }],
            validateTrigger: false,
          })(
            <Input type="number" size="large"/>,
          )}
        </Form.Item>

      </div>

      <Divider/>

      <div className="form-block">
        <Form.Item {...formItemLayoutNoLabel}>
          <Button
            type="primary"
            size="large"
            loading={submitting}
            htmlType="submit"
            className="default-button"
          >
            {props.langData['Next']}
          </Button>
        </Form.Item>
      </div>

    </Form>
  )

}

Step1Form.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  form: PropTypes.object,
  history: PropTypes.object,
}

function Step1(props) {

  const WrappedStep1Form = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(Step1Form)

  return <WrappedStep1Form {...props} />

}

Step1.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
})(Step1)
