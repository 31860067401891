import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Card, Table, DatePicker, Button, Icon, Row, Divider, Tag } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode, getCurrencyWithId } from '../../../data/Currency'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import moment from 'moment'
import { cloneDeep } from 'lodash'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Category Based Report', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($sorting: InvoiceSorting, $filterInvoice: InvoiceFilterBase, $filterInvoiceList: InvoiceFilterBase, $paging: Paging) {
      invoiceList(sorting:$sorting, paging: $paging, filter: $filterInvoiceList){
        transactions{
          __typename
          ... on SalesInvoice{
            transaction_id
            grand_total
            total_vat
            date
            expiry_date
            discount_type
            currency
            invoice_country
            e_invoice_type
            customer{
              name
            }
            category{
              name
            }
            discount
            remaining
          }
          ... on PurchaseInvoice{
            transaction_id
            grand_total
            total_vat
            discount_type
            date
            expiry_date
            currency
            invoice_country
            customer{
              name
            }
            category{
              name
            }
            discount
            remaining
          }
        }

        page_info{
          total_count
        }
      }
      reports {
        invoice {
          sum(
            field: grand_total
            group: [type, category_id]
            filter: $filterInvoice
          )
          {
            results {
              type
              category_id
              sum
              category {
                name
              }
            }
          }
        }
        vatReport:invoice {
          sum(
            field: total_vat
            group: [type, category_id]
            filter: $filterInvoice
          )
          {
            results {
              type
              category_id
              sum
              category {
                name
              }
            }
          }
        }
      }
      categoryList:invoiceCategoryList {
        category_id
        name
        name
      }

      legacyData {
        currency_id
      }

    }
  `
)

class InvoiceImcomeExpenseReport extends Component {

  currencyId = 1

  state = {
    data: null,
    invoice: null,
    filterList: {
      AND: [{
        date: {
          gte: moment().subtract(1, 'months').format('YYYY-MM-DD hh:mm'),
          lte: moment().format('YYYY-MM-DD hh:mm'),
        },
      }],
    },
    filter: {
      AND: [{
        date: {
          gte: moment().subtract(1, 'months').format('YYYY-MM-DD hh:mm'),
          lte: moment().format('YYYY-MM-DD hh:mm'),
        },
      }],
    },
    pagination: {
      pageSize: 10,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 10,
    },
    sorting: null,
    startValue: moment().subtract(1, 'months'),
    endValue: moment(),
    endOpen: false,
    headerName: '',
    filterCategoryId: 0, //null olmasın çünkü category_id alanı null dönen degerler var 0 id degeri olamayacagı için yazıldı
  }

  columns = [
    {
      title: '',
      width: '2%',
      key: 'action',
      render: (value) => {
        return (<div><Icon style={{ fontSize: 'larger', cursor: 'pointer' }}
                           type={this.state.filterCategoryId === value.category_id ? 'double-right' : 'plus'}/></div>)
      },
    },
    {
      title: this.props.langData['Categories'],
      dataIndex: 'categoryName',
      width: '20%',
    },
    {
      width: '13%',
      align: 'right',
      title: this.props.langData['Incomes'],
      dataIndex: 'salesSum',
      render: (value) => {
        return (
          <div>{toLocaleString(parseFloat(value))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    },
    {
      width: '13%',
      align: 'right',
      title: this.props.langData['Expenses'],
      dataIndex: 'purchaseSum',
      render: (value) => {
        return (
          <div>{toLocaleString(parseFloat(value))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    },
    {
      width: '13%',
      align: 'right',
      title: this.props.langData['Total'],
      render: (value, record) => {
        return (
          <div>{toLocaleString(parseFloat(record.salesSum - record.purchaseSum))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    }
    ,
    {
      width: '14%',
      align: 'right',
      title: this.props.langData['Total VAT Amount of Sales'],
      dataIndex: 'salesVatSum',
      render: (value) => {
        return (
          <div>{toLocaleString(parseFloat(value))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    },
    {
      width: '14%',
      align: 'right',
      title: this.props.langData['Total VAT Amount of Purchase'],
      dataIndex: 'purchaseVatSum',
      render: (value) => {
        return (
          <div>{toLocaleString(parseFloat(value))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    },
    {
      width: '14%',
      align: 'right',
      title: this.props.langData['Total VAT'],
      render: (value, record) => {
        return (
          <div>{toLocaleString(parseFloat(record.salesVatSum - record.purchaseVatSum))} {getCurrencyWithId(this.currencyId).symbol}</div>
        )
      },
    },
  ]

  invoiceListColumns = [
    {
      title: this.props.langData['Invoice Type'],
      fieldName: '__typename',
      dataIndex: '__typename',
      width: '10%',
      sorter: true,
      render: (value, record) => {
        if (value === 'SalesInvoice') {

          return (
            <div>
              <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Sales Invoice']}</Tag><br/>
              {record.e_invoice_type === 'EInvoice' ? <Tag className="success" style={{
                marginRight: 0,
                marginTop: 5,
              }}>{this.props.langData['E-Invoice']}</Tag> : record.e_invoice_type ? <Tag className="success" style={{
                marginRight: 0,
                marginTop: 5,
              }}>{this.props.langData['E-Archive']}</Tag> : ''}
            </div>
          )
        }
        if (value === 'PurchaseInvoice')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Purchase Invoice']}</Tag>
      },
    },
    {
      title: this.props.langData['Customer'],
      dataIndex: 'customer',
      fieldName: 'customer',
      fieldLabel: 'Customer',
      subFields: ['name'],
      width: '20%',
      key: 'customer',
      align: 'center',
      render: record => record ? record.name : '',
    },
    {
      title: this.props.langData['Expiry Date'],
      dataIndex: 'expiry_date',
      fieldName: 'expiry_date',
      fieldLabel: 'Expiry Date',
      align: 'center',
      width: '15%',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY'),
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      fieldName: 'date',
      fieldLabel: 'Date',
      width: '15%',
      sorter: true,
      align: 'center',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY'),
    },
    {
      title: this.props.langData['Total VAT'],
      dataIndex: 'total_vat',
      key: 'income',
      width: '15%',
      align: 'right',
      render: value => {
        return (`${toLocaleString(value)} ${getCurrencyWithId(this.currencyId).symbol}`)
      },
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'remaining',
      fieldName: 'remaining',
      fieldLabel: 'Total',
      align: 'right',
      width: '25%',
      render: (value, record) => {
        if (value !== undefined)
          return (
            <div>
              <span>{`${this.props.langData['Total']} : ${toLocaleString(record.grand_total)} ${getCurrencyWithCode(record.currency).symbol}`}</span>
              <br/>
              <span>{`${this.props.langData['Remaining']} : ${toLocaleString(parseFloat(value))} ${getCurrencyWithCode(record.currency).symbol}`}</span>
            </div>
          )
      },
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['transaction/invoice-income-expense-report'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  disabledStartDate = startValue => {
    const { endValue } = this.state
    if ( ! startValue || ! endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state
    if ( ! endValue || ! startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  onChange = (field, value) => {
    if (value)
      this.setState({
        [field]: value,
      })
    else
      this.setState({
        [field]: field === 'startValue' ? moment().subtract(1, 'months') : moment(),
      })
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize)
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
      })
    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field === '__typename' ? 'type' : sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterSetDate = refetch => {
    let filter = {
      date: {
        gte: moment(this.state.startValue).format('YYYY-MM-DD hh:mm'),
        lte: moment(this.state.endValue).format('YYYY-MM-DD hh:mm'),
      },
    }
    let filterList = this.state.filterList
    if (this.state.filterCategoryId !== 0) {
      filterList = [{
        category_id: { eq: this.state.filterCategoryId },
        date: {
          gte: moment(this.state.startValue).format('YYYY-MM-DD hh:mm'),
          lte: moment(this.state.endValue).format('YYYY-MM-DD hh:mm'),
        },
      }]
    }

    this.filterCallback(filter, filterList)

    refetch()
  }

  filterCallback = (filter, filterList) => {
    if ( ! filter)
      this.setState({
        filter: null,
      })
    else
      this.setState({
        filter: {
          AND: filter,
        },
        filterList: {
          AND: filterList,
        },
      })
  }

  rowItemClick = async (record, refetch) => {
    await this.setState({
      filterCategoryId: record.category_id,
      headerName: record.categoryName === this.props.langData['Uncategorized'] ? `${record.categoryName}  ${this.props.langData['Invoice List']}` : `${record.categoryName} ${this.props.langData['Categorized']} ${this.props.langData['Invoice List']}`,
    })

    this.filterSetDate(refetch)
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{
          filterInvoice: this.state.filter,
          filterInvoiceList: this.state.filterList,
          sorting: this.state.sorting,
        }}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.invoice) {

              this.currencyId = data.legacyData.currency_id

              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
                invoice: cloneDeep(data.invoiceList.transactions),
              })
            } else if (this.state.data && JSON.stringify(data.invoiceList.transactions) !== JSON.stringify(this.state.invoice)) {
              this.setState({
                invoice: cloneDeep(data.invoiceList.transactions),
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
              })
            }
          }

          if ( ! loadingQuery && data) {

            let sales = data.reports.invoice.sum.results.filter(x => x.type === 'SalesInvoice')
            let salesVat = data.reports.vatReport.sum.results.filter(x => x.type === 'SalesInvoice')
            let purchase = data.reports.invoice.sum.results.filter(x => x.type === 'PurchaseInvoice')
            let purchaseVat = data.reports.vatReport.sum.results.filter(x => x.type === 'PurchaseInvoice')
            const categoriesSum = []
            for (let s = 0; s < data.categoryList.length; s++) {
              let sls = sales.find(
                sa => {
                  if (sa.category_id === data.categoryList[s].category_id)
                    return sa
                })
              let prch = purchase.find(
                pr => {
                  if (pr.category_id === data.categoryList[s].category_id)
                    return pr
                })
              let slsVat = salesVat.find(
                saVat => {
                  if (saVat.category_id === data.categoryList[s].category_id)
                    return saVat
                })
              let prchVat = purchaseVat.find(
                prVat => {
                  if (prVat.category_id === data.categoryList[s].category_id)
                    return prVat
                })
              if (sls || prch)
                categoriesSum.push({
                  category_id: data.categoryList[s].category_id,
                  categoryName: sls ? sls.category.name : (prch ? prch.category.name : ''),
                  salesSum: sls ? sls.sum : 0,
                  purchaseSum: prch ? prch.sum : 0,
                  salesVatSum: slsVat ? slsVat.sum : 0,
                  purchaseVatSum: prchVat ? prchVat.sum : 0,
                })
            }
            categoriesSum.push({
              category_id: null,
              categoryName: this.props.langData['Uncategorized'],
              salesSum: sales.find(s => s.category_id == null) ? sales.find(s => s.category_id == null).sum !== 0 ? sales.find(s => s.category_id == null).sum : 0 : 0,
              salesVatSum: salesVat.find(s => s.category_id == null) ? salesVat.find(s => s.category_id == null).sum !== 0 ? salesVat.find(s => s.category_id == null).sum : 0 : 0,
              purchaseSum: purchase.find(p => p.category_id == null) ? purchase.find(p => p.category_id == null).sum !== 0 ? purchase.find(p => p.category_id == null).sum : 0 : 0,
              purchaseVatSum: purchaseVat.find(p => p.category_id == null) ? purchaseVat.find(p => p.category_id == null).sum !== 0 ? purchaseVat.find(p => p.category_id == null).sum : 0 : 0,
            })
            if ( ! this.state.data) {
              this.setState({
                data: categoriesSum,
                invoice: categoriesSum,
              })
            } else if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(categoriesSum)) {
              this.setState({
                data: categoriesSum,
                invoice: categoriesSum,
              })
            }
          }

          return (
            <div>
              <Card>
                <Row>
                  <Divider orientation="center"
                           style={{ marginTop: 0 }}>{this.props.langData['Invoice Category Income Expense Report']}</Divider>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <DatePicker
                      style={{ float: 'center', margin: 5 }}
                      size="large"
                      suffixIcon={<Icon type="calendar" theme="filled"/>}
                      showTime
                      format="DD.MM.YYYY HH:mm"
                      value={this.state.startValue}
                      placeholder="Start"
                      onChange={value => this.onChange('startValue', value)}
                    />
                    <DatePicker
                      style={{ float: 'center', margin: 5 }}
                      size="large"
                      suffixIcon={<Icon type="calendar" theme="filled"/>}
                      showTime
                      format="DD.MM.YYYY HH:mm"
                      value={this.state.endValue}
                      placeholder="End"
                      onChange={value => this.onChange('endValue', value)}
                    />
                    <Button type="primary" size="large"
                            onClick={() => {
                              this.setState({ filterCategoryId: 0 })
                              this.filterSetDate(refetch)
                            }
                            }>
                      {this.props.langData['Apply']}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Table
                      columns={this.columns}
                      rowKey={record => record.categoryName}
                      dataSource={this.state.data || []}
                      loading={ ! this.state.data}
                      pagination={false}
                      style={{ borderRadius: '0px' }}
                      rowClassName={record => {
                        if (record.category_id === this.state.filterCategoryId)
                          return 'tableRowClick'
                        else
                          return 'white'
                      }
                      }
                      onRow={record => ({
                        onClick: () => {
                          return this.rowItemClick(record, refetch)
                        },
                      })}
                      scroll={{ x: 1024, y: this.props.sizeWidth > 1024 ? 500 : null }}
                    />
                  </Col>
                </Row>
              </Card>
              {this.state.filterCategoryId !== 0 && this.state.filterCategoryId !== undefined &&
                <Card style={{ marginTop: 10 }}>
                  <Divider style={{ marginTop: 0 }} orientation="center">{this.state.headerName}</Divider>
                  <Row>
                    <Col>
                      <Table
                        columns={this.invoiceListColumns}
                        rowKey={record => record.transaction_id}
                        dataSource={this.state.filterCategoryId !== 0 ? this.state.invoice || [] : []}
                        loading={ ! this.state.invoice}
                        pagination={this.state.pagination}
                        style={{ borderRadius: '0px' }}
                        onChange={this.handleTableChange}
                        rowClassName="white"
                        /* 	onRow={record => ({
                          onClick: () => {
                            return this.DetailInvoiceRowItemClick(record)
                          }
                        })} */
                        scroll={{ x: 756, y: this.props.sizeWidth > 800 ? 756 : null }}
                      />
                    </Col>
                  </Row>
                </Card>}
            </div>
          )
        }}
      </Query>
    )
  }
}

InvoiceImcomeExpenseReport.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  rowItemClick: PropTypes.func,
  lang: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceImcomeExpenseReport)
