import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../routes'
import WebhookUpdateForm from './WebhookUpdateForm'

function WebhookUpdate(props) {

  const webhookId = Number(props.match.params.id)

  useEffect(() => {

    props.breadcrumb_add(webhookId)

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedWebhookUpdateForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(WebhookUpdateForm)

  return <WrappedWebhookUpdateForm {...props} webhook_id={webhookId}/>

}

WebhookUpdate.propTypes = {
  match: PropTypes.object,
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add(webhookId) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Web Hook', url: routes.WEBHOOK_LIST } })
      dispatch({
        type: 'breadcrumb_add',
        payload: { name: 'Web Hook Detail', url: generatePath(routes.WEBHOOK_DETAIL, { id: webhookId }) },
      })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(WebhookUpdate)
