import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Card, Icon, Button, Modal, Dropdown, Menu, Divider, Table, Tag, Tooltip } from 'antd'
import { Route, Link, Switch as RouteSwitch, generatePath } from 'react-router-dom'
import { confirm, graphQLErrorsToToast, successToast, toLocaleString } from '../../../helpers/helper'
import NewCreditDebit from './../../Customer/NewCreditDebit/NewCreditDebit'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'
import { getCurrencyWithId } from '../../../data/Currency'
import moment from 'moment'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'
import { PayCircleOutlined } from '@ant-design/icons'
import NewMoneyIO from '../NewMoneyIO'

const ButtonGroup = Button.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query ($safe_id: Int!, $paging: Paging){
      safeTransactionList(
        filter: {
          AND: [
            {
              OR: [
                { account_opening_safe: { safe_id: { eq: $safe_id }  } }
                { money_input: { safe_id: { eq: $safe_id }  } }
                { money_output: { safe_id: { eq: $safe_id }  } }
                { credit: { safe_id: { eq: $safe_id }  } }
                { debit: { safe_id: { eq: $safe_id }  } }
              ]
            }
          ]
        }
        sorting: {
          field: date
          type: DESC
        }
        paging: $paging
      ){
        transactions {
          __typename
          ... on AccountOpeningSafe {
            transaction_id
            type
            amount
            date
          }
          ... on MoneyInput {
            transaction_id
            amount
            date
            explanation
          }
          ... on MoneyOutput {
            transaction_id
            amount
            date
            explanation
          }
          ... on Credit {
            transaction_id
            amount
            date
            customer {
              name
            }
            explanation
          }
          ... on Debit {
            transaction_id
            amount
            date
            customer {
              name
            }
            explanation
          }
        }
        page_info {
          total_count
        }
      }
    }
  `
)

class SafeDetail extends Component {

  safe_id = null
  balance = null
  previousBalance = 0
  previousType = null

  state = {
    data: {
      currency_id: 1,
      balance: 0,
    },
    transactions: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    currentPage: null,
    goPage: null,
  }

  columns = [
    {
      title: this.props.langData['Transaction Type'],
      width: '15%',
      dataIndex: '__typename',
      render: (value, record) => {
        if (value === 'AccountOpeningSafe')
          return <Tag color={record.type === 'MoneyInput' ? '#1890ff' : '#ff4d4f'}
                      style={{ marginRight: 0 }}>{this.props.langData['Account Opening']}</Tag>
        if (value === 'Credit')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Credit']}</Tag>
        if (value === 'Debit')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Debit']}</Tag>
        if (value === 'MoneyInput')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Money Input']}</Tag>
        if (value === 'MoneyOutput')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Money Output']}</Tag>
      },
    },
    {
      title: this.props.langData['Customer'],
      width: '20%',
      dataIndex: 'customer.name',
    },
    {
      title: this.props.langData['Date'],
      width: '20%',
      dataIndex: 'date',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY H:mm'),
    },
    {
      title: this.props.langData['Explanation'],
      width: '15%',
      dataIndex: 'explanation',
    },
    {
      title: this.props.langData['Amount'],
      width: '15%',
      dataIndex: 'amount',
      className: 'text-right',
      render: (value, data) => `${toLocaleString(value)} ${getCurrencyWithId(this.state.data.currency_id).symbol}`,
    },
    {
      title: this.props.langData['Balance'],
      width: '15%',
      dataIndex: 'balance',
      className: 'text-right',
      render: (value, data) => `${toLocaleString(value)} ${getCurrencyWithId(this.state.data.currency_id).symbol}`,
    },
  ]

  constructor(props) {
    super(props)
    this.safe_id = Number(props.match.params.id)
  }

  componentDidMount() {

    postPanelApi('/safe/detail', { safe_id: this.safe_id }).then(result => {

      if (result) {

        this.props.breadcrumb_add(result.item.name, generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.safe_id }))

        // bakiyeyi at
        this.balance = result.item.balance

        // geçiş yumuşak olsun diye 1 saniye bekleme attık
        this.setState({ data: result.item })

      }

    })

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  handleTableChange = paging => {
    // eğer ileri bir sayfaya gidiyorsa bunu goPage değişkenine at
    if (this.state.currentPage < paging.current) {
      this.setState({
        goPage: paging.current,
      })
      this.setPagingForGoPage()
    } else {
      let limit = this.state.paging.limit
      this.setState({
        paging: {
          ...(this.state.paging),
          offset: (paging.current * limit) - limit,
        },
      })
    }
  }

  setPagingForGoPage = () => {
    if (this.state.goPage === this.state.currentPage) {
      this.setState({
        goPage: null,
      })
    } else {
      this.setState(prevState => ({
        paging: {
          ...(this.state.paging),
          offset: ((prevState.currentPage + 1) * prevState.paging.limit) - prevState.paging.limit,
        },
      }))
    }
  }

  rowItemClick = record => {
    let typeName
    let id = record.transaction_id

    if (record.__typename === 'AccountOpeningSafe') {
      typeName = 'safe-account-opening'
      id = this.safe_id
    } else if (record.__typename === 'AccountOpeningCustomer') {
      typeName = 'customer-account-opening'
      id = this.customer_id
    } else if (record.__typename === 'Credit') {
      typeName = 'credit'
    } else if (record.__typename === 'Debit') {
      typeName = 'debit'
    } else if (record.__typename === 'MoneyInput') {
      typeName = 'money-input'
    } else if (record.__typename === 'MoneyOutput') {
      typeName = 'money-output'
    }

    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      id,
      type: typeName,
    }))

  }

  edit = () => {
    this.props.history.push(generatePath(routes.SAFE_UPDATE, { id: this.safe_id }))
  }

  delete = () => {
    confirm(undefined, result => {
      if (result) {
        postPanelApi('/safe/delete', { safe_id: this.safe_id }).then(result => {

          if (result) {

            successToast(result.message)

            if (this.props.history.action === 'PUSH') {
              this.props.history.goBack()
            } else {
              this.props.history.push('/')
            }

          }

        })

      }
    })
  }

  menuOnClick = item => {

    switch (item.key) {
      case 'edit':
        this.edit()
        break
      case 'delete':
        this.delete()
        break
    }

  }

  render() {
    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="edit" icon="edit">
          <span><Icon type="edit"/>&nbsp;{this.props.langData['Edit']}</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/>&nbsp;{this.props.langData['Delete']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ safe_id: this.safe_id, paging: this.state.paging }}
        // skip={!this.state.data || (this.state.transactions && this.state.paging.offset < this.state.transactions.length)}
      >
        {({ loading: loadingListQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          // ilk veri gelişi
          if ( ! loadingListQuery && ! this.state.transactions) {
            let dataCopy = cloneDeep(data.safeTransactionList.transactions)
            dataCopy.map(x => {
              if (this.previousType) {
                if (this.previousType === 'Debit' || this.previousType === 'MoneyInput')
                  this.balance -= this.previousBalance
                else
                  this.balance += this.previousBalance
              }

              x.balance = this.balance
              this.previousBalance = x.amount
              if (x.type !== 'AccountOpeningCustomer')
                this.previousType = x.__typename
              else
                this.previousType = x.type
            })
            this.setState({
              transactions: dataCopy,
              currentPage: 1,
              pagination: {
                ...this.state.pagination,
                total: data.safeTransactionList.page_info.total_count,
              },
            })
          } else if ( ! loadingListQuery && this.state.transactions && data.safeTransactionList.transactions.length) {
            // eğer getirilen veri daha önceden getirilmişse yeniden işlem yapma.
            if ( ! this.state.transactions.find(x => x && x.transaction_id === data.safeTransactionList.transactions[0].transaction_id)) {
              let dataCopy = cloneDeep(data.safeTransactionList.transactions)
              dataCopy.forEach((x, i) => {
                if (this.previousType === 'Debit' || this.previousType === 'MoneyInput')
                  this.balance -= this.previousBalance
                else
                  this.balance += this.previousBalance

                x.balance = this.balance
                this.previousBalance = x.amount
                if (x.type !== 'AccountOpeningCustomer')
                  this.previousType = x.__typename
                else
                  this.previousType = x.type

              })

              this.setState(prevState => ({
                transactions: [...(this.state.transactions), ...dataCopy],
                pagination: {
                  ...this.state.pagination,
                  total: data.safeTransactionList.page_info.total_count,
                },
              }), () => {
                // sayfa ileriye doğru gitmesi gerekiyorsa, currentPage artır daha sonra setPagingForGoPage çalıştır
                if (this.state.goPage)
                  this.setState(prevState => ({
                    currentPage: prevState.currentPage + 1,
                  }), this.setPagingForGoPage)
              })
            }
          }

          return (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="wallet"/> &nbsp;{this.props.langData['Safe Detail']}</span>}
                  bordered={false}
                  loading={ ! this.state.data}
                  extra={
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button loading={ ! this.state.data}>
                        {this.props.langData['Actions']} {this.state.data && <Icon type="down"/>}
                      </Button>
                    </Dropdown>
                  }
                >

                  { ! this.state.data && <div style={{ width: '100%', height: 150 }}></div>}

                  {this.state.data && <React.Fragment>
                    <div className="form-block label-default">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {this.state.data.name}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="bulb"/><p>{this.props.langData['Currency']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {getCurrencyWithId(this.state.data.currency_id).name + ' - ' + getCurrencyWithId(this.state.data.currency_id).symbol}
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>}
                </Card>

                <Card
                  bodyStyle={{ padding: 0, marginTop: 20 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon type="swap"/> {this.props.langData['Transaction List']}
                  </Divider>

                  <Table
                    columns={this.columns}
                    rowKey={record => record.transaction_id}
                    dataSource={this.state.transactions}
                    pagination={this.state.pagination}
                    loading={loadingListQuery && ! (this.state.transactions && this.state.paging.offset < this.state.transactions.length)}
                    onChange={this.handleTableChange}
                    onRow={record => ({
                      onClick: () => this.rowItemClick(record),
                    })}
                    style={{ borderRadius: '0px' }}
                    rowClassName="white"
                    scroll={{ x: 700, y: 500 }}
                  />

                </Card>

              </Col>
              <Col xs={24} sm={24} md={10} lg={8} xl={7}>

                <Route exact path={routes.SAFE_DETAIL_BY_ID} render={() => {
                  return (
                    <Card
                      bodyStyle={{ padding: '0px' }}
                      title={
                        <div>
                          <ButtonGroup className="fl-r w-100">
                            <Tooltip placement="top"
                                     title={this.props.lang === 'tr' ? 'Kasadan Para Çıkışı' : this.props.langData['Money Output']}>
                              <Button className="w-50" type="danger" loading={ ! this.state.data}
                                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                <Link
                                  to={generatePath(routes.SAFE_MONEY_IO, { id: this.safe_id, type: 'money-output' })}
                                >
                                  &nbsp;{this.props.lang === 'tr' ? 'Kasadan Para Çıkışı' : this.props.langData['Money Output']}
                                </Link>
                              </Button>
                            </Tooltip>
                            <Tooltip placement="top"
                                     title={this.props.lang === 'tr' ? 'Kasaya Para Girişi' : this.props.langData['Money Input']}>
                              <Button className="w-50" type="primary" loading={ ! this.state.data}
                                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                <Link
                                  to={generatePath(routes.SAFE_MONEY_IO, { id: this.safe_id, type: 'money-input' })}
                                >
                                  &nbsp;{this.props.lang === 'tr' ? 'Kasaya Para Girişi' : this.props.langData['Money Input']}
                                </Link>
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                          <ButtonGroup className="fl-r w-100" style={{ marginTop: 16 }}>
                            <Button className="w-50" type="danger" loading={ ! this.state.data}>
                              <Link
                                to={generatePath(routes.SAFE_DETAIL_BY_TYPE, {
                                  id: this.safe_id,
                                  type: 'credit',
                                })}
                              >
                                &nbsp;{this.props.lang === 'tr' ? 'Cariye Ödeme' : this.props.langData['Credit']}
                              </Link>
                            </Button>
                            <Button className="w-50" type="primary" loading={ ! this.state.data}>
                              <Link
                                to={generatePath(routes.SAFE_DETAIL_BY_TYPE, {
                                  id: this.safe_id,
                                  type: 'debit',
                                })}
                              >
                                &nbsp;{this.props.lang === 'tr' ? 'Cariden Tahsilat' : this.props.langData['Debit']}
                              </Link>
                            </Button>
                          </ButtonGroup>
                        </div>
                      }
                      bordered={false}
                    >
                      {this.state.data && <div className="form-block">
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span style={{ fontSize: 15 }}>{this.props.langData['Balance']}</span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="text-right">
																	<span style={{ fontSize: 18, fontWeight: 'bold' }}>
																		{toLocaleString(this.state.data.balance)}
																	</span>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>
																		&nbsp;{getCurrencyWithId(this.state.data.currency_id).symbol}
																	</span>
                          </Col>
                        </Row>
                      </div>}
                    </Card>
                  )
                }}/>
                <RouteSwitch>
                  <Route path={routes.SAFE_MONEY_IO} render={props => {

                    const newNewMoneyIO = (
                      <NewMoneyIO safe_id={this.safe_id} type={props.match.params.type} {...props} />
                    )

                    if (this.props.sizeWidth <= 576) {
                      return (
                        <Modal
                          title={
                            <span><PayCircleOutlined/> &nbsp;{
                              props.match.params.type === 'money-input' ?
                                this.props.langData['Input'] :
                                this.props.langData['Output']
                            }</span>
                          }
                          bodyStyle={{ padding: 8 }}
                          visible={true}
                          footer={null}
                          onCancel={() => {
                            props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.safe_id }))
                          }}
                        >
                          {newNewMoneyIO}
                        </Modal>
                      )
                    }
                    return newNewMoneyIO

                  }}
                  />
                  {this.state.data && <Route path={routes.SAFE_DETAIL_BY_TYPE} render={props => {

                    const type = props.match.params.type

                    const newNewCreditDebit = (
                      <NewCreditDebit
                        type={type}
                        safe={{
                          safe_id: this.safe_id,
                          currency: this.state.data.currency_id,
                          safeList: this.state.data,
                        }}
                        {...props}
                      />
                    )

                    if (this.props.sizeWidth > 576) {
                      return newNewCreditDebit
                    }

                    return (
                      <Modal
                        title={
                          <span><Icon type="pay-circle-o"/> &nbsp;{
                            this.props.langData[type[0].toUpperCase() + type.slice(1)]
                          }</span>
                        }
                        bodyStyle={{ padding: 8 }}
                        visible={true}
                        footer={null}
                        onCancel={() => {
                          props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.safe_id }))
                        }}
                      >
                        {newNewCreditDebit}
                      </Modal>
                    )

                  }}
                  />}
                </RouteSwitch>
              </Col>
            </Row>
          )
        }}
      </Query>
    )
  }

}

SafeDetail.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,

  langData: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,

  match: PropTypes.object,
  history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SafeDetail)
