import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Upload, Icon, Button } from 'antd'
import { connect } from 'react-redux'
import axios from 'axios'

import { toast } from './../../helpers/helper'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

class ExcelUpload extends Component {

  state = {
    fileList: [],
  }

  bookTypes = [
    '.xlsx',
    '.xlsm',
    '.xlsb',
    '.xls',
    '.ods',
    '.fods',
    '.csv',
  ]

  deleteFile = file => {
    return new Promise((r, j) => {
      if (file.status === 'error') {
        this.setState({
          fileList: [],
        })
        return r(true)
      }
      axios({
        method: 'POST',
        headers: {
          'X-Token': localStorage.getItem('token'),
          'X-Module': 'product',
        },
        data: { id: file.response.id },
        url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/delete/excel`,
      }).then(result => {
        this.setState({
          fileList: [],
        })
        r(result.data)
      }).catch(err => {
        r(false)
      })
    })
  }

  importFile = () => {
    return new Promise((r, j) => {
      axios({
        method: 'POST',
        headers: {
          'X-Token': localStorage.getItem('token'),
          'X-Module': 'product',
        },
        data: { id: this.state.fileList[0].response.id },
        url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/import/excel`,
      }).then(result => {
        return (result.data)
      }).then(data => {
        this.props.onClose(data)
        r(data)
      }).catch(err => {
        r(false)
      })
    })
  }

  render() {
    return (
      <div>
        <Upload
          accept={this.bookTypes.join(',')}
          beforeUpload={(file, fileList) => {
            let extension = file.name.split('.')
            if (this.bookTypes.indexOf('.' + extension[extension.length - 1]) === -1) {
              toast(false, this.props.langData['Please select a valid excel file.'])

              return Promise.reject()
            }
          }}
          onChange={({ file, fileList }) => {
            if (file.error && file.status === 'error')
              toast(false, this.props.langData['An unexpected error was encountered while importing.'])
            if (file.status !== 'removed')
              this.setState({
                fileList: [file],
              })
          }}
          name="excel-file"
          headers={{
            'X-Token': localStorage.getItem('token'),
            'X-Module': 'product',
          }}
          fileList={this.state.fileList}
          multiple={false}
          action={`${process.env.REACT_APP_IMPORT_EXPORT_URL}/upload/excel`}
          onRemove={this.deleteFile}
          listType="picture"
          iconRender={(file) => {
            return <Icon type="file-excel"/>
          }}
          disabled={this.props.waiting}
        >
          <Button
            style={{ width: '100%', height: '150px' }}
            disabled={this.state.fileList.length}
          >
            <p>
              <Icon type="file-excel" style={{ fontSize: 45, color: '#1890ff', marginBottom: 10 }}/>
            </p>
            <p style={{ color: '#555' }}>
              {! this.props.waiting ? (this.state.fileList.length ?
                  this.props.langData['To make a different file selection, remove the file you previously selected.']
                  :
                  this.props.langData['Click or drag .xls file to this area to upload']
              ) : (
                this.props.langData['Wait for finished uploading.']
              )}
            </p>
          </Button>
        </Upload>
        <div
          style={{ padding: 8 }}>{this.props.langData['Your products recorded in our database will be updated. Other products will be recorded in the database.']}</div>
        <a
          download
          href={`${process.env.REACT_APP_IMPORT_EXPORT_URL}/excel/template/${localStorage.getItem('token')}`}
        >
          <Button
            size="large"
            style={{ marginTop: 10 }}
          >
            {this.props.langData['Download template']}
          </Button>
        </a>
        <Button
          onClick={this.importFile}
          type="primary"
          size="large"
          style={{ marginTop: 10, marginLeft: 10 }}
          disabled={ ! this.state.fileList.filter(x => x.status === 'done').length || this.props.waiting}
        >
          {this.props.langData['Import']}
        </Button>

        <Button size="large" style={{ marginTop: 10, marginLeft: 10, float: 'right' }}
                onClick={() => this.props.openExcelSavingModal()}>
          {this.props.langData['Record Control']}
        </Button>

      </div>
    )

  }
}

ExcelUpload.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  onClose: PropTypes.func,
  waiting: PropTypes.bool,
  openExcelSavingModal: PropTypes.func,
}

export default connect(mapStateToProps)(ExcelUpload)
