import React, { useEffect, useState } from 'react'
import { postPanelApi } from '../../../api'
import { Button, Card, Table } from 'antd'
import { QuestionOutlined, SaveOutlined } from '@ant-design/icons'
import { successToast } from '../../../helpers/helper'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import PropTypes from "prop-types";

function SyncInvoice(props) {

  const transactionId = Number(props.match.params.id)

  const [submitting, setSubmitting] = useState(false)
  const [invoiceAddress, setInvoiceAddress] = useState({
    old_invoice_address: '',
    new_invoice_address: '',
  })

  useEffect(() => {

    postPanelApi('/integration/tsoft/sync-invoice-open', { transaction_id: transactionId }).then(result => {

      if (result) {

        setInvoiceAddress({
          old_invoice_address: result.old_invoice_address,
          new_invoice_address: result.new_invoice_address,
        })

      }

    })

  })

  const columns = [
    {
      title: 'Değişecek Bilgi',
      dataIndex: 'title',
    },
    {
      title: 'Mevcut Bilgi',
      dataIndex: 'old',
    },
    {
      title: 'Yeni Bilgi',
      dataIndex: 'new',
    },
  ]

  const data = [
    {
      key: '1',
      title: 'Fatura Adresi',
      old: invoiceAddress.old_invoice_address,
      new: invoiceAddress.new_invoice_address,
    },
  ]

  const handleSubmit = () => {

    setSubmitting(true)

    postPanelApi('/integration/tsoft/sync-invoice-submit', { transaction_id: transactionId }).then(result => {

      if (result) {

        successToast(result.message)

        setTimeout(() => props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
          type: 'sales',
          id: transactionId,
        })), 500)

      } else {

        setSubmitting(false)

      }

    })

  }

  return (
    <Card
      title={<span><QuestionOutlined/> Bilgileri Kontrol Ediniz</span>}
    >

      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        footer={() => {
          return (
            <Button
              type="primary"
              size="large"
              disabled={submitting}
              onClick={handleSubmit}
            >
              <SaveOutlined/> Değişiklikleri Kaydet
            </Button>
          )
        }}
      />

    </Card>
  )

}

SyncInvoice.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default SyncInvoice
