import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import ProductForm from '../../ProductForm/ProductForm'
import { generatePath } from 'react-router-dom'
import routes from '../../../../routes'


const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Create Product Copy' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewProductCopy extends Component {
  product_copy_id

  constructor(props) {
    super(props)
    this.product_copy_id = parseInt(props.match.params.id)
  }

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.PRODUCT_COPY, {
      id: this.product_copy_id,
    }))
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedNewProductCopyForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ProductForm)
    return <WrappedNewProductCopyForm
      product_copy_id={this.product_copy_id} {...this.props}></WrappedNewProductCopyForm>
  }
}

NewProductCopy.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  id: PropTypes.number,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProductCopy)
