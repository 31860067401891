import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import { Table, Input, Select, Form, Button, Icon, Popover, Checkbox, Tooltip, Col, Row, Spin } from 'antd'
import { connect } from 'react-redux'
import {
  deleteEmpty,
  toLocaleString,
  graphQLErrorsToToast,
  createCsv,
  confirm,
  extractExcel,
} from '../../../helpers/helper'
import { multipleDeleteData, searchCustomer } from '../../../helpers/trait'
import { getCurrencyWithCode, getCurrencyWithId } from '../../../data/Currency'
import moment from 'moment'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { cloneDeep } from 'lodash'

import ExcelExtractModal from '../../../utils/ExcelExtractModal'
import routes from '../../../routes'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
    filterData: state.TableFilterReducer.data.Customer,
    filterFieldsData: state.TableFilterReducer.data.CustomerFieldsData,
    pageInfo: state.TableFilterReducer.data.CustomerPage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Customer' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'Customer' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'CustomerFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'CustomerPage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'Customer' } })
      dispatch({ type: 'resetFilter', payload: { name: 'CustomerFieldsData' } })
    },
  }
}

const LIST_QUERY = fieldsQuery => gql`
  query($filter: CustomerFilterBase, $sorting: CustomerSorting, $paging: Paging, $currency: Currency, $date: Date!){
    customerList(filter: $filter, sorting: $sorting, paging: $paging){
      customers{
        customer_id
        ${fieldsQuery}
      }
      page_info{
        total_count
      }
    }
    currencyRates(currency:$currency date: $date){
      currency_rates{
        try
        usd
        eur
        gbp
        rub
      }
    }

    legacyData {
      currency_id
    }

  }
`

class CustomerList extends Component {

  currencyId = 1

  fields = []
  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    currency: null,
    filter: null,
    columns: null,
    paging: {
      offset: 0,
      limit: 50,
    },
    sorting: null,
    selectedRows: [],
    isDeleteEnable: false,
    isExtractExcel: false,
  }

  constructor(props) {
    super(props)

    this.multipleDeleteData = multipleDeleteData('customer', 'customer_id').bind(this)
    this.fields = this.props.sizeWidth < 576 ? ['name', 'balance', 'phone_1'] : ['name', 'balance', 'e_mail', 'category', 'phone_1']
    if (localStorage.getItem('customer_list_columns'))
      this.fields = localStorage.getItem('customer_list_columns').split(',')
  }

  onChangeField = fields => {
    if ( ! fields.length)
      return
    let columns = cloneDeep(this.state.columns)
    columns.map(x => {
      if (fields.indexOf(x.fieldName) !== -1)
        x.hide = false
      else
        x.hide = true
      x.width = 100 / fields.length + '%'
    })

    this.setState({
      columns,
    })

    localStorage.setItem('customer_list_columns', fields)

    this.fields = fields
  }

  componentDidMount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_add(routes.CUSTOMER_LIST)

    this.setState({
      columns: [
        {
          title: this.props.langData['Name'],
          fieldName: 'name',
          fieldLabel: 'Name',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('name') === -1,
          dataIndex: 'name',
          sorter: true,
          key: 'name',
        },
        {
          title: this.props.langData['Category'],
          fieldName: 'category',
          fieldLabel: 'Category',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('category') === -1,
          subFields: ['name'],
          dataIndex: 'category',
          key: 'category',
          align: 'center',
          render: record => record ? record.name : '',
        },
        {
          title: this.props.langData['Code'],
          fieldName: 'code',
          fieldLabel: 'Code',
          sorter: true,
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('code') === -1,
          dataIndex: 'code',
          key: 'code',
          align: 'center',
        },
        {
          title: this.props.langData['Marketplace'],
          fieldName: 'is_market_place',
          fieldLabel: 'Marketplace',
          sorter: true,
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('is_market_place') === -1,
          dataIndex: 'is_market_place',
          key: 'is_market_place',
          align: 'center',
          render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: 18 }}/> : null,
        },
        {
          title: this.props.langData['E-Mail'],
          fieldName: 'e_mail',
          fieldLabel: 'E-Mail',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('e_mail') === -1,
          dataIndex: 'e_mail',
          key: 'e_mail',
          align: 'center',
        },
        {
          title: this.props.langData['Country'],
          fieldName: 'country',
          fieldLabel: 'Country',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('country') === -1,
          dataIndex: 'country',
          key: 'country',
          align: 'center',
        },
        {
          title: this.props.langData['City'],
          fieldName: 'city',
          fieldLabel: 'City',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('city') === -1,
          dataIndex: 'city',
          key: 'city',
          align: 'center',
        },
        {
          title: this.props.langData['Phone'],
          fieldName: 'phone_1',
          fieldLabel: 'Phone',
          hide: this.fields.indexOf('phone_1') === -1,
          dataIndex: 'phone_1',
          width: 100 / this.fields.length + '%',
          key: 'phone_1',
          align: 'center',
        },
        {
          title: this.props.langData['Balance'],
          dataIndex: 'balance',
          fieldName: 'balance',
          fieldLabel: 'Balance',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('balance') === -1,
          sorter: true,
          align: 'right',
          key: 'balance',
          subFields: ['try', 'usd', 'eur', 'gbp', 'rub'],
          sorterNames: ['try', 'usd', 'eur', 'gbp', 'rub'],
          render: data => {
            let balance = 0
            let balancePropt = []
            if (data) {
              Object.keys(data).map(x => {
                if (data[x] !== 0 && x !== '__typename') {
                  balance += parseFloat((data[x] / this.state.currency[x]).toFixed(4))
                  balancePropt.push(<span
                    style={{ display: 'block' }}>{data[x] + ' ' + getCurrencyWithCode(x).symbol}</span>)
                }
              })
            }

            balance = toLocaleString(balance)
            if (balance) {
              return (
                <Tooltip title={balancePropt}>
                  <span>{`${balance} ${getCurrencyWithId(this.currencyId).symbol}`}</span>
                </Tooltip>
              )
            }
            return <span>{`${balance} ${getCurrencyWithId(this.currencyId).symbol}`}</span>
          },
        },
      ],
      filter: this.props.filterData,
    })
    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }
  }

  componentWillUnmount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_delete()
  }

  getVisibleColumnsFieldName = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide).map(x => x.fieldName) : []
  }

  getVisibleColumnsSubField = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide).map(x => x.subFields) : []
  }

  getQueryWithSubFields = (fields, subFields) => {
    let query = ''
    subFields.forEach((e, i) => {
      e = e === undefined ? '' : '{' + e + '}'
      query += fields[i] + ' ' + e
    })

    return query
  }

  getColumnsFieldNameWithLabel = () => {
    return this.state.columns ? this.state.columns.map(x => ({
      label: this.props.langData[x.fieldLabel],
      value: x.fieldName,
      key: x.key,
    })) : []
  }

  getVisibleRawColumns = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide) : []
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
    else {
      this.setState({
        sorting: null,
      })
    }
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({ filter: null })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.CUSTOMER_DETAIL, { id: record.customer_id }))
  }

  multipleDelete = refetch => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.multipleDeleteData(this.state.selectedRows, refetch)
      this.setState({
        selectedRows: [],
        isDeleteEnable: false,
      })
    })
  }

  render() {
    return (
      <Query
        query={LIST_QUERY(this.getQueryWithSubFields(this.getVisibleColumnsFieldName(), this.getVisibleColumnsSubField()))}
        fetchPolicy="network-only"
        skip={ ! this.state.columns}
        variables={{
          filter: this.state.filter,
          sorting: this.state.sorting,
          paging: this.state.paging,
          date: moment().format('YYYY-MM-DD HH:mm'),
        }}
        onError={graphQLErrorsToToast}
      >
        {({ loading: loadingQuery, data, refetch }) => {

          if (typeof data !== 'undefined') {
            if ( ! loadingQuery) {
              if ( ! this.state.data) {

                this.currencyId = data.legacyData.currency_id

                this.setState({
                  data: cloneDeep(data.customerList.customers),
                  pagination: {
                    ...this.state.pagination,
                    total: data.customerList.page_info.total_count,
                  },
                })
              } else if (this.state.data && JSON.stringify(data.customerList.customers) !== JSON.stringify(this.state.data)) {
                this.setState({
                  data: cloneDeep(data.customerList.customers),
                  pagination: {
                    pageSize: this.state.pagination.pageSize,
                    total: data.customerList.page_info.total_count,
                  },
                })
              }
              if ( ! this.state.currency) {
                this.setState({
                  currency: cloneDeep(data.currencyRates.currency_rates),
                })
              } else if (this.state.currency && JSON.stringify(this.state.currency) !== JSON.stringify(data.currencyRates.currency_rates)) {
                this.setState({
                  currency: cloneDeep(data.currencyRates.currency_rates),
                })
              }
            }
          }

          this.props.refetch && refetch()

          return (
            <div>
              <FilterBox
                callback={this.filterCallback}
                isSubmit={loadingQuery}
                csv={createCsv.bind(this)}
                isModal={this.props.modalClose}
                extractExcel={() => this.setState({ isExtractExcel: true })}
                multipleDelete={() => {
                  this.multipleDelete(refetch)
                }}
                {...this.props}
              />
              {this.state.isExtractExcel &&
                <ExcelExtractModal type="customer" onCancel={() => this.setState({ isExtractExcel: false })}
                                   onOk={extractExcel.bind(this)} {...this.props} />}
              { ! this.props.modalClose && <Row type="flex" justify="start">
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={3}
                     xl={3}>
                  <Popover content={(
                    <div>
                      <Checkbox.Group options={this.getColumnsFieldNameWithLabel()} onChange={fields => {
                        this.onChangeField(fields)
                        refetch()
                      }} value={this.fields} style={{ width: 110 }}/>
                    </div>
                  )} trigger="click" placement="bottom">
                    <Button icon="menu" block={true} style={{ marginBottom: 10, width: '100%', borderRadius: 0 }}>
                      {this.props.langData['Customize']}
                    </Button>
                  </Popover>
                </Col>
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={3}
                     xl={3}>
                  <Link to={routes.CUSTOMER_NEW}>
                    <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true} icon="plus-circle">
                      {this.props.langData['Add New One']}
                    </Button>
                  </Link>
                </Col>
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={3}
                     xl={3}>
                  {this.state.isDeleteEnable &&
                    <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                            onClick={() => this.multipleDelete(refetch)}><Icon
                      type="delete"/> {this.props.langData['Delete']} </Button>}
                </Col>
              </Row>}
              <Table
                columns={this.getVisibleRawColumns()}
                rowKey={record => record.customer_id}
                dataSource={this.state.data}
                loading={loadingQuery}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => {
                    if ( ! this.props.rowItemClick)
                      return this.rowItemClick(record)
                    this.props.rowItemClick(record)
                  },
                })}
                rowSelection={! this.props.modalClose ? {
                  selectedRowKeys: this.state.selectedRows.length ? this.state.selectedRows.map(x => x.customer_id) : [],
                  onChange: x => {
                    this.setState({
                      selectedRows: x.map(y => ({ customer_id: y })),
                      isDeleteEnable: x.length ? true : false,
                    })
                  },
                  columnWidth: 60,
                } : undefined}
                rowClassName="white"
                scroll={{
                  x: this.state.columns ? this.state.columns.filter(x => ! x.hide).length * 100 : 500,
                  y: this.props.scrollHeight ? this.props.scrollHeight : (! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)'),
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

CustomerList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  noBreadCrumb: PropTypes.bool,
  rowItemClick: PropTypes.func,
  modalClose: PropTypes.func,
  scrollHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  refetch: PropTypes.bool,
  email_verification: PropTypes.bool,
  filter: PropTypes.func,
  filterData: PropTypes.func,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.func,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.func,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isSubmit !== nextProps.isSubmit)
      this.setState({ isSubmit: nextProps.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

const CATEGORY_LIST_QUERY = gql`
  {
    customerCategoryList {
      category_id
      name
    }
  }`

class FilterBoxForm extends Component {

  initialFilterItems = {
    category_id: {
      label: this.props.langData['Category'],
      width: 380,
      operator: 'eq',
      added: false,
    },
    balance: {
      label: this.props.langData['Balance'],
      width: 400,
      added: false,
    },
    code: {
      label: this.props.langData['Code'],
      width: 350,
      added: false,
      operator: 'starts_with',
    },
    e_mail: {
      value: '',
      label: this.props.langData['E-Mail'],
      width: 400,
      added: false,
      operator: 'contains',
    },
    is_market_place: {
      label: this.props.langData['Marketplace'],
      width: 300,
      added: false,
      operator: 'eq',
    },
    id_or_tax_identification_number: {
      label: this.props.langData['Id/Tax Identification Number'],
      width: 300,
      added: false,
      operator: 'contains',
    },
    name: {
      required: true,
      operator: 'contains',
    },
  }

  state = {
    selectedOptions: [],
    categoryList: [],
    isSubmit: false,
    customerList: [],
    fetchingCustomer: false,
    filterItems: this.initialFilterItems,
  }

  constructor(props) {
    super(props)
    this.searchCustomer = searchCustomer('invoice[customer_id]').bind(this)
  }

  componentDidMount() {

    if (this.props.filterFieldsData) {

      const filterItems = cloneDeep(this.initialFilterItems)

      for (const [key, value] of Object.entries(this.props.filterFieldsData)) {

        if (filterItems.hasOwnProperty(key)) {

          if (filterItems[key].required !== true) {
            filterItems[key].added = true
          }

          this.props.form.getFieldDecorator(key, {
            initialValue: value,
          })

        }

      }

      this.setState({
        filterItems: filterItems,
      })

    }

  }

  onChangeCheckbox = options => {

    const filterItems = cloneDeep(this.initialFilterItems)

    for (const option of options) {

      if (filterItems.hasOwnProperty(option)) {
        if (filterItems[option].required !== true) {
          filterItems[option].added = true
        }
      }

    }

    this.setState({
      filterItems: filterItems,
    })

  }

  onSubmit = form => {

    form.validateFields((err, values) => {

      if (err) {
        return
      }

      deleteEmpty(values)

      const filterData = []

      for (const [key, value] of Object.entries(values)) {

        if ( ! this.state.filterItems.hasOwnProperty(key)) {
          continue
        }

        switch (key) {

          case 'balance': {

            // balance için value bilgisi json olarak geliyor, ["ne","OR"] şeklinde, 4 farklı ihtimal vardı
            const data = JSON.parse(value)

            filterData.push({
              [data['1']]: [
                { [key]: { ['try']: { [data['0']]: 0 } } },
                { [key]: { ['usd']: { [data['0']]: 0 } } },
                { [key]: { ['eur']: { [data['0']]: 0 } } },
                { [key]: { ['rub']: { [data['0']]: 0 } } },
                { [key]: { ['gbp']: { [data['0']]: 0 } } },
              ],
            })

            break

          }

          default: {

            filterData.push({
              [key]: {
                [this.state.filterItems[key].operator]: value,
              },
            })

            break

          }

        }

      }

      this.props.callback(filterData.length > 0 ? filterData : null, values)

    })

  }

  notRequiredFilterItems() {

    const items = []

    for (const [key, value] of Object.entries(this.state.filterItems)) {

      if (value.required !== true) {
        items.push({
          value: key,
          label: value.label,
          added: value.added,
        })
      }

    }

    return items

  }

  notRequiredAndAddedItems() {
    return this.notRequiredFilterItems().filter(x => x.added === true)
  }

  render() {

    const { getFieldDecorator } = this.props.form

    const options = this.notRequiredFilterItems().map(x => ({
      value: x.value,
      label: x.label,
    }))

    const selectedValues = this.notRequiredAndAddedItems().map(x => x.value)

    let headerContent = [
      ! this.props.isModal &&
      <Popover placement="bottomLeft" content={
        <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
          <Button key="1" onClick={this.props.csv} style={{
            width: '-webkit-fill-available',
            marginBottom: '10px',
          }}>{this.props.langData['Csv Export']}</Button>
          <Row xs="24" sm="24" md="24" lg="24" xl="24" xxl="24">
            <Button key="2" onClick={() => this.props.extractExcel(true)}>
              {this.props.langData['Xlsx Export']}
            </Button>
          </Row>
        </Row>
      } trigger="click">
        <Button><Icon type="file-unknown"/></Button>
      </Popover>,

      <CheckboxedDropdown
        key="2"
        width={250}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedValues}
        onChange={this.onChangeCheckbox}
      />,

    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let contentItems = [...filterHeader]

    if (this.state.filterItems.category_id.added) {
      contentItems.push(
        getFieldDecorator('category_id')(
          <Select
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.category_id.width : null }}
            allowClear
            key="5"
            suffixIcon={<Icon type="caret-down"/>}
            placeholder={this.props.langData['Category']}
          >
            {this.state.categoryList.map(x => <Select.Option value={x.category_id}
                                                             key={x.category_id}>{x.name}</Select.Option>)}
          </Select>,
        ),
      )
    }

    if (this.state.filterItems.balance.added) {
      contentItems.push(
        getFieldDecorator('balance')(
          <Select
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.balance.width : null }}
            allowClear
            key="6"
            placeholder={this.props.langData['Balance']}
            suffixIcon={<Icon type="caret-down"/>}
          >
            <Select.Option value='["ne","OR"]'>{this.props.langData['Has Balance']}</Select.Option>
            <Select.Option value='["eq", "AND"]'>{this.props.langData['Has No Balance']}</Select.Option>
            <Select.Option value='["gt","OR"]'>{this.props.langData['Borrower Customer']}</Select.Option>
            <Select.Option value='["lt","OR"]'>{this.props.langData['Lender Customer']}</Select.Option>
          </Select>,
        ),
      )
    }

    if (this.state.filterItems.code.added) {
      contentItems.push(
        getFieldDecorator('code')(
          <Input key="7" placeholder={this.props.langData['Code']} allowClear
                 style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.code.width : null }}/>,
        ),
      )
    }

    if (this.state.filterItems.e_mail.added) {
      contentItems.push(
        getFieldDecorator('e_mail')(
          <Input key="8" placeholder={this.props.langData['E-Mail']} allowClear
                 style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.e_mail.width : null }}/>,
        ),
      )
    }

    if (this.state.filterItems.is_market_place.added) {
      contentItems.push(
        getFieldDecorator('is_market_place')(
          <Select
            allowClear
            placeholder={this.props.langData['Marketplace']}
            key="9"
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.is_market_place.width : null }}
          >
            <Select.Option value={false}>{this.props.langData['Customer']}</Select.Option>
            <Select.Option value={true}>{this.props.langData['Marketplace Customer']}</Select.Option>
          </Select>,
        ),
      )
    }

    if (this.state.filterItems.id_or_tax_identification_number.added) {
      contentItems.push(
        getFieldDecorator('id_or_tax_identification_number')(
          <Input
            key="10"
            placeholder={this.props.langData['Id/Tax Identification Number']}
            allowClear
            style={{ width: this.props.sizeWidth > 576 ? this.state.filterItems.id_or_tax_identification_number.width : null }}
          />,
        ),
      )
    }

    contentItems.push((
      getFieldDecorator('name')(
        <Select
          filterOption={false}
          showSearch
          style={{ width: '100%' }}
          suffixIcon={<Icon type="caret-down"/>}
          notFoundContent={this.state.fetchingCustomer ? <Spin size="large"/> : null}
          onSearch={this.searchCustomer}
          allowClear
          placeholder={this.props.langData['Name']}
          key="11"
          defaultActiveFirstOption={true}
          onChange={
            () => {
              setTimeout(() => {
                this.onSubmit(this.props.form)
              }, 100)
            }
          }
        >
          {this.state.customerList.map((x, k) => {
            return <Select.Option value={x.name} key={k}>{x.name}</Select.Option>
          })}
        </Select>,
      )
    ))

    if (this.props.sizeWidth > 576) {
      return (
        <Query
          query={CATEGORY_LIST_QUERY}
          fetchPolicy="network-only"
          onError={graphQLErrorsToToast}
          onCompleted={data => {
            this.setState({
              categoryList: data.customerCategoryList,
            })
          }}
        >
          {() => {

            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {contentItems.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )
    }

    return (
      <Query
        query={CATEGORY_LIST_QUERY}
        fetchPolicy="network-only"
        onError={graphQLErrorsToToast}
        onCompleted={data => {
          this.setState({
            categoryList: data.customerCategoryList,
          })
        }}
      >
        {() => {

          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {contentItems.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )
  }
}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  isSubmit: PropTypes.bool,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  print: PropTypes.func,
  csv: PropTypes.func,
  isModal: PropTypes.func,
  isDeleteEnable: PropTypes.bool,
  multipleDelete: PropTypes.func,
  colOnChange: PropTypes.func,
  colFields: PropTypes.array,
  colOptions: PropTypes.array,
  filterFieldsData: PropTypes.object,
  extractExcel: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList)
