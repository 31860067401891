const token = localStorage.getItem('token')

const initialState = {

  auth_loaded: false,

  token: token ?? '',

  manager_grade: null,
  email_verification: true,

  company_id: null,

  licences: {
    connectprof: false,
    hook: false,
    tahsildar: false,
    tsoft_integration: false,
  },

}

export const AuthReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'set_token_info':

      localStorage.setItem('token', action.payload.token)

      return {
        auth_loaded: true,

        token: action.payload.token,
        manager_grade: action.payload.manager_grade,

        company_id: action.payload.company_id,
        email_verification: action.payload.email_verification,

        licences: action.payload.licences,
      }

    case 'clear_token_info':

      localStorage.removeItem('token')

      return {
        ...state,
        token: '',
      }

    default:

      return state

  }

}
