import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(localStorage.getItem('token') ? { 'X-Token': localStorage.getItem('token') } : {}),
      'Accept-Language': localStorage.getItem('lang'),
    },
  }))

  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export default client
