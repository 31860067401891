import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Table, Icon, Button, Card, Form, Input } from 'antd'
import apolloClient from '../../../helpers/apolloClient'
import AttributeForm from '../SubProductAttributesForm/SubProductAttributesForm'
import { connect } from 'react-redux'
import { confirm, graphQLErrorsToToast, toast } from './../../../helpers/helper'
import { deleteData } from './../../../helpers/trait'
import { Query } from '@apollo/client/react/components'
import routePaths from './../../../localize/routePaths'
import { Link, Route, Switch as RouterSwitch } from 'react-router-dom'
import { compile } from 'path-to-regexp'
import { gql } from '@apollo/client'

import { cloneDeep } from 'lodash'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Attributes', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}
const LIST_QUERY = (
  gql`
    query($paging: Paging) {
      subProductAttributeGroupList(paging: $paging) {
        attribute_groups {
          attribute_group_id
          name
          attributes {
            attribute_id
            attribute_group_id
            title
            code
          }
        }
        page_info {
          total_count
        }
      }
    }
  `
)

const ATTRIBUTE_GROUP_CREATE_QUERY = (
  gql`
    mutation attribute_group($name: String!){
      attribute_group: subProductAttributeGroupCreate(name: $name){
        attribute_group {
          attribute_group_id
        }
        informative{
          messages
        }
      }
    }
  `
)

const ATTRIBUTE_GROUP_UPDATE_QUERY = (
  gql`
    mutation attribute_group($attribute_group_id: Int!, $attribute_group: SubProductAttributeGroupUpdateInput){
      attribute_group: subProductAttributeGroupUpdate(attribute_group_id: $attribute_group_id, attribute_group: $attribute_group){
        attribute_group{
          attribute_group_id
        }
        informative {
          messages
        }
      }
    }
  `
)

class SubProductAttributes extends Component {
  state = {
    data: null,
    initialize: false,
    type: null,
    visible_attributes: null,
    expandedKey: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    editRow: [],
    newRowValue: null,
    refetch: null,
    editingRow: false,
  }

  attributesColumns = [
    {
      title: (
        <Button type="primary" icon="file-add" onClick={() => {
          this.props.history.push(
            '/' + compile(this.props.langData.route['sub-product/attributes/:id/attribute/new'])({ id: this.state.expandedKey }),
          )
        }}/>
      ),
      key: 'update',
      width: '3%',
      align: 'center',
      render: record => <Link
        to={'/' + compile(this.props.langData.route['sub-product/attributes/:id/attribute/update/:attribute_id'])({
          id: this.state.expandedKey,
          attribute_id: record.attribute_id,
        })}><Icon type="edit" theme="filled"/></Link>,
    },
    {
      title: this.props.langData['Name'],
      dataIndex: 'title',
      width: '50%',
      key: 'title',
    },
    {
      title: this.props.langData['Code'],
      dataIndex: 'code',
      width: '44%',
      key: 'code',
    },
    {
      title: '',
      key: 'delete',
      width: '3%',
      align: 'center',
      render: record => <Icon type="delete" theme="filled" onClick={() => this.deleteAttribute(record.attribute_id)}
                              style={{ cursor: 'pointer' }}/>,
    },
  ]

  columns = [
    {
      title: '',
      key: 'update',
      width: '3%',
      align: 'center',
      render: (record, data) => {
        if ( ! record.key)
          return <Icon type="edit" theme="filled" onClick={() => this.editRowAction(record, data)} size="small"/>
        else
          return <Button type="primary" icon="check" disabled={ ! this.state.newRowValue}
                         onClick={() => this.onSubmit(this.state.newRowValue, record.attribute_group_id ? record.attribute_group_id : null)}
                         size="small"/>
      },
    },
    {
      title: this.props.langData['Attribute Groups'],
      dataIndex: 'name',
      width: '94%',
      key: 'name',
    },
    {
      title: '',
      key: 'delete',
      width: '3%',
      align: 'center',
      render: record => {
        if ( ! record.key)
          return <Icon type="delete" theme="filled" onClick={() => this.delete(record.attribute_group_id)}
                       style={{ cursor: 'pointer' }}/>
        else
          return <Button type="danger" icon="close" size="small" onClick={() => this.closeEditRow()}/>
      },
    },
  ]

  closeEditRow = () => {
    if ( ! this.state.editingRow)
      this.setState({
        editRow: [],
        newRowValue: null,
      })
    else {
      this.setState({
        editingRow: false,
        newRowValue: null,
      })
    }
  }

  onSubmit = async (input, attribute_group_id) => {
    try {
      if ( ! input)
        return
      let variables = {}
      if (this.state.editingRow) {
        variables.attribute_group = { name: input }
        variables.attribute_group_id = attribute_group_id
      } else {
        variables.name = input
      }

      const result = await apolloClient.mutate({
        mutation: this.state.editingRow ? ATTRIBUTE_GROUP_UPDATE_QUERY : ATTRIBUTE_GROUP_CREATE_QUERY,
        variables,
      })

      if (result !== undefined)
        toast(true, result.data.attribute_group.informative.messages)
      if (this.state.refetch)
        this.state.refetch()

      this.setState({
        editRow: [],
        newRowValue: null,
        editingRow: false,
      })
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }

  delete(attribute_group_id) {
    this.deleteData = deleteData('subProductAttributeGroup', 'attribute_group_id', false, this.state.refetch).bind(this)
    confirm(undefined, result => {
      if (result) {
        this.deleteData(attribute_group_id)
      }
    })
  }

  deleteAttribute(attribute_id) {
    this.deleteData = deleteData('subProductAttribute', 'attribute_id', false, this.state.refetch).bind(this)
    confirm(undefined, result => {
      if (result) {
        this.deleteData(attribute_id)
      }
    })
  }

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['sub-product/attributes'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  editRowAction = data => {
    this.closeEditRow()

    if ( ! data) {
      this.setState({
        editRow: [{
          name: (
            <Input
              onChange={e => {
                e.preventDefault()
                this.setState({
                  newRowValue: e.target.value,
                })
              }}
              allowClear
              onPressEnter={e => {
                e.preventDefault()
                this.onSubmit(e.target.value)
              }}
            />),
          key: 'editRow',
        }],
      })
    } else {
      const willEditRow = this.state.data.map(x => {
        if (x.attribute_group_id === data.attribute_group_id) {
          x = {
            name: (<Input
              onChange={e => {
                e.preventDefault()
                this.setState({
                  newRowValue: e.target.value,
                })
              }}
              defaultValue={data.name}
              allowClear
              onPressEnter={e => {
                e.preventDefault()
                this.onSubmit(e.target.value, data.attribute_group_id)
              }}
            />),
            key: 'editUpdateRow',
            attribute_group_id: data.attribute_group_id,
          }
        }

        return x
      })

      this.setState({
        data: willEditRow,
        editingRow: true,
      })
    }
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize)
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
      })

    if (sorting.field) {
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
    }
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ paging: this.state.paging }}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.editingRow) {
            if ( ! this.state.data)
              this.setState({
                data: cloneDeep(data.subProductAttributeGroupList.attribute_groups),
                pagination: {
                  pageSize: this.state.pagination.pageSize,
                  total: data.subProductAttributeGroupList.page_info.total_count,
                },
              })
            else if (JSON.stringify(this.state.data) !== JSON.stringify(data.subProductAttributeGroupList.attribute_groups)) {
              this.setState({
                data: cloneDeep(data.subProductAttributeGroupList.attribute_groups),
                pagination: {
                  pageSize: this.state.pagination.pageSize,
                  total: data.subProductAttributeGroupList.page_info.total_count,
                },
              })
            }

            this.setState({
              refetch,
            })
          }

          if (this.props.match.params.id && this.state.data)
            this.setState({
              expandedKey: parseInt(this.props.match.params.id),
              visible_attributes: this.state.data.filter(x => x.attribute_group_id === parseInt(this.props.match.params.id)).map(x => x.attributes)[0],
            })

          return (
            this.state.data && <div>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 16 }}>
                  <Button type="primary" style={{ marginBottom: 10 }} icon="file-add"
                          disabled={this.state.editRow.length}
                          onClick={() => this.editRowAction()}>{this.props.langData['Add New One']}</Button>
                  <Table
                    columns={this.columns}
                    rowKey={record => record.key ? record.key : record.attribute_group_id}
                    dataSource={(this.state.editRow || []).concat(this.state.data || [])}
                    loading={ ! this.state.data}
                    pagination={this.state.pagination}
                    rowClassName="white"
                    bordered
                    expandedRowKeys={[this.state.expandedKey]}
                    onChange={this.handleTableChange}
                    onExpand={(expanded, record) => {
                      if ( ! record.key)
                        if (expanded) {
                          this.setState({
                            expandedKey: record.attribute_group_id,
                            visible_attributes: record.attributes,
                          })

                          this.props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: record.attribute_group_id }))
                        } else {
                          this.setState({
                            expandedKey: null,
                            visible_attributes: null,
                          })

                          this.props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: null }))
                        }
                      else
                        return false
                    }}
                    expandedRowRender={record => {
                      if ( ! record.key && this.state.expandedKey)
                        return (
                          <div>
                            <Row gutter={16}>
                              <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: 16 }}>
                                <Table
                                  columns={this.attributesColumns}
                                  rowKey={recordA => recordA.attribute_id}
                                  dataSource={record.attributes || []}
                                  bordered
                                  pagination={{
                                    ...this.state.pagination,
                                    pageSize: 50,
                                    size: 'small',
                                  }}
                                  rowClassName="white"
                                />
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <RouterSwitch>
                                  <Route exact path={routePaths['sub-product/attributes/:id/attribute/new']}
                                         render={props => {
                                           const WrappedAttributeForm = Form.create({
                                             validateMessages: this.props.langData.formValidationMessages,
                                           })(AttributeForm)
                                           return (
                                             <Card
                                               title={<span><Icon
                                                 type="file-add"/> &nbsp;{this.props.langData['Attribute New']}</span>}
                                               bodyStyle={{ padding: 8 }}
                                               footer={null}
                                               extra={
                                                 <Icon type="close" style={{ cursor: 'pointer' }}
                                                       onClick={() => {
                                                         props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: this.state.expandedKey }))
                                                       }}
                                                 />
                                               }
                                             >
                                               <WrappedAttributeForm
                                                 attribute_group_id={parseInt(props.match.params.id)}
                                                 refetch={() => {
                                                   refetch()
                                                   props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: this.state.expandedKey }))
                                                 }}
                                                 {...props}
                                               >
                                               </WrappedAttributeForm>
                                             </Card>
                                           )
                                         }}/>

                                  <Route exact
                                         path={routePaths['sub-product/attributes/:id/attribute/update/:attribute_id']}
                                         render={props => {
                                           const WrappedAttributeForm = Form.create({
                                             validateMessages: this.props.langData.formValidationMessages,
                                           })(AttributeForm)

                                           let visible_attributes = cloneDeep(this.state.visible_attributes)

                                           return (
                                             <Card
                                               title={<span><Icon
                                                 type="file-add"/> &nbsp;{this.props.langData['Update Attribute']}</span>}
                                               bodyStyle={{ padding: 8 }}
                                               footer={null}
                                               extra={
                                                 <Icon type="close" style={{ cursor: 'pointer' }}
                                                       onClick={() => {
                                                         props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: this.state.expandedKey }))
                                                       }}
                                                 />
                                               }
                                             >
                                               <WrappedAttributeForm
                                                 attribute_group_id={parseInt(props.match.params.id)}
                                                 attribute={visible_attributes.filter(x => x.attribute_id === parseInt(props.match.params.attribute_id))[0]}
                                                 attribute_id={parseInt(props.match.params.attribute_id)}
                                                 refetch={() => {
                                                   refetch()
                                                   props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: this.state.expandedKey }))
                                                 }}
                                                 {...props}
                                               >
                                               </WrappedAttributeForm>
                                             </Card>
                                           )
                                         }}/>

                                </RouterSwitch>
                              </Col>
                            </Row>
                          </div>
                        )
                      else
                        return false
                    }}
                  />
                </Col>
              </Row>
            </div>
          )
        }}
      </Query>
    )

  }

}

SubProductAttributes.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  sizeWidth: PropTypes.number,
  history: PropTypes.object,
  form: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubProductAttributes)
