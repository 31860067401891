import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Card, Divider, Select, DatePicker, InputNumber, Checkbox, Spin } from 'antd'
import {
  deleteNull,
  toast,
  graphQLErrorsToToast,
  updateEmptyAndUndefined,
  pushNotification,
} from '../../../helpers/helper'
import { currencyData, getCurrencyWithCode } from '../../../data/Currency'
import CustomerSearchModal from './../../Customer/CustomerSearchModal/CustomerSearchModal'
import moment from 'moment'
import { capitalize, debounce } from 'lodash'
import { searchCustomer, fetchCurrencyRates } from '../../../helpers/trait'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import routes from '../../../routes'
import { CalendarFilled, PayCircleOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 17 },
  },
}

const formItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 14 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 },
    lg: { span: 10 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const READ_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      data: ${type}(transaction_id: $transaction_id) {
      safe_id
      customer_id
      safe {
      currency
      }
      customer {
      customer_id
      name
      }
      amount
      date
      explanation
      currency_rates {
      try
      usd
      eur
      gbp
      rub
      }
      }

      safeList {
      safes {
      safe_id
      name
      currency
      default
      }
      }

      userInformation {
      settings {
      increment
      }
      }
      }
  `
)

const RETURN_INVOICE_DETAIL_QUERY = type => (
  gql`
      query($transaction_id: Int!){
          data: return${capitalize(type)}Invoice(transaction_id:$transaction_id) {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
              customer {
                  name
                  balance{
                      try
                      usd
                      eur
                      gbp
                      rub
                  }
              }
              products {
                  invoice_product_id
                  product_id
                  quantity
                  amount
                  total
                  vat
                  grand_total
                  product{
                      name
                  }
              }
              additional_charges {
                  invoice_additional_charge_id
                  additional_charge_id
                  quantity
                  amount
                  total
                  vat
                  grand_total
                  additional_charge{
                      name
                  }
              }

              currency_rates{
                  try
                  usd
                  eur
                  gbp
                  rub
              }
          }

          safeList {
              safes {
                  safe_id
                  name
                  currency
                  default
              }
          }

          userInformation {
              settings {
                  increment
              }
          }
      }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
      {
          safeList {
              safes {
                  safe_id
                  name
                  currency
                  default
              }
          }

          userInformation {
              settings {
                  increment
              }
          }
      }
  `
)

const READ_FOR_PAY_CREATE_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      data: ${type}Invoice(transaction_id: $transaction_id) {
      customer_id
      currency
      grand_total
      paid
      customer {
      customer_id
      name
      }
      }

      safeList{
      safes {
      safe_id
      name
      currency
      default
      }
      }
      userInformation {
      settings {
      increment
      }
      }
      }
  `
)

const CREATE_QUERY = type => (
  gql`
      mutation ($data: ${capitalize(type)}CreateInput!){
      data: ${type}Create(${type}: $data){
      data: ${type} {
      transaction_id
      }
      informative {
      messages
      }
      }
      }
  `
)

const UPDATE_QUERY = type => (
  gql`
      mutation ($transaction_id: Int!, $data: ${capitalize(type)}UpdateInput!){
      data: ${type}Update(transaction_id: $transaction_id, ${type}: $data){
      ${type} {
      transaction_id
      }
      informative {
      messages
      }
      }
      }
  `
)

class CreditDebitForm extends Component {
  increment = null
  callbackPage = props => {
    let address = this.props.transaction_id ? generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      type: this.props.langData[this.props.type],
      id: this.props.transaction_id,
    }) : ''

    if (props.priority_invoice) {
      if (props.id) {
        if (this.props.type === 'purchase') {
          address = generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
            id: this.props.priority_invoice,
          })
        } else {
          address = generatePath(routes.INVOICE_RETURN_BY_TYPE, {
            type: `${this.props.type}Detail`,
            id: this.props.priority_invoice,
          })
        }
      } else {
        address = generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
          type: this.props.langData[this.props.type],
          id: this.props.priority_invoice,
        })
      }
    } else if (props.customer) {
      address = generatePath(routes.CUSTOMER_DETAIL, { id: this.props.customer.customer_id })
    } else if (props.safe) {
      address = generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.props.safe.safe_id })
    }

    return address
  }

  state = {
    safeList: null,
    data: null,
    selectedSafe: null,
    currency: {},
    customerList: [],
    fetchingCustomer: false,
    addedCurrency: [],
    remaining: null,
    maxAmount: null,
    maxCurrencyRate: null,
    invoice_type: null,
    selectedSafeCurrencyAmount: true,
    modalCustomerSearch: false,
  }

  constructor(props) {
    super(props)
    this.onChangeDate = debounce(this.onChangeDate, 800)
    this.setMaxAmount = debounce(this.setMaxAmount, 800)
    this.searchCustomer = searchCustomer('data[customer_id]').bind(this)
    this.fetchCurrencyRates = fetchCurrencyRates('data[date]').bind(this)
  }

  componentDidMount() {
    // cari detaydan gönderilirken
    if (this.props.customer) {
      this.setState({ customerList: [this.props.customer] })
      this.props.form.setFieldsValue({ ['data[customer_id]']: this.props.customer.customer_id })
    }

    if (this.props.safe) {
      this.setState({
        selectedSafe: this.props.safe,
        safeList: [{ safe_id: this.props.safe.safe_id, ...this.props.safe.safeList }],
      })
      this.props.form.setFieldsValue({ ['data[safe_id]']: this.props.safe.safe_id })
    }
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return
      values.data.date = moment(values.data.date).format('YYYY-MM-DD HH:mm')
      values.data.amount = parseFloat(values.data.amount)

      if (this.props.transaction_id) {
        updateEmptyAndUndefined(values)
        values.transaction_id = Number(this.props.transaction_id)
      }

      // gönderilmeyen tüm kurları null yap
      if ( ! values.data.currency_rates) {
        values.data.currency_rates = {}
      }

      currencyData.forEach(x => {
        if ( ! values.data.currency_rates[x.code])
          values.data.currency_rates[x.code] = null
      })

      if (this.props.priority_invoice) {
        values.data.priority_invoice = this.props.priority_invoice
        //Sadece fatura ödemesi ise bu kontrol sağlandı.
        if (Number(values.data.amount).toFixed(0) === Number(this.state.data.grand_total - this.state.data.paid).toFixed(0)) {
          values.data.amount = this.state.data.grand_total
        }
      }

      // seçili kuru işle seçilmiş ise 1 yap
      if (this.state.selectedSafeCurrencyAmount) {
        values.data.currency_rates[this.state.selectedSafe.currency] = 1
      }

      if (this.props.id) {
        values.data.is_return_invoice = true
        values.data.priority_invoice = Number(this.props.id)
        values.data.customer_id = Number(this.props.customer_id)
      }

      if (this.props.payable) {
        values.data.tahsildar_notification_id = this.props.tahsildar_notification_id
      }

      delete values.currency
      delete values.selected_safe_currency_amount

      mutate({ variables: values })
    })
  }

  safeOnSelect = safe_id => {
    let step2 = async () => {
      this.setState({ addedCurrency: [] })
      try {
        await this.fetchCurrencyRates()
        // eğer fatura üstünden ödeme alınıyorsa
        // seçilen kasanın kuru ile fatura kuru aynı ise seçili kuru işle işaretlenmelidir.
        // seçilen kasanın kuru ile fatura kuru aynı değil ise o kuru otomatik olarak ekle. addCurrency ile
        if (this.props.priority_invoice) {
          if (this.state.selectedSafe.currency === this.state.data.currency)
            this.setState({ selectedSafeCurrencyAmount: true }, this.setMaxAmount)
          else
            this.addCurrency(this.state.data.currency, this.setMaxAmount)
        }
      } catch (err) { /* empty */
      }
    }

    let step1 = () => {
      let selectedSafe = this.state.safeList.find(x => x.safe_id === safe_id)
      // eski seçilen kasa ile yeni seçilen kasa kuru farklı ise kurları yeniden getir
      if (this.state.selectedSafe !== null && selectedSafe.currency !== this.state.selectedSafe.currency) {
        this.setState({ selectedSafe })
        setTimeout(step2, 10)
      } else {
        this.setState({ selectedSafe })
      }
    }

    step1()

  }

  addCurrency = (code, callback) => {
    let currency
    if ( ! code) {
      let currency_code = this.props.form.getFieldValue('currency')
      currency = getCurrencyWithCode(currency_code)
    } else {
      currency = getCurrencyWithCode(code)
    }

    this.setState({ addedCurrency: [...this.state.addedCurrency, currency] }, () => {
      // eski seçim select te kaldığı için. ( bug )
      let selectableCurrency = this.getSelectableCurrency()
      if (selectableCurrency.length)
        this.props.form.setFieldsValue({ currency: selectableCurrency[0].code })
      callback && callback()
    })
  }

  deleteCurrency = code => {
    let addedCurrency = []
    for (let x of this.state.addedCurrency) {
      if (x.code !== code)
        addedCurrency.push(x)
    }
    this.setState({ addedCurrency })
  }

  // seçilebilir kurları getirir
  getSelectableCurrency = () => {
    if ( ! this.state.selectedSafe)
      return []
    return currencyData.filter(x => (x.code !== this.state.selectedSafe.currency) && ! this.state.addedCurrency.find(y => y.code === x.code))

  }

  onChangeDate = value => {
    if (value !== null)
      this.fetchCurrencyRates(this.setMaxAmount)
  }

  // amount değerinin en fazla ne kadar alabileceğini sağlar
  setMaxAmount = () => {
    if ( ! this.props.priority_invoice)
      return

    let maxAmount
    if (this.state.data.currency === this.state.selectedSafe.currency) {
      maxAmount = this.state.remaining
    } else {
      let formCurrencyValue = this.props.form.getFieldValue(`data[currency_rates][${this.state.data.currency}]`)
      let currencyRate
      if (formCurrencyValue)
        currencyRate = formCurrencyValue
      else
        currencyRate = this.state.currency[this.state.data.currency]

      maxAmount = (this.state.remaining / currencyRate).toFixed(4)
    }

    this.setState({ maxAmount: parseFloat(maxAmount) }, () => {
      // yeniden tetikleki max propertisi işleyebilsin
      this.props.form.setFieldsValue({
        'data[amount]': this.props.form.getFieldValue('data[amount]'),
      })
    })
  }

  customerSearchModalCallback = record => {
    this.setState({
      customerList: [{ name: record.name, customer_id: record.customer_id }],
      modalCustomerSearch: null,
    })
    this.props.form.setFieldsValue({
      ['data[customer_id]']: record.customer_id,
    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    const form = (mutate, loadingMutation) => (
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit(mutate)
        }}
        className="form-label-default no-indent"
      >

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Customer']}</p>}
                     {...formItemLayout}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              {getFieldDecorator('data[customer_id]', {
                rules: [{ required: true }],
              })(
                <Select
                  size="large"
                  filterOption={false}
                  showSearch
                  suffixIcon={<Icon type="caret-down"/>}
                  style={{ width: '100%' }}
                  notFoundContent={this.state.fetchingCustomer ? <Spin size="large"/> : null}
                  onSearch={this.searchCustomer}
                >
                  {this.state.customerList.map((x, k) => {
                    return <Select.Option value={x.customer_id} key={k}>{x.name}</Select.Option>
                  })}
                </Select>,
              )}
              <Button size="large" icon="search" onClick={() => this.setState({ modalCustomerSearch: true })}></Button>
            </Input.Group>
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Safe']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('data[safe_id]', {
              rules: [{ required: true }],
              initialValue: this.props.safe ? this.props.safe.safe_id : this.state.safeList ? this.state.safeList.find(x => x.default).safe_id : '',
            })(
              <Select
                size="large"
                disabled={this.props.safe ? true : false}
                onSelect={this.safeOnSelect}
                suffixIcon={<Icon type="caret-down"/>}
              >
                {this.state.safeList && this.state.safeList.map((x, k) => {
                  return <Select.Option value={x.safe_id}
                                        key={k}>{x.name} - {getCurrencyWithCode(x.currency).symbol}</Select.Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Amount']}</p>}
                     {...formItemLayout}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              {getFieldDecorator('data[amount]', {
                initialValue: this.state.data && parseFloat(this.state.data.grand_total - this.state.data.paid).toFixed(5),
                rules: [{ required: true }],
              })(
                <InputNumber
                  size="large"
                  className="w-100"
                  step={this.increment ? this.increment.toFixed(4) : 0}
                  min={0}
                  decimalSeparator=","
                  max={this.state.maxAmount || undefined}
                />,
              )}
              <Button disabled
                      size="large">{this.state.selectedSafe && getCurrencyWithCode(this.state.selectedSafe.currency).symbol}</Button>
            </Input.Group>
          </Form.Item>

        </div>

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Process Selected Currency']}</p>}
                     {...formItemLayout2}
          >
            {getFieldDecorator('selected_safe_currency_amount', {
              valuePropName: 'checked',
              initialValue: this.state.selectedSafeCurrencyAmount,
            })(
              <Checkbox
                onChange={item => this.setState({ selectedSafeCurrencyAmount: item.target.checked })}
                className="fl-r"
                disabled={this.props.priority_invoice && this.state.data && this.state.data.currency === this.state.selectedSafe.currency}
              >
                {this.props.langData[this.state.selectedSafeCurrencyAmount ? 'Yes' : 'No']}
              </Checkbox>,
            )}
          </Form.Item>

          {this.getSelectableCurrency().length > 0 && (
            <Form.Item
              label={<span><p style={{ fontWeight: 'normal' }}>{this.props.langData['Add Currency']}</p></span>}
              {...formItemLayout}
            >
              <Input.Group compact style={{ display: 'flex' }}>
                {getFieldDecorator('currency', {
                  initialValue: this.getSelectableCurrency()[0].code,
                })(
                  <Select
                    size="large"
                    className="w-100"
                    suffixIcon={<Icon type="caret-down"/>}
                  >
                    {this.getSelectableCurrency().map((x, k) => (
                      <Select.Option value={x.code} key={k}>{x.symbol}</Select.Option>
                    ))}
                  </Select>,
                )}
                <Button size="large" style={{ padding: '0px 40px' }} onClick={() => this.addCurrency()}>
                  {this.props.langData['Add']}
                </Button>
              </Input.Group>
            </Form.Item>
          )}

          {this.state.addedCurrency.map((x, k) => {
            return (
              <Form.Item key={k} label={<p>{x.symbol}</p>}
                         {...formItemLayout}
              >
                <Input.Group compact style={{ display: 'flex' }}>
                  {getFieldDecorator('data[currency_rates][' + x.code + ']', {
                    initialValue: this.state.currency[x.code],
                    rules: [{ required: true }],
                  })(
                    <InputNumber
                      size="large"
                      step={this.increment ? this.increment.toFixed(4) : 0.0001}
                      min={0.0001}
                      decimalSeparator=","
                      style={{ width: '100%', marginRight: 0 }}
                      onChange={this.props.priority_invoice && this.state.data.currency === x.code ? this.setMaxAmount : null}
                    />,
                  )}
                  <Input
                    size="large" disabled style={{ width: '50%', textAlign: 'center' }}
                    value={getCurrencyWithCode(this.state.selectedSafe.currency).symbol + ' / ' + x.symbol}
                  />
                  <Button
                    size="large"
                    icon="close"
                    disabled={this.props.priority_invoice && this.state.data.currency === x.code}
                    onClick={() => this.deleteCurrency(x.code)}
                  />
                </Input.Group>

              </Form.Item>
            )
          })}

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Date']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('data[date]', {
              initialValue: moment(),
              rules: [{ required: true }],
            })(
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                suffixIcon={<CalendarFilled/>}
                showTime={{ format: 'HH:mm' }}
                size="large"
                className="w-100"
                onChange={this.onChangeDate}
              />,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item hasFeedback {...formItemLayoutNoLabel} >
            {getFieldDecorator('data[explanation]', { rules: [{ max: 250 }] })(
              <Input.TextArea size="large" maxLength={250} autoSize={{ minRows: 2, maxRows: 5 }}
                              placeholder={this.props.langData['Explanation']}/>,
            )}
          </Form.Item>
        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" loading={loadingMutation} htmlType="submit" className="default-button">
              {this.props.langData['Save']}
            </Button>
            <Link to={this.callbackPage(this.props)}>
              <Button size="large" style={{ marginRight: 8 }} loading={loadingMutation}
                      className="default-button danger2">
                {this.props.langData['Close']}
              </Button>
            </Link>
          </Form.Item>
        </div>

        {this.state.modalCustomerSearch && (
          <CustomerSearchModal callback={this.customerSearchModalCallback}
                               modalClose={() => this.setState({ modalCustomerSearch: false })}/>
        )}

      </Form>
    )

    return (
      <Query
        query={this.props.transaction_id ? READ_QUERY(this.props.type) : this.props.id ? RETURN_INVOICE_DETAIL_QUERY(this.props.type) : this.props.priority_invoice ? READ_FOR_PAY_CREATE_QUERY(this.props.type) : READ_FOR_CREATE_QUERY}
        variables={{ transaction_id: (this.props.transaction_id) || Number(this.props.priority_invoice) }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery) {
            this.increment = data.userInformation.settings.increment
            // işlem düzenleme veya fatura üstünden ödeme alma açılıyorsa, işlem yoksa hata ver ve yönlendir
            if ((this.props.transaction_id || this.props.priority_invoice) && ! data.data) {
              toast(false, this.props.langData['x not found']('Transaction'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data && ! this.state.safeList) {
              if (this.props.transaction_id) {
                let dataCopy = data.data
                this.setState({
                  data: dataCopy,
                  safeList: data.safeList.safes,
                  selectedSafe: dataCopy.safe,
                  customerList: [dataCopy.customer],
                })
                // kur değerlerini ayarla
                let addedCurrency = []
                for (let x in dataCopy.currency_rates) {
                  if (x !== '__typename' && x !== dataCopy.safe.currency && dataCopy.currency_rates[x] !== null)
                    addedCurrency.push(getCurrencyWithCode(x))
                }

                // eğer seçili kur değeri null ise. Seçili kasanın kuru işle işareti kaldırılmıştır
                if ( ! dataCopy.currency_rates[dataCopy.safe.currency])
                  this.setState({ selectedSafeCurrencyAmount: false })

                this.setState({ addedCurrency })
                setTimeout(() => {
                  this.props.form.setFieldsValue(deleteNull({
                    'data[customer_id]': dataCopy.customer_id,
                    'data[safe_id]': dataCopy.safe_id,
                    'data[amount]': dataCopy.amount,
                    'data[explanation]': dataCopy.explanation,
                    'data[date]': moment(dataCopy.date),
                    'data[currency_rates][try]': dataCopy.currency_rates.try,
                    'data[currency_rates][usd]': dataCopy.currency_rates.usd,
                    'data[currency_rates][eur]': dataCopy.currency_rates.eur,
                    'data[currency_rates][gbp]': dataCopy.currency_rates.gbp,
                    'data[currency_rates][rub]': dataCopy.currency_rates.rub,
                  }))
                  // kurları getir
                  this.fetchCurrencyRates()
                }, 10)
              } else if (this.props.priority_invoice) { // fatura üstünden ödeme alma ise
                let dataCopy = data.data
                let selectedSafe = data.safeList.safes.find(x => x.default)
                this.setState({
                  data: dataCopy,
                  safeList: data.safeList.safes,
                  selectedSafe,
                  customerList: [dataCopy.customer],
                  remaining: parseFloat((dataCopy.grand_total - dataCopy.paid).toFixed(4)),
                }, () => {

                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'data[customer_id]': this.props.id ? dataCopy.customer.name : dataCopy.customer_id,
                    })
                  }, 10)

                  if (selectedSafe.currency === dataCopy.currency)
                    this.setState({ selectedSafeCurrencyAmount: true })
                  else
                    this.addCurrency(dataCopy.currency)

                  // kurları getirdikten sonra maxAmunt ayarlaması yap.
                  this.fetchCurrencyRates(() => {
                    let maxAmount
                    if (this.state.data.currency === selectedSafe.currency) {
                      maxAmount = parseFloat((dataCopy.grand_total - dataCopy.paid).toFixed(4))
                    } else {
                      let currencyRate = this.state.currency[this.state.data.currency]
                      maxAmount = (this.state.remaining / currencyRate).toFixed(4)
                    }
                    this.setState({ maxAmount: parseFloat(maxAmount) })
                    {/* this.props.form.setFieldsValue({
											'data[amount]': maxAmount
										}) */
                    }
                    if (this.props.payable) {
                      this.props.form.setFieldsValue({
                        ['data[amount]']: parseFloat(this.props.payable).toFixed(4),
                      })
                    }
                  })

                })

              } else { // yeni işlem ise
                this.setState({
                  safeList: data.safeList.safes,
                  selectedSafe: data.safeList.safes.find(x => x.default),
                }, () => {
                  // kasayı seçtikten sonra kurları getir
                  this.fetchCurrencyRates()
                })
                // Ödeme ve alacak durumuna göre amount set edildi.
                this.props.form.setFieldsValue({
                  'data[amount]': this.props.type === 'credit' ? (this.props.amount < 0 ? (-1 * this.props.amount) : 0) : (this.props.amount > 0 ? this.props.amount : 0),
                })
              }
            }
          }

          return (
            <Mutation
              mutation={
                this.props.transaction_id ?
                  UPDATE_QUERY(this.props.type) :
                  this.props.id ? CREATE_QUERY(this.props.type === 'sales' ? 'credit' : 'debit') :
                    this.props.priority_invoice ?
                      CREATE_QUERY(this.props.type === 'sales' ? 'debit' : 'credit') :
                      CREATE_QUERY(this.props.type)
              }
              onError={err => graphQLErrorsToToast(err)}
              onCompleted={dataMutate => {
                toast(true, dataMutate.data.informative.messages)
                if (this.props.type === 'sales') {
                  pushNotification('You received a payment', 'Your payment has been successful')
                }
                // eğer yeni oluşturuyorsa yönlendir. Yeni oluşturmuyorsa yada fatura üstünden ödeme alınıyorsa callback ile detay sayfasını yenile
                if (typeof this.props.id !== 'undefined') {
                  if (this.props.type === 'purchase') {
                    this.props.history.push(generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
                      id: this.props.id,
                    }))
                  } else {
                    this.props.history.push(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
                      type: `${this.props.type}Detail`,
                      id: this.props.id,
                    }))
                  }
                } else if ( ! this.props.transaction_id && ! this.props.priority_invoice && ! this.props.safe) {
                  this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
                    type: this.props.langData[this.props.type],
                    id: this.props.transaction_id || dataMutate.data.data.transaction_id,
                  }))
                } else if (this.props.safe) {
                  this.props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, { id: this.props.safe.safe_id }))
                } else if (this.props.priority_invoice) {
                  this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
                    type: this.props.langData[this.props.type],
                    id: this.props.priority_invoice,
                  }))
                  this.props.callback()
                } else {
                  this.props.callback()
                }
              }}
            >
              {(mutate, { loading: loadingMutation }) => {
                if (this.props.sizeWidth > 576) {
                  return (
                    <Card
                      loading={! this.props.transaction_id ? false : (data && ! data.data)}
                      bodyStyle={{ padding: '0px' }}
                      style={{ border: this.props.payable ? '3px dashed darkblue' : 'none' }}
                      title={
                        <span><PayCircleOutlined/> &nbsp;{
                          this.props.priority_invoice && this.props.id ? (this.props.langData[this.props.type === 'sales' ? 'Pay to Return Invoice' : 'Get Payment to Return Invoice'])
                            : this.props.priority_invoice ?
                              (this.props.langData[this.props.type === 'sales' ? 'Get Payment' : 'Pay']) :
                              this.props.transaction_id ? this.props.langData['Update'] : this.props.langData[capitalize(this.props.type)]
                        }</span>
                      }
                      bordered={false}
                      extra={
                        <Link to={this.callbackPage(this.props)}>
                          <Icon className="cursor" type="close"/>
                        </Link>
                      }
                    >
                      {form(mutate, loadingMutation)}
                    </Card>
                  )
                }
                return form(mutate, loadingMutation)
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

CreditDebitForm.propTypes = {
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,
  form: PropTypes.object,
  customer: PropTypes.object,
  safe: PropTypes.object,
  transaction_id: PropTypes.number,
  priority_invoice: PropTypes.string,
  type: PropTypes.string, // credit|debit yada sales|purchase gelebilir.
  history: PropTypes.object,
  callback: PropTypes.func,
  currency: PropTypes.number,
  id: PropTypes.number,
  customer_id: PropTypes.number,
  payable: PropTypes.number,
  tahsildar_notification_id: PropTypes.number,
  amount: PropTypes.number,
}

export default connect(mapStateToProps)(CreditDebitForm)
