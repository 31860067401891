import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'

import ManagerInfoForm from './ManagerInfoForm'

function ManagerInfo(props) {

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedManagerInfoForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(ManagerInfoForm)

  return <WrappedManagerInfoForm {...props} />

}

ManagerInfo.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Manager' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(ManagerInfo)
