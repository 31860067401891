import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { compile } from 'path-to-regexp'
import { capitalize, cloneDeep } from 'lodash'
import { graphQLErrorsToToast, toast, confirm, toLocaleString } from '../../../helpers/helper'
import { Row, Col, Card, Icon, Divider, Table, Dropdown, Button, Menu } from 'antd'
import moment from 'moment'
import apolloClient from './../../../helpers/apolloClient'
import { deleteData } from '../../../helpers/trait'
import InvoicePrintModal from './../InvoicePrintModal/InvoicePrintModal'
import InvoiceSearchModal from './../InvoiceSearchModal/InvoiceSearchModal'
import { generatePath, Link } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Waybill' } })
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const READ_QUERY = type => (
  gql`
    query($waybill_id:Int!){
    waybill: ${type}Waybill(waybill_id:$waybill_id) {
    customer {
    name
    id_or_tax_identification_number
    }
    waybill_id
    invoice_transaction_id
    customer_id
    no
    explanation
    date
    sending_date
    waybill_country
    waybill_city
    waybill_town
    waybill_address
    waybill_postal_code
    products {
    waybill_product_id
    product_id
    unit_id
    quantity
    product {
    name
    unit {
    name
    }
    selling_price
    vat
    }
    }
    additional_charges {
    waybill_additional_charge_id
    additional_charge_id
    unit_id
    quantity
    additional_charge {
    name
    unit {
    name
    }
    selling_price
    vat
    }
    }
    invoice{
    transaction_id
    }
    e_despatch_type
    e_despatch_uuid
    e_despatch_id
    }
    }
  `
)

const E_DESPATCH_CREATE = (
  gql`
    mutation($transaction_id: Int!, $type: EDespatchType!){
      sendingWaybillCreateEDespatch(transaction_id: $transaction_id, type: $type){
        sending_waybill{
          e_despatch_id
          e_despatch_type
          e_despatch_uuid
        }
        informative {
          messages
        }
      }
    }
  `
)

const WAYBILL_UPDATE_MUTATION = type => (
  gql`
    mutation ($waybill_id: Int!, $${type}_waybill: WaybillUpdateInput!){
    waybillUpdate:${type}WaybillUpdate(waybill_id: $waybill_id, ${type}_waybill: $${type}_waybill){
    informative{
    messages
    }
    ${type}_waybill {
    waybill_id
    invoice_transaction_id
    customer_id
    no
    explanation
    date
    waybill_country
    waybill_city
    waybill_town
    waybill_address
    waybill_postal_code
    e_despatch_type
    e_despatch_uuid
    e_despatch_id
    }
    }
    }
  `
)

const typeEnglishName = {
  'giden': 'sending',
  'gelen': 'incoming',
}

class WaybillDetail extends Component {
  waybill_id

  state = {
    type: null,
    data: null,
    waybill_id: null,
    printModal: false,
    id_or_tax_identification_number: null,
    modalProduct: false,
    invoiceModal: false,
  }

  constructor(props) {
    super(props)
    this.waybill_id = parseInt(props.match.params.id)
  }

  columns = [
    {
      title: this.props.langData['Product'],
      dataIndex: 'product.name|additional_charge.name',
      render: (value, record) => record.product ? record.product.name : record.additional_charge.name,
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      render: (data) => toLocaleString(data),
    },
    {
      title: this.props.langData['Unit'],
      dataIndex: 'product.unit.name|additional_charge.unit.name',
      render: (value, record) => record.product ? record.product.unit.name : record.additional_charge.unit.name,
    },
  ]

  componentDidMount() {
    let type = this.props.match.params.type
    if (typeEnglishName[type])
      type = typeEnglishName[type]
    this.setState({ type, waybill_id: this.waybill_id })

    this.props.breadcrumb_add(this.props.langData[capitalize(type) + ' Waybill'], compile('/' + this.props.langData.route['waybill/:type/detail/:id'])({
      type,
      id: this.waybill_id,
    }))
    this.deleteData = deleteData(type + 'Waybill', 'waybill_id').bind(this)

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  menuOnClick = item => {
    this[item.key]()
  }

  onPrint = value => {
    let printPage = value
    const pageFields = {
      type: 'v',
      copy: 1,
    }
    switch (printPage) {
      case 'A4-h4':
        pageFields.type = 'h'
        pageFields.copy = 4
        break
      case 'A5-h':
        pageFields.type = 'h'
        break
      case 'A4-h3':
        pageFields.type = 'h'
        pageFields.copy = 3
        break
      default:

        break
    }

    window.open(
      `${process.env.REACT_APP_NEW_API_URL}/waybill/printMultiple/${localStorage.getItem('token')}/${this.state.waybill_id}/${pageFields.copy}x/${pageFields.type}/${this.props.lang}`,
      '_blank',
    )

  }

  edit() {
    this.props.history.push('/' + compile(this.props.langData.route['waybill/:type/update/:id'])({
      type: this.props.langData[capitalize(this.state.type)],
      id: this.state.data.waybill_id,
    }))
  }

  delete = () => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteData(this.state.data.waybill_id)
    })
  }

  createInvoice() {
    this.props.history.push(generatePath(routes.WAYBILL_NEW_INVOICE, {
      type: this.props.langData[capitalize(this.state.type)],
      id: this.state.data.waybill_id,
    }))
  }

  createEDespatchQuery = async refetch => {
    try {
      const result = await apolloClient.mutate({
        mutation: E_DESPATCH_CREATE,
        variables: { transaction_id: this.state.waybill_id, type: 'Sevk' },
      })
      toast(true, result.data.sendingWaybillCreateEDespatch.informative.messages)
      refetch()
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }

  createEDespatch = async refetch => {
    if ( ! this.state.id_or_tax_identification_number) {
      confirm('Tc Undefined', async result => {
        if (result)
          return this.createEDespatchQuery(refetch)
      })
      return ''
    }

    return this.createEDespatchQuery(refetch)

  }

  print() {
    this.setState({ printModal: true })
  }

  eDespatchPrint = () => {
    window.open(
      `${process.env.REACT_APP_NEW_API_URL}/waybill/edespatch/print/${localStorage.getItem('token')}/${this.state.waybill_id}/${this.props.lang}`,
      '_blank',
    )
  }

  viewInvoice() {
    this.props.history.push(
      generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
        type: this.props.langData[this.state.type === 'sending' ? 'sales' : 'purchase'],
        id: this.state.data.invoice_transaction_id,
      }),
    )
  }

  invoiceSearchModalCallback = async (refetch, record) => {
    confirm('Match Invoice', async result => {
      if ( ! result)
        return
      try {
        let result = await apolloClient.mutate({
          mutation: WAYBILL_UPDATE_MUTATION(this.state.type),
          variables: {
            waybill_id: this.state.data.waybill_id,
            [this.state.type + '_waybill']: { invoice_transaction_id: record.transaction_id },
          },
        })
        toast(true, result.data.waybillUpdate.informative.messages)
        refetch()
        this.setState({ invoiceModal: false })
      } catch (error) {
        graphQLErrorsToToast(error)
      }
    })
  }

  render() {
    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        {this.state.type !== 'incoming' && <Menu.Item key="print">
          <span><Icon type="printer"/>{this.props.langData['Print']}</span>
        </Menu.Item>}
        <Menu.Item key="edit">
          <span><Icon type="edit"/> {this.props.langData['Edit']}</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']} </span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={READ_QUERY(this.state.type)}
        // skip={!this.state.waybill_id}
        variables={{ waybill_id: this.state.waybill_id }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery) {
            if ( ! data.waybill) {
              toast(false, this.props.langData['x not found']('Waybill'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data || JSON.stringify(this.state.data) !== JSON.stringify(data.waybill)) {
              let copyData = cloneDeep(data.waybill)

              this.setState({
                data: copyData,
                products: copyData.products,
                additional_charges: copyData.additional_charges,
                id_or_tax_identification_number: copyData.customer.id_or_tax_identification_number,
              })
            }
          }
          return (
            <Row gutter={16}>
              {this.state.printModal &&
                <InvoicePrintModal onPrint={this.onPrint} onCancel={() => this.setState({ printModal: false })}/>}
              <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  title={
                    <span><Icon type="car"/> &nbsp;{this.props.langData['Waybill Detail']}</span>
                  }
                  loading={ ! this.state.data}
                  extra={[
                    this.state.type !== 'incoming' &&
                    <Button key="0" loading={ ! this.state.data} style={{ marginRight: 8 }}
                            onClick={() => this.createEDespatch(refetch)}>
                      <span><Icon type="safety-certificate"/> &nbsp;{this.props.langData['E-Despatch']}</span>
                    </Button>,
                    <Dropdown key="1" overlay={menu} trigger={['click']}>
                      <Button loading={ ! this.state.data}>
                        {this.props.langData['Actions']} {this.state.data && <Icon type="down"/>}
                      </Button>
                    </Dropdown>,
                  ]}
                >
                  { ! this.state.data && <div style={{ width: '100%', height: 150 }}></div>}

                  {this.state.data && <React.Fragment>
                    <div className="form-block label-default">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="tag"/><p>{this.props.langData['Type']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.props.langData[this.state.type === 'sending' ? 'Sending Waybill' : 'Incoming Waybill']}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          <Link to={generatePath(routes.CUSTOMER_DETAIL, { id: this.state.data.customer_id })}>
                            <span style={{ color: '#1890ff' }}>{this.state.data.customer.name}</span>
                          </Link>
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="paper-clip"/><p>{this.props.langData['Waybill No']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.no ? this.state.data.no : '-'}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {moment(this.state.data.date).locale(this.props.lang).format('DD MMMM YYYY HH:mm')}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="calendar"/><p>{this.props.langData['Date of Shipment']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {moment(this.state.data.sending_date).locale(this.props.lang).format('DD MMMM YYYY HH:mm')}
                        </Col>
                      </Row>

                      <Divider/>

                      {this.state.data.waybill_country && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Waybill Country']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.waybill_country}
                        </Col>
                      </Row>}

                      {(this.state.data.waybill_city || this.state.data.waybill_town) &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="environment-o"/><p>{this.props.langData['Waybill City/Town']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {
                              (this.state.data.waybill_city ? this.state.data.waybill_city : '-') + '/' +
                              (this.state.data.waybill_town ? this.state.data.waybill_town : '-')
                            }
                          </Col>
                        </Row>}

                      {this.state.data.waybill_postal_code && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Waybill Postal Code']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.waybill_postal_code}
                        </Col>
                      </Row>}

                      {this.state.data.waybill_address && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <span><Icon type="environment-o"/><p>{this.props.langData['Waybill Address']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                          {this.state.data.waybill_address}
                        </Col>
                      </Row>}
                    </div>

                    {this.state.data.explanation &&
                      <div>
                        <Divider/>
                        <div style={{ padding: '0px 10px 20px 10px', textAlign: 'center' }}>
                          {this.state.data.explanation}
                        </div>
                      </div>
                    }
                  </React.Fragment>}
                </Card>

                <Card
                  bodyStyle={{ padding: 0, marginTop: 20, marginBottom: 20 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon
                      type="star-o"/> {this.props.langData['Products'] + '/' + this.props.langData['Additional Charges']}
                  </Divider>

                  <Table
                    className="table-no-border"
                    columns={this.columns}
                    rowKey={record => record.waybill_product_id ? record.waybill_product_id + 'p' : record.waybill_additional_charge_id + 'a'}
                    dataSource={this.state.data ? this.state.data.products.concat(this.state.data.additional_charges) : null}
                    loading={ ! this.state.data}
                    pagination={false}
                    style={{ borderRadius: 0 }}
                    rowClassName="white"
                    size="small"
                    scroll={{ x: 'max-content' }}
                    bordered
                  >
                  </Table>
                </Card>
              </Col>

              {(this.state.data && this.state.data.e_despatch_type) && <Col xs={24} sm={24} md={10} lg={8} xl={7}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  style={{ marginTop: 10 }}
                  title={
                    <Button className="w-100" type={this.state.type === 'sales' ? 'primary' : 'danger'}
                            onClick={this.eDespatchPrint}>
                      <Icon type="printer"/>&nbsp;
                      {this.props.langData['E-Despatch'] + ' ' + this.props.langData['Print']}
                    </Button>
                  }
                  bordered={false}
                >
                  <div className="form-block">
                    <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <span style={{ fontSize: 15 }}>ID</span>
                      </Col>
                      <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.data.e_despatch_id}
												</span>
                      </Col>
                    </Row>
                    <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <span style={{ fontSize: 15 }}>UUID</span>
                      </Col>
                      <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
												<span style={{ fontSize: 14, fontWeight: 'bold' }}>
													{this.state.data.e_despatch_uuid}
												</span>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>}

              {this.state.data && <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  bordered={false}
                  title={
                    <React.Fragment>
                      <Button type={this.state.type === 'sending' ? 'primary' : 'danger'}
                              style={{ width: '100%', display: 'block' }}
                              onClick={() => this.state.data.invoice ? this.viewInvoice() : this.createInvoice()}>
                        <span>{this.props.langData[this.state.data.invoice ? 'View Invoice' : 'Create Invoice']}</span>
                      </Button>
                      { ! this.state.data.invoice &&
                        <Button type={this.state.type === 'sending' ? 'primary' : 'danger'}
                                style={{ width: '100%', marginTop: 16 }}
                                onClick={() => this.setState({ invoiceModal: true })}>
                          <span>{this.props.langData['Invoice Match']}</span>
                        </Button>}
                    </React.Fragment>
                  }
                />
              </Col>}

              {this.state.invoiceModal && <InvoiceSearchModal
                type={this.state.type}
                callback={record => this.invoiceSearchModalCallback(refetch, record)}
                modalClose={() => this.setState({ invoiceModal: false })}
              />}

            </Row>
          )
        }}
      </Query>
    )
  }
}

WaybillDetail.propTypes = {
  langData: PropTypes.object,
  type: PropTypes.string,
  waybill_id: PropTypes.number,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillDetail)
