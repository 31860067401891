import { Button, Form, Icon, Input, Modal, Select, List, Typography } from 'antd'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from '../../../helpers/helper'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 },
    lg: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 13 },
    lg: { span: 13 },
  },
}

class ShareEInvoiceMailModal extends Component {
  state = {
    to: '',
    subject: '',
    content: '',
    loading: false,
  }

  onSubmit = () => {
    const { to, subject, content } = this.state
    if ( ! to || ! subject || ! content) {
      toast(false, this.props.langData['Please Fill All Inputs'])
      return
    }
    this.setState({ loading: true })
    this.props.onOk({ ...this.state })
  }

  handleInputChange = (type, value) => {
    this.setState({ [type]: value })
  }

  render() {
    const { loading } = this.state
    return (
      <Modal
        title={<span><Icon type="mail"/> {this.props.langData['Share E-Invoice Mail']}</span>}
        visible={true}
        footer={null}
        onCancel={this.props.onCancel}
        width="35%"
      >
        {
          this.props.incorrectInvoices.length > 0 ? (
            <List
              header={<div style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}>{this.props.langData['Emails That Cannot Be Sent']}</div>}
              footer={<div style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}>{this.props.langData['Your e-invoices with these codes/codes could not be sent as e-mail.']}</div>}
              bordered
              dataSource={this.props.incorrectInvoices}
              renderItem={item => (
                <List.Item>
                  <Typography.Text delete>{item}</Typography.Text>
                </List.Item>
              )}
            />
          ) : (
            <Form
              className="form-label-default"
              onSubmit={e => {
                e.preventDefault()
                this.onSubmit()
              }}
            >
              <Form.Item
                hasFeedback
                label={<span><Icon type="user-add"/><p>{this.props.langData['To']}</p></span>}
                {...formItemLayout}
              >
                <Select className="remove-icon-black" mode="tags" style={{ width: '100%', color: 'black' }}
                        placeholder={this.props.langData['Enter Mail / Mails']}
                        onChange={data => this.setState({ to: data })}/>
              </Form.Item>

              <Form.Item
                hasFeedback
                label={<span><Icon type="flag"/><p>{this.props.langData['Subject']}</p></span>}
                {...formItemLayout}
              >
                <Input placeholder={this.props.langData['Enter Mail Subject']} onChange={(e) => {
                  this.handleInputChange('subject', e.target.value)
                }}/>
              </Form.Item>

              <Form.Item
                hasFeedback
                label={<span><Icon type="menu"/><p>{this.props.langData['Content']}</p></span>}
                {...formItemLayout}
              >
                <Input placeholder={this.props.langData['Enter Mail Content']} onChange={(e) => {
                  this.handleInputChange('content', e.target.value)
                }}/>
              </Form.Item>

              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" className="default-button" loading={loading}>
                  {this.props.langData['Send']}
                </Button>
              </Form.Item>
            </Form>
          )
        }
      </Modal>
    )
  }
}

ShareEInvoiceMailModal.propTypes = {
  langData: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  incorrectInvoices: PropTypes.array,
}
export default ShareEInvoiceMailModal
