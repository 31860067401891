import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Divider, Row, Table, Tag } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode, getCurrencyWithId } from '../../../data/Currency'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import moment from 'moment'
import { cloneDeep } from 'lodash'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Recoveries Report', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($date:DateTime!,$oneMonthDate:DateTime!,$twoMonthDate:DateTime!,$threeMonthDate:DateTime!,$fourMonthDate:DateTime!,$filter: InvoiceFilterBase, $sorting: InvoiceSorting, $paging: Paging){
      invoiceList(filter: $filter, sorting: $sorting, paging: $paging){
        transactions{
          __typename
          ... on SalesInvoice{
            transaction_id
            grand_total
            discount_type
            currency
            invoice_country
            date
            expiry_date
            category{
              name
            }
            customer{
              name
              e_mail
            }
            return_invoices {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
              e_invoice_type
              e_invoice_id
              e_invoice_uuid
            }
            currency
            series
            total_vat
            remaining
            additional_charges{
              additional_charge{
                name
              }
              total
            }
          }
          ... on PurchaseInvoice{
            transaction_id
            grand_total
            discount_type
            currency
            invoice_country
            date
            expiry_date
            category{
              name
            }
            customer{
              name
              e_mail
            }
            return_invoices {
              transaction_id
              invoice_id
              customer_id
              currency
              code
              no
              series
              category_id
              total
              discount
              discount_type
              total_vat
              grand_total
              paid
              remaining
              explanation
              date
              expiry_date
              invoice_country
              invoice_city
              invoice_town
              invoice_address
              delivery_country
              delivery_city
              delivery_town
              delivery_address
            }
            currency
            series
            total_vat
            remaining
            additional_charges{
              additional_charge{
                name
              }
              total
            }
          }
        }

        page_info{
          total_count
        }
      }

      reports {
        invoice {
          oneMonthSum:sum(field: remaining, group: type, filter: {AND: [{expiry_date: {gte: $oneMonthDate, lt: $date}, type: {eq: SalesInvoice}}]}) {
            results {
              sum
            }
          }
          twoMonthSum:sum(field: remaining, group: type, filter: {AND: [{expiry_date: {gte: $twoMonthDate, lt: $oneMonthDate}, type: {eq: SalesInvoice}}]}) {
            results {
              sum
            }
          }
          threeMonthSum:sum(field: remaining, group: type, filter: {AND: [{expiry_date: {gte:$threeMonthDate, lt: $twoMonthDate }, type: {eq: SalesInvoice}}]}) {
            results {
              sum
            }
          }
          fourMonthSum:sum(field: remaining, group: type, filter: {AND: [{expiry_date: {gte:$fourMonthDate, lt: $threeMonthDate }, type: {eq: SalesInvoice}}]}) {
            results {
              sum
            }
          }
          fourplusMonthSum:sum(field: remaining, group: type, filter: {AND: [{expiry_date: {lt:$fourMonthDate}, type: {eq: SalesInvoice}}]}) {
            results {
              sum
            }
          }
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

class TransactionRecoveries extends Component {

  currencyId = 1

  state = {
    data: null,
    pagination: {
      pageSize: 5,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 5,
    },
    columns: null,
    sorting: {
      field: 'expiry_date',
      type: 'DESC',
    },
    filter: {
      AND: [
        {
          type: { eq: 'SalesInvoice' },
          remaining: { gt: 0 },
        },
      ],
    },
    oneMonthSum: null,
    twoMonthSum: null,
    threeMonthSum: null,
    fourMonthSum: null,
    fourplusMonthSum: null,
  }


  componentDidMount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_add(this.props.langData.route['transaction/recoveries'])
    this.setState({
      columns: [
        {
          title: this.props.langData['Invoice Type'],
          fieldName: '__typename',
          dataIndex: '__typename',
          render: (value, record) => {
            if (value === 'SalesInvoice') {
              return (
                <div>
                  <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Sales Invoice']}</Tag><br/>
                  {record.e_invoice_type === 'EInvoice' ? <Tag className="success" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['E-Invoice']}</Tag> : record.e_invoice_type ? <Tag className="success"
                                                                                             style={{
                                                                                               marginRight: 0,
                                                                                               marginTop: 5,
                                                                                             }}>{this.props.langData['E-Archive']}</Tag> : ''}
                  {record.return_invoices && record.return_invoices.length ? <Tag color="#ff7875" style={{
                    marginRight: 0,
                    marginTop: 5,
                  }}>{this.props.langData['Return']}</Tag> : ''}
                </div>
              )
            }
            if (value === 'PurchaseInvoice') {
              return (
                <div>
                  <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Purchase Invoice']}</Tag>
                  {
                    record.return_invoices && record.return_invoices.length ? <Tag color="#ff7875" style={{
                      marginRight: 0,
                      marginTop: 5,
                    }}>{this.props.langData['Return']}</Tag> : ''
                  }
                </div>
              )
            }
          },
        },
        {
          title: this.props.langData['Customer'],
          fieldName: 'customer',
          fieldLabel: 'Customer',
          subFields: ['name'],
          dataIndex: 'customer',
          width: '20%',
          key: 'customer',
          align: 'center',
          render: record => record ? record.name : 'İsimsiz',
        },
        {
          title: this.props.langData['Category'],
          fieldName: 'category',
          fieldLabel: 'Category',
          subFields: ['name'],
          dataIndex: 'category',
          width: '20%',
          key: 'category',
          align: 'center',
          render: record => {
            return (record ? <Tag color="#1890ff" style={{ marginRight: 0 }}>{record.name}</Tag> :
                <Tag style={{ marginRight: 0 }}>{this.props.langData['Uncategorized']}</Tag>
            )
          },
        },
        {
          title: this.props.langData['Expiry Date'],
          dataIndex: 'expiry_date',
          width: '20%',
          sorter: true,
          render: value => moment(value).format('DD MMMM YYYY'),
        },
        {
          title: this.props.langData['Total'],
          dataIndex: 'remaining',
          fieldName: 'remaining',
          fieldLabel: 'Total',
          align: 'right',
          width: '30%',
          render: (value, record) => {
            return (
              <div>
                <span>{`${this.props.langData['Total']} : ${toLocaleString(record.grand_total)} ${getCurrencyWithCode(record.currency).symbol}`}</span>
                <br/>
                <span>{`${this.props.langData['Remaining']} : ${toLocaleString(value)} ${getCurrencyWithCode(record.currency).symbol}`}</span>
              </div>
            )
          },
        },
      ],
    })
  }

  componentWillUnmount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  handleTableChange = (paging, sorting) => {
    if (paging.pageSize)
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
      })

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field ? sorting.field : 'expiry_date',
          type: (sorting.order === 'ascend' ? 'ASC' : 'DESC'),
        },
      })
  }

  callExternalFunction(params) {
    let filterData = []
    let endDate = moment().subtract(parseInt(params.point.index), 'months').format('Y-MM-DD')
    let startDate = moment(startDate).subtract(parseInt((params.point.index) + 1), 'months').format('Y-MM-DD')

    if (params.point.index == 4)
      filterData.push({ 'expiry_date': { 'lt': endDate } })
    else
      filterData.push({ 'expiry_date': { 'lt': endDate, 'gt': startDate } })
    this.filterCallback(filterData.length ? filterData : null)
  }

  filterCallback = filter => {
    if ( ! filter) {
      this.setState({ filter: { AND: filter } })
    } else {
      filter.push({ type: { eq: 'SalesInvoice' }, remaining: { gt: 0 } })
      this.setState({
        filter: {
          AND: filter,
        },
      })
    }
  }
  rowItemClick = record => {
    this.props.history.push(generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
      type: this.props.langData[record.__typename === 'SalesInvoice' ? 'sales' : 'purchase'],
      id: record.transaction_id,
    }))
  }


  render() {
    const options = {
      chart: {
        height: '350px',
        type: 'column',
        renderTo: 'atmospheric-composition',
        backgroundColor: 'white',
      },
      tooltip: {
        shape: 'callout',
        headerFormat: '<b>{point.x}</b><br/>',
        formatter: ((e) => {
          let langData = this.props.langData
          const symbol = getCurrencyWithId(this.currencyId).symbol
          return function () {
            var __value = ''
            switch (this.x) {
              case langData['Toplam Geçikmiş Tahsilat']:
                __value = this.y
                break
              case langData['1-30 Day Delayed']:
                __value = this.y
                break
              case langData['31-60 Day Delayed']:
                __value = this.y
                break
              case langData['61-90 Day Delayed']:
                __value = this.y
                break
              case langData['91-120 Day Delayed']:
                __value = this.y
                break
              case langData['120+ Day Delayed']:
                __value = this.y
                break
            }

            return `<b>${this.x}<br/>${langData['Total']} : ${toLocaleString(__value)} ${symbol}</b>`
          }
        })(),
      },
      legend: {
        enabled: false,
      },
      title: false,

      xAxis: {
        opposite: true,
        lineWidth: 0,
        // id: [1, 2, 3, 4],
        categories: [this.props.langData['1-30 Day Delayed'], this.props.langData['31-60 Day Delayed'], this.props.langData['61-90 Day Delayed'], this.props.langData['91-120 Day Delayed'], this.props.langData['120+ Day Delayed'], this.props.langData['Total']],
        gridLineWidth: 1,
        width: this.props.sizeWidth - 300 + 'px',
      },

      yAxis: {
        gridLineWidth: 0,
        allowDecimals: true,
        min: 0,
        title: false,
        visible: false,
      },

      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },

      series: [{
        events: {
          click: (e) => {
            return this.callExternalFunction(e)
          },
        },
        states: {
          hover: {
            enabled: true,
          },
        },
        data: [this.state.oneMonthSum, 0, 0, 0, 0],
      },
        {
          events: {
            click: (e) => {
              return this.callExternalFunction(e)
            },
          },
          data: [0, this.state.twoMonthSum, 0, 0, 0],
        },
        {
          events: {
            click: (e) => {
              return this.callExternalFunction(e)
            },
          },
          data: [0, 0, this.state.threeMonthSum, 0, 0],
        }, {
          events: {
            click: (e) => {
              return this.callExternalFunction(e)
            },
          },
          data: [0, 0, 0, this.state.fourMonthSum, 0],
        }, {
          events: {
            click: (e) => {
              return this.callExternalFunction(e)
            },
          },
          data: [0, 0, 0, 0, this.state.fourplusMonthSum],
        }],
      credits: { enabled: false },
    }
    return (
      <Query
        query={LIST_QUERY}
        // skip={!this.state.columns}
        fetchPolicy="network-only"
        variables={{
          date: moment().format('Y-MM-DD'),
          oneMonthDate: moment().subtract(1, 'months').format('Y-MM-DD'),
          twoMonthDate: moment().subtract(2, 'months').format('Y-MM-DD'),
          threeMonthDate: moment().subtract(3, 'months').format('Y-MM-DD'),
          fourMonthDate: moment().subtract(4, 'months').format('Y-MM-DD'),
          filter: this.state.filter,
          sorting: this.state.sorting,
          paging: this.state.paging,
        }}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data) {

              this.currencyId = data.legacyData.currency_id

              this.setState({
                oneMonthSum: cloneDeep(data.reports.invoice.oneMonthSum.results[0] ? data.reports.invoice.oneMonthSum.results[0].sum : 0),
                twoMonthSum: cloneDeep(data.reports.invoice.twoMonthSum.results[0] ? data.reports.invoice.twoMonthSum.results[0].sum : 0),
                threeMonthSum: cloneDeep(data.reports.invoice.threeMonthSum.results[0] ? data.reports.invoice.threeMonthSum.results[0].sum : 0),
                fourMonthSum: cloneDeep(data.reports.invoice.fourMonthSum.results[0] ? data.reports.invoice.fourMonthSum.results[0].sum : 0),
                fourplusMonthSum: cloneDeep(data.reports.invoice.fourplusMonthSum.results[0] ? data.reports.invoice.fourplusMonthSum.results[0].sum : 0),

                data: cloneDeep(data.invoiceList.transactions),
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
              })
            } else if (this.state.data && JSON.stringify(data.invoiceList.transactions) !== JSON.stringify(this.state.data)) {
              this.setState({
                data: cloneDeep(data.invoiceList.transactions),
                pagination: {
                  ...this.state.pagination,
                  total: data.invoiceList.page_info.total_count,
                },
              })
            }
          }
          this.props.refetch && refetch()
          return (
            <div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 10 }}>
                  <Card bordered={false} bodyStyle={{ padding: '10px 20px' }}>
                    <Row gutter={0}>
                      <Col xs={24} sm={24} md={24} xl={24}>
                        <div>
                          <h2>{this.props.langData['Credits Report']}</h2>
                          <Divider style={{ margin: 0 }}/>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 16 }}>
                  <Card bodyStyle={{ padding: 10 }}>
                    <Row>
                      <Col span={24}>
                        <Table
                          columns={this.state.columns}
                          rowKey={record => record.transaction_id}
                          dataSource={this.state.data}
                          pagination={this.state.pagination}
                          loading={loadingQuery}
                          onChange={this.handleTableChange}
                          onRow={record => ({
                            onClick: () => {
                              return this.rowItemClick(record)
                            },
                          })}
                          rowClassName="white"
                          scroll={{ x: 500, y: this.props.sizeWidth > 576 ? 500 : null }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          )
        }}
      </Query>
    )
  }

}

TransactionRecoveries.propTypes = {
  langData: PropTypes.array,
  lang: PropTypes.string,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  noBreadCrumb: PropTypes.bool,
  rowItemClick: PropTypes.func,
  modalClose: PropTypes.func,
  scrollHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  refetch: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRecoveries)
