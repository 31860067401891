const messages = {
  'E-Invoice Print': 'E-Invoice Print',
  'E-Archive Print': 'E-Archive Print',

  'E-Invoice Cancel': 'E-Invoice Cancel',
  'E-Archive Cancel': 'E-Archive Cancel',

  'Send E-Mail': 'Send E-Mail',

  'Login Successful': 'Login Successful',
  'Login': 'Login',
  'Logout': 'Logout',
  'Loading': 'Loading',
  'or': 'or',
  'Username': 'Username',
  'Password': 'Password',
  'Register': 'Register',
  'Register Steps': 'Register Steps',
  'register now': 'Register Now!',
  'Registration completed': 'Registration Completed',
  'Remember me': 'Remember me',
  'Forgot password': 'Forgot password',
  'New Password': 'New Password',
  'Redirecting': 'Redirecting',
  'Logged in': 'Logged In',
  'Type': 'Type',
  'Transaction Type': 'Transaction Type',
  'Invoice Type': 'Invoice Type',
  'Save': 'Save',
  'Save & New': 'Save & New',
  'Update': 'Update',
  'E-Mail': 'E-Mail',
  'Website': 'Website',
  'Web Service': 'Web Service',
  'New': 'New',
  'Add': 'Add',
  'Edit': 'Edit',
  'Delete': 'Delete',
  'Customize': 'Customize',
  'Delete Opening Stock': 'Delete Opening Stock',
  'List': 'List',
  'Open': 'Open',
  'Close': 'Close',
  'Closed': 'Closed',
  'Yes': 'Yes',
  'No': 'No',
  'Active': 'Active',
  'Passive': 'Passive',
  'Status': 'Status',
  'E-Invoice or E-Archive Created': 'E-Invoice or E-Archive Created',
  'Settings': 'Settings',
  'Preferences': 'Preferences',
  'Actions': 'Actions',
  'Action': 'Action',
  'More': 'More',
  'Step': 'Step',
  'Next': 'Next',
  'Steps already completed': 'Steps are already completed',
  'This step is already complete': 'This step is already completed',
  'This step has not yet been passed': 'This step has not been passed yet.',
  'Select safe': 'Select safe',
  'Add Currency': 'Add Currency',
  'items': 'items',
  'Products': 'Products',
  'required': 'required',
  'Optional': 'Optional',
  'Server error': 'Server error',
  'Network error': 'Network error',
  'Incorrect products entered': 'Incorrect products entered',
  'Incorrect additional charges entered': 'Incorrect additional charges entered',
  'Invoices Processed': 'Invoices Processed',
  'Amount processed': 'Amount processed',
  'Add Product': 'Add Product',
  'Edit Product': 'Edit Product',
  'Add Additional Charge': 'Add Additional Charge',
  'Edit Additional Charge': 'Edit Additional Charge',
  'Filter': 'Filter',
  'Choose': 'Choose',
  'TODAY': 'TODAY',
  'Today': 'Today',
  'Late': 'Late',
  'Lated': 'Lated',
  'Export Sales Invoices': 'Export Sales Invoices',
  'Export Purchase Invoices': 'Export Purchase Invoices',
  'Create Excel': 'Create Excel',
  'Add New One': 'Add New One',
  'Import from Excel': 'Import from Excel',
  'Click or drag .xls file to this area to upload': 'Click or drag excel file to this area to upload',
  'Your products recorded in our database will be updated. Other products will be recorded in the database.': 'Your products recorded in our database will be updated. Other products will be recorded in the database.',
  'The products have been imported successfully.': 'The products have been imported successfully.',
  'Wait for finished uploading.': 'Wait for finished uploading.',
  'Your products are imported': 'Your products are imported',
  'Please select a valid excel file.': 'Please select a valid excel file.',
  'An unexpected error was encountered while importing.': 'An unexpected error was encountered while importing.',
  'To make a different file selection, remove the file you previously selected.': 'To make a different file selection, remove the file you previously selected.',
  'Download template': 'Download template',
  'Import': 'Import',
  'Has Debit': 'Has Debit',
  'No Debit': 'No Debit',
  'Has Credit': 'Has Credit',
  'No Credit': 'No Credit',
  'He has debit': 'He has debit',
  'He has credit': 'He has credit',
  'Total Customers': 'Total Customers',
  'Total Products': 'Total Products',
  'Total Sales Invoices': 'Total Sales Invoices',
  'Total Purchase Invoices': 'Total Purchase Invoices',
  'Invoice Transactions': 'Invoice Transactions',
  'Save Customer': 'Save Customer',
  'Save Product': 'Save Product',
  'Save Safe': 'Save Safe',
  'Pay': 'Pay',
  'Get Payment': 'Get Payment',
  'pay': 'pay',
  'getpayment': 'getpayment',
  'Paid': 'Paid',
  'Unpaid': 'Unpaid',
  'Next Value': 'Next Value',
  'Current Value': 'Current Value',
  'Pattern': 'Pattern',
  'Start': 'Start',
  'End': 'End',
  'Show All Columns': 'Show All Columns',
  'Number': 'Number',
  'Defaults': 'Defaults',
  'Invoice Template': 'Invoice Template',
  'Default': 'Default',
  'Source': 'Source',
  'Download Pattern': 'Download Pattern',
  'Print': 'Print',
  'Process Selected Currency': 'Process Selected Currency',
  'Design': 'Design',
  'Invoice Design': 'Invoice Design',
  'Waybill Design': 'Waybill Design',
  'Size': 'Size',
  'Page Size': 'Page Size',
  'Element Size': 'Elemant Size',
  'Vertical': 'Vertical',
  'Horizontal': 'Horizontal',
  'Specific': 'Specific',
  'Customer Information': 'Customer Information',
  'Product Table': 'Product Table',
  'Total Amount Information': 'Total Amount Information',
  'Location': 'Location',
  'Text Align': 'Text Align',
  'Bold/Italic': 'Bold/Italic',
  'Font Size': 'Font Size',
  'Total With Text': 'Total With Text',
  'Lated Debit': 'Lated Debit',
  'Lated Credit': 'Lated Credit',
  'Total Charged': 'Total Charged',
  'Total Payable': 'Total Payable',
  'Invoice Information': 'Invoice Information',
  'Waybill Information': 'Waybill Information',
  'Show Currency': 'Show Currency',
  'Your Message': 'Your Message',
  'Subject': 'Subject',
  'Comments': 'Comments',
  'Send': 'Send',
  'At least one product or additional charge must be entered': 'At least one product or additional charge must be entered',
  'The delivery address is the same as the billing address': 'The delivery address is the same as the billing address',
  'This product is a main product': 'This product is a main product',
  'This product is a ghost product': 'This product is a ghost product',
  'sales': 'sales',
  'purchase': 'purchase',
  'customer-account-opening': 'customer-account-opening',
  'safe-account-opening': 'safe-account-opening',
  'money-input': 'money-input',
  'money-output': 'money-output',
  'Report': 'Report',
  'Recoveries Report': 'Recoveries Report',
  'Incomes': 'Incomes',
  'Expenses': 'Expenses',
  'Apply': 'Apply',
  'Income Expense Report': 'Income Expense Report',
  'VAT Report': 'VAT Report',
  'Credits Report': 'Credits Report',
  '1-30 Day Delayed': '1-30 Day Delayed',
  '31-60 Day Delayed': '31-60 Day Delayed',
  '61-90 Day Delayed': '61-90 Day Delayed',
  '91-120 Day Delayed': '91-120 Day Delayed',
  '120+ Day Delayed': '120+ Day Delayed',
  'Calculated VAT': 'Calculated VAT',
  'VAT to be Deducted': 'VAT to be Deducted',
  'Total Calculated VAT': 'Total Calculated VAT',
  'Total VAT to be Deducted': 'Total VAT to be Deducted',
  'Piece': 'Piece',
  'KVKK Regulations': 'KVKK Regulations',
  'Top Customers': 'Top Customers',
  'Transaction Report': 'Transaction Report',
  'Safe Report': 'Safe Report',
  'Monthly': 'Monthly',
  'Weekly': 'Weekly',
  'Invoice Category Income Expense Report': 'Invoice Category Income Expense Report',
  'Category Based Report': 'Category Based Report',
  'Verify Your JetOfis Account': 'Verify Your JetOfis Account',
  'Resubmit': 'Resubmit',
  'Copy': 'Copy',
  'Share Mail Invoice': 'Share Mail Invoice',
  'Print Invoice': 'Print Invoice',
  'Print Waybill': 'Print Waybill',
  // common messages
  'x not found': x => `${messages[x]} not found`,

  // support page
  'An unexpected problem occurred during the opening of the page': 'An unexpected problem occurred during the opening of the page',

  'Hook': 'Hook',
  'Starting': 'Starting',
  'Quick Menu': 'Quick Menu',
  'Categories': 'Categories',
  'Categorized': 'Categorized',
  'Uncategorized': 'Uncategorized',
  'Invoice Categories': 'Invoice Categories',
  'Category': 'Category',
  'category': 'category',
  'New Category': 'New Category',
  'Update Category': 'Update Category',

  'Customer': 'Customer',
  'customer': 'customer',
  'Customer List': 'Customer List',
  'New Customer': 'New Customer',
  'Update Customer': 'Update Customer',
  'Customer Category': 'Customer Category',
  'Customer Detail': 'Customer Detail',
  'Customer Search': 'Customer Search',
  'Customer Defaults': 'Customer Defaults',
  'Customer Transaction Extract': 'Customer Extract',
  'Select Date': 'Select Date',
  'Please choose date range': 'Please choose date range!',
  'Start Date': 'Start Date',
  'End Date': 'End Date',

  'Has Balance': 'Has Balance',
  'Has No Balance': 'Has No Balance',
  'Borrower Customer': 'Borrower Customer',
  'Lender Customer': 'Lender Customer',

  'Sequence': 'Sequence',
  'sequence': 'sequence',
  'Sequences': 'Sequences',
  'New Sequence': 'New Sequence',
  'Update Sequence': 'Update Sequence',

  'Support': 'Support',
  'Supports': 'Supports',
  'New Support': 'New Support',
  'Support Detail': 'Support Detail',
  'Add Reply': 'Add Reply',
  'Departmant': 'Departmant',
  'Your Ticket': 'Your Ticket',

  'My Packages': 'My Packages',
  'New Package': 'New Package',

  'Template': 'Template',
  'template': 'template',
  'Templates': 'Templates',
  'New Template': 'New Template',
  'Update Template': 'Update Template',

  'Waybill Template': 'Waybill Template',
  'waybill template': 'waybill template',
  'Waybill Templates': 'Waybill Templates',
  'New Waybill Template': 'New Waybill Template',
  'Update Waybill Template': 'Update Waybill Template',

  'Product': 'Product',
  'product': 'product',
  'Product List': 'Product List',
  'New Product': 'New Product',
  'Update Product': 'Update Product',
  'Product Category': 'Product Category',
  'Product Detail': 'Product Detail',
  'Product Search': 'Product Search',
  'Product Defaults': 'Product Defaults',
  'Create Product Copy': 'Create Product Copy',
  'Main Product': 'Main Product',

  'Additional Charges': 'Additional Charges',
  'Additional Charge': 'Additional Charge',
  'Additional charge': 'Additional charge',
  'New Additional Charge': 'New Additional Charge',
  'Update Additional Charge': 'Update Additional Charge',
  'Additional Charge Category': 'Additional Charge Category',
  'Additional Charge Categories': 'Additional Charge Categories',
  'Additional Charge Search': 'Additional Charge Search',
  'Copy Additional Charge': 'Copy Additional Charge',
  'Duplicate': 'Copy',

  'Your invoice has been created': 'Your invoice has been created!',
  'Your invoice creation was successful': 'Your invoice creation was successful',
  'You received a payment': 'You received a payment!',
  'Your payment has been successful': 'Your payment has been successful.',
  'Stock Decreased': 'Stock Decreased and product stock decreased!',
  'The stock of named product decreased': x => `Invoice created and product named ${x} is at critical stock level.`,
  'E-Invoice will be cancelled. Are you sure?': x => `${x} will be cancelled. Are you sure?`,

  'Sub Products': 'Sub Products',
  'Sub Product': 'Sub Product',
  'New Sub Product': 'New Sub Product',
  'Attributes': 'Attributes',
  'Attribute': 'Attribute',
  'First Attribute Group': 'First Attribute Group',
  'Second Attribute Group': 'Second Attribute Group',
  'Attribute Group': 'Attribute Group',
  'Attribute Groups': 'Attribute Groups',
  'Sub Product Attributes': 'Sub Product Attributes',
  'Attribute New': 'Attribute New',
  'Update Attribute': 'Update Attribute',
  'Change Default Attribute Groups': 'Change Default Attribute Groups',
  'Default attribute groups is not defined.': 'Default attribute groups is not defined.',
  'At least one attribute must be selected': 'At least one attribute must be selected!',

  'Invoice': 'Invoice',
  'invoice': 'invoice',
  'Invoice List': 'Invoice List',
  'New Invoice': 'New Invoice',
  'Create Invoice': 'Create Invoice',
  'Update Invoice': 'Update Invoice',
  'Invoice Category': 'Invoice Category',
  'Invoice Detail': 'Invoice Detail',
  'Sales Invoice': 'Sales Invoice',
  'Purchase Invoice': 'Purchase Invoice',
  'Invoice Defaults': 'Invoice Defaults',
  'Payment Means': 'Payment Means',
  'Payment Agent Name': 'Payment Agent Name',
  'Payment Due Date': 'Payment Due Date',
  'Optional Payment Due Date': 'If the payment date is not entered, the payment date on the invoice will be taken. If there is no payment date, the invoice date will be taken',
  'Party Name': 'Party Name',
  'Instruction Note': 'Instruction Note',
  'EArhhive empty Customer ID': 'If your customer do not have an identification number, it will be entered as 11111111111 and continue',

  'Waybill': 'Waybill',
  'Waybill List': 'Waybill List',
  'Waybill Type': 'Waybill Type',
  'Sending': 'sending',
  'Incoming': 'incoming',
  'Sending Waybill': 'Sending Waybill',
  'Incoming Waybill': 'Incoming Waybill',
  'Date of Shipment': 'Date of Shipment',
  'Waybill No': 'Waybill No',
  'New Sending Waybill': 'New Sending Waybill',
  'New Incoming Waybill': 'New Incoming Waybill',
  'Date Of Shipment': 'Date Of Shipment',
  'Invoice With Waybill': 'Invoice With Waybill',
  'Sales Without Waybill': 'Sales Without Waybill',
  'Invoice With Waybill Info': 'It is a sale that the shipment is made by invoice. After, the waybill cannot create. Product stock decreases.',
  'Sales Without Waybill Info': 'It is a sale that will be shipped without shipment or later. Product stock does not decrease.',
  'Create Sending Waybill': 'Create Sending Waybill',
  'Create Incoming Waybill': 'Create Incoming Waybill',
  'Invoice With Waybilll': 'INVOICE WITH WAYBILL',
  'Sales Without Waybilll': 'SALES WITHOUT WAYBILL',
  'Purchase Without Waybill': 'PURCHASE WITHOUT WAYBILL',
  'Pieces waybill was created': d => `${d} Pieces Waybill Was Created`,
  'Waybill Detail': 'Waybill Detail',
  'Waybill Country': 'Waybill Country',
  'Waybill Address': 'Waybill Address',
  'Waybill City/Town': 'Waybill City/Town',
  'Incoming Waybill Update': 'Incoming Waybill Update',
  'Sending Waybill Update': 'Sending Waybill Update',
  'View Invoice': 'View Invoice',
  'Waybill Invoice': 'Waybill Invoice',
  'Tc Undefined': 'If you dont have a valid identity number on your customer, your identity number will assign as 11111111111. Do you want to continue',
  'Invoice Match': 'Invoice Match',
  'Match Invoice': 'Are you sure about match waybill with this invoice',

  'Safe': 'Safe',
  'Safe List': 'Safe List',
  'New Safe': 'New Safe',
  'Update Safe': 'Update Safe',
  'Safe Detail': 'Safe Detail',

  'Transaction': 'Transaction',
  'transaction': 'transaction',
  'Transaction List': 'Transaction List',
  'Money Input': 'Money Input',
  'Money Output': 'Money Output',
  'Transaction Category': 'Transaction Category',
  'Transaction Detail': 'Transaction Detail',

  'Manager': 'Manager',
  'manager': 'manager',
  'Manager List': 'Manager List',
  'New Manager': 'New Manager',
  'Update Manager': 'Update Manager',
  'Manager Information': 'Manager Information',
  'Token is invalid': 'The token is invalid',
  'Your transaction was successfully completed': 'Your transaction was successfully completed',
  'Email verification completed successfully': 'Email verification completed successfully',

  'User': 'User',
  'Company': 'Company',
  'Information': 'Information',
  'Update User Information': 'Update User Information',
  'User Information': 'User Information',
  'Company Information': 'Company Information',
  'Commissioner Information': 'Commissioner Information',
  'Update User Settings': 'Update User Settings',
  'My Manager Information': 'My Manager Information',
  'Carrier Company Information': 'Carrier Company Information',
  'Carrier Company Chauffeur Information': 'Carrier Company Chauffeur Information',
  'Carrier Title': 'Carrier Title',
  'Carrier Vehicle Plate': 'Carrier Vehicle Plate',
  'Carrier Tax Identification Number': 'Carrier Tax Identification Number',
  'Actual Shipping Date': 'Actual Shipping Date',
  'E-Invoice Warn': 'At least one of the Carrier Title or Carrier Chauffeur Name fields must be filled in.',

  'General Settings': 'General Settings',
  'Integration Web Service Settings': 'Integration Web Service Settings',
  'T-Soft Web Service Settings': 'T-Soft Web Service Settings',
  'Invoice Settings': 'Invoice Settings',
  'E-Invoice & E-Archive Settings': 'E-Invoice & E-Archive Settings',
  'Invoice & E-Invoice & E-Archive Settings': 'Invoice & E-Invoice & E-Archive Settings',
  'Product Settings': 'Product Settings',
  'E-Waybill Invoice Settings': 'E-Waybill Invoice Settings',
  'E-Waybill Settings': 'E-Waybill Settings',
  'You can configure your T-Soft Web Service settings using this menu': 'You can configure your T-Soft Web Service settings using this menu',
  'You can configure your general settings using this menu': 'You can configure your general settings using this menu',
  'You can configure your integration settings using this menu': 'You can configure your integration settings using this menu',
  'You can configure your invoice settings using this menu': 'You can configure your invoice settings using this menu',
  'You can configure your product settings using this menu': 'You can configure your product settings using this menu',
  'You can configure your E-Waybill settings using this menu': 'You can configure your E-Waybill settings using this menu',
  'Should modal be switched on when adding product and additional charge to the invoice': 'Should modal be switched on when adding product and additional charge?',
  'Should modal be opened when adding additional charge to the product': 'Should modal be opened when adding additional charge to the product?',
  'Amount of increase in decimal numbers': 'Amount of increase in decimal numbers',
  'Critical Stock Alert Count': 'Critical Stock Alert Count',
  'Surname': 'Surname',
  'Product name view on invoice': 'Product name view on invoice',
  'Is Activate Partial Vat': 'Is Activate Partial Vat',
  'E-Invoice to be sent as e-mail?': 'E-Invoice to be sent as e-mail?',
  'Use Person Name': 'Use Person Name',
  'Send Supplier Code Instead of Product Code': 'Send Supplier Code Instead of Product Code',
  'General Settings Success': 'General Settings Saved.',
  'Invoice Settings Success': 'Invoice Settings Saved.',
  'Product Settings Success': 'Product Settings Saved.',
  'Web Service Settings Success': 'T-Soft Web Service Settings Saved.',
  'Integration Settings Success': 'Integration Settings Saved.',
  'E-Waybill Settings Success': 'E-Waybill Settings Saved.',
  'Product Name': 'Product Name',
  'Does use marketplace customer': 'Does use marketplace customer?',
  'Marketplace Customer': 'Marketplace Customer',

  'Create Return': 'Create Return',
  'Return Invoice': 'Return Invoice',
  'Return Sales Invoice': 'Return Sales Invoice',
  'Return Purchase Invoice': 'Return Purchase Invoice',
  'Return': 'Return',
  'Get Payment to Return Invoice': 'Get Payment To Return Invoice',
  'Pay to Return Invoice': 'Pay To Return Invoice',
  'This customer has debt': (d, e) => `This customer has ${d + ' ' + e} debt`,

  'Name': 'Name',
  'Name Surname': 'Name Surname',
  'Balance': 'Balance',
  'Opening Balance': 'Opening Balance',
  'Account Opening': 'Account Opening',
  'Customer Account Opening': 'Customer Account Opening',
  'Safe Account Opening': 'Safe Account Opening',
  'Opening Stock': 'Opening Stock',
  'Currency': 'Currency',
  'Fixed Exchange Rate': 'Fixed Exchange Rate',
  'Stock': 'Stock',
  'Stock Tracking': 'Stock Tracking',
  'Barcode': 'Barcode',
  'Vat': 'Vat',
  'Unit': 'Unit',
  'Selling Price': 'Selling Price',
  'Buying Price': 'Buying Price',
  'Legal Person': 'Legal Person',
  'Real Person': 'Real Person',
  'Phone': 'Phone',
  'Automatic Code': 'Automatic Code',
  'Code': 'Code',
  'Country': 'Country',
  'City': 'City',
  'Town': 'Town',
  'Address': 'Address',
  'Invoice Address': 'Invoice Address',
  'Invoice Country': 'Invoice Country',
  'City/Country': 'City/Country',
  'Invoice City/Town': 'Invoice City/Town',
  'Invoice Postal Code': 'Invoice Postal Code',
  'Delivery Address': 'Delivery Address',
  'Delivery Country': 'Delivery Country',
  'Delivery City/Town': 'Delivery City/Town',
  'Delivery Postal Code': 'Delivery Postal Code',
  'Waybill Postal Code': 'Waybill Postal Code',
  'Postal Code': 'Postal Code',
  'Id/Tax Identification Number': 'Id/Tax Identification Number',
  'Tax Identification Number': 'Tax Identification Number',
  'Identification Number': 'Identification Number',
  'Tax Administration': 'Tax Administration',
  'Explanation': 'Explanation',
  'Marketplace Order No': 'Marketplace Order No',
  'Marketplace': 'Marketplace',
  'Credit': 'Credit',
  'Credits': 'Credits',
  'credit': 'credit',
  'Debit': 'Debit',
  'Debits': 'Debits',
  'debit': 'debit',
  'Input': 'Input',
  'Output': 'Output',
  'input': 'input',
  'output': 'output',
  'Date': 'Date',
  'Textbox': 'Text box',
  'Expiry Date': 'Expiry Date',
  'Date Format': 'Date Format',
  'Amount': 'Amount',
  'Price': 'Price',
  'Commissioner Name Surname': 'Authorized Person Name & Surname',
  'Commissioner E-Mail': 'Authorized Person E-Mail',
  'Commissioner Phone': 'Authorized Person Phone',
  'Company Name': 'Company Name',
  'Title': 'Title',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Title2': 'Title',
  'Total': 'Total',
  'Interim Total': 'Sub Total',
  'Grand Total': 'Sum Total',
  'Total VAT': 'Total VAT',
  'Total VAT Amount of Sales': 'Total VAT Amount of Sales',
  'Total VAT Amount of Purchase': 'Total VAT Amount of Purchase',
  'Remaining': 'Remaining',
  'Discount': 'Discount',
  'Total Discount': 'Total Discount',
  'Percent': 'Percent',
  'Value': 'Value',
  'Series': 'Series',
  'Queue': 'Queue',
  'Invoice No': 'Invoice No',
  'Quantity': 'Quantity',
  'Payment List': 'Payment List',
  'Solved': 'Solved',
  'Unsolved': 'Unsolved',
  'In_process': 'In Process',
  'Pending': 'Pending',
  'Corporate Code': 'Corporate Code',
  'Login Name': 'Login Name',
  'E-Invoice': 'E-Invoice',
  'E-Archive': 'E-Archive',
  'E-Despatch': 'E-Despatch',
  'Web Hook': 'Web Hook',
  'Web Hook List': 'Web Hook List',
  'Web Hook Detail': 'Web Hook Detail',
  'New Web Hook': 'New Web Hook',
  'Http Status Code': 'Http Status Code',
  'Method URI': 'Method URI',
  'Store URI': 'Store URI',
  'Create': 'Create',
  'Methods': 'Methods',
  'Method': 'Method',
  'Other': 'Other',
  'Where to get product informations': 'Where to get product informations?',
  'Describe of where to get product informations': d => `Your product information on the invoice will be get from the ${d} platform.`,
  'Pieces return invoice was created': d => `${d} Pieces Return Invoice Was Created`,
  'Payment Date': 'Payment Date',
  'Expiry date is today': 'Payment day is today',

  'Add Sales Invoice': 'Add Sales Invoice',
  'Add Purchase Invoice': 'Add Purchase Invoice',
  'Invoice Name': 'Invoice Name',
  'Supplier Product Code': 'Supplier Product Code',
  'Has Stock': 'Has Stock',
  'Has No Stock': 'Has No Stock',

  'Previous Calculated Vat': 'Previous Calculated Vat',
  'Total Reduced VAT': 'Total Reduced VAT',
  'Payable VAT': 'Payable VAT',
  'VAT Transferred To Next Period': 'VAT Transferred To Next Period',
  'VAT To Be Reduced For This Period': 'VAT To Be Reduced For This Period',
  'This Sale was Made Over the Internet': 'This Sale was Made Over the Internet. \nIt pass as waybill.',
  'Xlsx Export': 'Xlsx Export',
  'Csv Export': 'Csv Export',

  'E-Commerce Invoice Settings': 'E-Commerce Settings',
  'E-Invoice Template Code': 'E-Invoice Template Code',
  'E-Archive Template Code': 'E-Archive Template Code',
  'Tax Exemption Code': 'Tax Exemption Code',
  'Tax Exemption Description': 'Tax Exemption Description',
  'Transporter Company Title': 'Transporter Company Title',
  'Transporter Company TIN': 'Transporter Company TIN',
  'Transporter VKN No': 'Transporter VKN No',
  'Online Sales URL': 'Online Sales URL',
  'Default Payment Means Code': 'Default Payment Means Code',
  'Default Instruction Note': 'Default Instruction Note',

  'Bank Name': 'Bank Name',
  'IBAN No': 'IBAN No',
  'paying': 'pay',

  'Credit Card': 'Credit Card',
  'Pay at the Door': 'Pay at the Door',
  'Eft Money Order': 'Eft / Money Order',
  'Payment Agent': 'Payment Agent',
  'Payment Agent Name Speacil Status': 'If the invoice has a payment agent, the payment agent of the invoice will be written, if there is no payment agent, the payment agent will be written',

  'Incoming E-Invoice': 'Incoming E-Invoice',
  'Incoming E-Invoice Box': 'Incoming E-Invoice Box',
  'Incoming E-Invoice Box Detail': 'Incoming E-Invoice Box Detail',
  'Total Price': 'Total Price',
  'Invoice City': 'Invoice City',
  'Invoice Town': 'Invoice Town',
  'Saving Status': 'Saving Status',
  'Show Transferred': 'Show Transferred',
  'Show Not Transferred': 'Show Not Transferred',
  'Matched': 'Matched',
  'Imported': 'Imported',
  'Match customer and product': 'Match customer and product.',
  'Match Customer': 'Match Customer',
  'Match Product': 'Match Product',
  'Selected Customer': 'Selected Customer',
  'Transfer Steps': 'Transfer Steps',
  'Please match customer': 'Please match customer.',
  'Please match product': 'Please match product.',
  'Please complete the import process': 'Please complete the import process.',
  'Customer match process completed': 'Customer match process completed.',
  'Product match process completed': 'Product match process completed.',
  'Import process completed': 'Customer match process completed.',
  'Get Available Invoices': 'Get Available Invoices',
  'It returns invoices for last 30 days': 'It returns invoices for last 30 days.',
  'Show Unsaved': 'Show Unsaved',
  'Show Saved': 'Show Saved',
  'Blacklist': 'Blacklist',
  'Default List': 'Default List',

  'Dated Bills': 'Dated Bills',
  'Press ENTER, after entering the text': 'Press ENTER, after entering the text.',
  'Unpursued': 'Unpursued',
  'Product Record Control': 'Product Record Control',
  'Record Control': 'Record Control',
  'Record Date': 'Record Date',
  'insert': 'Insert',
  'update': 'Update',
  'delete': 'Delete',

  'Message': 'Message',
  'Customer Balance Report': 'Customer Balance Report',
  'Balance Report': 'Balance Report',
  'Last Transaction Date': 'Last Transaction Date',
  'Selected Date Debit': 'Selected Date Debit',
  'Selected Date Credit': 'Selected Date Credit',
  'Total Debit': 'Total Debit',
  'Total Credit': 'Total Credit',
  'Calculations were made over today': 'Calculations were made over today exchange!',
  'You can send up to 10 invoices!': 'You can send up to 10 invoices!',
  'You can send up to 10 waybill!': 'You can send up to 10 waybill!',

  'Please Fill All Inputs': 'Please Fill All Inputs!',
  'Number of record to be start': 'Number of customer to be start',
  'Total Record Count': 'Total Record Count',
  'You can record up to 1000 records': 'You can record up to 1000 records!',

  'Share Invoice': 'Share Invoice',
  'Share E-Invoice Mail': 'Share E-Invoice Mail',
  'To': 'To',
  'Content': 'Content',
  'Enter Mail / Mails': 'Enter Mail / Mails.',
  'Enter Mail Content': 'Enter Mail Content.',
  'Enter Mail Subject': 'Enter Mail Subject.',
  'Emails That Cannot Be Sent': 'E-Mails That Cannot Be Sent',
  'Your e-invoices with these codes/codes could not be sent as e-mail.': 'Your e-invoices with these codes/codes could not be sent as e-mail!',
  'Your invoices with these codes/codes could not be sent as e-mail.': 'Your invoices with these codes/codes could not be sent as e-mail.',
  'Let it be sent to customer mail': 'Let it be sent to customer mail',
  'Let it be sent to the e-mails I have determined': 'Let it sent mails I have determined',
  'Mail Address / Addresses': 'Mail Address / Addresses',

  'Settle Net': 'Settle Net',
  'Invoice Code': 'Invoice Code',
  'Invoice Amount': 'Invoice Amount',
  'Settle Net Amount': 'Settle Net Amount',
  'Settle Net Detail': 'Settle Net Detail',

  'Create E-Transform': 'Create E-Transform',
  'Create E-Archive': 'Create E-Archive',
  'Create E-Invoice': 'Create E-Invoice',
  'Preview Creating Multiple E-Transform': d => `Preview Creating Multiple ${d}`,
  'Cancel': 'Cancel',

  'Sending Date': 'Sending Date',

  'Cancel Invoice Message': 'This will affect the reports and other calculations when the invoice is canceled. Do you want to continue',
  'Invoice Has Been Cancelled': 'Invoice Has Been Cancelled',
  'Cancel Invoice Detail Message': 'Your invoice has been canceled. Reports and other calculations will be affected! If you want it not to affect the calculations, please delete this invoice.',
  'Cancel Invoice': 'Cancel Invoice',
  'Activate Invoice': 'Activate Invoice',
  'Activate Invoice Message': 'Are you sure you want to activate your invoice',
  'Customer Name': 'Customer Name',

  'You have not yet sent a payment notification and have not received payment': 'You have not yet sent a payment notification and have not received payment!',
  'Get Full Payment To Invoice': 'Get Full Payment To Invoice',
  'Payment Notification Control': 'Payment Notification Control',
  'Send Payment Notification': 'Send Payment Notification',
  'Payment Records': 'Payment Records',
  'Payment': 'Payment',
  'Site Address': 'Site Address',
  'Send Shipment Carrier Information': 'Send Shipment Carrier Information',

  try: 'Turkish Lira',
  usd: 'American Dollar',
  eur: 'Euro',
  gbp: 'English Pound',
  rub: 'Russian Ruble',

  'date-formats': {
    'DD.MM.YYYY': 'DD.MM.YYYY',
    'MM.DD.YYYY': 'MM.DD.YYYY',
    'YYYY.MM.DD': 'YYYY.MM.DD',
    'YYYY.DD.MM': 'YYYY.DD.MM',
    'DD-MM-YYYY': 'DD-MM-YYYY',
    'MM-DD-YYYY': 'MM-DD-YYYY',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'YYYY-DD-MM': 'YYYY-DD-MM',
    'DD/MM/YYYY': 'DD/MM/YYYY',
    'MM/DD/YYYY': 'MM/DD/YYYY',
    'YYYY/MM/DD': 'YYYY/MM/DD',
    'YYYY/DD/MM': 'YYYY/DD/MM',
  },

  'route': {
    'login': 'login',
    'forgot-my-password': 'forgot-my-password',
    'register': 'register',
    'register/:commissioner_e_mail?': 'register/:commissioner_e_mail?',
    'customer/transactions-export': 'customer/transactions-export',
    'customer/balance-report': 'customer/balance-report',
    'product/update/:id': 'product/update/:id',
    'product/detail/:id': 'product/detail/:id',
    'product/defaults': 'product/defaults',
    'invoice/:type/update/:id': 'invoice/:type/update/:id',
    'invoice/defaults': 'invoice/defaults',
    'invoice/incoming-e-invoice-box': 'invoice/incoming-e-invoice-box',
    'invoice/detail/incoming-e-invoice-box/:id': 'invoice/detail/incoming-e-invoice-box/:id',
    'waybill/list': 'waybill/list',
    'invoice/invoice-income-expense-report': 'invoice/invoice-income-expense-report',
    'transaction/recoveries': 'transaction/recoveries',
    'sequence': 'sequence',
    'sequence/new': 'sequence/new',
    'sequence/update/:id': 'sequence/update/:id',
    'template/list': 'template/list',
    'template/new': 'template/new',
    'template/update/:id': 'template/update/:id',
    'template/design/:id': 'template/design/:id',
    'waybill-template/list': 'waybill-template/list',
    'waybill-template/new': 'waybill-template/new',
    'waybill-template/update/:id': 'waybill-template/update/:id',
    'waybill-template/design/:id': 'waybill-template/design/:id',
    'product/detail/:id/sub-product/update/:sub_product_id': 'product/detail/:id/sub-product/update/:sub_product_id',
    'sub-product/attributes/:id?': 'sub-product/attributes/:id?',
    'sub-product/attributes/:id/attribute/new': 'sub-product/attributes/:id/attribute/new',
    'sub-product/attributes/:id/attribute/update/:attribute_id': 'sub-product/attributes/:id/attribute/update/:attribute_id',
    'product/detail/:id/set-default-attribute-groups': 'product/detail/:id/set-default-attribute-groups',
    'waybill/new/:type': 'waybill/new/:type',
    'waybill/:type/update/:id': 'waybill/:type/update/:id',
    'waybill/new/:type/:id': 'waybill/new/:type/:id',
    'waybill/:type/detail/:id': 'waybill/:type/detail/:id',
  },

  'Dashboard': 'Dashboard',
  'Are you sure you want to continue': 'Are you sure you want to continue',
  'Welcome Jetofis': 'Welcome Jetofis',
  'Days Late': d => `${d} Days Late`,
  'Cash flow for the last and next {x} days': d => 'Cash flow for the last and next {x} days'.replace('{x}', d),
  'Your session is over': 'Your session is over',

  'languages': {
    'tr': 'Turkish',
    'en': 'English',
  },

  'formValidationMessages': {
    default: 'Validation error on field %s',
    required: '%s is required',
    enum: '%s must be one of %s',
    whitespace: '%s cannot be empty',
    date: {
      format: '%s date %s is invalid for format %s',
      parse: '%s date could not be parsed, %s is invalid ',
      invalid: '%s date %s is invalid',
    },
    types: {
      string: '%s is not a %s',
      method: '%s is not a %s (function)',
      array: '%s is not an %s',
      object: '%s is not an %s',
      number: '%s is not a %s',
      date: '%s is not a %s',
      boolean: '%s is not a %s',
      integer: '%s is not an %s',
      float: '%s is not a %s',
      regexp: '%s is not a valid %s',
      email: '%s is not a valid %s',
      url: '%s is not a valid %s',
      hex: '%s is not a valid %s',
    },
    string: {
      len: '%s must be exactly %s characters',
      min: '%s must be at least %s characters',
      max: '%s cannot be longer than %s characters',
      range: '%s must be between %s and %s characters',
    },
    number: {
      len: '%s must equal %s',
      min: '%s cannot be less than %s',
      max: '%s cannot be greater than %s',
      range: '%s must be between %s and %s',
    },
    array: {
      len: '%s must be exactly %s in length',
      min: '%s cannot be less than %s in length',
      max: '%s cannot be greater than %s in length',
      range: '%s must be between %s and %s in length',
    },
    pattern: {
      mismatch: '%s value %s does not match pattern %s',
    },
    clone() {
      const cloned = JSON.parse(JSON.stringify(this))
      cloned.clone = this.clone
      return cloned
    },
  },

  formFields: {
    e_mail: 'E-Mail',
    password: 'Password',
    web_site: 'Website',
    'safe[name]': 'Safe name',
    'safe[code]': 'Safe code',
    'safe[currency]': 'Safe currency',
    'account_opening[type]': 'Opening balance type',
    'account_opening[amount]': 'Opening balance amount',
    'account_opening[date]': 'Date',
    'safe[default]': 'Default',
    'product[name]': 'Product name',
    'product[category_id]': 'Product category',
    'product[code]': 'Product code',
    'product[stock_tracking]': 'Product stock tracking',
    'product[unit_id]': 'Product unit',
    'product[vat]': 'Product vat',
    'product[buying_price]': 'Product buying price',
    'product[selling_price]': 'Product selling price',
    'product[buying_currency]': 'Product buying currency',
    'product[selling_currency]': 'Product selling currency',
    'stock_opening[type]': 'Opening stock type',
    'stock_opening[quantity]': 'Opening stock quantity',
    'stock_opening[date]': 'Date',
    'customer[name]': 'Customer name',
    'customer[category_id]': 'Customer category',
    'customer[code]': 'Customer code',
    'customer[phone_1]': 'Customer phone 1',
    'customer[phone_2]': 'Customer phone 2',
    'customer[country]': 'Customer country',
    'customer[city]': 'Customer city',
    'customer[town]': 'Customer town',
    'customer[address]': 'Customer address',
    'customer[e_mail]': 'Customer e-mail',
    'customer[type]': 'Customer type',
    'customer[id_or_tax_identification_number]': 'Customer id/tax identification number',
    'customer[tax_administration]': 'Tax administration',
    process_selected_currency: 'Process selected currency amount',
    currency: 'Currency',
    'account_opening[currency_rates][usd]': 'Opening balance usd currency rates',
    'account_opening[currency_rates][try]': 'Opening balance try currency rates',
    'account_opening[currency_rates][eur]': 'Opening balance eur currency rates',
    'customer[explanation]': 'Customer explanation',
    company_name: 'Company name',
    commissioner_name_surname: 'Commissioner name surname',
    commissioner_e_mail: 'Commissioner e-mail',
    commissioner_phone: 'Commissioner phone',
    kvkk_status: 'Please confrim the KVKK Regulations',
    title: 'Title',
    phone_1: 'Phone 1',
    phone_2: 'Phone 2',
    country: 'Country',
    city: 'City',
    town: 'Town',
    address: 'Address',
    name_surname: 'Name surname',
    tax_identification_number: 'ax identification number',
    tax_administration: 'Tax administration',
    fixed_exchange_rate: 'Fixed exchange rate',
    'invoice[customer_id]': 'Invoice customer',
    type: 'Type',
    'invoice[category_id]': 'Invoice category',
    'invoice[code]': 'Invoice code',
    'invoice[series]': 'Invoice series',
    'invoice[no]': 'Invoice quaque',
    'invoice[date]': 'Invoice date',
    'invoice[expiry_date]': 'Invoice expiry date',
    'invoice[currency]': 'Invoice currency',
    'invoice[currency_rates][usd]': 'Invoice usd currency rates',
    'invoice[currency_rates][try]': 'Invoice try currency rates',
    'invoice[currency_rates][eur]': 'Invoice eur currency rates',
    'invoice[discount]': 'Invoice discount',
    'invoice[discount_type]': 'Invoice discount type',
    'invoice[invoice_country]': 'Invoice country',
    'invoice[invoice_city]': 'Invoice city',
    'invoice[invoice_town]': 'Invoice town',
    'invoice[invoice_address]': 'Invoice address',
    'invoice[delivery_country]': 'Delivery country',
    'invoice[delivery_city]': 'Delivery city',
    'invoice[delivery_town]': 'Delivery town',
    'invoice[delivery_address]': 'Delivery address',
    'invoice[explanation]': 'Invoice explanation',
    'category[name]': 'Category name',
    'category[code]': 'Category code',
    'additional_charge[name]': 'Additional charge name',
    'additional_charge[code]': 'Additional charge code',
    'additional_charge[unit_id]': 'Ek ücret birimi',
    'additional_charge[category_id]': 'Additional charge category',
    'additional_charge[buying_price]': 'Additional charge buying price',
    'additional_charge[selling_price]': 'Additional charge selling price',
    'additional_charge[vat]': 'Additional charge vat',
    name: 'Sequence name',
    step: 'Sequence step',
    start: 'Sequence start',
    end: 'Sequence end',
    current_value: 'Sequence current value',
    pattern: 'Sequence pattern',
    show_all_columns: 'Show all columns',
    instruction_note: 'Instruction Note',
    series: 'Invoice Series',
    no: 'Invoice No',
    invoice_country: 'Invoice Country',
    invoice_city: 'Invoice City',
    invoice_town: 'Invoice Town',
    invoice_address: 'Invoice Address',
    delivery_country: 'Delivery Country',
    delivery_city: 'Delivery City',
    delivery_town: 'Delivery Town',
    delivery_address: 'Delivery Address',
    explanation: 'Explanation',
    departmant_id: 'Departmant',
    content: 'Message',
    subject: 'Subject',
    'attribute[code]': 'Attribute Code',
    'attribute[title]': 'Attribute Name',
    'waybill[customer_id]': 'Customer',
    'waybill[date]': 'Date',
    'waybill[sending_date]': 'Sending Date',
    'attribute_group[first]': 'First Attribute Group',
    'product[attributes][0][attribute_id]': 'Attribute 1',
    'product[attributes][1][attribute_id]': 'Attribute 2',
    'tahsildar_ws[store_uri]': 'Site Address',
    'tahsildar_ws[ws_username]': 'Login Name',
    'tahsildar_ws[ws_password]': 'Password',
    'mail_addresses': 'Mail Addres / Addresses',
    'sending_date': 'Sending Date',
    'payment_agent_means': 'Payment Agent Means',
  },

  'countries': [
    'ABD', 'Afganistan', 'Almanya', 'Angola', 'Anguilla', 'Arjantin', 'Arnavutluk', 'Aruba', 'Avustralya', 'Avusturya', 'Azerbeycan', 'Bahamalar', 'Bahreyn', 'Bangladeş',
    'Barbados', 'Batı Somoa', 'Belize', 'Belçika', 'Benin', 'Bermuda', 'Birleşik Arap Emirlikleri', 'Bolivya', 'Bosna Hersek', 'Botsvana', 'Brezilya', 'Brunei', 'Bulgaristan',
    'Burundi', 'Cebelitarık', 'Cezayir', 'Cook Adaları', 'Curaçao', 'Danimarka', 'Dominik', 'Dominika', 'Ekvator', 'El Salvador', 'Endonezya', 'Eritre', 'Ermenistan', 'Estonya',
    'Etiyopya', 'Falkland Adaları', 'Foroe Adaları', 'Fas', 'Fiji', 'Fildişi Sahilleri', 'Filipinler', 'Filistin', 'Finlandiya', 'Fransa', 'Bagon', 'Gana', 'Gine Cumhuriyeti',
    'Greneda', 'Grönland', 'Guatemala', 'Guyana', 'Güney Amerika', 'Gürcistan', 'Haiti', 'Hindistan', 'Hollanda', 'Hong Kong', 'Hırvatistan', 'Irak', 'Jamaika', 'Japonya',
    'K.K.T.C', 'Kosova', 'Kamboçya', 'Kamerun', 'Kanada', 'Karadağ', 'Katar', 'Kazakistan', 'Kenya', 'Kolombiya', 'Kongo', 'Kore Cumhuriyeti', 'Kosta Rika', 'Kuveyt', 'Küba',
    'Kırgızistan', 'Laos', 'Latvia', 'Lesotho', 'Liberya', 'Libya', 'Lihtenştayn', 'Litvanya', 'Lübnan', 'Lüksemburg', 'Macaristan', 'Madagaskar', 'Makau', 'Maldives', 'Malezya',
    'Mali', 'Malta', 'Meksika', 'Moldovya', 'Monako', 'Mozambik', 'Moğolistan', 'Mısır', 'Namibya', 'Nepal', 'Nijerya', 'Nikaragua', 'Norveç', 'Pakistan', 'Palua', 'Panama',
    'Papua Yeni Gine', 'Paraguay', 'Peru', 'Polonya', 'Portekiz', 'Porto Rika', 'Romanya', 'Ruanda', 'Senegal', 'Singapur', 'Slovakya', 'Slovenya', 'Soloman Adaları', 'Somali',
    'Sri Lanka', 'Sudan', 'Suriye', 'Suudi Arabistan', 'Sırbistan', 'Tacikistan', 'Tanzanya', 'Tayland', 'Tayvan', 'Togo', 'Tongo', 'Tunus', 'Türkmenistan', 'Türkiye', 'Uganda',
    'Ukrayna', 'Umman', 'Uruguay', 'Venezuela', 'Vietnam', 'Virjin Adaları', 'Yemen', 'Yeni Zelanda', 'Yuguslavya', 'Yunanistan', 'Zambiya', 'Zimbabve', 'Çad', 'Çek Cumhuriyeti',
    'Çin Halk Cumhuriyeti', 'Özbekistan', 'Ürdün', 'İngiltere', 'İrlanda', 'İskoçya', 'İspanya', 'İsrail', 'İsveç', 'İsviçre', 'İtalya', 'İzlanda', 'Şili',
  ],

  vats: [0, 1, 8, 10, 18, 20],

  'Switch Theme': 'Switch Theme',
  'Dark': 'Dark',
  'Light': 'Light',

}

export default messages
