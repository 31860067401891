import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Tag, Card, Button } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode, getCurrencyWithId } from '../../../data/Currency'
import { cloneDeep, startCase, kebabCase } from 'lodash'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import moment from 'moment'
import routes from '../../../routes'
import { generatePath } from 'react-router-dom'

const ButtonGroup = Button.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe Report', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($sorting: SafeTransactionSorting, $paging: Paging, $startDate:Date! ,$endDate:Date!, $filter: SafeTransactionFilterBase){
      reports {
        safeTransaction {
          moving(field:date moving:1 interval:{start_date:$startDate ,end_date:$endDate}) {
            sum(field: amount, group: type) {
              results {
                type
                sum
              }
            }
          }
        }
      }
      safeTransactionList(sorting: $sorting, paging: $paging, filter:  $filter){
        transactions{
          __typename
          ... on MoneyInput{
            transaction_id
            amount
            safe{
              name
              currency
            }
            date

          }
          ... on MoneyOutput{
            transaction_id
            safe{
              name
              currency
            }
            date
            amount
          }
          ... on Credit {
            transaction_id
            amount
            date
            customer {
              name
            }
            safe{
              name
              currency
            }
          }
          ... on Debit {
            transaction_id
            amount
            date
            customer {
              name
            }
            safe{
              name
              currency
            }
          }

          ... on AccountOpeningSafe{
            transaction_id
            amount
            date
            safe_id
            type
            safe{
              name
              currency
            }
          }
        }
        page_info{
          total_count
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

class SafeReport extends Component {

  state = {
    data: null,
    chartDatas: [],
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    chartXaxisStart: parseInt(moment().format('DD').toString()) - parseInt(moment().startOf('month').format('DD').toString()),
    chartXaxisEnd: (parseInt(moment().endOf('month').format('DD').toString()) - parseInt(moment().format('DD').toString())) + 2,
    allowChartUpdate: true,
    pagination: {
      pageSize: 10,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    sorting: null,
    paging: {
      offset: 0,
      limit: 10,
    },
    reportType: 'month',
  }

  columns = [
    {
      title: this.props.langData['Type'],
      dataIndex: '__typename',
      key: 'type',
      width: '20%',
      render: (value, record) => {
        if (value === 'Credit')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Credit']}</Tag>
        if (value === 'Debit')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Debit']}</Tag>
        if (value === 'MoneyInput')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Money Input']}</Tag>
        if (value === 'MoneyOutput')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Money Output']}</Tag>
        if (value === 'AccountOpeningSafe')
          return (
            record.type === 'MoneyInput' ?
              <Tag color="#1890ff"
                   style={{ marginRight: 0 }}>{this.props.langData['Safe Account Opening'] + '-' + this.props.langData['Money Input']}</Tag>
              : <Tag color="#ff4d4f"
                     style={{ marginRight: 0 }}>{this.props.langData['Safe Account Opening'] + '-' + this.props.langData['Money Output']}</Tag>
          )
      },
    },
    {
      title: this.props.langData['Safe'],
      dataIndex: 'safe.name',
      width: '20%',
    },
    {
      title: this.props.langData['Customer'],
      width: '20%',
      dataIndex: 'customer.name',
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      width: '20%',
      sorter: true,
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY H:mm'),
    },
    {
      title: this.props.langData['Amount'],
      dataIndex: 'amount',
      width: '20%',
      align: 'right',
      render: (value, data) => `${toLocaleString(value)} ${getCurrencyWithCode(data.safe.currency).symbol}`,
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add(routes.SAFE_REPORT)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize)
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pagesize,
        },
      })
    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  rowItemClick = record => {

    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      type: record.__typename === 'AccountOpeningSafe' ? kebabCase(this.props.langData['Safe Account Opening']) : kebabCase(this.props.langData[startCase(record.__typename)]),
      id: record.__typename === 'AccountOpeningSafe' ? record.safe_id : record.transaction_id,
    }))

  }

  render() {
    const options = {
      chart: {
        height: '450px',
        type: 'column',
      },
      title: false,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      xAxis: {
        categories: (() => {
          let data = []
          for (let i = -(this.state.chartXaxisStart); i < this.state.chartXaxisEnd; i++) {
            let date
            if (i === 0)
              date = this.props.langData['Today']
            else {
              if (this.state.reportType === 'week')
                date = moment().locale(this.props.lang).add(i, 'days').format('dddd')
              else
                date = moment().locale(this.props.lang).add(i, 'days').format('DD MMMM')
            }
            data.push(date)
          }
          return data
        })(),
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        useHTML: true,
        formatter: (() => {
          return function () {
            return this.points.map(x => {
              return `
								${x.series.name}: <b>${toLocaleString(x.y)} ${x.series.userOptions.custom}</b><br/>
							`
            }).join('')
          }
        })(),
      },
      colors: ['#F12525', '#4897F1', '#008000', '#70012B'],
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      series: this.state.chartDatas || [],
      credits: { enabled: false },
    }
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{
          filter: {
            AND: [{
              credit: { date: { gte: this.state.startDate, lte: this.state.endDate } },
              OR: [{
                debit: { date: { gte: this.state.startDate, lte: this.state.endDate } },
                OR: [{
                  money_input: { date: { gte: this.state.startDate, lte: this.state.endDate } },

                  OR: [{
                    money_output: { date: { gte: this.state.startDate, lte: this.state.endDate } },
                  }],
                }],
              }],
            }],
          },
          sorting: this.state.sorting,
          paging: this.state.paging,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }}
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          let moneyOutputCarths = []
          let moneyInputCharts = []

          let debitCharts = []
          let creditCharts = []

          let moving = data.reports.safeTransaction.moving

          for (let c = 0; c < data.reports.safeTransaction.moving.sum.length; c++) {
            moneyOutputCarths.push(moving.sum[c].results.filter(x => x.type === 'MoneyOutput').length ? -+moving.sum[c].results.filter(x => x.type === 'MoneyOutput')[0].sum : 0)
            moneyInputCharts.push(moving.sum[c].results.filter(x => x.type === 'MoneyInput').length ? moving.sum[c].results.filter(x => x.type === 'MoneyInput')[0].sum : 0)
            debitCharts.push(moving.sum[c].results.filter(x => x.type === 'Debit').length ? moving.sum[c].results.filter(x => x.type === 'Debit')[0].sum : 0)
            creditCharts.push(moving.sum[c].results.filter(x => x.type === 'Credit').length ? -+moving.sum[c].results.filter(x => x.type === 'Credit')[0].sum : 0)
          }

          const symbol = getCurrencyWithId(data.legacyData.currency_id).symbol

          const chartdts = [
            {
              name: this.props.langData['Money Output'],
              data: moneyOutputCarths,
              custom: symbol,
              stack: 'male',
            },
            {
              name: this.props.langData['Money Input'],
              data: moneyInputCharts,
              custom: symbol,
              stack: 'female',
            },
            {
              name: this.props.langData['Debit'],
              data: debitCharts,
              custom: symbol,
              stack: 'female',
            },
            {
              name: this.props.langData['Credit'],
              data: creditCharts,
              custom: symbol,
              stack: 'male',
            },
          ]

          this.setState({
            chartDatas: chartdts,
          })

          this.setState({
            data: cloneDeep(data.safeTransactionList.transactions),
            pagination: {
              ...this.state.pagination,
              total: data.safeTransactionList.page_info.total_count,
            },
          })

        }}
      >
        {({ loading: loadingQuery, refetch }) => {

          return (
            <div>
              <Card
                title={
                  moment(this.state.startDate)
                    .locale(this.props.lang)
                    .format('DD MMMM')
                  + ' - ' +
                  moment(this.state.endDate)
                    .locale(this.props.lang)
                    .format('DD MMMM')
                  + ' ' +
                  this.props.langData['Safe Report']
                }
                headStyle={{ paddingBottom: 0 }}
                bodyStyle={{ paddingTop: 5 }}
              >
                <ButtonGroup style={{ float: 'right' }}>
                  <Button
                    onClick={() => {
                      this.allowChartUpdate = false
                      this.setState({
                        reportType: 'week',
                        startDate: moment().startOf('week').format('YYYY-MM-DD'),
                        endDate: moment().endOf('week').add(1, 'days').format('YYYY-MM-DD'),
                        chartXaxisStart: 0,
                        chartXaxisEnd: 6,

                      })
                      refetch()
                    }}
                  >
                    <b> {this.props.langData['Weekly']}</b>
                  </Button>
                  <Button
                    onClick={() => {
                      this.allowChartUpdate = false
                      this.setState({
                        reportType: 'month',
                        startDate: moment().startOf('month').format('YYYY-MM-DD'),
                        endDate: moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
                        chartXaxisStart: parseInt(moment().format('DD').toString()) - parseInt(moment().startOf('month').format('DD').toString()),
                        chartXaxisEnd: (parseInt(moment().endOf('month').format('DD').toString()) - parseInt(moment().format('DD').toString())) + 2,
                      })
                      refetch()
                    }}
                  >
                    <b> {this.props.langData['Monthly']}</b>
                  </Button>
                </ButtonGroup>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </Card>
              <Table
                columns={this.columns}
                rowKey={record => record.transaction_id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={loadingQuery}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => {
                    return this.rowItemClick(record)
                  },
                })}
                rowClassName="white"
                scroll={{ x: 500, y: this.props.sizeWidth > 576 ? 500 : null }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

SafeReport.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(SafeReport)
