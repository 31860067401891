import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './RegisterStep.css'
import Step1 from './../Step1/Step1'
import Step2 from './../Step2/Step2'
import Step3 from './../Step3/Step3'
import { Route } from 'react-router-dom'
import { Button, Dropdown, Menu, Row, Col, Card, Divider, Steps, Spin } from 'antd'
import logo from './../../../logo.svg'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'

function RegisterStep(props) {

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)

  const loadPage = () => {

    setLoading(true)

    postPanelApi('/get-token-info').then(result => {

      if (result) {

        setStep(parseInt(result.company.step.replace('Step', '')) - 1)

      }

      setLoading(false)

    })

  }

  useEffect(() => {

    loadPage()

  }, [])

  useEffect(() => props.history.listen((location) => {

    if (typeof location.pathname === 'string') {

      const pathnameExploded = location.pathname.split('/')

      if (pathnameExploded.length > 0) {

        const step = parseInt(pathnameExploded[pathnameExploded.length - 1])

        if ([1, 2, 3].includes(step)) {

          loadPage()

        }

      }

    }

  }), [])

  const menu = <Menu onSelect={e => props.languageChange(e.key)} selectable={true}>
    {Object.keys(props.langData.languages).map(x => {
      return (
        <Menu.Item key={x}>{props.langData.languages[x]}</Menu.Item>
      )
    })}
  </Menu>

  if (loading) {
    return <Spin/>
  }

  return (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={1} sm={2} md={4} lg={6}/>
      <Col xs={22} sm={20} md={16} lg={12}>
        <Card style={{
          textAlign: 'center',
          boxShadow: '0 0 100px rgba(0,0,0,.08)',
          background: 'transparent',
          margin: '40px 0px',
        }} bordered={false} bodyStyle={{ padding: 0 }}>
          <img alt="Jetofis" src={logo} className="register-step-logo"/>
          <Divider>{props.langData['Register Steps']}</Divider>
          <div style={{ padding: '20px 30px' }}>
            <Steps
              current={step}
              style={{ textAlign: 'left' }}
            >
              <Steps.Step key="Step1" title={props.langData['Step'] + ' 1'}/>
              <Steps.Step key="Step2" title={props.langData['Step'] + ' 2'}/>
              <Steps.Step key="Step3" title={props.langData['Step'] + ' 3'}/>
            </Steps>
            <div className="steps-content">
              <Route exact path={routes.REGISTER_STEP.replace(':step', 1)} component={Step1}/>
              <Route exact path={routes.REGISTER_STEP.replace(':step', 2)} component={Step2}/>
              <Route exact path={routes.REGISTER_STEP.replace(':step', 3)} component={Step3}/>
            </div>
            <Dropdown trigger={['click']} overlay={menu}>
              <Button className="fl-r" style={{
                marginTop: 16,
                marginBottom: 20,
              }}>{props.langData.languages[props.lang]}</Button>
            </Dropdown>
          </div>
        </Card>
      </Col>
      <Col xs={1} sm={2} md={4} lg={6}/>
    </Row>
  )

}

RegisterStep.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  languageChange: PropTypes.func,
  lang: PropTypes.string,
  history: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
})(RegisterStep)
