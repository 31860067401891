import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  selectFilterLowerCase,
  deleteNull,
  updateEmptyAndUndefined,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize, successToast,
} from '../../../helpers/helper'
import { currencyData, getCurrencyWithId } from '../../../data/Currency'
import { debounce } from 'lodash'
import { Form, Icon, Input, Button, Col, Card, Divider, Select, DatePicker, InputNumber, Switch } from 'antd'
import moment from 'moment'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import AddUpSelect from './../../Utils/AddUpSelect'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'

const CREATE_QUERY = (
  gql`
      mutation ($customer: CustomerCreateInput!, $account_opening: AccountOpeningCustomerCreateInput){
          customer: customerCreate(customer: $customer, account_opening: $account_opening){
              customer: customer {
                  customer_id
              }
              informative {
                  messages
              }
          }
      }
  `
)

class NewCustomerForm extends Component {

  initialState = {
    categories: [],
    currencies: [],
    added_currencies: [],
    increment: 1,
    currency_rates: [],
  }

  state = this.initialState

  sequenceInterval = null

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: this.props.isModal ? 16 : 12 },
    },
  }

  formItemLayoutHalf = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: this.props.isModal ? 16 : 8 },
    },
  }

  formItemLayoutNoLabel = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 },
      lg: { span: 0 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: this.props.isModal ? 24 : 20 },
    },
  }

  mounted = false

  constructor(props) {
    super(props)
    this.onChangeDate = debounce(this.onChangeDate, 800)
  }

  componentDidMount() {

    this.mounted = true

    this.runWhenReady()

  }

  componentWillUnmount() {

    this.mounted = false

    clearInterval(this.sequenceInterval)

  }

  runWhenReady() {

    postPanelApi('/customer/create/open').then(result1 => {

      if ( ! this.mounted) {
        return
      }

      if (result1) {

        this.setState({
          increment: result1.increment,
          categories: result1.categories,
          currencies: result1.currencies,
          currency_rates: result1.currency_rates,
        })

        if (this.props.incomingEInvoiceData) {

          this.props.form.setFieldsValue(deleteNull({

            // customer/create/opne'dan gelen bilgiler
            'customer[category_id]': result1.form.customer.category_id,
            'customer[automatic_code]': result1.form.customer.automatic_code,
            'customer[code]': result1.form.customer.code,
            'customer[postal_code]': result1.form.customer.postal_code,

            // incomingEInvoiceData sayfasından gelen bilgiler
            'customer[name]': this.props.incomingEInvoiceData.name,
            'customer[is_individual]': this.props.incomingEInvoiceData.type === false,
            'customer[phone_1]': this.props.incomingEInvoiceData.phone_1,
            'customer[phone_2]': this.props.incomingEInvoiceData.phone_2,
            'customer[country]': this.props.incomingEInvoiceData.country,
            'customer[city]': this.props.incomingEInvoiceData.city,
            'customer[town]': this.props.incomingEInvoiceData.town,
            'customer[e_mail]': this.props.incomingEInvoiceData.e_mail,
            'customer[id_or_tax_identification_number]': this.props.incomingEInvoiceData.id_or_tax_identification_number,
            'customer[tax_administration]': this.props.incomingEInvoiceData.tax_administration,
            'customer[explanation]': this.props.incomingEInvoiceData.explanation,
            'customer[address]': this.props.incomingEInvoiceData.address,
          }))

        } else {

          const fieldsValue = {}

          for (const [key, value] of Object.entries(result1.form.customer)) {
            fieldsValue[`customer[${key}]`] = value
          }

          for (const [key, value] of Object.entries(result1.form.account_opening)) {
            fieldsValue[`account_opening[${key}]`] = value
          }

          this.props.form.setFieldsValue(fieldsValue)

        }

        if (result1.code_sequence_id) {

          this.sequenceInterval = setInterval(() => {

            postPanelApi('/get-next-sequence', { sequence_id: result1.code_sequence_id }).then(result2 => {

              if (result2) {

                if (this.props.form.getFieldValue('customer[automatic_code]')) {

                  this.props.form.setFieldsValue({
                    'customer[code]': result2.next_value,
                  })

                }

              }

            })

          }, 10000)

        }

        // this.props.form.setFieldsValue(deleteNull(result1.form))

      }

    })

  }

  onSubmit = (callback = undefined) => {

    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      // amount girilmişse
      if (values.account_opening.amount) {

        // hiç currency_rates seçilmemişse
        if ( ! values.account_opening.currency_rates) {
          values.account_opening.currency_rates = {}
        }

        // seçili kuru işle seçilmiş ise döviz kurlarını sıfırla
        if (this.props.form.getFieldValue('other[process_selected_currency]')) {
          values.account_opening.currency_rates = {}
        }

        currencyData.forEach(currency => {

          if ( ! values.account_opening.currency_rates[currency.code]) {
            values.account_opening.currency_rates[currency.code] = this.state.currency_rates[currency.currency_id]
          }

        })

      }


      // diğer ekranda yardımcı olması için kullanılan bilgileri silelim
      delete values.other

      postPanelApi('/customer/create/submit', values).then(result => {

        if (result) {

          successToast(result.message)

          if (callback) {
            return callback()
          }

          if (this.props.isModal) {
            return this.props.isModal(true)
          }

          this.props.history.push(generatePath(routes.CUSTOMER_DETAIL, {
            id: result.customer_id,
          }))

        }

      })

    })

  }

  onSubmitAndNew = () => {

    this.onSubmit(async () => {

      this.props.form.resetFields()

      if (this.mounted) {

        clearInterval(this.sequenceInterval)

        this.runWhenReady()
      }

      document.getElementById('main-content').scrollTop = 0

    })

  }

  currencyOnSelect = (currencyId) => {

    this.updateCurrencyRates(() => {

      this.setState({ added_currencies: [] }, () => {

        const selectableCurrencies = this.getSelectableCurrencies()

        this.props.form.setFieldsValue({
          'other[currency_id]': selectableCurrencies.length > 0 ?
            selectableCurrencies[0].currency_id :
            null,
        })

      })

    })

  }

  addCurrency = () => {

    const selectedCurrency = getCurrencyWithId(this.props.form.getFieldValue('other[currency_id]'))

    this.setState({ added_currencies: [...this.state.added_currencies, selectedCurrency] }, () => {

      const selectableCurrencies = this.getSelectableCurrencies()

      this.props.form.setFieldsValue({
        'other[currency_id]': selectableCurrencies.length > 0 ?
          selectableCurrencies[0].currency_id :
          null,
      })

    })

  }

  deleteCurrency = currencyId => {

    const currencies = []

    for (let currency of this.state.added_currencies) {
      if (currency.currency_id !== currencyId) {
        currencies.push(currency)
      }
    }

    this.setState({ added_currencies: currencies })

  }

  // seçilebilir kurları getirir
  getSelectableCurrencies = () => currencyData.filter(x => (x.currency_id !== this.getSelectedCurrency().currency_id) && ! this.state.added_currencies.find(y => y.currency_id === x.currency_id))

  onChangeDate = value => {
    if (value !== null) {
      this.updateCurrencyRates()
    }
  }

  createCustomerCategory = (name) => {

    postPanelApi('/customer/category/create', { name }).then(result => {

      if (result) {

        this.setState(state => {

          const categories = [...state.categories, {
            category_id: result.category_id,
            name: result.name,
          }]

          return {
            categories,
          }

        })

      }

    })

  }

  getSelectedCurrency() {
    return getCurrencyWithId(this.props.form.getFieldValue('account_opening[currency_id]'))
  }

  updateCurrencyRates(callback = undefined) {

    postPanelApi('/general/currency-rates', {
      date: this.props.form.getFieldValue('account_opening[date]'),
      currency_id: this.props.form.getFieldValue('account_opening[currency_id]'),
    }).then(result => {

      this.setState({
        currency_rates: result.currency_rates,
      }, () => {
        if (callback) {
          callback()
        }
      })

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Mutation
        mutation={CREATE_QUERY}
      >
        {(mutate, { loading: loadingMutation }) => (
          <Card
            bodyStyle={{ padding: '0px' }}
            title={
              <span><Icon type="file-add"/> {this.props.langData['New Customer']}</span>
            }
            bordered={false}
          >

            <Form
              hideRequiredMark={true}
              className="form-label-default"
            >

              <div className="form-block">

                <Form.Item hasFeedback label={(
                  <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[name]', {
                    rules: [{ max: 250 }, { required: true }],
                    validateTrigger: false,
                  })(
                    <Input size="large" maxLength={250}/>,
                  )}
                </Form.Item>

                <Form.Item hasFeedback
                           label={(<span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>)}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[category_id]', { validateTrigger: false })(
                    <AddUpSelect
                      data={this.state.categories.map(x => ({
                        key: x.category_id,
                        value: x.category_id,
                        content: x.name,
                      }))}
                      onAddUp={value => this.createCustomerCategory(value)}
                    />,
                  )}
                </Form.Item>

                <Form.Item
                  label={(<span><Icon type="bulb"/><p>{this.props.langData['Automatic Code']}</p></span>)}
                  {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[automatic_code]', {
                    validateTrigger: false,
                    valuePropName: 'checked',
                  })(
                    <Switch
                      checkedChildren={this.props.langData['Yes']}
                      unCheckedChildren={this.props.langData['No']}
                    />,
                  )}
                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[code]', { rules: [{ max: 20 }], validateTrigger: false })(
                    <Input
                      size="large"
                      maxLength={20}
                      disabled={this.props.form.getFieldValue('customer[automatic_code]')}
                    />,
                  )}
                </Form.Item>

              </div>

              <Divider/>

              <div className="form-block">

                <Form.Item hasFeedback label={(
                  <span><Icon type="phone"/><p>{this.props.langData['Phone'] + ' 1'}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[phone_1]', { rules: [{ max: 20 }], validateTrigger: false })(
                    <Input size="large" maxLength={20}/>,
                  )}
                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="phone"/><p>{this.props.langData['Phone'] + ' 2'}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[phone_2]', { rules: [{ max: 20 }], validateTrigger: false })(
                    <Input size="large" maxLength={20}/>,
                  )}
                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="environment-o"/><p>{this.props.langData['Country']}</p></span>)}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[country]', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      filterOption={selectFilterLowerCase}
                      suffixIcon={<Icon type="caret-down"/>}
                      maxLength={100}
                    >
                      {this.props.langData.countries.map((x, k) => {
                        return <Select.Option value={x} key={k}>{x}</Select.Option>
                      })}
                    </Select>,
                  )}
                </Form.Item>

                <Form.Item label={(
                  <span><Icon
                    type="environment-o"/><p>{this.props.langData['City'] + ' / ' + this.props.langData['Town'] + ' / ' + this.props.langData['Postal Code']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  <Input.Group size="large">
                    <Col span={8}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('customer[city]', { rules: [{ max: 100 }], validateTrigger: false })(
                          this.props.lang === 'tr' && this.props.form.getFieldValue('customer[country]') === 'Türkiye' ? (
                            <Select
                              size="large"
                              className="w-100"
                              showSearch
                              suffixIcon={<Icon type="caret-down"/>}
                              allowClear
                              filterOption={selectFilterLowerCase}
                              maxLength={100}
                            >
                              {this.props.langData.cityTown.map((x, k) => {
                                return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                              })}
                            </Select>
                          ) : (
                            <Input size="large" maxLength={100}/>
                          ),
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('customer[town]', { rules: [{ max: 100 }], validateTrigger: false })(
                          this.props.lang === 'tr' && this.props.form.getFieldValue('customer[country]') === 'Türkiye' ? (
                            <Select
                              size="large"
                              className="w-100"
                              showSearch
                              allowClear
                              suffixIcon={<Icon type="caret-down"/>}
                              filterOption={selectFilterLowerCase}
                              maxLength={100}
                            >
                              {this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('customer[city]')).length && (
                                this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('customer[city]'))[0].towns.map((x, k) => {
                                  return <Select.Option value={x} key={k}>{x}</Select.Option>
                                })
                              )}
                            </Select>
                          ) : (
                            <Input size="large" maxLength={100}/>
                          ),
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('customer[postal_code]')(
                          <Input
                            size="large"
                            style={{ width: '100%' }}
                            maxLength={10}
                          />,
                        )}
                      </Form.Item>
                    </Col>
                  </Input.Group>

                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="environment-o"/><p>{this.props.langData['Address']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[address]', { rules: [{ max: 500 }], validateTrigger: false })(
                    <Input.TextArea
                      size="large"
                      maxLength={500}
                      autoSize={{ minRows: 2, maxRows: 5 }}
                    />,
                  )}
                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="mail"/><p>{this.props.langData['E-Mail']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[e_mail]', {
                    rules: [{ type: 'email', max: 100 }],
                    validateTrigger: false,
                  })(
                    <Input size="large" maxLength={70}/>,
                  )}
                </Form.Item>

                <Form.Item label={(
                  <span><Icon
                    type="money-collect"/><p>{this.props.langData['Bank Name'] + ' / ' + this.props.langData['IBAN No']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  <Input.Group size="large">
                    <Col span={12}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('customer[bank_name]', { validateTrigger: false })(
                          <Input placeholder={this.props.langData['Bank Name']} maxLength={100} size="large"/>,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('customer[iban_no]', { validateTrigger: false })(
                          <Input placeholder={this.props.langData['IBAN No']} maxLength={100} size="large"/>,
                        )}
                      </Form.Item>
                    </Col>
                  </Input.Group>
                </Form.Item>
              </div>

              <Divider/>

              <div className="form-block">

                <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Type']}</p></span>)}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[is_individual]', {
                    validateTrigger: false,
                    valuePropName: 'checked',
                  })(
                    <Switch
                      checkedChildren={this.props.langData['Real Person']}
                      unCheckedChildren={this.props.langData['Legal Person']}
                    />,
                  )}
                </Form.Item>

                <Form.Item hasFeedback label={(
                  <span><Icon type="idcard"/><p>{
                    this.props.form.getFieldValue('customer[is_individual]') ?
                      this.props.langData['Identification Number'] :
                      this.props.langData['Id/Tax Identification Number']
                  }</p></span>
                )}
                           {...this.formItemLayout}
                >
                  {getFieldDecorator('customer[id_or_tax_identification_number]', {
                    rules: [{ max: 11, min: 10 }],
                    validateTrigger: false,
                  })(
                    <Input size="large" maxLength={11}/>,
                  )}
                </Form.Item>

                { ! this.props.form.getFieldValue('customer[is_individual]') && (
                  <Form.Item hasFeedback label={(
                    <span><Icon type="compass"/><p>{this.props.langData['Tax Administration']}</p></span>
                  )}
                             {...this.formItemLayout}
                  >
                    {getFieldDecorator('customer[tax_administration]', {
                      rules: [{ max: 250 }],
                      validateTrigger: false,
                    })(
                      <Input.TextArea size="large" maxLength={250} autoSize={{ minRows: 2, maxRows: 5 }}/>,
                    )}
                  </Form.Item>
                )}

              </div>

              <Divider/>

              <div className="form-block">

                <Form.Item label={(
                  <span><Icon type="pay-circle-o"/><p>{this.props.langData['Opening Balance']}</p></span>
                )}
                           {...this.formItemLayout}
                >
                  <Input.Group compact>
                    <Form.Item>
                      {getFieldDecorator('account_opening[amount]', { validateTrigger: false })(
                        <InputNumber
                          size="large"
                          min={0}
                          step={this.state.increment.toFixed(4)}
                          decimalSeparator=","
                          style={{ marginRight: '0px', width: '100%' }}
                        />,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('account_opening[currency_id]', {
                        validateTrigger: false,
                      })(
                        <Select
                          size="large"
                          onSelect={this.currencyOnSelect}
                          suffixIcon={<Icon type="caret-down"/>}
                        >
                          {this.state.currencies.map((currency) => (
                            <Select.Option value={currency.currency_id} key={currency.currency_id}>
                              {currency.symbol}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('account_opening[code]', {
                        validateTrigger: false,
                      })(
                        <Select size="large" suffixIcon={<Icon type="caret-down"/>}>
                          <Select.Option value="Debit">{this.props.langData['He has credit']}</Select.Option>
                          <Select.Option value="Credit">{this.props.langData['He has debit']}</Select.Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </Input.Group>

                </Form.Item>

                {this.props.form.getFieldValue('account_opening[amount]') > 0 && (
                  <div>

                    <Form.Item label={(
                      <span><Icon
                        type="pay-circle-o"/><p>{this.props.langData['Process Selected Currency']}</p></span>
                    )}
                               {...this.formItemLayoutHalf}
                    >
                      {getFieldDecorator('other[process_selected_currency]', {
                        initialValue: true,
                        valuePropName: 'checked',
                        validateTrigger: false,
                      })(
                        <Switch
                          checkedChildren={this.props.langData['Yes']}
                          unCheckedChildren={this.props.langData['No']}
                        />,
                      )}
                    </Form.Item>

                    { ! this.props.form.getFieldValue('other[process_selected_currency]') && this.getSelectableCurrencies().length > 0 && (
                      <Form.Item label={(
                        <span><Icon type="plus-circle-o"/><p>{this.props.langData['Add Currency']}</p></span>
                      )}
                                 {...this.formItemLayoutHalf}
                      >
                        <Input.Group compact style={{ display: 'flex' }}>
                          {getFieldDecorator('other[currency_id]', {
                            initialValue: this.getSelectableCurrencies()[0].currency_id,
                            validateTrigger: false,
                          })(
                            <Select size="large" className="w-100">
                              {this.getSelectableCurrencies().map((x, k) => (
                                <Select.Option value={x.currency_id} key={k}>{x.symbol}</Select.Option>
                              ))}
                            </Select>,
                          )}
                          <Button size="large" style={{ padding: '0px 40px' }} onClick={this.addCurrency}>
                            {this.props.langData['Add']}
                          </Button>
                        </Input.Group>
                      </Form.Item>
                    )}

                    { ! this.props.form.getFieldValue('other[process_selected_currency]') && this.state.added_currencies.map(addedCurrency => (
                      <Form.Item label={(
                        <span><Icon type="pay-circle-o"/><p>{addedCurrency.symbol}</p></span>
                      )} key={addedCurrency.currency_id}
                                 {...this.formItemLayoutHalf}
                      >
                        <Input.Group compact style={{ display: 'flex' }}>
                          {getFieldDecorator(`account_opening[currency_rates][${addedCurrency.code}]`, {
                            initialValue: this.state.currency_rates[addedCurrency.currency_id],
                            rules: [{ required: true }],
                            validateTrigger: false,
                          })(
                            <InputNumber
                              size="large"
                              step={this.state.increment.toFixed(4)}
                              min={0.0001}
                              decimalSeparator=","
                              style={{ width: '100%', marginRight: 0 }}
                              formatter={value => {
                                if (value.toString() === '') {
                                  return ''
                                }
                                return value.toString().replace(addedCurrency.symbol, '') + ` ${addedCurrency.symbol}`
                              }}
                              parser={value => value.replace(' ' + addedCurrency.symbol, '')}
                            />,
                          )}
                          <Input
                            size="large" disabled style={{ width: '50%', textAlign: 'center' }}
                            value={this.getSelectedCurrency().symbol + ' / ' + addedCurrency.symbol}
                          />
                          <Button
                            size="large"
                            icon="close"
                            onClick={() => this.deleteCurrency(addedCurrency.currency_id)}
                          />
                        </Input.Group>

                      </Form.Item>
                    ))}

                    <Form.Item label={(<span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>)}
                               {...this.formItemLayoutHalf}
                    >
                      {getFieldDecorator('account_opening[date]', {
                        initialValue: moment(),
                        rules: [{ required: true }],
                        validateTrigger: false,
                      })(
                        <DatePicker
                          format="DD.MM.YYYY HH:mm"
                          showTime={{ format: 'HH:mm' }}
                          size="large"
                          className="w-100"
                          onChange={this.onChangeDate}
                        />,
                      )}
                    </Form.Item>

                  </div>
                )}

              </div>

              <Divider/>

              <div className="form-block">
                <Form.Item hasFeedback {...this.formItemLayoutNoLabel} >
                  {getFieldDecorator('customer[explanation]', { rules: [{ max: 500 }], validateTrigger: false })(
                    <Input.TextArea
                      maxLength={500}
                      size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                      placeholder={this.props.langData['Explanation']}
                    />,
                  )}
                </Form.Item>
              </div>

              <Divider/>

              <div className="form-block">
                <Form.Item {...this.formItemLayoutNoLabel}>
                  { ! this.props.isModal && (
                    <Button
                      loading={loadingMutation}
                      size="large" className="default-button success"
                      onClick={() => {
                        this.onSubmitAndNew()
                      }}
                      style={{ float: 'right' }}
                    >
                      {this.props.langData['Save & New']}
                    </Button>
                  )}
                  <Button
                    loading={loadingMutation}
                    type="primary"
                    size="large"
                    className="default-button"
                    style={{ marginRight: 5 }}
                    onClick={() => this.onSubmit()}
                  >
                    {this.props.langData['Save']}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Card>
        )}
      </Mutation>
    )
  }

}

NewCustomerForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  lang: PropTypes.string,
  isModal: PropTypes.func,
  incomingEInvoiceData: PropTypes.object,
}

export default NewCustomerForm
