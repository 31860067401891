import axios from 'axios'

import { errorToast } from './helpers/helper'

export const postGuestApi = async (endpoint, data = {}) => {

  return postApi('/guest-actions' + endpoint, data)

}

export const postPanelApi = async (endpoint, data = {}) => {

  return postApi('/panel-actions' + endpoint, data)

}

export const postApi = async (endpoint, data = {}) => {

  const token = localStorage.getItem('token')
  const traceId = localStorage.getItem('trace_id')
  const lang = localStorage.getItem('lang')

  const config = {
    headers: {}
  }

  if (token) {
    config.headers['X-Token'] = token
  }

  if (traceId) {
    config.headers['X-Trace-Id'] = traceId
  }

  if (lang) {
    config.headers['Accept-Language'] = lang
  }

  return axios.post(process.env.REACT_APP_MAIN_API_URL + '/v2' + endpoint, data, config).then(response => response.data).then(result => {

    if (result.code === 'SUCCESS') {
      return result.data
    }

    errorToast(result.data.message)

  }).catch(error => {

    // @todo makge error handling

    errorToast('Servisden istenilen cevap elde edilemedi.')

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    console.log(error.config)

  })

}
