const initialState = {

  // test modunu aktif etmek için kullanılıyor, aktif olunca normalde görünmeyen menü vs. görünüyor
  test_mode: false,

  development_mode: false,

  // koyu tema aktif oluyor
  dark_theme: false,

}

export const GeneralReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'toggle_test_mode':

      return {
        ...state,
        test_mode: ! state.test_mode,
      }

    case 'toggle_development_mode':
      return {
        ...state,
        development_mode: ! state.development_mode,
      }

    case 'switch_theme':

      return {
        ...state,
        dark_theme: ! state.dark_theme,
      }

    default:

      return state

  }

}
