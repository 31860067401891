import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SequenceForm from './../SequenceForm/SequenceForm'
import { connect } from 'react-redux'
import { compile } from 'path-to-regexp'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class SequenceUpdate extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(
      '/' + compile(this.props.langData.route['sequence/update/:id'])({ id: this.props.sequence_id }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.sequence_id !== nextProps.sequence_id)
      return true
    return false
  }

  render() {
    const WrappedSequenceForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(SequenceForm)
    return <WrappedSequenceForm {...this.props} sequence_id={this.props.sequence_id}
                                refetch={this.props.refetch}></WrappedSequenceForm>
  }

}

SequenceUpdate.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  sequence_id: PropTypes.number,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(SequenceUpdate)
