import React, { Component } from 'react'
import { connect } from 'react-redux'
import TemplateForm from './../TemplateForm/TemplateForm'
import PropTypes from 'prop-types'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Template' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewTemplate extends Component {

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['template/new'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedTemplateForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(TemplateForm)
    return <WrappedTemplateForm {...this.props}></WrappedTemplateForm>
  }

}

NewTemplate.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplate)
