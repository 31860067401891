import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreditDebitForm from './../CreditDebitForm/CreditDebitForm'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete(name) {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewCreditDebit extends Component {

  componentDidMount() {

    if (this.props.safe) { // kasadan geliniyorsa
      this.props.breadcrumb_add(
        this.props.type === 'debit' ? 'Debit' : 'Credit',
        generatePath(routes.SAFE_DETAIL_BY_TYPE, {
          id: this.props.safe.safe_id,
          type: this.props.type,
        }),
      )
    } else if (this.props.customer) { // müşteriden geliniyorsa
      this.props.breadcrumb_add(
        this.props.type === 'debit' ? 'Debit' : 'Credit',
        generatePath(routes.ADD_DEBIT_CREDIT_FOR_CUSTOMER, {
          id: this.props.customer.customer_id,
          type: this.props.type,
        }),
      )
    }
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete(this.props.type === 'debit' ? 'Debit' : 'Credit')
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  render() {
    const WrappedCreditDebitForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(CreditDebitForm)
    return <WrappedCreditDebitForm {...this.props}></WrappedCreditDebitForm>
  }

}

NewCreditDebit.propTypes = {
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,

  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,

  type: PropTypes.string,
  customer: PropTypes.object,
  safe: PropTypes.object,
  amount: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditDebit)
