import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'

import routes from '../../../routes'

import PurchaseReturnInvoiceDetailForm from './PurchaseReturnInvoiceDetailForm'

function PurchaseReturnInvoiceDetail(props) {

  const transaction_id = Number(props.match.params.id)

  useEffect(() => {

    // @todo bir önceki alış faturası detay sayfasına ulaşmak için link verilecek
    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedPurchaseReturnInvoiceDetailForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(PurchaseReturnInvoiceDetailForm)

  return (
    <WrappedPurchaseReturnInvoiceDetailForm {...props} transaction_id={transaction_id}/>
  )

}

PurchaseReturnInvoiceDetail.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Return Invoice' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(PurchaseReturnInvoiceDetail)
