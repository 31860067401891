import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formErrorFieldsNameLocalize, successToast } from '../../helpers/helper'
import { connect } from 'react-redux'
import { currencyData, getCurrencyWithId } from '../../data/Currency'
import { Form, Icon, Input, Button, Card, Divider, Select, InputNumber, DatePicker, Switch } from 'antd'
import moment from 'moment'
import { generatePath } from 'react-router-dom'
import routes from '../../routes'
import { postPanelApi } from '../../api'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const { Option } = Select
const InputGroup = Input.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

class NewSafeForm extends Component {

  state = {
    loading: false,
    submitting: false,

    increment: 1,
    currency_id: currencyData[0].currency_id,
  }

  componentDidMount() {

    this.setState({
      loading: true,
    })

    postPanelApi('/safe/new-open').then(result => {

      if (result) {

        this.setState({
          loading: false,
          currency_id: result.currency_id,
          increment: result.increment,
        })

        this.props.form.setFieldsValue({
          'safe[currency_id]': result.currency_id,
        })

      }

    })

  }

  onSubmit = (callback) => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      if ( ! values.account_opening.amount) {
        delete values.account_opening
      } else {
        values.account_opening.date = moment(values.account_opening.date).toISOString(true)
      }

      postPanelApi('/safe/new-save', values).then(result => {

        if (result) {

          successToast(result.message)

          if (callback) {
            return callback()
          }

          this.props.history.push(generatePath(routes.SAFE_DETAIL_BY_ID, {
            id: result.safe_id,
          }))

        }

      })

    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Card
        loading={this.state.loading}
        bodyStyle={{ padding: '0px' }}
        title={
          <span><Icon type="file-add"/> &nbsp;{this.props.langData['New Safe']}</span>
        }
        bordered={false}
      >

        <Form
          hideRequiredMark={true}
          onSubmit={e => {
            e.preventDefault()
          }}
          className="form-label-default"
        >

          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('safe[name]', {
                rules: [{ max: 20 }, { required: true }],
                validateTrigger: false,
              })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('safe[code]', { rules: [{ max: 20 }], validateTrigger: false })(
                <Input size="large" maxLength={20}/>,
              )}
            </Form.Item>

            <Form.Item label={(<span><Icon type="bulb"/><p>{this.props.langData['Currency']}</p></span>)}
                       {...formItemLayout}
            >
              {getFieldDecorator('safe[currency_id]', {
                rules: [{ required: true }],
                validateTrigger: false,
              })(
                <Select
                  size="large"
                  suffixIcon={<Icon type="caret-down"/>}
                  onSelect={value => {
                    this.setState({
                      currency_id: value,
                    })
                  }}
                >
                  {currencyData.map((x, k) => {
                    return <Option value={x.currency_id} key={k}>{x.name + ' - ' + x.symbol}</Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Opening Balance']}</p></span>)}
                       {...formItemLayout}
            >
              <InputGroup compact style={{ display: 'flex' }}>
                {getFieldDecorator('account_opening[type]', {
                  initialValue: 'input',
                  validateTrigger: false,
                })(
                  <Select
                    style={{ width: '18%' }}
                    size="large"
                    suffixIcon={<Icon type="caret-down"/>}
                  >
                    <Option value="input">+</Option>
                    <Option value="output">-</Option>
                  </Select>,
                )}
                <InputGroup compact style={{ display: 'flex', marginTop: '1px' }}>
                  {getFieldDecorator('account_opening[amount]', {
                    initialValue: 0,
                    validateTrigger: false,
                  })(
                    <InputNumber
                      size="large"
                      step={this.state.increment.toFixed(4)}
                      min={0}
                      decimalSeparator=","
                      style={{ width: '80%' }}
                    />,
                  )}
                  <Button size="large" disabled style={{ width: '20%' }}>
                    {getCurrencyWithId(this.state.currency_id).symbol}
                  </Button>
                </InputGroup>
              </InputGroup>

            </Form.Item>


            { !! this.props.form.getFieldValue('account_opening[amount]') &&
              <Form.Item label={(<span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>)}
                         {...formItemLayout}
              >
                {getFieldDecorator('account_opening[date]', {
                  initialValue: moment(),
                  rules: [{ required: true }],
                  validateTrigger: false,
                })(
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    showTime={{ format: 'HH:mm' }}
                    size="large"
                    className="w-100"
                  />,
                )}
              </Form.Item>}

            <Form.Item label={(
              <span><Icon type="check-circle-o"/><p>{this.props.langData['Default']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('safe[default]', {
                valuePropName: 'checked',
                initialValue: false,
                validateTrigger: false,
              })(
                <Switch
                  checkedChildren={<CheckOutlined/>}
                  unCheckedChildren={<CloseOutlined/>}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item {...formItemLayoutNoLabel}>
              <Button
                loading={this.state.submitting}
                size="large"
                className="default-button success"
                onClick={() => this.onSubmit(() => {
                  this.props.form.resetFields()
                  this.props.form.setFieldsValue({
                    'safe[currency_id]': this.state.currency_id,
                  })
                  document.getElementById('main-content').scrollTop = 0
                })}
                style={{ float: 'right' }}
              >
                {this.props.langData['Save & New']}
              </Button>
              <Button
                loading={this.state.submitting}
                type="primary" size="large" className="default-button"
                style={{ marginRight: 5 }}
                onClick={() => this.onSubmit()}
              >
                {this.props.langData['Save']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Card>
    )
  }

}

NewSafeForm.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

export default connect(mapStateToProps)(NewSafeForm)
