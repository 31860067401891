import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'

import routes from '../../../../routes'

import InvoiceSettingsForm from './InvoiceSettingsForm/InvoiceSettingsForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice & E-Invoice & E-Archive Settings', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function InvoiceSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add(routes.INVOICE_SETTINGS)

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedInvoiceSettings = Form.create({
    validateMessages: langData.formValidationMessages,
  })(InvoiceSettingsForm)
  return <WrappedInvoiceSettings {...props} />
}

InvoiceSettings.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  form: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSettings)
