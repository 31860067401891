import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import UpdateCategoryForm from '../UpdateCategoryForm'

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class CategoryUpdate extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.CATEGORY_UPDATE_BY_TYPE, {
      id: this.props.category_id,
      type: this.props.type,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {

    const WrappedUpdateCategoryForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(UpdateCategoryForm)

    return (
      <WrappedUpdateCategoryForm
        langData={this.props.langData}
        category_id={this.props.category_id}
        type={this.props.type}
        onCategoryUpdated={this.props.onCategoryUpdated}
      />
    )
  }

}

CategoryUpdate.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  category_id: PropTypes.string,
  type: PropTypes.string,
  onCategoryUpdated: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(CategoryUpdate)
