import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import NewCategoryForm from '../NewCategoryForm'

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewCategory extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.CATEGORY_NEW, { type: this.props.type }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {

    const WrappedNewCategoryForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(NewCategoryForm)

    return <WrappedNewCategoryForm
      langData={this.props.langData}
      type={this.props.type}
      onCategoryAdded={this.props.onCategoryAdded}
    />

  }

}

NewCategory.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  type: PropTypes.string,
  onCategoryAdded: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(NewCategory)
