import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Modal, Table, Form, Select, Input, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, deleteEmpty, toLocaleString, confirm, toast } from '../../../helpers/helper'
import { Route, Link, Switch as RouterSwitch, generatePath } from 'react-router-dom'
import NewAdditionalCharge from './../NewAdditionalCharge/NewAdditionalCharge'
import AdditionalChargeUpdate from './../AdditionalChargeUpdate/AdditionalChargeUpdate'
import AdditionalChargeCopy from './../AdditionalChargeCopy/AdditionalChargeCopy'
import apolloClient from './../../../helpers/apolloClient'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { cloneDeep } from 'lodash'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'
import routes from '../../../routes'
import { getCurrencyWithId } from '../../../data/Currency'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
    filterData: state.TableFilterReducer.data.AdditionalCharge,
    filterFieldsData: state.TableFilterReducer.data.AdditionalChargeFieldsData,
    pageInfo: state.TableFilterReducer.data.AdditionalChargePage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Additional Charge', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'AdditionalCharge' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'AdditionalChargeFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'AdditionalChargePage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'AdditionalCharge' } })
      dispatch({ type: 'resetFilter', payload: { name: 'AdditionalChargeFieldsData' } })
    },
  }
}

const LIST_QUERY = gql`
  query additionalChargeList($filter: AdditionalChargeFilterBase, $sorting: AdditionalChargeSorting, $paging: Paging){
    additionalChargeList(filter: $filter, sorting: $sorting, paging: $paging) {
      additional_charges {
        additional_charge_id
        name
        code
        buying_price
        selling_price
        vat
        category {
          name
        }
      }
      page_info {
        total_count
      }
    }

    legacyData {
      currency_id
    }

  }
`

const MULTIPLE_DELETE_QUERY = (
  gql`
    mutation($additional_charge_ids: [AdditionalChargeDeleteInput]!){
      additionalChargeMultipleDelete(additional_charge_ids: $additional_charge_ids){
        informative{
          messages
        }
        additional_charges{
          additional_charge_id
          informative{
            messages
          }
        }
      }
    }`
)

class AdditionalCharge extends Component {

  currencyId = 1

  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    filter: null,
    sorting: null,
    paging: {
      offset: 0,
      limit: 50,
    },
    selectedRows: [],
    isDeleteEnable: false,
  }

  columns = [
    {
      title: this.props.langData['Name'],
      dataIndex: 'name',
      sorter: true,
      width: '25%',
    },
    {
      title: this.props.langData['Code'],
      dataIndex: 'code',
      sorter: true,
      width: '20%',
      align: 'center',
    },
    {
      title: this.props.langData['Category'],
      dataIndex: 'category.name',
      width: '15%',
      align: 'center',
    },
    {
      title: this.props.langData['Buying Price'],
      dataIndex: 'buying_price',
      width: '15%',
      align: 'right',
      render: buying => buying ? `${toLocaleString(buying)} ${getCurrencyWithId(this.currencyId).symbol}` : null,
    },
    {
      title: this.props.langData['Selling Price'],
      dataIndex: 'selling_price',
      width: '15%',
      align: 'right',
      render: selling => selling ? `${toLocaleString(selling)} ${getCurrencyWithId(this.currencyId).symbol}` : null,
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '10%',
      align: 'right',
      render: vat => vat ? `% ${vat}` : null,
    },
  ]

  componentDidMount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_add(routes.ADDITIONAL_CHARGE_LIST)
    this.setState({ filter: this.props.filterData })

    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }
  }

  componentWillUnmount() {
    ! this.props.noBreadCrumb && this.props.breadcrumb_delete()
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }
    if (sorting.field) {
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
    } else {
      this.setState({ sorting: null })
    }
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({ filter: null })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.ADDITIONAL_CHARGE_UPDATE, {
      id: record.additional_charge_id,
    }))
  }

  multipleDeleteQuery = async refetch => {
    try {
      const result = await apolloClient.mutate({
        mutation: MULTIPLE_DELETE_QUERY,
        variables: { additional_charge_ids: this.state.selectedRows },
      })
      refetch()
      toast(true, result.data.additionalChargeMultipleDelete.informative.messages[0])
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  multipleDelete = refetch => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.multipleDeleteQuery(refetch)
      this.setState({
        selectedRows: [],
        isDeleteEnable: false,
      })
    })
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ filter: this.state.filter, sorting: this.state.sorting, paging: this.state.paging }}
        onError={graphQLErrorsToToast}
      >
        {({ loading: loadingQuery, data, refetch }) => {

          if ( ! loadingQuery && ! this.state.data) {

            this.currencyId = data.legacyData.currency_id

            this.setState({
              data: cloneDeep(data.additionalChargeList.additional_charges),
              pagination: {
                ...this.state.pagination,
                total: data.additionalChargeList.page_info.total_count,
              },
            })
          } else if (this.state.data && data && JSON.stringify(data.additionalChargeList.additional_charges) !== JSON.stringify(this.state.data)) {
            this.setState({
              data: cloneDeep(data.additionalChargeList.additional_charges),
              pagination: {
                pageSize: this.state.pagination.pageSize,
                total: data.additionalChargeList.page_info.total_count,
              },
            })
          }

          this.props.refetch && refetch()

          return (
            <div>
              <FilterBox callback={this.filterCallback} isSubmit={loadingQuery}
                         isModal={this.props.modalClose} {...this.props} />
              <Row type="flex" justify="start" style={{ marginBottom: 10, borderRadius: 0 }}>
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  { ! this.props.modalClose &&
                    <Link to={routes.ADDITIONAL_CHARGE_NEW}>
                      <Button icon="file-add" block={true}>{this.props.langData['Add']}</Button>
                    </Link>}
                </Col>
                {this.state.isDeleteEnable > 0 && <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Button icon="delete" block={true}
                          onClick={() => this.multipleDelete(refetch)}> {this.props.langData['Delete']} </Button>
                </Col>}
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  { ! this.props.modalClose && this.state.selectedRows.length === 1 &&
                    <Link to={generatePath(routes.ADDITIONAL_CHARGE_COPY, {
                      id: this.state.selectedRows[0].additional_charge_id,
                    })}>
                      <Button icon="copy" block={true}> {this.props.langData['Copy']} </Button>
                    </Link>
                  }
                </Col>
              </Row>

              <Table
                columns={this.columns}
                rowKey={record => record.additional_charge_id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                rowClassName="white"
                onRow={record => ({
                  onClick: () => {
                    if ( ! this.props.rowItemClick)
                      return this.rowItemClick(record)
                    this.props.rowItemClick(record)
                  },
                })}
                rowSelection={! this.props.modalClose ? {
                  selectedRowKeys: this.state.selectedRows.length ? this.state.selectedRows.map(x => x.additional_charge_id) : [],
                  onChange: x => {
                    this.setState({
                      selectedRows: x.map(y => ({ additional_charge_id: y })),
                      isDeleteEnable: x.length ? true : false,
                    })
                  },
                } : ''}
                scroll={{
                  x: 500,
                  y: this.props.scrollHeight ? this.props.scrollHeight : (! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)'),
                }}
              />

              { ! this.props.modalClose && <RouterSwitch>
                <Route path={routes.ADDITIONAL_CHARGE_NEW} render={props => (
                  <Modal
                    title={<span><Icon type="file-add"/> &nbsp;{this.props.langData['New Additional Charge']}</span>}
                    bodyStyle={{ padding: 8 }}
                    visible={true}
                    footer={null}
                    onCancel={() => props.history.push(routes.ADDITIONAL_CHARGE_LIST)}
                  >
                    <NewAdditionalCharge {...props} addAdditionalCharge={this.addAdditionalCharge} refetch={refetch}/>
                  </Modal>
                )}/>

                <Route exact path={routes.ADDITIONAL_CHARGE_UPDATE} render={props => (
                  <Modal
                    title={<span><Icon type="edit"/> &nbsp;{this.props.langData['Update Additional Charge']}</span>}
                    bodyStyle={{ padding: 8 }}
                    visible={true}
                    footer={null}
                    onCancel={() => props.history.push(routes.ADDITIONAL_CHARGE_LIST)}
                  >
                    <AdditionalChargeUpdate
                      additional_charge_id={Number(props.match.params.id)}
                      editAdditionalCharge={this.editAdditionalCharge}
                      deleteAdditionalCharge={this.deleteAdditionalCharge}
                      {...props}
                      refetch={refetch}
                    />
                  </Modal>

                )}/>

                <Route path={routes.ADDITIONAL_CHARGE_COPY} render={props => (
                  <Modal
                    title={<span><Icon type="copy"/> &nbsp;{this.props.langData['Copy Additional Charge']}</span>}
                    bodyStyle={{ padding: 8 }}
                    visible={true}
                    footer={null}
                    onCancel={() => props.history.push(routes.ADDITIONAL_CHARGE_LIST)}
                  >
                    <AdditionalChargeCopy {...props} additional_charge_copy_id={props.match.params.id}
                                          refetch={refetch}/>
                  </Modal>
                )}/>
              </RouterSwitch>}

            </div>
          )
        }}
      </Query>
    )
  }

}

AdditionalCharge.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  noBreadCrumb: PropTypes.bool,
  rowItemClick: PropTypes.func,
  modalClose: PropTypes.func,
  scrollHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object,
  refetch: PropTypes.bool,
  email_verification: PropTypes.bool,
  filter: PropTypes.func,
  filterData: PropTypes.func,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.object,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.object,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(props) {
    if (this.props.isSubmit !== props.isSubmit)
      this.setState({ isSubmit: props.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

const CATEGORY_LIST_QUERY = (
  gql`
    {
      additionalChargeCategoryList {
        category_id
        name
      }
    }
  `
)

class FilterBoxForm extends Component {

  state = {
    selectedOptions: [],
    categoryList: null,
    isSubmit: false,
    items: [
      {
        value: 'name',
        required: true,
        operator: 'contains',
      },
      {
        value: 'code',
        label: this.props.langData['Code'],
        width: 400,
        operator: 'contains',
        added: false,
      },
      {
        value: 'category_id',
        label: this.props.langData['Category'],
        width: 400,
        operator: 'eq',
        added: false,
      },
    ],
  }

  componentDidMount() {
    this.props.filterFieldsData && Object.keys(this.props.filterFieldsData).forEach(x => {
      this.state.items.find(y => y.value === x).added = true
      this.props.form.getFieldDecorator(x, { initialValue: this.props.filterFieldsData[x] })
    })
  }

  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items: items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }

  onSubmit = form => {
    form.validateFields((err, values) => {
      if (err)
        return

      deleteEmpty(values)
      let filterData = []

      for (let value in values) {
        let item = this.state.items.filter(x => x.value === value)[0]
        filterData.push(
          { [value]: { [item.operator]: values[value] } },
        )
      }
      this.props.callback(filterData.length ? filterData : null, values)
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []
    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      ! this.props.isModal && <Button key="1"><Icon type="filter"/></Button>,
      <CheckboxedDropdown
        key="2"
        width={100}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedOptions}
        onChange={this.onChangeCheckbox}
      />,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let content = [...filterHeader]

    if (this.state.items.filter(x => x.value === 'category_id' && x.added).length)
      content.push(
        (
          getFieldDecorator('category_id')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'category_id')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              key="5"
              placeholder={this.props.langData['Category']}
            >
              {this.state.categoryList.map(x => <Select.Option value={x.category_id}
                                                               key={x.category_id}>{x.name}</Select.Option>)}
            </Select>,
          )
        ),
      )

    if (this.state.items.filter(x => x.value === 'code' && x.added).length)
      content.push(
        (
          getFieldDecorator('code')(
            <Input
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'code')[0].width : null }}
              allowClear
              key="6"
              placeholder={this.props.langData['Code']}
            />,
          )
        ),
      )

    content.push((
      getFieldDecorator('name')(
        <Input key="7" allowClear placeholder={this.props.langData['Name']}/>,
      )
    ))

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={CATEGORY_LIST_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading: loadingQuery, data, error }) => {
            if (error) {
              graphQLErrorsToToast(error)
              return null
            }

            if ( ! loadingQuery && ! this.state.categoryList)
              this.setState({ categoryList: cloneDeep(data.additionalChargeCategoryList) })
            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {content.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )

    return (
      <Query
        query={CATEGORY_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.categoryList)
            this.setState({ categoryList: cloneDeep(data.additionalChargeCategoryList) })

          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {content.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )

  }

}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  isSubmit: PropTypes.bool,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isModal: PropTypes.func,
  filterFieldsData: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCharge)

