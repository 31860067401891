const initialState = {
	width: 0,
	height: 0,
	size: ''
}

let sizes = [
	{width: 480, name: 'xs'},  // 0 - 480
	{width: 576, name: 'sm'},  // 480 - 576
	{width: 768, name: 'md'},  // 576 - 768
	{width: 992, name: 'lg'},  // 768 - 992
	{width: 1200, name: 'xl'}, // 992 - 1200
	{width: 1600, name: 'xxl'} // 1200 ve üzeri
]

export const SizeDetectorReducer = (state = initialState, action) => {
	if(action.type === 'size_set'){
		let size = 'xs'

		for(let x of sizes){
			if(action.payload.width <= x.width){
				size = x.name
				break
			}
		}
		
		state = {
			width: action.payload.width,
			height: action.payload.height,
			size: size
		}
	}
	return state
}
