import Store from './../reducers/Store'

function sizeDetector(){
	Store.dispatch({ type: 'size_set', payload: { width: window.outerWidth, height: window.outerHeight } })
}

window.onresize = () => {
	sizeDetector()
}

export default sizeDetector
