import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Form, Select, Input, Icon, DatePicker, Divider, Switch } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { formErrorFieldsNameLocalize } from '../../../helpers/helper'
import { postPanelApi } from '../../../api'

const switchLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 13 },
  },
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 17 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

function InvoiceEArchiveModalForm(props) {

  const [submitting, setSubmitting] = useState(false)
  const [transporter, setTransporter] = useState({
    name: null,
    tin: null,
  })
  const [paymentMeansCodes, setPaymentMeansCodes] = useState([])

  useEffect(() => {

    postPanelApi('/invoice/create-earchive-open').then(result => {

      if (result) {

        setTransporter({
          name: result.form.transporter_name,
          tin: result.form.transporter_tin,
        })

        setPaymentMeansCodes(result.payment_means_codes)

        setTimeout(() => {

          props.form.setFieldsValue({
            payment_means_code: result.form.payment_means_code,
            instruction_note: result.form.instruction_note,
          })

        }, 100)

      }

    })

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      setSubmitting(true)

      const result = await props.callback(values)

      if (result) {
        props.onCancel()
      } else {
        setSubmitting(false)
      }

    })

  }

  const changeShipmentStatus = status => {

    // render tamamlandıktan sonra çalışacak, ek bilgilerin görünmesi, seçilen checkbox'un true olmasına bağlı
    // ilk seferde set ediyor, sonraki kapatıp açmalarda yine set ediyor, bu bir sorun mudur?
    // yani kullanıcı işaretleyip değişiklik yaparsa ve kapatıp tekrar açarsa yaptığı değişiklik sıfırlanmış olacak

    if (status) {

      setTimeout(() => {

        props.form.setFieldsValue({
          'transporter_name': transporter.name,
          'transporter_tin': transporter.tin,
        })

      }, 100)

    }


  }

  const { getFieldDecorator } = props.form

  return (

    <Modal
      title={<span><Icon type="file-text"/> &nbsp;{props.langData['E-Archive']}</span>}
      bodyStyle={{ padding: 8 }}
      visible={true}
      footer={null}
      onCancel={props.onCancel}
    >
      <Form

        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default no-indent"
        hideRequiredMark={true}
      >
        <div className="form-block">
          <Form.Item hasFeedback label={<p>{props.langData['Payment Means']}</p>} {...formItemLayout}>
            {getFieldDecorator('payment_means_code', {
              rules: [{ required: true }],
            })(
              <Select
                style={{ width: '100%' }}
                suffixIcon={<Icon type="caret-down"/>}
              >
                {paymentMeansCodes.map(item => (
                    <Select.Option key={item.code} value={item.code}>{item.title}</Select.Option>
                  ),
                )}
              </Select>,
            )}
          </Form.Item>
        </div>

        {props.form.getFieldValue('payment_means_code') === 1 && (
          <div className="form-block">
            <Form.Item
              hasFeedback
              help={props.isMultiple ? (
                <div style={{ marginTop: '10px' }}>
                  <Icon type="info-circle"/>&nbsp; {props.langData['Payment Agent Name Speacil Status']}
                </div>
              ) : ''}
              label={<p>{props.langData['Payment Agent Name']}</p>}
              {...formItemLayout}
            >
              {getFieldDecorator('payment_agent_means', { rules: [{ max: 250, required: true }] })(
                <Input
                  style={{ width: '100%' }}
                  allowClear
                  key="6"
                />,
              )}
            </Form.Item>
          </div>
        )}

        <div className="form-block">
          <Form.Item
            help={<div style={{ marginTop: '10px' }}>
              <Icon type="info-circle"/>&nbsp; {props.langData['Optional Payment Due Date']}
            </div>}
            hasFeedback
            label={<p>{props.langData['Payment Due Date']}</p>}
            {...formItemLayout}
          >
            {getFieldDecorator('payment_due_date')(
              <DatePicker format="YYYY-MM-DD"/>,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item
            help={<div style={{ marginTop: '10px' }}>
              <InfoCircleOutlined/>&nbsp; {props.langData['EArhhive empty Customer ID']} </div>}
            hasFeedback
            label={<p>{props.langData['Instruction Note']}</p>} {...formItemLayout}>
            {getFieldDecorator('instruction_note', {
              rules: [{
                max: 100,
                required: [1, 97].includes(props.form.getFieldValue('payment_means_code')),
              }],
            })(
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 3, maxRows: 5 }}
                style={{ width: '100%' }}
                maxLength={100}
              />,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item
            label={<p>{props.langData['Send Shipment Carrier Information']}</p>} {...switchLayout}>
            {
              getFieldDecorator('shipment_status', {
                valuePropName: 'checked',
                initialValue: false,
              })(
                <Switch
                  checkedChildren={<Icon type="check"/>}
                  unCheckedChildren={<Icon type="close"/>}
                  onChange={changeShipmentStatus}
                />,
              )
            }
          </Form.Item>
        </div>
        {props.form.getFieldValue('shipment_status') &&
          <React.Fragment>
            <Divider>{props.langData['Optional']}</Divider>

            <div className="form-block">
              <Form.Item hasFeedback label={<p>{props.langData['Party Name']}</p>} {...formItemLayout}>
                {getFieldDecorator('transporter_name', { rules: [{ max: 250 }] })(
                  <Input
                    style={{ width: '100%' }}
                    allowClear
                    key="6"
                  />,
                )}
              </Form.Item>
            </div>

            <div className="form-block">
              <Form.Item hasFeedback
                         label={<p>{props.langData['Transporter VKN No']}</p>} {...formItemLayout}>
                {getFieldDecorator('transporter_tin', { rules: [{ max: 10 }] })(
                  <Input
                    style={{ width: '100%' }}
                    allowClear
                    key="6"
                  />,
                )}
              </Form.Item>
            </div>

            <div className="form-block">
              <Form.Item hasFeedback label={<p>{props.langData['Sending Date']}</p>} {...formItemLayout}>
                {getFieldDecorator('sending_date', { rules: [{ required: true }] })(
                  <DatePicker format="YYYY-MM-DD"/>,
                )}
              </Form.Item>
            </div>
          </React.Fragment>
        }
        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="default-button"
              loading={submitting}
            >
              {props.langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )

}

InvoiceEArchiveModalForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  isMultiple: PropTypes.bool,
}

function InvoiceEArchiveModal(props) {

  const WrappedInvoiceEArchiveModalForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(InvoiceEArchiveModalForm)

  return <WrappedInvoiceEArchiveModalForm {...props} />

}

InvoiceEArchiveModal.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  onCancel: PropTypes.func,
  isMultiple: PropTypes.bool,
}

export default InvoiceEArchiveModal
