let lang = undefined

const langInLocalStorage = localStorage.getItem('lang')

if (typeof lang === 'undefined' && typeof langInLocalStorage !== 'undefined') {
  lang = langInLocalStorage
}

if (typeof lang === 'undefined' || lang === null) {

  // varsayılan olarak dil Türkçe olsun
  lang = 'tr'

  localStorage.setItem('lang', lang)

}

const initialState = {
  lang: lang,
  langData: require(`./../localize/${lang}`).default,
}

export const LocalizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'language_change':
      localStorage.setItem('lang', action.payload.lang)
      if (action.payload.lang !== 'en')
        require(`moment/locale/${action.payload.lang}`)
      return {
        ...state,
        lang: action.payload.lang,
        langData: require(`./../localize/${action.payload.lang}`).default,
      }
    default:
      return state
  }
}
