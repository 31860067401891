import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdditionalChargeForm from './../AdditionalChargeForm/AdditionalChargeForm'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewAdditionalChargeUpdate extends Component {

  componentDidMount() {
    this.props.breadcrumb_add(routes.ADDITIONAL_CHARGE_NEW)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const WrappedAdditionalChargeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(AdditionalChargeForm)
    return <WrappedAdditionalChargeForm {...this.props}></WrappedAdditionalChargeForm>
  }

}

NewAdditionalChargeUpdate.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  isModal: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAdditionalChargeUpdate)
