import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, InputNumber, Button, Divider, Modal, Select, Spin } from 'antd'
import { graphQLErrorsToToast } from './../../../helpers/helper'
import { gql } from '@apollo/client'

import { debounce } from 'lodash'
import apolloClient from './../../../helpers/apolloClient'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

class ProductAdditionalCharge extends Component {

  render() {
    const WrappedProductAdditionalChargeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ProductAdditionalChargeForm)
    return <WrappedProductAdditionalChargeForm {...this.props} />
  }

}

ProductAdditionalCharge.propTypes = {
  langData: PropTypes.object,
}

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          additional_charge_id
          name
        }
      }
    }
  `
)

class ProductAdditionalChargeForm extends Component {

  constructor(props) {
    super(props)
    let additionalChargeList = []
    if (props.additional_charge.additional_charge_id)
      additionalChargeList.push({
        name: props.additional_charge.additional_charges.filter(x => x.additional_charge_id === props.additional_charge.additional_charge_id)[0].name,
        additional_charge_id: props.additional_charge.additional_charge_id,
      })
    this.state = {
      additionalChargeList: additionalChargeList,
      fetchingAdditionalCharge: false,
    }
    this.searchAdditionalCharge = debounce(this.searchAdditionalCharge, 800)
  }

  onSubmit = event => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (err)
        return
      this.props.editAdditionalCharge({
        ...this.props.additional_charge,
        ...values,
      }, values.additional_charge_id ? this.state.additionalChargeList.find(x => x.additional_charge_id === values.additional_charge_id).name : null)
    })
  }

  searchValue = null

  searchAdditionalCharge = async value => {
    if (this.searchValue === value)
      return

    this.searchValue = value

    if (value.length < 2)
      return

    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      if ( ! result.data.additionalChargeList.additional_charges.length)
        this.props.form.setFieldsValue({ additional_charge_id: null })
      this.setState({
        fetchingAdditionalCharge: false,
        additionalChargeList: result.data.additionalChargeList.additional_charges,
      })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  render() {

    const { getFieldDecorator } = this.props.form
    return (
      <Modal
        title={
          <span>
						<Icon type="star-o"/>
            &nbsp; {this.props.langData[this.props.additional_charge.additional_charge_id ? 'Edit Additional Charge' : 'Add Additional Charge']}
					</span>
        }
        bodyStyle={{ padding: 8 }}
        visible={true}
        footer={null}
        onCancel={this.props.modalClose}
      >
        <Form onSubmit={this.onSubmit} className="form-label-default">


          <div className="form-block">

            <Form.Item hasFeedback label={(
              <span><Icon type="star-o"/><p>{this.props.langData['Additional Charge']}</p></span>
            )}
            >
              {getFieldDecorator('additional_charge_id', {
                rules: [{ required: true }],
                initialValue: this.props.additional_charge.additional_charge_id,
              })(
                <Select
                  size="large"
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  showSearch
                  notFoundContent={this.state.fetchingAdditionalCharge ? <Spin size="large"/> : null}
                  onSearch={this.searchAdditionalCharge}
                >
                  {this.state.additionalChargeList.map((x, k) => {
                    return <Option value={x.additional_charge_id} key={k}>{x.name}</Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Quantity']}</p></span>
            )}>
              {getFieldDecorator('quantity', {
                initialValue: this.props.additional_charge.quantity,
              })(
                <InputNumber
                  size="large"
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Buying Price']}</p></span>
            )}>
              {getFieldDecorator('buying_price', {
                initialValue: this.props.additional_charge.buying_price,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedBuyingCurrency, '') + ` ${this.props.selectedBuyingCurrency}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedBuyingCurrency, '')}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Selling Price']}</p></span>
            )}>
              {getFieldDecorator('selling_price', {
                initialValue: this.props.additional_charge.selling_price,
              })(
                <InputNumber
                  className="w-100"
                  min={0.0001}
                  step={0.0001}
                  formatter={value => {
                    if (value.toString() === '')
                      return ''
                    return value.toString().replace(this.props.selectedSellingCurrency, '') + ` ${this.props.selectedSellingCurrency}`
                  }}
                  parser={value => value.replace(' ' + this.props.selectedSellingCurrency, '')}
                />,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Vat']}</p></span>
            )}>
              {getFieldDecorator('vat', {
                initialValue: this.props.additional_charge.vat,
              })(
                this.props.lang === 'tr' ? (
                  <Select
                    className="w-100"
                  >
                    {this.props.langData.vats.map((x, k) => {
                      return <Option value={x} key={k}>{`${x}%`}</Option>
                    })}
                  </Select>
                ) : (
                  <InputNumber
                    className="w-100"
                    min={0}
                    step={1}
                    formatter={value => value.toString().replace('%', '') + '%'}
                    parser={value => value.replace('%', '')}
                  />
                ),
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit" className="default-button">
                {this.props.langData[this.props.additional_charge.additional_charge_id ? 'Edit' : 'Add']}
              </Button>
              <Button type="primary" size="large" onClick={this.props.modalClose} style={{ marginRight: 8 }}
                      className="default-button">
                {this.props.langData['Close']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Modal>
    )
  }
}

ProductAdditionalChargeForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  additional_charge: PropTypes.object,
  form: PropTypes.object,
  editAdditionalCharge: PropTypes.func,
  selectedBuyingCurrency: PropTypes.string,
  selectedSellingCurrency: PropTypes.string,
  modalClose: PropTypes.func,
}

export default connect(mapStateToProps)(ProductAdditionalCharge)
