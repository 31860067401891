import { Button, Col, DatePicker, Form, Icon, Modal, Radio, Row } from 'antd'
import React, { useState } from 'react'
import moment from 'moment'
import { toast } from '../../helpers/helper'
import PropTypes from 'prop-types'

function CustomerTransactionsExport({ customerId, langData, lang, onCancel }) {

  const [start, setStart] = useState(moment().subtract(1, 'M'))
  const [end, setEnd] = useState(moment())

  const [exportType, setExportType] = useState('pdf')

  const customerTransactionExtract = () => {
    if (exportType === 'pdf') {
      window.open(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/customer/transaction/${exportType}/${localStorage.token}/${customerId}/${start.clone().format('YYYY-MM-DDT00:00:00.000Z')}/${end.clone().format('YYYY-MM-DDT23:59:00.000Z')}/1x/v/${lang}`, '_blank')
    } else {
      window.open(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/customer/transaction/${exportType}/${localStorage.token}/${customerId}/${start.clone().format('YYYY-MM-DDT00:00:00.000Z')}/${end.clone().format('YYYY-MM-DDT23:59:00.000Z')}`, '_blank')
    }
  }

  return (
    <Modal
      title={<span><Icon type="export"/> &nbsp; {langData['Customer Transaction Extract']}</span>}
      bodyStyle={{ padding: 20 }}
      visible={true}
      footer={null}
      width="550px"
      onCancel={onCancel}
    >

      <Row gutter={[24, 24]}>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ marginTop: 7 }}>
          <b>{langData['Select Date']}:</b>
        </Col>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <DatePicker
            style={{ marginRight: 1 }}
            defaultValue={start}
            disabledDate={(current) => current && current < start}
            format="DD-MMMM-YYYY"
            placeholder={langData['Start Date']}
            onChange={setStart}/>
          <DatePicker
            format="DD-MMMM-YYYY"
            defaultValue={end}
            placeholder={langData['End Date']}
            disabledDate={current => {
              if (current) {
                return current < start || current > start.clone().add(1, 'M')
              }
            }}
            onChange={setEnd}/>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ marginTop: 7 }}>
          <b>{langData['Type']}:</b>
        </Col>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <Radio.Group defaultValue={exportType} buttonStyle="solid" onChange={(e) => setExportType(e.target.value)}>
            <Radio.Button value="pdf">PDF</Radio.Button>
            <Radio.Button value="excel">Excel</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <div className="form-block">
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" className="default-button" onClick={() => {
              if (start && end) {
                return customerTransactionExtract()
              }
              return toast(false, langData['Please choose date range'])
            }}>
              {langData['Send']}
            </Button>
          </Form.Item>
        </div>
      </Row>

    </Modal>
  )

}

CustomerTransactionsExport.propTypes = {
  show: PropTypes.bool,
  customerId: PropTypes.number,
  langData: PropTypes.object,
  lang: PropTypes.string,
  onCancel: PropTypes.func,
}

CustomerTransactionsExport.defaultProps = {
  show: false,
}

export default CustomerTransactionsExport
