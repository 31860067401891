import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Dropdown, Menu, Row, Col, Card, Divider } from 'antd'

import './Register.css'

import logo from './../../logo.svg'

import { formErrorFieldsNameLocalize, successToast } from '../../helpers/helper'

import { postGuestApi } from '../../api'
import routes from '../../routes'

function RegisterForm(props) {

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {

    if (props.token.length > 0) {
      props.clear_token_info()
    }

    if (props.match.params.commissioner_e_mail) {
      props.form.setFieldsValue({
        commissioner_e_mail: props.match.params.commissioner_e_mail,
      })
    }

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      setSubmitting(true)

      postGuestApi('/auth/register', values).then(result => {

        if (result) {

          successToast(result.message)

          setTimeout(() => {
            props.history.push(routes.LOGIN)
          }, 3000)

        } else {

          setSubmitting(false)

        }

      })

    })
  }

  const menu = (
    <Menu onSelect={e => props.languageChange(e.key)} selectable={true}>
      {Object.keys(props.langData.languages).map(x => {
        return (
          <Menu.Item key={x}>{props.langData.languages[x]}</Menu.Item>
        )
      })}
    </Menu>
  )

  const { getFieldDecorator } = props.form

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Form.Item hasFeedback>
        {getFieldDecorator('company_name', {
          rules: [{ max: 255 }, { required: true }],
          validateTrigger: false,
        })(
          <Input prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                 placeholder={props.langData['Company Name']}/>,
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator('commissioner_name_surname', {
          rules: [{ max: 250 }, { required: true }],
          validateTrigger: false,
        })(
          <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                 placeholder={props.langData['Commissioner Name Surname']}/>,
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator('commissioner_phone', {
          rules: [{ max: 20 }, { required: true }],
          validateTrigger: false,
        })(
          <Input type="number" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                 placeholder={props.langData['Commissioner Phone']}/>,
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator('commissioner_e_mail', {
          rules: [{
            type: 'email',
            transform: value => value.trim(),
          }, { max: 100 }, { required: true }], validateTrigger: false,
        })(
          <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                 placeholder={props.langData['Commissioner E-Mail']}/>,
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {getFieldDecorator('password', {
          rules: [{ max: 32 }, { min: 6 }, { required: true }],
          validateTrigger: false,
        })(
          <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password"
                 placeholder={props.langData['Password']}/>,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" loading={submitting} htmlType="submit" className="register-form-button">
          {props.langData['Register']}
        </Button>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="fl-r">{props.langData.languages[props.lang]}</Button>
        </Dropdown>
      </Form.Item>

    </Form>
  )

}

RegisterForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
  clear_token_info: PropTypes.func,
  lang: PropTypes.string,
  languageChange: PropTypes.func,
  match: PropTypes.object,
  token: PropTypes.string,
}

function Register(props) {

  const WrappedRegisterForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(RegisterForm)

  return (
    <div className="h-100">
      <Row type="flex" justify="space-around" align="middle" className="h-100">
        <Col xs={1} sm={6} md={7} lg={8.5}/>
        <Col xs={22} sm={12} md={10} lg={7}>
          <Card style={{
            textAlign: 'center',
            boxShadow: '0 0 100px rgba(0,0,0,.08)',
            background: 'transparent',
            padding: '0px 20px 20px 20px',
          }} bordered={false}>
            <img src={logo} alt="Jetofis" className="register-logo"/>
            <WrappedRegisterForm {...props} />
            <Divider>JETOFİS</Divider>
            <Card.Meta
              title="Bulut Tabanlı Ticari Yazılım"
              description="www.jetofis.com"
            />
          </Card>
        </Col>
        <Col xs={1} sm={6} md={7} lg={8.5}/>
      </Row>
    </div>
  )

}

Register.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    token: state.AuthReducer.token,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
    clear_token_info() {
      dispatch({ type: 'clear_token_info' })
    },
  }
})(Register)
