import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Col, Divider, Spin } from 'antd'

import { toast } from '../helpers/helper'

function LoginAsManager(props) {

  useEffect(() => {

    localStorage.setItem('token', props.match.params.token)

    toast(true, props.langData['Welcome Jetofis'] + '...')

    props.history.push('/')

  }, [])

  return (
    <div className="h-100">
      <Row type="flex" align="middle" className="h-100">
        <Col xs={1} sm={6} md={7} lg={9} xl={6}/>
        <Col xs={22} sm={12} md={10} lg={6} xl={12}>
          <Card
            style={{
              textAlign: 'center',
              boxShadow: '0 0 100px rgba(0,0,0,.08)',
              background: 'transparent',
              padding: 20,
            }}
            bordered={false}
          >
            <img
              src="https://app.jetofis.com/logo.svg"
              alt="Jetofis"
              style={{ width: '100%', maxWidth: 400 }}
            />

            <Spin/>

            <Divider>JETOFİS</Divider>
            <Card.Meta
              title="Bulut Tabanlı Ticari Yazılım"
              description="www.jetofis.com"
            />
          </Card>
        </Col>
        <Col xs={1} sm={6} md={7} lg={9} xl={6}/>
      </Row>
    </div>
  )

}

LoginAsManager.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(LoginAsManager)
