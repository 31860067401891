import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  confirm,
  toast,
  graphQLErrorsToToast,
  updateEmptyAndUndefined,
  selectFilterLowerCase,
  formErrorFieldsNameLocalize,
  deleteNull,
} from '../../../helpers/helper'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Card, Divider, Select, InputNumber } from 'antd'
import { Query, Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { createCategory, deleteData } from '../../../helpers/trait'
import { cloneDeep } from 'lodash'
import AddUpSelect from './../../Utils/AddUpSelect'
import routes from '../../../routes'
import { getCurrencyWithId } from '../../../data/Currency'

const mapStateToProps = state => {
  return {
    lang: state.LocalizeReducer.lang,
    langData: state.LocalizeReducer.langData,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 },
    lg: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 13 },
    lg: { span: 13 },
  },
}

const READ_QUERY = (
  gql`
    query ($additional_charge_id: Int!){
      additionalCharge(additional_charge_id: $additional_charge_id) {
        additional_charge_id
        name
        code
        unit_id
        unit {
          name
        }
        category_id
        category {
          name
        }
        buying_price
        selling_price
        vat
      }
      additionalChargeCategoryList {
        category_id
        name
      }
      unitList {
        unit_id
        name
      }
      userInformation {
        settings {
          increment
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
    {
      additionalChargeCategoryList {
        category_id
        name
      }
      unitList {
        unit_id
        name
      }

      userInformation {
        settings {
          increment
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation additionalCharge($additional_charge: AdditionalChargeCreateInput!){
      additional_charge: additionalChargeCreate(additional_charge: $additional_charge){
        additional_charge {
          additional_charge_id
          name
          code
          buying_price
          selling_price
          vat
          category {
            name
          }
        }
        informative {
          messages
        }
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation additionalCharge($additional_charge_id: Int!, $additional_charge: AdditionalChargeUpdateInput!){
      additional_charge: additionalChargeUpdate(additional_charge_id: $additional_charge_id, additional_charge: $additional_charge){
        additional_charge {
          name
          code
          buying_price
          selling_price
          vat
          category {
            name
          }
        }
        informative {
          messages
        }
      }
    }
  `
)

class AdditionalChargeForm extends Component {

  currencyId = 1

  state = {
    categories: null,
    units: null,
    data: null,
    initialize: false,
    increment: null,
  }

  constructor(props) {
    super(props)
    this.createCategory = createCategory('additionalCharge')
    this.deleteData = deleteData('additionalCharge', 'additional_charge_id').bind(this)
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      const step1 = async () => {
        if (values.additional_charge.category_id) {
          let category = this.state.categories.filter(x => x.name === values.additional_charge.category_id)
          if (category.length) {
            values.additional_charge.category_id = category[0].category_id
            step2()
          } else {
            try {
              values.additional_charge.category_id = await this.createCategory(values.additional_charge.category_id)
              step2()
            } catch (err) {
              graphQLErrorsToToast(err)
            }
          }
        } else {
          step2()
        }
      }

      const step2 = () => {

        if (this.props.additional_charge_id) {
          updateEmptyAndUndefined(values)
          values.additional_charge_id = this.props.additional_charge_id
        }

        mutate({ variables: values })
      }

      step1()

    })
  }

  delete = () => {
    confirm(undefined, async result => {
      if ( ! result)
        return
      this.deleteData(this.props.additional_charge_id)
      this.props.refetch()
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Query
        query={(this.props.additional_charge_id || this.props.additional_charge_copy_id) ? READ_QUERY : READ_FOR_CREATE_QUERY}
        variables={this.props.additional_charge_id ? { additional_charge_id: this.props.additional_charge_id } :
          this.props.additional_charge_copy_id ? { additional_charge_id: this.props.additional_charge_copy_id } : null}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            // bulamazsan hata ver ve yönlendir
            if ((this.props.additional_charge_id || this.props.additional_charge_copy_id) && ! data.additionalCharge) {
              toast(false, this.props.langData['x not found']('Additional charge'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data) {

              this.currencyId = data.legacyData.currency_id

              this.setState({
                categories: cloneDeep(data.additionalChargeCategoryList),
                units: data.unitList,
                initialize: true,
                increment: data.userInformation.settings.increment,
              })

              if (this.props.additional_charge_id || this.props.additional_charge_copy_id) {
                this.setState({ data: data.additionalCharge })
                let additional_charge = data.additionalCharge
                setTimeout(() => {
                  this.props.form.setFieldsValue(deleteNull({
                    'additional_charge[name]': this.props.additional_charge_id ? additional_charge.name : additional_charge.name + ' ' + this.props.langData['Duplicate'],
                    'additional_charge[code]': this.props.additional_charge_id ? additional_charge.code : '',
                    'additional_charge[buying_price]': additional_charge.buying_price,
                    'additional_charge[selling_price]': additional_charge.selling_price,
                    'additional_charge[vat]': additional_charge.vat,
                    'additional_charge[unit_id]': additional_charge.unit_id,
                  }))
                  if (additional_charge.category_id)
                    this.props.form.setFieldsValue({
                      'additional_charge[category_id]': additional_charge.category.name,
                    })
                }, 10)
              }
            }
          }

          return (
            <Mutation
              mutation={! this.props.additional_charge_id ? CREATE_QUERY : UPDATE_QUERY}
              onError={err => graphQLErrorsToToast(err)}
              onCompleted={dataMutate => {
                toast(true, dataMutate.additional_charge.informative.messages)
                if ( ! this.props.isModal) {
                  this.props.history.push(routes.ADDITIONAL_CHARGE_LIST)
                }
                if ( ! this.props.additional_charge_id || ! this.props.additional_charge_copy_id) {
                  if (this.props.isModal)
                    return this.props.isModal(true)
                  this.props.refetch()
                  this.props.form.resetFields()
                } else {
                  this.props.refetch()
                  this.props.history.push(routes.ADDITIONAL_CHARGE_LIST)
                }
              }}
            >
              {(mutate, { loading: loadingMutation }) => (
                <Card
                  loading={( ! this.props.additional_charge_id || ! this.props.additional_charge_copy_id) ? false : (loadingQuery || ! data.additionalCharge)}
                  bodyStyle={{ padding: '0px' }} bordered={false}>

                  <Form
                    hideRequiredMark={true}
                    onSubmit={e => {
                      e.preventDefault()
                      this.onSubmit(mutate)
                    }}
                    className="form-label-default"
                  >

                    <div className="form-block">

                      <Form.Item hasFeedback label={(
                        <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('additional_charge[name]', {
                          rules: [{ max: 20 }, { required: true }],
                          validateTrigger: false,
                        })(
                          <Input size="large" maxLength={20}/>,
                        )}
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('additional_charge[code]', {
                          rules: [{ max: 20 }, { required: true }],
                          validateTrigger: false,
                        })(
                          <Input size="large" maxLength={20}/>,
                        )}
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon
                          type="bulb"/><p>{this.props.langData['Category']}</p></span>)} {...formItemLayout} >
                        {getFieldDecorator('additional_charge[category_id]', { validateTrigger: false })(
                          <AddUpSelect
                            data={this.state.categories && this.state.categories.map(x => ({
                              key: x.category_id,
                              value: x.name,
                            }))}
                            onAddUp={value => this.createCategory(value)}
                          />,
                        )}
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon type="database"/><p>{this.props.langData['Unit']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('additional_charge[unit_id]', {
                          rules: [{ required: true }],
                          validateTrigger: false,
                        })(
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            suffixIcon={<Icon type="caret-down"/>}
                            filterOption={selectFilterLowerCase}
                          >
                            {this.state.units && this.state.units.map(x => {
                              return <Select.Option value={x.unit_id} key={x.unit_id}>{x.name}</Select.Option>
                            })}
                          </Select>,
                        )}
                      </Form.Item>

                      <Form.Item label={(
                        <span><Icon type="wallet"/><p>{this.props.langData['Buying Price']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        <Input.Group compact style={{ display: 'flex' }}>
                          {getFieldDecorator('additional_charge[buying_price]', { validateTrigger: false })(
                            <InputNumber
                              size="large"
                              min={0.0001}
                              prefix={null}
                              step={this.state.increment && this.state.increment.toFixed(4)}
                              decimalSeparator=","
                              style={{ marginRight: '0px', width: '100%' }}
                            />,
                          )}
                          <Button disabled size="large">{getCurrencyWithId(this.currencyId).symbol}</Button>
                        </Input.Group>

                      </Form.Item>

                      <Form.Item label={(
                        <span><Icon type="wallet"/><p>{this.props.langData['Selling Price']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        <Input.Group compact style={{ display: 'flex' }}>
                          {getFieldDecorator('additional_charge[selling_price]', { validateTrigger: false })(
                            <InputNumber
                              size="large"
                              min={0.0001}
                              step={this.state.increment && this.state.increment.toFixed(4)}
                              decimalSeparator=","
                              style={{ marginRight: '0px', width: '100%' }}
                            />,
                          )}
                          <Button disabled size="large">{getCurrencyWithId(this.currencyId).symbol}</Button>
                        </Input.Group>
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon type="wallet"/><p>{this.props.langData['Vat']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('additional_charge[vat]', { validateTrigger: false })(
                          (this.props.lang === 'tr') ?
                            (
                              <Select
                                size="large"
                                className="w-100"
                                suffixIcon={<Icon type="caret-down"/>}
                              >
                                {this.props.langData.vats.map((x, k) => {
                                  return <Select.Option value={x} key={k}>{`${x}%`}</Select.Option>
                                })}
                              </Select>
                            ) : (
                              <InputNumber
                                size="large"
                                min={0}
                                formatter={value => `${value}%`}
                                className="w-100"
                                parser={value => {
                                  if (value === '')
                                    return ''
                                  return value.replace('%', '')
                                }}
                              />
                            ),
                        )}
                      </Form.Item>

                    </div>

                    <Divider/>

                    <div className="form-block">
                      <Form.Item>
                        <Button type="primary" size="large" loading={loadingMutation} htmlType="submit"
                                className="default-button">
                          {this.props.langData['Save']}
                        </Button>
                        { ! this.props.isModal && <Link to={routes.ADDITIONAL_CHARGE_LIST}>
                          <Button size="large" style={{ marginRight: 8 }} loading={loadingMutation}
                                  className="default-button danger2">
                            {this.props.langData['Close']}
                          </Button>
                        </Link>}
                        {(this.props.additional_charge_id) && <Button
                          type="danger" icon="delete" size="large" style={{ float: 'left' }}
                          onClick={this.delete} loading={loadingMutation} className="default-button"
                        >
                          {this.props.langData['Delete']}
                        </Button>}
                      </Form.Item>
                    </div>

                  </Form>
                </Card>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

AdditionalChargeForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  additional_charge_id: PropTypes.number,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
  refetch: PropTypes.func,
  isModal: PropTypes.func,
  additional_charge_copy_id: PropTypes.number,
}

export default connect(mapStateToProps)(AdditionalChargeForm)
