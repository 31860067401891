import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Icon, Form, Button, Switch, Divider, Input, Select } from 'antd'
import {
  ApartmentOutlined,
  BarcodeOutlined,
  CodepenOutlined,
  FileTextOutlined,
  MailOutlined,
  MoneyCollectOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { postPanelApi } from '../../../../../api'

import { deleteNull, formErrorFieldsNameLocalize, successToast } from '../../../../../helpers/helper'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

function InvoiceSettingsForm(props) {

  const { form, langData } = props

  const [submitting, setSubmitting] = useState(false)
  const [paymentMeansCodes, setPaymentMeansCodes] = useState([])

  useEffect(() => {

    postPanelApi('/settings/invoice-settings-open').then(result => {

      if (result) {

        setPaymentMeansCodes(result.payment_means_codes)

        form.setFieldsValue(deleteNull(result.form))

      }

    })

  }, [])

  const onSubmit = () => {

    form.validateFieldsAndScroll((err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      setSubmitting(true)

      postPanelApi('/settings/invoice-settings-update', values).then(result => {

        if (result) {

          successToast(langData['Invoice Settings Success'])

        }

        setSubmitting(false)

      })

    })
  }

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><Icon
        type="control"/> &nbsp;{langData['Invoice & E-Invoice & E-Archive Settings']}</span>}
      bordered={false}
    >
      <Form
        hideRequiredMark={true}
        className="form-label-default"
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
      >

        <div className="form-block">
          <Divider>{langData['Invoice Settings']}</Divider>

          <Form.Item label={(
            <span><Icon
              type="fullscreen"/><p>{langData['Should modal be switched on when adding product and additional charge to the invoice']}</p></span>
          )}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('modal_status', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Form.Item label={<span><Icon
            type="tags"/><p>{langData['Product name view on invoice']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('product_invoice_name_status', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Invoice Name']}
                unCheckedChildren={langData['Product Name']}
              />,
            )}
          </Form.Item>

          <Form.Item label={<span><Icon
            type="shopping"/><p>{langData['Does use marketplace customer']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('does_use_market_place_customer', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Divider>{langData['E-Invoice & E-Archive Settings']}</Divider>

          <Form.Item label={<span><ApartmentOutlined/><p>{langData['Is Activate Partial Vat']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('partial_vat', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Form.Item
            label={<span><MailOutlined/><p>{langData['E-Invoice to be sent as e-mail?']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('send_email', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Form.Item label={<span><UserOutlined/><p>{langData['Use Person Name']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('use_person_name', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Form.Item
            label={<span><BarcodeOutlined/><p>{langData['Send Supplier Code Instead of Product Code']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('supplier_code_instead_product_code', {
              valuePropName: 'checked',
            })(
              <Switch
                checkedChildren={langData['Yes']}
                unCheckedChildren={langData['No']}
              />,
            )}
          </Form.Item>

          <Form.Item
            label={<span><CodepenOutlined/><p>{langData['E-Invoice Template Code']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('e_invoice_template_code')(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item
            label={<span><CodepenOutlined/><p>{langData['E-Archive Template Code']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('e_archive_template_code')(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item
            label={<span><CodepenOutlined/><p>{langData['Tax Exemption Code']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('tax_exemption_reason_code')(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item
            label={<span><CodepenOutlined/><p>{langData['Tax Exemption Description']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('tax_exemption_reason_value')(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item
            label={<span><MoneyCollectOutlined/><p>{langData['Default Payment Means Code']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('default_payment_means_code')(
              <Select
                style={{ width: '100%' }}
                suffixIcon={<Icon type="caret-down"/>}
              >
                {paymentMeansCodes.map(item => (
                    <Select.Option key={item.value} value={item.value}>{item.title}</Select.Option>
                  ),
                )}
              </Select>,
            )}
          </Form.Item>

          <Form.Item
            label={<span><FileTextOutlined/><p>{langData['Default Instruction Note']}</p></span>}
            {...formItemLayout}
          >
            {form.getFieldDecorator('default_instruction_note', {
              rules: [{
                max: 100,
              }],
            })(
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 3, maxRows: 5 }}
                style={{ width: '100%' }}
                maxLength={250}
              />,
            )}
          </Form.Item>

          <Divider>{langData['E-Commerce Invoice Settings']}</Divider>

          <Form.Item label={<span><Icon
            type="tags"/><p>{langData['Transporter Company Title']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('transporter_name')(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item label={<span><Icon
            type="idcard"/><p>{langData['Transporter Company TIN']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('transporter_tin')(
              <Input size="large" maxLength={10}/>,
            )}
          </Form.Item>

          <Form.Item label={<span><Icon
            type="shopping-cart"/><p>{langData['Online Sales URL']}</p></span>}
                     {...formItemLayout}
          >
            {form.getFieldDecorator('online_shoping_url')(
              <Input size="large"/>,
            )}
          </Form.Item>
        </div>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" loading={submitting} htmlType="submit"
                    className="default-button">
              {langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  )

}

InvoiceSettingsForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  modal_status: PropTypes.bool,
  switch_modal: PropTypes.func,
}

export default InvoiceSettingsForm
