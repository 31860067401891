import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spin } from 'antd'

import routes from '../routes'

import { postPanelApi } from '../api'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Support' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    }
  }
}

class SupportPage extends Component {

  state = {
    loading: false,
    pageData: {
      type: '',
      data: {},
    }
  }

  componentDidMount() {

    this.props.breadcrumb_add(routes.SUPPORT_PAGE)

    this.setState({
      loading: true,
    })

    postPanelApi('/general/open-support-page').then(result => {

      if (result) {

        this.setState({
          pageData: result,
        })

      }

      this.setState({
        loading: false,
      })

    })

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {

    if (this.state.loading) {
      return <Spin/>
    }

    switch (this.state.pageData.type) {

      case 'first_usage':
        return (
          <div
            style={{ marginTop: '50px', fontSize: 36, textAlign: 'center' }}
            dangerouslySetInnerHTML={{ __html: this.state.pageData.data.message }}
          />
        )

      case 'iframe':
        return (
          <iframe
            src={this.state.pageData.data.iframe_url}
            width='100%'
            height='1200'
            frameBorder={0}
          />
        )

      default:
        return (
          <div
            style={{ marginTop: '50px', fontSize: 36, textAlign: 'center' }}
          >
            {this.props.langData['An unexpected problem occurred during the opening of the page']}
          </div>
        )

    }
  }

}

SupportPage.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage)
