import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Row, Col, Card, Icon, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import { confirm, successToast } from '../../../helpers/helper'
import { Route, Link, Switch as RouterSwitch, generatePath } from 'react-router-dom'
import NewCategory from './../NewCategory/NewCategory'
import CategoryUpdate from './../CategoryUpdate/CategoryUpdate'
import { startCase } from 'lodash'
import routes from '../../../routes'
import { postPanelApi } from '../../../api'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Categories', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class Category extends Component {

  state = {
    loading: false,
    items: [],
  }

  delete(category_id, type) {

    confirm(undefined, confirmed => {

      if (confirmed) {

        postPanelApi('/category/delete', {
          type: type,
          category_id: category_id,
        }).then(result => {

          if (result) {

            successToast(result.message)

            this.loadPage()

          }

        })

      }

    })

  }

  componentDidMount() {

    this.props.breadcrumb_add(routes.CATEGORY_LIST)

    this.loadPage()

  }

  loadPage = () => {

    this.setState({
      loading: true,
    })

    postPanelApi('/category/list').then(result => {

      if (result) {

        this.setState({
          items: result.items,
        })

      }

    }).finally(() => {

      this.setState({
        loading: false,
      })

    })

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    return (
      <Row gutter={16}>
        {Object.entries(this.state.items).map(([type, items]) => {
          return <Col key={type} xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: 16 }}>
            <Card
              loading={this.state.loading}
              bodyStyle={{ padding: '0px' }}
              title={<span><Icon type="bars"/> {`${this.props.langData[startCase(type)]}`}</span>}
              bordered={false}
              extra={
                <Link
                  to={generatePath(routes.CATEGORY_NEW, {
                    type: type,
                  })}
                >
                  <Button icon="file-add">
                    {this.props.langData['Add']}
                  </Button>
                </Link>
              }
            >
              <List
                dataSource={items}
                style={{
                  ...(this.props.sizeWidth > 576 ? { height: 300 } : { maxHeight: 300 }),
                  overflow: 'auto',
                }}
                renderItem={item => (
                  <List.Item key={item.category_id} actions={[
                    <Link
                      key={item.category_id}
                      to={generatePath(routes.CATEGORY_UPDATE_BY_TYPE, {
                        id: item.category_id,
                        type: type,
                      })}
                    >
                      <Icon type="edit" style={{ fontSize: '16px' }}/>
                    </Link>,
                    <Icon key={item.category_id} type="delete" style={{ fontSize: '15px' }}
                          onClick={() => this.delete(item.category_id, type)}/>,
                  ]}>
                    <List.Item.Meta
                      description={
                        <div style={{ padding: '0px 20px' }}>{item.name} {item.code && ` - ${item.code}`}</div>
                      }
                    />
                  </List.Item>
                )}
              >
              </List>
            </Card>
          </Col>
        })}
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>

          <RouterSwitch>
            <Route path={routes.CATEGORY_NEW} render={props => {
              return (
                <Modal
                  title={<span><Icon type="file-add"/> &nbsp;{this.props.langData['New Category']}</span>}
                  bodyStyle={{ padding: 8 }}
                  visible={true}
                  footer={null}
                  onCancel={() => {
                    props.history.push(routes.CATEGORY_LIST)
                  }}
                >
                  <NewCategory
                    langData={this.props.langData}
                    type={props.match.params.type}
                    onCategoryAdded={() => {
                      props.history.push(routes.CATEGORY_LIST)
                      this.loadPage()
                    }}
                  />
                </Modal>
              )
            }}/>

            <Route exact path={routes.CATEGORY_UPDATE_BY_TYPE} render={(props) => {
              return (
                <Modal
                  title={<span><Icon type="edit"/> &nbsp;{this.props.langData['Update Category']}</span>}
                  bodyStyle={{ padding: 8 }}
                  visible={true}
                  footer={null}
                  onCancel={() => {
                    props.history.push(routes.CATEGORY_LIST)
                  }}
                >
                  <CategoryUpdate
                    langData={this.props.langData}
                    category_id={Number(props.match.params.id)}
                    type={props.match.params.type}
                    onCategoryUpdated={() => {
                      props.history.push(routes.CATEGORY_LIST)
                      this.loadPage()
                    }}
                  />
                </Modal>
              )

            }}/>

          </RouterSwitch>

        </Col>
      </Row>
    )

  }

}

Category.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  sizeWidth: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Category)
