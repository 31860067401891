import React, { Component } from 'react'
import { Select, Tag, Divider } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons'

import { selectFilterLowerCase } from '../../helpers/helper'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

class AddUpSelect extends Component {

  state = {
    value: null,
  }

  render() {
    const { data, langData, onAddUp, ...props } = this.props
    return (
      <Select
        {...props}
        size="large"
        showSearch
        allowClear
        maxLength={50}
        suffixIcon={<CaretDownOutlined/>}
        filterOption={selectFilterLowerCase}
        dropdownRender={menu => {
          return (
            <div>
              {menu}
              {this.state.value ? (
                <span>
									<Divider style={{ margin: '4px 0 0 0' }}/>
									<div
                    style={{ cursor: 'pointer', padding: 5 }}
                    className="success"
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => {
                      onAddUp(this.state.value)
                      this.setState({
                        value: null,
                      })
                    }}
                  >
										<span>
                      <Tag size="small"><PlusOutlined/> {langData['Add New One']}</Tag> {this.state.value}
                    </span>
									</div>
								</span>
              ) : ''}
            </div>
          )
        }}
        notFoundContent={<span></span>}
        onSearch={value => {
          let datas = data.filter(x => x.content ? (x.content === value) : (x.value === value))
          if ( ! datas.length) {
            this.setState({ value: value })
          } else {
            this.setState({ value: null })
          }
        }}
      >
        {this.props.data && data.map(x => {
          return <Select.Option value={x.value} key={x.key}>{x.content ? x.content : x.value}</Select.Option>
        })}
      </Select>
    )
  }

}

AddUpSelect.propTypes = {
  langData: PropTypes.object,
  data: PropTypes.array,
  onAddUp: PropTypes.func,
}

export default connect(mapStateToProps)(AddUpSelect)
