const marketplaceData = [
  {
    name: 'T-Soft',
    code: 'TS',
  },
  {
    name: 'GittiGidiyor',
    code: 'GG',
  },
  {
    name: 'Hepsiburada',
    code: 'HB',
  },
  {
    name: 'N11',
    code: 'NN',
  },
  {
    name: 'Amazon',
    code: 'AZ',
  },
  {
    name: 'Sahibinden',
    code: 'SB',
  },
  {
    name: 'Akakçe',
    code: 'AA',
  },
  {
    name: 'Trendyol',
    code: 'TY',
  },
  {
    name: 'Zoodmall',
    code: 'ZM',
  },
  {
    name: 'Knawat',
    code: 'KN',
  },
  {
    name: 'Evidea',
    code: 'EV',
  },
  {
    name: 'Morhipo',
    code: 'MH',
  },
  {
    name: 'Modanisa',
    code: 'MN',
  },
  {
    name: 'ÇiçekSepeti',
    code: 'CS',
  },
]

const getMarketplacesWithCode = code => {
  for (const data of marketplaceData) {
    if (code.startsWith(data.code))
      return data
  }
}

export {
  marketplaceData,
  getMarketplacesWithCode,
}
