import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { toast, formErrorFieldsNameLocalize } from '../../helpers/helper'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Checkbox, Dropdown, Menu, Row, Col, Card, Divider } from 'antd'
import './Login.css'

import logo from './../../logo.svg'
import { postGuestApi } from '../../api'

class LoginForm extends Component {

  state = {
    submitting: false,
  }

  onSubmit = () => {

    const { from } = this.props.location.state || { from: { pathname: '/' } }

    this.props.form.validateFields((err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      localStorage.removeItem('token')

      this.setState({
        submitting: true,
      })

      postGuestApi('/auth/login', values).then(result => {

        if (result) {

          localStorage.setItem('token', result.token)

          toast(true, this.props.langData['Welcome Jetofis'] + '...')

          this.props.history.push(from.pathname)

        } else {

          this.setState({
            submitting: false,
          })

        }

      })

    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    const menu = (
      <Menu onSelect={e => this.props.languageChange(e.key)} selectable={true}>
        {Object.keys(this.props.langData.languages).map(x => {
          return (
            <Menu.Item key={x}>{this.props.langData.languages[x]}</Menu.Item>
          )
        })}
      </Menu>
    )

    return (
      <Form
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit()
        }}
        className="login-form"
      >
        <Form.Item hasFeedback>
          {getFieldDecorator('email', {
            rules: [{ type: 'email' }, { max: 70 }, { required: true }],
            validateTrigger: false,
          })(
            <Input maxLength={70} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }}/>}
                   placeholder={this.props.langData['E-Mail']}/>,
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [{ max: 32 }, { min: 6 }, { required: true }],
            validateTrigger: false,
          })(
            <Input maxLength={32} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password"
                   placeholder={this.props.langData['Password']}/>,
          )}
        </Form.Item>
        <Form.Item>
          <Checkbox className="fl-l">{this.props.langData['Remember me']}</Checkbox>
          <Link to={'/' + this.props.langData.route['forgot-my-password']}
                className="login-form-forgot">{this.props.langData['Forgot password']}</Link><br/>

          <Button type="primary" loading={this.state.submitting} htmlType="submit" className="login-form-button">
            {this.props.langData['Login']}
          </Button>
          <p className="fl-l">{this.props.langData['or']}</p>
          <Link className="fl-l"
                to={'/' + this.props.langData.route['register']}>&nbsp;{this.props.langData['register now']}!</Link>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="fl-r">{this.props.langData.languages[this.props.lang]}</Button>
          </Dropdown>
        </Form.Item>
      </Form>
    )
  }

}

LoginForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
  lang: PropTypes.string,
  languageChange: PropTypes.func,
  location: PropTypes.object,
  state: PropTypes.object,
}

function Login(props) {

  const WrappedLoginForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(LoginForm)

  return (
    <div className="h-100">
      <Row type="flex" justify="space-around" align="middle" className="h-100">
        <Col xs={1} sm={6} md={7} lg={9}/>
        <Col xs={22} sm={12} md={10} lg={6}>

          <Card style={{
            textAlign: 'center',
            boxShadow: '0 0 100px rgba(0,0,0,.08)',
            background: 'transparent',
            padding: 20,
          }} bordered={false}>
            <img src={logo} alt="Jetofis" className="login-logo"/>
            <WrappedLoginForm {...props} />
            <Divider>JETOFİS</Divider>
            <Card.Meta
              title="Bulut Tabanlı Ticari Yazılım"
              description="www.jetofis.com"
            />
          </Card>
        </Col>
        <Col xs={1} sm={6} md={7} lg={9}/>
      </Row>
    </div>
  )

}

Login.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
})(Login)
