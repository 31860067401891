import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProductForm from './../ProductForm/ProductForm'
import { connect } from 'react-redux'
import { compile } from 'path-to-regexp'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class ProductUpdate extends Component {

  product_id = null

  constructor(props) {
    super(props)
    this.product_id = this.props.match.params.id
  }

  componentDidMount() {
    this.props.breadcrumb_add(
      compile(this.props.langData.route['product/update/:id'])({ id: this.product_id }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedProductForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ProductForm)
    return <WrappedProductForm product_id={this.product_id} {...this.props} ></WrappedProductForm>
  }

}

ProductUpdate.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpdate)
