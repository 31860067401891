import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Statistic, List, Icon, Badge, Select, Spin } from 'antd'
import { toLocaleString, graphQLErrorsToToast } from '../../helpers/helper'
import apolloClient from './../../helpers/apolloClient'
import { getCurrencyWithCode } from '../../data/Currency'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import './Starting.css'
import routes from '../../routes'
import { PlusOutlined } from '@ant-design/icons'
import { postPanelApi } from '../../api'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    dark_theme: state.GeneralReducer.dark_theme,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Starting' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    toggle_development_mode() {
      dispatch({ type: 'toggle_development_mode' })
    },
    toggle_test_mode() {
      dispatch({ type: 'toggle_test_mode' })
    },
  }
}

const REPORT_QUERY = gql`
  query($expiry_date: DateTime, $start_date: Date!, $end_date: Date!, $currency: Currency, $date: Date!, $vat_start_date: DateTime, $vat_end_date: DateTime){
    reports{
      creditDebitSum: invoice{
        sum(field: remaining, group: [
          type
          currency
        ]){
          results {
            type
            currency
            sum
          }
        }
      }

      lateCreditDebitSum: invoice{
        sum(field: remaining, group: [
          type
          currency
        ], filter: {
          AND: [
            {
              expiry_date: {
                lt: $expiry_date
              }
            }
          ]
        }){
          results{
            type
            currency
            sum
          }
        }
      }

      countSalesPurchaseInvoice: invoice{
        count(group: [
          type
        ]){
          results {
            type
            count
          }
        }
      }

      movingInvoice: invoice{
        moving(field: expiry_date, interval: { start_date: $start_date, end_date: $end_date}, moving: 1){
          sum(field:grand_total, group: [
            currency
            type
          ]){
            results{
              sum
              type
              currency
            }
          }
        }
      }

      countCustomers: customer{
        count{
          results {
            count
          }
        }
      }

      countProducts: product{
        count{
          results{
            count
          }
        }
      }

      invoice{
        totalVats:sum(field: total_vat, group: type, filter:
        {
          AND: [
            {
              date: {
                gte: $vat_start_date,
                lte: $vat_end_date
              }
            }
          ]
        }
        ){
          results {
            sum
            type
          }
        }
      }
    }

    currencyRates(currency: $currency date: $date){
      currency_rates{
        try
        usd
        eur
        gbp
        rub
      }
    }

    invoiceList(paging: {
      offset: 0
      limit: 3
    }, filter: {
      AND: [
        {
          expiry_date: {
            lt : $expiry_date
          },
          remaining: {
            gt : 0
          }
        }
      ]
    }){
      transactions{
        __typename
        ... on SalesInvoice{
          transaction_id
          remaining
          expiry_date
          currency
        }
        ... on PurchaseInvoice{
          transaction_id
          remaining
          expiry_date
          currency
        }
        ... on ReturnSalesInvoice{
          transaction_id
          remaining
          expiry_date
          currency
        }
        ... on ReturnPurchaseInvoice{
          transaction_id
          remaining
          expiry_date
          currency
        }
      }
    }

    legacyData {
      currency_code
    }

  }
`

const VAT_REPORT_QUERY = (
  gql`
    query($vat_start_date: DateTime, $vat_end_date: DateTime) {
      reports {
        invoice{
          totalVats:sum(field: total_vat, group: type, filter:
          {
            AND: [
              {
                date: {
                  gte: $vat_start_date,
                  lte: $vat_end_date
                }
              }
            ]
          }
          ){
            results {
              sum
              type
            }
          }
        }
      }
    }
  `
)

class Starting extends Component {

  state = {
    currencyCode: 'try',
    lateData: [],
    totalDebit: null,
    totalCredit: null,
    creditLate: null,
    debitLate: null,
    currency: null,
    counts: {
      customer: null,
      purchaseInvoice: null,
      salesInvoice: null,
      product: null,
    },
    nextDaysData: { input: [], output: [] },
    totalSalesVat: null,
    totalPurchaseVat: null,
  }

  componentDidMount() {

    this.props.breadcrumb_add()
    this.itemEventListener()

    postPanelApi('/update-token-expiry-date').then()

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
    document.removeEventListener('keydown', this.handleKeydown)
  }

  onListItemClick = record => {
    this.props.history.push(
      generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
        type: this.props.langData[record.__typename === 'SalesInvoice' ? 'sales' : 'purchase'],
        id: record.transaction_id,
      }),
    )
  }

  changeVatReport = async event => {

    const result = await apolloClient.query({
      query: VAT_REPORT_QUERY, variables: {
        vat_start_date: event === 'currentMonth' ? moment().startOf('month').format('YYYY-MM-DD HH:mm') : moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm'),
        vat_end_date: event === 'currentMonth' ? moment().endOf('month').format('YYYY-MM-DD HH:mm') : moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm'),
      },
    })

    this.setState({
      totalSalesVat: cloneDeep(result.data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0] && result.data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0] ?
        result.data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum - result.data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0].sum :
        result.data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0] ? result.data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum : 0),
      totalPurchaseVat: cloneDeep(result.data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0] && result.data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0] ?
        result.data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum - result.data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0].sum :
        result.data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0] ? result.data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum : 0),
    })
  }

  stri = ''
  sayac

  handleKeydown = (event) => {

    clearTimeout(this.sayac)

    this.stri += event.key

    if (this.stri === 'developmentmod') {
      this.props.toggle_development_mode()
    }

    if (this.stri === 'testmod') {
      this.props.toggle_test_mode()
    }

    this.sayac = setTimeout(() => {
      this.stri = ''
    }, 1000)

  }

  itemEventListener = () => {
    document.addEventListener('keydown', this.handleKeydown)
  }

  render() {
    const options = {
      chart: {
        type: 'column',
        height: 280,
      },
      title: {
        style: { display: 'none' },
      },
      xAxis: {
        categories: (() => {
          let data = []
          for (let i = -6; i < 10; i++) {
            let date
            if (i === 0)
              date = this.props.langData['Today']
            else
              date = moment().locale(this.props.lang).add(i, 'days').format('DD MMMM')
            data.push(date)
          }
          return data
        })(),
        plotBands: [{
          from: 5.5,
          to: 6.5,
          color: 'rgba(68, 170, 213, .2)',
        }],
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        style: { color: '#000000' },

      },
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: (() => {
          const symbol = getCurrencyWithCode(this.state.currencyCode).symbol
          return function () {
            return this.points.map(x => {
                return `
								<b>${x.series.name}: <b>${toLocaleString(x.y)} ${symbol}<b/><br/>
								`
              },
            ).join('')
          }
        })(),
      },
      plotOptions: {
        spline: {
          marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          name: this.props.langData['Input'],
          data: this.state.nextDaysData.input,
        }, {
          name: this.props.langData['Output'],
          marker: { symbol: 'diamond' },
          data: this.state.nextDaysData.output,
        },
      ],
      credits: { enabled: false },
    }
    const bodyStyle = {
      textAlign: 'center',
      fontSize: '18px',
    }
    const titleColor = this.props.dark_theme ? '#1b2e42' : '#9ea6ad'
    const bodyColor = this.props.dark_theme ? 'card-title-dark' : 'card-title-light'
    const statisticColor = this.props.dark_theme ? '#ffffff' : '#666666'
    const moneyTypeColor = this.props.dark_theme ? 'money-type-dark' : 'money-type-light'
    const badgeColor = this.props.dark_theme ? 'badge-dark-color' : 'badge-light-color'
    const smallCardTitle = this.props.dark_theme ? {
      backgroundColor: '#808b96',
      color: '#ffffff',
      fontSize: '16px',
    } : { backgroundColor: '#ffffff', color: '#666666', fontSize: '16px' }

    return (
      <Query
        query={REPORT_QUERY}
        fetchPolicy="network-only"
        variables={{
          expiry_date: moment().format('Y-MM-DD HH:mm'),
          start_date: moment().subtract(6, 'days').format('Y-MM-DD'),
          end_date: moment().add(7, 'days').format('Y-MM-DD'),
          date: moment().format('Y-MM-DD'),
          vat_start_date: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm'),
          vat_end_date: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm'),
        }}
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          this.setState({
            currency: cloneDeep(data.currencyRates.currency_rates),
            currencyCode: data.legacyData.currency_code,
          })

          if ( ! this.state.counts.customer) {
            let customer = data.reports.countCustomers.count.results
            let sales = data.reports.countSalesPurchaseInvoice.count.results.filter(x => x.type === 'SalesInvoice')
            let purchase = data.reports.countSalesPurchaseInvoice.count.results.filter(x => x.type === 'PurchaseInvoice')
            let product = data.reports.countProducts.count.results
            this.setState({
              counts: {
                customer: cloneDeep(customer[0].count),
                purchaseInvoice: cloneDeep(purchase[0] ? purchase[0].count : 0),
                salesInvoice: cloneDeep(sales[0] ? sales[0].count : 0),
                product: cloneDeep(product[0].count),
              },
            })
          }

          if (this.state.totalDebit === null || this.state.totalCredit === null) {
            let debit = data.reports.creditDebitSum.sum.results.filter(x => x.type === 'SalesInvoice')
            let credit = data.reports.creditDebitSum.sum.results.filter(x => x.type === 'PurchaseInvoice')

            let totalDebit = 0
            debit.map(x => {
              if (x.currency !== data.legacyData.currency_code) {
                totalDebit += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
              } else {
                totalDebit += parseFloat(x.sum.toFixed(4))
              }
            })
            let totalCredit = 0
            credit.map(x => {
              if (x.currency !== data.legacyData.currency_code)
                totalCredit += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
              else
                totalCredit += parseFloat(x.sum.toFixed(4))
            })
            this.setState({
              totalDebit: cloneDeep(totalDebit.toFixed(4)),
              totalCredit: cloneDeep(totalCredit.toFixed(4)),
            })
          }
          if (this.state.debitLate === null || this.state.creditLate === null) {
            let debit = data.reports.lateCreditDebitSum.sum.results.filter(x => x.type === 'SalesInvoice')
            let credit = data.reports.lateCreditDebitSum.sum.results.filter(x => x.type === 'PurchaseInvoice')
            let lateDebit = 0

            debit.map(x => {
              if (x.currency !== data.legacyData.currency_code) {
                lateDebit += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
              } else {
                lateDebit += parseFloat(x.sum.toFixed(4))
              }
            })
            let lateCredit = 0
            credit.map(x => {
              if (x.currency !== data.legacyData.currency_code) {
                lateCredit += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
              } else {
                lateCredit += parseFloat(x.sum.toFixed(4))
              }
            })
            this.setState({
              debitLate: cloneDeep(lateDebit.toFixed(4)),
              creditLate: cloneDeep(lateCredit.toFixed(4)),
            })

          }
          if ( ! this.state.nextDaysData.length) {
            let nextDaysData = { input: [], output: [] }
            for (let x in data.reports.movingInvoice.moving.sum) {
              let nextData = data.reports.movingInvoice.moving.sum[x].results
              if ( ! nextData.length) {
                nextDaysData.input.push(0)
                nextDaysData.output.push(0)
              } else {
                let input = 0
                let output = 0
                nextData.map(x => {
                  if (x.type === 'SalesInvoice') {
                    if (x.currency === data.legacyData.currency_code) {
                      input += parseFloat(x.sum.toFixed(4))
                    } else {
                      input += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
                    }
                  } else {
                    if (x.currency === data.legacyData.currency_code) {
                      output += x.sum
                    } else {
                      output += parseFloat((x.sum / this.state.currency[x.currency]).toFixed(4))
                    }
                  }
                })

                nextDaysData.input.push(input)
                nextDaysData.output.push(output)
              }
            }

            this.setState({
              nextDaysData: cloneDeep(nextDaysData),
            })

          }

          if ( ! this.state.lateData.length) {
            this.setState({
              lateData: cloneDeep(data.invoiceList.transactions),
            })
          }

          if (this.state.totalSalesVat === null || this.state.totalPurchaseVat === null) {
            this.setState({
              totalSalesVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0] && data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0] ?
                data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum - data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnSalesInvoice')[0].sum :
                data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'SalesInvoice')[0].sum : 0),
              totalPurchaseVat: cloneDeep(data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0] && data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0] ?
                data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum - data.reports.invoice.totalVats.results.filter(x => x.type === 'ReturnPurchaseInvoice')[0].sum :
                data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0] ? data.reports.invoice.totalVats.results.filter(x => x.type === 'PurchaseInvoice')[0].sum : 0),
            })
          }

        }}
      >
        {({ loading }) => {

          if (loading) {
            return <Spin/>
          }

          return (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={6} xl={6} style={{ marginBottom: 16 }}>
                        <Card
                          extra={<Icon className="money-type-dark" type="download" style={{ fontSize: 23 }}></Icon>}

                          className={this.props.dark_theme ? 'first-card-title-dark' : 'first-card-title-light'}
                          title={
                            <span className="title-style">
																	{this.props.langData['Total Charged']}
                              <span style={{ float: 'right' }}></span>
																</span>
                          }
                          headStyle={{
                            backgroundColor: this.props.dark_theme ? '#255bae' : '#ce6049',
                            border: 0,
                            fontSize: '16px',
                          }}
                          bordered={false}
                          style={{ ...bodyStyle }}
                          hoverable={true}
                        >
                          <Statistic
                            size="large"
                            value={this.state.totalDebit}
                            decimalSeparator=","
                            groupSeparator="."
                            precision={4}
                            valueStyle={{ color: statisticColor }}
                          />
                          <span className={moneyTypeColor}>
                            {this.props.langData[this.state.currencyCode]}
                          </span>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6} style={{ marginBottom: 16 }}>
                        <Card
                          extra={<Icon className="money-type-dark" type="reload" style={{ fontSize: 23 }}/>}
                          className={bodyColor}
                          bordered={false}
                          hoverable={true}
                          title={
                            <span className="title-style">
																	{this.props.langData['Lated Debit']}<i className="fas fa-redo-alt"></i>
																</span>
                          }
                          style={{ ...bodyStyle }}
                          headStyle={{ backgroundColor: titleColor, border: 0 }}
                        >
                          <Statistic
                            value={this.state.debitLate}
                            decimalSeparator=","
                            groupSeparator="."
                            precision={4}
                            valueStyle={{ color: statisticColor }}
                          />
                          <span className={moneyTypeColor}>
                            {this.props.langData[this.state.currencyCode]}
                          </span>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6} style={{ marginBottom: 16 }}>
                        <Card
                          extra={<Icon className="money-type-dark" type="upload" style={{ fontSize: 23 }}/>}
                          className={bodyColor}
                          bordered={false}
                          hoverable={true}
                          title={
                            <span className="title-style">
																	{this.props.langData['Total Payable']}
																</span>
                          }
                          style={{ ...bodyStyle }}
                          headStyle={{ backgroundColor: this.props.dark_theme ? '#1b2e42 ' : '#ce6049', border: 0 }}

                        >
                          <Statistic
                            value={this.state.totalCredit}
                            decimalSeparator=","
                            groupSeparator="."
                            precision={4}
                            valueStyle={{ color: statisticColor }}
                          />
                          <span className={moneyTypeColor}>
                            {this.props.langData[this.state.currencyCode]}
                          </span>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6} style={{ marginBottom: 16 }}>
                        <div className="info-wrapper">
                          <Card
                            extra={<Icon className="money-type-dark" type="reload" style={{ fontSize: 23 }}/>}
                            className={bodyColor}
                            bordered={false}
                            title={
                              <span className="title-style">
																		{this.props.langData['Lated Credit']}
																	</span>
                            }
                            style={{ ...bodyStyle }}
                            hoverable={true}
                            headStyle={{ backgroundColor: titleColor, border: 0 }}
                          >
                            <Statistic
                              value={this.state.creditLate}
                              decimalSeparator=","
                              groupSeparator="."
                              precision={4}
                              valueStyle={{ color: statisticColor }}
                            />
                            <span className={moneyTypeColor}>
                              {this.props.langData[this.state.currencyCode]}
                            </span>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={4}>
                    <Card
                      // style={{ marginBottom: 5, height: 159}}
                      loading={this.state.counts.salesInvoice === null && this.state.counts.purchaseInvoice === null}
                      bordered={false}
                      size="small"
                      style={{ ...bodyStyle, marginBottom: 8, height: 164 }}
                      bodyStyle={{ marginTop: '10%' }}
                      title={
                        <span
                          className={this.props.dark_theme ? 'last-card-title-style-dark' : 'last-card-title-style-light'}
                        >
															{this.props.langData['Total Sales Invoices']}
														</span>
                      }
                      headStyle={{
                        backgroundColor: this.props.dark_theme ? '#2c3e50' : '#ffffff',
                        marginBottom: '10px',
                        fontSize: '16px',
                        border: 0,
                      }}
                    >
                      <Badge
                        className="badge-icon-color"
                        count={
                          <span
                            style={{
                              fontSize: '27px',
                              borderRadius: '50%',
                              background: '#ffffff',
                              display: 'inline-block',
                              boxShadow: '0px 0px 3px #2c3e50',
                              padding: '0.2em 0.3em',
                            }}
                          >
                                <Link className="link" to={routes.INVOICE_LIST}><PlusOutlined/></Link>
                              </span>
                        }
                      >
                        <a className={badgeColor} style={{
                          width: '75px',
                          height: '75px',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}><span style={{
                          width: '100%', lineHeight: '75px',
                          fontSize: '25px', color: '#fff',
                        }}>{this.state.counts.salesInvoice}</span></a>

                      </Badge>
                    </Card>
                    <Card
                      loading={this.state.counts.salesInvoice === null && this.state.counts.purchaseInvoice === null}
                      bordered={false}
                      size="small"
                      style={{ ...bodyStyle, marginBottom: 5, height: 164 }}
                      bodyStyle={{ marginTop: '10%' }}
                      title={
                        <span
                          className={this.props.dark_theme ? 'last-card-title-style-dark' : 'last-card-title-style-light'}
                        >
															{this.props.langData['Total Purchase Invoices']}
														</span>
                      }
                      headStyle={{
                        backgroundColor: this.props.dark_theme ? '#2c3e50' : '#ffffff',
                        marginBottom: '10px',
                        fontSize: '16px',
                        border: 0,
                      }}
                    >
                      <Badge className="badge-icon-color" count={<span style={{
                        fontSize: '27px',
                        borderRadius: '50%',
                        background: '#ffffff',
                        display: 'inline-block',
                        boxShadow: '0px 0px 3px #2c3e50',
                        padding: '0.2em 0.3em',
                      }}><Link className="link" to={routes.INVOICE_LIST}><PlusOutlined/></Link></span>}>
                        <a className={badgeColor} style={{
                          width: '75px',
                          height: '75px',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}><span style={{
                          width: '100%', lineHeight: '75px',
                          fontSize: '25px', color: '#fff',
                        }}>{this.state.counts.purchaseInvoice}</span></a>

                      </Badge>
                    </Card>

                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={4}>
                    <Card
                      loading={this.state.counts.customer === null}
                      bordered={false}
                      bodyStyle={{ marginTop: '10%' }}
                      size="small"
                      style={{ ...bodyStyle, marginBottom: 8, height: 164 }}
                      title={
                        <span
                          className={this.props.dark_theme ? 'last-card-title-style-dark' : 'last-card-title-style-light'}
                        >
															{this.props.langData['Total Customers']}
														</span>
                      }
                      headStyle={{
                        backgroundColor: this.props.dark_theme ? '#2c3e50' : '#ffffff',
                        marginBottom: '10px',
                        fontSize: '16px',
                        border: 0,
                      }}
                    >

                      <Badge className="badge-icon-color" count={<span style={{
                        fontSize: '27px',
                        borderRadius: '50%',
                        background: '#ffffff',
                        display: 'inline-block',
                        boxShadow: '0px 0px 3px #2c3e50',
                        padding: '0.2em 0.3em',
                      }}><Link className="link" to={routes.CUSTOMER_LIST}><Icon
                        type="file-text"/></Link></span>}>
                        <a className={badgeColor} style={{
                          width: '75px',
                          height: '75px',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}><span style={{
                          width: '100%', lineHeight: '75px',
                          fontSize: '25px', color: '#fff',
                        }}>{this.state.counts.customer}</span></a>

                      </Badge>
                    </Card>
                    <Card
                      loading={this.state.counts.product === null}
                      bordered={false}
                      bodyStyle={{ marginTop: '10%' }}
                      size="small"
                      style={{ ...bodyStyle, marginBottom: 5, height: 164 }}
                      title={
                        <span
                          className={this.props.dark_theme ? 'last-card-title-style-dark' : 'last-card-title-style-light'}
                        >
															{this.props.langData['Total Products']}
														</span>
                      }
                      headStyle={{
                        backgroundColor: this.props.dark_theme ? '#2c3e50' : '#ffffff',
                        marginBottom: '10px',
                        fontSize: '16px',
                        border: 0,
                      }}
                    >
                      <Badge className="badge-icon-color" count={<span style={{
                        fontSize: '27px',
                        borderRadius: '50%',
                        background: '#ffffff',
                        display: 'inline-block',
                        boxShadow: '0px 0px 3px #2c3e50',
                        padding: '0.2em 0.3em',
                      }}><Link className="link" to={routes.PRODUCT_LIST}><Icon type="file-text"/></Link></span>}>
                        <a className={badgeColor} style={{
                          width: '75px',
                          height: '75px',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }}><span style={{
                          width: '100%',
                          lineHeight: '75px',
                          fontSize: '25px',
                          color: '#fff',
                        }}>{this.state.counts.product}</span></a>

                      </Badge>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card
                      size="small"
                      style={{ marginBottom: 5, height: 109, textAlign: 'center', display: 'block' }}
                      title={
                        <React.Fragment>
                          <a href={routes.TRANSACTION_VAT_REPORT} style={{ marginRight: 1 }}>
                            {this.props.langData['Total Calculated VAT']}
                          </a>
                          <Select defaultValue="previousMonth" size="small" style={{ width: 'max-content' }}
                                  onChange={event => this.changeVatReport(event)}>
                            <Option value="previousMonth">{moment().subtract(1, 'months').format('MMMM')}</Option>
                            <Option value="currentMonth">{moment().format('MMMM')}</Option>
                          </Select>
                        </React.Fragment>
                      }
                      headStyle={{ ...smallCardTitle, border: 0, textAlign: 'inherit' }}
                      bordered={false}
                    >
                      <Statistic
                        value={this.state.totalSalesVat}
                        decimalSeparator=","
                        groupSeparator="."
                        precision={4}
                        valueStyle={{ color: '#cf1322' }}
                        prefix={<Icon type="arrow-up" style={{
                          backgroundColor: this.props.dark_theme ? '#de2020' : '#ee7474',
                          color: '#ffffff',
                        }}/>}
                        suffix={
                          <span>
                            {this.state.currencyCode === 'try' ? 'TL' : this.state.currencyCode.toUpperCase()}
                          </span>
                        }
                      />
                    </Card>
                    <Card
                      style={{ marginBottom: 5, height: 109, textAlign: 'center' }}
                      size="small"
                      title={
                        <a href={routes.TRANSACTION_VAT_REPORT}>{this.props.langData['Total VAT to be Deducted']}</a>
                      }
                      headStyle={{ ...smallCardTitle, textAlign: 'center', border: 0 }}
                      bordered={false}
                    >
                      <Statistic
                        value={this.state.totalPurchaseVat}
                        decimalSeparator=","
                        groupSeparator="."
                        precision={4}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<Icon type="arrow-down" style={{
                          backgroundColor: this.props.dark_theme ? '#127220' : '#a1cfa8',
                          color: '#ffffff',
                        }}/>}
                        suffix={
                          <span>
                            {this.state.currencyCode === 'try' ? 'TL' : this.state.currencyCode.toUpperCase()}
                          </span>
                        }
                      />
                    </Card>
                    <Card
                      title={<a href={routes.TRANSACTION_VAT_REPORT}>
                        {parseFloat(this.state.totalSalesVat - this.state.totalPurchaseVat) > 0 ? this.props.langData['Payable VAT'] : this.props.langData['VAT Transferred To Next Period']}
                      </a>}
                      size="small"
                      headStyle={{ ...smallCardTitle, textAlign: 'center', border: 0 }}
                      style={{ marginBottom: 5, height: 109, textAlign: 'center' }}
                      bordered={false}
                    >
                      <Statistic
                        value={parseFloat(this.state.totalSalesVat - this.state.totalPurchaseVat).toFixed(4)}
                        decimalSeparator=","
                        groupSeparator="."
                        precision={4}
                        valueStyle={{ color: '#1890ff' }}
                        prefix={<Icon type="plus" style={{
                          backgroundColor: this.props.dark_theme ? '#3b86ff' : '#a4bfec',
                          color: '#ffffff',
                        }}/>}
                        suffix={
                          <span>
                            {this.state.currencyCode === 'try' ? 'TL' : this.state.currencyCode.toUpperCase()}
                          </span>
                        }
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10} style={{ marginBottom: 16 }}>
                    <Card
                      bordered={false}
                      title={<span><Icon type="pay-circle-o"/> &nbsp;{this.props.langData['Late']}</span>}
                      headStyle={{
                        backgroundColor: this.props.dark_theme ? '#1b2e42' : '#9da5ac',
                        border: 0,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      actions={[
                        <div key="1"
                             style={{ textAlign: 'center', width: '100%', fontSize: 12, color: 'indianred' }}>
                          <Icon
                            type="clock-circle-o"/> &nbsp;{this.props.langData['TODAY']} {moment().locale(this.props.lang).format('DD MMMM')}
                        </div>,
                      ]}
                      bodyStyle={{ padding: 0, height: 233, overflow: 'scroll' }}
                    >
                      <List
                        size="large"
                        dataSource={this.state.lateData}
                        renderItem={item => (
                          <List.Item
                            onClick={() => this.onListItemClick(item)}
                            className={'list-item list-item-' + (item.__typename === 'SalesInvoice' ? 'debit' : 'credit')}
                            style={{ padding: '16px 26px' }}
                          >
                            <Icon type={item.__typename === 'SalesInvoice' ? 'double-right' : 'double-left'}
                                  style={{ marginRight: 18, lineHeight: '45px' }}/>
                            <div>
                              {this.props.langData['Days Late'](moment().diff(moment(item.expiry_date), 'days'))}
                              <br/>
                              <strong>{item.__typename === 'SalesInvoice' ? this.props.langData['Debit'] : this.props.langData['Credit']}</strong> &nbsp;
                              {toLocaleString(parseFloat((item.remaining).toFixed(4)))} {getCurrencyWithCode(item.currency).symbol}
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: 16 }}>
                <Card
                  bordered={false}
                  title={this.props.langData['Cash flow for the last and next {x} days'](6)}
                  headStyle={{
                    backgroundColor: this.props.dark_theme ? '#1b2e42' : '#9da5ac',
                    border: 0,
                    color: 'white',
                    fontSize: '18px',
                  }}
                  bodyStyle={{ padding: 0 }}
                  style={{ ...bodyStyle }}
                >
                  <HighchartsReact
                    className={this.props.dark_theme ? 'highcharts-color-0-dark' : 'highcharts-color-0-light'}
                    highcharts={Highcharts}
                    options={options}
                  ></HighchartsReact>
                </Card>
              </Col>
            </Row>
          )
        }}
      </Query>
    )
  }

}

Starting.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  toggle_development_mode: PropTypes.func,
  toggle_test_mode: PropTypes.func,
  lang: PropTypes.string,
  dark_theme: PropTypes.bool,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Starting)
