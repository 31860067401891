import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Dropdown, Menu, Row, Col, Card, Divider } from 'antd'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import { toast, formErrorFieldsNameLocalize, graphQLErrorsToToast } from '../../helpers/helper'
import routes from '../../routes'

const NEWPASSWORD_MUTATION = (
  gql`
    mutation($new_passwd: NewPasswdInput!){
      newPasswd(new_passwd: $new_passwd){
        informative{
          messages
        }
      }
    }
  `
)

function NewPasswordForm(props) {

  const token = props.match.params.token

  const onSubmit = (mutate) => {
    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      try {
        const result = await mutate({ variables: { new_passwd: { ...values, token: token } } })

        if (result !== undefined) {
          toast(true, result.data.newPasswd.informative.messages)

          setTimeout(() => {
            props.history.push(routes.LOGIN)
          }, 3000)
        }
      } catch (e) {
        graphQLErrorsToToast(e)
      }
    })
  }

  const menu = <Menu onSelect={e => props.languageChange(e.key)} selectable={true}>
    {Object.keys(props.langData.languages).map(x => {
      return (
        <Menu.Item key={x}>{props.langData.languages[x]}</Menu.Item>
      )
    })}
  </Menu>

  const { getFieldDecorator } = props.form

  return (
    <Mutation
      mutation={NEWPASSWORD_MUTATION}
      onError={err => graphQLErrorsToToast(err)}
    >
      {(mutate, { loading: loadingMutation }) => {
        return (
          <Form onSubmit={e => {
            e.preventDefault()
            onSubmit(mutate)
          }}>
            <Form.Item hasFeedback style={{ marginTop: 24, marginBottom: 14 }}>
              {getFieldDecorator('password', { rules: [{ max: 32 }, { min: 6 }, { required: true }] })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password"
                       placeholder={props.langData['Password']}/>,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loadingMutation} htmlType="submit" style={{ width: '100%' }}>
                {props.langData['Send']}
              </Button>
              <Link to={routes.LOGIN} className="fl-l">{props.langData['Login']}</Link>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="fl-r">{props.langData.languages[props.lang]}</Button>
              </Dropdown>
            </Form.Item>
          </Form>
        )
      }}
    </Mutation>
  )

}

NewPasswordForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  lang: PropTypes.string,
  languageChange: PropTypes.func,
}

function NewPassword(props) {

  const WrappedNewPasswordForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(NewPasswordForm)

  return (
    <div className="h-100">
      <Row type="flex" justify="space-around" align="middle" className="h-100">
        <Col xs={1} sm={6} md={7} lg={9}/>
        <Col xs={22} sm={12} md={10} lg={6}>

          <Card style={{
            textAlign: 'center',
            boxShadow: '0 0 100px rgba(0,0,0,.08)',
            background: 'transparent',
            padding: 20,
          }} bordered={false}>
            <img src="https://app.jetofis.com/logo.svg" alt="Jetofis"/>
            <Divider style={{ marginTop: 20 }}>{props.langData['New Password']}</Divider>
            <WrappedNewPasswordForm {...props} />
            <Divider>JETOFİS</Divider>
            <Card.Meta
              title="Bulut Tabanlı Ticari Yazılım"
              description="www.jetofis.com"
            />
          </Card>

        </Col>
        <Col xs={1} sm={6} md={7} lg={9}/>
      </Row>
    </div>
  )

}

NewPassword.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
  }
})(NewPassword)
