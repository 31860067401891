import en from './en'
import tr from './tr'

let routes = {}

for(let x in en.route){
	
	let enExploded = en.route[x].split(/\/?(:[^/]+)\/?/)
	let trExploded = tr.route[x].split(/\/?(:[^/]+)\/?/)
	
	routes[x] = ''

	enExploded.map((y, i) => {
		if(y !== ''){
			if(y[0] === ':')
				routes[x] += `/${y}`
			else
				routes[x] += `/(${y}|${trExploded[i]})`
		}
	})

}

export default routes
