import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  selectFilterLowerCase,
  deleteEmpty,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
} from './../../../helpers/helper'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Col, Card, Divider, Select } from 'antd'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'

const { Option } = Select
const InputGroup = Input.Group
const { TextArea } = Input

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
  },
}

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Defaults', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const INVOICE_DEFAULTS_QUERY = (
  gql`
    query{
      invoiceCategoryList {
        category_id
        name
        code
      }
      sequenceList{
        sequences {
          sequence_id
          name
          type
          pattern
          start
          end
          step
          current_value
          next_value
        }
      }
      invoiceDefaults {
        series
        category_id
        invoice_country
        invoice_city
        invoice_town
        invoice_address
        delivery_country
        delivery_city
        delivery_town
        delivery_address
        explanation
        code
        no
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation($invoice_defaults: InvoiceDefaultsInput!){
      invoiceDefaultsUpdate(invoice_defaults: $invoice_defaults){
        informative{
          messages
        }
      }
    }
  `
)


class InvoiceDefaults extends Component {

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['invoice/defaults'])
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    const WrappedLInvoiceDefaultsForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceDefaultsForm)
    return <WrappedLInvoiceDefaultsForm {...this.props}></WrappedLInvoiceDefaultsForm>
  }

}

InvoiceDefaults.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

class InvoiceDefaultsForm extends Component {

  state = {
    categories: [],
    sequences: [],
    initialize: false,
    country: null,
    city: null,
  }

  onSubmit = (form, mutate) => {
    form.validateFields(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      deleteEmpty(values)
      try {
        const result = await mutate({ variables: { invoice_defaults: { ...values } } })
        toast(true, result.data.invoiceDefaultsUpdate.informative.messages)
      } catch (err) {
        graphQLErrorsToToast(err)
      }

    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const form = (mutate, loadingMutation) => (

      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit(this.props.form, mutate)
        }}
        className="form-label-default"
      >

        <div className="form-block">

          <Form.Item hasFeedback label={(<span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('category_id')(
              <Select
                size="large"
                showSearch
                allowClear
                filterOption={selectFilterLowerCase}
                suffixIcon={<Icon type="caret-down"/>}
              >
                {this.state.categories.map(x => {
                  return <Option value={x.category_id} key={x.category_id}>{x.name}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('code')(
              <Select
                size="large"
                showSearch
                allowClear
                filterOption={selectFilterLowerCase}
                suffixIcon={<Icon type="caret-down"/>}
              >
                {this.state.sequences.map(x => {
                  return <Option value={x.sequence_id} key={x.sequence_id}>{x.name}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Invoice No']}</p></span>
          )}
                     {...formItemLayout}
          >
            <InputGroup size="large">
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('series', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Input placeholder={this.props.langData['Series']}/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('no')(
                    <Select size="large"
                            showSearch
                            allowClear
                            filterOption={selectFilterLowerCase}
                            placeholder={this.props.langData['Queue']}
                            className="w-100"
                            suffixIcon={<Icon type="caret-down"/>}
                    >
                      {this.state.sequences.map(x => {
                        return <Option value={x.sequence_id} key={x.sequence_id}>{x.name}</Option>
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </InputGroup>

          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item hasFeedback
                     label={(<span><Icon type="environment-o"/><p>{this.props.langData['Invoice Country']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('invoice_country', { rules: [{ max: 100 }], validateTrigger: false })(
              <Select
                size="large"
                showSearch
                allowClear
                suffixIcon={<Icon type="caret-down"/>}
                filterOption={selectFilterLowerCase}
                onSelect={val => this.setState({ invoice_country: val })}
              >
                {this.props.langData.countries.map((x, k) => {
                  return <Option value={x} key={k}>{x}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="environment-o"/><p>{this.props.langData['Invoice City/Town']}</p></span>
          )}
                     {...formItemLayout}
          >
            <InputGroup size="large">
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('invoice_city', { rules: [{ max: 100 }], validateTrigger: false })(
                    (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                      <Select
                        size="large"
                        className="w-100"
                        showSearch
                        allowClear
                        suffixIcon={<Icon type="caret-down"/>}
                        filterOption={selectFilterLowerCase}
                        onChange={val => this.setState({ city: val })}
                      >
                        {this.props.langData.cityTown.map((x, k) => {
                          return <Option value={x.city} key={k}>{x.city}</Option>
                        })}
                      </Select>
                    ) : <Input/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('invoice_town', { rules: [{ max: 100 }], validateTrigger: false })(
                    (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                      <Select
                        size="large"
                        className="w-100"
                        showSearch
                        allowClear
                        suffixIcon={<Icon type="caret-down"/>}
                        filterOption={selectFilterLowerCase}
                      >
                        {
                          this.props.langData.cityTown.filter(x => x.city === this.state.city).length && (
                            this.props.langData.cityTown.filter(x => x.city === this.state.city)[0].towns.map((x, k) => {
                              return <Option value={x} key={k}>{x}</Option>
                            })
                          )
                        }
                      </Select>
                    ) : <Input/>,
                  )}
                </Form.Item>
              </Col>
            </InputGroup>

          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="environment-o"/><p>{this.props.langData['Invoice Address']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('invoice_address', { rules: [{ max: 500 }], validateTrigger: false })(
              <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}/>,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item hasFeedback
                     label={(
                       <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Country']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('delivery_country', { rules: [{ max: 100 }], validateTrigger: false })(
              <Select
                size="large"
                showSearch
                allowClear
                suffixIcon={<Icon type="caret-down"/>}
                filterOption={selectFilterLowerCase}
                onSelect={val => this.setState({ delivery_country: val })}
              >
                {this.props.langData.countries.map((x, k) => {
                  return <Option value={x} key={k}>{x}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span><Icon type="environment-o"/><p>{this.props.langData['Delivery City/Town']}</p></span>
          )}
                     {...formItemLayout}
          >
            <InputGroup size="large">
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('delivery_city', { rules: [{ max: 100 }], validateTrigger: false })(
                    (this.props.lang === 'tr' && this.state.delivery_country === 'Türkiye') ? (
                      <Select
                        size="large"
                        suffixIcon={<Icon type="caret-down"/>}
                        className="w-100"
                        showSearch
                        allowClear
                        filterOption={selectFilterLowerCase}
                        onChange={val => this.setState({ city: val })}
                      >
                        {this.props.langData.cityTown.map((x, k) => {
                          return <Option value={x.city} key={k}>{x.city}</Option>
                        })}
                      </Select>
                    ) : <Input/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('delivery_town', { rules: [{ max: 100 }], validateTrigger: false })(
                    (this.props.lang === 'tr' && this.state.delivery_country === 'Türkiye') ? (
                      <Select
                        size="large"
                        className="w-100"
                        suffixIcon={<Icon type="caret-down"/>}
                        showSearch
                        allowClear
                        filterOption={selectFilterLowerCase}
                      >
                        {
                          this.props.langData.cityTown.filter(x => x.city === this.state.city).length && (
                            this.props.langData.cityTown.filter(x => x.city === this.state.city)[0].towns.map((x, k) => {
                              return <Option value={x} key={k}>{x}</Option>
                            })
                          )
                        }
                      </Select>
                    ) : <Input/>,
                  )}
                </Form.Item>
              </Col>
            </InputGroup>

          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="environment-o"/><p>{this.props.langData['Delivery Address']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('delivery_address', { rules: [{ max: 500 }], validateTrigger: false })(
              <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}/>,
            )}
          </Form.Item>

        </div>
        <Divider/>

        <div className="form-block">
          <Form.Item hasFeedback {...formItemLayoutNoLabel} >
            {getFieldDecorator('explanation', { rules: [{ max: 500 }], validateTrigger: false })(
              <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                        placeholder={this.props.langData['Explanation']}
              />,
            )}
          </Form.Item>
        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" loading={loadingMutation} htmlType="submit" className="default-button">
              {this.props.langData['Save']}
            </Button>
          </Form.Item>
        </div>

      </Form>
    )
    return (
      <Query
        query={INVOICE_DEFAULTS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            if (data.invoiceCategoryList.length && ! this.state.categories.length)
              this.setState({
                categories: cloneDeep(data.invoiceCategoryList),
              })
            if ( ! this.state.sequences.length)
              this.setState({
                sequences: cloneDeep(data.sequenceList.sequences),
              })
            if ( ! this.state.initialize) {
              this.props.form.setFieldsValue(data.invoiceDefaults)

              this.setState({
                initialize: true,
                country: cloneDeep(data.invoiceDefaults.country),
                city: cloneDeep(data.invoiceDefaults.city),
              })
            }
          }


          return (
            <Mutation
              mutation={UPDATE_QUERY}
              onError={err => graphQLErrorsToToast(err)}
            >
              {(mutate, { loading: loadingMutation }) => {
                return (
                  <Card loading={ ! this.state.initialize} bodyStyle={{ padding: '0px' }}
                        title={
                          <span><Icon type="idcard"/>
                            &nbsp;{this.props.langData['Invoice Defaults']}</span>
                        }
                        bordered={false}
                  >
                    {form(mutate, loadingMutation)}
                  </Card>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

InvoiceDefaultsForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDefaults)
