import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphQLErrorsToToast, formErrorFieldsNameLocalize, deleteNull, toast } from './../../../helpers/helper'
import { connect } from 'react-redux'
import { Form, Icon, Button, Card, Divider } from 'antd'
import { Query, Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import AddUpSelect from './../../Utils/AddUpSelect'


const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}
const READ_QUERY = (
  gql`
    query($product_id: Int!){
      subProductAttributeGroupList{
        attribute_groups {
          attribute_group_id
          name
        }
      }
      defaultAttributeGroup(product_id: $product_id){
        attribute_group_id
        is_first
        attribute_group {
          name
        }
      }
    }

  `
)

const CREATE_QUERY = (
  gql`
    mutation setDefault($product_id: Int!, $attribute_group: [DefaultAttributeGroupInput]!){
      setDefault: setDefaultAttributeGroups(product_id: $product_id, attribute_group: $attribute_group){
        default_attribute_groups{
          attribute_group_id
          is_first
          product_id
        }
        informative {
          messages
        }
      }
    }
  `
)

class DefaultAttributeGroupForm extends Component {

  state = {
    data: null,
    attribute_groups: null,
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      let attribute_group = [
        {
          attribute_group_id: values.attribute_group.first,
          is_first: true,
        },
        {
          attribute_group_id: values.attribute_group.second,
          is_first: false,
        },
      ]

      if ( ! values.attribute_group.second) {
        attribute_group = attribute_group[0]
      }

      if ( ! values.attribute_group.first) {
        attribute_group = attribute_group[1]
      }

      if (typeof attribute_group === 'undefined') {
        return toast(false, this.props.langData['At least one attribute must be selected'])
      }

      try {
        const result = await mutate({ variables: { attribute_group, product_id: this.props.product_id } })

        if (result !== undefined) {
          toast(true, result.data.setDefault.informative.messages)
          this.props.refetch()
        }
      } catch (err) {
        graphQLErrorsToToast(err)
      }

    })
  }

  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form

    return (
      <Query
        query={READ_QUERY}
        variables={{ product_id: this.props.product_id }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            if (data.defaultAttributeGroup.length && ! this.state.data) {
              this.setState({
                data: data.defaultAttributeGroup,
              })
              setTimeout(() => {
                this.props.form.setFieldsValue(deleteNull({
                  'attribute_group[first]': data.defaultAttributeGroup.filter(x => x.is_first === true)[0] && data.defaultAttributeGroup.filter(x => x.is_first === true)[0].attribute_group_id,
                  'attribute_group[second]': data.defaultAttributeGroup.filter(x => x.is_first === false)[0] && data.defaultAttributeGroup.filter(x => x.is_first === false)[0].attribute_group_id,
                }))
              }, 10)
            }

            if ( ! this.state.attribute_groups)
              this.setState({
                attribute_groups: data.subProductAttributeGroupList.attribute_groups,
              })
          }
          return (
            <Mutation
              mutation={CREATE_QUERY}
            >
              {(mutate, { loading: loadingMutation }) => (
                <Card loading={loadingQuery} bodyStyle={{ padding: '0px' }} bordered={false}>

                  <Form
                    hideRequiredMark={true}
                    onSubmit={e => {
                      e.preventDefault()
                      this.onSubmit(mutate)
                    }}
                    className="form-label-default"
                  >

                    <div className="form-block">

                      <Form.Item hasFeedback label={(
                        <span><Icon type="tag"/><p>{this.props.langData['First Attribute Group']}</p></span>
                      )}
                                 labelCol={{ span: 10 }}
                                 wrapperCol={{ span: 12 }}

                      >
                        {getFieldDecorator('attribute_group[first]', { validateTrigger: false })(
                          <AddUpSelect
                            data={this.state.attribute_groups && this.state.attribute_groups.filter(x => x.attribute_group_id !== getFieldsValue().attribute_group.second).map(x => ({
                              key: x.attribute_group_id,
                              value: x.attribute_group_id,
                              content: x.name,
                            }))}
                            // onAddUp={value => {
                            // 	console.log(value)
                            // }}
                          />,
                        )}
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon type="paper-clip"/><p>{this.props.langData['Second Attribute Group']}</p></span>
                      )}
                                 labelCol={{ span: 10 }}
                                 wrapperCol={{ span: 12 }}
                      >
                        {getFieldDecorator('attribute_group[second]', { validateTrigger: false })(
                          <AddUpSelect
                            data={this.state.attribute_groups && this.state.attribute_groups.filter(x => x.attribute_group_id !== getFieldsValue().attribute_group.first).map(x => ({
                              key: x.attribute_group_id,
                              value: x.attribute_group_id,
                              content: x.name,
                            }))}
                            //onAddUp={value => console.log(value)}
                          />,
                        )}
                      </Form.Item>
                    </div>

                    <Divider/>

                    <div className="form-block">
                      <Form.Item>
                        <Button
                          loading={loadingMutation}
                          type="primary" size="large" className="default-button"
                          style={{ marginRight: 5 }}
                          onClick={() => this.onSubmit(mutate)}
                        >
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>

                  </Form>
                </Card>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

DefaultAttributeGroupForm.propTypes = {
  langData: PropTypes.object,
  product_id: PropTypes.number,
  form: PropTypes.object,
  history: PropTypes.object,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps)(DefaultAttributeGroupForm)
