import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
  updateEmptyAndUndefined,
  deleteNull,
} from '../../../helpers/helper'
import { compile } from 'path-to-regexp'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Divider } from 'antd'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 14 },
  },
}

const CREATE_QUERY = (
  gql`
    mutation attribute($attribute: SubProductAttributeCreateInput!){
      attribute: subProductAttributeCreate(attribute: $attribute){
        attribute {
          attribute_id
        }
        informative{
          messages
        }
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation attribute($attribute_id: Int!, $attribute: SubProductAttributeUpdateInput){
      attribute: subProductAttributeUpdate(attribute_id: $attribute_id, attribute: $attribute){
        attribute{
          attribute_id
        }
        informative{
          messages
        }
      }
    }
  `
)

class SubProductAttributesForm extends Component {

  state = {
    initialize: true,
    selectedCurrency: {},
    opening_balance: 0,
    data: null,
    default: false,
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)
      if (this.props.attribute_id) {
        updateEmptyAndUndefined(values)
        values.attribute_id = this.props.attribute_id
      } else {
        values.attribute.attribute_group_id = this.props.attribute_group_id
      }

      try {
        const result = await mutate({ variables: values })
        if (result !== undefined) {
          toast(true, result.data.attribute.informative.messages)

          this.props.refetch()
        }
      } catch (err) {
        graphQLErrorsToToast(err)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    if (this.props.attribute_id) {
      // bulamazsan hata ver ve yönlendir
      if ( ! this.props.attribute) {
        toast(false, this.props.langData['x not found']('Attribute'))
        this.props.history.push('/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: this.props.attribute_group_id }))
      } else if ( ! this.state.data) {

        this.setState({ data: this.props.attribute })

        setTimeout(() => {
          this.props.form.setFieldsValue(deleteNull({
            'attribute[title]': this.props.attribute.title,
            'attribute[code]': this.props.attribute.code,
          }))
        }, 10)
      }
    }
    return (
      <Mutation
        mutation={! this.props.attribute_id ? CREATE_QUERY : UPDATE_QUERY}
      >
        {(mutate, { loading: loadingMutation }) => (
          <Form
            hideRequiredMark={true}
            onSubmit={e => {
              e.preventDefault()
              this.onSubmit(mutate)
            }}
            className="form-label-default"
          >

            <div className="form-block">
              <Form.Item hasFeedback label={(
                <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
              )}
                         {...formItemLayout}
              >
                {getFieldDecorator('attribute[title]', {
                  rules: [{ max: 50 }, { required: true }],
                  validateTrigger: false,
                })(
                  <Input size="large" maxLength={50}/>,
                )}
              </Form.Item>

              <Form.Item hasFeedback label={(
                <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
              )}
                         {...formItemLayout}
              >
                {getFieldDecorator('attribute[code]', {
                  rules: [{ max: 50 }, { required: true }],
                  validateTrigger: false,
                })(
                  <Input size="large" maxLength={50}/>,
                )}
              </Form.Item>
            </div>

            <Divider/>

            <div className="form-block">
              <Button
                loading={loadingMutation}
                type="primary"
                size="large"
                className="default-button"
                style={{ marginRight: 5 }}
                onClick={() => this.onSubmit(mutate)}
              >
                {this.props.langData['Save']}
              </Button>
            </div>
          </Form>
        )}
      </Mutation>
    )
  }

}

SubProductAttributesForm.propTypes = {
  langData: PropTypes.object,
  attribute_id: PropTypes.number,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  attribute: PropTypes.object,
  history: PropTypes.object,
  attribute_group_id: PropTypes.number,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps)(SubProductAttributesForm)
