import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'
import UpdateSafeForm from '../UpdateSafeForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class SafeUpdate extends Component {

  safe_id = null

  constructor(props) {
    super(props)
    this.safe_id = Number(this.props.match.params.id)
  }

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.SAFE_UPDATE, { id: this.safe_id }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    const WrappedUpdateSafeForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(UpdateSafeForm)
    return <WrappedUpdateSafeForm safe_id={this.safe_id} {...this.props} />
  }

}

SafeUpdate.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SafeUpdate)
