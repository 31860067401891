import React, { Component } from 'react'
import { gql } from '@apollo/client'

import {
  Card,
  Form,
  Icon,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Col,
  Table,
  InputNumber,
  Spin,
  Dropdown,
  Menu,
} from 'antd'
import { Query, Mutation } from '@apollo/client/react/components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { searchCustomer } from '../../../helpers/trait'
import {
  formErrorFieldsNameLocalize,
  graphQLErrorsToToast,
  selectFilterLowerCase,
  toast,
  updateEmptyAndUndefined,
} from '../../../helpers/helper'
import moment from 'moment'
import { cloneDeep, capitalize } from 'lodash'
import { compile } from 'path-to-regexp'

//component
import CustomerSearchModal from './../../Customer/CustomerSearchModal/CustomerSearchModal'
import AdditionalChargeSearchModal
  from './../../AdditionalCharge/AdditionalChargeSearchModal/AdditionalChargeSearchModal'
import ProductSearchModal from './../../Product/ProductSearchModal/ProductSearchModal'
import InvoiceProduct from './../InvoiceProduct/InvoiceProduct'
import InvoiceAdditionalCharge from './../InvoiceAdditionalCharge/InvoiceAdditionalCharge'
import apolloClient from './../../../helpers/apolloClient'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

const INVOICE_READ_QUERY = type => (
  gql`
    query ($transaction_id: Int!){
    waybill: ${type}Invoice(transaction_id: $transaction_id) {
    transaction_id
    customer_id
    customer {
    name
    }
    waybill_invoice_country: invoice_country
    waybill_invoice_city: invoice_city
    waybill_invoice_town: invoice_town
    waybill_invoice_address: invoice_address
    waybill_delivery_country: delivery_country
    waybill_delivery_city: delivery_city
    waybill_delivery_town: delivery_town
    waybill_delivery_address: delivery_address
    invoice_postal_code
    delivery_postal_code
    products {
    product_id
    quantity
    product {
    name
    unit {
    name
    }
    unit_id
    }
    }
    additional_charges {
    additional_charge_id
    additional_charge {
    name
    unit {
    name
    }
    unit_id
    }
    quantity
    }
    }
    userInformation {
    settings {
    increment
    }
    }
    }
  `
)

const READ_QUERY = type => (
  gql`
    query ($waybill_id: Int!){
    waybill: ${type}Waybill(waybill_id: $waybill_id){
    waybill_id
    invoice_transaction_id
    customer_id
    no
    date
    sending_date
    waybill_country
    waybill_city
    waybill_town
    waybill_address
    waybill_postal_code
    explanation
    customer {
    name
    }
    products {
    waybill_product_id
    product_id
    quantity
    product {
    name
    }
    unit {
    name
    }
    unit_id
    }
    additional_charges {
    waybill_additional_charge_id
    additional_charge_id
    additional_charge {
    name
    }
    unit {
    name
    }
    quantity
    unit_id
    }
    }
    userInformation {
    settings {
    increment
    }
    }
    }
  `
)

const PRODUCT_READ_QUERY = (
  gql`
    query ($product_id: Int!){
      product(product_id: $product_id) {
        product_id
        name
        selling_price
        selling_currency
        buying_price
        buying_currency
        vat
        unit_id
        additional_charges {
          additional_charge_id
          buying_price
          selling_price
          quantity
          vat
          additional_charge {
            unit_id
            name
            unit {
              name
            }
          }
        }
      }
      userInformation {
        settings {
          increment
        }
      }
    }
  `
)

const ADDITIONAL_CHARGE_READ_QUERY = (
  gql`
    query additionalCharge($additional_charge_id: Int!){
      additionalCharge(additional_charge_id: $additional_charge_id) {
        additional_charge_id
        name
        category_id
        buying_price
        selling_price
        vat
        unit_id
        unit {
          name
        }
      }
      userInformation {
        settings {
          increment
        }
      }
    }
  `
)

const PRODUCT_SEARCH_QUERY = (
  gql`
    query productList($name: String!){
      productList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        products {
          product_id
          unit_id
          name
          buying_price
          buying_currency
          selling_price
          selling_currency
          vat
          additional_charges {
            additional_charge_id
            buying_price
            selling_price
            quantity
            vat
            additional_charge {
              name
            }
          }
        }
      }
    }
  `
)

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          unit_id
          additional_charge_id
          name
          buying_price
          selling_price
          vat
        }
      }
    }
  `
)

const WAYBILL_CREATE_QUERY = type => (
  gql`
    mutation waybillCreate($waybill: WaybillCreateInput!) {
    waybill: ${type}WaybillCreate(${type}_waybill: $waybill) {
    waybill: ${type}_waybill {
    waybill_id
    }
    informative {
    messages
    }
    }
    }
  `
)

const WAYBILL_UPDATE_QUERY = type => (
  gql`
    mutation ($waybill: WaybillUpdateInput!, $waybill_id: Int!) {
    waybill: ${type}WaybillUpdate(${type}_waybill: $waybill, waybill_id: $waybill_id) {
    waybill: ${type}_waybill {
    waybill_id
    invoice_transaction_id
    customer_id
    no
    explanation
    date
    sending_date
    waybill_country
    waybill_city
    waybill_town
    waybill_address
    waybill_postal_code
    }
    informative {
    messages
    }
    }
    }
  `
)

const CUSTOMER_READ_QUERY = (
  gql`
    query($customer_id: Int!){
      customer(customer_id:$customer_id){
        customer_id
        address
        country
        city
        town
        name
      }
    }
  `
)

class WaybillForm extends Component {
  constructor(props) {
    super(props)
    this.searchCustomer = searchCustomer('waybill[customer_id]').bind(this)
  }

  initialState = {
    customerList: [],
    products: [],
    additional_charges: [],
    initialize: false,
    modalProduct: null,
    modalAdditionalCharge: null,
    modalCustomerSearch: null,
    modalProductSearch: null,
    modalAdditionalChargeSearch: null,
    modal_status: null,
    selectedCurrency: {},
    increment: 0.0001,

    showProductSearchAsModal: false,
  }

  state = this.initialState

  columns = [
    {
      title: this.props.langData['Product'] + ' / ' + this.props.langData['Additional Charge'],
      width: '40%',
      key: 'product_id|additional_charge_id',
      render: (value, record) => {
        let type = (typeof record.product_id !== 'undefined') ? 'product' : 'additional_charge'
        if (this.props.sizeWidth > 576)
          return (
            <Input.Group compact style={{ display: 'flex' }}>
              <Select
                suffixIcon={<Icon type="caret-down"/>}
                className="w-100"
                filterOption={false}
                showSearch
                defaultActiveFirstOption={false}
                notFoundContent={(this.state.fetchingProduct || this.state.fetchingAdditionalCharge) ? <Spin/> : null}
                value={record.product_id || record.additional_charge_id}
                onSearch={value => (type === 'product') ? this.searchProduct(record.id, value) : this.searchAdditionalCharge(record.id, value)}
                disabled={record.delete}
                onSelect={value => {
                  let datas = this.state[type + 's']
                  let data = datas.find(x => x.id === record.id)
                  let dataPure = data[type + 's'].find(x => x[type + '_id'] === value)

                  data[type + '_id'] = value

                  this.setState({ [type + 's']: datas })

                  if (type === 'product' && dataPure.additional_charges.length) {
                    let product_additional_charges = []
                    dataPure.additional_charges
                      .forEach(x => {
                        product_additional_charges.push({
                          id: Math.floor(Math.random() * 999999) + 1,
                          additional_charge_id: x.additional_charge_id,
                          quantity: 1,
                          additional_charges: [{
                            additional_charge_id: x.additional_charge_id,
                            name: x.additional_charge.name,
                          }],
                        })

                      })

                    this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
                  }
                }}
              >
                {
                  (record.products || record.additional_charges).map((x, k) => {
                    return <Select.Option value={x.product_id || x.additional_charge_id}
                                          key={k}>{x.name}</Select.Option>
                  })
                }
              </Select>
              <Button
                icon="search"
                disabled={record.delete}
                onClick={() => this.setState({ [`modal${type === 'product' ? 'Product' : 'AdditionalCharge'}Search`]: record.id })}
              ></Button>
            </Input.Group>
          )
        if (value && record[type + 's'].length)
          return record[type + 's'].find(x => x[type + '_id'] === record[type + '_id']).name
        return null
      },
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              style={{ width: '100%' }}
              min={0.0001}
              step={this.state.increment && this.state.increment.toFixed(4)}
              decimalSeparator=","
              value={value}
              disabled={record.delete}
              onChange={value => {
                if (typeof (value) === 'number') {
                  let datas = (typeof record.product_id !== 'undefined') ? this.state.products : this.state.additional_charges
                  datas.find(x => x.id === record.id).quantity = value
                  this.setState({
                    [(typeof record.product_id !== 'undefined') ? 'products' : 'additional_charges']: datas,
                  })
                }
              }}
            />
          )
        return `${value} ${record.unit ? ' ' + record.unit.name : ''}`
      },
    },
    {
      title: this.props.langData['Unit'],
      dataIndex: 'unit',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <Input
              style={{ width: '100%' }}
              value={value}
              disabled={value ? true : false}
            />
          )
      },
    },
    {
      className: 'text-right',
      render: (value, record) => {
        return (
          <Button
            icon={record.delete === true ? 'minus-circle-o' : 'close-circle-o'}
            style={{ width: 40 }}
            onClick={event => {
              event.stopPropagation()
              if (typeof record.product_id !== 'undefined')
                this.deleteProduct(record.id)
              else
                this.deleteAdditionalCharge(record.id)
            }}
          >
          </Button>
        )
      },
    },
  ]

  menu = (
    <Menu onClick={e => (e.key === 'product') ? this.addProduct() : this.addAdditionalCharge()}>
      <Menu.Item key="product">
        {this.props.langData['Product']}
      </Menu.Item>
      <Menu.Item key="additionalCharge">
        {this.props.langData['Additional Charge']}
      </Menu.Item>
    </Menu>
  )

  onSubmit = async (mutate, callback) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      const step1 = async () => {

        if (this.state.products.length) {
          let products = []
          let products_update = []

          for (let i = 0; i < this.state.products.length; i++) {
            let product = Object.assign({}, this.state.products[i])

            if ( ! product.product_id || ! product.quantity)
              return toast(false, this.props.langData['Incorrect products entered'])

            delete product.amount
            delete product.unit
            delete product.products
            delete product.id
            delete product.__typename

            if (product.waybill_product_id && ! this.props.transaction_id)
              products_update.push(product)
            else
              products.push(product)

            if (products.length)
              values.waybill.products = products
            if (products_update.length)
              values.waybill.products_update = products_update
          }
        }

        if (this.state.additional_charges.length) {
          let additional_charges = []
          let additional_charges_update = []

          for (let i = 0; i < this.state.additional_charges.length; i++) {
            let additional_charge = Object.assign({}, this.state.additional_charges[i])

            if ( ! additional_charge.additional_charge_id || ! additional_charge.quantity)
              return toast(false, this.props.langData['Incorrect products entered'])

            delete additional_charge.amount
            delete additional_charge.unit
            delete additional_charge.additional_charges
            delete additional_charge.id
            delete additional_charge.products
            delete additional_charge.__typename

            if (additional_charge.waybill_additional_charge_id && ! this.props.transaction_id)
              additional_charges_update.push(additional_charge)
            else
              additional_charges.push(additional_charge)

            if (additional_charges.length)
              values.waybill.additional_charges = additional_charges
            if (additional_charges_update.length)
              values.waybill.additional_charges_update = additional_charges_update
          }
        }

        values.waybill.date = moment(values.waybill.date).format('YYYY-MM-DD HH:mm')
        if (values.waybill.sending_date)
          values.waybill.sending_date = moment(values.waybill.sending_date).format('YYYY-MM-DD HH:mm')

        if (this.props.waybill_id || this.props.transaction_id) {
          if (( ! values.waybill.products && ! values.waybill.products_update) && ( ! values.waybill.additional_charges && ! values.waybill.additional_charges_update))
            return toast(false, this.props.langData['At least one product or additional charge must be entered'])

          updateEmptyAndUndefined(values)

          if (this.props.transaction_id) {
            values.waybill.invoice_transaction_id = this.props.transaction_id
          } else if (this.props.waybill_id) {
            values.waybill_id = this.props.waybill_id
          }
        } else {
          if ( ! values.waybill.products && ! values.waybill.additional_charges)
            return toast(false, this.props.langData['At least one product or additional charge must be entered'])
        }

        try {
          const result = await mutate({ variables: values })
          if (result !== undefined) {
            toast(true, result.data.waybill.informative.messages)
            if (callback) {
              return callback()
            }
            this.props.history.push('/' + compile(this.props.langData.route['waybill/:type/detail/:id'])({
              type: this.props.langData[capitalize(this.props.type)],
              id: this.props.waybill_id || result.data.waybill.waybill.waybill_id,
            }))
          }
        } catch (err) {
          graphQLErrorsToToast(err)
        }
      }
      step1()
    })
  }

  onSubmitAndNew = (mutate) => {
    this.onSubmit(mutate, async () => {
      this.props.form.resetFields()
      document.getElementById('main-content').scrollTop = 0
      this.setState({
        ...(this.initialState),
      })
    })
  }

  addProduct = () => {
    let product = {
      id: Math.floor(Math.random() * 999999) + 1,
      product_id: null,
      quantity: 1,
      products: [],
    }

    setTimeout(() => {
      let element = document.querySelector('#table .ant-table-body')
      if (element)
        element.scrollTop = 999999
    }, 1)

    this.setState({
      products: [...this.state.products, product],
    })

    if (this.props.sizeWidth <= 576) {
      this.setState({ modalProduct: product })
    } else {
      this.setState({ showProductSearchAsModal: true, modalProductSearch: product.id })
    }
  }


  addAdditionalCharge = () => {
    let additional_charge = {
      id: Math.floor(Math.random() * 999999) + 1,
      additional_charge_id: null,
      quantity: 1,
      additional_charges: [],
    }

    setTimeout(() => {
      let element = document.querySelector('#table .ant-table-body')
      if (element)
        element.scrollTop = 999999
    }, 1)

    this.setState({
      additional_charges: [...this.state.additional_charges, additional_charge],
    })

    if (this.props.sizeWidth <= 576) {
      this.setState({ modalAdditionalCharge: additional_charge })
    } else {
      this.setState({ modalAdditionalChargeSearch: additional_charge.id })
    }

  }

  editProduct = (product, name, unit, additional_charges) => {
    let products = []
    this.state.products.map(x => {
      if (x.id !== product.id)
        products.push(x)
      else
        products.push({
          ...product, products: [
            {
              product_id: product.product_id,
              name,
              unit,
            },
          ],
        })
    })

    this.setState({ modalProduct: null, products })

    let product_additional_charges = []
    additional_charges
      .forEach(x => {
        product_additional_charges.push({
          id: Math.floor(Math.random() * 999999) + 1,
          additional_charge_id: x.additional_charge_id,
          quantity: 1,
          additional_charges: [{
            additional_charge_id: x.additional_charge_id,
            name: x.additional_charge.name,
            unit: x.additional_charge.unit.name,
          }],
        })

      })

    this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
  }

  editAdditionalCharge = (additional_charge) => {
    let additional_charges = []
    this.state.additional_charges.map(x => {
      if (x.id !== additional_charge.id)
        additional_charges.push(x)
      else
        additional_charges.push({
          ...additional_charge, additional_charges: [
            {
              additional_charge_id: additional_charge.additional_charge_id,
              name: additional_charge.name,
              unit: additional_charge.unit,
            },
          ],
        })
    })
    this.setState({
      modalAdditionalCharge: null,
      additional_charges: additional_charges,
    })
  }

  modalClose = () => {
    let products = this.state.products.filter(x => x.product_id !== null)
    let additional_charges = this.state.additional_charges.filter(x => x.additional_charge_id !== null)
    this.setState({
      products: products,
      additional_charges: additional_charges,
      modalProduct: null,
      modalAdditionalCharge: null,
    })
  }

  productValue = null

  searchProduct = async (id, value) => {
    if (this.productValue === value)
      return

    this.productValue = value

    if (value.length < 2)
      return

    let products = this.state.products
    let product = products.find(x => x.id === id)

    product.products = []
    this.setState({ fetchingProduct: true })

    try {
      const result = await apolloClient.query({ query: PRODUCT_SEARCH_QUERY, variables: { name: value } })
      product.products = result.data.productList.products
      if ( ! result.data.productList.products.length) {
        product.product_id = null
        product.name = null
      }
      this.setState({ products, fetchingProduct: false })
    } catch (err) {
      this.setState({ fetchingProduct: false })
      graphQLErrorsToToast(err)
    }

  }

  additionalChargeValue = null

  searchAdditionalCharge = async (id, value) => {
    if (this.additionalChargeValue === value)
      return

    this.additionalChargeValue = value

    if (value.length < 2)
      return

    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    additional_charge.additional_charges = []
    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      additional_charge.additional_charges = result.data.additionalChargeList.additional_charges
      if ( ! result.data.additionalChargeList.additional_charges.length) {
        additional_charge.additional_charge_id = null
        additional_charge.name = null
      }
      this.setState({ additional_charges, fetchingAdditionalCharge: false })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  customerSearchModalCallback = async (record) => {
    this.setState({
      customerList: [{
        name: record.name,
        customer_id: record.customer_id,
      }],
      modalCustomerSearch: null,
    })
    this.props.form.setFieldsValue({
      'waybill[customer_id]': record.customer_id,
    })
    try {
      const getAddress = await apolloClient.query({
        query: CUSTOMER_READ_QUERY,
        variables: { customer_id: record.key ? Number(record.key) : record.customer_id },
      })
      if (getAddress.data) {
        this.props.form.setFieldsValue({
          'waybill[waybill_town]': getAddress.data.customer.town,
          'waybill[waybill_country]': getAddress.data.customer.country,
          'waybill[waybill_city]': getAddress.data.customer.city,
          'waybill[waybill_address]': getAddress.data.customer.address,
        })
        this.setState({
          customerList: [{
            name: getAddress.data.customer.name,
            customer_id: getAddress.data.customer.customer_id,
          }],
        })
      } else {
        toast(false, this.props.langData['Server error'])
      }
    } catch (error) {
      graphQLErrorsToToast(error)
    }

  }

  productSearchModalCallback = async record => {
    try {
      const result = await apolloClient.query({
        query: PRODUCT_READ_QUERY,
        variables: { product_id: record.product_id },
      })
      if ( ! result.data.product)
        return toast(false, this.props.langData['x not found']('Product'))

      let id = this.state.modalProductSearch
      let products = this.state.products
      let product = products.find(x => x.id === id)
      let productPure = result.data.product
      product.product_id = record.product_id
      product.unit = record.unit && record.unit.name ? record.unit.name : record.unit
      product.products = [{
        product_id: record.product_id,
        name: productPure.name,
      }]
      product.unit_id = productPure.unit_id
      this.setState({
        products: products,
        showProductSearchAsModal: false,
        modalProductSearch: null,
        increment: result.data.userInformation.settings.increment,
      })

      if (productPure.additional_charges.length) {
        let product_additional_charges = []
        productPure.additional_charges
          .filter(x => ! this.state.additional_charges.find(y => y.additional_charge_id === x.additional_charge_id))
          .forEach(x => {
            product_additional_charges.push({
              id: Math.floor(Math.random() * 999999) + 1,
              additional_charge_id: x.additional_charge_id,
              quantity: x.quantity,
              unit: x.additional_charge.unit.name,
              unit_id: x.additional_charge.unit_id,
              additional_charges: [{
                additional_charge_id: x.additional_charge_id,
                name: x.additional_charge.name,
              }],
            })
          })
        this.setState({ additional_charges: [...this.state.additional_charges, ...product_additional_charges] })
      }

    } catch (err) {
      this.setState({ showProductSearchAsModal: false, modalProductSearch: null })
      graphQLErrorsToToast(err)
    }

  }

  deleteProduct = id => {
    if ( ! this.props.waybill_id) {
      this.setState({ products: this.state.products.filter(x => x.id !== id) })
    } else {
      let products = this.state.products
      if ( ! products.find(x => x.id === id).invoice_product_id) {
        this.setState({ products: this.state.products.filter(x => x.id !== id) })
      } else {
        products.find(x => x.id === id).delete = ! products.find(x => x.id === id).delete
        this.setState({ products })
      }
    }
  }

  deleteAdditionalCharge = id => {
    if ( ! this.props.waybill_id) {
      this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
    } else {
      let additional_charges = this.state.additional_charges
      if ( ! additional_charges.find(x => x.id === id).invoice_additional_charge_id) {
        this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })
      } else {
        additional_charges.find(x => x.id === id).delete = ! additional_charges.find(x => x.id === id).delete
        this.setState({ additional_charges })
      }
    }
  }

  additionalChargeSearchModalCallback = async record => {
    let id = this.state.modalAdditionalChargeSearch
    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    try {
      const result = await apolloClient.query({
        query: ADDITIONAL_CHARGE_READ_QUERY,
        variables: { additional_charge_id: record.additional_charge_id },
      })
      if ( ! result.data.additionalCharge)
        return toast(false, this.props.langData['x not found']('Additional charge'))

      let additionalChargePure = result.data.additionalCharge
      additional_charge.additional_charge_id = null
      additional_charge.unit = additionalChargePure.unit.name
      additional_charge.additional_charge_id = additionalChargePure.additional_charge_id
      additional_charge.unit_id = additionalChargePure.unit_id

      additional_charge.additional_charges = [{
        additional_charge_id: additionalChargePure.additional_charge_id,
        name: additionalChargePure.name,
      }]

      this.setState({
        additional_charges,
        modalAdditionalChargeSearch: null,
        increment: result.data.userInformation.settings.increment,
      })

    } catch (err) {
      this.setState({ modalAdditionalChargeSearch: null })
      graphQLErrorsToToast(err)
    }
  }

  rowItemClick = record => {
    this.setState({ ['modal' + ((typeof record.product_id !== 'undefined') ? 'Product' : 'AdditionalCharge')]: record })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Query
        query={this.props.transaction_id ? INVOICE_READ_QUERY(this.props.type === 'sending' ? 'sales' : 'purchase') : READ_QUERY(this.props.type)}
        fetchPolicy="network-only"
        variables={! this.props.waybill_id ? { transaction_id: this.props.transaction_id } : { waybill_id: this.props.waybill_id }}
        skip={ ! this.props.waybill_id && ! this.props.transaction_id}
      >
        {({ loading: loadingQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            if (this.props.waybill_id && ! data.waybill) {
              toast(false, this.props.langData['x not found']('Waybill'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')

            } else {
              if (data) {
                this.setState({
                  data: cloneDeep(data.waybill),
                  customerList: [{
                    name: data.waybill.customer.name,
                    customer_id: data.waybill.customer_id,
                  }],
                  increment: data.userInformation.settings.increment,
                })
              }
              this.setState({
                initialize: true,
              })

              if (this.props.waybill_id || this.props.transaction_id) {
                if (this.props.transaction_id) {
                  const isDeliveryAddress = data.waybill.waybill_delivery_country || data.waybill.waybill_delivery_town || data.waybill.waybill_delivery_city || data.waybill.waybill_delivery_address || data.waybill.delivery_postal_code

                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'waybill[waybill_city]': isDeliveryAddress ? data.waybill.waybill_delivery_city : data.waybill.waybill_invoice_city,
                      'waybill[waybill_country]': isDeliveryAddress ? data.waybill.waybill_delivery_country : data.waybill.waybill_invoice_country,
                      'waybill[waybill_town]': isDeliveryAddress ? data.waybill.waybill_delivery_town : data.waybill.waybill_invoice_town,
                      'waybill[waybill_address]': isDeliveryAddress ? data.waybill.waybill_delivery_address : data.waybill.waybill_invoice_address,
                      'waybill[waybill_postal_code]': isDeliveryAddress ? data.waybill.delivery_postal_code : data.waybill.invoice_postal_code,
                    })
                  }, 10)
                } else if (this.props.waybill_id) {
                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'waybill[waybill_city]': data.waybill.waybill_city,
                      'waybill[waybill_country]': data.waybill.waybill_country,
                      'waybill[waybill_town]': data.waybill.waybill_town,
                      'waybill[waybill_postal_code]': data.waybill.waybill_postal_code,
                      'waybill[customer_id]': data.waybill.customer_id,
                      'waybill[waybill_address]': data.waybill.waybill_address,
                    })
                  }, 10)
                }

                setTimeout(() => {
                  this.props.form.setFieldsValue({
                    'waybill[date]': moment(data.waybill.date),
                    'waybill[sending_date]': moment(data.waybill.sending_date).add(1, 'minutes'),
                    'waybill[no]': data.waybill.no,
                    'waybill[customer_id]': data.waybill.customer_id,
                    'waybill[explanation]': data.waybill.explanation,
                  })
                }, 10)

                if (data.waybill.products && data.waybill.products.length) {
                  let products = cloneDeep(data.waybill.products)
                  products.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.products = [
                      {
                        product_id: x.product_id,
                        name: x.product.name,
                      },
                    ]
                    x.unit = this.props.transaction_id ? x.product.unit.name : x.unit.name
                    x.unit_id = this.props.transaction_id ? x.product.unit_id : x.unit_id
                    delete x.product
                  })
                  this.setState({ products })
                }

                if (data.waybill.additional_charges && data.waybill.additional_charges.length) {
                  let additional_charges = cloneDeep(data.waybill.additional_charges)
                  additional_charges.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.additional_charges = [
                      {
                        additional_charge_id: x.additional_charge_id,
                        name: x.additional_charge.name,
                      },
                    ]
                    x.unit = this.props.transaction_id ? x.additional_charge.unit.name : x.unit.name
                    x.unit_id = this.props.transaction_id ? x.additional_charge.unit_id : x.unit_id
                    delete x.additional_charge
                  })
                  this.setState({ additional_charges })
                }
              }
            }
          }

          return (
            <Mutation
              mutation={! this.props.waybill_id ? WAYBILL_CREATE_QUERY(this.props.type) : WAYBILL_UPDATE_QUERY(this.props.type)}
            >
              {(mutate, { loading: loadingMutation }) => (
                <div>
                  <Card
                    title={
                      <span>
												<Icon type={this.props.waybill_id ? 'edit' : 'file-add'}/>&nbsp;
                        {
                          this.props.type === 'sending' && this.props.waybill_id ? this.props.langData['Sending Waybill Update'] :
                            this.props.type === 'sending' ? this.props.langData['New Sending Waybill'] :
                              this.props.type === 'incoming' && this.props.waybill_id ? this.props.langData['Incoming Waybill Update'] :
                                this.props.langData['New Incoming Waybill']
                        }
											</span>
                    }
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                  >
                    <Form hideRequiredMark={true} className="form-label-default">

                      <div className="form-block">

                        <Form.Item label={(
                          <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          <Input.Group compact style={{ display: 'flex' }}>
                            {getFieldDecorator('waybill[customer_id]', {
                              rules: [{ required: true }],
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                showSearch
                                style={{ width: '100%' }}
                                suffixIcon={<Icon type="caret-down"/>}
                                onSearch={this.searchCustomer}
                              >
                                {this.state.customerList.map((x, k) => {
                                  return (
                                    <Select.Option
                                      onClick={this.customerSearchModalCallback}
                                      value={x.customer_id}
                                      key={k}
                                    >
                                      {x.name}
                                    </Select.Option>
                                  )
                                })}
                              </Select>,
                            )}
                            <Button size="large" icon="search"
                                    onClick={() => this.setState({ modalCustomerSearch: true })}></Button>
                          </Input.Group>
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="number"/><p>{this.props.langData['Waybill No']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('waybill[no]', { validateTrigger: false })(
                            <Input size="large"/>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('waybill[date]', {
                            rules: [{ required: true }],
                            validateTrigger: false,
                            initialValue: moment(),
                          })(
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              showTime={{ format: 'HH:mm' }}
                              size="large"
                              className="w-100"
                              suffixIcon={<Icon type="calendar" theme="filled"/>}
                            />,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="calendar"/><p>{this.props.langData['Date Of Shipment']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('waybill[sending_date]', {
                            initialValue: moment(this.props.form.getFieldValue('waybill[date]')).add(5, 'minutes'),
                            rules: [{ required: true }],
                            validateTrigger: false,
                          })(
                            <DatePicker
                              format="DD.MM.YYYY HH:mm"
                              size="large"
                              className="w-100"
                              showTime={{ format: 'HH:mm' }}
                              suffixIcon={<Icon type="calendar" theme="filled"/>}
                              disabledDate={current => current < this.props.form.getFieldValue('waybill[date]')}
                            />,
                          )}
                        </Form.Item>

                        <Divider/>

                        <Form.Item hasFeedback label={(
                          <span><Icon
                            type="environment-o"/><p>{this.props.langData['Waybill'] + ' ' + this.props.langData['Country']}</p></span>)}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('waybill[waybill_country]', {
                            rules: [{ max: 100 }],
                            validateTrigger: false,
                          })(
                            <Select
                              size="large"
                              suffixIcon={<Icon type="caret-down"/>}
                              showSearch
                              allowClear
                              filterOption={selectFilterLowerCase}
                              onSelect={val => this.setState({ invoice_country: val })}
                              maxLength={100}
                            >
                              {this.props.langData.countries.map((x, k) => {
                                return <Select.Option value={x} key={k}>{x}</Select.Option>
                              })}
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon
                            type="environment-o"/><p>{this.props.langData['Waybill'] + ' ' + this.props.langData['City'] + '/' + this.props.langData['Town'] + '/' + this.props.langData['Postal Code']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          <Input.Group size="large">
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator('waybill[waybill_city]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                                      <Select
                                        size="large"
                                        className="w-100"
                                        showSearch
                                        allowClear
                                        suffixIcon={<Icon type="caret-down"/>}
                                        placeholder={this.props.langData['City']}
                                        filterOption={selectFilterLowerCase}
                                        onChange={val => this.setState({ invoice_city: val })}
                                        maxLength={100}
                                      >
                                        {this.props.langData.cityTown.map((x, k) => {
                                          return <Select.Option value={x.city} key={k}>{x.city}</Select.Option>
                                        })}
                                      </Select>
                                    )
                                    : <Input maxLength={100}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator('waybill[waybill_town]', {
                                  rules: [{ max: 100 }],
                                  validateTrigger: false,
                                })(
                                  (this.props.lang === 'tr' && this.state.invoice_country === 'Türkiye') ? (
                                    <Select
                                      size="large"
                                      className="w-100"
                                      showSearch
                                      allowClear
                                      placeholder={this.props.langData['Town']}
                                      suffixIcon={<Icon type="caret-down"/>}
                                      filterOption={selectFilterLowerCase}
                                      maxLength={100}
                                    >
                                      {
                                        this.props.langData.cityTown.filter(x => x.city === this.state.invoice_city).length && (
                                          this.props.langData.cityTown.filter(x => x.city === this.state.invoice_city)[0].towns.map((x, k) => {
                                            return <Select.Option value={x} key={k}>{x}</Select.Option>
                                          })
                                        )
                                      }
                                    </Select>
                                  ) : <Input maxLength={100}/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator('waybill[waybill_postal_code]')(
                                  <Input
                                    size="large"
                                    style={{ width: '100%' }}
                                    maxLength={10}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          </Input.Group>
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon
                            type="environment-o"/><p>{this.props.langData['Waybill'] + ' ' + this.props.langData['Address']}</p></span>
                        )}
                                   {...formItemLayout}
                        >
                          {getFieldDecorator('waybill[waybill_address]', {
                            rules: [{ max: 250 }],
                            validateTrigger: false,
                          })(
                            <Input.TextArea
                              size="large"
                              autoSize={{ minRows: 2, maxRows: 5 }}
                            />,
                          )}
                        </Form.Item>

                        <Divider/>

                        <div className="form-block">
                          <Form.Item hasFeedback {...formItemLayoutNoLabel} >
                            {getFieldDecorator('waybill[explanation]', {
                              rules: [{ max: 500 }],
                              validateTrigger: false,
                            })(
                              <Input.TextArea
                                size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                                placeholder={this.props.langData['Explanation']}
                                maxLength={500}
                              />,
                            )}
                          </Form.Item>
                        </div>

                        {this.state.modalProduct && (
                          <InvoiceProduct
                            editProduct={this.editProduct}
                            product={this.state.modalProduct}
                            modalClose={this.modalClose}
                            selectedCurrency={this.state.selectedCurrency}
                            type={this.state.type}
                            currency={this.state.currency}
                          />
                        )}

                        {this.state.modalAdditionalCharge && (
                          <InvoiceAdditionalCharge
                            editAdditionalCharge={this.editAdditionalCharge}
                            additional_charge={this.state.modalAdditionalCharge}
                            modalClose={this.modalClose}
                            selectedCurrency={this.state.selectedCurrency}
                            type={this.state.type}
                            currency={this.state.currency}
                          />
                        )}

                        {this.state.modalCustomerSearch && (
                          <CustomerSearchModal
                            callback={this.customerSearchModalCallback}
                            modalClose={() => this.setState({ modalCustomerSearch: false })}
                          />
                        )}

                        {this.state.showProductSearchAsModal && (
                          <ProductSearchModal
                            langData={this.props.langData}
                            callback={this.productSearchModalCallback}
                            modalClose={() => this.setState({
                              showProductSearchAsModal: false,
                              modalProductSearch: false,
                            })}
                          />
                        )}

                        {this.state.modalAdditionalChargeSearch && (
                          <AdditionalChargeSearchModal
                            callback={this.additionalChargeSearchModalCallback}
                            modalClose={() => this.setState({ modalAdditionalChargeSearch: false })}
                          />
                        )}
                      </div>
                    </Form>
                  </Card>

                  <Card
                    bodyStyle={{ padding: 0, marginTop: 20 }}
                    bordered={false}
                  >
                    <Divider>
                      <Icon
                        type="star-o"/> {this.props.langData['Products']} / {this.props.langData['Additional Charges']}
                    </Divider>

                    <Table
                      columns={this.columns}
                      rowKey={record => record.id}
                      dataSource={this.state.products.concat(this.state.additional_charges)}
                      pagination={false}
                      id="table"
                      rowClassName="white"
                      scroll={{ x: 'max-content' }}
                    />
                    <Col xs={24} sm={6} md={12} xl={12}>
                      <Dropdown
                        overlay={this.menu}
                        trigger={['click']}
                        disabled={
                          this.state.products.filter(x => x.product_id === null).length ||
                          this.state.additional_charges.filter(x => x.additional_charge_id === null).length
                        }
                      >
                        <Button
                          style={{ marginLeft: 16, marginTop: 25, marginBottom: 14 }}
                          icon="plus-circle-o"
                        >
                          {this.props.langData['Add']}
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col xs={24} sm={18} md={12} xl={12}>
                      <Form.Item className="form-block">
                        { ! this.props.transaction_id && <Button
                          loading={this.state.isSubmit}
                          size="large" className="default-button success"
                          onClick={() => this.onSubmitAndNew(mutate)}
                          style={{ float: 'right' }}
                        >
                          {this.props.langData['Save & New']}
                        </Button>}
                        <Button
                          loading={this.state.isSubmit}
                          type="primary" size="large" className="default-button"
                          style={{ marginRight: 5 }}
                          onClick={() => this.onSubmit(mutate)}
                        >
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Card>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

WaybillForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string,
  lang: PropTypes.string,
  waybill_id: PropTypes.number,
  sizeWidth: PropTypes.number,
  history: PropTypes.object,
  transaction_id: PropTypes.number,
}

export default connect(mapStateToProps, null)(WaybillForm)
