import React from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Col, Icon, Popover, Row } from 'antd'

function CheckboxedDropdown(props) {

  return (
    <Popover
      content={
        <Checkbox.Group
          onChange={props.onChange}
          style={{ width: props.width ? props.width : 100 }}
        >
          <Row>
            {props.options.map((option) => {
              return (
                <Col key={option.value} span={24}>
                  <Checkbox
                    value={option.value}
                    checked={props.selectedValues.includes(option.value)}
                  >
                    {option.label}
                  </Checkbox>
                </Col>
              )
            })}
          </Row>
        </Checkbox.Group>
      }
      placement="bottom"
      trigger={['click']}
    >
      <Button>{props.langData['Choose']} <Icon type="caret-down" style={{ fontSize: 11 }}/></Button>
    </Popover>
  )

}

CheckboxedDropdown.propTypes = {
  langData: PropTypes.object,

  width: PropTypes.number,
  options: PropTypes.array,
  selectedValues: PropTypes.array,

  onChange: PropTypes.func,
}

export default CheckboxedDropdown
