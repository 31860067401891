import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InvoiceForm from './../InvoiceForm/InvoiceForm'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class NewCustomerInvoice extends Component {

  id
  type

  typeEnglishName = {
    'satis': 'sales',
    'alis': 'purchase',
  }

  constructor(props) {
    super(props)
    this.id = parseInt(props.match.params.id)
    this.type = props.match.params.type
    if (this.typeEnglishName[this.type]) {
      this.type = this.typeEnglishName[this.type]
    }
  }

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.INVOICE_TYPE_NEW, {
      type: this.type,
      id: this.id,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
    this.id = null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ! (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
  }

  render() {
    const WrappedNewCustomerInvoiceForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(InvoiceForm)
    return <WrappedNewCustomerInvoiceForm id={this.id} type={this.type} {...this.props}></WrappedNewCustomerInvoiceForm>
  }
}

NewCustomerInvoice.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerInvoice)
