import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Icon, Button, Dropdown, Menu, Divider, Tag, Tabs, Table, Drawer, Modal, Form } from 'antd'
import { toast, confirm, graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { Route, Link, Switch as RouterSwitch, generatePath } from 'react-router-dom'
import { deleteData } from '../../../helpers/trait'
import { compile } from 'path-to-regexp'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import routePaths from './../../../localize/routePaths'
import NewSubProduct from './../NewSubProduct/NewSubProduct'
import SubProductUpdate from './../SubProductUpdate/SubProductUpdate'
import DefaultAttributeGroupForm from './../DefaultAttributeGroupForm/DefaultAttributeGroupForm'
import { getCurrencyWithCode } from '../../../data/Currency'
import randomColor from 'randomcolor'
import routes from '../../../routes'

const TabPane = Tabs.TabPane

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product' } })
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const PRODUCT_READ_QUERY = (
  gql`
      query($product_id: Int!, $paging: Paging, $filter: ProductTransactionFilterBase){
          product(product_id: $product_id) {
              product_id
              name
              code
              category_id
              stock_tracking
              stock
              unit_id
              name_on_invoice
              supplier_product_code
              unit {
                  name
              }
              selling_price
              selling_currency
              buying_price
              buying_currency
              vat
              barcode
              has_sub_product
              sub_products{
                  product_id
                  stock
                  unit_id
                  barcode
                  unit{
                      name
                  }
                  selling_price
                  selling_currency
                  buying_price
                  stock_tracking
                  buying_currency
                  attributes {
                      attribute_id
                      attribute_group_id
                      title
                      code
                      is_first
                  }
              }
              stock_opening {
                  type
                  product_id
                  quantity
                  date
              }
              additional_charges {
                  product_additional_charge_id
                  additional_charge_id
                  buying_price
                  selling_price
                  quantity
                  vat
                  additional_charge{
                      name
                      unit {
                          name
                      }
                  }
              }
              buying_currency
          }

          defaultAttributeGroup(product_id: $product_id){
              attribute_group_id
              is_first
          }

          productDetailTransactionList(product_id: $product_id, paging: $paging, filter: $filter){
              transactions{
                  __typename
                  ... on StockOpening {
                      product_id
                      quantity
                      type
                      date
                      product{
                          unit{
                              name
                          }
                      }
                  }

                  __typename
                  ... on SalesInvoice {
                      transaction_id
                      code
                      products{
                          product_id
                          quantity
                          product{
                              unit{
                                  name
                              }
                          }
                      }
                      customer{
                          name
                      }
                      date
                  }

                  __typename
                  ... on PurchaseInvoice {
                      transaction_id
                      products{
                          product_id
                          quantity
                          product{
                              unit{
                                  name
                              }
                          }
                      }
                      customer{
                          name
                      }
                      date
                  }

                  __typename
                  ... on ReturnSalesInvoice {
                      transaction_id
                      products {
                          invoice_product_id
                          product_id
                          quantity
                          amount
                          total
                          vat
                          vat_total
                          grand_total
                          product {
                              unit {
                                  name
                              }
                          }
                      }
                      customer {
                          name
                      }
                      date
                  }

                  __typename
                  ... on ReturnPurchaseInvoice {
                      transaction_id
                      products {
                          invoice_product_id
                          product_id
                          quantity
                          amount
                          total
                          vat
                          vat_total
                          grand_total
                          product {
                              unit {
                                  name
                              }
                          }
                      }
                      customer {
                          name
                      }
                      date
                  }
              }
              page_info{
                  total_count
              }
          }
          settings {
              invoice_settings {
                  product_invoice_name_status
              }
          }
      }
  `
)

class ProductDetail extends Component {

  product_id = null
  isSubProduct = false

  state = {
    data: null,
    defaultAttributeGroup: [],
    transactions: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
      style: { padding: '0px 20px' },
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    more: false,
    sub_product: null,
    refetch: null,
    isSubProduct: false,
    product_invoice_name_status: false,
  }

  columns = [
    {
      title: this.props.langData['Transaction Type'],
      dataIndex: '__typename',
      width: '30%',
      key: 'type',
      render: (value, record) => {
        if (value === 'ReturnSalesInvoice')
          return <Tag color="#AFB42B" style={{ marginRight: 0 }}>{this.props.langData['Return Sales Invoice']}</Tag>
        if (value === 'ReturnPurchaseInvoice')
          return <Tag color="#FF6D00" style={{ marginRight: 0 }}>{this.props.langData['Return Purchase Invoice']}</Tag>
        if (value === 'SalesInvoice')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Output']}</Tag>
        if (value === 'PurchaseInvoice')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Input']}</Tag>
        if (value === 'StockOpening')
          return <Tag color={record.type ? '#1890ff' : '#ff4d4f'}
                      style={{ marginRight: 0 }}>{this.props.langData['Opening Stock']}</Tag>

      },
    },
    {
      title: this.props.langData['Customer'],
      dataIndex: 'customer.name',
      width: '35%',
      key: 'customer_name',
      render: value => value,
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      key: 'date',
      width: '20%',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY'),
    },
    {
      title: this.props.langData['Quantity'],
      className: 'text-right',
      key: 'quantity',
      width: '15%',
      dataIndex: 'quantity',
      render: (value, record) =>
        `${record.quantity !== undefined ? record.quantity : record.products.filter(x => x.product_id === this.state.data.product_id)[0] ? record.products.filter(x => x.product_id === this.state.data.product_id)[0].quantity : ''} ${record.product !== undefined ? record.product.unit.name : record.products.filter(x => x.product_id === this.state.data.product_id)[0] ? record.products.filter(x => x.product_id === this.state.data.product_id)[0].product.unit.name : ''}`,
    },
  ]

  subProductColumns = [
    {
      title: '',
      key: 'edit',
      align: 'center',
      // width: '7%',
      render: record => (
        <Link to={'/' + compile(this.props.langData.route['product/detail/:id/sub-product/update/:sub_product_id'])({
          id: this.product_id,
          sub_product_id: record.product_id,
        })}>
          <Icon type="edit" theme="filled" style={{ cursor: 'pointer' }}/>
        </Link>
      ),
    },
    {
      title: this.props.langData['Attributes'],
      dataIndex: 'attributes',
      // width: '35%',
      key: 'attributes',
      render: value => (
        <div>
          {value.sort(x => x.is_first ? 1 : -1).map(x => <Tag
            color={randomColor({ format: 'hsl', hue: 'blue', luminosity: 'dark' })}
            key={x.code}>{x.title}</Tag>)}
        </div>
      ),
    },
    {
      title: this.props.langData['Stock'],
      dataIndex: 'stock',
      // width: '20%',
      key: 'stock',
      render: (value, record) => record.stock_tracking ? value + ' ' + record.unit.name : this.props.langData['Unpursued'],
    },
    {
      title: this.props.langData['Buying Price'],
      dataIndex: 'buying_price',
      key: 'buying_price',
      align: 'right',
      // width: '15%',
      render: (value, data) => `${(value === null ? 0 : toLocaleString(value)) + (data.buying_currency ? ' ' + getCurrencyWithCode(data.buying_currency).symbol : '')}`,
    },
    {
      title: this.props.langData['Selling Price'],
      key: 'selling_price',
      // width: '15%',
      align: 'right',
      dataIndex: 'selling_price',
      render: (value, data) => `${(value === null ? 0 : toLocaleString(value)) + (data.selling_currency ? ' ' + getCurrencyWithCode(data.selling_currency).symbol : '')}`,
    },
    {
      title: '',
      key: 'delete',
      align: 'center',
      // width: '7%',
      render: record => <Icon type="delete" theme="filled" onClick={() => this.delete(record.product_id)}
                              style={{ cursor: 'pointer' }}/>,
    },
  ]


  product_additional_columns = [
    {
      title: this.props.langData['Product'],
      dataIndex: 'product.name|additional_charge.name',
      render: (value, record) => record.product ? record.product.name : record.additional_charge.name,
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) => {
        return (value ? value : 1) + ' ' + (typeof record.product !== 'undefined' ? (record.product.unit ? record.product.unit.name : null) : (record.additional_charge.unit ? record.additional_charge.unit.name : null))
      },
    },
    {
      title: this.props.langData['Amount'],
      dataIndex: 'selling_price',
      key: 'selling_price',
      className: 'text-right',
      render: (value, record) => value ? `${toLocaleString(record.selling_price) + ' ' + getCurrencyWithCode(this.state.data.selling_currency).symbol}` : '-',
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      key: 'vat',
      className: 'text-right',
      render: value => value ? value + '%' : '-',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'selling_price',
      key: 'selling_price_total',
      className: 'text-right',
      render: (value, record) => value ? `${toLocaleString(value + ((record.selling_price) * record.vat) / 100) + ' ' + getCurrencyWithCode(this.state.data.selling_currency).symbol}` : '-',
    },
  ]

  constructor(props) {
    super(props)

    this.product_id = parseInt(props.match.params.id)
    this.deleteData = deleteData('product', 'product_id').bind(this)
    this.deleteOpeningStockData = deleteData('stockOpening', 'product_id', false).bind(this)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  handleTableChange = pagination => {
    if (pagination.pageSize)
      this.setState({
        paging: {
          offset: (pagination.current * pagination.pageSize) - pagination.pageSize,
          limit: (pagination.current * pagination.pageSize),
        },
      })
  }

  edit = () => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['product/update/:id'])({ id: this.product_id }),
    )
  }

  delete = sub_product_id => {
    if (sub_product_id)
      this.deleteData = deleteData('product', 'product_id', false, this.state.refetch).bind(this)
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteData(sub_product_id ? sub_product_id : this.product_id)
    })
  }

  deleteOpeningStock = () => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.deleteOpeningStockData(this.product_id)
      this.state.refetch()
    })
  }

  defaultAttributeGroup = () => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['product/detail/:id/set-default-attribute-groups'])({ id: this.product_id }),
    )
  }

  toggleMore = () => {
    this.setState({
      more: ! this.state.more,
    })
  }

  menuOnClick = item => {
    this[item.key]()
  }

  rowItemClickSub = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['product/detail/:id'])({ id: record.product_id }),
    )
  }

  rowItemClick = record => {
    if (record.__typename === 'StockOpening') {
      this.props.history.push(
        '/' + compile(this.props.langData.route['product/update/:id'])({ id: record.product_id }),
      )
    } else if (record.__typename === 'ReturnSalesInvoice' || record.__typename === 'ReturnPurchaseInvoice') {
      if (record.__typename === 'ReturnSalesInvoice') {
        this.props.history.push(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
          type: record.__typename === 'ReturnSalesInvoice' ? 'salesDetail' : 'purchaseDetail',
          id: record.transaction_id,
        }))
      } else {
        this.props.history.push(generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
          id: record.transaction_id,
        }))
      }
    } else {
      this.props.history.push(
        generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
          type: this.props.langData[record.__typename === 'SalesInvoice' ? 'sales' : 'purchase'],
          id: record.transaction_id,
        }),
      )
    }
  }

  render() {
    const menu = refetch => (
      <Menu className="menu-default" onClick={item => this.menuOnClick(item, refetch)}>
        { ! this.isSubProduct && <Menu.Item key="edit" icon="edit">
          <span> <Icon type="edit"/> {this.props.langData['Edit']}</span>
        </Menu.Item>}
        {(this.state.data.has_sub_product && ! this.state.data.sub_products.length) &&
          <Menu.Item key="defaultAttributeGroup">
            <span> <Icon type="ordered-list"/>{this.props.langData['Change Default Attribute Groups']} </span>
          </Menu.Item>}
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']}</span>
        </Menu.Item>
        {(this.state.transactions && this.state.transactions.filter(x => x.__typename === 'StockOpening').length) &&
          <Menu.Item key="deleteOpeningStock">
            <span><Icon type="delete"/> {this.props.langData['Delete Opening Stock']}</span>
          </Menu.Item>}
      </Menu>
    )

    return (
      <Query
        query={PRODUCT_READ_QUERY}
        fetchPolicy="network-only"
        variables={{
          product_id: this.product_id,
          filter: { AND: [{ product_id: { eq: this.product_id } }] },
          paging: this.state.paging,
        }}
      >
        {({ loading: loadingReadQuery, data, error, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingReadQuery) {
            if ( ! data.product) {
              toast(false, this.props.langData['x not found']('Product'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data && ! this.state.transactions) {
              this.props.breadcrumb_add(data.product.name, compile('/' + this.props.langData.route['product/detail/:id'])({ id: this.product_id }))

              this.setState({
                data: cloneDeep(data.product),
                transactions: cloneDeep(data.productDetailTransactionList.transactions),
                pagination: {
                  ...(this.state.pagination),
                  total: data.productDetailTransactionList.page_info.total_count,
                },
                defaultAttributeGroup: cloneDeep(data.defaultAttributeGroup),
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })
            } else if (JSON.stringify(this.state.transactions) !== JSON.stringify(data.productDetailTransactionList.transactions)) {
              this.setState({
                transactions: cloneDeep(data.productDetailTransactionList.transactions),
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })
            } else if (JSON.stringify(this.state.data) !== JSON.stringify(data.product))
              this.setState({
                data: cloneDeep(data.product),
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })
            else if (JSON.stringify(this.state.defaultAttributeGroup) !== JSON.stringify(data.defaultAttributeGroup))
              this.setState({
                defaultAttributeGroup: cloneDeep(data.defaultAttributeGroup),
                product_invoice_name_status: data.settings.invoice_settings.product_invoice_name_status,
              })
          }

          this.setState({
            refetch,
          })

          return (
            this.state.data && <div>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={14} lg={13} xl={13} style={{ marginBottom: 16 }}>
                  <Card
                    bodyStyle={{ padding: '0px' }}
                    title={<span><Icon type="idcard"/> &nbsp;{this.props.langData['Product Detail']}</span>}
                    bordered={false}
                    loading={ ! this.state.data}
                    extra={
                      <Dropdown overlay={menu(refetch)} trigger={['click']}>
                        <Button>
                          {this.props.langData['Actions']} <Icon type="down"/>
                        </Button>
                      </Dropdown>
                    }
                  >

                    { ! this.state.data && <div style={{ width: '100%', height: 150 }}></div>}

                    {this.state.data && <React.Fragment>
                      <div className="form-block label-default">
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                            <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                            {this.state.data.name}
                          </Col>
                        </Row>

                        {this.state.product_invoice_name_status &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="tags"/><p>{this.props.langData['Invoice Name']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.name_on_invoice ? this.state.data.name_on_invoice : '-'}
                            </Col>
                          </Row>
                        }

                        {this.state.data.category &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.category.name}
                            </Col>
                          </Row>
                        }

                        {this.state.data.code &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.code}
                            </Col>
                          </Row>
                        }

                        {this.state.data.supplier_product_code &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon
                                type="paper-clip"/><p>{this.props.langData['Supplier Product Code']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.supplier_product_code ? this.state.data.supplier_product_code : '-'}
                            </Col>
                          </Row>
                        }

                        {this.state.data.unit &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="paper-clip"/><p>{this.props.langData['Unit']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.unit.name}
                            </Col>
                          </Row>
                        }

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                            <span><Icon type="paper-clip"/><p>{this.props.langData['Stock Tracking']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                            {this.props.langData[this.state.data.stock_tracking ? 'Open' : 'Closed']}
                          </Col>
                        </Row>

                        {this.state.data.stock_tracking &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="paper-clip"/><p>{this.props.langData['Stock']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {this.state.data.stock + ' ' + (this.state.data.unit.name || '')}
                            </Col>
                          </Row>
                        }

                        {(this.state.data.buying_price && this.state.data.buying_currency) &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Buying Price']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {toLocaleString(this.state.data.buying_price) + ' ' + getCurrencyWithCode(this.state.data.buying_currency).symbol}
                            </Col>
                          </Row>
                        }

                        {(this.state.data.selling_price && this.state.data.selling_currency) &&
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                              <span><Icon type="pay-circle-o"/><p>{this.props.langData['Selling Price']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                              {toLocaleString(this.state.data.selling_price) + ' ' + getCurrencyWithCode(this.state.data.selling_currency).symbol}
                            </Col>
                          </Row>
                        }

                      </div>
                      <Divider>
                        <Button size="small" type="dashed" onClick={this.toggleMore}>{
                          ! this.state.more ? this.props.langData['More'] : this.props.langData['Close']
                        }
                        </Button>
                      </Divider>

                      {this.state.more &&
                        <div>
                          <div className="form-block label-default">

                            {Object.keys(this.state.data).includes('vat') &&
                              <Row gutter={24} style={{ marginBottom: 16 }}>
                                <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                                  <span><Icon type="paper-clip"/><p>{this.props.langData['Vat']}</p></span>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                                  {this.state.data.vat ? this.state.data.vat + '%' : '-'}
                                </Col>
                              </Row>
                            }

                            {this.state.data.barcode &&
                              <Row gutter={24} style={{ marginBottom: 16 }}>
                                <Col xs={24} sm={24} md={14} lg={10} xl={8}>
                                  <span><Icon type="paper-clip"/><p>{this.props.langData['Barcode']}</p></span>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={14} xl={16}>
                                  {this.state.data.barcode}
                                </Col>
                              </Row>
                            }

                          </div>
                        </div>
                      }

                    </React.Fragment>}
                  </Card>

                  { ! this.state.data.has_sub_product && <Card
                    bodyStyle={{ padding: 0, marginTop: 20 }}
                    bordered={false}
                  >
                    <Tabs size="large">
                      <TabPane tab={this.props.langData['Invoice Transactions']} key="invoice_transactions">
                        <Table
                          columns={this.columns}
                          rowKey={Math.floor(Math.random() * 999999) + 1}
                          dataSource={this.state.transactions}
                          loading={ ! this.state.transactions}
                          pagination={this.state.pagination}
                          onChange={this.handleTableChange}
                          onRow={record => ({
                            onClick: () => this.rowItemClick(record),
                          })}
                          rowClassName="white"
                          scroll={{ x: 700, y: 500 }}
                        />
                      </TabPane>
                    </Tabs>
                  </Card>}
                  {(this.state.data && this.state.data.has_sub_product) && <Card
                    style={{ padding: 0, marginTop: 20 }}
                    bodyStyle={{ padding: 0 }}
                    loading={loadingReadQuery}
                    bordered={true}
                    title={<span><Icon type="apartment"/> &nbsp;{this.props.langData['Sub Products']}</span>}
                    extra={
                      <Link
                        to={generatePath(routes.SUB_PRODUCT_NEW, { id: this.product_id })}>
                        <Button icon="plus-circle-o" type="primary">{this.props.langData['Add New One']}</Button>
                      </Link>
                    }
                  >
                    <Table
                      columns={this.subProductColumns}
                      rowKey={record => record.product_id}
                      dataSource={this.state.data.sub_products || []}
                      pagination={this.state.pagination}
                      onRow={record => ({
                        onClick: () => {
                          this.props.history.push(
                            '/' + compile(this.props.langData.route['product/detail/:id'])({ id: record.product_id }),
                          )

                          this.product_id = record.product_id
                          this.isSubProduct = true
                          this.setState({
                            data: null,
                          })
                        },
                      })}
                      loading={ ! (this.state.data && this.state.data.has_sub_product)}
                      rowClassName="white"
                    />
                  </Card>}
                </Col>
                <Col xs={24} sm={24} md={10} lg={11} xl={11} style={{ marginBottom: 16 }}>

                  {this.state.data.additional_charges.length > 0 && <Card
                    bodyStyle={{ padding: 0 }}
                    bordered={false}
                  >
                    <Tabs size="large">
                      <TabPane tab={this.props.langData['Additional Charges']} key="invoice_transactions">
                        <Table
                          columns={this.product_additional_columns}
                          rowKey={record => record.invoice_product_id ? record.product_additional_charge_id + 'p' : record.product_additional_charge_id + 'a'}
                          dataSource={this.state.data ? this.state.data.additional_charges : null}
                          loading={ ! this.state.data}
                          pagination={this.state.pagination}
                          rowClassName="white"
                          scroll={{ x: 'max-content' }}
                        >
                        </Table>
                      </TabPane>
                    </Tabs>
                  </Card>}

                </Col>
                <RouterSwitch>
                  <Route exact path={routes.SUB_PRODUCT_NEW} render={props => {
                    if ( ! this.state.defaultAttributeGroup.length) {
                      props.history.push(
                        '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                      )
                      toast(false, this.props.langData['Default attribute groups is not defined.'])
                      return null
                    }

                    return (
                      <Drawer
                        title={<span><Icon type="file-add"/> &nbsp;{this.props.langData['New Sub Product']}</span>}
                        bodyStyle={{ padding: 8 }}
                        visible={true}
                        width={this.props.sizeWidth > 756 ? '40%' : '100%'}
                        footer={null}
                        onClose={() => {
                          props.history.push(
                            '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                          )
                        }}
                      >
                        <NewSubProduct {...props} refetch={() => {
                          props.history.push(
                            '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                          )
                          refetch()
                        }}/>
                      </Drawer>
                    )
                  }}/>

                  <Route exact path={routePaths['product/detail/:id/sub-product/update/:sub_product_id']}
                         render={props => (
                           <Drawer
                             title={<span><Icon
                               type="file-add"/> &nbsp;{this.props.langData['Sub Product Update']}</span>}
                             bodyStyle={{ padding: 8 }}
                             visible={true}
                             width={this.props.sizeWidth > 756 ? '40%' : '100%'}
                             footer={null}
                             onClose={() => {
                               props.history.push(
                                 '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                               )
                             }}
                           >
                             <SubProductUpdate refetch={() => {
                               props.history.push(
                                 '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                               )
                               refetch()
                             }} {...props} />
                           </Drawer>
                         )}/>

                  <Route exact path={routePaths['product/detail/:id/set-default-attribute-groups']} render={props => {
                    const WrappedDefaultAttributeGroupForm = Form.create({
                      validateMessages: this.props.langData.formValidationMessages,
                    })(DefaultAttributeGroupForm)

                    return (
                      <Modal
                        title={<span><Icon
                          type="file-add"/> &nbsp;{this.props.langData['Change Default Attribute Groups']}</span>}
                        bodyStyle={{ padding: 8 }}
                        visible={true}
                        width={this.props.sizeWidth > 756 ? '50%' : '100%'}
                        footer={null}
                        onCancel={() => {
                          props.history.push(
                            '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                          )
                        }}
                      >
                        <WrappedDefaultAttributeGroupForm product_id={this.product_id} refetch={() => {
                          props.history.push(
                            '/' + compile(this.props.langData.route['product/detail/:id'])({ id: this.product_id }),
                          )
                          refetch()
                        }}/>
                      </Modal>
                    )
                  }
                  }/>
                </RouterSwitch>
              </Row>
            </div>
          )
        }}
      </Query>
    )
  }

}

ProductDetail.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  lang: PropTypes.string,
  sizeWidth: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
