import React from 'react'
import ReactDOM from 'react-dom'
import OneSignal from 'react-onesignal'

// assets
import 'antd/dist/antd.min.css'
import './assets/index.css'

// Components
import App from './component/App'

import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import apolloClient from './helpers/apolloClient'

import Store from './reducers/Store'
import sizeDetector from './utils/SizeDetector'

OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_APP_ID, {
  allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
})

sizeDetector()

ReactDOM.render(
  <Provider store={Store}>
    <ApolloProvider client={apolloClient}>
      <App/>
    </ApolloProvider>
  </Provider>
  , document.getElementById('root'))
