import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Modal, Table } from 'antd'

class SettleNetResult extends Component {
  columns = [
    {
      title: this.props.langData['Invoice Code'],
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: this.props.langData['Invoice Amount'],
      dataIndex: 'grand_total',
      key: 'grand_total',
      align: 'center',
    },
    {
      title: this.props.langData['Settle Net Amount'],
      dataIndex: 'paid',
      key: 'paid',
      align: 'center',
    },
  ]

  render() {
    return (
      <Modal
        visible={true}
        width="35%"
        title={<span><Icon type="pull-request"/> {this.props.langData['Settle Net Detail']}</span>}
        onCancel={this.props.onCancel}
        footer={null}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          columns={this.columns}
          dataSource={this.props.data}
          bordered
          pagination={{
            pageSize: 8,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Modal>
    )
  }
}

SettleNetResult.propTypes = {
  data: PropTypes.array,
  onCancel: PropTypes.func,
  langData: PropTypes.object,
}
export default SettleNetResult
