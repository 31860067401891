import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'

import routes from '../../../routes'
import CustomerUpdateForm from './CustomerUpdateForm'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Customer', url } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class CustomerUpdate extends Component {

  customer_id = null

  constructor(props) {
    super(props)
    this.customer_id = Number(this.props.match.params.id)
  }

  componentDidMount() {
    this.props.breadcrumb_add(generatePath(routes.CUSTOMER_DETAIL, { id: this.customer_id }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    const WrappedCustomerUpdateForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(CustomerUpdateForm)
    return <WrappedCustomerUpdateForm customer_id={this.customer_id} {...this.props}/>
  }

}

CustomerUpdate.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUpdate)
