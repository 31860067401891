import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  selectFilterLowerCase,
  graphQLErrorsToToast,
  updateEmptyAndUndefined,
  formErrorFieldsNameLocalize,
} from './../../../helpers/helper'
import { connect } from 'react-redux'
import { Form, Icon, Button, Card, Divider, Select, InputNumber, Checkbox } from 'antd'
import { gql } from '@apollo/client'

import { Query, Mutation } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'
import AddUpSelect from './../../Utils/AddUpSelect'

const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    test_mode: state.GeneralReducer.test_mode,
    development_mode: state.GeneralReducer.development_mode,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutHalf = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 6 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
  },
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Defaults', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const PRODUCT_DEFAULTS_QUERY = (
  gql`
    query{
      productCategoryList {
        category_id
        name
        code
      }

      sequenceList{
        sequences {
          sequence_id
          name
          type
          pattern
          start
          end
          step
          current_value
          next_value
        }
      }

      productDefaults {
        category_id
        stock_tracking
        unit_id
        vat
        code
        selling_price_merge_with_additional_charges_price
        attribute_group {
          attribute_group_id
          is_first
        }
      }

      subProductAttributeGroupList{
        attribute_groups {
          attribute_group_id
          name
        }
      }

      unitList {
        unit_id
        name
      }

    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation($product_defaults: ProductDefaultsInput!){
      productDefaultsUpdate(product_defaults: $product_defaults){
        informative{
          messages
        }
      }
    }
  `
)

class ProductDefaults extends Component {

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['product/defaults'])
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  render() {
    const WrappedLProductDefaultsForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(ProductDefaultsForm)
    return <WrappedLProductDefaultsForm {...this.props}></WrappedLProductDefaultsForm>
  }

}

ProductDefaults.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

class ProductDefaultsForm extends Component {

  state = {
    categories: [],
    units: [],
    sequences: [],
    attribute_groups: [],
    initialize: false,
    isSubmit: false,
    selling_price_merge_with_additional_charges_price: false,
  }

  onSubmit = (form, mutate) => {
    form.validateFields(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      if (values.attribute_group.first && values.attribute_group.second) {
        const attribute_group = [
          {
            attribute_group_id: values.attribute_group.first,
            is_first: true,
          },
          {
            attribute_group_id: values.attribute_group.second,
            is_first: false,
          },
        ]

        values.attribute_group = attribute_group
      } else {
        delete values.attribute_group
      }
      values.stock_tracking = values.stock_tracking === 'true'
      updateEmptyAndUndefined(values)

      try {
        const result = await mutate({ variables: { product_defaults: { ...values } } })
        toast(true, result.data.productDefaultsUpdate.informative.messages)
      } catch (err) {
        graphQLErrorsToToast(err)
      }

    })
  }

  render() {

    const { getFieldDecorator, getFieldsValue } = this.props.form
    const form = (mutate, loadingMutation) => (

      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit(this.props.form, mutate)
        }}
        className="form-label-default"
      >

        <div className="form-block">
          <Form.Item hasFeedback label={(<span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('category_id')(
              <Select size="large"
                      showSearch
                      allowClear
                      suffixIcon={<Icon type="caret-down"/>}
                      filterOption={selectFilterLowerCase}
              >
                {this.state.categories.map(x => {
                  return <Option value={x.category_id} key={x.category_id}>{x.name}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('code')(
              <Select size="large"
                      showSearch
                      allowClear
                      suffixIcon={<Icon type="caret-down"/>}
                      filterOption={selectFilterLowerCase}
              >
                {this.state.sequences.map(x => {
                  return <Option value={x.sequence_id} key={x.sequence_id}>{x.name}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="calculator"/><p>{this.props.langData['Stock Tracking']}</p></span>
          )}
                     {...formItemLayoutHalf}
          >
            {getFieldDecorator('stock_tracking')(
              <Select size="large" suffixIcon={<Icon type="caret-down"/>}>
                <Option value="true">{this.props.langData['Open']}</Option>
                <Option value="false">{this.props.langData['Closed']}</Option>
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="database"/><p>{this.props.langData['Unit']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('unit_id')(
              <Select
                size="large"
                showSearch
                allowClear
                suffixIcon={<Icon type="caret-down"/>}
                filterOption={selectFilterLowerCase}
              >
                {this.state.units.map(x => {
                  return <Option value={x.unit_id} key={x.unit_id}>{x.name}</Option>
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="wallet"/><p>{this.props.langData['Vat']}</p></span>
          )}
                     {...formItemLayoutHalf}
          >
            {getFieldDecorator('vat')(
              (this.props.lang === 'tr') ?
                (
                  <Select
                    size="large"
                    allowClear
                    className="w-100"
                    suffixIcon={<Icon type="caret-down"/>}
                  >
                    {this.props.langData.vats.map((x, k) => {
                      return <Option value={x} key={k}>{`${x}%`}</Option>
                    })}
                  </Select>
                ) : (
                  <InputNumber
                    size="large"
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    className="w-100"
                    parser={value => value.replace('%', '')}
                  />
                ),
            )}
          </Form.Item>

        </div>

        {(this.props.development_mode || this.props.test_mode) && <div className="form-block">
          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{this.props.langData['First Attribute Group']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('selling_price_merge_with_additional_charges_price', { validateTrigger: false })(
              <Checkbox checked={this.state.selling_price_merge_with_additional_charges_price} onChange={e => {
                this.setState({
                  selling_price_merge_with_additional_charges_price: e.target.checked,
                })
              }}>
                <span>{this.props.langData['Transfer additional charges to the product price during the hook.']}</span>
              </Checkbox>,
            )}
          </Form.Item>
        </div>}
        <Divider/>
        <div className="form-block">
          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{this.props.langData['First Attribute Group']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('attribute_group[first]', { validateTrigger: false })(
              <AddUpSelect
                data={this.state.attribute_groups && this.state.attribute_groups.filter(x => x.attribute_group_id !== getFieldsValue().attribute_group.second).map(x => ({
                  key: x.attribute_group_id,
                  value: x.attribute_group_id,
                  content: x.name,
                }))}
                onAddUp={value => {
                  console.log(value)
                }}
              />,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Second Attribute Group']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('attribute_group[second]', { validateTrigger: false })(
              <AddUpSelect
                data={this.state.attribute_groups && this.state.attribute_groups.filter(x => x.attribute_group_id !== getFieldsValue().attribute_group.first).map(x => ({
                  key: x.attribute_group_id,
                  value: x.attribute_group_id,
                  content: x.name,
                }))}
                onAddUp={value => console.log(value)}
              />,
            )}
          </Form.Item>
        </div>
        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" loading={loadingMutation} htmlType="submit" className="default-button">
              {this.props.langData['Save']}
            </Button>
          </Form.Item>
        </div>

      </Form>
    )
    return (
      <Query
        query={PRODUCT_DEFAULTS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.initialize) {
            let dataCopy = cloneDeep(data.productDefaults)
            let attribute_group = dataCopy.attribute_group
            let selling_price_merge_with_additional_charges_price = dataCopy.selling_price_merge_with_additional_charges_price
            delete dataCopy.attribute_group
            delete dataCopy.selling_price_merge_with_additional_charges_price

            dataCopy.stock_tracking = dataCopy.stock_tracking.toString()
            this.props.form.setFieldsValue({
              ...dataCopy,
              ...(attribute_group.length ? {
                'attribute_group[first]': attribute_group.find(x => x.is_first).attribute_group_id,
                'attribute_group[second]': attribute_group.find(x => ! x.is_first).attribute_group_id,
              } : {}),
            })

            this.setState({
              categories: cloneDeep(data.productCategoryList),
              units: cloneDeep(data.unitList),
              sequences: cloneDeep(data.sequenceList.sequences),
              attribute_groups: cloneDeep(data.subProductAttributeGroupList.attribute_groups),
              selling_price_merge_with_additional_charges_price,
              initialize: true,
            })
          }

          return (
            <Mutation
              mutation={UPDATE_QUERY}
              onError={err => graphQLErrorsToToast(err)}
            >
              {(mutate, { loading: loadingMutation }) => {
                return (
                  <Card loading={loadingQuery} bodyStyle={{ padding: '0px' }}
                        title={
                          <span><Icon type="idcard"/>
                            &nbsp;{this.props.langData['Product Defaults']}</span>
                        }
                        bordered={false}
                  >
                    {form(mutate, loadingMutation)}
                  </Card>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

ProductDefaultsForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  lang: PropTypes.string,
  test_mode: PropTypes.bool,
  development_mode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDefaults)
