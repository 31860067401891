import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import ProductSettingsForm from './../ProductSettings/ProductSettingsForm/ProductSettingsForm'
import routes from '../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Product Settings', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function ProductSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add(routes.PRODUCT_SETTINGS)

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedProductSettings = Form.create({
    validateMessages: langData.formValidationMessages,
  })(ProductSettingsForm)
  return <WrappedProductSettings {...props} />

}

ProductSettings.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSettings)
