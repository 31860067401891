import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input, Icon, Button, Popover, Checkbox, Row, Col, Table, DatePicker, Tag } from 'antd'
import { cloneDeep } from 'lodash'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import moment from 'moment'

import { graphQLErrorsToToast, deleteEmpty, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithId } from '../../../data/Currency'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const InputGroup = Input.Group
const { RangePicker } = DatePicker

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    email_verification: state.AuthReducer.email_verification,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Customer' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Balance Report', url: url } })
    },
    breadrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const CUSTOMER_LIST_QUERY = () => (
  gql`
    query ($customer_balance_transaction_filter: CustomerBalanceTransactionFilterBase, $customer_filter: CustomerFilterBase, $paging: Paging, $sorting: CustomerSorting, $currency: Currency, $date: Date!) {
      customerBalanceInfoList(
        customer_balance_transaction_filter: $customer_balance_transaction_filter
        customer_filter: $customer_filter
        paging: $paging
        sorting: $sorting
      ) {
        customer_balance_info{
          customer {
            customer_id
            type
            name
            category_id
            code
            phone_1
            phone_2
            country
            city
            town
            address
            e_mail
            id_or_tax_identification_number
            tax_administration
            explanation
          }
          transactions_with_filters{
            credits {
              amount
              currency_id
              type
            }
            debits {
              amount
              currency_id
              type
            }
            account_openings_customer {
              amount
              currency_id
              type
            }
            sales_invoices {
              amount
              currency_id
              type
            }
            purchase_invoices {
              amount
              currency_id
              type
            }
            return_sales_invoices {
              amount
              currency_id
              type
            }
            return_purchase_invoices {
              amount
              currency_id
              type
            }
          }
          transactions_without_filters{
            credits {
              amount
              currency_id
              type
            }
            debits {
              amount
              currency_id
              type
            }
            account_openings_customer {
              amount
              currency_id
              type
            }
            sales_invoices {
              amount
              currency_id
              type
            }
            purchase_invoices {
              amount
              currency_id
              type
            }
            return_sales_invoices {
              amount
              currency_id
              type
            }
            return_purchase_invoices {
              amount
              currency_id
              type
            }
          }
        }
        page_info{
          total_count
        }
      }
      currencyRates (currency: $currency, date: $date){
        currency_rates {
          try
          usd
          eur
          gbp
          rub
        }
      }

      legacyData {
        currency_id
      }

    }
  `
)

class CustomerBalanceReport extends Component {
  constructor(props) {
    super(props)

    this.fields = props.sizeWidth < 576 ? ['code', 'name'] : ['code', 'name', 'balance', 'total_debt', 'total_credit']
    if (localStorage.getItem('customer_balance_list_columns'))
      this.fields = localStorage.getItem('customer_balance_list_columns').split(',')
  }

  currencyId = 1

  fields = []
  state = {
    data: null,
    columns: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    customerTransactionFilter: null,
    customerFilter: null,
    sorting: null,
    paging: {
      offset: 0,
      limit: 50,
    },
    currencyRates: {},
  }


  balanceCalculator = (data, type) => {
    const currencyRates = this.state.currencyRates
    let balanceData = cloneDeep(data)
    let balance = 0

    balanceData.account_openings_customer.forEach(value => {
      if (type === 'debit') {
        if (value.type === 2) {
          balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
        } else if (value.type === 3) {
          balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
        }
      } else {
        if (value.type === 3) {
          balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
        } else if (value.type === 2) {
          balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
        }
      }

    })
    balanceData.credits.forEach(value => {
      if (type === 'debit') {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    balanceData.sales_invoices.forEach(value => {
      if (type === 'debit') {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    balanceData.return_sales_invoices.forEach(value => {
      if (type === 'debit') {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    balanceData.purchase_invoices.forEach(value => {
      if (type === 'debit') {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    balanceData.debits.forEach(value => {
      if (type === 'debit') {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    balanceData.return_purchase_invoices.forEach(value => {
      if (type === 'debit') {
        balance += value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      } else {
        balance -= value.amount / currencyRates.currency_rates[getCurrencyWithId(value.currency_id).code]
      }
    })
    return balance
  }

  componentDidMount() {
    this.props.breadrumb_add('/' + this.props.langData.route['customer/balance-report'])

    this.setState({
      columns: [
        {
          title: this.props.langData['Code'],
          fieldName: 'code',
          fieldLabel: 'Code',
          sorter: true,
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('code') === -1,
          dataIndex: 'customer.code',
          key: 'code',
          align: 'left',
        },
        {
          title: this.props.langData['Name'],
          fieldName: 'name',
          fieldLabel: 'Name',
          sorter: true,
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('name') === -1,
          dataIndex: 'customer.name',
          key: 'name',
        },
        {
          title: this.props.langData['Category'],
          fieldName: 'category',
          fieldLabel: 'Category',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('category') === -1,
          subFields: ['name'],
          dataIndex: 'customer.category',
          key: 'category',
          align: 'center',
          render: record => record ? record.name : '',
        },
        {
          title: this.props.langData['E-Mail'],
          fieldName: 'e_mail',
          fieldLabel: 'E-Mail',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('e_mail') === -1,
          dataIndex: 'customer.e_mail',
          key: 'e_mail',
          align: 'center',
        },
        {
          title: this.props.langData['Country'],
          fieldName: 'country',
          fieldLabel: 'Country',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('country') === -1,
          dataIndex: 'customer.country',
          key: 'country',
          align: 'center',
        },
        {
          title: this.props.langData['City'],
          fieldName: 'city',
          fieldLabel: 'City',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('city') === -1,
          dataIndex: 'customer.city',
          key: 'city',
          align: 'center',
        },
        {
          title: this.props.langData['Phone'],
          fieldName: 'phone_1',
          fieldLabel: 'Phone',
          hide: this.fields.indexOf('phone_1') === -1,
          dataIndex: 'customer.phone_1',
          width: 100 / this.fields.length + '%',
          key: 'phone_1',
          align: 'center',
        },
        {
          title: this.props.langData['Total Debit'],
          hide: this.fields.indexOf('total_debt') === -1,
          fieldName: 'total_debt',
          fieldLabel: 'Total Debit',
          dataIndex: 'total_debt',
          width: 100 / this.fields.length + '%',
          align: 'center',
          key: 'total_debt',
          render: (_, record) => {
            const totalDebt = this.balanceCalculator(record.transactions_without_filters, 'debit')
            if (totalDebt <= 0)
              return '-'
            return `${toLocaleString(totalDebt)} ${getCurrencyWithId(this.currencyId).symbol}`
          },
        },
        {
          title: this.props.langData['Total Credit'],
          hide: this.fields.indexOf('total_credit') === -1,
          fieldName: 'total_credit',
          fieldLabel: 'Total Credit',
          dataIndex: 'total_credit',
          width: 100 / this.fields.length + '%',
          align: 'center',
          key: 'total_credit',
          render: (_, record) => {
            const totalCredit = this.balanceCalculator(record.transactions_without_filters, 'credit')
            if (totalCredit <= 0)
              return '-'
            return `${toLocaleString(totalCredit)} ${getCurrencyWithId(this.currencyId).symbol}`
          },
        },
        {
          title: this.props.langData['Selected Date Debit'],
          hide: this.fields.indexOf('selected_date_debt') === -1,
          fieldName: 'selected_date_debt',
          fieldLabel: 'Selected Date Debit',
          dataIndex: 'selected_date_debt',
          width: 100 / this.fields.length + '%',
          align: 'center',
          key: 'selected_date_debt',
          render: (_, record) => {
            const totalDebt = this.balanceCalculator(record.transactions_with_filters, 'debit')
            if (totalDebt <= 0)
              return '-'
            return `${toLocaleString(totalDebt)} ${getCurrencyWithId(this.currencyId).symbol}`
          },
        },
        {
          title: this.props.langData['Selected Date Credit'],
          hide: this.fields.indexOf('selected_date_credit') === -1,
          fieldName: 'selected_date_credit',
          fieldLabel: 'Selected Date Credit',
          dataIndex: 'selected_date_credit',
          width: 100 / this.fields.length + '%',
          align: 'center',
          key: 'selected_date_credit',
          render: (_, record) => {
            const totalCredit = this.balanceCalculator(record.transactions_with_filters, 'credit')
            if (totalCredit <= 0)
              return '-'
            return `${toLocaleString(totalCredit)} ${getCurrencyWithId(this.currencyId).symbol}`
          },
        },
        {
          title: this.props.langData['Balance'],
          dataIndex: 'customer.balance',
          fieldName: 'balance',
          fieldLabel: 'Balance',
          width: 100 / this.fields.length + '%',
          hide: this.fields.indexOf('balance') === -1,
          align: 'right',
          key: 'balance',
          sorter: true,
          render: (_, record) => {
            const balance = this.balanceCalculator(record.transactions_without_filters, 'debit')
            return `${toLocaleString(balance)} ${getCurrencyWithId(this.currencyId).symbol}`
          },
        },
      ],
    })
  }

  onChangeField = fields => {
    if ( ! fields.length)
      return
    let columns = cloneDeep(this.state.columns)
    columns.map(x => {
      if (fields.indexOf(x.fieldName) !== -1)
        x.hide = false
      else
        x.hide = true
      x.width = 100 / fields.length + '%'
    })

    this.setState({
      columns,
    })

    localStorage.setItem('customer_balance_list_columns', fields)

    this.fields = fields
  }

  componentWillUnmount() {
    this.props.breadrumb_delete()
  }

  getVisibleColumnsFieldName = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide).map(x => x.fieldName) : []
  }

  getVisibleColumnsSubField = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide).map(x => x.subFields) : []
  }

  getQueryWithSubFields = (fields, subFields) => {
    let query = ''
    subFields.forEach((e, i) => {
      e = e === undefined ? '' : '{' + e + '}'
      query += fields[i] + ' ' + e
    })
    return query
  }

  getColumnsFieldNameWithLabel = () => {
    return this.state.columns ? this.state.columns.map(x => ({
      label: this.props.langData[x.fieldLabel],
      value: x.fieldName,
      key: x.key,
    })) : []
  }

  getVisibleRawColumns = () => {
    return this.state.columns ? this.state.columns.filter(x => ! x.hide) : []
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  filterCallback = (customer_filter, customer_balance_transaction_filter) => {

    let customerFilter = null
    let customerTransactionFilter = null

    if (customer_filter) {
      customerFilter = {
        AND: customer_filter,
      }
    }

    if (customer_balance_transaction_filter) {
      customerTransactionFilter = customer_balance_transaction_filter
    }

    this.setState({ customerFilter, customerTransactionFilter })
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
    }

    if (sorting.field) {
      this.setState({
        sorting: {
          field: sorting.field.split('.')[1],
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
    } else {
      this.setState({
        sorting: null,
      })
    }
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.CUSTOMER_DETAIL, { id: record.customer.customer_id }))
  }

  render() {
    return (
      <Query
        query={CUSTOMER_LIST_QUERY(this.getQueryWithSubFields(this.getVisibleColumnsFieldName(), this.getVisibleColumnsSubField()))}
        fetchPolicy="network-only"
        skip={ ! this.state.columns}
        variables={{
          customer_filter: this.state.customerFilter,
          customer_balance_transaction_filter: this.state.customerTransactionFilter,
          sorting: this.state.sorting,
          paging: this.state.paging,
          date: moment().format('YYYY-MM-DD HH:mm'),
        }}
      >
        {({ loading: loadingQuery, error, data, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery) {
              if ( ! this.state.data) {

                this.currencyId = data.legacyData.currency_id

                this.setState({
                  data: cloneDeep(data.customerBalanceInfoList.customer_balance_info),
                  currencyRates: cloneDeep(data.currencyRates),
                  pagination: {
                    ...this.state.pagination,
                    total: data.customerBalanceInfoList.page_info.total_count,
                  },
                })
              } else if (this.state.data && JSON.stringify(data.customerBalanceInfoList.customer_balance_info) !== JSON.stringify(this.state.data)) {
                if ( ! this.state.customerTransactionFilter) {
                  this.setState({
                    pagination: {
                      pageSize: this.state.pagination.pageSize,
                      total: data.customerBalanceInfoList.page_info.total_count,
                    },
                  })
                }
                if (this.state.customerTransactionFilter) {
                  let filteredData = []
                  data.customerBalanceInfoList.customer_balance_info.forEach(cData => {
                    if (Object.values(cData.transactions_with_filters).some(filterData => filterData)) {
                      filteredData.push(cData)
                    }
                  })
                  this.setState({
                    data: filteredData,
                    pagination: {
                      pageSize: this.state.pagination.pageSize,
                      total: filteredData.length,
                    },
                  })
                } else {
                  this.setState({ data: data.customerBalanceInfoList.customer_balance_info })
                }
              }
            }
          }

          return (
            <div>
              <FilterBox callback={this.filterCallback} {...this.props} />
              <Row type="flex">
                <Col xs={6} sm={6} md={6} lg={3} xl={3}>
                  <Popover content={(
                    <div>
                      <Checkbox.Group options={this.getColumnsFieldNameWithLabel()} onChange={fields => {
                        this.onChangeField(fields)
                        refetch()
                      }} value={this.fields} style={{ width: 180, display: 'grid' }}/>
                    </div>
                  )} trigger="click" placement="bottom">
                    <div>
                      <Button icon="menu" block={true} style={{ marginBottom: 10, width: '100%', borderRadius: 0 }}>
                        {this.props.langData['Customize']}
                      </Button>
                    </div>
                  </Popover>
                </Col>
                <Col xs={18} sm={18} md={18} lg={21} xl={21} style={{ textAlign: 'center', marginTop: 3 }}>
                  <Tag color="#ff0000"
                       style={{ fontSize: 18 }}>{this.props.langData['Calculations were made over today']}</Tag>
                </Col>
              </Row>
              <Table
                columns={this.getVisibleRawColumns()}
                rowKey={record => record.customer_id}
                dataSource={this.state.data}
                loading={loadingQuery}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => {
                    return this.rowItemClick(record)
                  },
                })}
                rowClassName="white"
                scroll={{
                  x: this.state.columns ? this.state.columns.filter(x => ! x.hide).length * 100 : 500,
                  y: ! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)',
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

CustomerBalanceReport.propTypes = {
  langData: PropTypes.object,
  breadrumb_add: PropTypes.func,
  breadrumb_delete: PropTypes.func,
  email_verification: PropTypes.bool,
  sizeWidth: PropTypes.number,
  history: PropTypes.object,
}

class FilterBox extends Component {

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} />
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
}

const CATEGORY_LIST_QUERY = (
  gql`
    {
      customerCategoryList {
        category_id
        name
      }
    }
  `
)

class FilterBoxForm extends Component {

  state = {
    categoryList: null,
    items: [
      {
        value: 'name',
        required: true,
        operator: 'contains',
        width: '50%',
      },
      {
        value: 'date',
        label: this.props.langData['Date'],
        width: '50%',
        added: false,
      },
    ],
  }

  onSubmit = form => {
    form.validateFields((err, values) => {
      if (err)
        return
      deleteEmpty(values)
      let filterData = []
      let transactionFilterData = []

      for (let value in values) {
        let item = this.state.items.filter(x => x.value === value)[0]
        if (value === 'balance') {
          let data = JSON.parse(values[value])
          filterData.push(
            {
              [data['1']]: [
                { [value]: { ['try']: { [data['0']]: 0 } } },
                { [value]: { ['usd']: { [data['0']]: 0 } } },
                { [value]: { ['eur']: { [data['0']]: 0 } } },
                { [value]: { ['rub']: { [data['0']]: 0 } } },
                { [value]: { ['gbp']: { [data['0']]: 0 } } },
              ],
            },
          )
        } else if (value === 'date') {
          transactionFilterData.push(
            {
              sales_invoice_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              purchase_invoice_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              return_sales_invoice_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              return_purchase_invoice_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              credit_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              debit_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
              account_openings_customer_filter: {
                AND: {
                  [value]: { gte: moment(values[value][0]).startOf(), lte: moment(values[value][1]).endOf() },
                },
              },
            },
          )
        } else {
          filterData.push(
            { [value]: { [item.operator]: values[value] } },
          )
        }
        if ( ! values[value][0]) {
          transactionFilterData = []
        }
      }
      this.props.callback(filterData.length ? filterData : null, transactionFilterData.length ? transactionFilterData[0] : null)

    })
  }
  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items: items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []
    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      <Button key="1" style={{ width: 100 }}><Icon type="filter"/></Button>,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <InputGroup compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </InputGroup>
        ),
      ]
    }

    let content = [...filterHeader]

    content.push(
      (
        getFieldDecorator('date')(
          <RangePicker key="6" format="DD-MM-YYYY"
                       style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'date')[0].width : null }}
                       allowClear/>,
        )
      ),
    )

    content.push(
      (
        getFieldDecorator('name')(
          <Input key="3"
                 style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'name')[0].width : null }}
                 placeholder={this.props.langData['Name']} allowClear/>,
        )
      ),
    )

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={CATEGORY_LIST_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading: loadingQuery, data, error }) => {
            if (error) {
              graphQLErrorsToToast(error)
              return null
            }

            if ( ! loadingQuery && ! this.state.categoryList)
              this.setState({ categoryList: cloneDeep(data.customerCategoryList) })
            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <InputGroup compact style={{ display: 'flex' }}>
                  {content.map(x => x)}
                  <Button htmlType="submit">{this.props.langData['Filter']}</Button>
                </InputGroup>
              </Form>
            )
          }}
        </Query>
      )
    return (
      <Query
        query={CATEGORY_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.categoryList)
            this.setState({ categoryList: cloneDeep(data.customerCategoryList) })
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {content.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )
  }
}

FilterBoxForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,
  callback: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBalanceReport)
