import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AdditionalCharge from './../AdditionalCharge/AdditionalCharge'
import NewAdditionalChargeModal from './../NewAdditionalChargeModal/NewAdditionalChargeModal'
import { Icon, Button, Modal } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

class AdditionalChargeSearchModal extends Component {

  state = {
    formModal: false,
    refetch: false,
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          title={
            <React.Fragment>
              <span><Icon type="idcard"/> &nbsp;{this.props.langData['Additional Charge Search']}</span>
              <Button key="new" type="primary" style={{ float: 'right' }}
                      onClick={() => this.setState({ formModal: true })}>
                {this.props.langData['Add New One']}
              </Button>
            </React.Fragment>
          }
          bodyStyle={{ padding: 8 }}
          visible={true}
          closable={false}
          width="75%"
          onCancel={this.props.modalClose}
          footer={[
            <Button key="close" size="large" onClick={this.props.modalClose} style={{ marginRight: 8 }}
                    className="danger2">
              {this.props.langData['Close']}
            </Button>,
          ]}
        >
          <AdditionalCharge
            noBreadCrumb={true}
            scrollHeight={200}
            rowItemClick={this.props.callback}
            modalClose={this.props.modalClose}
            refetch={this.state.refetch}
          />
        </Modal>
        {this.state.formModal && <NewAdditionalChargeModal modalClose={status => {
          this.setState({
            formModal: false,
            refetch: status ? true : false,
          })
        }}/>}
      </React.Fragment>
    )
  }
}

AdditionalChargeSearchModal.propTypes = {
  langData: PropTypes.object,
  modalClose: PropTypes.func,
  callback: PropTypes.func,
}

export default connect(mapStateToProps)(AdditionalChargeSearchModal)

