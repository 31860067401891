const initialState = {
  data: {}
}

export const TableFilterReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'filter':
      return {
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data
        }
      }

    case 'filterFields':
      return {
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data,
        }
      }

    case 'pageInformations':
      return {
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data
        }
      }

    case 'resetFilter':
      if (action.payload.name)
        delete state.data[action.payload.name]
      return {
        data: {
          ...state.data
        }
      }

    default:
      return state

  }

}
