import React, { Component } from 'react'
import IO from 'socket.io-client'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table, Tag, Icon } from 'antd'
import moment from 'moment'

const mapStateToProps = state => {
  return {
    company_id: state.AuthReducer.company_id,
    langData: state.LocalizeReducer.langData,
  }
}

class ProductSaveControlModal extends Component {
  socket = null

  state = {
    data: null,
    pagination: {
      pageSize: 10,
      showTotal: total => ` Toplam ${total} Adet `,
    },
  }

  columns = [
    {
      title: this.props.langData['Type'],
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: value => {
        if (value === 'insert')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData[value]}</Tag>
        if (value === 'update')
          return <Tag color="#FFA500" style={{ marginRight: 0 }}>{this.props.langData[value]}</Tag>
        if (value === 'delete')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData[value]}</Tag>
      },
    },
    {
      title: this.props.langData['Product Name'],
      align: 'center',
      key: 'product[Ürün adı]',
      dataIndex: 'product[Ürün adı]',
    },
    {
      title: this.props.langData['Code'],
      align: 'center',
      key: 'product[Ürün kodu]',
      dataIndex: 'product[Ürün kodu]',
    },
    {
      title: this.props.langData['Main Product'],
      dataIndex: 'product[Alt ürünleri var mı]',
      key: 'product[Alt ürünleri var mı]',
      align: 'center',
      render: value => value === 'Evet' ? <Icon type="check-circle-o" size="large" style={{ fontSize: 18 }}/> :
        <Icon type="close-circle" size="large" style={{ fontSize: 18 }}/>,
    },
    {
      title: this.props.langData['Record Date'],
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      render: value => moment(value).format('DD MMMM YYYY HH:mm'),
    },
    {
      title: this.props.langData['Stock'],
      align: 'center',
      key: 'product[Stok]',
      dataIndex: 'product[Stok]',
    },
    {
      title: this.props.langData['Status'],
      dataIndex: 'success',
      key: 'success',
      align: 'center',
      render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: 18 }}/> :
        <Icon type="close-circle" size="large" style={{ fontSize: 18 }}/>,
    },
    {
      title: this.props.langData['Message'],
      dataIndex: 'message',
      key: 'message',
      align: 'right',
    },
  ]

  componentDidMount() {
    this.socket = IO(process.env.REACT_APP_MAIN_API_URL, {
      query: {
        user_id: this.props.company_id,
      },
      transports: ['websocket'],
    })
    this.socket.on('import_product_logs', data => this.setState({ data: data[0].data }))
  }

  render() {
    return (
      <Table
        dataSource={this.state.data}
        loading={ ! this.state.data}
        columns={this.columns}
        scoll={{ x: 'max-content' }}
        pagination={this.state.pagination}
      />
    )
  }
}

ProductSaveControlModal.propTypes = {
  company_id: PropTypes.number,
  langData: PropTypes.object,
}
export default connect(mapStateToProps, null)(ProductSaveControlModal)
