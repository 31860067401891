const initialState = {
  fixed_exchange_rate: 'try', // sadece read yapılıyor, daha sonra tamamen kaldırılacak
}

export const GlobalReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
