import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { generatePath } from 'react-router-dom'

import routes from '../../../../routes'

import InvoiceReturnForm from './../InvoiceReturnForm/InvoiceReturnForm'

function InvoiceReturn(props) {

  const transaction_id = Number(props.match.params.id)
  const type = props.match.params.type

  useEffect(() => {

    props.breadcrumb_add(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
      type: type,
      id: transaction_id,
    }))

    return () => {

      props.breadcrumb_delete()

    }

  }, []);

  const WrappedInvoiceReturn = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(InvoiceReturnForm)

  return (
    <WrappedInvoiceReturn {...props} transaction_id={transaction_id} type={type}/>
  )

}

InvoiceReturn.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Invoice' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Return Invoice', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(InvoiceReturn)
