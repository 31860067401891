import React, { Component } from 'react'
import { Query, Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { Icon, Form, Card, Input, Divider, Select, Col, Button, DatePicker } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  graphQLErrorsToToast,
  toast,
  deleteNull,
  selectFilterLowerCase,
  updateEmptyAndUndefined,
} from '../../../../../helpers/helper'
import moment from 'moment'

const InputGroup = Input.Group
const { Option } = Select

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

const READ_FOR_CREATE_QUERY = (
  gql`
    {
      settings {
        waybill_settings {
          user_id
          carrier_company_information {
            carrier_vkn
            carrier_title
            country
            city
            carrier_vehicle_plate
            town
            actual_shipping_date
          }
          carrier_company_chauffeur {
            tckn
            name
            surname
          }
        }
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation ($settings: SettingsUpdateInput!) {
      settingsUpdate(settings: $settings) {
        settings {
          waybill_settings {
            user_id
            carrier_company_information {
              carrier_vkn
              carrier_title
              country
              city
              carrier_vehicle_plate
            }
            carrier_company_chauffeur {
              tckn
              name
              surname
            }
          }
        }
        informative {
          messages
        }
      }
    }
  `
)

class EWaybillSettingsForm extends Component {

  state = {
    initialize: false,
    city: null,
    country: null,
    actual_shipping_date: null,
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err)
        graphQLErrorsToToast(err)

      if ( ! values.settings.waybill_settings.carrier_company_chauffeur.name && ! values.settings.waybill_settings.carrier_company_information.carrier_title) {
        toast(false, this.props.langData['E-Invoice Warn'])
        return null
      } else {
        updateEmptyAndUndefined(values)
        mutate({ variables: values })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Query
        query={READ_FOR_CREATE_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, error, data }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            let datas = data && data.settings.waybill_settings
            this.setState({
              initialize: true,
              city: datas.carrier_company_information.city,
              country: datas.carrier_company_information.country,
              actual_shipping_date: datas.carrier_company_information.actual_shipping_date,
            })
            this.props.form.setFieldsValue(deleteNull({
              'settings[waybill_settings][carrier_company_chauffeur][name]': datas.carrier_company_chauffeur.name,
              'settings[waybill_settings][carrier_company_chauffeur][surname]': datas.carrier_company_chauffeur.surname,
              'settings[waybill_settings][carrier_company_chauffeur][tckn]': datas.carrier_company_chauffeur.tckn,
              'settings[waybill_settings][carrier_company_information][carrier_title]': datas.carrier_company_information.carrier_title,
              'settings[waybill_settings][carrier_company_information][carrier_vehicle_plate]': datas.carrier_company_information.carrier_vehicle_plate,
              'settings[waybill_settings][carrier_company_information][carrier_vkn]': datas.carrier_company_information.carrier_vkn,
              'settings[waybill_settings][carrier_company_information][country]': datas.carrier_company_information.country,
              'settings[waybill_settings][carrier_company_information][city]': datas.carrier_company_information.city,
              'settings[waybill_settings][carrier_company_information][town]': datas.carrier_company_information.town,
              'settings[waybill_settings][carrier_company_information][actual_shipping_date]': datas.carrier_company_information.actual_shipping_date ? moment(datas.carrier_company_information.actual_shipping_date) : '',
            }))
          }
          return (
            <Mutation
              mutation={CREATE_QUERY}
              onError={err => graphQLErrorsToToast(err)}
              onCompleted={() => {
                toast(true, this.props.langData['E-Waybill Settings Success'])
              }}
            >
              {(mutate, { loading: loadingMutation }) => (
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="control"/> &nbsp;{this.props.langData['E-Waybill Settings']}</span>}
                  bordered={false}
                  loading={(loadingQuery || ! data)}
                >
                  <Form
                    hideRequiredMark={true}
                    className="form-label-default"
                    onSubmit={e => {
                      e.preventDefault()
                      this.onSubmit(mutate)
                    }}
                  >
                    <div className="form-block">

                      <Divider>{this.props.langData['Carrier Company Information']}</Divider>

                      <Form.Item
                        help={this.props.langData['E-Invoice Warn']}
                        label={<span><Icon type="form"/><p>{this.props.langData['Carrier Title']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_information][carrier_title]', {
                          validateTrigger: false,
                        })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                      <Form.Item
                        label={<span><Icon type="car"/><p>{this.props.langData['Carrier Vehicle Plate']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_information][carrier_vehicle_plate]', {
                          validateTrigger: false,
                        })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                      <Form.Item
                        label={<span><Icon
                          type="edit"/><p>{this.props.langData['Carrier Tax Identification Number']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_information][carrier_vkn]', {
                          validateTrigger: false,
                        })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                      <Form.Item
                        label={<span><Icon
                          type="schedule"/><p>{this.props.langData['Actual Shipping Date']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_information][actual_shipping_date]', {
                          validateTrigger: false,
                        })(
                          <DatePicker
                            format="DD.MM.YYYY HH:mm"
                            size="large"
                            className="w-100"
                            suffixIcon={<Icon type="calendar" theme="filled"/>}
                            showTime
                          />,
                        )}
                      </Form.Item>

                      <Form.Item hasFeedback label={(
                        <span><Icon type="environment-o"/><p>{this.props.langData['Waybill Country']}</p></span>)}
                                 {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_information][country]', {
                          rules: [{ max: 100 }],
                          validateTrigger: false,
                        })(
                          <Select
                            size="large"
                            suffixIcon={<Icon type="caret-down"/>}
                            showSearch
                            allowClear
                            filterOption={selectFilterLowerCase}
                            maxLength={100}
                            onChange={val => this.setState({ country: val })}
                          >
                            {this.props.langData.countries.map((x, k) => {
                              return <Option value={x} key={k}>{x}</Option>
                            })}
                          </Select>,
                        )}
                      </Form.Item>

                      <Form.Item label={(
                        <span><Icon type="environment-o"/><p>{this.props.langData['Waybill City/Town']}</p></span>
                      )}
                                 {...formItemLayout}
                      >
                        <InputGroup size="large">
                          <Col span={12}>
                            <Form.Item hasFeedback>
                              {getFieldDecorator('settings[waybill_settings][carrier_company_information][city]', {
                                rules: [{ max: 100 }],
                                validateTrigger: false,
                              })(
                                (this.props.lang === 'tr' && this.state.country === 'Türkiye') ? (
                                  <Select
                                    size="large"
                                    className="w-100"
                                    showSearch
                                    allowClear
                                    suffixIcon={<Icon type="caret-down"/>}
                                    filterOption={selectFilterLowerCase}
                                    maxLength={100}
                                    onChange={val => this.setState({ city: val })}
                                  >
                                    {this.props.langData.cityTown.map((x, k) => {
                                      return <Option value={x.city} key={k}>{x.city}</Option>
                                    })}
                                  </Select>
                                ) : <Input maxLength={100}/>,
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item hasFeedback>
                              {getFieldDecorator('settings[waybill_settings][carrier_company_information][town]', {
                                rules: [{ max: 100 }],
                                validateTrigger: false,
                              })(
                                (this.props.lang === 'tr' && this.state.country === 'Türkiye') ? (
                                  <Select
                                    size="large"
                                    className="w-100"
                                    showSearch
                                    allowClear
                                    suffixIcon={<Icon type="caret-down"/>}
                                    filterOption={selectFilterLowerCase}
                                    maxLength={100}
                                  >
                                    {
                                      this.props.langData.cityTown.filter(x => x.city === this.state.city).length && (
                                        this.props.langData.cityTown.filter(x => x.city === this.state.city)[0].towns.map((x, k) => {
                                          return <Option value={x} key={k}>{x}</Option>
                                        })
                                      )
                                    }
                                  </Select>
                                ) : <Input maxLength={100}/>,
                              )}
                            </Form.Item>
                          </Col>
                        </InputGroup>

                      </Form.Item>

                      <Divider>{this.props.langData['Carrier Company Chauffeur Information']}</Divider>

                      <Form.Item
                        label={<span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_chauffeur][name]', {
                          validateTrigger: false,
                        })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                      <Form.Item
                        label={<span><Icon type="tag"/><p>{this.props.langData['Surname']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_chauffeur][surname]', { validateTrigger: false })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                      <Form.Item
                        label={<span><Icon type="edit"/><p>{this.props.langData['Identification Number']}</p></span>}
                        {...formItemLayout}
                      >
                        {getFieldDecorator('settings[waybill_settings][carrier_company_chauffeur][tckn]', { validateTrigger: false })(
                          <Input size="large"/>,
                        )}
                      </Form.Item>

                    </div>

                    <div className="form-block">
                      <Form.Item {...formItemLayoutNoLabel}>
                        <Button type="primary" size="large" loading={loadingMutation} htmlType="submit"
                                className="default-button">
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Card>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

EWaybillSettingsForm.propTypes = {
  langData: PropTypes.object,
  form: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, null)(EWaybillSettingsForm)
