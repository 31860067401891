import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  toast,
  deleteNull,
  updateEmptyAndUndefined,
  graphQLErrorsToToast,
  formErrorFieldsNameLocalize,
  selectFilterLowerCase,
} from '../../../helpers/helper'
import { currencyData, getCurrencyWithCode } from '../../../data/Currency'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Card, Divider, Select, InputNumber, DatePicker, Table, Spin, Col } from 'antd'
import moment from 'moment'
import { gql } from '@apollo/client'

import { cloneDeep, debounce } from 'lodash'
import { Query, Mutation } from '@apollo/client/react/components'
import apolloClient from './../../../helpers/apolloClient'
import AdditionalChargeSearchModal
  from './../../AdditionalCharge/AdditionalChargeSearchModal/AdditionalChargeSearchModal'
import ProductAdditionalCharge from './../ProductAdditionalCharge/ProductAdditionalCharge'
import { createCategory, refetchAndControlSequenceValues, setDefault } from '../../../helpers/trait'

const { Option } = Select
const InputGroup = Input.Group

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
    fixed_exchange_rate: state.GlobalReducer.fixed_exchange_rate,
  }
}

const READ_QUERY = (
  gql`
    query subProduct($product_id: Int!, $main_product_id: Int!){
      subProduct(product_id: $product_id) {
        product_id
        main_product_id
        code
        stock_tracking
        selling_price
        selling_currency
        buying_price
        buying_currency
        barcode
        stock_opening {
          type
          quantity
          date
        }
        additional_charges {
          product_additional_charge_id
          additional_charge_id
          buying_price
          selling_price
          quantity
          vat
          additional_charge {
            name
          }
        }
        attributes {
          attribute_id
          attribute_group_id
          title
          code
          is_first
        }
      }

      productDefaults {
        stock_tracking
        unit_id
        vat
      }

      defaultAttributeGroup(product_id: $main_product_id){
        attribute_group_id
        is_first
        attribute_group {
          attribute_group_id
          name
          attributes {
            attribute_id
            title
            code
          }
        }
      }
    }
  `
)

const READ_FOR_CREATE_QUERY = (
  gql`
    query($main_product_id: Int!){
      productDefaults {
        stock_tracking
        unit_id
        vat
      }

      defaultAttributeGroup(product_id: $main_product_id){
        attribute_group_id
        is_first
        attribute_group {
          attribute_group_id
          name
          attributes {
            attribute_id
            title
            code
          }
        }
      }

    }
  `
)

const SEARCH_ATTRIBUTE_GROUP_QUERY = (
  gql`
    query search($name: String!){
      search: subProductAttributeGroupList(
        filter: {
          AND: [
            { name: { contains: $name } }
          ]
        },
        paging: {
          offset: 0,
          limit: 50
        }
      ){
        attribute_groups {
          attribute_group_id
          name
          attributes {
            attribute_id
            title
            code
          }
        }
        page_info{
          total_count
        }
      }
    }
  `
)

const ADDITIONAL_CHARGE_READ_QUERY = (
  gql`
    query additionalCharge($additional_charge_id: Int!){
      additionalCharge(additional_charge_id: $additional_charge_id){
        name
        buying_price
        selling_price
        vat
      }
      unitList {
        unit_id
        name
      }
    }
  `
)

const ADDITIONAL_CHARGE_SEARCH_QUERY = (
  gql`
    query additionalChargeList($name: String!){
      additionalChargeList(filter: {
        AND: [
          { name: { contains: $name } }
        ]
      }){
        additional_charges {
          additional_charge_id
          name
          buying_price
          selling_price
          vat
        }
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation subProduct($product: SubProductCreateInput!, $stock_opening: StockOpeningCreateInput){
      subProduct: subProductCreate(product: $product, stock_opening: $stock_opening){
        informative{
          messages
        }
      }
    }
  `
)

const UPDATE_QUERY = (
  gql`
    mutation subProduct($product_id: Int!, $product: SubProductUpdateInput!, $stock_opening: StockOpeningCreateInput){
      subProduct: subProductUpdate(product_id: $product_id, product: $product, stock_opening: $stock_opening) {
        informative {
          messages
        }
      }
    }
  `
)

class SubProductForm extends Component {

  columns = [
    {
      title: this.props.langData['Additional Charge'],
      dataIndex: 'additional_charge_id',
      width: '40%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputGroup compact style={{ display: 'flex' }}>
              <Select
                className="w-100"
                filterOption={false}
                defaultActiveFirstOption={false}
                showSearch
                style={{ width: '100%' }}
                suffixIcon={<Icon type="caret-down"/>}
                notFoundContent={this.state.fetchingAdditionalCharge ? <Spin size="large"/> : null}
                onSearch={value => this.searchAdditionalCharge(record.id, value)}
                value={value}
                disabled={record.delete}
                onSelect={value => {
                  let selected_additional_charge = record.additional_charges.find(x => x.additional_charge_id === value)
                  let additional_charges = this.state.additional_charges
                  let additional_charge = additional_charges.find(x => x.id === record.id)
                  additional_charge.additional_charge_id = value
                  additional_charge.buying_price = selected_additional_charge.buying_price
                  additional_charge.selling_price = selected_additional_charge.selling_price
                  additional_charge.vat = selected_additional_charge.vat
                  this.setState({ additional_charges })
                }}
              >
                {record.additional_charges && record.additional_charges.map((x, i) => {
                  return <Option value={x.additional_charge_id} key={i}>{x.name}</Option>
                })}
              </Select>
              <Button
                icon="search"
                disabled={record.delete}
                onClick={() => this.setState({ modalAdditionalChargeSearch: record.id })}
              ></Button>
            </InputGroup>
          )
        if (value && record.additional_charges)
          return record.additional_charges.find(x => x.additional_charge_id === value).name
        return null
      },
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              className="w-100"
              min={0.0001}
              step={0.0001}
              value={value}
              allowClear
              disabled={record.delete}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).quantity = value
                this.setState({ additional_charges })
              }}
            />
          )
        return value
      },
    },
    {
      title: this.props.langData['Buying Price'],
      dataIndex: 'buying_price',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              className="w-100"
              min={0}
              step={0.0001}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).buying_price = value
                this.setState({ additional_charges })
              }}
              value={value}
              disabled={record.delete}
              formatter={value => {
                if (value.toString() === '')
                  return ''
                return value.toString().replace(this.state.selectedBuyingCurrency.symbol, '') + ` ${this.state.selectedBuyingCurrency.symbol}`
              }}
              parser={value => value.replace(' ' + this.state.selectedBuyingCurrency.symbol, '')}
            />
          )
        return value ? (value + ' ' + this.state.selectedBuyingCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Selling Price'],
      dataIndex: 'selling_price',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 576)
          return (
            <InputNumber
              className="w-100"
              min={0}
              step={0.0001}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).selling_price = value
                this.setState({ additional_charges })
              }}
              value={value}
              disabled={record.delete}
              formatter={value => {
                if (value.toString() === '')
                  return ''
                return value.toString().replace(this.state.selectedSellingCurrency.symbol, '') + ` ${this.state.selectedSellingCurrency.symbol}`
              }}
              parser={value => value.replace(' ' + this.state.selectedSellingCurrency.symbol, '')}
            />
          )
        return value ? (value + ' ' + this.state.selectedSellingCurrency.symbol) : null
      },
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      width: '15%',
      render: (value, record) => {
        if (this.props.sizeWidth > 756) {
          if (this.props.lang === 'tr')
            return (
              <Select
                className="w-100"
                value={value}
                suffixIcon={<Icon type="caret-down"/>}
                disabled={record.delete}
                onChange={value => {
                  let additional_charges = this.state.additional_charges
                  additional_charges.find(x => x.id === record.id).vat = value
                  this.setState({ additional_charges })
                }}
              >
                {this.props.langData.vats.map((x, k) => {
                  return <Option value={x} key={k}>{`${x}%`}</Option>
                })}
              </Select>
            )
          return (
            <InputNumber
              className="w-100"
              min={0}
              step={1}
              onChange={value => {
                let additional_charges = this.state.additional_charges
                additional_charges.find(x => x.id === record.id).vat = value
                this.setState({ additional_charges })
              }}
              value={value}
              disabled={record.delete}
              formatter={value => {
                if (value.toString() === '') {
                  return ''
                }
                return value.toString().replace('%', '') + '%'
              }}
              parser={value => value.replace('%', '')}
            />
          )
        }
        return value ? (value + '%') : null
      },
    },
    {
      className: 'text-right',
      render: (value, record) => {
        return (
          <Button
            icon={record.delete === true ? 'minus-circle-o' : 'close-circle-o'}
            style={{ width: 40 }}
            onClick={event => {
              event.stopPropagation()
              this.deleteAdditionalCharge(record.id)
            }}
          >
          </Button>
        )
      },
    },
  ]

  initialState = {
    data: null,
    categories: null,
    units: null,
    additional_charges: [],
    fetchingAdditionalCharge: false,
    stock_opening_quantity: null,
    selectedSellingCurrency: {},
    selectedBuyingCurrency: {},
    defaults: null,
    modalAdditionalChargeSearch: null,
    modalAdditionalCharge: null,
    initialize: false,
    attributeGroups: [],
  }

  state = this.initialState

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 16 },
      lg: { span: this.props.isModal ? 16 : 12 },
    },
  }

  formItemLayoutHalf = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
  }

  sequenceIntervals = null

  constructor(props) {
    super(props)
    this.searchAdditionalCharge = debounce(this.searchAdditionalCharge, 800)
    this.createCategory = createCategory('product').bind(this)
    this.refetchAndControlSequenceValues = refetchAndControlSequenceValues.bind(this)
    this.setDefault = setDefault.bind(this)
  }

  onSubmit = mutate => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err)
        return formErrorFieldsNameLocalize(err)

      values.product.stock_tracking = (values.product.stock_tracking === 'true')
      values.product.main_product_id = this.props.main_product_id
      delete values.first
      delete values.second

      if (this.state.additional_charges.length) {
        let additional_charges = []
        let additional_charges_update = []
        let additional_charges_delete = []
        for (let i = 0; i < this.state.additional_charges.length; i++) {
          let additional_charge = this.state.additional_charges[i]
          if (additional_charge.delete) {
            additional_charges_delete.push(additional_charge.product_additional_charge_id)
          } else {
            if ( ! additional_charge.additional_charge_id) {
              toast(false, this.props.langData['Incorrect additional charges entered'])
              return null
            }
            delete additional_charge.id
            delete additional_charge.additional_charges
            delete additional_charge.delete
            if (additional_charge.product_additional_charge_id)
              additional_charges_update.push(additional_charge)
            else
              additional_charges.push(additional_charge)
          }
        }
        if (additional_charges.length)
          values.product.additional_charges = additional_charges
        if (additional_charges_update.length)
          values.product.additional_charges_update = additional_charges_update
        if (additional_charges_delete.length)
          values.product.additional_charges_delete = additional_charges_delete

      }

      if (this.props.product_id) {
        updateEmptyAndUndefined(values)
        values.product_id = this.props.product_id
        if ( ! values.stock_opening.quantity)
          values.stock_opening = null
        else
          values.stock_opening.date = moment(values.stock_opening.date).format('YYYY-MM-DD HH:mm')

        // varsayılan değerlere eş değer olan fieldları null yap, veritabanı bunları set_defaults ile otomatik alacak
        for (let x in this.state.defaults) {
          if (this.state.defaults[x] === values.product[x])
            values.product[x] = null
        }

        var prevAttributeData = this.state.data.attributes
        let attribute_delete = []
        let attributes = []
        let currentAttribute = values.product.attributes.map(x => x.attribute_id)
        let prevAttribute = prevAttributeData.map(x => x.attribute_id)

        currentAttribute.forEach((x, y) => {
          if (prevAttribute.indexOf(x) === -1 && x !== null)
            attributes.push({ attribute_id: x, is_first: (y === 0) })
        })

        prevAttribute.forEach(x => {
          if (currentAttribute.indexOf(x) === -1 && x !== null)
            attribute_delete.push({ attribute_id: x })
        })
        values.product.attributes = attributes
        values.product.attribute_delete = attribute_delete

        delete values.product.code

      } else {
        if ( ! values.stock_opening.quantity)
          delete values.stock_opening
        else
          values.stock_opening.date = moment(values.stock_opening.date).format('YYYY-MM-DD HH:mm')

        // varsayılan değerlere eş değer olan fieldları sil( yani gönderme ), veritabanı bunları set_defaults ile otomatik alacak
        for (let x in this.state.defaults) {
          if (this.state.defaults[x] === values.product[x])
            delete values.product[x]
        }

        values.product.attributes = values.product.attributes.filter(x => x.attribute_id !== undefined && x.attribute_id !== null).map((x, y) => ({
          ...x,
          is_first: y === 0,
        }))
      }

      try {
        const result = await mutate({ variables: values })
        if (result !== undefined) {
          toast(true, result.data.subProduct.informative.messages)
          if (this.props.isModal)
            return this.props.isModal(true)
          this.props.refetch()
        }
      } catch (err) {
        graphQLErrorsToToast(err)
      }

    })
  }

  addAdditionalCharge = () => {
    let additional_charge = {
      id: Math.floor(Math.random() * 999999) + 1,
      additional_charge_id: null,
      quantity: null,
      buying_price: null,
      selling_price: null,
      vat: null,
    }
    let additional_charges = this.state.additional_charges
    additional_charges.push(additional_charge)
    this.setState({ additional_charges })
    if (this.props.sizeWidth <= 576)
      this.setState({ modalAdditionalCharge: additional_charge })
  }

  deleteAdditionalCharge = id => {
    if ( ! this.props.product_id)
      return this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })

    let additional_charges = this.state.additional_charges
    if ( ! additional_charges.find(x => x.id === id).product_additional_charge_id)
      return this.setState({ additional_charges: this.state.additional_charges.filter(x => x.id !== id) })

    additional_charges.find(x => x.id === id).delete = ! additional_charges.find(x => x.id === id).delete
    this.setState({ additional_charges })
  }

  additionalChargeSearchModalCallback = async record => {
    let id = this.state.modalAdditionalChargeSearch
    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    try {
      const result = await apolloClient.query({
        query: ADDITIONAL_CHARGE_READ_QUERY,
        variables: { additional_charge_id: record.additional_charge_id },
      })
      if ( ! result.data.additionalCharge)
        return toast(false, this.props.langData['x not found']('Additional charge'))

      let additionalChargePure = result.data.additionalCharge
      additional_charge.additional_charge_id = record.additional_charge_id
      additional_charge.buying_price = additionalChargePure.buying_price
      additional_charge.selling_price = additionalChargePure.selling_price
      additional_charge.vat = additionalChargePure.vat
      additional_charge.additional_charges = [
        {
          additional_charge_id: record.additional_charge_id,
          name: additionalChargePure.name,
        },
      ]

      this.setState({ additional_charges, modalAdditionalChargeSearch: null })
    } catch (err) {
      this.setState({ modalAdditionalChargeSearch: null })
      graphQLErrorsToToast(err)
    }

  }

  searchValue = null

  searchAdditionalCharge = async (id, value) => {
    if (this.searchValue === value)
      return

    this.searchValue = value

    if (value.length < 2)
      return

    let additional_charges = this.state.additional_charges
    let additional_charge = additional_charges.find(x => x.id === id)

    this.setState({ fetchingAdditionalCharge: true })

    try {
      const result = await apolloClient.query({ query: ADDITIONAL_CHARGE_SEARCH_QUERY, variables: { name: value } })
      additional_charge.additional_charges = result.data.additionalChargeList.additional_charges
      if ( ! result.data.additionalChargeList.additional_charges.length) {
        additional_charge.additional_charge_id = null
        additional_charge.name = null
      }
      this.setState({ fetchingAdditionalCharge: false })
      this.setState({ additional_charges })
    } catch (err) {
      this.setState({ fetchingAdditionalCharge: false })
      graphQLErrorsToToast(err)
    }

  }

  attributeGroupSearchValue = null

  searchAttributeGroup = async value => {
    if (this.attributeGroupSearchValue === value)
      return

    this.attributeGroupSearchValue = value

    const result = await apolloClient.query({
      query: SEARCH_ATTRIBUTE_GROUP_QUERY,
      variables: { name: value },
      fetchPolicy: 'network-only',
    })

    this.setState({
      attributeGroups: result.data.search.attribute_groups,
    })

    return result


  }

  rowItemClick = record => {
    this.setState({
      modalAdditionalCharge: record,
    })
  }

  editAdditionalCharge = (additional_charge, name) => {
    let additional_charges = []

    this.state.additional_charges.forEach(x => {
      if (x.id !== additional_charge.id)
        additional_charges.push(x)
      else
        additional_charges.push({
          ...additional_charge, additional_charges: [
            {
              additional_charge_id: additional_charge.additional_charge_id,
              name,
            },
          ],
        })
    })

    this.setState({
      modalAdditionalCharge: null,
      additional_charges,
    })
  }

  modalClose = () => {
    let additional_charges = this.state.additional_charges.filter(x => x.additional_charge_id !== null)
    this.setState({
      additional_charges,
      modalAdditionalCharge: null,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Query
        query={this.props.product_id ? READ_QUERY : READ_FOR_CREATE_QUERY}
        variables={this.props.product_id ? {
          product_id: this.props.product_id,
          main_product_id: this.props.main_product_id,
        } : { main_product_id: this.props.main_product_id }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.initialize) {
            // ürün düzenleme açılıyorsa ve ürün yoksa hata ver ve yönlendir

            if (this.props.product_id && ! data.subProduct) {
              toast(false, this.props.langData['x not found']('Sub Product'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else {
              let defaults = cloneDeep(data.productDefaults)
              let attributeGroups = cloneDeep(data.defaultAttributeGroup)

              this.setState({
                attributeGroups,
                defaults,
                initialize: true,
              })
              this.props.form.setFieldsValue({
                'first': attributeGroups.filter(x => x.is_first)[0] && attributeGroups.filter(x => x.is_first)[0].attribute_group.name,
                'second': attributeGroups.filter(x => ! x.is_first)[0] && attributeGroups.filter(x => ! x.is_first)[0].attribute_group.name,
              })

              if (this.props.product_id) {
                this.setState({ data: data.subProduct })
                // ürün ek ücretleri var ise her ek ücret için bir id belirle. ve select te seçili görülebilsin diye. additional_charges ekle
                if (data.subProduct.additional_charges.length) {
                  let additional_charges = cloneDeep(data.subProduct.additional_charges)
                  additional_charges.forEach(x => {
                    x.id = Math.floor(Math.random() * 999999) + 1
                    x.additional_charges = [
                      {
                        additional_charge_id: x.additional_charge_id,
                        name: x.additional_charge.name,
                      },
                    ]
                    delete x.additional_charge
                  })
                  this.setState({ additional_charges })
                }

                let product = data.subProduct

                let stock_opening = data.subProduct.stock_opening
                this.props.form.setFieldsValue(deleteNull({
                  'product[code]': product.code,
                  'product[stock_tracking]': (product.stock_tracking).toString(),
                  'product[barcode]': product.barcode,
                  'product[selling_price]': product.selling_price,
                  'product[buying_price]': product.buying_price,
                  'product[buying_currency]': product.buying_currency,
                  'product[selling_currency]': product.selling_currency,
                  'product[attributes][0][attribute_id]': product.attributes.filter(x => x.is_first)[0].attribute_id,
                  'product[attributes][1][attribute_id]': product.attributes.find(x => ! x.is_first) ? product.attributes.find(x => ! x.is_first).attribute_id : null,
                }))
                // kurları seç
                if (product.selling_currency)
                  this.setState({ selectedSellingCurrency: getCurrencyWithCode(product.selling_currency) })
                else
                  this.setState({ selectedSellingCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })

                if (product.buying_currency)
                  this.setState({ selectedBuyingCurrency: getCurrencyWithCode(product.buying_currency) })
                else
                  this.setState({ selectedBuyinCurrency: getCurrencyWithCode(this.props.fixed_exchange_rate) })

                if (stock_opening) {
                  this.setState({ stock_opening_quantity: stock_opening.quantity })
                  setTimeout(() => {
                    this.props.form.setFieldsValue({
                      'stock_opening[quantity]': stock_opening.quantity,
                      'stock_opening[type]': stock_opening.type,
                      'stock_opening[date]': moment(stock_opening.date),
                    })
                  }, 10)
                }
              } else {
                // varsayılan değerleri yükle
                let defaultDataForUpdate = {}
                Object.keys(defaults).forEach(x => {
                  if (defaults[x] !== null) {
                    if (x === 'stock_tracking')
                      defaultDataForUpdate[`product[${x}]`] = defaults[x].toString()
                    else
                      defaultDataForUpdate[`product[${x}]`] = defaults[x]
                  }
                })

                this.props.form.setFieldsValue(defaultDataForUpdate)

                // varsayılan kurları seç
                let selectedCurrency = getCurrencyWithCode(this.props.fixed_exchange_rate)
                this.setState({
                  selectedBuyingCurrency: selectedCurrency,
                  selectedSellingCurrency: selectedCurrency,
                })
              }
            }
          }

          return (
            <Mutation
              mutation={! this.props.product_id ? CREATE_QUERY : UPDATE_QUERY}
            >
              {(mutate, { loading: loadingMutation }) => (
                <div>
                  <Card
                    loading={! this.props.product_id ? false : (loadingQuery || ! this.state.data)}
                    bodyStyle={{ padding: '0px' }}
                    bordered={false}
                  >

                    <Form hideRequiredMark={true} className="form-label-default">

                      <div className="form-block">
                        {this.props.product_id && <Form.Item hasFeedback label={(
                          <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                        )}
                                                             {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[code]', { rules: [{ max: 20 }], validateTrigger: false })(
                            <Input disabled={true} size="large" maxLength={20}
                                   onBlur={item => this.setDefault('product[code]', 'code', item)}/>,
                          )}
                        </Form.Item>}

                        <Form.Item hasFeedback label={(
                          <span><Icon type="barcode"/><p>{this.props.langData['Barcode']}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >
                          {getFieldDecorator('product[barcode]', { rules: [{ max: 30 }] })(
                            <Input size="large" maxLength={30}/>,
                          )}
                        </Form.Item>

                        <Form.Item hasFeedback label={(
                          <span><Icon type="calculator"/><p>{this.props.langData['Stock Tracking']}</p></span>
                        )}
                                   {...(this.formItemLayout)}
                        >
                          {getFieldDecorator('product[stock_tracking]', {
                            initialValue: 'true',
                            validateTrigger: false,
                          })(
                            <Select size="large" suffixIcon={<Icon type="caret-down"/>}>
                              <Option value="true">{this.props.langData['Open']}</Option>
                              <Option value="false">{this.props.langData['Closed']}</Option>
                            </Select>,
                          )}
                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Opening Stock']}</p></span>)}
                                   {...this.formItemLayout}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('stock_opening[type]', {
                              initialValue: 'Pozitive',
                              validateTrigger: false,
                            })(
                              <Select size="large" suffixIcon={<Icon type="caret-down"/>}>
                                <Option value="Pozitive">+</Option>
                                <Option value="Negative">-</Option>
                              </Select>,
                            )}
                            {getFieldDecorator('stock_opening[quantity]', { validateTrigger: false })(
                              <InputNumber
                                decimalSeparator=","
                                size="large"
                                step={0.0001}
                                min={0}
                                className="w-100"
                                onChange={value => this.setState({ stock_opening_quantity: value })}
                              />,
                            )}
                          </InputGroup>

                        </Form.Item>

                        { !! this.state.stock_opening_quantity &&
                          <Form.Item label={(<span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>)}
                                     {...this.formItemLayout}
                          >
                            {getFieldDecorator('stock_opening[date]', {
                              initialValue: moment(),
                              rules: [{ required: true }],
                              validateTrigger: false,
                            })(
                              <DatePicker
                                format="DD.MM.YYYY HH:mm"
                                showTime={{ format: 'HH:mm' }}
                                size="large"
                                className="w-100"
                              />,
                            )}
                          </Form.Item>}

                      </div>
                      <Divider>
                        <Icon type="star-o"/> {this.props.langData['Attributes']}
                      </Divider>
                      <div className="form-block">
                        <Form.Item label={(
                          <span><Icon type="database"/><p>{this.props.langData['Attribute'] + ' 1'}</p></span>
                        )}
                                   {...this.formItemLayout}
                        >

                          <InputGroup size="large">
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator('first', { validateTrigger: false })(
                                  <Input disabled={true} size="large"/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator(
                                  'product[attributes][0][attribute_id]',
                                  {
                                    rules: [{ required: this.state.attributeGroups.filter(x => x.is_first).length > 0 }],
                                    validateTrigger: false,
                                  },
                                )(
                                  <Select
                                    allowClear
                                    filterOption={selectFilterLowerCase}
                                    size="large"
                                    suffixIcon={<Icon type="caret-down"/>}
                                    showSearch
                                    style={{ width: '100%' }}
                                    disabled={this.state.attributeGroups.filter(x => x.is_first).length === 0}
                                  >
                                    {this.state.attributeGroups.filter(x => x.is_first)[0] && this.state.attributeGroups.filter(x => x.is_first)[0].attribute_group.attributes.map(x => {
                                      return <Option value={x.attribute_id} key={x.attribute_id}>{x.title}</Option>
                                    })}
                                  </Select>,
                                )}
                              </Form.Item>
                            </Col>
                          </InputGroup>
                        </Form.Item>
                        <Form.Item label={(
                          <span><Icon type="database"/><p>{this.props.langData['Attribute'] + ' 2'}</p></span>
                        )}
                                   style={{ marginBottom: 0 }}
                                   {...this.formItemLayout}
                        >

                          <InputGroup size="large">
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator('second', { validateTrigger: false })(
                                  <Input disabled={true} size="large"/>,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item hasFeedback>
                                {getFieldDecorator(
                                  'product[attributes][1][attribute_id]',
                                  {
                                    rules: [{ required: this.state.attributeGroups.filter(x => ! x.is_first).length > 0 }],
                                    validateTrigger: false,
                                  },
                                )(
                                  <Select
                                    allowClear
                                    filterOption={selectFilterLowerCase}
                                    size="large"
                                    suffixIcon={<Icon type="caret-down"/>}
                                    showSearch
                                    style={{ width: '100%' }}
                                    disabled={this.state.attributeGroups.filter(x => ! x.is_first).length === 0}
                                  >
                                    {this.state.attributeGroups.filter(x => ! x.is_first)[0] && this.state.attributeGroups.find(x => ! x.is_first) && this.state.attributeGroups.find(x => ! x.is_first).attribute_group.attributes.map(x => {
                                      return <Option value={x.attribute_id} key={x.attribute_id}>{x.title}</Option>
                                    })}
                                  </Select>,
                                )}
                              </Form.Item>
                            </Col>
                          </InputGroup>
                        </Form.Item>
                      </div>
                      <Divider/>
                      <div className="form-block">
                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Buying Price']}</p></span>
                        )}
                                   {...(this.props.isModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('product[buying_price]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                min={0.0001} step={0.0001}
                                decimalSeparator=","
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}
                            {getFieldDecorator('product[buying_currency]', {
                              initialValue: this.props.fixed_exchange_rate,
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                suffixIcon={<Icon type="caret-down"/>}
                                onSelect={val => this.setState({ selectedBuyingCurrency: currencyData.find(x => x.code === val) })}
                              >
                                {currencyData.map((x, k) => {
                                  return <Option value={x.code} key={k}>{x.symbol}</Option>
                                })}
                              </Select>,
                            )}
                          </InputGroup>

                        </Form.Item>

                        <Form.Item label={(
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Selling Price']}</p></span>
                        )}
                                   {...(this.props.isModal ? this.formItemLayout : this.formItemLayoutHalf)}
                        >
                          <InputGroup compact style={{ display: 'flex' }}>
                            {getFieldDecorator('product[selling_price]', { validateTrigger: false })(
                              <InputNumber
                                size="large"
                                min={0.0001} step={0.0001}
                                decimalSeparator=","
                                style={{ marginRight: '0px', width: '100%' }}
                              />,
                            )}
                            {getFieldDecorator('product[selling_currency]', {
                              initialValue: this.props.fixed_exchange_rate,
                              validateTrigger: false,
                            })(
                              <Select
                                size="large"
                                suffixIcon={<Icon type="caret-down"/>}
                                onSelect={val => this.setState({ selectedSellingCurrency: currencyData.find(x => x.code === val) })}
                              >
                                {currencyData.map((x, k) => {
                                  return <Option value={x.code} key={k}>{x.symbol}</Option>
                                })}
                              </Select>,
                            )}
                          </InputGroup>

                        </Form.Item>
                      </div>

                    </Form>
                  </Card>
                  <Card loading={loadingQuery} bodyStyle={{ padding: 0, marginTop: 20 }} bordered={false}>
                    { ! this.props.isModal && <React.Fragment>
                      <Divider>
                        <Icon type="star-o"/> {this.props.langData['Additional Charges']}
                      </Divider>

                      <Table
                        columns={this.columns}
                        rowKey={record => record.id}
                        dataSource={this.state.additional_charges}
                        pagination={false}
                        rowClassName="white"
                        scroll={{ x: 700, y: 500 }}
                        onRow={record => {
                          if (this.props.sizeWidth <= 576)
                            return { onClick: () => this.rowItemClick(record) }
                        }}
                      />

                      <Button
                        style={{ marginLeft: 16, marginTop: 14 }}
                        icon="plus-circle-o"
                        onClick={this.addAdditionalCharge}
                        disabled={this.state.additional_charges.filter(x => x.additional_charge_id === null).length}
                      >
                        {this.props.langData['Add']}
                      </Button>

                      {this.state.modalAdditionalChargeSearch && (
                        <AdditionalChargeSearchModal
                          callback={this.additionalChargeSearchModalCallback}
                          modalClose={() => this.setState({ modalAdditionalChargeSearch: false })}
                        />
                      )}

                      {this.state.modalAdditionalCharge && (
                        <ProductAdditionalCharge
                          editAdditionalCharge={this.editAdditionalCharge}
                          additional_charge={this.state.modalAdditionalCharge}
                          modalClose={this.modalClose}
                          selectedBuyingCurrency={this.state.selectedBuyingCurrency.symbol}
                          selectedSellingCurrency={this.state.selectedSellingCurrency.symbol}
                        />
                      )}
                    </React.Fragment>}
                    <div className="form-block">
                      <Form.Item>
                        <Button
                          loading={loadingMutation}
                          type="primary" size="large" className="default-button"
                          style={{ marginRight: 5 }}
                          onClick={() => this.onSubmit(mutate)}
                        >
                          {this.props.langData['Save']}
                        </Button>
                      </Form.Item>
                    </div>
                  </Card>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

}

SubProductForm.propTypes = {
  langData: PropTypes.object,
  lang: PropTypes.string,
  product_id: PropTypes.number,
  main_product_id: PropTypes.number,
  form: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  fixed_exchange_rate: PropTypes.string,
  isModal: PropTypes.func,
  refetch: PropTypes.func,
}

export default connect(mapStateToProps)(SubProductForm)
