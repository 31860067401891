import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Input, Button, Card, Divider } from 'antd'
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons'

import {
  formErrorFieldsNameLocalize,
  updateEmptyAndUndefined,
  deleteNull, successToast,
} from '../../../helpers/helper'

import { postPanelApi } from '../../../api'
import routes from '../../../routes'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 8 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 16 },
  },
}

function NewManagerForm(props) {

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {

    props.form.validateFields(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }


      updateEmptyAndUndefined(values)

      values = deleteNull(values)

      setSubmitting(true)

      postPanelApi('/manager/new-submit', values).then(result => {

        if (result) {

          successToast(result.message)

          props.history.replace(routes.MANAGER_LIST)

        } else {

          setSubmitting(false)

        }

      })

    })
  }

  const { getFieldDecorator } = props.form

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><UserAddOutlined/> {props.langData['New Manager']}</span>}
      bordered={false}
    >
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default"
      >
        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{props.langData['Name Surname']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('name_surname', {
              rules: [{ max: 250 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('e_mail', {
              rules: [{ max: 100 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="key"/><p>{props.langData['Password']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('password', {
              rules: [
                { max: 32 },
                { min: 6 },
                { required: true },
              ],
              validateTrigger: false,
            })(
              <Input size="large" type="password"/>,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button
              type="primary"
              size="large"
              loading={submitting}
              htmlType="submit"
              className="default-button">
              <PlusOutlined/> {props.langData['Add']}
            </Button>
          </Form.Item>
        </div>

      </Form>
    </Card>
  )
}

NewManagerForm.propTypes = {
  manager_id: PropTypes.number,
  langData: PropTypes.object,
  isMe: PropTypes.bool,
  form: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
})(NewManagerForm)
