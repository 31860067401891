import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { connect } from 'react-redux'

import routes from '../../../routes'

import { postPanelApi } from '../../../api'
import { generatePath } from 'react-router-dom'

function ManagerList(props) {

  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])

  const columns = [
    {
      title: props.langData['Name Surname'],
      dataIndex: 'name_surname',
      sorter: true,
      width: '40%',
      key: 'name_surname',
    },
    {
      title: props.langData['E-Mail'],
      dataIndex: 'e_mail',
      key: 'e_mail',
      width: '40%',
    },
    {
      title: props.langData['Status'],
      dataIndex: 'status',
      sorter: true,
      className: 'text-right',
      key: 'status',
      width: '20%',
    },
  ]

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  useEffect(() => {

    setLoading(true)

    postPanelApi('/manager/list').then(result => {

      if (result) {

        setItems(result.items)

      }

      setLoading(false)

    })

  }, [])

  const rowItemClick = record => {
    if (record.is_me) {
      props.history.push(routes.MANAGER_INFO)
    } else {
      props.history.push(generatePath(routes.MANAGER_UPDATE, { id: record.manager_id }))
    }
  }

  return (
    <Table
      columns={columns}
      rowKey={record => record.manager_id}
      dataSource={items}
      loading={loading}
      pagination={false}
      onRow={record => ({
        onClick: () => rowItemClick(record),
      })}
      rowClassName="white"
      scroll={{ x: 700, y: 500 }}
    />
  )

}

ManagerList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Manager' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: routes.MANAGER_LIST } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(ManagerList)
