const messages = {
  'E-Invoice Print': 'E-Fatura Yazdır',
  'E-Archive Print': 'E-Arşiv Yazdır',

  'E-Invoice Cancel': 'E-Fatura İptal',
  'E-Archive Cancel': 'E-Arşiv İptal',

  'Send E-Mail': 'E-Posta Gönder',

  'Login Successful': 'Giriş Başarılı',
  'Login': 'Giriş Yap',
  'Logout': 'Çıkış Yap',
  'Loading': 'Yükleniyor',
  'or': 'ya da',
  'Username': 'Kullanıcı Adı',
  'Password': 'Parola',
  'Register': 'Kayıt Ol',
  'Register Steps': 'Kayıt Aşamaları',
  'register now': 'Şimdi Kayıt Ol!',
  'Registration completed': 'Kayıt Tamamlandı',
  'Remember me': 'Beni Hatırla',
  'Forgot password': 'Şifremi Unuttum',
  'New Password': 'Yeni Şifre',
  'Redirecting': 'Yönlendiriliyorsunuz',
  'Logged in': 'Giriş Yapıldı',
  'Type': 'Türü',
  'Transaction Type': 'İşlem Türü',
  'Invoice Type': 'Fatura Türü',
  'Save': 'Kaydet',
  'Save & New': 'Kaydet & Yeni',
  'Update': 'Güncelle',
  'E-Mail': 'E-Posta',
  'Website': 'Web Sitesi',
  'Web Service': 'Web Servis',
  'New': 'Yeni',
  'Add': 'Ekle',
  'Edit': 'Düzenle',
  'Delete': 'Sil',
  'Customize': 'Özelleştir',
  'Delete Opening Stock': 'Stok Açılışını Sil',
  'List': 'Listesi',
  'Open': 'Açık',
  'Close': 'Kapat',
  'Closed': 'Kapalı',
  'Yes': 'Evet',
  'No': 'Hayır',
  'Active': 'Aktif',
  'Passive': 'Pasif',
  'Status': 'Durumu',
  'E-Invoice or E-Archive Created': 'E-Fatura ya da E-Arşiv Oluşturuldumu',
  'Settings': 'Ayarlar',
  'Preferences': 'Tercihler',
  'Actions': 'İşlemler',
  'Action': 'İşlem',
  'More': 'Daha Fazla',
  'Step': 'Adım',
  'Next': 'İlerle',
  'Steps already completed': 'Adımlar zaten tamamlanmış',
  'This step is already complete': 'Bu adım zaten tamamlanmış',
  'This step has not yet been passed': 'Bu aşamaya henüz geçilmemiş',
  'Select safe': 'Kasa Seçiniz',
  'Add Currency': 'Kur Ekle',
  'items': 'Kayıt',
  'Products': 'Ürünler',
  'required': 'zorunludur',
  'Optional': 'İsteğe Bağlı',
  'Server error': 'Sunucu hatası',
  'Network error': 'Ağ hatası',
  'Incorrect products entered': 'Hatalı ürünler girilmiş',
  'Incorrect additional charges entered': 'Hatalı ek ücretler girilmiş',
  'Invoices Processed': 'İşlenen Faturalar',
  'Amount processed': 'İşlenen Tutar',
  'Add Product': 'Ürün Ekle',
  'Edit Product': 'Ürün Düzenle',
  'Add Additional Charge': 'Ek Ücret Ekle',
  'Edit Additional Charge': 'Ek Ücret Düzenle',
  'Filter': 'Filtrele',
  'Choose': 'Seçiniz',
  'TODAY': 'BUGÜN',
  'Today': 'Bugün',
  'Late': 'Geciken',
  'Lated': 'Gecikmiş',
  'Export Sales Invoices': 'Satış Faturalarını Aktar',
  'Export Purchase Invoices': 'Alış Faturalarını Aktar',
  'Create Excel': 'Excel Oluştur',
  'Add New One': 'Yeni Ekle',
  'Import from Excel': 'Excel Yükle',
  'Click or drag .xls file to this area to upload': 'Yükleme yapmak için bu alana tıklayınız veya excel dosyanızı bu alana sürükleyip bırakınız.',
  'Your products recorded in our database will be updated. Other products will be recorded in the database.': 'Veri tabanımızda kayıtlı ürünleriniz güncellenecektir. Diğer ürünler veri tabanına kayıt edilecektir.',
  'Wait for finished uploading.': 'Yüklemenin bitmesini bekleyiniz.',
  'The products have been imported successfully.': 'ÜRünler başarıyla içeri aktarılmıştır.',
  'Your products are imported': 'Ürünleriniz içeri aktarılıyor',
  'Please select a valid excel file.': 'Lütfen geçerli bir excel dosyası seçiniz.',
  'An unexpected error was encountered while importing.': 'İçeri aktarma sırasında beklenmeyen bir hata ile karşılaşıldı.',
  'To make a different file selection, remove the file you previously selected.': 'Farklı bir dosya seçimi yapmak için önceden seçtiğiniz dosyayı kaldırınız.',
  'Download template': 'Şablon İndir',
  'Import': 'İçeri Aktar',
  'Has Debit': 'Tahsilat Var',
  'No Debit': 'Tahsilat Yok',
  'Has Credit': 'Ödeme Var',
  'No Credit': 'Ödeme Yok',
  'He has debit': 'Borcu var',
  'He has credit': 'Alacağı var',
  'Total Customers': 'Toplam Cari',
  'Total Products': 'Toplam Ürün',
  'Total Sales Invoices': 'Toplam Satış Faturası',
  'Total Purchase Invoices': 'Toplam Alış Faturası',
  'Invoice Transactions': 'Fatura Hareketleri',
  'Save Customer': 'Cari Kaydet',
  'Save Product': 'Ürün Kaydet',
  'Save Safe': 'Kasa Kaydet',
  'Pay': 'Ödeme Yap',
  'Get Payment': 'Ödeme Al',
  'pay': 'odemeyap',
  'getpayment': 'odemeal',
  'Paid': 'Ödenmiş',
  'Unpaid': 'Ödenmemiş',
  'Next Value': 'Sonraki Değer',
  'Current Value': 'Mevcut Değer',
  'Pattern': 'Desen',
  'Start': 'Başlangıç',
  'End': 'Bitiş',
  'Show All Columns': 'Tüm Sütünları Göster',
  'Number': 'Sayı',
  'Defaults': 'Varsayılanlar',
  'Invoice Template': 'Fatura Şablonu',
  'Default': 'Varsayılan',
  'Source': 'Kaynak',
  'Download Pattern': 'İndirme Deseni',
  'Print': 'Yazdır',
  'Process Selected Currency': 'Seçili Kuru İşle',
  'Design': 'Tasarla',
  'Invoice Design': 'Fatura Tasarla',
  'Waybill Design': 'İrsaliye Tasarla',
  'Size': 'Boyut',
  'Page Size': 'Sayfa Boyutu',
  'Element Size': 'Eleman Boyutu',
  'Vertical': 'Dikey',
  'Horizontal': 'Yatay',
  'Specific': 'Özel',
  'Customer Information': 'Cari Bilgileri',
  'Product Table': 'Ürün Tablosu',
  'Total Amount Information': 'Toplam Miktari Bilgileri',
  'Location': 'Konum',
  'Text Align': 'Metin Hizalama',
  'Bold/Italic': 'Kalın/Yatay',
  'Font Size': 'Yazı Boyutu',
  'Total With Text': 'Yazı İle Toplam',
  'Lated Debit': 'Gecikmiş Tahsilat',
  'Total Charged': 'Toplam Tahsil Edilecek',
  'Total Payable': 'Toplam Ödenecek',
  'Lated Credit': 'Gecikmiş Ödeme',
  'Invoice Information': 'Fatura Bilgileri',
  'Waybill Information': 'İrsaliye Bilgileri',
  'Show Currency': 'Para Birimini Göster',
  'Your Message': 'Mesajınız',
  'Subject': 'Konu',
  'Comments': 'Yorumlar',
  'Send': 'Gönder',
  'At least one product or additional charge must be entered': 'En az bir ürün veya ek ücret girilmelidir',
  'The delivery address is the same as the billing address': 'Teslimat adresi fatura adresi ile aynı',
  'This product is a main product': 'Bu ürün bir ana üründür',
  'This product is a ghost product': 'Bu ürün bir hayalet üründür',
  'sales': 'satis',
  'purchase': 'alis',
  'customer-account-opening': 'cari-hesap-acilisi',
  'safe-account-opening': 'kasa-hesap-acilisi',
  'money-input': 'para-giris',
  'money-output': 'para-cikis',
  'Report': 'Rapor',
  'Recoveries Report': 'Tahsilatlar Raporu',
  'Incomes': 'Gelirler',
  'Expenses': 'Giderler',
  'Apply': 'Uygula',
  'Income Expense Report': 'Gelir Gider Raporu',
  'VAT Report': 'KDV Raporu',
  'Credits Report': 'Tahsilatlar Raporu',
  'Transaction Report': 'Hareketler Raporu',
  '1-30 Day Delayed': '1-30 Gün Geçikmiş',
  '31-60 Day Delayed': '31-60 Gün Geçikmiş',
  '61-90 Day Delayed': '61-90 Gün Geçikmiş',
  '91-120 Day Delayed': '91-120 Gün Geçikmiş',
  '120+ Day Delayed': '120 Gün+ Gecikmiş',
  'Calculated VAT': 'Hesaplanan KDV',
  'VAT to be Deducted': 'İndirilecek KDV',
  'Total Calculated VAT': 'Toplam Hesaplanan KDV',
  'Total VAT to be Deducted': 'Toplam İndirilecek KDV',
  'Piece': 'Adet',
  'KVKK Regulations': 'KVKK Yönetmeliği',
  'Top Customers': 'En Çok Satış Yapılan Müşteriler',
  'Safe Report': 'Kasa Raporu',
  'Monthly': 'Aylık',
  'Weekly': 'Haftalık',
  'Invoice Category Income Expense Report': 'Fatura Kategorisi Gelir Gider Raporu',
  'Category Based Report': 'Kategori Bazlı Rapor',
  'Verify Your JetOfis Account': 'JetOfis Hesabınızı Doğrulayın',
  'Resubmit': 'Tekrar Gönder',
  'Copy': 'Kopyala',
  'Share Mail Invoice': 'Fatura Mail Paylaş',
  'Print Invoice': 'Fatura Yazdır',
  'Print Waybill': 'İrsaliye Yazdır',
  // common messages
  'x not found': x => `${messages[x]} bulunamadı`,

  // support page
  'An unexpected problem occurred during the opening of the page': 'Sayfanın açılması sırasında beklenmeyen bir sorun meydana geldi',

  'Hook': 'Hook',
  'Starting': 'Başlangıç',
  'Quick Menu': 'Hızlı Menü',
  'Categories': 'Kategoriler',
  'Categorized': 'Kategorili',
  'Uncategorized': 'Kategorisiz',
  'Invoice Categories': 'Fatura Kategorileri',
  'Category': 'Kategori',
  'category': 'kategori',
  'New Category': 'Yeni Kategori',
  'Update Category': 'Kategori Güncelle',
  'Select Date': 'Tarih Seçiniz',
  'Please choose date range': 'Lütfen tarih aralığı seçin!',
  'Start Date': 'Başlangıç Tarihi',
  'End Date': 'Bitiş Tarihi',

  'Customer': 'Cari',
  'customer': 'cari',
  'Customer List': 'Cari Listesi',
  'New Customer': 'Yeni Cari',
  'Update Customer': 'Cari Güncelle',
  'Customer Category': 'Cari Kategori',
  'Customer Detail': 'Cari Detay',
  'Customer Search': 'Cari Ara',
  'Customer Defaults': 'Cari Varsayılanları',

  'Has Balance': 'Bakiyesi Olan',
  'Has No Balance': 'Bakiyesi Olmayan',
  'Borrower Customer': 'Borçlu Müşteri',
  'Lender Customer': 'Alacaklı Müşteri',

  'Customer Transaction Extract': 'Cari Ekstresi',
  'Sequence': 'Sayaç',
  'sequence': 'sayaş',
  'Sequences': 'Sayaçlar',
  'New Sequence': 'Yeni Sayaç',
  'Update Sequence': 'Sayaç Güncelle',

  'Support': 'Destek',
  'Supports': 'Destek Taleplerim',
  'New Support': 'Yeni Destek',
  'Support Detail': 'Destek Detay',
  'Add Reply': 'Cevap Ekle',
  'Departmant': 'Departman',
  'Your Ticket': 'Talebiniz',

  'My Packages': 'Paketlerim',
  'New Package': 'Yeni Paket',

  'Template': 'Şablon',
  'template': 'sablon',
  'Templates': 'Fatura Şablonları',
  'New Template': 'Yeni Şablon',
  'Update Template': 'Şablon Güncelle',

  'Waybill Template': 'İrsaliye Şablonu',
  'waybill template': 'irsaliye şablonu',
  'Waybill Templates': 'İrsaliye Şablonları',
  'New Waybill Template': 'Yeni İrsaliye Şablonu',
  'Update Waybill Template': 'İrsaliye Şablonunu Güncelle',

  'Product': 'Ürün',
  'product': 'urun',
  'Product List': 'Ürün Listesi',
  'New Product': 'Yeni Ürün',
  'Update Product': 'Ürün Güncelle',
  'Product Category': 'Ürün Kategori',
  'Product Detail': 'Ürün Detay',
  'Product Search': 'Ürün Ara',
  'Product Defaults': 'Ürün Varsayılanları',
  'Create Product Copy': 'Ürün Kopyası Oluştur',
  'Main Product': 'Ana Ürün',

  'Additional Charges': 'Ek Ücretler',
  'Additional Charge': 'Ek Ücret',
  'Additional charge': 'Ek ücret',
  'New Additional Charge': 'Yeni Ek Ücret',
  'Update Additional Charge': 'Ek Ücret Güncelle',
  'Additional Charge Category': 'Ek Ücret Kategori',
  'Additional Charge Categories': 'Ek Ücret Kategoriler',
  'Additional Charge Search': 'Ek Ücret Ara',
  'Copy Additional Charge': 'Ek Ücret Kopyala',
  'Duplicate': 'Kopya',

  'Your invoice has been created': 'Faturanız oluşturuldu!',
  'Your invoice creation was successful': 'Fatura oluşturma işleminiz başarıyla gerçekleşti.',
  'You received a payment': 'Bir ödeme aldınız!',
  'Your payment has been successful': 'Tahsilat işleminiz başarıyla gerçekleşti.',
  'Stock Decreased': 'Faturanız oluşturuldu ve ürün stoğu azaldı!',
  'The stock of named product decreased': x => `Faturanız oluşturuldu ve ${x} kritik stok seviyesinde.`,
  'E-Invoice will be cancelled. Are you sure?': x => `${x} iptal edilecek. Emin misiniz?`,

  'Sub Products': 'Alt Ürünler',
  'Sub Product': 'Alt Ürün',
  'Sub Product Attributes': 'Alt Ürün Nitelikleri',
  'New Sub Product': 'Yeni Alt Ürün',
  'Attributes': 'Nitelikler',
  'Attribute': 'Nitelik',
  'First Attribute Group': 'Birinci Nitelik Grubu',
  'Second Attribute Group': 'İkinci Nitelik Grubu',
  'Attribute Group': 'Nitelik Grubu',
  'Attribute Groups': 'Nitelik Grupları',
  'Attribute New': 'Yeni Nitelik',
  'Update Attribute': 'Nitelik Güncelle',
  'Change Default Attribute Groups': 'Varsayılan Nitelik Grupları',
  'Default attribute groups is not defined.': 'Varsayılan nitelik grupları tanımlanmamış.',
  'At least one attribute must be selected': 'En az bir nitelik seçilmelidir!',

  'Invoice': 'Fatura',
  'invoice': 'fatura',
  'Invoice List': 'Fatura Listesi',
  'New Invoice': 'Yeni Fatura',
  'Create Invoice': 'Fatura Oluştur',
  'Update Invoice': 'Fatura Güncelle',
  'Invoice Category': 'Fatura Kategori',
  'Invoice Detail': 'Fatura Detay',
  'Sales Invoice': 'Satış Faturası',
  'Purchase Invoice': 'Alış Faturası',
  'Invoice Defaults': 'Fatura Varsayılanları',
  'Payment Means': 'Ödeme Aracı',
  'Payment Agent Name': 'Ödeme Aracı Adı',
  'Payment Due Date': 'Ödeme Tarihi',
  'Optional Payment Due Date': 'Ödeme tarihi girilmez ise faturadaki ödeme tarihi alınacaktır. Ödeme tarihi yoksa fatura tarihi alınacaktır',
  'Party Name': 'Gönderi Taşıyan',
  'Instruction Note': 'Talimat Notu',
  'EArhhive empty Customer ID': 'Müşterinizin kimlik numarası yoksa 11111111111 olarak girilip devam edilecektir',

  'Waybill': 'İrsaliye',
  'Waybill List': 'İrsaliye Listesi',
  'Waybill Type': 'İrsaliye Türü',
  'Sending': 'giden',
  'Incoming': 'gelen',
  'Sending Waybill': 'Giden İrsaliye',
  'Incoming Waybill': 'Gelen İrsaliye',
  'Date of Shipment': 'Sevk Tarihi',
  'Waybill No': 'İrsaliye No',
  'New Sending Waybill': 'Yeni Giden İrsaliye',
  'New Incoming Waybill': 'Yeni Gelen İrsaliye',
  'Date Of Shipment': 'Sevk Tarihi',
  'Invoice With Waybill': 'İrsaliyeli Fatura',
  'Sales Without Waybill': 'İrsaliyesiz Alış',
  'Invoice With Waybill Info': 'Sevkiyatı fatura ile birlikte yapılan satıştır. Sonrasında irsaliye oluşturulamaz. Ürün stoğu düşer.',
  'Sales Without Waybill Info': 'Sevkiyatsız ya da daha sonra sevkiyatı yapılacak olan satıştır. Ürün stoğu düşmez.',
  'Create Sending Waybill': 'Giden İrsaliye Oluştur',
  'Create Incoming Waybill': 'Gelen İrsaliye Oluştur',
  'Invoice With Waybilll': 'İRSALİYELİ FATURA',
  'Sales Without Waybilll': 'İRSALİYESİZ SATIŞ',
  'Purchase Without Waybill': 'İRSALİYESİZ ALIŞ',
  'Pieces waybill was created': d => `${d} Adet İrsaliye Oluşturuldu`,
  'Waybill Detail': 'İrsaliye Detay',
  'Waybill Country': 'İrsaliye Ülke',
  'Waybill Address': 'İrsaliye Adres',
  'Waybill City/Town': 'İrsaliye İl/İlçe',
  'Incoming Waybill Update': 'Gelen İrsaliye Güncelle',
  'Sending Waybill Update': 'Giden İrsaliye Güncelle',
  'View Invoice': 'Faturayı Görüntüle',
  'Waybill Invoice': 'İrsaliye Faturası',
  'Tc Undefined': 'Carinizde uygun bir T.C kimlik numarası yok ise T.C kimlik numaranız 11111111111 olarak atanacaktır. Devam etmek istiyor musunuz',
  'Invoice Match': 'Fatura Eşleştir',
  'Match Invoice': 'İrsaliyenizi bu fatura ile eşleştirmek istediğinize emin misiniz',

  'Safe': 'Kasa',
  'Safe List': 'Kasa Listesi',
  'New Safe': 'Yeni Kasa',
  'Update Safe': 'Kasa Güncelle',
  'Safe Detail': 'Kasa Detay',

  'Transaction': 'Hareket',
  'transaction': 'islem',
  'Transaction List': 'Hareket Listesi',
  'Money Input': 'Para Giriş',
  'Money Output': 'Para Çıkış',
  'Transaction Category': 'İşlem Kategori',
  'Transaction Detail': 'İşlem Detay',

  'Manager': 'Yönetici',
  'manager': 'yonetici',
  'Manager List': 'Yönetici Listesi',
  'New Manager': 'Yeni Yönetici',
  'Update Manager': 'Yönetici Güncelle',
  'Manager Information': 'Yönetici Bilgileri',
  'Token is invalid': 'Token Geçersiz',
  'Your transaction was successfully completed': 'İşleminiz başarılı bir şekilde gerçekleştirildi',
  'Email verification completed successfully': 'E-Posta doğrulama işlemi başarılı bir şekilde tamamlandı',

  'User': 'Kullanıcı',
  'Company': 'Firma',
  'Information': 'Bilgileri',
  'Update User Information': 'Kullanıcı Bilgileri Güncelleme',
  'User Information': 'Kullanıcı Bilgileri',
  'Company Information': 'Firma Bilgileri',
  'Commissioner Information': 'Yetkili Bilgileri',
  'Update User Settings': 'Kullanıcı Ayarları Güncelleme',
  'My Manager Information': 'Yönetici Bilgilerim',
  'Carrier Company Information': 'Taşıyıcı Şirket Bilgileri',
  'Carrier Company Chauffeur Information': 'Taşıyıcı Şirket Şoför Bilgileri',
  'Surname': 'Soyad',
  'Carrier Title': 'Taşıyıcı Unvan',
  'Carrier Vehicle Plate': 'Taşıyıcı Araç Plakası',
  'Carrier Tax Identification Number': 'Taşıyıcı Vergi Kimlik Numarası',
  'Actual Shipping Date': 'Fiili Sevk Tarihi',
  'E-Invoice Warn': 'Taşıyıcı Unvan veya Taşıyıcı Şoför Adı alanlarından en az biri doldurulmalıdır.',

  'General Settings': 'Genel Ayarlar',
  'Integration Web Service Settings': 'Entegrasyon Web Servis Ayarları',
  'T-Soft Web Service Settings': 'T-Soft Web Servis Ayarları',
  'Invoice Settings': 'Fatura Ayarları',
  'Invoice & E-Invoice & E-Archive Settings': 'Fatura & E-Fatura & E-Arşiv Ayarları',
  'E-Invoice & E-Archive Settings': 'E-Fatura & E-Arşiv Ayarları',
  'Product Settings': 'Ürün Ayarları',
  'E-Waybill Settings': 'E-İrsaliye Ayarları',
  'E-Waybill Invoice Settings': 'E-İrsaliye Ayarları',
  'You can configure your T-Soft Web Service settings using this menu': 'Bu menüyü kullanarak T-Soft Web ayarlarınızı yapılandırabilirsiniz',
  'You can configure your general settings using this menu': 'Bu menüyü kullanarak genel ayarlarınızı yapılandırabilirsiniz',
  'You can configure your integration settings using this menu': 'Bu menüyü kullanarak entegrasyon ayarlarınızı yapılandırabilirsiniz',
  'You can configure your invoice settings using this menu': 'Bu menüyü kullanarak fatura ayarlarınızı yapılandırabilirsiniz',
  'You can configure your product settings using this menu': 'Bu menüyü kullanarak ürün ayarlarınızı yapılandırabilirsiniz',
  'You can configure your E-Waybill settings using this menu': 'Bu menüyü kullanarak E-İrsaliye ayarlarınızı yapılandırabilirsiniz',
  'Should modal be switched on when adding product and additional charge to the invoice': 'Faturaya ürün ve ek ücret eklenirken modal açılsın mı?',
  'Should modal be opened when adding additional charge to the product': 'Ürüne ek ücret eklenirken modal açılsın mı?',
  'Amount of increase in decimal numbers': 'Ondalık sayılardaki artış miktarı',
  'Critical Stock Alert Count': 'Kritik Stok Uyarı Miktarı',
  'Product name view on invoice': 'Faturada ürün adı görünümü',
  'Is Activate Partial Vat': 'Parçalı KDV aktif edilsin mi?',
  'E-Invoice to be sent as e-mail?': 'E-Fatura mail olarak gönderilsin mi?',
  'Use Person Name': 'Şahıs İsmini Kullan',
  'Send Supplier Code Instead of Product Code': 'Ürün Kodu Yerine Tedarikçi Kodu Gönderilsin',
  'General Settings Success': 'Genel Ayarlarınız Kaydedildi.',
  'Invoice Settings Success': 'Fatura Ayarlarınız Kaydedildi.',
  'Product Settings Success': 'Ürün Ayarlarınız Kaydedildi.',
  'Web Service Settings Success': 'T-Soft Web Servis Ayarlarınız Kaydedildi.',
  'Integration Settings Success': 'Entegrasyon Ayarlarınız Kaydedildi.',
  'E-Waybill Settings Success': 'E-İrsaliye Ayarlarınız Kaydedildi.',
  'Product Name': 'Ürün Adı',
  'Does use marketplace customer': 'Pazaryeri carisi kullanılsın mı?',
  'Marketplace Customer': 'Pazaryeri Carisi',

  'Create Return': 'İade Oluştur',
  'Return Invoice': 'İade Faturası',
  'Return Sales Invoice': 'Satış İade Faturası',
  'Return Purchase Invoice': 'Alış İade Faturası',
  'Return': 'İade',
  'Get Payment to Return Invoice': 'İade Faturasına Ödeme Al',
  'Pay to Return Invoice': 'İade Faturasına Ödeme Yap',
  'This customer has debt': (d, e) => `Bu müşterinin ${d + ' ' + e} borcu bulunmaktadır.`,

  'Name': 'Adı',
  'Name Surname': 'Ad Soyad',
  'Balance': 'Bakiye',
  'Opening Balance': 'Açılış Bakiyesi',
  'Account Opening': 'Hesap Açılışı',
  'Customer Account Opening': 'Cari Hesap Açılışı',
  'Safe Account Opening': 'Kasa Hesap Açılışı',
  'Opening Stock': 'Stok Açılışı',
  'Currency': 'Döviz Kuru',
  'Fixed Exchange Rate': 'Varsayılan Para Birimi',
  'Stock': 'Stok',
  'Stock Tracking': 'Stok Takibi',
  'Barcode': 'Barkod',
  'Vat': 'Vergi',
  'Unit': 'Birim',
  'Selling Price': 'Satış Fiyatı',
  'Buying Price': 'Alış Fiyatı',
  'Legal Person': 'Tüzel Kişi',
  'Real Person': 'Gerçek Kişi',
  'Phone': 'Telefon',
  'Automatic Code': 'Otomatik Kod',
  'Code': 'Kod',
  'Country': 'Ülke',
  'City': 'İl',
  'Town': 'İlçe',
  'Address': 'Adres',
  'Invoice Address': 'Fatura Adres',
  'Invoice Country': 'Fatura Ülke',
  'City/Country': 'İl/Ülke',
  'Invoice City/Town': 'Fatura İl/İlçe',
  'Invoice Postal Code': 'Fatura Posta Kodu',
  'Delivery Address': 'Teslimat Adres',
  'Delivery Country': 'Teslimat Ülke',
  'Delivery City/Town': 'Teslimat İl/İlçe',
  'Delivery Postal Code': 'Teslimat Posta Kodu',
  'Waybill Postal Code': 'İrsaliye Posta Kodu',
  'Postal Code': 'Posta Kodu',
  'Id/Tax Identification Number': 'Kimlik No/Vergi Kimlik No',
  'Tax Identification Number': 'Vergi Kimlik No',
  'Identification Number': 'Kimlik No',
  'Tax Administration': 'Vergi Dairesi',
  'Explanation': 'Açıklama',
  'Marketplace Order No': 'Pazaryeri Sipariş No',
  'Marketplace': 'Pazaryeri',
  'Credit': 'Ödeme',
  'Credits': 'Ödemeler',
  'credit': 'odeme',
  'Debit': 'Tahsilat',
  'Debits': 'Tahsilatlar',
  'debit': 'tahsilat',
  'Input': 'Giriş',
  'Output': 'Çıkış',
  'input': 'giris',
  'output': 'cikis',
  'Date': 'Tarih',
  'Textbox': 'Metin Kutusu',
  'Expiry Date': 'Vade Tarihi',
  'Date Format': 'Tarih Formatı',
  'Amount': 'Tutar',
  'Price': 'Fiyat',
  'Commissioner Name Surname': 'Yetkili Ad Soyad',
  'Commissioner E-Mail': 'Yetkili E-Posta',
  'Commissioner Phone': 'Yetkili Telefon',
  'Company Name': 'Firma Adı',
  'Title': 'Ünvan',
  'First Name': 'Şahıs Adı',
  'Last Name': 'Şahıs Soyadı',
  'Title2': 'Başlık',
  'Total': 'Toplam',
  'Interim Total': 'Ara Toplam',
  'Grand Total': 'Genel Toplam',
  'Total VAT': 'Toplam KDV',
  'Total VAT Amount of Sales': 'Satışların Toplam KDV Tutarı',
  'Total VAT Amount of Purchase': 'Alışların Toplam KDV Tutarı',
  'Remaining': 'Kalan',
  'Discount': 'İskonto',
  'Total Discount': 'Toplam İskonto',
  'Percent': 'Yüzde',
  'Value': 'Değer',
  'Series': 'Seri',
  'Queue': 'Sıra',
  'Invoice No': 'Fatura No',
  'Quantity': 'Miktar',
  'Payment List': 'Ödeme Listesi',
  'Solved': 'Çözüldü',
  'Unsolved': 'Çözülmemiş',
  'In_process': 'İşlemde',
  'Pending': 'Beklemede',
  'Corporate Code': 'Şirket Kodu',
  'Login Name': 'Giriş Adı',
  'E-Invoice': 'E-Fatura',
  'E-Archive': 'E-Arşiv',
  'E-Despatch': 'E-İrsaliye',
  'Web Hook': 'Web Kancası',
  'Web Hook List': 'Web Kancası Listesi',
  'New Web Hook': 'Yeni Web Kancası',
  'Web Hook Detail': 'Web Kancası Detay',
  'Http Status Code': 'Http Durum Kodu',
  'Method URI': 'Metod URI',
  'Store URI': 'Mağaza URI',
  'Create': 'Ekleme',
  'Methods': 'Metodlar',
  'Method': 'Metod',
  'Other': 'Diğer',
  'Where to get product informations': 'Ürün bilgileri nereden alınsın?',
  'Describe of where to get product informations': d => `Faturadaki ürün bilgileriniz ${d} platformundan alınacaktır.`,
  'Pieces return invoice was created': d => `${d} Adet İade Faturası Oluşturuldu`,
  'Payment Date': 'Ödeme Tarihi',
  'Expiry date is today': 'Vade tarihi bugün',

  'Add Sales Invoice': 'Satış Faturası Ekle',
  'Add Purchase Invoice': 'Alış Faturası Ekle',
  'Invoice Name': 'Fatura Adı',
  'Supplier Product Code': 'Tedarikçi Ürün Kodu',
  'Has Stock': 'Stoğu Olan',
  'Has No Stock': 'Stoğu Olmayan',

  'Previous Calculated Vat': 'Önceki Dönemden Devreden KDV',
  'Total Reduced VAT': 'Toplam İndirilecek KDV',
  'Payable VAT': 'Ödenecek KDV',
  'VAT Transferred To Next Period': 'Sonraki Döneme Devreden KDV',
  'VAT To Be Reduced For This Period': 'Bu Döneme Ait İndirilecek KDV',

  'E-Commerce Invoice Settings': 'E-Ticaret Fatura Ayarları',
  'E-Invoice Template Code': 'E-Fatura Şablon Kodu',
  'E-Archive Template Code': 'E-Arşiv Şablon Kodu',
  'Tax Exemption Code': 'Vergi Muafiyet Kodu',
  'Tax Exemption Description': 'Vergi Muafiyet Açıklaması',
  'Transporter Company Title': 'Taşıyıcı Firma Unvanı',
  'Transporter Company TIN': 'Taşıyıcı Firma VKN',
  'Transporter VKN No': 'Taşıyıcı VKN No',
  'Online Sales URL': 'İnternetten Satış Adresi',
  'Default Payment Means Code': 'Varsayılan Ödeme Aracı',
  'Default Instruction Note': 'Varsayılan Talimat Notu',

  'This Sale was Made Over the Internet': 'Bu satış internet üzerinden yapılmıştır. \nİrsaliye yerine geçer.',
  'Xlsx Export': 'Xlsx Dışarı Aktar',
  'Csv Export': 'Csv Dışarı Aktar',

  'Bank Name': 'Banka Adı',
  'IBAN No': 'IBAN No',
  'paying': 'odeme',

  'Credit Card': 'Kredi Kartı',
  'Pay at the Door': 'Kapıda Ödeme',
  'Eft Money Order': 'Eft / Havale',
  'Payment Agent': 'Ödeme Aracısı',
  'Payment Agent Name Speacil Status': 'Seçilen Faturaların ödeme aracı varsa varolan ödeme aracı yazılacaktır. Ödeme aracı yoksa yukarıda yazılan ödeme aracı alınacaktır',

  'Incoming E-Invoice': 'Gelen E-Fatura',
  'Incoming E-Invoice Box': 'Gelen E-Fatura Kutusu',
  'Incoming E-Invoice Box Detail': 'Gelen E-Fatura Kutusu Detay',
  'Total Price': 'Toplam Fiyat',
  'Invoice City': 'Fatura İl',
  'Invoice Town': 'Fatura İlçe',
  'Saving Status': 'Kaydedilme Durumu',
  'Show Transferred': 'Aktarılanları Göster',
  'Show Not Transferred': 'Aktarılmayanları Göster',
  'Matched': 'Eşleştirildi',
  'Imported': 'İçeri Aktarıldı',
  'Match customer and product': 'Cari ve ürün eşleştiriniz.',
  'Match Customer': 'Cari Eşleştir',
  'Match Product': 'Ürün Eşleştir',
  'Selected Customer': 'Seçilen Cari',
  'Transfer Steps': 'Aktarım Adımları',
  'Please match customer': 'Lütfen carinizi eşleştiriniz.',
  'Please match product': 'Lütfen ürününüzü eşleştiriniz.',
  'Please complete the import process': 'Lütfen içeri aktarma işlemini tamamlayınız.',
  'Customer match process completed': 'Cari eşleştirme işlemi tamamlandı.',
  'Product match process completed': 'Ürün eşleştirme işlemi tamamlandı.',
  'Import process completed': 'İçeri aktarma işlemi tamamlandı.',
  'Get Available Invoices': 'Mevcut faturaları getir',
  'It returns invoices for last 30 days': 'Son 30 güne ait faturaları getirir.',
  'Show Unsaved': 'Kaydedilmeyenleri Göster',
  'Show Saved': 'Kaydedilenleri Göster',
  'Blacklist': 'Kara Liste',
  'Default List': 'Varsayılan Liste',

  'Dated Bills': 'Tarihli Faturalar',
  'Press ENTER, after entering the text': 'Metni girdikten sonra ENTER tuşuna basınız.',
  'Unpursued': 'Takipsiz',
  'Product Record Control': 'Ürün Kayıt Kontrol',
  'Record Control': 'Kayıt Kontrol',
  'Record Date': 'Kayıt Tarihi',
  'insert': 'Ekleme',
  'update': 'Güncelleme',
  'delete': 'Silme',

  'Message': 'Mesaj',
  'Customer Balance Report': 'Cari Bakiye Raporu',
  'Balance Report': 'Bakiye Raporu',
  'Last Transaction Date': 'Son İşlem Tarihi',
  'Selected Date Debit': 'Seçili Tarihteki Borç',
  'Selected Date Credit': 'Seçili Tarihteki Alacak',
  'Total Debit': 'Toplam Borç',
  'Total Credit': 'Toplam Alacak',
  'Calculations were made over today': 'Hesaplamalar bugünün kuru üzerinden yapılmıştır!',
  'You can send up to 10 invoices!': 'En fazla 10 adet fatura gönderebilirsiniz!',
  'You can send up to 10 waybill!': 'En fazla 10 adet irsaliye gönderebilirsiniz!',

  'Please Fill All Inputs': 'Lütfen tüm alanları doldurunuz!',
  'Number of record to be start': 'Başlanacak Kayıt Sayısı',
  'Total Record Count': 'Toplam Kayıt Adedi',
  'You can record up to 1000 records': 'En fazla 1000 adet kayıt çekebilirsiniz!',

  'Share Invoice': 'Fatura Paylaş',
  'Share E-Invoice Mail': 'E-Fatura Mail Paylaş',
  'To': 'Kime',
  'Content': 'İçerik',
  'Enter Mail / Mails': 'Mail / Mailler giriniz.',
  'Enter Mail Content': 'Mail İçeriği Giriniz.',
  'Enter Mail Subject': 'Mail Konusu Giriniz.',
  'Emails That Cannot Be Sent': 'Gönderilemeyen E-Mailler',
  'Your e-invoices with these codes/codes could not be sent as e-mail.': 'Bu kod / kodlara sahip e faturalarınız mail olarak gönderilemedi!',
  'Your invoices with these codes/codes could not be sent as e-mail.': 'Bu kod / kodlara sahip faturalarınız mail olarak gönderilemedi!',
  'Let it be sent to customer mail': 'Carinin e-mail adresine gönderilsin',
  'Let it be sent to the e-mails I have determined': 'Belirlediğim e-maillere gönderilsin.',
  'Mail Address / Addresses': 'Mail Adresi / Adresleri',

  'You have not yet sent a payment notification and have not received payment': 'Henüz ödeme bildirimi göndermediniz ve ödeme almadınız!',
  'Get Full Payment To Invoice': 'Faturaya Tam Ödeme Al',
  'Payment Notification Control': 'Ödeme Bildirimi Kontrolü',
  'Send Payment Notification': 'Ödeme Bildirimi Gönder',
  'Payment Records': 'Ödeme Kayıtları',
  'Payment': 'Ödeme',
  'Site Address': 'Site Adresi',
  'Settle Net': 'Mahsuplaştır',
  'Invoice Code': 'Fatura Kodu',
  'Invoice Amount': 'Fatura Tutarı',
  'Settle Net Amount': 'Mahsuplaştırılan Tutar',
  'Settle Net Detail': 'Mahsuplaştırma Detayı',

  'Create E-Transform': 'E-Dönüşüm Oluştur',
  'Create E-Archive': 'E-Arşiv Oluştur',
  'Create E-Invoice': 'E-Fatura Oluştur',
  'Preview Creating Multiple E-Transform': d => `Çoklu ${d} Oluşturma Önizleme`,
  'Sending Date': 'Gönderim Tarihi',

  'Cancel Invoice Message': 'Bu fatura iptal edildiğinde raporları ve diğer hesaplamaları etkileyecektir. Devam etmek istiyor musunuz',
  'Invoice Has Been Cancelled': 'Fatura İptal Edildi',
  'Cancel Invoice Detail Message': 'Faturanız iptal edildi. Raporlar ve diğer hesaplamalar etkilenecektir! Hesaplamalara etki etmemesini istiyorsanız lütfen bu faturayı silin.',
  'Cancel': 'İptal',
  'Cancel Invoice': 'Faturayı İptal Et',
  'Activate Invoice': 'Faturayı Aktif Et',
  'Activate Invoice Message': 'Faturanızı aktif etmek istediğinize emin misiniz',
  'Customer Name': 'Cari Adı',
  'Send Shipment Carrier Information': 'Gönderici taşıyıcı bilgileri gönderilsin',

  try: 'Türk Lirası',
  usd: 'Amerikan Doları',
  eur: 'Euro',
  gbp: 'İngiliz Sterlini',
  rub: 'Rus Rublesi',

  'date-formats': {
    'GG.AA.YYYY': 'DD.MM.YYYY',
    'AA.GG.YYYY': 'MM.DD.YYYY',
    'YYYY.AA.GG': 'YYYY.MM.DD',
    'YYYY.GG.AA': 'YYYY.DD.MM',
    'GG-AA-YYYY': 'DD-MM-YYYY',
    'AA-GG-YYYY': 'MM-DD-YYYY',
    'YYYY-AA-GG': 'YYYY-MM-DD',
    'YYYY-GG-AA': 'YYYY-DD-MM',
    'GG/AA/YYYY': 'DD/MM/YYYY',
    'AA/GG/YYYY': 'MM/DD/YYYY',
    'YYYY/AA/GG': 'YYYY/MM/DD',
    'YYYY/GG/AA': 'YYYY/DD/MM',
  },

  'route': {
    ':token?': ':token?',
    'login': 'giris',
    'forgot-my-password': 'sifremi-unuttum',
    'register': 'kayit',
    'register/:commissioner_e_mail?': 'kayit/:commissioner_e_mail?',
    'customer/transactions-export': 'cari/hareketler-disa-aktarma',
    'customer/balance-report': 'cari/bakiye-raporu',
    'product/update/:id': 'urun/guncelle/:id',
    'product/detail/:id': 'urun/detay/:id',
    'product/defaults': 'urun/varsayilanlar',
    'invoice/:type/update/:id': 'fatura/:type/guncelle/:id',
    'invoice/defaults': 'fatura/varsayilanlar',
    'invoice/incoming-e-invoice-box': 'fatura/gelen-e-fatura-kutusu',
    'invoice/detail/incoming-e-invoice-box/:id': 'fatura/detay/gelen-e-fatura-kutusu/:id',
    'waybill/list': 'irsaliye/listesi',
    'invoice/invoice-income-expense-report': 'fatura/fatura-gelir-gider-raporu',
    'transaction/recoveries': 'hareket/satislar-raporu',
    'sequence': 'sayac',
    'sequence/new': 'sayac/yeni',
    'sequence/update/:id': 'sayac/guncelle/:id',
    'template/list': 'sablon/listesi',
    'template/new': 'sablon/yeni',
    'template/update/:id': 'sablon/guncelle/:id',
    'template/design/:id': 'sablon/tasarla/:id',
    'waybill-template/list': 'irsaliye-sablon/listesi',
    'waybill-template/new': 'irsaliye-sablon/yeni',
    'waybill-template/update/:id': 'irsaliye-sablon/guncelle/:id',
    'waybill-template/design/:id': 'irsaliye-sablon/tasarla/:id',
    'product/detail/:id/sub-product/update/:sub_product_id': 'urun/detay/:id/alt-urun/guncelle/:sub_product_id',
    'sub-product/attributes/:id?': 'alt-urun/nitelikler/:id?',
    'sub-product/attributes/:id/attribute/new': 'alt-urun/nitelikler/:id/nitelik/yeni',
    'sub-product/attributes/:id/attribute/update/:attribute_id': 'alt-urun/nitelikler/:id/nitelik/guncelle/:attribute_id',
    'product/detail/:id/set-default-attribute-groups': 'urun/detay/:id/varsayilan-nitelik-gruplarini-tanimla',
    'waybill/new/:type': 'irsaliye/yeni/:type',
    'waybill/:type/update/:id': 'irsaliye/:type/guncelle/:id',
    'waybill/new/:type/:id': 'irsaliye/yeni/:type/:id',
    'waybill/:type/detail/:id': 'irsaliye/:type/detay/:id',
  },

  'Dashboard': 'Panel',
  'Are you sure you want to continue': 'Devam etmek istediğinize emin misiniz',
  'Welcome Jetofis': 'Jetofis\'e Hoş Geldiniz!',
  'Days Late': d => `${d} Gün Gecikti`,
  'Cash flow for the last and next {x} days': d => 'Geçmiş ve Önümüzdeki {x} günün nakit akışı'.replace('{x}', d),
  'Your session is over': 'Oturum süreniz doldu',

  'languages': {
    'tr': 'Türkçe',
    'en': 'İngilizce',
  },

  'formValidationMessages': {
    default: '%s alanında doğrulama hatası',
    required: '%s alanı zorunludur',
    enum: '%s, %s elemanlarından biri olmalıdır',
    whitespace: '%s boş olamaz',
    date: {
      format: '%s date %s is invalid for format %s',
      parse: '%s parse edilemedi, %s uygun değil.',
      invalid: '%s date %s is invalid',
    },
    types: {
      string: 'Bu alan bir yazı olmalıdır',
      method: '%s is not a %s (function)',
      array: 'Bu alan bir dizi',
      object: '%s uygun bir %s değil',
      number: 'Bu alan bir sayı olmalıdır',
      date: 'Bu alan bir tarih olmalıdır',
      boolean: '%s uygun bir %s değil',
      integer: 'Bu alan tam sayı olmalıdır',
      float: '%s uygun bir %s değil',
      regexp: '%s uygun bir %s değil',
      email: '%s uygun bir %s değil',
      url: '%s uygun bir %s değil',
      hex: '%s uygun bir %s değil',
    },
    string: {
      len: '%s tam olarak %s karakter olmalıdır',
      min: '%s en az %s karakterli olmalıdır',
      max: '%s %s karakterden daha uzun olamaz ',
      range: '%s %s ve %s karakter aralığında olmalıdır.',
    },
    number: {
      len: '%s ve %s eşit olmalıdır',
      min: '%s %s den daha az olamaz',
      max: '%s %sden daha fazla olamaz',
      range: '%s %s ve %s karakter aralığında olmalıdır.',
    },
    array: {
      len: '%s tam olarak %s uzunluğunda olmalıdır',
      min: '%s %s den daha az olamaz',
      max: '%s %sden daha fazla olamaz',
      range: '%s %s ve %s karakter aralığında olmalıdır.',
    },
    pattern: {
      mismatch: '%s değer %s pattern ile eşleşmiyor %s',
    },
    clone() {
      const cloned = JSON.parse(JSON.stringify(this))
      cloned.clone = this.clone
      return cloned
    },
  },

  formFields: {
    e_mail: 'E-Posta',
    password: 'Parola',
    web_site: 'Web Sitesi',
    'safe[name]': 'Kasa adı',
    'safe[code]': 'Kasa kodu',
    'safe[currency]': 'Kasa döviz kuru',
    'account_opening[type]': 'Açılış bakiyesi tipi',
    'account_opening[amount]': 'Açılış bakiyesi tutarı',
    'account_opening[date]': 'Tarih',
    'safe[default]': 'Varsayılan',
    'product[name]': 'Ürün adı',
    'product[category_id]': 'Ürün kategorisi',
    'product[code]': 'Ürün kodu',
    'product[stock_tracking]': 'Ürün stok takibi',
    'product[unit_id]': 'Ürün birimi',
    'product[vat]': 'Ürün vergisi',
    'product[buying_price]': 'Ürün alış fiyatı',
    'product[selling_price]': 'Ürün satış fiyatı',
    'product[buying_currency]': 'Ürün alış para birimi',
    'product[selling_currency]': 'Ürün satış para birimi',
    'stock_opening[type]': 'Stok açılış tipi',
    'stock_opening[quantity]': 'Stok açılış miktarı',
    'stock_opening[date]': 'Tarih',
    'customer[name]': 'Cari adı',
    'customer[category_id]': 'Cari kategorisi',
    'customer[code]': 'Cari kodu',
    'customer[phone_1]': 'Cari telefon 1',
    'customer[phone_2]': 'Cari telefon 2',
    'customer[country]': 'Cari ülke',
    'customer[city]': 'Cari il',
    'customer[town]': 'Cari ilçe',
    'customer[address]': 'Cari adresi',
    'customer[e_mail]': 'Cari e-posta',
    'customer[type]': 'Cari tipi',
    'customer[id_or_tax_identification_number]': 'Cari kimlik veya vergi numarası',
    'customer[tax_administration]': 'Cari vergi dairesi',
    process_selected_currency: 'Seçili kuru işle',
    currency: 'Döviz kuru',
    'account_opening[currency_rates][usd]': 'Açılış bakiyesi döviz kurunun usd oranı',
    'account_opening[currency_rates][try]': 'Açılış bakiyesi döviz kurunun try oranı',
    'account_opening[currency_rates][eur]': 'Açılış bakiyesi döviz kurunun eur oranı',
    'customer[explanation]': 'Cari açıklaması',
    company_name: 'Firma Adı',
    commissioner_name_surname: 'Yetkili adı soyadı',
    commissioner_e_mail: 'Yetkili e-posta',
    commissioner_phone: 'Yetkili telefon numarası',
    kvkk_status: 'Lütfen KVKK Yönetmeliğini Onaylayınız.',
    title: 'Ünvan',
    phone_1: 'Telefon 1',
    phone_2: 'Telefon 2',
    country: 'Ülke',
    city: 'İl',
    town: 'İlçe',
    address: 'Adres',
    name_surname: 'Ad Soyad',
    tax_identification_number: 'Vergi numarası',
    tax_administration: 'Vergi dairesi',
    fixed_exchange_rate: 'Varsayılan para birimi',
    'invoice[customer_id]': 'Fatura cari',
    type: 'Tür',
    'invoice[category_id]': 'Fatura kategori',
    'invoice[code]': 'Fatura kodu',
    'invoice[series]': 'Fatura seri',
    'invoice[no]': 'Fatura sıra',
    'invoice[date]': 'Fatura tarihi',
    'invoice[expiry_date]': 'Fatura vade tarihi',
    'invoice[currency]': 'Fatura para birimi',
    'invoice[currency_rates][usd]': 'Fatura döviz kurunun usd oranı',
    'invoice[currency_rates][try]': 'Fatura döviz kurunun try oranı',
    'invoice[currency_rates][eur]': 'Fatura döviz kurunun eur oranı',
    'invoice[discount]': 'Fatura iskonto',
    'invoice[discount_type]': 'Fatura iskonto türü',
    'invoice[invoice_country]': 'Fatura ülke',
    'invoice[invoice_city]': 'Fatura il',
    'invoice[invoice_town]': 'Fatura ilçe',
    'invoice[invoice_address]': 'Fatura adres',
    'invoice[delivery_country]': 'Teslimat ülke',
    'invoice[delivery_city]': 'Teslimat il',
    'invoice[delivery_town]': 'Teslimat ilçe',
    'invoice[delivery_address]': 'Teslimat adres',
    'invoice[explanation]': 'Fatura açıklaması',
    'category[name]': 'Kategori adı',
    'category[code]': 'Kategori kodu',
    'additional_charge[name]': 'Ek ücret adı',
    'additional_charge[unit_id]': 'Ek ücret birimi',
    'additional_charge[code]': 'Ek ücret kodu',
    'additional_charge[category_id]': 'Ek ücret kategori',
    'additional_charge[buying_price]': 'Ek ücret alış fiyatı',
    'additional_charge[selling_price]': 'Ek ücret satış fiyatı',
    'additional_charge[vat]': 'Ek ücret vergi',
    name: 'Ad',
    step: 'Sayaç adım',
    start: 'Sayaç başlangıç',
    end: 'Sayaç bitiş',
    current_value: 'Sayaç mevcut değer',
    pattern: 'Sayaç deseni',
    show_all_columns: 'Tüm sütunları göster',
    instruction_note: 'Talimat Notu',
    series: 'Fatura Seri',
    no: 'Fatura No',
    invoice_country: 'Fatura Ülke',
    invoice_city: 'Fatura İl',
    invoice_town: 'Fatura İlçe',
    invoice_address: 'Fatura adres',
    delivery_country: 'Teslimat ülke',
    delivery_city: 'Teslimat il',
    delivery_town: 'Teslimat ilçe',
    delivery_address: 'Teslimat adres',
    explanation: 'Fatura açıklaması',
    departmant_id: 'Departman',
    content: 'Mesaj',
    subject: 'Konu',
    attribute_group1: 'İlk Nitelik',
    attribute_group2: 'İkinci Nitelik',
    'attribute[code]': 'Nitelik Kodu',
    'attribute[title]': 'Nitelik Adı',
    'waybill[customer_id]': 'Cari',
    'waybill[date]': 'Tarih',
    'waybill[sending_date]': 'Sevk Tarihi',
    'attribute_group[first]': 'Birincil Nitelik Grubu',
    'product[attributes][0][attribute_id]': 'Nitelik 1',
    'product[attributes][1][attribute_id]': 'Nitelik 2',
    'tahsildar_ws[store_uri]': 'Site Adresi',
    'tahsildar_ws[ws_username]': 'Giriş Adı',
    'tahsildar_ws[ws_password]': 'Şifre',
    'mail_addresses': 'Mail Adresi / Adresleri',
    'sending_date': 'Gönderim Tarihi',
    'payment_agent_means': 'Ödeme Aracı Adı',
  },

  'countries': [
    'ABD', 'Afganistan', 'Almanya', 'Angola', 'Anguilla', 'Arjantin', 'Arnavutluk', 'Aruba', 'Avustralya', 'Avusturya', 'Azerbeycan', 'Bahamalar', 'Bahreyn', 'Bangladeş',
    'Barbados', 'Batı Somoa', 'Belize', 'Belçika', 'Benin', 'Bermuda', 'Birleşik Arap Emirlikleri', 'Bolivya', 'Bosna Hersek', 'Botsvana', 'Brezilya', 'Brunei', 'Bulgaristan',
    'Burundi', 'Cebelitarık', 'Cezayir', 'Cook Adaları', 'Curaçao', 'Danimarka', 'Dominik', 'Dominika', 'Ekvator', 'El Salvador', 'Endonezya', 'Eritre', 'Ermenistan', 'Estonya',
    'Etiyopya', 'Falkland Adaları', 'Foroe Adaları', 'Fas', 'Fiji', 'Fildişi Sahilleri', 'Filipinler', 'Filistin', 'Finlandiya', 'Fransa', 'Bagon', 'Gana', 'Gine Cumhuriyeti',
    'Greneda', 'Grönland', 'Guatemala', 'Guyana', 'Güney Amerika', 'Gürcistan', 'Haiti', 'Hindistan', 'Hollanda', 'Hong Kong', 'Hırvatistan', 'Irak', 'Jamaika', 'Japonya',
    'K.K.T.C', 'Kosova', 'Kamboçya', 'Kamerun', 'Kanada', 'Karadağ', 'Katar', 'Kazakistan', 'Kenya', 'Kolombiya', 'Kongo', 'Kore Cumhuriyeti', 'Kosta Rika', 'Kuveyt', 'Küba',
    'Kırgızistan', 'Laos', 'Latvia', 'Lesotho', 'Liberya', 'Libya', 'Lihtenştayn', 'Litvanya', 'Lübnan', 'Lüksemburg', 'Macaristan', 'Madagaskar', 'Makau', 'Maldives', 'Malezya',
    'Mali', 'Malta', 'Meksika', 'Moldovya', 'Monako', 'Mozambik', 'Moğolistan', 'Mısır', 'Namibya', 'Nepal', 'Nijerya', 'Nikaragua', 'Norveç', 'Pakistan', 'Palua', 'Panama',
    'Papua Yeni Gine', 'Paraguay', 'Peru', 'Polonya', 'Portekiz', 'Porto Rika', 'Romanya', 'Ruanda', 'Senegal', 'Singapur', 'Slovakya', 'Slovenya', 'Soloman Adaları', 'Somali',
    'Sri Lanka', 'Sudan', 'Suriye', 'Suudi Arabistan', 'Sırbistan', 'Tacikistan', 'Tanzanya', 'Tayland', 'Tayvan', 'Togo', 'Tongo', 'Tunus', 'Türkmenistan', 'Türkiye', 'Uganda',
    'Ukrayna', 'Umman', 'Uruguay', 'Venezuela', 'Vietnam', 'Virjin Adaları', 'Yemen', 'Yeni Zelanda', 'Yuguslavya', 'Yunanistan', 'Zambiya', 'Zimbabve', 'Çad', 'Çek Cumhuriyeti',
    'Çin Halk Cumhuriyeti', 'Özbekistan', 'Ürdün', 'İngiltere', 'İrlanda', 'İskoçya', 'İspanya', 'İsrail', 'İsveç', 'İsviçre', 'İtalya', 'İzlanda', 'Şili',
  ],

  'cityTown': [
    {
      'city': 'Adana',
      'towns': [
        'Aladağ', 'Ceyhan', 'Çukurova', 'Feke', 'İmamoğlu', 'Karaisalı', 'Karataş', 'Kozan', 'Pozantı',
        'Saimbeyli', 'Sarıçam', 'Seyhan', 'Tufanbeyli', 'Yumurtalık', 'Yüreğir',
      ],
    },
    {
      'city': 'Adıyaman',
      'towns': ['Besni', 'Çelikhan', 'Gerger', 'Gölbaşı', 'Kahta', 'Merkez', 'Samsat', 'Sincik', 'Tut'],
    },
    {
      'city': 'Afyonkarahisar',
      'towns': [
        'Başmakçı', 'Bayat', 'Bolvadin', 'Çay', 'Çobanlar', 'Dazkırı', 'Dinar', 'Emirdağ', 'Evccityer', 'Hocalar',
        'İhsaniye', 'İscehisar', 'Kızılören', 'Merkez', 'Sandıklı', 'Sinanpaşa', 'Sultandağı', 'Şuhut',
      ],
    },
    {
      'city': 'Ağrı',
      'towns': ['Diyadin', 'Doğubayazıt', 'Eleşkirt', 'Hamur', 'Merkez', 'Patnos', 'Taşlıçay', 'Tutak'],
    },
    {
      'city': 'Amasya',
      'towns': ['Göynücek', 'Gümüşhacıköy', 'Hamamözü', 'Merkez', 'Merzifon', 'Suluova', 'Taşova'],
    },
    {
      'city': 'Ankara',
      'towns': [
        'Altındağ', 'Ayaş', 'Bala', 'Beypazarı', 'Çamlıdere', 'Çankaya', 'Çubuk', 'Elmadağ', 'Güdül', 'Haymana', 'Kalecik', 'Kızılcahamam', 'Nallıhan',
        'Polatlı', 'Şereflikoçhisar', 'Yenimahalle', 'Gölbaşı', 'Keçiören', 'Mamak', 'Sincan', 'Kazan', 'Akyurt', 'Etimesgut', 'Evren', 'Pursaklar',
      ],
    },
    {
      'city': 'Antalya',
      'towns': [
        'Akseki', 'Alanya', 'Elmalı', 'Finike', 'Gazipaşa', 'Gündoğmuş', 'Kaş', 'Korkuteli', 'Kumluca',
        'Manavgat', 'Serik', 'Demre', 'İbradı', 'Kemer', 'Aksu', 'Döşemealtı', 'Kepez', 'Konyaaltı', 'Muratpaşa',
      ],
    },
    {
      'city': 'Artvin',
      'towns': ['Ardanuç', 'Arhavi', 'Merkez', 'Borçka', 'Hopa', 'Şavşat', 'Yusufeli', 'Murgul'],
    },
    {
      'city': 'Aydın',
      'towns': [
        'Merkez', 'Bozdoğan', 'Efeler', 'Çine', 'Germencik', 'Karacasu', 'Koçarlı', 'Kuşadası', 'Kuyucak',
        'Nazcityli', 'Söke', 'Sultanhisar', 'Yenipazar', 'Buharkent', 'İncirliova', 'Karpuzlu', 'Köşk', 'Didim',
      ],
    },
    {
      'city': 'Balıkesir',
      'towns': [
        'Altıeylül', 'Ayvalık', 'Merkez', 'Balya', 'Bandırma', 'Bigadiç', 'Burhaniye', 'Dursunbey', 'Edremit', 'Erdek', 'Gönen',
        'Havran', 'İvrindi', 'Karesi', 'Kepsut', 'Manyas', 'Savaştepe', 'Sındırgı', 'Gömeç', 'Susurluk', 'Marmara',
      ],
    },
    {
      'city': 'Bilecik',
      'towns': ['Merkez', 'Bozüyük', 'Gölpazarı', 'Osmaneli', 'Pazaryeri', 'Söğüt', 'Yenipazar', 'İnhisar'],
    },
    {
      'city': 'Bingöl',
      'towns': ['Merkez', 'Genç', 'Karlıova', 'Kiğı', 'Solhan', 'Adaklı', 'Yayladere', 'Yedisu'],
    },
    {
      'city': 'Bitlis',
      'towns': ['Adcitycevaz', 'Ahlat', 'Merkez', 'Hizan', 'Mutki', 'Tatvan', 'Güroymak'],
    },
    {
      'city': 'Bolu',
      'towns': ['Merkez', 'Gerede', 'Göynük', 'Kıbrıscık', 'Mengen', 'Mudurnu', 'Seben', 'Dörtdivan', 'Yeniçağa'],
    },
    {
      'city': 'Burdur',
      'towns': ['Ağlasun', 'Bucak', 'Merkez', 'Gölhisar', 'Tefenni', 'Yeşcityova', 'Karamanlı', 'Kemer', 'Altınyayla', 'Çavdır', 'Çeltikçi'],
    },
    {
      'city': 'Bursa',
      'towns': [
        'Gemlik', 'İnegöl', 'İznik', 'Karacabey', 'Keles', 'Mudanya', 'Mustafakemalpaşa', 'Orhaneli', 'Orhangazi',
        'Yenişehir', 'Büyükorhan', 'Harmancık', 'Nilüfer', 'Osmangazi', 'Yıldırım', 'Gürsu', 'Kestel',
      ],
    },
    {
      'city': 'Çanakkale',
      'towns': ['Ayvacık', 'Bayramiç', 'Biga', 'Bozcaada', 'Çan', 'Merkez', 'Eceabat', 'Ezine', 'Gelibolu', 'Gökçeada', 'Lapseki', 'Yenice'],
    },
    {
      'city': 'Çankırı',
      'towns': ['Merkez', 'Çerkeş', 'Eldivan', 'citygaz', 'Kurşunlu', 'Orta', 'Şabanözü', 'Yapraklı', 'Atkaracalar', 'Kızılırmak', 'Bayramören', 'Korgun'],
    },
    {
      'city': 'Çorum',
      'towns': ['Alaca', 'Bayat', 'Merkez', 'İskcityip', 'Kargı', 'Mecitözü', 'Ortaköy', 'Osmancık', 'Sungurlu', 'Boğazkale', 'Uğurludağ', 'Dodurga', 'Laçin', 'Oğuzlar'],
    },
    {
      'city': 'Denizli',
      'towns': [
        'Acıpayam', 'Buldan', 'Çal', 'Çameli', 'Çardak', 'Çivrcity', 'Merkez', 'Merkezefendi', 'Pamukkale', 'Güney',
        'Kale', 'Sarayköy', 'Tavas', 'Babadağ', 'Bekcityli', 'Honaz', 'Serinhisar', 'Baklan', 'Beyağaç', 'Bozkurt',
      ],
    },
    {
      'city': 'Diyarbakır',
      'towns': [
        'Kocaköy', 'Çermik', 'Çınar', 'Çüngüş', 'Dicle', 'Ergani', 'Hani', 'Hazro', 'Kulp',
        'Lice', 'Scityvan', 'Eğcity', 'Bağlar', 'Kayapınar', 'Sur', 'Yenişehir', 'Bismcity',
      ],
    },
    {
      'city': 'Edirne',
      'towns': ['Merkez', 'Enez', 'Havsa', 'İpsala', 'Keşan', 'Lalapaşa', 'Meriç', 'Uzunköprü', 'Süloğlu'],
    },
    {
      'city': 'Elazığ',
      'towns': ['Ağın', 'Baskcity', 'Merkez', 'Karakoçan', 'Keban', 'Maden', 'Palu', 'Sivrice', 'Arıcak', 'Kovancılar', 'Alacakaya'],
    },
    {
      'city': 'Erzincan',
      'towns': ['Çayırlı', 'Merkez', 'İliç', 'Kemah', 'Kemaliye', 'Refahiye', 'Tercan', 'Üzümlü', 'Otlukbeli'],
    },
    {
      'city': 'Erzurum',
      'towns': [
        'Aşkale', 'Çat', 'Hınıs', 'Horasan', 'İspir', 'Karayazı', 'Narman', 'Oltu', 'Olur', 'Pasinler', 'Şenkaya',
        'Tekman', 'Tortum', 'Karaçoban', 'Uzundere', 'Pazaryolu', 'Köprüköy', 'Palandöken', 'Yakutiye', 'Aziziye',
      ],
    },
    {
      'city': 'Eskişehir',
      'towns': [
        'Çifteler', 'Mahmudiye', 'Mihalıççık', 'Sarıcakaya', 'Seyitgazi', 'Sivrihisar', 'Alpu',
        'Beylikova', 'İnönü', 'Günyüzü', 'Han', 'Mihalgazi', 'Odunpazarı', 'Tepebaşı',
      ],
    },
    {
      'city': 'Gaziantep',
      'towns': ['Araban', 'İslahiye', 'Nizip', 'Oğuzeli', 'Yavuzeli', 'Şahinbey', 'Şehitkamil', 'Karkamış', 'Nurdağı'],
    },
    {
      'city': 'Giresun',
      'towns': [
        'Alucra', 'Bulancak', 'Dereli', 'Espiye', 'Eynescity', 'Merkez', 'Görele', 'Keşap', 'Şebinkarahisar',
        'Tirebolu', 'Piraziz', 'Yağlıdere', 'Çamoluk', 'Çanakçı', 'Doğankent', 'Güce',
      ],
    },
    {
      'city': 'Gümüşhane',
      'towns': ['Merkez', 'Kelkit', 'Şiran', 'Torul', 'Köse', 'Kürtün'],
    },
    {
      'city': 'Hakkari',
      'towns': ['Çukurca', 'Merkez', 'Şemdinli', 'Yüksekova'],
    },
    {
      'city': 'Hatay',
      'towns': ['Altınözü', 'Arsuz', 'Defne', 'Dörtyol', 'Hassa', 'Antakya', 'İskenderun', 'Kırıkhan', 'Payas', 'Reyhanlı', 'Samandağ', 'Yayladağı', 'Erzin', 'Belen', 'Kumlu'],
    },
    {
      'city': 'Isparta',
      'towns': ['Atabey', 'Eğirdir', 'Gelendost', 'Merkez', 'Keçiborlu', 'Senirkent', 'Sütçüler', 'Şarkikaraağaç', 'Uluborlu', 'Yalvaç', 'Aksu', 'Gönen', 'Yenişarbademli'],
    },
    {
      'city': 'Mersin',
      'towns': ['Anamur', 'Erdemli', 'Gülnar', 'Mut', 'Scityifke', 'Tarsus', 'Aydıncık', 'Bozyazı', 'Çamlıyayla', 'Akdeniz', 'Mezitli', 'Toroslar', 'Yenişehir'],
    },
    {
      'city': 'İstanbul',
      'towns': [
        'Adalar', 'Bakırköy', 'Beşiktaş', 'Beykoz', 'Beyoğlu', 'Çatalca', 'Eyüp', 'Fatih', 'Gaziosmanpaşa', 'Kadıköy', 'Kartal', 'Sarıyer', 'Scityivri',
        'Şcitye', 'Şişli', 'Üsküdar', 'Zeytinburnu', 'Büyükçekmece', 'Kağıthane', 'Küçükçekmece', 'Pendik', 'Ümraniye', 'Bayrampaşa', 'Avcılar', 'Bağcılar',
        'Bahçelievler', 'Güngören', 'Maltepe', 'Sultanbeyli', 'Tuzla', 'Esenler', 'Arnavutköy', 'Ataşehir', 'Başakşehir', 'Beylikdüzü', 'Çekmeköy', 'Esenyurt',
        'Sancaktepe', 'Sultangazi',
      ],
    },
    {
      'city': 'İzmir',
      'towns': [
        'Aliağa', 'Bayındır', 'Bergama', 'Bornova', 'Çeşme', 'Dikcityi', 'Foça', 'Karaburun', 'Karşıyaka', 'Kemalpaşa', 'Kınık', 'Kiraz', 'Menemen',
        'Ödemiş', 'Seferihisar', 'Selçuk', 'Tire', 'Torbalı', 'Urla', 'Beydağ', 'Buca', 'Konak', 'Menderes', 'Balçova', 'Çiğli', 'Gaziemir', 'Narlıdere',
        'Güzelbahçe', 'Bayraklı', 'Karabağlar',
      ],
    },
    {
      'city': 'Kars',
      'towns': ['Arpaçay', 'Digor', 'Kağızman', 'Merkez', 'Sarıkamış', 'Selim', 'Susuz', 'Akyaka'],
    },
    {
      'city': 'Kastamonu',
      'towns': [
        'Abana', 'Araç', 'Azdavay', 'Bozkurt', 'Cide', 'Çatalzeytin', 'Daday', 'Devrekani', 'İnebolu', 'Merkez',
        'Küre', 'Taşköprü', 'Tosya', 'İhsangazi', 'Pınarbaşı', 'Şenpazar', 'Ağlı', 'Doğanyurt', 'Hanönü', 'Seydcityer',
      ],
    },
    {
      'city': 'Kayseri',
      'towns': [
        'Bünyan', 'Develi', 'Felahiye', 'İncesu', 'Pınarbaşı', 'Sarıoğlan', 'Sarız', 'Tomarza', 'Yahyalı',
        'Yeşcityhisar', 'Akkışla', 'Talas', 'Kocasinan', 'Melikgazi', 'Hacılar', 'Özvatan',
      ],
    },
    {
      'city': 'Kırklareli',
      'towns': ['Babaeski', 'Demirköy', 'Merkez', 'Kofçaz', 'Lüleburgaz', 'Pehlivanköy', 'Pınarhisar', 'Vize'],
    },
    {
      'city': 'Kırşehir',
      'towns': ['Çiçekdağı', 'Kaman', 'Merkez', 'Mucur', 'Akpınar', 'Akçakent', 'Boztepe'],
    },
    {
      'city': 'Kocaeli',
      'towns': ['Gebze', 'Gölcük', 'Kandıra', 'Karamürsel', 'Körfez', 'Derince', 'Başiskele', 'Çayırova', 'Darıca', 'Dcityovası', 'İzmit', 'Kartepe'],
    },
    {
      'city': 'Konya',
      'towns': [
        'Akşehir', 'Beyşehir', 'Bozkır', 'Cihanbeyli', 'Çumra', 'Doğanhisar', 'Ereğli', 'Hadim', 'Kadınhanı', 'Karapınar', 'Kulu', 'Sarayönü', 'Seydişehir',
        'Yunak', 'Akören', 'Altınekin', 'Derebucak', 'Hüyük', 'Karatay', 'Meram', 'Selçuklu', 'Taşkent', 'Ahırlı', 'Çeltik', 'Derbent', 'Emirgazi', 'Güneysınır',
        'Halkapınar', 'Tuzlukçu', 'Yalıhüyük',
      ],
    },
    {
      'city': 'Kütahya',
      'towns': ['Altıntaş', 'Domaniç', 'Emet', 'Gediz', 'Merkez', 'Simav', 'Tavşanlı', 'Aslanapa', 'Dumlupınar', 'Hisarcık', 'Şaphane', 'Çavdarhisar', 'Pazarlar'],
    },
    {
      'city': 'Malatya',
      'towns': ['Akçadağ', 'Arapgir', 'Arguvan', 'Darende', 'Doğanşehir', 'Hekimhan', 'Merkez', 'Pütürge', 'Yeşcityyurt', 'Battalgazi', 'Doğanyol', 'Kale', 'Kuluncak', 'Yazıhan'],
    },
    {
      'city': 'Manisa',
      'towns': [
        'Akhisar', 'Alaşehir', 'Demirci', 'Gördes', 'Kırkağaç', 'Kula', 'Merkez', 'Salihli', 'Sarıgöl',
        'Saruhanlı', 'Selendi', 'Soma', 'Şehzadeler', 'Yunusemre', 'Turgutlu', 'Ahmetli', 'Gölmarmara', 'Köprübaşı',
      ],
    },
    {
      'city': 'Kahramanmaraş',
      'towns': ['Afşin', 'Andırın', 'Dulkadiroğlu', 'Onikişubat', 'Elbistan', 'Göksun', 'Merkez', 'Pazarcık', 'Türkoğlu', 'Çağlayancerit', 'Ekinözü', 'Nurhak'],
    },
    {
      'city': 'Mardin',
      'towns': ['Derik', 'Kızıltepe', 'Artuklu', 'Merkez', 'Mazıdağı', 'Midyat', 'Nusaybin', 'Ömerli', 'Savur', 'Dargeçit', 'Yeşcityli'],
    },
    {
      'city': 'Muğla',
      'towns': ['Bodrum', 'Datça', 'Fethiye', 'Köyceğiz', 'Marmaris', 'Menteşe', 'Mcityas', 'Ula', 'Yatağan', 'Dalaman', 'Seydikemer', 'Ortaca', 'Kavaklıdere'],
    },
    {
      'city': 'Muş',
      'towns': ['Bulanık', 'Malazgirt', 'Merkez', 'Varto', 'Hasköy', 'Korkut'],
    },
    {
      'city': 'Nevşehir',
      'towns': ['Avanos', 'Derinkuyu', 'Gülşehir', 'Hacıbektaş', 'Kozaklı', 'Merkez', 'Ürgüp', 'Acıgöl'],
    },
    {
      'city': 'Niğde',
      'towns': ['Bor', 'Çamardı', 'Merkez', 'Ulukışla', 'Altunhisar', 'Çiftlik'],
    },
    {
      'city': 'Ordu',
      'towns': [
        'Akkuş', 'Altınordu', 'Aybastı', 'Fatsa', 'Gölköy', 'Korgan', 'Kumru', 'Mesudiye', 'Perşembe',
        'Ulubey', 'Ünye', 'Gülyalı', 'Gürgentepe', 'Çamaş', 'Çatalpınar', 'Çaybaşı', 'İkizce', 'Kabadüz', 'Kabataş',
      ],
    },
    {
      'city': 'Rize',
      'towns': ['Ardeşen', 'Çamlıhemşin', 'Çayeli', 'Fındıklı', 'İkizdere', 'Kalkandere', 'Pazar', 'Merkez', 'Güneysu', 'Derepazarı', 'Hemşin', 'İyidere'],
    },
    {
      'city': 'Sakarya',
      'towns': [
        'Akyazı', 'Geyve', 'Hendek', 'Karasu', 'Kaynarca', 'Sapanca', 'Kocaali', 'Pamukova',
        'Taraklı', 'Ferizli', 'Karapürçek', 'Söğütlü', 'Adapazarı', 'Arifiye', 'Erenler', 'Serdivan',
      ],
    },
    {
      'city': 'Samsun',
      'towns': [
        'Alaçam', 'Bafra', 'Çarşamba', 'Havza', 'Kavak', 'Ladik', 'Terme', 'Vezirköprü', 'Asarcık',
        'Ondokuzmayıs', 'Salıpazarı', 'Tekkeköy', 'Ayvacık', 'Yakakent', 'Atakum', 'Canik', 'İlkadım',
      ],
    },
    {
      'city': 'Siirt',
      'towns': ['Baykan', 'Eruh', 'Kurtalan', 'Pervari', 'Merkez', 'Şirvan', 'Tcitylo'],
    },
    {
      'city': 'Sinop',
      'towns': ['Ayancık', 'Boyabat', 'Durağan', 'Erfelek', 'Gerze', 'Merkez', 'Türkeli', 'Dikmen', 'Saraydüzü'],
    },
    {
      'city': 'Sivas',
      'towns': [
        'Divriği', 'Gemerek', 'Gürün', 'Hafik', 'İmranlı', 'Kangal', 'Koyulhisar', 'Merkez', 'Suşehri',
        'Şarkışla', 'Yıldızeli', 'Zara', 'Akıncılar', 'Altınyayla', 'Doğanşar', 'Gölova', 'Ulaş',
      ],
    },
    {
      'city': 'Tekirdağ',
      'towns': ['Çerkezköy', 'Çorlu', 'Ergene', 'Hayrabolu', 'Malkara', 'Muratlı', 'Saray', 'Süleymanpaşa', 'Kapaklı', 'Şarköy', 'Marmaraereğlisi'],
    },
    {
      'city': 'Tokat',
      'towns': ['Almus', 'Artova', 'Erbaa', 'Niksar', 'Reşadiye', 'Merkez', 'Turhal', 'Zcitye', 'Pazar', 'Yeşcityyurt', 'Başçiftlik', 'Sulusaray'],
    },
    {
      'city': 'Trabzon',
      'towns': [
        'Akçaabat', 'Araklı', 'Arsin', 'Çaykara', 'Maçka', 'Of', 'Ortahisar', 'Sürmene', 'Tonya', 'Vakfıkebir',
        'Yomra', 'Beşikdüzü', 'Şalpazarı', 'Çarşıbaşı', 'Dernekpazarı', 'Düzköy', 'Hayrat', 'Köprübaşı',
      ],
    },
    {
      'city': 'Tunceli',
      'towns': ['Çemişgezek', 'Hozat', 'Mazgirt', 'Nazımiye', 'Ovacık', 'Pertek', 'Pülümür', 'Merkez'],
    },
    {
      'city': 'Şanlıurfa',
      'towns': ['Akçakale', 'Birecik', 'Bozova', 'Ceylanpınar', 'Eyyübiye', 'Halfeti', 'Halcityiye', 'Hcityvan', 'Karaköprü', 'Siverek', 'Suruç', 'Viranşehir', 'Harran'],
    },
    {
      'city': 'Uşak',
      'towns': ['Banaz', 'Eşme', 'Karahallı', 'Sivaslı', 'Ulubey', 'Merkez'],
    },
    {
      'city': 'Van',
      'towns': ['Başkale', 'Çatak', 'Erciş', 'Gevaş', 'Gürpınar', 'İpekyolu', 'Muradiye', 'Özalp', 'Tuşba', 'Bahçesaray', 'Çaldıran', 'Edremit', 'Saray'],
    },
    {
      'city': 'Yozgat',
      'towns': [
        'Akdağmadeni', 'Boğazlıyan', 'Çayıralan', 'Çekerek', 'Sarıkaya', 'Sorgun', 'Şefaatli',
        'Yerköy', 'Merkez', 'Aydıncık', 'Çandır', 'Kadışehri', 'Saraykent', 'Yenifakılı',
      ],
    },
    {
      'city': 'Zonguldak',
      'towns': ['Çaycuma', 'Devrek', 'Ereğli', 'Merkez', 'Alaplı', 'Gökçebey', 'Kozlu'],
    },
    {
      'city': 'Aksaray',
      'towns': ['Ağaçören', 'Eskcity', 'Gülağaç', 'Güzelyurt', 'Merkez', 'Ortaköy', 'Sarıyahşi'],
    },
    {
      'city': 'Bayburt',
      'towns': ['Merkez', 'Aydıntepe', 'Demirözü'],
    },
    {
      'city': 'Karaman',
      'towns': ['Ermenek', 'Merkez', 'Ayrancı', 'Kazımkarabekir', 'Başyayla', 'Sarıvelcityer'],
    },
    {
      'city': 'Kırıkkale',
      'towns': ['Delice', 'Keskin', 'Merkez', 'Sulakyurt', 'Bahşcityi', 'Balışeyh', 'Çelebi', 'Karakeçcityi', 'Yahşihan'],
    },
    {
      'city': 'Batman',
      'towns': ['Merkez', 'Beşiri', 'Gercüş', 'Kozluk', 'Sason', 'Hasankeyf'],
    },
    {
      'city': 'Şırnak',
      'towns': ['Beytüşşebap', 'Cizre', 'İdcity', 'Scityopi', 'Merkez', 'Uludere', 'Güçlükonak'],
    },
    {
      'city': 'Bartın',
      'towns': ['Merkez', 'Kurucaşcitye', 'Ulus', 'Amasra'],
    },
    {
      'city': 'Ardahan',
      'towns': ['Merkez', 'Çıldır', 'Göle', 'Hanak', 'Posof', 'Damal'],
    },
    {
      'city': 'Iğdır',
      'towns': ['Aralık', 'Merkez', 'Tuzluca', 'Karakoyunlu'],
    },
    {
      'city': 'Yalova',
      'towns': ['Merkez', 'Altınova', 'Armutlu', 'Çınarcık', 'Çiftlikköy', 'Termal'],
    },
    {
      'city': 'Karabük',
      'towns': ['Eflani', 'Eskipazar', 'Merkez', 'Ovacık', 'Safranbolu', 'Yenice'],
    },
    {
      'city': 'Kcityis',
      'towns': ['Merkez', 'Elbeyli', 'Musabeyli', 'Polateli'],
    },
    {
      'city': 'Osmaniye',
      'towns': ['Bahçe', 'Kadirli', 'Merkez', 'Düziçi', 'Hasanbeyli', 'Sumbas', 'Toprakkale'],
    },
    {
      'city': 'Düzce',
      'towns': ['Akçakoca', 'Merkez', 'Yığılca', 'Cumayeri', 'Gölyaka', 'Çcityimli', 'Gümüşova', 'Kaynaşlı'],
    },
  ],

  vats: [0, 1, 8, 10, 18, 20],

  'Switch Theme': 'Tema Değiştir',
  'Dark': 'Koyu',
  'Light': 'Açık',

}

export default messages
