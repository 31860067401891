import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import GeneralSettingsForm from './GeneralSettingsForm/GeneralSettingsForm'
import routes from '../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'General Settings', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function GeneralSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add(routes.GENERAL_SETTINGS)

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedGeneralSettingsForm = Form.create({
    validateMessages: langData.formValidationMessages,
  })(GeneralSettingsForm)

  return <WrappedGeneralSettingsForm {...props} />

}

GeneralSettings.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)
