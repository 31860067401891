import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Drawer } from 'antd'

import NewCustomer from './../NewCustomer/NewCustomer'

function NewCustomerModal(props) {

  return (
    <Drawer
      placement={props.sizeWidth > 576 ? 'right' : 'top'}
      closable={true}
      visible={true}
      width={props.sizeWidth > 576 ? '40%' : '100%'}
      height="100%"
      onClose={props.modalClose}
    >
      <NewCustomer isModal={props.modalClose}/>
    </Drawer>
  )

}

NewCustomerModal.propTypes = {
  langData: PropTypes.object,
  modalClose: PropTypes.func,
  sizeWidth: PropTypes.number,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
  }
})(NewCustomerModal)
