import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Col, Card, Table, DatePicker, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithId } from '../../../data/Currency'
import { startCase } from 'lodash'
import { gql } from '@apollo/client'

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { Query } from '@apollo/client/react/components'
import moment from 'moment'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Safe' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Income Expense Report', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($filterCredit: CreditDebitFilterBase, $filterMoneyIO: MoneyIOFilterBase ) {
      reports {
        moneyIO {
          sum(
            field: amount
            group: type
            filter: $filterMoneyIO
          ) {
            results {
              sum
              type
            }
          }
        }
        creditDebit {
          sum(
            field: amount
            group: type
            filter: $filterCredit
          ) {
            results {
              sum
              type
            }
          }
        }
      }

      legacyData {
        currency_id
      }

    }

  `
)

class IncomeExpenseReport extends Component {

  state = {
    data: {
      income: [],
      expense: [],
    },
    filter: {
      AND: [{
        date: {
          gte: moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z'),
          lte: moment().format('YYYY-MM-DDT23:59:00.000Z'),
        },
      }],
    },
    sumCharts: [],
    startValue: moment().subtract(1, 'months'),
    endValue: moment(),
    currency_id: 1,
  }

  columns = [
    {
      dataIndex: 'type',
      width: '80%',
      render: value => this.props.langData[startCase(value)],
    },
    {
      width: '20%',
      dataIndex: 'sum',
      render: (value) => `${toLocaleString(value)} ${getCurrencyWithId(this.state.currency_id).symbol}`,
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add(routes.SAFE_INCOME_EXPENSE_REPORT)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }


  filterCallback = filter => {
    if ( ! filter) {
      this.setState({
        filter: null,
      })
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
      })
    }
  }

  disabledStartDate = startValue => {
    const { endValue } = this.state
    if ( ! startValue || ! endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state
    if ( ! endValue || ! startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  onChange = (field, value) => {
    if (value)
      this.setState({
        [field]: value,
      })
    else
      this.setState({
        [field]: field === 'startValue' ? moment().subtract(1, 'months') : moment(),
      })
  }

  filterSetDate = refetch => {
    let filter = {
      date: {
        gte: moment(this.state.startValue).format('YYYY-MM-DDT00:00:00.000Z'),
        lte: moment(this.state.endValue).format('YYYY-MM-DDT23:59:00.000Z'),
      },
    }

    this.filterCallback(filter)

    refetch()
  }

  render() {

    const options = {
      chart: {
        type: 'pie',
        margin: [30, 0, 0, 0],
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name} </b><br>{point.percentage:.1f} %',
            distance: -50,
          },
        },
      },
      tooltip: {
        enabled: true,
        pointFormat: '{series.name}: <b>{point.y}</b> {point.custom}',
      },
      title: {
        text: '',
      },
      series: [{
        name: 'Toplam',
        showInLegend: false,
        tooltip: { enabled: false },
        data: this.state.sumCharts,
      }],
      credits: { enabled: false },
    }

    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ filterCredit: this.state.filter, filterMoneyIO: this.state.filter }}
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          const debits = data.reports.creditDebit.sum.results.filter(x => x.type === 'Debit')
          const credits = data.reports.creditDebit.sum.results.filter(x => x.type === 'Credit')

          const moneyInputs = data.reports.moneyIO.sum.results.filter(x => x.type === 'MoneyInput')
          const moneyOutputs = data.reports.moneyIO.sum.results.filter(x => x.type === 'MoneyOutput')

          this.setState({

            currency_id: data.legacyData.currency_id,

            data: {
              expense: [...moneyOutputs, ...credits],
              income: [...moneyInputs, ...debits],
            },

            sumCharts: [
              {
                name: this.props.langData['Incomes'],
                y: ((debits.length ? debits[0].sum : 0) + (moneyInputs.length ? moneyInputs[0].sum : 0)),
                custom: getCurrencyWithId(data.legacyData.currency_id).symbol,
              },
              {
                name: this.props.langData['Expenses'],
                y: ((credits.length ? credits[0].sum : 0) + (moneyOutputs.length ? moneyOutputs[0].sum : 0)),
                custom: getCurrencyWithId(data.legacyData.currency_id).symbol,
              },
            ],

          })

        }}
      >
        {({ loading: loadingQuery, refetch }) => {

          return (
            <Card>
              <Col xs={24} sm={24} md={24} lg={12} xl={14} style={{ marginBottom: 16, textAlign: 'center' }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={5} style={{ marginBottom: 16 }}>
                  <DatePicker
                    style={{ float: 'center' }}
                    size="large"
                    suffixIcon={<Icon type="calendar" theme="filled"/>}
                    disabledDate={this.disabledStartDate}
                    showTime
                    format="DD.MM.YYYY"
                    value={this.state.startValue}
                    placeholder="Start"
                    onChange={value => this.onChange('startValue', value)}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={6} style={{ marginBottom: 16, textAlign: 'center' }}>
                  <DatePicker
                    style={{ float: 'center' }}
                    size="large"
                    disabledDate={this.disabledEndDate}
                    suffixIcon={<Icon type="calendar" theme="filled"/>}
                    showTime
                    format="DD.MM.YYYY"
                    value={this.state.endValue}
                    placeholder="End"
                    onChange={value => this.onChange('endValue', value)}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={5} xxl={3} style={{ marginBottom: 16, textAlign: 'center' }}>
                  <Button type="primary" size="large" loading={loadingQuery}
                          onClick={() => this.filterSetDate(refetch)} style={{ marginLeft: '25px' }}>
                    {this.props.langData['Apply']}
                  </Button>
                </Col>
                <Divider orientation="center">{this.props.langData['Incomes']}</Divider>
                <Table
                  columns={this.columns}
                  size="middle"
                  rowKey={() => Math.floor(Math.random() * 999999) + 1}
                  dataSource={this.state.data.income || []}
                  loading={loadingQuery}
                  pagination={false}
                  showHeader={false}
                  style={{ borderRadius: '0px' }}
                  rowClassName="white"
                /><br/><br/>
                <Divider style={{ width: '10px' }} orientation="center">{this.props.langData['Expenses']}</Divider>
                <Table
                  columns={this.columns}
                  size="middle"
                  rowKey={() => Math.floor(Math.random() * 999999) + 1}
                  dataSource={this.state.data.expense || []}
                  loading={loadingQuery}
                  pagination={false}
                  showHeader={false}
                  style={{ borderRadius: '0px' }}
                  rowClassName="white"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={10} style={{ marginBottom: 16 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                ></HighchartsReact>
              </Col>
            </Card>
          )
        }}
      </Query>
    )
  }
}

IncomeExpenseReport.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(IncomeExpenseReport)
