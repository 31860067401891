import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Input, Select, Form, Button, Icon, Popover, Checkbox, Tag, DatePicker } from 'antd'
import { connect } from 'react-redux'
import apolloClient from './../../../helpers/apolloClient'
import { deleteEmpty, graphQLErrorsToToast, toast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode } from '../../../data/Currency'
import { cloneDeep, startCase, kebabCase } from 'lodash'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import moment from 'moment'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'
import { generatePath } from 'react-router-dom'
import routes from '../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
    email_verification: state.AuthReducer.email_verification,
    filterData: state.TableFilterReducer.data.Transaction,
    filterFieldsData: state.TableFilterReducer.data.TransactionFieldsData,
    pageInfo: state.TableFilterReducer.data.TransactionPage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'Transaction' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'TransactionFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'TransactionPage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'Transaction' } })
      dispatch({ type: 'resetFilter', payload: { name: 'TransactionFieldsData' } })
    },
  }
}

const LIST_QUERY = (
  gql`
    query($filter: SafeTransactionFilterBase, $sorting: SafeTransactionSorting, $paging: Paging){
      safeTransactionList(filter: $filter, sorting: $sorting, paging: $paging){
        transactions{
          __typename
          ... on MoneyInput{
            transaction_id
            amount
            safe{
              name
              currency
            }
            date

          }
          ... on MoneyOutput{
            transaction_id
            safe{
              name
              currency
            }
            date
            amount
          }
          ... on Credit {
            transaction_id
            amount
            date
            customer {
              name
            }
            safe{
              name
              currency
            }
          }
          ... on Debit {
            transaction_id
            amount
            date
            customer {
              name
            }
            safe{
              name
              currency
            }
          }

          ... on AccountOpeningSafe{
            transaction_id
            amount
            date
            safe_id
            type
            safe{
              name
              currency
            }
          }
        }
        page_info{
          total_count
        }
      }
    }
  `
)

class TransactionList extends Component {

  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    filter: null,
    sorting: {
      field: 'date',
      type: 'DESC',
    },
    paging: {
      offset: 0,
      limit: 50,
    },
  }

  columns = [
    {
      title: this.props.langData['Type'],
      dataIndex: '__typename',
      key: 'type',
      width: '20%',
      render: (value, record) => {
        if (value === 'Credit')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Credit']}</Tag>
        if (value === 'Debit')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Debit']}</Tag>
        if (value === 'MoneyInput')
          return <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Money Input']}</Tag>
        if (value === 'MoneyOutput')
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Money Output']}</Tag>
        if (value === 'AccountOpeningSafe')
          return (
            record.type === 'MoneyInput' ?
              <Tag color="#1890ff"
                   style={{ marginRight: 0 }}>{this.props.langData['Safe Account Opening'] + '-' + this.props.langData['Money Input']}</Tag>
              : <Tag color="#ff4d4f"
                     style={{ marginRight: 0 }}>{this.props.langData['Safe Account Opening'] + '-' + this.props.langData['Money Output']}</Tag>
          )
      },
    },
    {
      title: this.props.langData['Safe'],
      dataIndex: 'safe.name',
      width: '20%',
    },
    {
      title: this.props.langData['Customer'],
      width: '20%',
      dataIndex: 'customer.name',
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      width: '20%',
      sorter: true,
      render: value => moment(value).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: this.props.langData['Amount'],
      dataIndex: 'amount',
      width: '20%',
      align: 'right',
      render: (value, data) => `${toLocaleString(value) + ' ' + getCurrencyWithCode(data.safe.currency).symbol}`,
    },
  ]

  componentDidMount() {

    this.props.breadcrumb_add(routes.TRANSACTION_LIST)

    this.setState({
      filter: this.props.filterData,
    })

    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }

  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pagesize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({
        filter: null,
      })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  rowItemClick = record => {
    this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
      type: record.__typename === 'AccountOpeningSafe' ? kebabCase(this.props.langData['Safe Account Opening']) : kebabCase(this.props.langData[startCase(record.__typename)]),
      id: record.__typename === 'AccountOpeningSafe' ? record.safe_id : record.transaction_id,
    }))
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ filter: this.state.filter, sorting: this.state.sorting, paging: this.state.paging }}
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery && ! this.state.data)
            this.setState({
              data: cloneDeep(data.safeTransactionList.transactions),
              pagination: {
                ...this.state.pagination,
                total: data.safeTransactionList.page_info.total_count,
              },
            })
          else if (this.state.data && JSON.stringify(data.safeTransactionList.transactions) !== JSON.stringify(this.state.data))
            this.setState({
              data: cloneDeep(data.safeTransactionList.transactions),
              pagination: {
                pageSize: this.state.pagination.pageSize,
                total: data.safeTransactionList.page_info.total_count,
              },
            })
          return (
            <div>
              <FilterBox callback={this.filterCallback} isSubmit={loadingQuery} {...this.props} />
              <Table
                columns={this.columns}
                rowKey={record => record.transaction_id}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={loadingQuery}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => {
                    return this.rowItemClick(record)
                  },
                })}
                rowClassName="white"
                scroll={{
                  x: 500,
                  y: ! this.props.email_verification ? 'calc(100vh - 320px)' : 'calc(100vh - 276px)',
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

TransactionList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
  email_verification: PropTypes.bool,
  filter: PropTypes.func,
  filterData: PropTypes.func,
  pageInfo: PropTypes.func,
  filterFields: PropTypes.object,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.object,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(props) {
    if (this.props.isSubmit !== props.isSubmit)
      this.setState({ isSubmit: props.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
  filterData: PropTypes.array,
}

const SAFE_LIST_QUERY = (
  gql`
    query{
      safeList{
        safes{
          safe_id
          name
        }
      }
    }
  `
)

const CUSTOMER_LIST_QUERY = (
  gql`
    query($filter: CustomerFilterBase){
      customerList(filter: $filter){
        customers{
          customer_id
          name
        }
        page_info{
          total_count
        }
      }
    }
  `
)

const CUSTOMER_READ = (
  gql`
    query($customer_id: Int!){
      customer(customer_id: $customer_id) {
        customer_id
        name
      }
    }
  `
)

class FilterBoxForm extends Component {

  state = {
    selectedOptions: [],
    safeList: [],
    customerList: [],
    isSubmit: false,
    items: [
      {
        value: 'safe_id',
        required: true,
        width: 500,
        operator: 'eq',
        label: this.props.langData['Safe'],
      },
      {
        value: 'type',
        label: this.props.langData['Type'],
        width: 250,
        operator: 'in',
        added: false,
        options: [
          { value: 'Credit', label: this.props.langData['Credit'] },
          { value: 'Debit', label: this.props.langData['Debit'] },
          { value: 'MoneyInput', label: this.props.langData['Money Input'] },
          { value: 'MoneyOutput', label: this.props.langData['Money Output'] },
          { value: 'AccountOpeningSafe', label: this.props.langData['Safe Account Opening'] },
        ],
      },
      {
        value: 'date',
        label: this.props.langData['Date'],
        width: 400,
        operator: 'date',
      },
      {
        value: 'customer_id',
        label: this.props.langData['Customer'],
        width: 300,
        operator: 'eq',
        added: false,
      },
    ],
  }

  componentDidMount() {
    this.props.filterFieldsData && Object.keys(this.props.filterFieldsData).forEach(x => {
      this.state.items.find(y => y.value === x).added = true
      this.props.form.getFieldDecorator(x, { initialValue: this.props.filterFieldsData[x] })
    })
    if (this.props.filterFieldsData && this.props.filterFieldsData.customer_id)
      this.getCustomerInformation(this.props.filterFieldsData.customer_id)
  }

  getCustomerInformation = async id => {
    try {
      let customerResult = await apolloClient.query({ query: CUSTOMER_READ, variables: { customer_id: id } })
      this.setState({
        customerList: [{
          customer_id: customerResult.data.customer.customer_id,
          name: customerResult.data.customer.name,
        }],
      })
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }


  onSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return
      }
      deleteEmpty(values)
      let filterData = []
      for (let value in values) {
        let item = this.state.items.filter(x => x.value === value)[0]
        if (value === 'type') {
          filterData.push(
            { [value]: { [item.operator]: values[value] } },
          )
        } else if (value === 'customer_id')
          filterData.push(
            {
              OR: [
                { 'credit': { [value]: { [item.operator]: values[value] } } },
                { 'debit': { [value]: { [item.operator]: values[value] } } },
              ],
            },
          )
        else if (value === 'safe_id')
          filterData.push(
            {
              OR: [
                { 'credit': { [value]: { [item.operator]: values[value] } } },
                { 'debit': { [value]: { [item.operator]: values[value] } } },
                { 'money_input': { [value]: { [item.operator]: values[value] } } },
                { 'money_output': { [value]: { [item.operator]: values[value] } } },
              ],
            },
          )
        else if (value === 'date' && values[value][0])
          filterData.push(
            {
              OR: [
                {
                  'credit': {
                    [value]: {
                      gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
                      lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
                    },
                  },
                },
                {
                  'debit': {
                    [value]: {
                      gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
                      lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
                    },
                  },
                },
                {
                  'money_input': {
                    [value]: {
                      gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
                      lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
                    },
                  },
                },
                {
                  'money_output': {
                    [value]: {
                      gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
                      lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
                    },
                  },
                },
              ],
            },
          )
      }

      this.props.callback(filterData.length ? filterData : null, values)
    })
  }

  customerValue = null

  searchCustomer = async value => {
    if (this.customerValue === value)
      return
    this.customerValue = value
    if (value.length < 2)
      return
    try {
      let customerList = await apolloClient.query({
        query: CUSTOMER_LIST_QUERY,
        variables: { filter: { AND: [{ name: { contains: value } }] } },
      })
      this.setState({
        customerList: customerList.data.customerList.customers,
      })

      if ( ! customerList.data.customerList.page_info.total_count)
        this.props.form.setFieldsValue({
          customer_id: null,
        })
    } catch (e) {
      toast(false, this.props.langData['Server error'])
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []

    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      <Button key="1"><Icon type="filter"/></Button>,
      <CheckboxedDropdown
        key="2"
        width={120}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedOptions}
        onChange={this.onChangeCheckbox}
      />,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }
    let content = [...filterHeader]

    if (this.state.items.filter(x => x.value === 'type' && x.added).length) {
      content.push(
        (
          <Popover
            content={
              getFieldDecorator('type')(
                <Checkbox.Group
                  options={this.state.items.filter(x => x.value === 'type')[0].options}
                  style={{ width: 120 }}
                />,
              )
            }
            placement="bottom"
            trigger={['click']}
            key="5"
          >
            <Button
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'type')[0].width : '100%' }}
            >
              {this.props.langData['Type']} <Icon type="caret-down" style={{ fontSize: 11 }}/>
            </Button>
          </Popover>
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'customer_id' && x.added).length) {
      content.push(
        (
          getFieldDecorator('customer_id')(
            <Select
              allowClear
              filterOption={false}
              defaultActiveFirstOption={false}
              showSearch
              suffixIcon={<Icon type="caret-down"/>}
              onSearch={this.searchCustomer}
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'customer_id')[0].width : null }}
              key="6"
              placeholder={this.props.langData['Customer']}
            >
              {this.state.customerList.map(x => <Select.Option value={x.customer_id}
                                                               key={x.customer_id}>{x.name}</Select.Option>)}
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('date')(
            <DatePicker.RangePicker key="77" format="DD-MM-YYYY"
                                    style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'date')[0].width : null }}
                                    allowClear/>,
          )
        ),
      )
    }

    content.push((
      getFieldDecorator('safe_id')(
        <Select
          allowClear
          defaultActiveFirstOption={false}
          placeholder={this.props.langData['Safe']}
          key="7"
          suffixIcon={<Icon type="caret-down"/>}
          style={{ width: '100%' }}
        >
          {this.state.safeList.map(x => {
            return <Select.Option value={x.safe_id} key={x.safe_id}>{x.name}</Select.Option>
          })}
        </Select>,
      )
    ))

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={SAFE_LIST_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading: loadingQuery, data, error }) => {
            if (error) {
              graphQLErrorsToToast(error)
              return null
            }

            if ( ! loadingQuery && ! this.state.safeList.length)
              this.setState({
                safeList: cloneDeep(data.safeList.safes),
              })

            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit()
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {content.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )
    return (
      <Query
        query={SAFE_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            if ( ! this.state.customerList)
              this.setState({ customerList: cloneDeep(data.customerList.customers) })
            if ( ! this.state.safeList)
              this.setState({ safeList: cloneDeep(data.safeList.safes) })
          }
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit()
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {content.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )

  }

}

FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isSubmit: PropTypes.bool,
  filterFieldsData: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList)
