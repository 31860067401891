import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon, Button } from 'antd'
import { connect } from 'react-redux'
import { graphQLErrorsToToast } from '../../../helpers/helper'
import { compile } from 'path-to-regexp'
import { Link } from 'react-router-dom'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { cloneDeep } from 'lodash'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const LIST_QUERY = (
  gql`
    query($paging: Paging){
      waybillTemplateList(paging: $paging){
        templates {
          template_id
          name
          default
        }
        page_info {
          total_count
        }
      }
    }
  `
)

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Waybill Template' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class WaybillTemplateList extends Component {

  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
  }

  columns = [
    {
      title: this.props.langData['Name'],
      dataIndex: 'name',
      width: '50%',
      key: 'name',
    },
    {
      title: this.props.langData['Default'],
      dataIndex: 'default',
      width: '20%',
      key: 'default',
      className: 'text-center',
      render: value => value ? <Icon type="check-circle-o" size="large" style={{ fontSize: '18px' }}/> : null,
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['waybill-template/list'])
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  rowItemClick = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['waybill-template/update/:id'])({ id: record.template_id }),
    )
  }

  handleTableChange = (pagination, filters, sorting) => {
    if (pagination.pageSize)
      this.setState({
        paging: {
          offset: (pagination.current * pagination.pageSize) - pagination.pageSize,
          limit: (pagination.current * pagination.pageSize),
        },
      })
  }

  render() {
    return (
      <Query
        query={LIST_QUERY}
        fetchPolicy="network-only"
        variables={{ paging: this.state.paging }}
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }

          if ( ! loadingQuery) {
            if ( ! this.state.data) {
              this.setState({
                data: cloneDeep(data.waybillTemplateList.templates),
                pagination: {
                  ...(this.state.pagination),
                  total: cloneDeep(data.waybillTemplateList.page_info.total_count),
                },
              })
            }
          }

          return (
            <div>
              <Table
                columns={this.columns}
                rowKey={record => record.template_id}
                dataSource={this.state.data}
                onChange={this.handleTableChange}
                loading={ ! this.state.data}
                pagination={this.state.pagination}
                rowClassName="white"
                onRow={record => ({
                  onClick: () => this.rowItemClick(record),
                })}
                scroll={{ x: 500, y: 500 }}
              />
              <div style={{ textAlign: 'right', paddingTop: 10 }}>
                <Link to={'/' + this.props.langData.route['waybill-template/new']}><Button
                  size="large">{this.props.langData['New Waybill Template']}</Button></Link>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }

}

WaybillTemplateList.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillTemplateList)
