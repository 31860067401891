import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formErrorFieldsNameLocalize, successToast } from '../../helpers/helper'
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Divider } from 'antd'

import routes from '../../routes'
import { postPanelApi } from '../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 },
    lg: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 13 },
    lg: { span: 13 },
  },
}

class NewCategoryForm extends Component {

  state = {
    submitting: false,
  }

  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      this.setState({
        submitting: true,
      })

      postPanelApi('/category/create', {
        type: this.props.type,
        name: values.name,
        code: values.code,
      }).then(result => {

        this.setState({
          submitting: false,
        })

        if (result) {

          successToast(result.message)

          this.props.onCategoryAdded()

        }

      })

    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit()
        }}
        className="form-label-default"
      >

        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('name', { rules: [{ max: 50 }, { required: true }], validateTrigger: false })(
              <Input size="large" maxLength={50}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('code', { rules: [{ max: 50 }], validateTrigger: false })(
              <Input size="large" maxLength={50}/>,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item>
            <Button type="primary" size="large" loading={this.state.submitting} htmlType="submit"
                    className="default-button">
              {this.props.langData['Save']}
            </Button>
            <Link to={routes.CATEGORY_LIST}>
              <Button
                size="large"
                style={{ marginRight: 8 }}
                loading={this.state.submitting}
                className="default-button danger2"
              >
                {this.props.langData['Close']}
              </Button>
            </Link>
          </Form.Item>
        </div>

      </Form>
    )
  }

}

NewCategoryForm.propTypes = {
  langData: PropTypes.object,
  type: PropTypes.string,
  form: PropTypes.object,
  onCategoryAdded: PropTypes.func,
}

export default NewCategoryForm
