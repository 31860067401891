import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { Link } from 'react-router-dom'
import { graphQLErrorsToToast, toast, deleteEmpty, confirm, createCsv } from '../../../helpers/helper'
import { cloneDeep } from 'lodash'
import {
  Table,
  Tag,
  Form,
  Button,
  Icon,
  Input,
  Select,
  Spin,
  Row,
  Col,
  DatePicker,
  Tooltip,
} from 'antd'
import moment from 'moment'
import apolloClient from './../../../helpers/apolloClient'
import { multipleDeleteData } from '../../../helpers/trait'
import { compile } from 'path-to-regexp'
import CheckboxedDropdown from '../../Utils/CheckboxedDropdown'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    email_verification: state.AuthReducer.email_verification,
    sizeWidth: state.SizeDetectorReducer.width,
    filterData: state.TableFilterReducer.data.Waybill,
    filterFieldsData: state.TableFilterReducer.data.WaybillFieldsData,
    pageInfo: state.TableFilterReducer.data.WaybillPage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Waybill' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'List', url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
    filter(data) {
      dispatch({ type: 'filter', payload: { data: data, name: 'Waybill' } })
    },
    filterFields(data) {
      dispatch({ type: 'filterFields', payload: { data: data, name: 'WaybillFieldsData' } })
    },
    pageInformations(data) {
      dispatch({ type: 'pageInformations', payload: { data: data, name: 'WaybillPage' } })
    },
    resetFilter() {
      dispatch({ type: 'resetFilter', payload: { name: 'Waybill' } })
      dispatch({ type: 'resetFilter', payload: { name: 'WaybillFieldsData' } })
    },
  }
}

const READ_QUERY = (
  gql`
    query($filter:WaybillFilterBase, $paging:Paging, $sorting:WaybillSorting){
      waybillList(filter:$filter, paging:$paging, sorting:$sorting){
        waybills{
          __typename
          ... on SendingWaybill{
            waybill_id
            no
            explanation
            date
            sending_date
            customer {
              name
              customer_id
            }
            e_despatch_type
            e_despatch_uuid
            e_despatch_id
          }
          ... on IncomingWaybill{
            waybill_id
            no
            explanation
            date
            sending_date
            customer {
              name
              customer_id
            }
          }
        }
        page_info {
          total_count
        }
      }
    }
  `
)

class WaybillList extends Component {

  constructor(props) {
    super(props)
    this.multipleDeleteData = multipleDeleteData('waybill', 'waybill_id').bind(this)

  }

  state = {
    data: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    sorting: {
      field: 'sending_date',
      type: 'DESC',
    },
    filter: null,
    selectedRows: [],
    isDeleteEnable: false,
  }

  columns = [
    {
      title: this.props.langData['Waybill Type'],
      dataIndex: '__typename',
      fieldName: '__typename',
      render: (record, data) => {
        if (record === 'SendingWaybill') {
          return <div>
            <Tag color="#1890ff" style={{ marginRight: 0 }}>{this.props.langData['Sending Waybill']}</Tag><br/>
            {data.e_despatch_type &&
              <Tag color="#ff7875" style={{ marginRight: 0, marginTop: 5 }}>{this.props.langData['E-Despatch']}</Tag>}
          </div>
        } else if (record === 'IncomingWaybill') {
          return <Tag color="#ff4d4f" style={{ marginRight: 0 }}>{this.props.langData['Incoming Waybill']}</Tag>
        }
      },
    },
    {
      title: this.props.langData['Customer'],
      dataIndex: 'customer.name',
      fieldName: 'customer.name',
    },
    {
      title: this.props.langData['Waybill No'],
      dataIndex: 'no',
      fieldName: 'no',
      align: 'center',
    },
    {
      title: this.props.langData['Explanation'],
      dataIndex: 'explanation',
      fieldName: 'explanation',
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'date',
      fieldName: 'date',
      render: (record) => moment(record).format('DD.MM.YYYY'),
      sorter: true,
    },
    {
      title: this.props.langData['Date of Shipment'],
      dataIndex: 'sending_date',
      fieldName: 'sending_date',
      align: 'right',
      sorter: true,
      render: (record) => moment(record).format('DD.MM.YYYY HH:mm'),
    },
  ]

  componentDidMount() {
    this.props.breadcrumb_add('/' + this.props.langData.route['waybill/list'])
    this.setState({ filter: this.props.filterData })

    if (this.props.pageInfo && ! this.props.filterData && (this.state.pagination.current !== this.props.pageInfo.current)) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          current: this.props.pageInfo.current,
        },
        paging: {
          offset: this.props.pageInfo.offset,
          limit: this.props.pageInfo.limit,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  rowItemClick = record => {
    this.props.history.push(
      '/' + compile(this.props.langData.route['waybill/:type/detail/:id'])({
        type: this.props.langData[record.__typename === 'SendingWaybill' ? 'Sending' : 'Incoming'],
        id: record.waybill_id,
      }),
    )
  }

  getVisibleColumnsFieldName = () => {
    return this.columns ? this.columns.filter(x => ! x.hide).map(x => x.fieldName) : []
  }

  handleTableChange = (paging, filter, sorting) => {
    if (paging.pageSize) {
      this.setState({
        paging: {
          offset: (paging.current * paging.pageSize) - paging.pageSize,
          limit: paging.pageSize,
        },
        pagination: {
          ...this.state.pagination,
          current: paging.current,
        },
      })
      this.props.pageInformations({
        current: paging.current,
        offset: (paging.current * paging.pageSize) - paging.pageSize,
        limit: paging.pageSize,
      })
    }

    if (sorting.field)
      this.setState({
        sorting: {
          field: sorting.field,
          type: (sorting.order === 'descend' ? 'DESC' : 'ASC'),
        },
      })
  }

  filterCallback = (filter, fields) => {
    if ( ! filter) {
      this.setState({
        filter: null,
      })
      this.props.resetFilter()
    } else {
      this.setState({
        filter: {
          AND: filter,
        },
        paging: {
          offset: 0,
          limit: 50,
        },
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      })
      this.props.filter({ AND: filter })
      this.props.filterFields(fields)
    }
  }

  multipleDelete = refetch => {
    confirm(undefined, result => {
      if ( ! result)
        return
      this.multipleDeleteData(this.state.selectedRows, refetch)
      this.setState({
        selectedRows: [],
        isDeleteEnable: false,
      })
    })
  }

  getSelectedRows = () => {
    let transactionIds = []
    this.state.selectedRows.forEach(row => {
      transactionIds.push(row.waybill_id)
    })
    return transactionIds
  }

  printWaybill = () => {
    const transactionIds = this.getSelectedRows()
    window.open(`${process.env.REACT_APP_NEW_API_URL}/waybill/printMultiple/${localStorage.token}/${transactionIds}/1x/v/${this.props.lang}`, '_blank')
  }

  render() {
    return (
      <Query
        query={READ_QUERY}
        fetchPolicy="network-only"
        variables={{ filter: this.state.filter, paging: this.state.paging, sorting: this.state.sorting }}
      >
        {({ loading: loadingQuery, error, data, refetch }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if (typeof data !== 'undefined') {
            if ( ! loadingQuery && ! this.state.data)
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: data.waybillList.page_info.total_count,
                },
                data: cloneDeep(data.waybillList.waybills),
              })
            else if (this.state.data && JSON.stringify(data.waybillList.waybills) !== JSON.stringify(this.state.data))
              this.setState({
                data: cloneDeep(data.waybillList.waybills),
                pagination: {
                  pageSize: this.state.pagination.pageSize,
                  total: data.waybillList.page_info.total_count,
                },
              })
          }

          return (
            <div>
              <FilterBox
                callback={this.filterCallback}
                isSubmit={loadingQuery}
                csv={createCsv.bind(this)}
                multipleDelete={() => {
                  this.multipleDelete(refetch)
                }}
                {...this.props}
              />
              <Row type="flex" justify="start">
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={4}
                     xl={4}>
                  <Link to={'/' + compile(this.props.langData.route['waybill/new/:type'])({
                    type: this.props.langData['Sending'],
                  })}>
                    <Button className="btn-add" style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                            icon="plus-circle">{this.props.langData['Sending Waybill']}</Button>
                  </Link>
                </Col>
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={4}
                     xl={4}>
                  <Link to={'/' + compile(this.props.langData.route['waybill/new/:type'])({
                    type: this.props.langData['Incoming'],
                  })}>
                    <Button className="btn-add" style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                            icon="plus-circle">{this.props.langData['Incoming Waybill']}</Button>
                  </Link>
                </Col>
                {this.state.isDeleteEnable &&
                  this.state.selectedRows.length <= 10 && <Col xs={12} sm={8} md={6} lg={4} xl={4}>
                    <Tooltip placement="top" title={this.props.langData['You can send up to 10 waybill!']}>
                      <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                              onClick={this.printWaybill}><Icon
                        type="copy"/>{this.props.langData['Print Waybill']}</Button>
                    </Tooltip>
                  </Col>}
                <Col xs={this.state.isDeleteEnable ? 8 : 12} sm={this.state.isDeleteEnable ? 8 : 12} md={6} lg={4}
                     xl={4}>
                  {this.state.isDeleteEnable &&
                    <Button style={{ marginBottom: 10, borderRadius: 0 }} block={true}
                            onClick={() => this.multipleDelete(refetch)}><Icon
                      type="delete"/>{this.props.langData['Delete']}</Button>}
                </Col>
              </Row>
              <Table
                columns={this.columns}
                rowKey={record => record.waybill_id}
                pagination={this.state.pagination}
                loading={loadingQuery}
                onChange={this.handleTableChange}
                onRow={record => ({
                  onClick: () => this.rowItemClick(record),
                })}
                dataSource={this.state.data}
                rowSelection={{
                  selectedRowKeys: this.state.selectedRows.length ? this.state.selectedRows.map(x => x.waybill_id) : [],
                  onChange: (_, k) => {
                    this.setState({
                      selectedRows: k.map(y => ({
                        waybill_id: y.waybill_id,
                        type: y.__typename === 'SendingWaybill' ? 'sending' : y.__typename === 'IncomingWaybill' ? 'incoming' : null,
                      })),
                      isDeleteEnable: k.length ? true : false,
                    })
                  },
                  columnWidth: 60,
                }}
                rowClassName="white"
                scroll={{
                  x: 700,
                  y: ! this.props.email_verification ? 'calc(100vh - 360px)' : 'calc(100vh - 318px)',
                }}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

WaybillList.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  lang: PropTypes.string,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  email_verification: PropTypes.bool,
  filter: PropTypes.func,
  filterFields: PropTypes.func,
  filterData: PropTypes.object,
  pageInfo: PropTypes.func,
  filterFieldsData: PropTypes.object,
  pageInformations: PropTypes.func,
  resetFilter: PropTypes.func,
}

class FilterBox extends Component {

  state = {
    isSubmit: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isSubmit !== nextProps.isSubmit)
      this.setState({ isSubmit: nextProps.isSubmit })
    return false
  }

  render() {
    const WrappedFilterBoxForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(FilterBoxForm)

    return (
      <div style={{ marginBottom: 10 }}>
        <WrappedFilterBoxForm {...this.props} isSubmit={this.state.isSubmit}/>
      </div>
    )
  }

}

FilterBox.propTypes = {
  langData: PropTypes.object,
  isSubmit: PropTypes.bool,
}

const CUSTOMER_LIST_QUERY = (
  gql`
    query($filter: CustomerFilterBase){
      customerList(filter: $filter){
        customers{
          customer_id
          name
        }
        page_info{
          total_count
        }
      }
    }
  `
)

const CUSTOMER_READ = (
  gql`
    query($customer_id: Int!){
      customer(customer_id: $customer_id) {
        customer_id
        name
      }
    }
  `
)

class FilterBoxForm extends Component {

  state = {
    selectedOptions: [],
    customerList: [],
    isSubmit: false,
    items: [
      {
        value: 'customer_id',
        required: true,
        width: 200,
        operator: 'eq',
      },
      {
        value: 'type',
        label: this.props.langData['Type'],
        added: false,
        width: 300,
        operator: 'eq',
      },
      {
        value: 'date',
        label: this.props.langData['Date'],
        added: false,
        width: '30%',
        operator: 'date',
      },
      {
        value: 'sending_date',
        label: this.props.langData['Date of Shipment'],
        added: false,
        width: '30%',
        operator: 'date',
      },
      {
        value: 'no',
        label: this.props.langData['Waybill No'],
        added: false,
        width: 300,
        operator: 'contains',
      },
    ],
  }

  componentDidMount() {
    this.props.filterFieldsData && Object.keys(this.props.filterFieldsData).forEach(x => {
      this.state.items.find(y => y.value === x).added = true
      this.props.form.getFieldDecorator(x, { initialValue: this.props.filterFieldsData[x] })
    })
    if (this.props.filterFieldsData && this.props.filterFieldsData.customer_id)
      this.getCustomerInformation(this.props.filterFieldsData.customer_id)
  }

  getCustomerInformation = async id => {
    try {
      let customerResult = await apolloClient.query({ query: CUSTOMER_READ, variables: { customer_id: id } })
      this.setState({
        customerList: [{
          customer_id: customerResult.data.customer.customer_id,
          name: customerResult.data.customer.name,
        }],
      })
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  onChangeCheckbox = options => {
    let items = this.state.items
    options.map(option => {
      items.filter(x => x.value === option)[0].added = true
    })
    items.filter(x => {
      if (options.indexOf(x.value) === -1)
        x.added = false
    })
    this.setState({ items: items })
    this.calculateItemsWidth()
  }

  calculateItemsWidth = () => {
    let items = this.state.items
    let requiredItem = items.filter(x => x.required)[0]
    requiredItem.width = 77
    items.filter(x => ! x.required && x.added).map(x => {
      requiredItem.width -= x.width
    })
    this.setState({
      items: items,
    })
  }

  onSubmit = form => {
    form.validateFields((err, values) => {
      if (err)
        return
      this.setState({ isSubmit: true })
      deleteEmpty(values)
      let filterData = []

      for (let value in values) {
        let item = this.state.items.filter(x => x.value === value)[0]
        if (item.operator === 'date')
          filterData.push({
            [value]: {
              gte: moment(values[value][0]).format('YYYY-MM-DDT00:00:00.000Z'),
              lte: moment(values[value][1]).format('YYYY-MM-DDT23:59:00.000Z'),
            },
          })
        else
          filterData.push({ [value]: { [item.operator]: values[value] } })
      }

      this.props.callback(filterData.length ? filterData : null, values)

    })
  }

  customerValue = null

  searchCustomer = async value => {
    if (this.customerValue === value)
      return
    this.customerValue = value
    if (value.length < 2)
      return
    try {
      let customerList = await apolloClient.query({
        query: CUSTOMER_LIST_QUERY,
        variables: { filter: { AND: [{ name: { contains: value } }] } },
      })
      this.setState({
        customerList: customerList.data.customerList.customers,
      })

      if ( ! customerList.data.customerList.page_info.total_count)
        this.props.form.setFieldsValue({
          customer_id: null,
        })
    } catch (e) {
      toast(false, this.props.langData['Server error'])
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form

    let options = []
    this.state.items.filter(x => x.required !== true).map(x => options.push({ value: x.value, label: x.label }))

    let selectedOptions = []
    this.state.items.filter(x => ! x.required && x.added).map(x => selectedOptions.push(x.value))

    let headerContent = [
      <Button key="1" onClick={() => this.props.csv()}><Icon type="file-unknown"/></Button>,
      <CheckboxedDropdown
        key="2"
        width={150}
        langData={this.props.langData}
        options={options}
        selectedValues={selectedOptions}
        onChange={this.onChangeCheckbox}
      />,
    ]

    let filterHeader

    if (this.props.sizeWidth > 576) {
      filterHeader = headerContent
    } else {
      headerContent.push(
        <Button htmlType="submit" key="3" style={{ width: this.props.sizeWidth <= 576 ? '100%' : null }}
                disabled={this.state.isSubmit}>{this.props.langData['Filter']}</Button>,
      )
      filterHeader = [
        (
          <Input.Group compact key="4" style={{ height: 32, display: 'flex' }}>
            {headerContent}
          </Input.Group>
        ),
      ]
    }

    let content = [...filterHeader]

    if (this.state.items.filter(x => x.value === 'type' && x.added).length) {
      content.push(
        (
          getFieldDecorator('type')(
            <Select
              style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'type')[0].width : null }}
              allowClear
              suffixIcon={<Icon type="caret-down"/>}
              key="55"
              placeholder={this.props.langData['Type']}
            >
              <Select.Option value="IncomingWaybill">{this.props.langData['Incoming Waybill']}</Select.Option>
              <Select.Option value="SendingWaybill">{this.props.langData['Sending Waybill']}</Select.Option>
            </Select>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'no' && x.added).length) {
      content.push(
        (
          getFieldDecorator('no')(
            <Input allowClear key="6"
                   style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'no')[0].width : null }}
                   placeholder={this.props.langData['Waybill No']}/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('date')(
            <DatePicker.RangePicker key="77" format="DD-MM-YYYY"
                                    style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'date')[0].width : null }}
                                    allowClear/>,
          )
        ),
      )
    }

    if (this.state.items.filter(x => x.value === 'sending_date' && x.added).length) {
      content.push(
        (
          getFieldDecorator('sending_date')(
            <DatePicker.RangePicker key="8" format="DD-MM-YYYY"
                                    style={{ width: this.props.sizeWidth > 576 ? this.state.items.filter(x => x.value === 'sending_date')[0].width : null }}
                                    allowClear/>,
          )
        ),
      )
    }


    content.push((
      getFieldDecorator('customer_id')(
        <Select
          allowClear
          filterOption={false}
          defaultActiveFirstOption={false}
          suffixIcon={<Icon type="caret-down"/>}
          showSearch
          notFoundContent={this.state.fetchingCustomer ? <Spin/> : null}
          onSearch={this.searchCustomer}
          placeholder={this.props.langData['Customer']}
          key="7"
          style={{ width: '100%' }}
        >
          {this.state.customerList.map((x, k) => {
            return <Select.Option value={x.customer_id} key={k}>{x.name}</Select.Option>
          })}
        </Select>,
      )
    ))

    if (this.props.sizeWidth > 576)
      return (
        <Query
          query={CUSTOMER_LIST_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading: loadingQuery, data, error }) => {
            if (error) {
              graphQLErrorsToToast(error)
              return null
            }
            if ( ! loadingQuery && ! this.state.customerList)
              this.setState({ categoryList: cloneDeep(data.customerList) })
            return (
              <Form onSubmit={e => {
                e.preventDefault()
                this.onSubmit(this.props.form)
              }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  {content.map(x => x)}
                  <Button htmlType="submit" disabled={this.props.isSubmit}>{this.props.langData['Filter']}</Button>
                </Input.Group>
              </Form>
            )
          }}
        </Query>
      )

    return (
      <Query
        query={CUSTOMER_LIST_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, data, error }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery && ! this.state.customerList)
            this.setState({ customerList: cloneDeep(data.customerList) })
          return (
            <Form onSubmit={e => {
              e.preventDefault()
              this.onSubmit(this.props.form)
            }} style={{ display: 'flex', flexDirection: 'column', lineHeight: '40px' }}>
              {content.map(x => x)}
            </Form>
          )
        }}
      </Query>
    )
  }
}


FilterBoxForm.propTypes = {
  langData: PropTypes.object,
  callback: PropTypes.func,
  form: PropTypes.object,
  sizeWidth: PropTypes.number,
  isSubmit: PropTypes.bool,
  print: PropTypes.func,
  csv: PropTypes.func,
  isDeleteEnable: PropTypes.bool,
  multipleDelete: PropTypes.func,
  filterFieldsData: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillList)
