import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Icon, Button, Dropdown, Menu, Divider, Table, Modal } from 'antd'
import { Route, Link, generatePath } from 'react-router-dom'
import { confirm, toLocaleString, graphQLErrorsToToast } from '../../../helpers/helper'
import { getCurrenciesWithoutMatchWithCode, getCurrencyWithCode } from '../../../data/Currency'
import { compile } from 'path-to-regexp'
import PropTypes from 'prop-types'
import { camelCase } from 'lodash'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { deleteData } from '../../../helpers/trait'
import moment from 'moment'
import CreditDebitUpdate from './../../Customer/CreditDebitUpdate/CreditDebitUpdate'
import routes from '../../../routes'
import UpdateMoneyIO from '../../Safe/UpdateMoneyIO'
import { PayCircleOutlined } from '@ant-design/icons'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    sizeWidth: state.SizeDetectorReducer.width,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Transaction' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const CREDIT_DEBIT_READ_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      data: ${type}(transaction_id: $transaction_id) {
      amount
      date
      explanation
      safe {
      name
      currency
      }
      customer {
      customer_id
      name
      }
      commits {
      invoice_transaction_id
      currency_amounts {
      try
      usd
      eur
      gbp
      rub
      }
      invoice: ${type === 'debit' ? 'sales' : 'purchase'}_invoice {
      grand_total
      paid
      no
      series
      explanation
      currency
      }
      }
      return_commits {
      invoice_transaction_id
      paid_transaction_id
      currency_amounts {
      try
      usd
      eur
      gbp
      rub
      }
      invoice: return_${type === 'debit' ? 'purchase' : 'sales'}_invoice {
      no
      series
      grand_total
      paid
      explanation
      currency
      }
      }
      }
      }
  `
)

const MONEY_INPUT_OUTPUT_READ_QUERY = type => (
  gql`
      query ($transaction_id: Int!){
      data: ${camelCase(type)}(transaction_id: $transaction_id) {
      amount
      date
      explanation
      safe {
      name
      currency
      }
      category {
      name
      }
      }
      }
  `
)

const ACCOUNT_OPENING_CUSTOMER_READ_QUERY = (
  gql`
      query ($customer_id: Int!){
          data: accountOpeningCustomer(customer_id: $customer_id) {
              type
              amount
              date
              currency
              customer {
                  name
              }
              commits {
                  __typename
                  ... on SalesInvoiceCommit {
                      invoice_transaction_id
                      currency_amounts {
                          try
                          usd
                          eur
                          gbp
                          rub
                      }
                      invoice: sales_invoice {
                          grand_total
                          paid
                          no
                          series
                          explanation
                          currency
                      }
                  }
                  ... on PurchaseInvoiceCommit {
                      invoice_transaction_id
                      currency_amounts {
                          try
                          usd
                          eur
                          gbp
                          rub
                      }
                      invoice: purchase_invoice {
                          grand_total
                          paid
                          no
                          series
                          explanation
                          currency
                      }
                  }
              }
          }
      }
  `
)

const ACCOUNT_OPENING_SAFE_READ_QUERY = (
  gql`
      query ($safe_id: Int!){
          data: accountOpeningSafe(safe_id: $safe_id) {
              type
              amount
              date
              safe {
                  name
                  currency
              }
          }
      }
  `
)

class TransactionDetail extends Component {

  id = null

  typeEnglishName = {
    'odeme': 'credit',
    'tahsilat': 'debit',
    'cari-hesap-acilisi': 'customer-account-opening',
    'kasa-hesap-acilisi': 'safe-account-opening',
    'para-giris': 'money-input',
    'para-cikis': 'money-output',
  }

  typeNames = {
    'customer-account-opening': 'Customer Account Opening',
    'safe-account-opening': 'Safe Account Opening',
    'credit': 'Credit',
    'debit': 'Debit',
    'money-input': 'Money Input',
    'money-output': 'Money Output',
  }


  columns = [
    {
      title: this.props.langData['Invoice No'],
      dataIndex: 'invoice.no',
      render: (value, record) => {
        return `
					${value ? value : ''}
					${record.invoice.serial ? (value ? ' / ' + record.invoice.serial : record.invoice.serial) : ''}
					${! value && ! record.invoice.serial ? '-' : ''}
				`
      },
    },
    {
      title: this.props.langData['Explanation'],
      dataIndex: 'invoice.explanation',
      render: value => value ? value : '-',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'invoice.grand_total',
      render: (value, record) => `${toLocaleString(value) + ' ' + getCurrencyWithCode(record.invoice.currency).symbol}`,
    },
    {
      title: this.props.langData['Remaining'],
      dataIndex: 'remainig',
      render: (...params) => `${toLocaleString(params[1].invoice.grand_total - params[1].invoice.paid) + ' ' + getCurrencyWithCode(params[1].invoice.currency).symbol}`,
    },
    {
      title: this.props.langData['Amount processed'],
      className: 'text-right',
      render: (...params) => {
        let currency = getCurrencyWithCode(this.state.data.safe ? this.state.data.safe.currency : this.state.data.currency)
        return (
          <div>
            <div>{toLocaleString(params[1].currency_amounts[currency.code]) + ' ' + currency.symbol}</div>
            {getCurrenciesWithoutMatchWithCode(currency.code).map((x, k) => {
              if (params[1].currency_amounts[x.code] && x.code !== currency.code)
                return <div
                  key={k}>{`${toLocaleString(params[1].currency_amounts[x.code]) + ' ' + getCurrencyWithCode(x.code).symbol}`}</div>
            })}
          </div>
        )
      },
    },
  ]

  constructor(props) {

    super(props)

    this.id = Number(props.match.params.id)

    let type = this.props.match.params.type

    if (this.typeEnglishName[type]) {
      type = this.typeEnglishName[type]
    }

    this.type = type

    this.state = {
      data: null,
    }

  }

  componentDidMount() {
    this.props.breadcrumb_add(
      this.typeNames[this.type],
      generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
        type: this.props.langData[this.type],
        id: this.id,
      }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  rowItemClick = record => {
    let typeName

    if (record.__typename) {
      typeName = record.__typename === 'SalesInvoiceCommit' ? 'sales' : 'purchase'
    } else {
      typeName = this.type === 'debit' ? 'sales' : 'purchase'
    }

    if (this.state.data.commits.map(x => x.invoice)[0]) {
      this.props.history.push(
        generatePath(routes.INVOICE_DETAIL_BY_TYPE, {
          type: this.props.langData[typeName],
          id: record.invoice_transaction_id,
        }),
      )
    } else {
      if (this.type === 'debit') {
        this.props.history.push(generatePath(routes.PURCHASE_RETURN_INVOICE_DETAIL, {
          id: record.invoice_transaction_id,
        }))
      } else {
        this.props.history.push(generatePath(routes.INVOICE_RETURN_BY_TYPE, {
          type: this.type === 'debit' ? `${'purchase'}Detail` : `${'sales'}Detail`,
          id: record.invoice_transaction_id,
        }))
      }
    }
  }

  delete = () => {
    confirm(undefined, result => {
      if ( ! result)
        return

      let operation

      if (this.type === 'credit')
        operation = deleteData('credit', 'transaction_id').bind(this)
      else if (this.type === 'debit')
        operation = deleteData('debit', 'transaction_id').bind(this)
      else if (this.type === 'customer-account-opening')
        operation = deleteData('accountOpeningCustomer', 'customer_id').bind(this)
      else if (this.type === 'safe-account-opening')
        operation = deleteData('accountOpeningSafe', 'safe_id').bind(this)
      else if (this.type === 'money-input')
        operation = deleteData('moneyInput', 'transaction_id').bind(this)
      else if (this.type === 'money-output')
        operation = deleteData('moneyOutput', 'transaction_id').bind(this)

      operation(this.id)

    })
  }

  edit = () => {
    if (['safe-account-opening', 'customer-account-opening'].includes(this.type)) {
      this.props.history.push(
        '/' + compile(this.props.langData.route[`${this.type.split('-')[0]}/update/:id`])({
          id: this.id,
        }),
      )
    } else {
      this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_UPDATE_BY_TYPE, {
        type: this.type,
        id: this.id,
      }))
    }
  }

  menuOnClick = item => {
    switch (item.key) {
      case 'edit':
        this.edit()
        break
      case 'delete':
        this.delete()
        break
    }
  }

  render() {

    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="edit">
          <span><Icon type="edit"/> {this.props.langData['Edit']}</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={(() => {
          if (['credit', 'debit'].includes(this.type)) {
            return CREDIT_DEBIT_READ_QUERY(this.type)
          } else if (['money-input', 'money-output'].includes(this.type)) {
            return MONEY_INPUT_OUTPUT_READ_QUERY(this.type)
          } else if (this.type === 'customer-account-opening') {
            return ACCOUNT_OPENING_CUSTOMER_READ_QUERY
          } else if (this.type === 'safe-account-opening') {
            return ACCOUNT_OPENING_SAFE_READ_QUERY
          }
        })()}
        variables={{
          [(this.type === 'customer-account-opening') ? 'customer_id' : (this.type === 'safe-account-opening' ? 'safe_id' : 'transaction_id')]: Number(this.id),
        }}
        skip={ ! this.type}
        fetchPolicy="network-only"
        onError={graphQLErrorsToToast}
        onCompleted={data => {
          this.setState({ data: data.data })
        }}
      >
        {({ loading, refetch }) => {

          return (this.state.data &&
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="pay-circle-o"/> &nbsp;{this.props.langData['Transaction Detail']}</span>}
                  bordered={false}
                  loading={loading}
                  extra={
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button loading={loading}>
                        {this.props.langData['Actions']} {<Icon type="down"/>}
                      </Button>
                    </Dropdown>
                  }
                >

                  <React.Fragment>

                    <div className="form-block label-default">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="tag"/><p>{this.props.langData['Action']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {this.props.langData[this.typeNames[this.type]]}
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="wallet"/><p>{this.props.langData['Safe']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {this.state.data.safe ? this.state.data.safe.name : '-'}
                        </Col>
                      </Row>

                      {this.state.data.customer && <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="idcard"/><p>{this.props.langData['Customer']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          <Link
                            to={generatePath(routes.CUSTOMER_DETAIL, {
                              id: this.state.data.customer.customer_id ? this.state.data.customer.customer_id : this.id,
                            })}
                          >
                            <span style={{ color: '#1890ff' }}>
                              {this.state.data.customer ? this.state.data.customer.name : ''}
                            </span>
                          </Link>
                        </Col>
                      </Row>}

                      {this.state.data.category &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {this.state.data.category.name}
                          </Col>
                        </Row>
                      }

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="pay-circle-o"/><p>{this.props.langData['Amount']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {
                            this.state.data.type === 0 && (this.state.data.type === 4 || this.state.data.type === 1) ? '-' : ''
                          }
                          {
                            toLocaleString(this.state.data.amount) + ' ' +
                            (this.state.data.safe ?
                                getCurrencyWithCode(this.state.data.safe.currency).symbol :
                                getCurrencyWithCode(this.state.data.currency).symbol
                            )
                          }
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="calendar"/><p>{this.props.langData['Date']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {moment(this.state.data.date).locale(this.props.lang).format('DD MMMM YYYY H:mm')}
                        </Col>
                      </Row>

                    </div>

                    {this.state.data.explanation && <React.Fragment>
                      <Divider/>
                      <div style={{ padding: '0px 10px 20px 10px', textAlign: 'center' }}>
                        {this.state.data.explanation}
                      </div>
                    </React.Fragment>}

                  </React.Fragment>

                </Card>

                {(['credit', 'debit', 'customer-account-opening'].includes(this.type)) && <Card
                  bodyStyle={{ padding: 0, marginTop: 20 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon type="swap"/> {this.props.langData['Invoices Processed']}
                  </Divider>

                  <Table
                    columns={this.columns}
                    rowKey={() => Math.floor(Math.random() * 999999) + 1}
                    dataSource={this.state.data && (this.state.data.commits.length > 0 && this.state.data.commits.map(x => x.invoice)[0] !== null ? this.state.data.commits : this.state.data.return_commits)}
                    loading={ ! this.state.data}
                    pagination={false}
                    onRow={record => ({
                      onClick: () => this.rowItemClick(record),
                    })}
                    style={{ borderRadius: '0px' }}
                    rowClassName="white"
                    scroll={{ x: 700 }}
                  />
                </Card>}

              </Col>
              <Col xs={24} sm={24} md={10} lg={8} xl={7}>
                <Route exact path={routes.TRANSACTION_DETAIL_UPDATE_BY_TYPE} render={props => {

                  let updateComponent

                  if (['money-input', 'money-output'].includes(this.type)) {
                    updateComponent = (
                      <UpdateMoneyIO
                        transaction_id={this.id}
                        type={this.type}
                        {...props}
                        callback={() => {
                          this.setState({ data: null })
                          refetch()
                        }}
                      />
                    )
                  } else {
                    updateComponent = (
                      <CreditDebitUpdate
                        transaction_id={Number(this.id)} type={this.type} {...props}
                        callback={() => {
                          this.setState({ data: null })
                          refetch()
                        }}
                      />
                    )
                  }

                  if (this.props.sizeWidth > 576) {
                    return updateComponent
                  }

                  return (
                    <Modal
                      title={<span><PayCircleOutlined/> &nbsp;{this.props.langData['Update']}</span>}
                      bodyStyle={{ padding: 8 }}
                      visible={true}
                      footer={null}
                      onCancel={() => {
                        props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
                          id: this.id,
                          type: this.type,
                        }))
                      }}
                    >
                      {updateComponent}
                    </Modal>
                  )

                }}/>
              </Col>
            </Row>
          )
        }}
      </Query>
    )
  }

}

TransactionDetail.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  sizeWidth: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetail)
