import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Icon, Button, Modal, Dropdown, Menu, Divider, Spin } from 'antd'
import { Route, Link, generatePath } from 'react-router-dom'
import { confirm, graphQLErrorsToToast, toLocaleString } from '../../../helpers/helper'
import NewCreditDebit from './../NewCreditDebit/NewCreditDebit'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { deleteData } from '../../../helpers/trait'
import moment from 'moment'

import TransactionList from './TransactionList'
import routes from '../../../routes'
import { PayCircleOutlined } from '@ant-design/icons'
import { getCurrencyWithId } from '../../../data/Currency'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(name, url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Customer' } })
      dispatch({ type: 'breadcrumb_add', payload: { name, url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const READ_QUERY = gql`
  query ($customer_id: Int!, $currency: Currency, $date: Date!){
    customer(customer_id: $customer_id){
      name
      code
      type
      category {
        name
      }
      balance {
        try
        usd
        eur
        gbp
        rub
      }
      e_mail
      id_or_tax_identification_number
      tax_administration
      phone_1
      phone_2
      country
      postal_code
      city
      town
      address
      explanation
    }

    currencyRates(currency: $currency, date: $date){
      currency_rates {
        try
        usd
        eur
        gbp
        rub
      }
    }

    legacyData {
      currency_id
    }

  }
`

class CustomerDetail extends Component {

  customer_id = null
  currencyId = 1

  state = {
    transactions: null,
    more: false,
  }

  constructor(props) {
    super(props)
    this.customer_id = Number(props.match.params.id)
    this.deleteData = deleteData('customer', 'customer_id').bind(this)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  edit = () => {
    this.props.history.push(generatePath(routes.CUSTOMER_UPDATE, { id: this.customer_id }))
  }

  delete = () => {
    confirm(undefined, result => {
      if ( ! result) {
        return
      }
      this.deleteData(this.customer_id)
    })
  }

  toggleMore = () => {
    this.setState({
      more: ! this.state.more,
    })
  }

  invoicePurchase = () => {
    this.props.history.push(generatePath(routes.INVOICE_TYPE_NEW, {
      id: this.customer_id,
      type: this.props.langData['purchase'],
    }))
  }

  invoiceSales = () => {
    this.props.history.push(generatePath(routes.INVOICE_TYPE_NEW, {
      id: this.customer_id,
      type: this.props.langData['sales'],
    }))
  }

  menuOnClick = item => {

    switch (item.key) {
      case 'invoicePurchase':
        this.invoicePurchase()
        break
      case 'invoiceSales':
        this.invoiceSales()
        break
      case 'edit':
        this.edit()
        break
      case 'delete':
        this.delete()
        break
    }

  }

  render() {

    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="invoicePurchase">
          <span><Icon type="file-sync"/>{this.props.langData['Add Purchase Invoice']}</span>
        </Menu.Item>
        <Menu.Item key="invoiceSales">
          <span><Icon type="file-done"/>{this.props.langData['Add Sales Invoice']}</span>
        </Menu.Item>
        <Menu.Item key="edit" icon="edit">
					<span>
						<Icon type="edit"/> {this.props.langData['Edit']}
					</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/> {this.props.langData['Delete']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={READ_QUERY}
        fetchPolicy="network-only"
        variables={{
          customer_id: this.customer_id,
          date: moment().format('YYYY-MM-DD'),
        }}
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          this.currencyId = data.legacyData.currency_id

          this.props.breadcrumb_add(data.customer.name, generatePath(routes.CUSTOMER_DETAIL, { id: this.customer_id }))

        }}
      >
        {({ loading: loadingReadQuery, data }) => {

          if (loadingReadQuery) {
            return <Spin/>
          }

          // bakiyeyi at
          const customerBalances = Object.assign({}, data.customer.balance)

          // gösterilecek bakiyeyi günün kuruna dönüştür
          let totalCustomerBalance = 0

          Object.keys(customerBalances).map(x => {
            if (x !== '__typename') {
              totalCustomerBalance += parseFloat((customerBalances[x] / data.currencyRates.currency_rates[x]).toFixed(4))
            }
          })

          const customerData = data.customer

          return (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  title={<span><Icon type="idcard"/> &nbsp;{this.props.langData['Customer Detail']}</span>}
                  bordered={false}
                  extra={
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button>
                        {this.props.langData['Actions']} {<Icon type="down"/>}
                      </Button>
                    </Dropdown>
                  }
                >

                  <React.Fragment>

                    <div className="form-block label-default">
                      <Row gutter={24} style={{ marginBottom: 16 }}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                          <span><Icon type="tag"/><p>{this.props.langData['Name']}</p></span>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                          {customerData.name}
                        </Col>
                      </Row>


                      {customerData.category &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon type="bulb"/><p>{this.props.langData['Category']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {customerData.category.name}
                          </Col>
                        </Row>
                      }

                      {customerData.code &&
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon type="paper-clip"/><p>{this.props.langData['Code']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {customerData.code}
                          </Col>
                        </Row>
                      }

                    </div>

                    <Divider>
                      <Button size="small" type="dashed" onClick={this.toggleMore}>
                        {! this.state.more ? this.props.langData['More'] : this.props.langData['Close']}
                      </Button>
                    </Divider>

                    {this.state.more &&
                      <div>
                        <div className="form-block label-default">

                          {customerData.phone_1 &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="phone"/><p>{this.props.langData['Phone']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.phone_1}
                              </Col>
                            </Row>
                          }

                          {customerData.phone_2 &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="phone"/><p>{this.props.langData['Phone']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.phone_2}
                              </Col>
                            </Row>
                          }

                          {customerData.country &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="environment-o"/><p>{this.props.langData['Country']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.country}
                              </Col>
                            </Row>
                          }

                          {(customerData.city || customerData.town) &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
																			<span>
																				<Icon type="environment-o"/>
																				<p>{this.props.langData['City'] + ' / ' + this.props.langData['Town']}</p>
																			</span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.city ? customerData.city + ' / ' : ''}
                                {customerData.town}
                              </Col>
                            </Row>
                          }

                          {customerData.postal_code &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="environment-o"/><p>{this.props.langData['Postal Code']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.postal_code}
                              </Col>
                            </Row>
                          }

                          {customerData.address &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="environment-o"/><p>{this.props.langData['Address']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.address}
                              </Col>
                            </Row>
                          }

                          {customerData.e_mail &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="mail"/><p>{this.props.langData['E-Mail']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.e_mail}
                              </Col>
                            </Row>
                          }

                        </div>

                        <Divider/>

                        <div className="form-block label-default">

                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                              <span><Icon type="bulb"/><p>{this.props.langData['Type']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                              {customerData.type === 'LegalPerson' ? this.props.langData['Legal Person'] : this.props.langData['Real Person']}
                            </Col>
                          </Row>

                          {customerData.id_or_tax_identification_number &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
																			<span><Icon type="idcard"/><p>{
                                        this.state.type === 'LegalPerson' ?
                                          this.props.langData['Id/Tax Identification Number'] :
                                          this.props.langData['Identification Number']
                                      }</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.id_or_tax_identification_number}
                              </Col>
                            </Row>
                          }

                          {customerData.tax_administration &&
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                                <span><Icon type="compass"/><p>{this.props.langData['Tax Administration']}</p></span>
                              </Col>
                              <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                                {customerData.tax_administration}
                              </Col>
                            </Row>
                          }

                        </div>

                        <Divider/>

                        {customerData.explanation &&
                          <div style={{ padding: '0px 10px 20px 10px', textAlign: 'center' }}>
                            {customerData.explanation}
                          </div>
                        }

                      </div>

                    }

                  </React.Fragment>

                </Card>

                <TransactionList
                  customerId={this.customer_id}
                  langData={this.props.langData}
                  lang={this.props.lang}
                  customerBalances={customerBalances}
                  totalCustomerBalance={totalCustomerBalance}
                  history={this.props.history}
                />

              </Col>

              <Col xs={24} sm={24} md={10} lg={8} xl={7}>

                {customerData && <Route path={routes.ADD_DEBIT_CREDIT_FOR_CUSTOMER} render={props => {

                  const type = props.match.params.type

                  const newNewCreditDebit = (
                    <NewCreditDebit
                      type={type}
                      customer={{ customer_id: this.customer_id, name: customerData.name }}
                      amount={totalCustomerBalance.toFixed(4)}
                      {...props}
                    />
                  )

                  if (this.props.sizeWidth > 576) {
                    return newNewCreditDebit
                  }

                  return (
                    <Modal
                      title={
                        <span><PayCircleOutlined/> &nbsp;{
                          this.props.langData[type[0].toUpperCase() + type.slice(1)]
                        }</span>
                      }
                      bodyStyle={{ padding: 8 }}
                      visible={true}
                      footer={null}
                      onCancel={() => {
                        props.history.push(generatePath(routes.CUSTOMER_DETAIL, { id: this.customer_id }))
                      }}
                    >
                      {newNewCreditDebit}
                    </Modal>
                  )

                }}/>}

                <Route exact path={routes.CUSTOMER_DETAIL} render={() => {
                  return (
                    <Card
                      bodyStyle={{ padding: '0px' }}
                      title={
                        <Button.Group className="fl-r w-100">
                          <Button className="w-50" type="danger">
                            <Link
                              to={generatePath(routes.ADD_DEBIT_CREDIT_FOR_CUSTOMER, {
                                id: this.customer_id,
                                type: 'credit',
                              })}
                            >
                              &nbsp;{this.props.langData['Credit']}
                            </Link>
                          </Button>
                          <Button className="w-50" type="primary">
                            <Link
                              to={generatePath(routes.ADD_DEBIT_CREDIT_FOR_CUSTOMER, {
                                id: this.customer_id,
                                type: 'debit',
                              })}
                            >
                              &nbsp;{this.props.langData['Debit']}
                            </Link>
                          </Button>
                        </Button.Group>
                      }
                      bordered={false}
                    >
                      <div className="form-block">
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span style={{ fontSize: 15 }}>{this.props.langData['Balance']}</span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18} className="text-right">
                            <span style={{ fontSize: 18, fontWeight: 'bold' }}>
                              {toLocaleString(totalCustomerBalance)}
                            </span>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>
                              &nbsp;{getCurrencyWithId(this.currencyId).symbol}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                }}/>

              </Col>

            </Row>
          )
        }}
      </Query>
    )
  }

}

CustomerDetail.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail)
