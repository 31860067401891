import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Icon, Button, Divider, Select } from 'antd'

import {
  deleteEmpty,
  formErrorFieldsNameLocalize,
  successToast,
} from '../../../helpers/helper'

import { currencyData } from '../../../data/Currency'

import { postPanelApi } from '../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 14 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

function Step3Form(props) {

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize
      }

      deleteEmpty(values)

      setSubmitting(true)

      postPanelApi('/auth/step3-save', values).then(result => {

        if (result) {

          successToast(result.message)

          props.history.push('/')

        } else {

          setSubmitting(false)

        }

      })

    })
  }

  const { getFieldDecorator } = props.form

  return (
    <Form
      hideRequiredMark={true}
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      className="form-label-default"
    >

      <Divider>{props.langData['Settings']}</Divider>

      <div className="form-block">

        <Form.Item
          label={(<span><Icon type="pay-circle-o"/><p>{props.langData['Fixed Exchange Rate']}</p></span>)}
          {...formItemLayout}
        >
          {getFieldDecorator('fixed_exchange_rate', {
            rules: [{ required: true }],
            initialValue: 1,
            validateTrigger: false,
          })(
            <Select size="large">
              {currencyData.map((x) => (
                <Select.Option value={x.currency_id} key={x.currency_id}>
                  {x.name + ' - ' + x.symbol}
                </Select.Option>),
              )}
            </Select>,
          )}
        </Form.Item>

      </div>

      <Divider/>

      <div className="form-block">
        <Form.Item {...formItemLayoutNoLabel}>
          <Button type="primary" size="large" loading={submitting} htmlType="submit"
                  className="default-button">
            {props.langData['Next']}
          </Button>
        </Form.Item>
      </div>

    </Form>
  )

}

Step3Form.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  form: PropTypes.object,
  history: PropTypes.object,
}

function Step3(props) {

  const WrappedStep3Form = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(Step3Form)

  return <WrappedStep3Form {...props} />

}

Step3.propTypes = {
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(Step3)
