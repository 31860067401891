import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TemplateForm from '../WaybillTemplateForm/WaybillTemplateForm'
import { connect } from 'react-redux'
import { compile } from 'path-to-regexp'
import { Form } from 'antd'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add(url) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update Waybill Template', url: url } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

class WaybillTemplateUpdate extends Component {

  template_id = null

  constructor(props) {
    super(props)
    this.template_id = parseInt(this.props.match.params.id)
  }

  componentDidMount() {
    this.props.breadcrumb_add(
      '/' + compile(this.props.langData.route['waybill-template/update/:id'])({ id: this.template_id }),
    )
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextState) === JSON.stringify(this.state) && JSON.stringify(nextProps) === JSON.stringify(this.props))
      return false
    return true
  }

  render() {
    const WrappedTemplateForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(TemplateForm)
    return <WrappedTemplateForm template_id={this.template_id} {...this.props} ></WrappedTemplateForm>
  }

}

WaybillTemplateUpdate.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaybillTemplateUpdate)
