import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'

import IntegrationSettingsForm from '../IntegrationSettings/IntegrationSettingsForm/IntegrationSettingsForm'
import routes from '../../../../routes'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Preferences', url: routes.SETTINGS_INDEX } })
      dispatch({
        type: 'breadcrumb_add',
        payload: { name: 'Integration Web Service Settings', url: routes.INTEGRATION_SETTINGS },
      })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

function IntegrationSettings(props) {

  const { breadcrumb_add, breadcrumb_delete, langData } = props

  useEffect(() => {

    breadcrumb_add()

    return () => {

      breadcrumb_delete()

    }

  }, [breadcrumb_add, breadcrumb_delete])

  const WrappedIntegrationSettings = Form.create({
    validateMessages: langData.formValidationMessages,
  })(IntegrationSettingsForm)
  return <WrappedIntegrationSettings {...props} />

}

IntegrationSettings.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  form: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSettings)
