import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Query } from '@apollo/client/react/components'
import { compile } from 'path-to-regexp'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { gql } from '@apollo/client'
import { Col, Row, Icon, Card, Divider, Table, Button, Steps } from 'antd'

import { graphQLErrorsToToast, toast, toLocaleString } from '../../../helpers/helper'
import { getCurrencyWithCode } from '../../../data/Currency'
import CustomerSearchModal from '../../Customer/CustomerSearchModal/CustomerSearchModal'
import ProductSearchModal from '../../Product/ProductSearchModal/ProductSearchModal'
import apolloClient from '../../../helpers/apolloClient'

const mapStateToProps = state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Incoming E-Invoice Box Detail' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
}

const READ_QUERY = (
  gql`
    query($e_invoice_uuid: String!) {
      incomingEInvoiceFind(e_invoice_uuid: $e_invoice_uuid) {
        user_id
        customer
        currency
        code
        date
        invoice_country
        invoice_city
        invoice_town
        invoice_address
        products
        line_extension_amount
        tax_exclusive_amount
        tax_inclusive_amount
        allowance_total_amount
        charge_total_amount
        payable_rounding_amount
        payable_amount
        e_invoice_uuid
        e_invoice_id
        e_invoice_type
        come_from
        is_saved
        message
      }
    }
  `
)

const CREATE_QUERY = (
  gql`
    mutation($purchase_invoice: InvoiceCreateInput!){
      purchaseInvoiceCreate(purchase_invoice: $purchase_invoice){
        purchase_invoice {
          transaction_id
          customer_id
          currency
          code
          no
          series
          category_id
          total
          discount
          discount_type
          total_vat
          grand_total
          paid
          remaining
          explanation
          date
          expiry_date
          invoice_country
          invoice_city
          invoice_town
          invoice_address
          delivery_country
          delivery_city
          delivery_town
          delivery_address
          as_waybill
          e_invoice_type
          e_invoice_id
          e_invoice_uuid
        }
        informative {
          messages
        }
      }
    }
  `
)

const CUSTOMER_LIST_QUERY = (
  gql`
    query CustomerList($name: String!) {
      customerList(filter: {
        AND: [
          { name: { starts_with: $name } }
        ]
      })
      {
        customers {
          name
          customer_id
        }
      }
    }
  `
)

class IncomingEInvoiceBoxDetail extends Component {
  e_invoice_uuid = null

  constructor(props) {
    super(props)
    this.e_invoice_uuid = props.match.params.id
  }

  state = {
    data: null,
    totalVat: null,
    generalTotalPrice: null,
    customer: null,
    modalCustomerSearch: false,
    showProductSearchAsModal: false,
    productRowIndex: null,
    matchedAllProducts: false,
    importedProducts: false,
  }

  componentDidMount() {
    this.props.breadcrumb_add(compile('/' + this.props.langData.route['invoice/detail/incoming-e-invoice-box/:id'])({
      id: this.e_invoice_uuid,
    }))
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  columns = [
    {
      title: this.props.langData['Product'],
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.props.langData['Quantity'],
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) => `${record.quantity} ${record.unit_name}`,
    },
    {
      title: this.props.langData['Price'],
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (value, record) => `${toLocaleString(record.amount)} ${getCurrencyWithCode(record.selling_currency).symbol}`,
    },
    {
      title: this.props.langData['Vat'],
      dataIndex: 'vat',
      key: 'vat',
      render: value => value + '%',
    },
    {
      title: this.props.langData['Total'],
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => toLocaleString((record.amount * record.quantity) + (record.amount * record.quantity) * (record.vat / 100)) + ' ' + getCurrencyWithCode(record.selling_currency).symbol,
    },
    {
      title: this.props.langData['Action'],
      dataIndex: 'action',
      key: 'operation',
      render: (value, record, index) => {
        if ( ! this.state.data.is_saved) {
          return <div style={{ marginBottom: 20 }}>
            <a style={{ textDecoration: 'underline' }}
               onClick={() => this.setState({ showProductSearchAsModal: true, productRowIndex: index })}><b>Ürün
              Eşleştir</b></a>
            <h5>{record.product_id ? `(${this.props.langData['Matched']})` : ''}</h5>
          </div>
        }
        return <b>{this.props.langData['Imported']}</b>
      },

    },
  ]

  onSubmit = async () => {
    const data = this.state.data
    const productData = data.products.map(product => {

      return {
        product_id: product.product_id,
        quantity: product.quantity,
        amount: product.amount,
        vat: product.vat,
      }
    })

    let requestData = {}

    requestData.customer_id = this.state.customer.customer_id
    requestData.currency = data.currency
    requestData.date = moment(data.date)
    requestData.e_invoice_uuid = data.e_invoice_uuid
    requestData.e_invoice_id = data.e_invoice_id
    requestData.e_invoice_type = 'EInvoice'
    requestData.products = productData
    requestData.code = data.code

    try {
      const result = await apolloClient.mutate({ mutation: CREATE_QUERY, variables: { purchase_invoice: requestData } })
      this.setState({ importedProducts: true })

      this.props.history.push('/invoice/incoming-e-invoice-box')
      toast(true, result.data.purchaseInvoiceCreate.informative.messages)
    } catch (error) {
      graphQLErrorsToToast(error)
    }
  }

  customerSearchModalCallback = record => {
    this.setState({
      customer: {
        customer_id: record.customer_id,
        name: record.name,
      },
      modalCustomerSearch: null,
    })
  }

  productSearchModalCallback = record => {

    let data = cloneDeep(this.state.data)

    data.products.forEach((product, index) => {
      if (index === this.state.productRowIndex) {
        product.product_id = record.product_id
      }
    })

    this.setState({
      data: data,
      showProductSearchAsModal: false,
    })

    let filteredResult = data.products.filter(product => product.product_id)
    if (filteredResult.length === data.products.length) {
      this.setState({ matchedAllProducts: true })
    }
  }

  setCurrentStep = () => {

    if (this.state.data && this.state.data.is_saved) {
      return 3
    }

    if (this.state.customer) {
      if (this.state.matchedAllProducts) {
        if (this.state.importedProducts) {
          return 3
        }
        return 2
      }
      return 1
    }

    return 0

  }

  viewIncomingEInvoice = () => {
    window.open(
      `${process.env.REACT_APP_NEW_API_URL}/invoice/incomingeinvoice/print/${localStorage.getItem('token')}/${this.state.data.e_invoice_id}/${this.props.lang}`,
      '_blank',
    )
  }

  checkAvailableCustomer = async name => {
    try {
      const result = await apolloClient.query({ query: CUSTOMER_LIST_QUERY, variables: { name } })
      const data = result.data.customerList.customers
      if (data && data.length === 1) {
        this.setState({
          customer: {
            name: data[0].name,
            customer_id: data[0].customer_id,
          },
        })
      }
    } catch (error) {
      return null
    }
  }

  render() {
    return (
      <Query
        query={READ_QUERY}
        variables={{ e_invoice_uuid: this.e_invoice_uuid }}
        fetchPolicy="network-only"
      >
        {({ loading: loadingQuery, error, data }) => {
          if (error) {
            graphQLErrorsToToast(error)
            return null
          }
          if ( ! loadingQuery) {
            if ( ! data.incomingEInvoiceFind) {
              toast(false, this.props.langData['x not found']('Incoming E-Invoice'))
              if (this.props.history.action === 'PUSH')
                this.props.history.goBack()
              else
                this.props.history.push('/')
            } else if ( ! this.state.data) {

              const incomingEInvoiceData = data.incomingEInvoiceFind

              let totalVat = 0
              let generalTotalPrice = 0

              incomingEInvoiceData.products.forEach(product => {
                totalVat += product.amount * product.vat / 100
                generalTotalPrice += (product.amount * product.quantity) + (product.amount * product.quantity) * (product.vat / 100)
              })

              this.setState({
                data: data.incomingEInvoiceFind,
                generalTotalPrice: generalTotalPrice - incomingEInvoiceData.allowance_total_amount,
                totalVat,
              })
              // First 6 chars of e-invoice customer
              const eInvoiceCustomer = incomingEInvoiceData.customer.name.substring(0, 6)
              this.checkAvailableCustomer(eInvoiceCustomer)
            }
          }

          return (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: '0px' }}
                  headStyle={{ height: 65 }}
                  title={<span><Icon
                    type="idcard"/> &nbsp;{this.props.langData['Incoming E-Invoice Box Detail']}</span>}
                  bordered={false}
                  loading={ ! this.state.data}
                  extra={true}
                >
                  {this.state.data &&
                    <React.Fragment>
                      <div className="form-block label-default">
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="user"/><p>{this.props.langData['Customer']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.customer.name}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="home"/><p>{this.props.langData['Invoice Address']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.invoice_address}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="bank"/><p>{this.props.langData['Invoice City']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.invoice_city}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="api"/><p>{this.props.langData['Invoice Country']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.invoice_country}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="alert"/><p>{this.props.langData['Invoice Town']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {this.state.data.invoice_town}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="clock-circle"/><p>{this.props.langData['Date']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {moment(this.state.data.date).format('DD.MM.YYYY HH:mm')}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span><Icon type="save"/><p>{this.props.langData['Saving Status']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            {<Icon type={this.state.data.is_saved ? 'check-circle' : 'close-circle'} theme="twoTone"
                                   twoToneColor="#52c41a"
                                   style={{ fontSize: 25 }}/>}
                          </Col>
                        </Row>
                      </div>
                    </React.Fragment>
                  }
                </Card>

                <Card
                  bodyStyle={{ padding: 0, marginTop: 20, marginBottom: 16 }}
                  bordered={false}
                >
                  <Divider>
                    <Icon type="star-o"/> {this.props.langData['Products']}
                  </Divider>

                  <Table
                    columns={this.columns}
                    dataSource={this.state.data && this.state.data.products}
                    loading={ ! this.state.data}
                    pagination={false}
                    style={{ borderRadius: '0px' }}
                    rowClassName="white"
                    size="small"
                    scroll={{ x: 700, y: 500 }}
                    bordered
                  />

                  {this.state.data &&
                    <div className="form" style={{ textAlign: 'right', width: '100%', height: 'auto', marginTop: 20 }}>

                      <div className="fl-r" style={{ marginRight: 10 }}>
                        <p>{toLocaleString(this.state.data.line_extension_amount) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        <p>{toLocaleString(this.state.totalVat) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        {this.state.data.allowance_total_amount !== 0 &&
                          <p>{toLocaleString(this.state.data.allowance_total_amount) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</p>
                        }
                        <p>
                          <strong>{toLocaleString(this.state.generalTotalPrice) + ' ' + getCurrencyWithCode(this.state.data.currency).symbol}</strong>
                        </p>
                      </div>

                      <div className="fl-r" style={{ marginRight: 26 }}>
                        <p>{this.props.langData['Interim Total']}</p>
                        <p>{this.props.langData['Total VAT']}</p>
                        {this.state.data.allowance_total_amount !== 0 && <p>{this.props.langData['Total Discount']}</p>}
                        <p><strong>{this.props.langData['Grand Total']}</strong></p>
                      </div>

                    </div>}
                </Card>

              </Col>

              {this.state.data && <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  headStyle={{ height: this.state.data.is_saved ? 65 : 'none', textAlign: 'center' }}
                  bordered={false}
                  title={
                    <Button className="w-100" type="danger" onClick={this.viewIncomingEInvoice}>
                      <Icon type="click"/>&nbsp;
                      {this.props.langData['View Invoice']}
                    </Button>
                  }
                >
                  <div className="form-block">
                    <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <span style={{ fontSize: 15 }}>ID</span>
                      </Col>
                      <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
                                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                                       {this.state.data.e_invoice_id}
                                    </span>
                      </Col>
                    </Row>
                    <Row gutter={24} style={{ marginBottom: 16 }}>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <span style={{ fontSize: 15 }}>UUID</span>
                      </Col>
                      <Col xs={24} sm={24} md={20} lg={20} xl={20} className="text-right">
                                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                                       {this.state.data.e_invoice_uuid}
                                    </span>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>}

              {this.state.data && <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginBottom: 16 }}>
                <Card
                  bodyStyle={{ padding: 0 }}
                  headStyle={{ height: this.state.data.is_saved ? 65 : 'none', textAlign: 'center' }}
                  bordered={false}
                  title={
                    ! this.state.data.is_saved ? <React.Fragment>
                        <Button disabled={ ! this.state.customer || ! this.state.matchedAllProducts} className="w-100"
                                type="danger" style={{ height: 45 }}
                                onClick={() => this.onSubmit()}>
                          <Icon type="click"/>&nbsp;
                          {this.props.langData['Import']}
                        </Button>
                        <h5 style={{
                          marginTop: 10,
                          color: 'white',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>{this.props.langData['Match customer and product']}</h5>
                        <Button type="primary" className="w-100"
                                onClick={() => this.setState({ modalCustomerSearch: true })}>
                          {this.props.langData['Match Customer']}
                        </Button>
                        {this.state.customer && <h5 style={{
                          textAlign: 'center',
                          marginTop: 10,
                          color: 'white',
                        }}>{this.props.langData['Selected Customer']}: {this.state.customer.name}</h5>}
                      </React.Fragment>
                      :
                      <span style={{ textAlign: 'center' }}><Icon type="check-circle" style={{ fontSize: 22 }}/>&nbsp;
                        <span>{this.props.langData['Imported']}</span></span>
                  }
                />
              </Col>}

              <Col xs={24} sm={24} md={10} lg={8} xl={7} style={{ marginBottom: 16 }}>
                <Card
                  title={
                    <span>
                                 <Icon type="sort-descending"/>&nbsp;
                      {this.props.langData['Transfer Steps']}
                              </span>
                  }
                  bodyStyle={{ padding: 10, marginTop: 20 }}
                  bordered={false}
                >
                  <Steps direction="vertical" current={this.setCurrentStep()} style={{ paddingLeft: 13 }}>
                    <Steps.Step title={this.props.langData['Match Customer']}
                                description={this.setCurrentStep() >= 1 ? this.props.langData['Customer match process completed'] : this.props.langData['Please match customer']}/>
                    <Steps.Step title={this.props.langData['Match Product']}
                                description={this.setCurrentStep() >= 2 ? this.props.langData['Product match process completed'] : this.props.langData['Please match product']}/>
                    <Steps.Step title={this.props.langData['Import']}
                                description={this.setCurrentStep() >= 3 ? this.props.langData['Import process completed'] : this.props.langData['Please complete the import process']}/>
                  </Steps>
                </Card>
              </Col>

              {this.state.modalCustomerSearch && (
                <CustomerSearchModal
                  callback={this.customerSearchModalCallback}
                  incomingEInvoiceData={this.state.data.customer}
                  modalClose={() => this.setState({ modalCustomerSearch: false })}
                />
              )}

              {this.state.showProductSearchAsModal && (
                <ProductSearchModal
                  langData={this.props.langData}
                  callback={this.productSearchModalCallback}
                  modalClose={() => this.setState({
                    showProductSearchAsModal: false,
                  })}
                />
              )}
            </Row>
          )
        }}
      </Query>
    )
  }
}

IncomingEInvoiceBoxDetail.propTypes = {
  id: PropTypes.string,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
  history: PropTypes.object,
  lang: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomingEInvoiceBoxDetail)
