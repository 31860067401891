import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Form, Input, Button, Col, Card, Divider, Select, Switch} from 'antd'
import {
  BulbOutlined,
  CaretDownOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'

import {
  selectFilterLowerCase,
  updateEmptyAndUndefined,
  formErrorFieldsNameLocalize,
  successToast,
} from '../../../helpers/helper'
import {postPanelApi} from '../../../api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
  },
}

class CustomerDefaultsForm extends Component {

  state = {
    loading: false,
    submitting: false,
    categories: [],
    sequences: [],
  }

  componentDidMount() {

    this.setState({
      loading: true,
    }, () => {

      postPanelApi('/customer/customer-defaults/edit-open').then(result => {

        if (result) {

          this.setState({
            categories: result.categories,
            sequences: result.sequences,
            loading: false,
          })

          this.props.form.setFieldsValue(result.form)

        }

      })

    })

  }

  onSubmit = () => {

    this.props.form.validateFields(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      updateEmptyAndUndefined(values)

      this.setState({
        submitting: true,
      }, () => {

        postPanelApi('/customer/customer-defaults/edit-submit', values).then(result => {

          if (result) {

            successToast(result.message)

          }

        }).finally(() => {

          this.setState({
            submitting: false,
          })

        })

      })

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <Card loading={this.state.loading} bodyStyle={{ padding: '0px' }}
            title={
              <span><IdcardOutlined/>&nbsp;{this.props.langData['Customer Defaults']}</span>
            }
            bordered={false}
      >
        <Form
          hideRequiredMark={true}
          onSubmit={e => {
            e.preventDefault()
            this.onSubmit()
          }}
          className="form-label-default">

          <div className="form-block">
            <Form.Item hasFeedback
                       label={(<span><BulbOutlined/><p>{this.props.langData['Category']}</p></span>)}
                       {...formItemLayout}
            >
              {getFieldDecorator('category_id')(
                <Select
                  size="large"
                  showSearch
                  allowClear
                  filterOption={selectFilterLowerCase}
                  suffixIcon={<CaretDownOutlined/>}
                >
                  {this.state.categories.map(x => (
                    <Select.Option value={x.category_id} key={x.category_id}>{x.name}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>

            <Form.Item hasFeedback label={(
              <span><PaperClipOutlined/><p>{this.props.langData['Code']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('sequence_id')(
                <Select
                  size="large"
                  showSearch
                  allowClear
                  filterOption={selectFilterLowerCase}
                  suffixIcon={<CaretDownOutlined/>}
                >
                  {this.state.sequences.map(x => {
                    return <Select.Option value={x.sequence_id}
                                          key={x.sequence_id}>{x.name}</Select.Option>
                  })}
                </Select>,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">

            <Form.Item hasFeedback
                       label={(<span><EnvironmentOutlined/><p>{this.props.langData['Country']}</p></span>)}
                       {...formItemLayout}
            >
              {getFieldDecorator('country', { rules: [{ max: 30 }], validateTrigger: true })(
                <Select
                  size="large"
                  showSearch
                  allowClear
                  filterOption={selectFilterLowerCase}
                  suffixIcon={<CaretDownOutlined/>}
                  onChange={val => this.setState({ country: val })}
                >
                  {this.props.langData.countries.map((x, k) => {
                    return <Select.Option value={x} key={k}>{x}</Select.Option>
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item label={(
              <span><EnvironmentOutlined/><p>{this.props.langData['City'] + ' / ' + this.props.langData['Town']}</p></span>
            )}
                       {...formItemLayout}
            >
              <Input.Group size="large">
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('city', { rules: [{ max: 32 }], validateTrigger: false })(
                      (this.props.lang === 'tr' && this.props.form.getFieldValue('country') === 'Türkiye') ? (
                        <Select
                          size="large"
                          className="w-100"
                          showSearch
                          allowClear
                          suffixIcon={<CaretDownOutlined/>}
                          filterOption={selectFilterLowerCase}
                          onChange={() => {
                            this.props.form.setFieldsValue({
                              town: '',
                            })
                          }}
                        >
                          {this.props.langData.cityTown.map((x, k) => {
                            return <Select.Option value={x.city}
                                                  key={k}>{x.city}</Select.Option>
                          })}
                        </Select>
                      ) : <Input/>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item hasFeedback>
                    {getFieldDecorator('town', { rules: [{ max: 32 }], validateTrigger: false })(
                      (this.props.lang === 'tr' && this.props.form.getFieldValue('country') === 'Türkiye') ? (
                        <Select
                          size="large"
                          className="w-100"
                          showSearch
                          allowClear
                          suffixIcon={<CaretDownOutlined/>}
                          filterOption={selectFilterLowerCase}
                        >
                          {
                            this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('city')).length && (
                              this.props.langData.cityTown.filter(x => x.city === this.props.form.getFieldValue('city'))[0].towns.map((x, k) => {
                                return <Select.Option value={x} key={k}>{x}</Select.Option>
                              })
                            )
                          }
                        </Select>
                      ) : <Input/>,
                    )}
                  </Form.Item>
                </Col>
              </Input.Group>

            </Form.Item>

            <Form.Item
              hasFeedback
              label={(<span><EnvironmentOutlined/><p>{this.props.langData['Address']}</p></span>)}
              {...formItemLayout}
            >
              {getFieldDecorator('address', { rules: [{ max: 250 }], validateTrigger: false })(
                <Input.TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}/>,
              )}
            </Form.Item>

            <Form.Item
              hasFeedback
              label={(<span><EnvironmentOutlined/><p>{this.props.langData['Postal Code']}</p></span>)}
              {...formItemLayout}
            >
              {getFieldDecorator('postal_code')(
                <Input
                  size="large"
                  maxLength={10}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">

            <Form.Item label={(<span><BulbOutlined/><p>{this.props.langData['Type']}</p></span>)}
                       {...formItemLayout}
            >
              {getFieldDecorator('is_individual', {
                valuePropName: 'checked',
              })(
                <Switch
                  checkedChildren={this.props.langData['Real Person']}
                  unCheckedChildren={this.props.langData['Legal Person']}
                />,
              )}
            </Form.Item>

          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item hasFeedback {...formItemLayoutNoLabel}>
              {getFieldDecorator('explanation', { rules: [{ max: 250 }], validateTrigger: false })(
                <Input.TextArea
                  size="large" autoSize={{ minRows: 2, maxRows: 5 }}
                  placeholder={this.props.langData['Explanation']}
                />,
              )}
            </Form.Item>
          </div>

          <Divider/>

          <div className="form-block">
            <Form.Item {...formItemLayoutNoLabel}>
              <Button type="primary" size="large" loading={this.state.submitting} htmlType="submit"
                      className="default-button">
                {this.props.langData['Save']}
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Card>
    )
  }

}

CustomerDefaultsForm.propTypes = {
  langData: PropTypes.object,
  history: PropTypes.object,
  form: PropTypes.object,
  lang: PropTypes.string,
}

export default CustomerDefaultsForm
