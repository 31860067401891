import React, { Component } from 'react'
import { Modal, Button, Form, Icon, InputNumber } from 'antd'
import PropTypes from 'prop-types'
import { toast } from '../helpers/helper'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 },
    lg: { span: 11 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 13 },
    lg: { span: 13 }
  }
}

class ExcelExtractModal extends Component {
  state = {
    starting: 0,
    total: 0
  }

  onSubmit() {
    if ( ! this.state.starting || ! this.state.total) {
      toast(false, this.props.langData['Please Fill All Inputs'])
      return
    }
    this.props.onOk({ starting: this.state.starting, total: this.state.total, type: this.props.type })
  }

  render() {
    return (
      <Modal
        title={<span><Icon type="file-excel"/> &nbsp;{this.props.langData['Xlsx Export']}</span>}
        visible={true}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        footer={null}
      >
        <Form
          hideRequiredMark={true}
          onSubmit={e => {
            e.preventDefault()
            this.onSubmit()
          }}
          className="form-label-default"
        >
          <Form.Item
            label={<span><Icon type="number"/>&nbsp;{this.props.langData['Number of record to be start']}</span>}
            {...formItemLayout}>
            <InputNumber style={{ width: '100%' }} onChange={(e) => this.setState({ starting: e })}/>
          </Form.Item>
          <Form.Item
            label={<span><Icon type="number"/>&nbsp;{this.props.langData['Total Record Count']}</span>}
            help={<span><Icon type="warning"/>&nbsp;{this.props.langData['You can record up to 1000 records']}</span>}
            {...formItemLayout}>
            <InputNumber style={{ width: '100%' }} min={1} max={1000} onChange={(e) => this.setState({ total: e })}/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" className="default-button">
              {this.props.langData['Send']}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

ExcelExtractModal.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  langData: PropTypes.object,
  type: PropTypes.bool
}
export default ExcelExtractModal
