import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreditDebitForm from './../../Customer/CreditDebitForm/CreditDebitForm'
import { connect } from 'react-redux'

import { Form } from 'antd'

class NewDebitTransaction extends Component {

  type
  id

  constructor(props) {
    super(props)
    this.id = this.props.match.params.id
    this.type = this.props.match.params.type
  }

  render() {

    const WrappedCreditDebitForm = Form.create({
      validateMessages: this.props.langData.formValidationMessages,
    })(CreditDebitForm)

    return <WrappedCreditDebitForm type={this.type} priority_invoice={this.id} id={this.id} {...this.props}/>
  }

}

NewDebitTransaction.propTypes = {
  langData: PropTypes.object,
  type: PropTypes.string, // fatura tipi
  id: PropTypes.number,
  callback: PropTypes.func,
  match: PropTypes.object,
  priority_invoice: PropTypes.string,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
})(NewDebitTransaction)
