import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Icon, Input, Button, Col, Card, Divider, Switch } from 'antd'

import { deleteEmpty, formErrorFieldsNameLocalize, successToast } from '../../../helpers/helper'

import { postPanelApi } from '../../../api'
import routes from '../../../routes'
import { BulbOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 12 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
  },
}

function UserInformationForm(props) {

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {

    postPanelApi('/settings/company-settings-open').then(result => {

      if (result) {

        props.form.setFieldsValue(result.form)

      }

    })

  }, [])

  const onSubmit = () => {

    props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      deleteEmpty(values)

      setSubmitting(true)

      postPanelApi('/settings/company-settings-update', values).then(result => {

        if (result) {

          successToast(result.message)

        }

        setSubmitting(false)

      })

    })
  }

  const { getFieldDecorator } = props.form

  return (
    <Card
      bodyStyle={{ padding: '0px' }}
      title={<span><UserOutlined/> &nbsp;{props.langData['Company Information']}</span>}
      bordered={false}
    >
      <Form
        hideRequiredMark={true}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
        className="form-label-default"
      >

        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="home"/><p>{props.langData['Company Name']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('company_name', { rules: [{ max: 250 }, { required: true }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="tag"/><p>{props.langData['Title']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('title', { rules: [{ max: 250 }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="user"/><p>{props.langData['First Name']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('first_name', {
              rules: [{ max: 250 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="user"/><p>{props.langData['Last Name']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('last_name', {
              rules: [{ max: 250 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="phone"/><p>{props.langData['Phone'] + ' 1'}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('phone_1', { rules: [{ max: 20 }], validateTrigger: false })(
              <Input type="number" size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="phone"/><p>{props.langData['Phone'] + ' 2'}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('phone_2', { rules: [{ max: 20 }], validateTrigger: false })(
              <Input type="number" size="large"/>,
            )}
          </Form.Item>

          <Form.Item label={(
            <span>
							<Icon type="environment-o"/>
							<p>{props.langData['Country'] + ' / ' + props.langData['City'] + ' / ' + props.langData['Town'] + ' / ' + props.langData['Postal Code']}</p>
						</span>
          )}
                     {...formItemLayout}
          >
            <Input.Group size="large">
              <Col span={6}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('country', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Input/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('city', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Input/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('town', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Input/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('postal_code', { rules: [{ max: 100 }], validateTrigger: false })(
                    <Input size="large" style={{ width: '100%' }} maxLength={10}/>,
                  )}
                </Form.Item>
              </Col>
            </Input.Group>

          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="environment-o"/><p>{props.langData['Address']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('address', { rules: [{ max: 500 }], validateTrigger: false })(
              <Input.TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }}/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('e_mail', { rules: [{ type: 'email', max: 100 }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="link"/><p>{props.langData['Website']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('web_site', { rules: [{ min: 1, max: 70 }], validateTrigger: false })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(<span><BulbOutlined/><p>{props.langData['Type']}</p></span>)}
                     {...formItemLayout}
          >
            {getFieldDecorator('is_individual', {
              valuePropName: 'checked',
              initialValue: false,
            })(
              <Switch
                checkedChildren={props.langData['Real Person']}
                unCheckedChildren={props.langData['Legal Person']}
              />,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="idcard"/><p>{props.langData['Id/Tax Identification Number']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('tax_identification_number', {
              rules: [{ min: 10, max: 11 }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          { ! props.form.getFieldValue('is_individual') && (
            <Form.Item hasFeedback label={(
              <span><Icon type="compass"/><p>{props.langData['Tax Administration']}</p></span>
            )}
                       {...formItemLayout}
            >
              {getFieldDecorator('tax_administration', { rules: [{ max: 250 }], validateTrigger: false })(
                <Input size="large"/>,
              )}
            </Form.Item>
          )}

          <Form.Item hasFeedback label={(
            <span><Icon
              type="money-collect"/><p>{props.langData['Bank Name'] + '/' + props.langData['IBAN No']}</p></span>
          )}
                     {...formItemLayout}
          >
            <Input.Group size="large">
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('bank_name', { validateTrigger: false })(
                    <Input placeholder={props.langData['Bank Name']} maxLength={100} size="large"/>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('iban_no', { validateTrigger: false })(
                    <Input placeholder={props.langData['IBAN No']} maxLength={100} size="large"/>,
                  )}
                </Form.Item>
              </Col>
            </Input.Group>

          </Form.Item>

        </div>

        <Divider>{props.langData['Commissioner Information']}</Divider>

        <div className="form-block">

          <Form.Item hasFeedback label={(
            <span><Icon type="user"/><p>{props.langData['Name Surname']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('commissioner_name_surname', {
              rules: [{ max: 250 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="mail"/><p>{props.langData['E-Mail']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('commissioner_e_mail', {
              rules: [{ type: 'email' }, { max: 100 }, { required: true }],
              validateTrigger: false,
            })(
              <Input size="large"/>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={(
            <span><Icon type="phone"/><p>{props.langData['Phone']}</p></span>
          )}
                     {...formItemLayout}
          >
            {getFieldDecorator('commissioner_phone', {
              rules: [{ max: 20 }, { required: true }],
              validateTrigger: false,
            })(
              <Input type="number" size="large"/>,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" disabled={submitting} loading={submitting} htmlType="submit"
                    className="default-button">
              <SaveOutlined/> {props.langData['Save']}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  )

}

UserInformationForm.propTypes = {
  form: PropTypes.object,
  langData: PropTypes.object,
}

function UserInformation(props) {

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedInformationForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(UserInformationForm)

  return <WrappedInformationForm langData={props.langData}/>

}

UserInformation.propTypes = {
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  langData: PropTypes.object,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Company' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Information', url: routes.COMPANY_EDIT_SETTINGS } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(UserInformation)
