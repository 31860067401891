import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ProductList from './../ProductList/ProductList'
import { Icon, Button, Modal, Drawer } from 'antd'
import NewProduct from '../NewProduct/NewProduct'

function ProductSearchModal(props) {

  const [refetch, setRefetch] = useState(false)
  const [showNewProductAsModal, setShowNewProductAsModal] = useState(false)

  return (
    <React.Fragment>
      <Modal
        title={
          <React.Fragment>
            <span><Icon type="idcard"/> &nbsp;{props.langData['Product Search']}</span>
            <Button
              key="new"
              type="primary"
              style={{ float: 'right' }}
              onClick={() => setShowNewProductAsModal(true)}
            >
              {props.langData['Add New One']}
            </Button>
          </React.Fragment>
        }
        bodyStyle={{ padding: 8 }}
        visible={true}
        closable={false}
        width="75%"
        onCancel={props.modalClose}
        footer={[
          <Button key="close" size="large" onClick={props.modalClose} style={{ marginRight: 8 }}
                  className="danger2">
            {props.langData['Close']}
          </Button>,
        ]}
      >
        <ProductList
          noBreadCrumb={true}
          scrollHeight={200}
          rowItemClick={props.callback}
          modalClose={props.modalClose}
          refetch={refetch}
          showHeaderButtons={false}
        />
      </Modal>
      {showNewProductAsModal && (
        <Drawer
          placement="right"
          closable={true}
          visible={true}
          width={'40%'}
        >
          <NewProduct showingInModal={true} onModalClose={(() => {
            setShowNewProductAsModal(false)
            setRefetch(true)
          })}/>
        </Drawer>
      )}
    </React.Fragment>
  )
}

ProductSearchModal.propTypes = {
  langData: PropTypes.object,

  callback: PropTypes.func,
  modalClose: PropTypes.func,
}

export default ProductSearchModal
