const base64toBlob = (base64Data) => {
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: 'application/pdf' })
}

export const openBase64NewTab = (base64Pdf) => {
  const blob = base64toBlob(base64Pdf)
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, 'invoice.pdf')
  } else {
    window.open(URL.createObjectURL(blob))
  }
}

export const hasLicence = (licences, type) => {

  if ( ! licences.hasOwnProperty(type)) {
    return false
  }

  return licences[type] === true

}
