import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Menu,
  Icon,
  Breadcrumb,
  Row,
  Col,
  Dropdown,
  Switch,
  Popover,
  Avatar,
  Tag,
  Button,
  Alert,
  Divider, Spin,
} from 'antd'
import './Main.css'
import { BrowserRouter as Router, Route, Link, Switch as RouterSwitch, generatePath } from 'react-router-dom'
import { connect } from 'react-redux'
import routePaths from './../../localize/routePaths'
import { toast, confirm } from '../../helpers/helper'
import { compile } from 'path-to-regexp'
import moment from 'moment'
import { gql } from '@apollo/client'

import apolloClient from './../../helpers/apolloClient'

// components
import Starting from './../Starting/Starting'
import CustomerList from './../Customer/CustomerList/CustomerList'
import NewCustomer from './../Customer/NewCustomer/NewCustomer'
import CustomerUpdate from './../Customer/CustomerUpdate/CustomerUpdate'
import CustomerDetail from './../Customer/CustomerDetail/CustomerDetail'
import CustomerDefaults from './../Customer/CustomerDefaults/CustomerDefaults'
import SafeList from './../Safe/SafeList/SafeList'
import NewSafe from './../Safe/NewSafe/NewSafe'
import SafeUpdate from './../Safe/SafeUpdate/SafeUpdate'
import SafeDetail from './../Safe/SafeDetail/SafeDetail'
import SafeReport from './../Safe/SafeReport/SafeReport'
import IncomeExpenseReport from './../Safe/SafeReport/IncomeExpenseReport'
import VatReport from './../Transaction/VatReport/VatReport'
import ProductList from './../Product/ProductList/ProductList'
import NewProduct from './../Product/NewProduct/NewProduct'
import ProductUpdate from './../Product/ProductUpdate/ProductUpdate'
import Category from './../Category/Category/Category'
import UserInformation from './../User/UserInformation/UserInformation'
import UserSettings from './../User/UserSettings/UserSettings/UserSettings'
import ManagerList from './../Manager/ManagerList/ManagerList'
import NewManager from './../Manager/NewManager/NewManager'
import ManagerUpdate from './../Manager/ManagerUpdate/ManagerUpdate'
import ManagerInfo from '../Manager/ManagerInfo/ManagerInfo'
import InvoiceList from './../Invoice/InvoiceList/InvoiceList'
import NewInvoice from './../Invoice/NewInvoice/NewInvoice'
import InvoiceUpdate from './../Invoice/InvoiceUpdate/InvoiceUpdate'
import InvoiceDetail from './../Invoice/InvoiceDetail/InvoiceDetail/InvoiceDetail'
import InvoiceCopy from '../Invoice/InvoiceDetail/InvoiceDetail/NewInvoiceCopy/NewInvoiceCopy'
import InvoiceIncomeExpenseReport from '../Invoice/InvoiceReport/InvoiceImcomeExpenseReport'
import TransactionDetail from './../Transaction/TransactionDetail/TransactionDetail'
import TransactionList from './../Transaction/TransactionList/TransactionList'
import TransactionReport from './../Transaction/TransactionReport/TransactionReport'
import TransactionRecoveries from './../Transaction/TransactionRecoveries/TransactionRecoveries'
import ProductDetail from './../Product/ProductDetail/ProductDetail'
import Sequence from './../Sequence/Sequence/Sequence'
import ProductDefaults from './../Product/ProductDefaults/ProductDefaults'
import InvoiceDefaults from './../Invoice/InvoiceDefaults/InvoiceDefaults'
import WaybillList from './../Invoice/WaybillList/WaybillList'
import TemplateList from './../Template/TemplateList/TemplateList'
import NewTemplate from './../Template/NewTemplate/NewTemplate'
import TemplateUpdate from './../Template/TemplateUpdate/TemplateUpdate'
import WaybillTemplateList from './../WaybillTemplate/WaybillTemplateList/WaybillTemplateList'
import NewWaybillTemplate from './../WaybillTemplate/NewWaybillTemplate/NewWaybillTemplate'
import WaybillTemplateUpdate from './../WaybillTemplate/WaybillTemplateUpdate/WaybillTemplateUpdate'
import AdditionalCharge from './../AdditionalCharge/AdditionalCharge/AdditionalCharge'
import WebhookList from '../Webhook/WebhookList'
import NewWebhook from '../Webhook/NewWebhook'
import WebhookUpdate from '../Webhook/WebhookUpdate'
import WebhookDetail from '../Webhook/WebhookDetail'
import SubProductAttributes from './../Product/SubProductAttributes/SubProductAttributes'
import GeneralSettings from '../User/UserSettings/GeneralSettings/GeneralSettings'
import IntegrationSettings from '../User/UserSettings/IntegrationSettings/IntegrationSettings'
import TSoftWebServiceSettings from './../User/UserSettings/TSoftWebServiceSettings/TSoftWebServiceSettings'
import InvoiceSettings from './../User/UserSettings/InvoiceSettings/InvoiceSettings'
import ProductSettings from './../User/UserSettings/ProductSettings/ProductSettings'
import EWaybillSettings from './../User/UserSettings/EWaybillSettings/EWaybillSettings'
import InvoiceReturn from './../Invoice/InvoiceReturn/InvoiceReturn/InvoiceReturn'
import NewWaybill from './../Invoice/NewWaybill/NewWaybill'
import WaybillUpdate from './../Invoice/WaybillUpdate/WaybillUpdate'
import WaybillDetail from './../Invoice/WaybillDetail/WaybillDetail'
import NewWaybillInvoice from './../Invoice/NewWaybillInvoice/NewWaybillInvoice'
import NewCustomerInvoice from './../Invoice/NewCustomerInvoice/NewCustomerInvoice'
import NewProductCopy from './../Product/ProductDetail/NewProductCopy/NewProductCopy'
import IncomingEInvoiceBox from './../Invoice/IncomingEInvoiceBox/IncomingEInvoiceBox'
import IncomingEInvoiceBoxDetail from '../Invoice/IncomingEInvoiceBoxDetail/IncomingEInvoiceBoxDetail'
import CustomerBalanceReport from './../Customer/CustomerBalanceReport/CustomerBalanceReport'

import logo from './../../logo.svg'
import logo_mini from './../../logo-mini.svg'
import logo_dark from './../../logo-dark.svg'

import SupportPage from '../../pages/SupportPage'
import routes from '../../routes'
import SyncInvoice from '../Integration/T-Soft/SyncInvoice'
import { ApiOutlined } from '@ant-design/icons'
import { postPanelApi } from '../../api'
import { hasLicence } from '../../utils'
import { licenceMethods } from '../../enums'
import MyPackagesPage from '../../pages/MyPackagesPage'
import BuyNewPackagePage from '../../pages/BuyNewPackagePage'
import PurchaseReturnInvoiceDetail from '../Invoice/PurchaseReturnInvoice/PurchaseReturnInvoiceDetail'

const VERIFICATION_RESUBMIT = (
  gql`
    query {
      managerReSubmitEmail {
        email_resubmit
        informative
      }
    }
  `
)

class Main extends Component {

  rootSubMenuKeys = [
    'customer',
    'product',
    'invoice',
    'safe',
    'transaction',
    'settings',
    'webhook',
    'demo',
  ]

  state = {
    collapsed: false,
    popoverMenuVisible: false,
    openKeys: [],
    selectedItem: null,
  }

  sessionCheckInterval = undefined

  componentDidMount() {

    postPanelApi('/get-token-info').then(result => {

      if (result) {

        if (result.company.step !== 'Completed') {
          this.props.history.replace(generatePath(routes.REGISTER_STEP, { step: result.company.step.replace('Step', '') }))
        }

        const time = moment(result.token.expiry_date).diff(moment(), 'seconds')

        // oturumun sonlanmasına kalan zaman 10 saniyeden daha düşükse çıkış yaptıralım, yoksa bekleme moduna geçelim
        if (time < 10) {

          this.props.clear_token_info()

          toast(false, this.props.langData['Your session is over'] + '.' + this.props.langData['Redirecting'] + '...')

          setTimeout(() => this.props.history.replace(routes.LOGIN), 3000)

          // devam etmeye gerek yok
          return

        } else {

          this.sessionCheckInterval = setInterval(() => {

            this.props.clear_token_info()

            toast(false, this.props.langData['Your session is over'] + '.' + this.props.langData['Redirecting'] + '...')

            setTimeout(() => this.props.history.replace(routes.LOGIN), 3000)

          }, time * 1000)

        }

        this.props.set_token_info({
          token: result.token.token,

          manager_grade: result.manager.grade,
          email_verification: result.manager.email_verification,

          company_id: result.company.company_id,

          licences: result.company.licences,
        })

        if (localStorage.theme === 'dark' && ! this.props.dark_theme) {
          this.props.switch_theme()
        }

        if (this.props.sizeWidth < 768) {
          if (this.props.sizeWidth === 0) {
            return
          }
          this.setState({ collapsed: true })
        }

      } else {

        this.props.history.replace(routes.LOGIN, {
          from: this.props.location,
        })

      }

    })

  }

  componentWillUnmount() {
    clearInterval(this.sessionCheckInterval)
  }

  changeLang(lang) {
    this.props.languageChange(lang)
  }

  toggleSideCollapsed = () => {
    this.setState({
      collapsed: ! this.state.collapsed,
    })
  }

  themeOnChange = theme => {
    localStorage.setItem('theme', theme ? 'dark' : 'light')
    this.props.switch_theme()
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1)
    if (this.rootSubMenuKeys.indexOf(latestOpenKey) === -1)
      this.setState({ openKeys })
    else
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
  }


  onModChange = type => {
    if (type === 'development') {
      this.props.toggle_development_mode()
    } else {
      this.props.toggle_test_mode()
    }
  }

  checkEmailReSubmit = async () => {
    const result = await apolloClient.query({ query: VERIFICATION_RESUBMIT })
    toast(true, result.data.managerReSubmitEmail.informative)
  }

  render() {

    if ( ! this.props.auth_loaded) {
      return <Spin/>
    }

    let root = document.body
    root.classList = []
    this.props.dark_theme ? root.classList.add('dark-layout') : root.classList.add('light-layout')

    const mainMenu = (
      <Menu
        theme={this.props.dark_theme && this.props.sizeWidth > 576 ? 'dark' : 'light'}
        mode="inline"
        onClick={() => {
          this.setState({ popoverMenuVisible: false })
        }}
        openKeys={this.state.openKeys}
        selectedKeys={this.state.selectedItem ? [this.state.selectedItem] : []}
        onOpenChange={this.onOpenChange}
        onSelect={item => {
          this.setState({
            selectedItem: item.key,
          })
        }}
      >

        <Menu.Item key="starting">
          <Link to={routes.STARTING}><Icon type="home"/><span>{this.props.langData['Starting']}</span></Link>
        </Menu.Item>
        <Menu.SubMenu key="customer"
                      title={<div><Icon type="idcard"/><span>{this.props.langData['Customer']}</span></div>}>
          <Menu.Item key="new-customer">
            <Link to={routes.CUSTOMER_NEW}><span>{this.props.langData['New Customer']}</span></Link>
          </Menu.Item>
          <Menu.Item key="customer-list">
            <Link to={routes.CUSTOMER_LIST}><span>{this.props.langData['Customer List']}</span></Link>
          </Menu.Item>
          <Menu.Item key="customer-defaults">
            <Link to={routes.CUSTOMER_DEFAULTS}><span>{this.props.langData['Customer Defaults']}</span></Link>
          </Menu.Item>
          <Menu.Item key="customer-balance-report"><Link
            to={'/' + this.props.langData.route['customer/balance-report']}><span>{this.props.langData['Customer Balance Report']}</span></Link></Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key="product"
          title={<div><Icon type="star-o"/><span>{this.props.langData['Product']}</span></div>}
        >
          <Menu.Item key="new-product">
            <Link to={routes.PRODUCT_NEW}><span>{this.props.langData['New Product']}</span></Link>
          </Menu.Item>
          <Menu.Item key="product-list">
            <Link to={routes.PRODUCT_LIST}><span>{this.props.langData['Product List']}</span></Link>
          </Menu.Item>
          <Menu.Item key="additional-charges">
            <Link to={routes.ADDITIONAL_CHARGE_LIST}><span>{this.props.langData['Additional Charges']}</span></Link>
          </Menu.Item>
          <Menu.Item key="product-defaults"><Link
            to={'/' + this.props.langData.route['product/defaults']}><span>{this.props.langData['Product Defaults']}</span></Link></Menu.Item>
          <Menu.Item key="sub-product-attributes"><Link
            to={'/' + compile(this.props.langData.route['sub-product/attributes/:id?'])({ id: null })}><span>{this.props.langData['Sub Product Attributes']}</span></Link></Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="invoice"
                      title={<div><Icon type="calculator"/><span>{this.props.langData['Invoice']}</span></div>}>
          <Menu.Item key="create-invoice">
            <Link to={routes.INVOICE_NEW}><span>{this.props.langData['New Invoice']}</span></Link>
          </Menu.Item>
          <Menu.Item key="invoice-list">
            <Link to={routes.INVOICE_LIST}><span>{this.props.langData['Invoice List']}</span></Link>
          </Menu.Item>
          <Menu.Item key="waybill-list"><Link
            to={'/' + this.props.langData.route['waybill/list']}><span>{this.props.langData['Waybill List']}</span></Link></Menu.Item>
          <Menu.Item key="invoice-defaults"><Link
            to={'/' + this.props.langData.route['invoice/defaults']}><span>{this.props.langData['Invoice Defaults']}</span></Link></Menu.Item>
          <Menu.Item key="invoice-income-expense-report"><Link
            to={'/' + this.props.langData.route['invoice/invoice-income-expense-report']}><span>{this.props.langData['Category Based Report']}</span></Link></Menu.Item>
          <Menu.Item key="incoming-e-invoice"><Link
            to={'/' + this.props.langData.route['invoice/incoming-e-invoice-box']}><span>{this.props.langData['Incoming E-Invoice Box']}</span></Link></Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="safe" title={<div><Icon type="wallet"/><span>{this.props.langData['Safe']}</span></div>}>
          {this.props.manager_grade === 1 && (
            <Menu.Item key="new-safe">
              <Link to={routes.SAFE_NEW}><span>{this.props.langData['New Safe']}</span></Link>
            </Menu.Item>
          )}
          <Menu.Item key="safe-list">
            <Link to={routes.SAFE_LIST}><span>{this.props.langData['Safe List']}</span></Link>
          </Menu.Item>
          <Menu.Item key="safe-report">
            <Link to={routes.SAFE_REPORT}><span>{this.props.langData['Safe Report']}</span></Link>
          </Menu.Item>
          <Menu.Item key="safe-income-expense-report">
            <Link to={routes.SAFE_INCOME_EXPENSE_REPORT}>
              <span>{this.props.langData['Income Expense Report']}</span>
            </Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="transaction"
                      title={<div><Icon type="pay-circle-o"/><span>{this.props.langData['Transaction']}</span></div>}>
          <Menu.Item key="transaction-report">
            <Link to={routes.TRANSACTION_REPORT}><span>{this.props.langData['Transaction Report']}</span></Link>
          </Menu.Item>
          <Menu.Item key="transaction-list">
            <Link to={routes.TRANSACTION_LIST}>
              <span>{this.props.langData['Transaction List']}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="transaction-recoveries"><Link
            to={'/' + this.props.langData.route['transaction/recoveries']}><span>{this.props.langData['Credits Report']}</span></Link></Menu.Item>
          <Menu.Item key="vat-report">
            <Link to={routes.TRANSACTION_VAT_REPORT}><span>{this.props.langData['VAT Report']}</span></Link>
          </Menu.Item>
        </Menu.SubMenu>

        {this.props.manager_grade === 1 && (
          <Menu.SubMenu
            key="settings"
            title={<div><Icon type="setting"/><span>{this.props.langData['Settings']}</span></div>}
          >
            <Menu.Item key="sequence-list">
              <Link to={'/' + this.props.langData.route['sequence']}>
                <span>{this.props.langData['Sequences']}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="template-list">
              <Link to={'/' + this.props.langData.route['template/list']}>
                <span>{this.props.langData['Templates']}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="waybill-template-list"><Link
              to={'/' + this.props.langData.route['waybill-template/list']}><span>{this.props.langData['Waybill Templates']}</span></Link></Menu.Item>
            <Menu.Item key="categories">
              <Link to={routes.CATEGORY_LIST}><span>{this.props.langData['Categories']}</span></Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}


        {this.props.sizeWidth <= 422 && <Menu.Item key="logout">
          <div onClick={() => {
            localStorage.removeItem('token')
            this.props.history.push(routes.LOGIN)
          }}>
            <Icon type="logout"/><span> {this.props.langData['Logout']}</span>
          </div>
        </Menu.Item>}

        {(this.props.development_mode || this.props.test_mode) && hasLicence(this.props.licences, licenceMethods.HOOK) && (
          <Menu.SubMenu
            key="webhook"
            title={<div><ApiOutlined/><span>{this.props.langData['Web Hook']}</span></div>}
          >
            <Menu.Item key="webhook-list">
              <Link to={routes.WEBHOOK_LIST}><span>{this.props.langData['Web Hook List']}</span></Link>
            </Menu.Item>
            <Menu.Item key="new-webhook">
              <Link to={routes.WEBHOOK_NEW}><span>{this.props.langData['New Web Hook']}</span></Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        <Menu.Item key="support">
          <Link to={routes.SUPPORT_PAGE}>
            <Icon type="customer-service"/><span>{this.props.langData['Support']}</span>
          </Link>
        </Menu.Item>

      </Menu>
    )

    const menuManager = (
      <Menu>
        <Menu.Item key="manager-list">
          <Link to={routes.MANAGER_LIST}><span>{this.props.langData['Manager List']}</span></Link>
        </Menu.Item>
        <Menu.Item key="my-manager-update">
          <Link to={routes.MANAGER_INFO}>
            <span> {this.props.langData['My Manager Information']}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="new-manager">
          <Link to={routes.MANAGER_NEW}><span>{this.props.langData['New Manager']}</span></Link>
        </Menu.Item>
      </Menu>

    )

    const menuLanguages = <Menu onSelect={e => this.changeLang(e.key)} selectable={true}>
      {Object.keys(this.props.langData.languages).map(x => {
        return (
          <Menu.Item key={x}>{this.props.langData.languages[x]}</Menu.Item>
        )
      })}
    </Menu>

    const menuUser = (
      <Menu className="menu-default">
        {this.props.manager_grade === 1 && (
          <Menu.Item key="user-information">
            <Link to={routes.COMPANY_EDIT_SETTINGS}>
              <Icon type="bank"/><span> {this.props.langData['Company Information']}</span>
            </Link>
          </Menu.Item>
        )}
        {this.props.manager_grade === 1 && (
          <Menu.Item key="settings">
            <Link to={routes.SETTINGS_INDEX}>
              <Icon type="control"/><span> {this.props.langData['Preferences']}</span>
            </Link>
          </Menu.Item>
        )}
        {this.props.manager_grade === 1 && (
          <Menu.Item key="my-packages">
            <Link to={routes.MY_PACKAGES_LIST}>
              <Icon type="control"/><span> {this.props.langData['My Packages']}</span>
            </Link>
          </Menu.Item>
        )}
        {this.props.manager_grade === 1 && (
          <Menu.Item key="buy-new-package">
            <Link to={routes.MY_PACKAGES_BUY_NEW}>
              <Icon type="control"/><span> {this.props.langData['New Package']}</span>
            </Link>
          </Menu.Item>
        )}
        {this.props.sizeWidth <= 576 && (
          <Menu.Item key="support">
            <div onClick={() => this.props.history.push(routes.SUPPORT_PAGE)}>
              <Icon type="customer-service"/><span> {this.props.langData['Support']}</span>
            </div>
          </Menu.Item>
        )}
      </Menu>
    )

    return (
      <React.Fragment>
        { ! this.props.email_verification && (
          <Alert style={{ margin: '5px 5px 5px 5px' }} message={(
            <React.Fragment>
              <div style={{ overflow: 'auto' }}>
                <span>{this.props.langData['Verify Your JetOfis Account']}</span>
                <Button
                  style={{ float: 'right', marginRight: 10, marginTop: -5 }} icon="mail" type="link"
                  onClick={() => {
                    this.checkEmailReSubmit()
                  }}
                >
                  {this.props.langData['Resubmit']}
                </Button>
              </div>
            </React.Fragment>
          )} type="warning" showIcon closable/>
        )}
        <Router>
          <Layout className="main" style={{ width: '100%' }}>
            {this.props.sizeWidth > 576 && (
              <Layout.Sider
                collapsible mode="inline"
                collapsed={this.state.collapsed}
                trigger={null}
                className="sider"
                style={{ background: (this.props.dark_theme ? '#001529' : 'white') }}
              >

                <div className="sider-content">
                  <div className="logo-block" style={{ padding: this.state.collapsed ? '15px 20px' : '20px 36px' }}>
                    <Link
                      to={routes.STARTING}
                      onClick={() => this.setState({ selectedItem: 'starting' })}
                    >
                      <img
                        alt="Jetofis"
                        src={(this.state.collapsed ? logo_mini : (this.props.dark_theme ? logo_dark : logo))}
                      />
                    </Link>
                  </div>

                  {mainMenu}

                  { ! this.state.collapsed &&
                    <div className="sider-footer" style={{ background: this.props.dark_theme ? '#000d18' : '' }}>
									<span className="switch-theme">
										<Icon type="bulb"/> {this.props.langData['Switch Theme']}
									</span>
                      <Switch onChange={this.themeOnChange} className="fl-r"
                              checkedChildren={this.props.langData['Dark']}
                              unCheckedChildren={this.props.langData['Light']} checked={this.props.dark_theme}/>
                    </div>}
                </div>
              </Layout.Sider>
            )}
            <Layout style={{ height: '100vh' }}>
              <Layout.Header className="header">
                <Row className={this.props.dark_theme ? 'row-color h-100' : 'row-colorr h-100'} type="flex"
                     justify="space-around" align="middle">

                  <Col xs={2} sm={4} md={4} lg={9}>
                    {this.props.sizeWidth <= 576 &&
                      <Popover overlayClassName="menu-popover" visible={this.state.popoverMenuVisible}
                               placement="topLeft"
                               content={mainMenu} trigger="click"
                               onVisibleChange={visible => ! visible && this.setState({ popoverMenuVisible: false })}>
                        <Icon className="side-trigger" type="bars"
                              onClick={() => this.setState({ popoverMenuVisible: ! this.state.popoverMenuVisible })}/>
                      </Popover>}

                    {this.props.sizeWidth > 576 && <Icon
                      className="side-trigger"
                      style={{ color: (this.props.dark_theme ? '#ffffff' : '#9da5ac') }}
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.toggleSideCollapsed}
                    />}

                  </Col>
                  <Col xs={7} sm={2} md={4} lg={6} className="h-100">
                    <Link to={routes.STARTING}>
                      {this.props.sizeWidth <= 576 && <img
                        alt="Jetofis"
                        src={logo}
                        style={{
                          padding: 10,
                          maxHeight: '100%',
                          maxWidth: '100%',
                          display: 'flex',
                          borderRadius: '10px',
                        }}
                      />}
                    </Link>
                  </Col>
                  <Col xs={15} sm={18} md={16} lg={9}
                       style={{ lineHeight: '1px', textAlign: this.props.sizeWidth <= 422 ? 'center' : 'right' }}>
                    <Dropdown trigger={['click']} overlay={menuLanguages}>
											<span style={{
                        verticalAlign: 'middle',
                        marginRight: 10,
                        lineHeight: '32px',
                        display: 'inline-block',
                      }}>
												<Icon type="global" style={{
                          fontSize: 28,
                          color: this.props.dark_theme ? '#ffffff' : '#9da5ac',
                          marginRight: 5,
                          float: 'left',
                        }}/>
												<span style={{
                          fontSize: 15,
                          color: this.props.dark_theme ? '#ffffff' : '#9da5ac',
                        }}>{this.props.langData.languages[this.props.lang]}</span>
											</span>
                    </Dropdown>

                    {this.props.sizeWidth >= 576 && <Link to={routes.SUPPORT_PAGE}>
                      <Avatar className="header-avatar" style={{
                        marginRight: 10,
                        borderRadius: '10px',
                        backgroundColor: this.props.dark_theme ? '#445463' : '#ffffff',
                        color: this.props.dark_theme ? '#ffffff' : '#9da5ac',
                      }} icon="customer-service"/>
                    </Link>}

                    <Dropdown trigger={['click']} overlay={menuManager}>
                      <Avatar className="header-avatar" style={{
                        marginRight: 10,
                        borderRadius: '10px',
                        backgroundColor: this.props.dark_theme ? '#445463' : '#ffffff',
                        color: this.props.dark_theme ? '#ffffff' : '#9da5ac',
                      }} icon="user" shape="square"/>
                    </Dropdown>

                    <Dropdown trigger={['click']} overlay={menuUser} defaulValue="tr">
                      <Avatar className="header-avatar" style={{
                        marginRight: 10,
                        borderRadius: '10px',
                        backgroundColor: this.props.dark_theme ? '#445463' : '#ffffff',
                        color: this.props.dark_theme ? '#ffffff' : '#9da5ac',
                      }} icon="key"/>
                    </Dropdown>

                    <Divider type="vertical" hidden={this.props.sizeWidth <= 422}
                             style={{ backgroundColor: this.props.dark_theme ? '#ffffff' : '#9da5ac' }}/>
                    <Avatar trigger={['click']} hidden={this.props.sizeWidth <= 422} className="header-avatar" style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginRight: 10,
                      borderRadius: '10px',
                      backgroundColor: this.props.dark_theme ? '#445463' : '#ffffff',
                      color: this.props.dark_theme ? '#ffffff' : '#f37054',
                    }}>
                      <div onClick={() => {
                        confirm(undefined, result => {
                          if ( ! result) {
                            return
                          }
                          localStorage.removeItem('token')
                          this.props.history.push(routes.LOGIN)
                        })
                      }}>
                        <Icon type="poweroff" style={{ fontSize: '17px', marginTop: '7px' }}/>

                      </div>
                    </Avatar>
                  </Col>
                </Row>
              </Layout.Header>
              <Layout.Content style={{ overflow: 'auto', backgroundColor: '#e8e9eb' }} id="main-content">
                <div style={{ padding: this.props.sizeWidth > 576 ? '20px 20px 0px 20px' : 10 }}>

                  {/* <div style={{ display: 'inline-block', width: '80%' }}> */}
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Breadcrumb className="content-breadcrumb"
                                  style={{ color: this.props.dark_theme ? '' : '#000000' }}>
                        {this.props.breadCrumbData.map((x, k) => (
                          <Breadcrumb.Item key={k}>
                            {x.icon && <Icon type={x.icon}/>}
                            {(x.url !== undefined) && (this.props.breadCrumbData.length !== k + 1) &&
                              <span><Link to={x.url}>{this.props.langData[x.name] || x.name}</Link></span>}
                            {(x.url === undefined || this.props.breadCrumbData.length === k + 1) &&
                              <span>{this.props.langData[x.name] || x.name}</span>}
                          </Breadcrumb.Item>
                        ))}
                      </Breadcrumb>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}
                         style={{ textAlign: this.props.sizeWidth <= 422 ? 'center' : '' }}>
                      <Button className={this.props.dark_theme ? 'dark-button-color' : 'light-button-color'}
                              hidden={this.props.sizeWidth <= 422} style={{
                        textAlign: this.props.sizeWidth <= 576 ? 'left' : 'right',
                        float: this.props.sizeWidth <= 576 ? '' : 'right',
                      }}>
                        <Link to={routes.CUSTOMER_NEW}>
                          <Icon style={{
                            color: this.props.dark_theme ? '#ffffff' : '#666666',
                            marginRight: '2px',
                            border: 0,
                          }} type="plus"/>
                          <span
                            style={{ color: this.props.dark_theme ? '#ffffff' : '#666666' }}>{this.props.langData['New Customer']}</span>
                        </Link>
                      </Button>
                      <Button className={this.props.dark_theme ? 'dark-button-color' : 'light-button-color'} style={{
                        marginBottom: '5px',
                        textAlign: this.props.sizeWidth <= 576 ? 'left' : '',
                        float: this.props.sizeWidth <= 576 ? '' : 'right',
                      }}>
                        <Link to={routes.INVOICE_NEW}>
                          <Icon style={{ color: this.props.dark_theme ? '#ffffff' : '#666666', marginRight: '2px' }}
                                type="plus"/>
                          <span
                            style={{ color: this.props.dark_theme ? '#ffffff' : '#666666' }}>{this.props.langData['New Invoice']}</span>
                        </Link>
                      </Button>
                      <Button className={this.props.dark_theme ? 'dark-button-color' : 'light-button-color'} style={{
                        textAlign: this.props.sizeWidth <= 576 ? 'left' : '',
                        float: this.props.sizeWidth <= 576 ? '' : 'right',
                      }}>
                        <Link to={routes.PRODUCT_NEW}>
                          <Icon style={{ color: this.props.dark_theme ? '#ffffff' : '#666666', marginRight: '2px' }}
                                type="plus"/>
                          <span
                            style={{ color: this.props.dark_theme ? '#ffffff' : '#666666' }}>{this.props.langData['New Product']}</span>
                        </Link>
                      </Button>
                    </Col>
                  </Row>


                  {/* </div> */}

                  <RouterSwitch>
                    {/* Starting */}
                    <Route exact path={routes.STARTING} component={Starting}/>

                    {/* Customer */}
                    <Route exact path={routes.CUSTOMER_LIST} component={CustomerList}/>
                    <Route exact path={routes.CUSTOMER_NEW} component={NewCustomer}/>
                    <Route exact path={routes.CUSTOMER_UPDATE} component={CustomerUpdate}/>
                    <Route path={routes.CUSTOMER_DETAIL} component={CustomerDetail}/>
                    <Route exact path={routes.CUSTOMER_DEFAULTS} component={CustomerDefaults}/>
                    <Route path={routePaths['customer/balance-report']} component={CustomerBalanceReport}/>

                    {/* Product */}
                    <Route exact path={routes.PRODUCT_LIST} component={ProductList}/>
                    <Route exact path={routes.PRODUCT_NEW} component={NewProduct}/>
                    <Route exact path={routePaths['product/update/:id']} component={ProductUpdate}/>
                    <Route path={routePaths['product/detail/:id']} component={ProductDetail}/>
                    <Route path={routePaths['sub-product/attributes/:id?']} component={SubProductAttributes}/>
                    <Route exact path={routePaths['product/defaults']} component={ProductDefaults}/>
                    <Route path={routes.PRODUCT_COPY} component={NewProductCopy}/>

                    {/* Safe */}
                    <Route exact path={routes.SAFE_LIST} component={SafeList}/>
                    <Route exact path={routes.SAFE_NEW} component={NewSafe}/>
                    <Route exact path={routes.SAFE_UPDATE} component={SafeUpdate}/>
                    <Route path={routes.SAFE_DETAIL_BY_ID} component={SafeDetail}/>
                    <Route exact path={routes.SAFE_REPORT} component={SafeReport}/>
                    <Route exact path={routes.SAFE_INCOME_EXPENSE_REPORT} component={IncomeExpenseReport}/>

                    {/* Manager */}
                    <Route exact path={routes.COMPANY_EDIT_SETTINGS} component={UserInformation}/>
                    <Route exact path={routes.SETTINGS_INDEX} component={UserSettings}/>
                    <Route exact path={routes.MANAGER_LIST} component={ManagerList}/>
                    <Route exact path={routes.MANAGER_NEW} component={NewManager}/>
                    <Route exact path={routes.MANAGER_UPDATE} component={ManagerUpdate}/>
                    <Route exact path={routes.MANAGER_INFO} component={ManagerInfo}/>

                    {/* Invoice */}
                    <Route exact path={routes.INVOICE_LIST} component={InvoiceList}/>
                    <Route exact path={routes.INVOICE_NEW} component={NewInvoice}/>
                    <Route exact path={routePaths['invoice/:type/update/:id']} component={InvoiceUpdate}/>
                    <Route path={routes.INVOICE_DETAIL_BY_TYPE} component={InvoiceDetail}/>
                    <Route exact path={routePaths['invoice/defaults']} component={InvoiceDefaults}/>
                    <Route exact path={routePaths['invoice/invoice-income-expense-report']}
                           component={InvoiceIncomeExpenseReport}/>
                    <Route path={routes.INVOICE_RETURN_BY_TYPE} component={InvoiceReturn}/>
                    <Route path={routes.PURCHASE_RETURN_INVOICE_DETAIL} component={PurchaseReturnInvoiceDetail}/>
                    <Route exact path={routes.INVOICE_TYPE_NEW} component={NewCustomerInvoice}/>
                    <Route path={routes.INVOICE_COPY_NEW} component={InvoiceCopy}/>
                    <Route exact path={routePaths['invoice/incoming-e-invoice-box']} component={IncomingEInvoiceBox}/>
                    <Route path={routePaths['invoice/detail/incoming-e-invoice-box/:id']}
                           component={IncomingEInvoiceBoxDetail}/>

                    {/* Waybill */}
                    <Route exact path={routes.WAYBILL_NEW_INVOICE} component={NewWaybillInvoice}/>
                    <Route exact path={routePaths['waybill/list']} component={WaybillList}/>
                    <Route exact path={routePaths['waybill/new/:type']} component={NewWaybill}/>
                    <Route exact path={routePaths['waybill/new/:type/:id']} component={NewWaybill}/>
                    <Route exact path={routePaths['waybill/:type/update/:id']} component={WaybillUpdate}/>
                    <Route path={routePaths['waybill/:type/detail/:id']} component={WaybillDetail}/>

                    {/* Transaction */}
                    <Route exact path={routes.TRANSACTION_LIST} component={TransactionList}/>

                    <Route path={routes.TRANSACTION_DETAIL_BY_TYPE} component={TransactionDetail}/>
                    <Route exact path={routes.TRANSACTION_REPORT} component={TransactionReport}/>
                    <Route exact path={routePaths['transaction/recoveries']} component={TransactionRecoveries}/>
                    <Route exact path={routes.TRANSACTION_VAT_REPORT} component={VatReport}/>

                    {/* Template */}
                    <Route exact path={routePaths['template/list']} component={TemplateList}/>
                    <Route exact path={routePaths['template/new']} component={NewTemplate}/>
                    <Route exact path={routePaths['template/update/:id']} component={TemplateUpdate}/>

                    {/* Waybill Template */}
                    <Route exact path={routePaths['waybill-template/list']} component={WaybillTemplateList}/>
                    <Route exact path={routePaths['waybill-template/new']} component={NewWaybillTemplate}/>
                    <Route exact path={routePaths['waybill-template/update/:id']}
                           component={WaybillTemplateUpdate}/>

                    {/* Support */}
                    <Route exact path={routes.SUPPORT_PAGE} component={SupportPage}/>

                    {/* My Packages */}
                    <Route exact path={routes.MY_PACKAGES_LIST} component={MyPackagesPage}/>
                    <Route exact path={routes.MY_PACKAGES_BUY_NEW} component={BuyNewPackagePage}/>

                    {/* Sequence */}
                    <Route path={routePaths['sequence']} component={Sequence}/>

                    {/* Categories */}
                    <Route path={routes.CATEGORY_LIST} component={Category}/>

                    {/* Additional Charge */}
                    <Route path={routes.ADDITIONAL_CHARGE_LIST} component={AdditionalCharge}/>

                    {/* Preferences */}
                    <Route path={routes.GENERAL_SETTINGS} component={GeneralSettings}/>
                    <Route path={routes.INTEGRATION_SETTINGS} component={IntegrationSettings}/>
                    <Route path={routes.INVOICE_SETTINGS} component={InvoiceSettings}/>
                    <Route path={routes.PRODUCT_SETTINGS} component={ProductSettings}/>
                    <Route path={routes.DESPATCH_SETTINGS} component={EWaybillSettings}/>
                    {hasLicence(this.props.licences, licenceMethods.TSOFT_INTEGRATION) &&
                      <Route path={routes.TSOFT_WS_SETTINGS} component={TSoftWebServiceSettings}/>}

                    {/* Web Hook */}
                    {(this.props.development_mode || this.props.test_mode) && hasLicence(this.props.licences, licenceMethods.HOOK) && (
                      <Route exact path={routes.WEBHOOK_LIST} component={WebhookList}/>
                    )}
                    {(this.props.development_mode || this.props.test_mode) && hasLicence(this.props.licences, licenceMethods.HOOK) && (
                      <Route exact path={routes.WEBHOOK_NEW} component={NewWebhook}/>
                    )}
                    {(this.props.development_mode || this.props.test_mode) && hasLicence(this.props.licences, licenceMethods.HOOK) && (
                      <Route exact path={routes.WEBHOOK_UPDATE} component={WebhookUpdate}/>
                    )}
                    {(this.props.development_mode || this.props.test_mode) && hasLicence(this.props.licences, licenceMethods.HOOK) && (
                      <Route path={routes.WEBHOOK_DETAIL} component={WebhookDetail}/>
                    )}

                    {/* integration */}
                    <Route path={routes.INTEGRATION_TSOFT_SYNC_INVOICE} component={SyncInvoice}/>

                    {/* Default Page */}
                    <Route exact path="" component={Starting}/>

                  </RouterSwitch>
                </div>
                {(this.props.development_mode || this.props.test_mode) && (
                  <Tag
                    color="blue"
                    style={{
                      position: 'fixed',
                      zIndex: 999999,
                      bottom: 5,
                      left: 5,
                    }}
                  >
                    {this.props.development_mode && (
                      <span style={{ cursor: 'default' }}>
                        <Icon
                          type="close"
                          onClick={() => this.onModChange('development')}
                          style={{
                            margin: 3,
                            color: '#cd5c5c',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        /> Geliştirici Modu
                      </span>
                    )}
                    {this.props.test_mode && (
                      <span style={{ cursor: 'default' }}>
                        <Icon
                          type="close"
                          onClick={() => this.onModChange('test')}
                          style={{
                            margin: 3,
                            color: '#cd5c5c',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        /> Test Modu
                      </span>
                    )}
                  </Tag>
                )}
              </Layout.Content>
            </Layout>
          </Layout>
        </Router>
      </React.Fragment>
    )
  }

}

Main.propTypes = {
  langData: PropTypes.object,
  languageChange: PropTypes.func,
  sizeWidth: PropTypes.number,
  manager_id: PropTypes.number,
  history: PropTypes.object,
  location: PropTypes.object,
  lang: PropTypes.string,
  breadCrumbData: PropTypes.array,
  test_mode: PropTypes.bool,
  toggle_test_mode: PropTypes.func,
  development_mode: PropTypes.bool,
  toggle_development_mode: PropTypes.func,
  email_verification: PropTypes.bool,
  dark_theme: PropTypes.bool,
  switch_theme: PropTypes.func,

  auth_loaded: PropTypes.bool,
  manager_grade: PropTypes.number,
  licences: PropTypes.object,

  set_token_info: PropTypes.func,
  clear_token_info: PropTypes.func,

}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    breadCrumbData: state.BreadCrumbReducer.data,
    sizeWidth: state.SizeDetectorReducer.width,
    test_mode: state.GeneralReducer.test_mode,
    development_mode: state.GeneralReducer.development_mode,
    email_verification: state.AuthReducer.email_verification,
    dark_theme: state.GeneralReducer.dark_theme,

    auth_loaded: state.AuthReducer.auth_loaded,
    manager_grade: state.AuthReducer.manager_grade,
    licences: state.AuthReducer.licences,

  }
}, dispatch => {
  return {
    languageChange(val) {
      dispatch({ type: 'language_change', payload: { lang: val } })
    },
    toggle_development_mode() {
      dispatch({ type: 'toggle_development_mode' })
    },
    toggle_test_mode() {
      dispatch({ type: 'toggle_test_mode' })
    },
    switch_theme() {
      dispatch({ type: 'switch_theme' })
    },
    set_token_info(payload) {
      dispatch({ type: 'set_token_info', payload })
    },
    clear_token_info() {
      dispatch({ type: 'clear_token_info' })
    },
  }
})(Main)
