import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'antd'

import ManagerUpdateForm from './ManagerUpdateForm'

function ManagerUpdate(props) {

  const managerId = parseInt(props.match.params.id)

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedManagerUpdateForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(ManagerUpdateForm)

  return <WrappedManagerUpdateForm {...props} manager_id={managerId}/>

}

ManagerUpdate.propTypes = {
  langData: PropTypes.object,
  match: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Manager' } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Update' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(ManagerUpdate)
