import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { generatePath, Link } from 'react-router-dom'
import moment from 'moment'
import { Form, Icon, Input, Button, Card, Divider, DatePicker, InputNumber } from 'antd'
import { CloseOutlined, PayCircleOutlined } from '@ant-design/icons'

import routes from '../../routes'

import AddUpSelect from './../Utils/AddUpSelect'

import { postPanelApi } from '../../api'
import { getCurrencyWithId } from '../../data/Currency'
import { updateEmptyAndUndefined, successToast, formErrorFieldsNameLocalize } from '../../helpers/helper'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 17 },
  },
}

const formItemLayoutNoLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
    md: { span: 0 },
    lg: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
}

class UpdateMoneyIOForm extends Component {

  state = {
    loading: false,
    submitting: false,

    categories: [],
    amount: 0,
    safe_name: null,
    currency_symbol: '',
  }

  componentDidMount() {

    this.setState({
      loading: true,
    })

    postPanelApi('/safe/update-io-open', {
      type: this.props.type,
      transaction_id: this.props.transaction_id,
    }).then(result => {

      this.setState({
        loading: false,
      }, () => {

        if (result) {

          this.setState({
            categories: result.categories,
            amount: result.transaction.amount,
            safe_name: result.safe.name,
            currency_symbol: getCurrencyWithId(result.safe.currency_id).symbol,
          })

          this.props.form.setFieldsValue({
            category_id: result.form.category_id,
            date: moment(result.form.date),
            explanation: result.form.explanation,
          })

        }

      })

    })

  }

  onSubmit = () => {

    this.props.form.validateFieldsAndScroll((err, values) => {

      if (err) {
        return formErrorFieldsNameLocalize(err)
      }

      // kayıt aşaması

      updateEmptyAndUndefined(values)

      this.setState({
        submitting: true,
      })

      postPanelApi('/safe/update-io-save', {
        type: this.props.type,
        transaction_id: this.props.transaction_id,
        category_id: values.category_id,
        date: values.date.toISOString(true),
        explanation: values.explanation,
      }).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.push(generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
            id: this.props.transaction_id,
            type: this.props.type,
          }))

          this.props.callback()

        } else {

          this.setState({
            submitting: false,
          })

        }

      })


    })
  }

  createTransactionCategory = (name) => {

    postPanelApi('/transaction/category/create', { name }).then(result => {

      if (result) {

        this.setState(state => {

          const categories = [...state.categories, {
            category_id: result.category_id,
            name: result.name,
          }]

          return {
            categories,
          }

        })

      }

    })

  }

  render() {

    const { getFieldDecorator } = this.props.form

    const form = () => (
      <Form
        hideRequiredMark={true}
        onSubmit={e => {
          e.preventDefault()
          this.onSubmit()
        }}
        className="form-label-default no-indent"
      >

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Safe']}</p>}
                     {...formItemLayout}
          >
            <Input value={this.state.safe_name} disabled={true}/>
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Amount']}</p>}
                     {...formItemLayout}
          >
            <Input.Group compact style={{ display: 'flex' }}>
              <InputNumber
                size="large"
                className="w-100"
                disabled={true}
                decimalSeparator=","
                value={this.state.amount}
              />
              <Button disabled size="large">{this.state.currency_symbol}</Button>
            </Input.Group>
          </Form.Item>

          <Form.Item label={<p>{this.props.langData['Category']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('category_id')(
              <AddUpSelect
                data={this.state.categories.map(x => ({ key: x.category_id, value: x.category_id, content: x.name }))}
                onAddUp={value => this.createTransactionCategory(value)}
              />,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">

          <Form.Item label={<p>{this.props.langData['Date']}</p>}
                     {...formItemLayout}
          >
            {getFieldDecorator('date', {
              rules: [{ required: true }],
            })(
              <DatePicker
                format="DD.MM.YYYY HH:mm"
                showTime={{ format: 'HH:mm' }}
                size="large"
                className="w-100"
                suffixIcon={<Icon type="calendar" theme="filled"/>}
              />,
            )}
          </Form.Item>

        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item hasFeedback {...formItemLayoutNoLabel} >
            {getFieldDecorator('explanation', { rules: [{ max: 250 }] })(
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 2, maxRows: 5 }}
                maxLength={250}
                placeholder={this.props.langData['Explanation']}
              />,
            )}
          </Form.Item>
        </div>

        <Divider/>

        <div className="form-block">
          <Form.Item {...formItemLayoutNoLabel}>
            <Button type="primary" size="large" loading={this.state.submitting} htmlType="submit"
                    className="default-button">
              {this.props.langData['Save']}
            </Button>
            <Link
              to={generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
                id: this.props.transaction_id,
                type: this.props.type,
              })}
            >
              <Button size="large" style={{ marginRight: 8 }} loading={this.state.submitting}
                      className="default-button danger2">
                {this.props.langData['Close']}
              </Button>
            </Link>
          </Form.Item>
        </div>

      </Form>
    )

    if (this.props.sizeWidth <= 576) {
      return form()
    }

    return (
      <Card
        bodyStyle={{ padding: '0px' }}
        title={<span><PayCircleOutlined/> &nbsp;{this.props.langData['Update']}</span>}
        bordered={false}
        loading={this.state.loading}
        extra={
          <Link
            to={generatePath(routes.TRANSACTION_DETAIL_BY_TYPE, {
              id: this.props.transaction_id,
              type: this.props.type,
            })}
          >
            <CloseOutlined/>
          </Link>
        }
      >
        {form()}
      </Card>
    )

  }

}

UpdateMoneyIOForm.propTypes = {
  langData: PropTypes.object,
  sizeWidth: PropTypes.number,

  transaction_id: PropTypes.number,
  type: PropTypes.string,

  form: PropTypes.object,

  callback: PropTypes.func,

  history: PropTypes.object,
}

export default UpdateMoneyIOForm
