import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col, Card, Icon, Tag, Divider, Button, Menu, Dropdown, Table, Tabs } from 'antd'
import ReactJson from 'react-json-view'
import { graphQLErrorsToToast, confirm, successToast } from '../../helpers/helper'
import { deleteData } from '../../helpers/trait'
import { cloneDeep, startCase } from 'lodash'
import moment from 'moment'
import { gql } from '@apollo/client'

import { Query } from '@apollo/client/react/components'
import { generatePath } from 'react-router-dom'
import routes from '../../routes'
import { CustomerServiceOutlined } from '@ant-design/icons'
import { postPanelApi } from '../../api'

const READ_QUERY = gql`
  query($hook_id: Int!, $paging: Paging, $filter: HookTransactionFilterBase){
    hook(hook_id: $hook_id) {
      hook_id
      url
      type
      date
      token
      active
      destination
      methods {
        method
      }
    }

    hookTransactionList(paging: $paging, filter: $filter){
      hook_transactions {
        _id
        response
        request
        hook_id
        body
        type
        method
      }
      page_info {
        total_count
      }
    }
  }
`

class WebhookDetail extends Component {

  hook_id = null

  state = {
    data: null,
    more: false,
    transactions: null,
    pagination: {
      pageSize: 50,
      showTotal: total => `${this.props.langData['Total']} ${total} ${this.props.langData['items']}`,
    },
    paging: {
      offset: 0,
      limit: 50,
    },
    expandedKey: null,
  }

  columns = [
    {
      title: this.props.langData['Http Status Code'],
      dataIndex: 'response.status',
      width: '20%',
      key: 'status',
    },
    {
      title: this.props.langData['Date'],
      dataIndex: 'response.headers.date',
      width: '50%',
      key: 'date',
      render: value => moment(value).locale(this.props.lang).format('DD MMMM YYYY'),
    },
    {
      title: this.props.langData['Method'],
      width: '30%',
      dataIndex: 'method',
      key: 'method',
      render: value => this.props.langData[value],
    },
  ]

  constructor(props) {
    super(props)
    this.hook_id = Number(props.match.params.id)
    this.deleteData = deleteData('hook', 'hook_id').bind(this)
  }

  componentWillUnmount() {
    this.props.breadcrumb_delete()
  }

  edit = () => {
    this.props.history.push(generatePath(routes.WEBHOOK_UPDATE, {
      id: this.hook_id,
    }))
  }

  delete = () => {
    confirm(undefined, confirmed => {
      if ( ! confirmed) {
        return
      }

      postPanelApi('/webhook/delete', { webhook_id: this.hook_id }).then(result => {

        if (result) {

          successToast(result.message)

          this.props.history.replace(routes.WEBHOOK_LIST)

        }

      })

    })
  }

  menuOnClick = item => {

    switch (item.key) {
      case 'edit':
        this.edit()
        break
      case 'delete':
        this.delete()
        break
      default:
        break
    }

  }

  handleTableChange = (pagination, filters, sorting) => {
    if (pagination.pageSize) {
      this.setState({
        paging: {
          offset: (pagination.current * pagination.pageSize) - pagination.pageSize,
          limit: (pagination.current * pagination.pageSize),
        },
      })
    }
  }

  render() {

    const menu = (
      <Menu className="menu-default" onClick={this.menuOnClick}>
        <Menu.Item key="edit" icon="edit">
          <span><Icon type="edit"/>&nbsp;{this.props.langData['Edit']}</span>
        </Menu.Item>
        <Menu.Item key="delete">
          <span><Icon type="delete"/>&nbsp;{this.props.langData['Delete']}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Query
        query={READ_QUERY}
        variables={{
          hook_id: this.hook_id,
          paging: this.state.paging,
          filter: { AND: [{ hook_id: { eq: this.hook_id } }] },
        }}
        fetchPolicy="network-only"
        onError={graphQLErrorsToToast}
        onCompleted={data => {

          this.props.breadcrumb_add(data.hook.url)

          this.setState({
            data: cloneDeep(data.hook),
            transactions: cloneDeep(data.hookTransactionList.hook_transactions),
            pagination: {
              ...(this.state.pagination),
              total: data.hookTransactionList.page_info.total_count,
            },
          })

        }}
      >
        {() => {

          return (
            <div>
              {this.state.data && <div>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={14} lg={16} xl={17} style={{ marginBottom: 16 }}>
                    <Card
                      bodyStyle={{ padding: '0px', marginBottom: 10 }}
                      title={<span><CustomerServiceOutlined/> {this.props.langData['Web Hook Detail']}</span>}
                      bordered={false}
                      extra={
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button loading={ ! this.state.data}>
                            {this.props.langData['Actions']} {this.state.data && <Icon type="down"/>}
                          </Button>
                        </Dropdown>
                      }
                    >

                      <div className="form-block label-default">
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon
                              type="tag"/><p>{this.state.data.destination ? this.props.langData['Store URI'] : this.props.langData['Method URI']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {this.state.data.url}
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon type="eye-o"/><p>{this.props.langData['Type']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {this.props.langData[startCase(this.state.data.type)]}
                          </Col>
                        </Row>
                        <Row gutter={24} style={{ marginBottom: 16 }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                            <span><Icon type="eye-o"/><p>{this.props.langData['Date']}</p></span>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                            {moment(this.state.data.date).locale(this.props.lang).format('DD MMMM YYYY H:mm')}
                          </Col>
                        </Row>
                        <Divider>
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                              this.setState({
                                more: ! this.state.more,
                              })
                            }}>{! this.state.more ? this.props.langData['More'] : this.props.langData['Close']}
                          </Button>
                        </Divider>
                        {this.state.more && <div>
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                              <span><Icon type="eye-o"/><p>{this.props.langData['Type']}</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                              <div>
                                {this.state.data.methods.map(x => {
                                  return <Tag color="#108ee9" style={{ cursor: 'default' }}
                                              key={x.method}>{this.props.langData[x.method]}</Tag>
                                })}
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={24} style={{ marginBottom: 16 }}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                              <span><Icon type="eye-o"/><p>Token</p></span>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                              <Tag style={{ border: 'dashed' }} title="Copy to clipboard">
                                {this.state.data.token}
                              </Tag>
                            </Col>
                          </Row>
                        </div>}
                      </div>

                    </Card>

                    <Card
                      bodyStyle={{ padding: 0, marginTop: 20 }}
                      bordered={false}
                    >
                      <Divider>
                        <Icon type="swap"/> {this.props.langData['Transaction List']}
                      </Divider>

                      <Table
                        columns={this.columns}
                        rowKey={record => record._id}
                        dataSource={this.state.transactions}
                        pagination={this.state.pagination}
                        onChange={this.handleTableChange}
                        expandedRowKeys={[this.state.expandedKey]}
                        onExpandedRowsChange={expandedKeys => {
                          this.setState({
                            expandedKey: expandedKeys[expandedKeys.length - 1],
                          })
                        }}
                        expandedRowRender={record =>
                          <div className="card-container">
                            <Tabs type="card">
                              <Tabs.TabPane tab="Response" key="1">
                                <ReactJson
                                  src={record.response}
                                  collapsed={true}
                                  enableClipboard={false}
                                  displayObjectSize={false}
                                  displayDataTypes={false}
                                  name={false}
                                  iconStyle="circle"
                                  theme={localStorage.getItem('theme') === 'dark' ? 'harmonic' : 'rjv-default'}
                                />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Request" key="2">
                                <ReactJson
                                  src={record.request}
                                  collapsed={true}
                                  enableClipboard={false}
                                  displayObjectSize={false}
                                  displayDataTypes={false}
                                  name={false}
                                  iconStyle="circle"
                                  theme={localStorage.getItem('theme') === 'dark' ? 'harmonic' : 'rjv-default'}
                                />
                              </Tabs.TabPane>
                              <Tabs.TabPane tab="Body" key="3">
                                <ReactJson
                                  src={! (record.body instanceof Object) ? { body: record.body } : record.body}
                                  collapsed={true}
                                  enableClipboard={false}
                                  displayObjectSize={false}
                                  displayDataTypes={false}
                                  name={false}
                                  iconStyle="circle"
                                  theme={localStorage.getItem('theme') === 'dark' ? 'harmonic' : 'rjv-default'}
                                />
                              </Tabs.TabPane>
                            </Tabs>
                          </div>
                        }
                        rowClassName="white"
                        scroll={{ x: 700, y: 500 }}
                      />

                    </Card>
                  </Col>
                </Row>
              </div>}
            </div>
          )
        }}
      </Query>
    )
  }

}

WebhookDetail.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  sizeWidth: PropTypes.number,
  lang: PropTypes.string,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
    lang: state.LocalizeReducer.lang,
    sizeWidth: state.SizeDetectorReducer.width,
  }
}, dispatch => {
  return {
    breadcrumb_add(hook_address) {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Web Hook', url: routes.WEBHOOK_LIST } })
      dispatch({ type: 'breadcrumb_add', payload: { name: hook_address } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(WebhookDetail)
