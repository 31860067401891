import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import routes from '../../routes'
import NewWebhookForm from './NewWebhookForm'

function NewWebhook(props) {

  useEffect(() => {

    props.breadcrumb_add()

    return () => {

      props.breadcrumb_delete()

    }

  }, [])

  const WrappedNewWebhookForm = Form.create({
    validateMessages: props.langData.formValidationMessages,
  })(NewWebhookForm)

  return <WrappedNewWebhookForm {...props}/>

}

NewWebhook.propTypes = {
  langData: PropTypes.object,
  breadcrumb_add: PropTypes.func,
  breadcrumb_delete: PropTypes.func,
}

export default connect(state => {
  return {
    langData: state.LocalizeReducer.langData,
  }
}, dispatch => {
  return {
    breadcrumb_add() {
      dispatch({ type: 'breadcrumb_add', payload: { name: 'Web Hook', url: routes.WEBHOOK_LIST } })
      dispatch({ type: 'breadcrumb_add', payload: { name: 'New' } })
    },
    breadcrumb_delete() {
      dispatch({ type: 'breadcrumb_delete' })
    },
  }
})(NewWebhook)
