import moment from 'moment'
import { gql } from '@apollo/client'
import { debounce, startCase } from 'lodash'

import apolloClient from './apolloClient'

import { postPanelApi } from '../api'
import { toast, graphQLErrorsToToast } from './helper'

const searchCustomer = (fieldName, customerValue) => {

  return debounce(async function (value) {

    if (customerValue === value) {
      return
    }

    customerValue = value

    if (value.length < 2) {
      return
    }

    this.setState({ fetchingCustomer: true })

    const result = await postPanelApi('/customer/search-for-select', { query: value })

    if (result.items.length === 0) {
      this.props.form.setFieldsValue({ [fieldName]: null })
    }

    this.setState({ customerList: result.items, fetchingCustomer: false })

  }, 800)
}

// create category
const CREATE_CATEGORY_QUERY = type => (
  gql`
    mutation category($category: CategoryCreateInput!){
    category: ${type}CategoryCreate(category: $category){
    category {
    category_id
    }
    }
    }
  `
)

const createCategory = type => {

  return function (name) {
    return new Promise(async (r, j) => {
      try {
        const result = await apolloClient.mutate({
          mutation: CREATE_CATEGORY_QUERY(type),
          variables: { category: { name } },
        })
        let category = result.data.category.category
        let categories = this.state.categories
        categories.push({
          category_id: category.category_id,
          name,
        })
        this.setState({ categories })
        r(category.category_id)
      } catch (err) {
        graphQLErrorsToToast(err)
        j()
      }
    })
  }

}

// sekanslara ilk değerini ayarlar. patternı replace ederek
const sequenceSetFirstValues = (fields, defaults) => {
  fields.forEach(x => {
    if (defaults[x + '_sequence']) {
      defaults[x] = defaults[x + '_sequence'].pattern.replace(
        /\{\$\}/g,
        defaults[x + '_sequence'].show_all_columns ?
          defaults[x + '_sequence'].next_value.toLocaleString('tr-TR', {
            minimumIntegerDigits: defaults[x + '_sequence'].end.toString().length, useGrouping: false,
          }) :
          defaults[x + '_sequence'].next_value,
      )
    } else if (defaults[x + '_sequence'] === null) {
      defaults[x] = null
    }
  })
}

// sekansları 10 saniyede bir yeniden çek ve kontrol et. Eğer formda değişiklik olmadıysa ve sekans değiştiyse. Formuda yeni sekansa eşitle
const NEXT_SEQUENCE_QUERY = (
  gql`
    query sequenceNext($sequence_id: Int!){
      sequenceNext(sequence_id: $sequence_id){
        next_value_with_pattern
      }
    }
  `
)

const refetchAndControlSequenceValues = function (prefixFormFields, fields, defaults) {

  let intervals = []

  fields.forEach(x => {
    if (defaults[x + '_sequence']) {
      const interval = setInterval(async () => {
        try {
          if (this.props.form.getFieldValue(`${prefixFormFields}[${x}]`) === this.state.defaults[x]) {
            const result = await apolloClient.query({
              query: NEXT_SEQUENCE_QUERY,
              variables: { sequence_id: defaults[x + '_sequence'].sequence_id },
              fetchPolicy: 'network-only',
            })
            if (result.data.sequenceNext.next_value_with_pattern !== this.state.defaults[x]) {
              this.setState({
                defaults: {
                  ...this.state.defaults,
                  [x]: result.data.sequenceNext.next_value_with_pattern,
                },
              })
              this.props.form.setFieldsValue({ [`${prefixFormFields}[${x}]`]: result.data.sequenceNext.next_value_with_pattern })
            }
          }
        } catch (err) {
          graphQLErrorsToToast(err)
        }
      }, 10000)

      intervals.push(interval)

    }
  })

  return () => intervals.forEach(x => clearInterval(x))

}

// currency rates değerlerini getirir form için veya direk olarak
const CURRENCY_RATES_QUERY = (
  gql`
    query currencyRates($currency: Currency!, $date: Date!){
      currencyRates (currency: $currency, date: $date){
        currency_rates {
          try
          usd
          eur
          gbp
          rub
        }
      }
    }
  `
)

// kur değerini formdaki selectedCurrency yada selectedSafe e göre getirir. aynı zamanda belirlenen fieldName e göre tarihi getirir
const fetchCurrencyRates = fieldName => {
  return async function (callback) {
    if (this.state.selectedCurrency === null && this.state.selectedSafe === null)
      return
    try {
      const result = await apolloClient.query({
        query: CURRENCY_RATES_QUERY,
        variables: {
          currency: this.state.selectedCurrency ? this.state.selectedCurrency.code : this.state.selectedSafe.currency,
          date: moment(this.props.form.getFieldValue(fieldName)).format('YYYY-MM-DD'),
        },
      })
      let currencyRates = result.data.currencyRates.currency_rates
      this.setState({ currency: currencyRates }, callback || null)
    } catch (err) {
      graphQLErrorsToToast(err)
    }
  }
}

// kur değerini direk olarak almaya yarar. currency adlı state güncellenir
const fetchCurrencyRatesDirect = async (currency, date = moment().format('YYYY-MM-DD'), callback) => {
  try {
    const result = await apolloClient.query({
      query: CURRENCY_RATES_QUERY,
      variables: { currency, date },
    })
    let currencyRates = result.data.currencyRates.currency_rates
    this.setState({ currency: currencyRates }, callback || null)
  } catch (err) {
    graphQLErrorsToToast(err)
  }
}

// varsayılan değeri ayarla
const setDefault = function (fieldName, defaultName, item, emptyState = '', stateKey) {

  let newValue = item

  if (typeof newValue !== 'string' && item && item.target) {
    newValue = item.target.value
  }

  if ((typeof newValue === 'undefined' || newValue === emptyState) && this.state.defaults[defaultName]) {

    this.props.form.setFieldsValue({ [fieldName]: this.state.defaults[defaultName] })

    if (stateKey) {
      this.setState({ [stateKey]: this.state.defaults[defaultName] })
    }

  }

}

// silme işlemleri, fatura silme, cari silme, kasa silme, işlem silme
const DELETE_QUERY = (type, fieldName) => (
  gql`
    mutation ($id: Int!){
    delete: ${type}Delete(${fieldName}: $id){
    informative {
    messages
    }
    }
    }
  `
)

const MULTIPLE_DELETE_QUERY = (type, fieldName) => (
  gql`
    mutation ($delete_input: [${startCase(type)}DeleteInput]!){
    delete: ${type}MultipleDelete(${fieldName}s: $delete_input){
    ${type === 'invoice' ? 'transaction' : type}s{
    ${fieldName}
    informative {
    messages
    }
    }
    informative {
    messages
    }
    }
    }
  `
)

const deleteData = (type, fieldName, goBack = true, refetch) => {

  return function (id) {
    return new Promise(async (r, j) => {
      try {
        const result = await apolloClient.mutate({
          mutation: DELETE_QUERY(type, fieldName),
          variables: { id: Number(id) },
        })

        if (refetch) {
          refetch()
        }

        toast(true, result.data.delete.informative.messages)
        if (goBack) {
          if (this.props.history.action === 'PUSH') {
            this.props.history.goBack()
          } else {
            this.props.history.push('/')
          }
        }
      } catch (err) {
        graphQLErrorsToToast(err)
        j()
      }
    })
  }

}

const multipleDeleteData = (type, fieldName) => {

  return function (delete_input, refetch) {
    return new Promise(async (r, j) => {
      try {
        const result = await apolloClient.mutate({
          mutation: MULTIPLE_DELETE_QUERY(type, fieldName),
          variables: { delete_input },
        })
        refetch()
        toast(true, result.data.delete.informative.messages)
      } catch (err) {
        graphQLErrorsToToast(err)
        j()
      }
    })
  }

}

export {
  searchCustomer,
  createCategory,
  sequenceSetFirstValues,
  refetchAndControlSequenceValues,
  fetchCurrencyRates,
  fetchCurrencyRatesDirect,
  setDefault,
  deleteData,
  multipleDeleteData,
}
